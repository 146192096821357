import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Chips } from '@uikit/Chips';
import { Table } from '@uikit/Table/Table';
import { TAppLanguage } from '@models/index';
import { useAppDispatch } from '@store/store';
import { CONSULTANT_OPERATION_LIST_STATUSES } from '@const/partners';
import { TConsultantFullTableData } from '@redux/consultantFullTable/models';
import { setSortTypeDataCFT } from '@redux/consultantFullTable/consultantFullTableSlice';

interface IConsultantFullTableProps {
  /**
   * Данные для таблицы
   * @param {TConsultantFullTableData[]}
   */
  data: TConsultantFullTableData[];
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const ConsultantFullTable = memo(
  ({ appLanguage, data, className = '' }: IConsultantFullTableProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const getStatusChips = useCallback(
      (status: string) => (
        <Chips round noClick type='dense' icon='disable' color='danger' tag={status} />
      ),
      [],
    );

    const Head = useMemo(
      () => [
        { id: 'accId', label: t('PARTNER_PAGE_TEXT.consultantFullTable.accId') },
        { id: 'filialName', label: t('PARTNER_PAGE_TEXT.consultantFullTable.filialName') },
        { id: 'lastPayDate', label: t('PARTNER_PAGE_TEXT.consultantFullTable.lastPayDate') },
        { id: 'payTillDate', label: t('PARTNER_PAGE_TEXT.consultantFullTable.payTillDate') },
      ],
      [t],
    );

    const getTableData = useCallback(
      (tableData: TConsultantFullTableData[]) => {
        const emptyData = [
          {
            accId: ' ',
            filialName: ' ',
            lastPayDate: ' ',
            payTillDate: ' ',
          },
        ];

        if (!tableData.length) {
          return emptyData;
        }

        return tableData.map(({ accId, filialName, payTillDate, lastPayDate }) => {
          return {
            accId,
            filialName: <p className='flex items-center'>{filialName}</p>,
            lastPayDate:
              lastPayDate === CONSULTANT_OPERATION_LIST_STATUSES[appLanguage].NOT_PAID
                ? getStatusChips(CONSULTANT_OPERATION_LIST_STATUSES[appLanguage].NOT_PAID)
                : lastPayDate,
            payTillDate:
              payTillDate === CONSULTANT_OPERATION_LIST_STATUSES[appLanguage].NOT_PAID
                ? getStatusChips(CONSULTANT_OPERATION_LIST_STATUSES[appLanguage].NOT_PAID)
                : payTillDate,
          };
        });
      },
      [appLanguage, getStatusChips],
    );

    // функция сортировки, получает название столбца отправляет в редакс
    const setSortTypeHandler = useCallback(
      (columnName: string, order: string) => {
        dispatch(setSortTypeDataCFT({ columnName, order }));
      },
      [dispatch],
    );

    return (
      <div className={className}>
        <h3 className='font-medium text-black text-h3Mobile leading-8 tracking-[0.004em] mb-4'>
          {t('PARTNER_PAGE_TEXT.consultantFullTable.header')}
        </h3>
        <Table
          name={Head}
          data={getTableData(data)}
          position={['left', 'left', 'left', 'left']}
          type='def'
          view='flat'
          color='default'
          pages
          labelRowsPerPage={t('PARTNER_PAGE_TEXT.consultantFullTable.pagination')}
          rowsPerPageOptions={[5, 8, 20]}
          defRows={8}
          checkboxes={false}
          paperClassName='no_shadow'
          onSortCallback={setSortTypeHandler}
          style={{ boxShadow: 'none', marginLeft: '6px' }}
        />
      </div>
    );
  },
);

ConsultantFullTable.displayName = 'ConsultantFullTable';
