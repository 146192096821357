import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';

interface IBbCommercialSideNav {
  /**
   * URL картинки которая отображается в верхней части блока
   * @param {string}
   */
  imgURL: string;
  /**
   * Callback для открытия страницы при клике на кнопку
   * @param {() => void}
   */
  changePageOnClick: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const ABNewCommercialSideNav = memo(
  ({ changePageOnClick, imgURL, className = '' }: IBbCommercialSideNav) => {
    const { t } = useTranslation();
    return (
      <div
        className={`border border-lightGray1 flex flex justify-start flex-col rounded-lg max-w-[17.5rem] ${className}`}>
        <div className='bg-lightGray flex items-center justify-center min-w-[4rem]'>
          <img
            src={imgURL}
            alt='Commercial'
            height={370}
            width={278}
            className='block pointer-events-none h-[23.16625rem] w-[17.375rem] rounded-t-lg'
          />
        </div>
        <div className='p-4'>
          <Button
            dense
            type='action'
            view='filled'
            color='success'
            onClick={changePageOnClick}
            text={t('BASE_TEMPLATE_PAGE_TEXT.abNewCommercial.button')}
            className='!min-w-[8.875rem] transition-all duration-300 ease-out'
          />
        </div>
      </div>
    );
  },
);
