import { dateSort, simpleSort } from '@helpers/index';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInitialState, initialState } from '@redux/operationListTable/initialState';
import { getOperationListTableData } from '@redux/operationListTable/operationListTableThunks';
import {
  TRejectResponse,
  TRejectResponse2,
  REJECT_RESPONSE_KEY,
  REJECT_RESPONSE_2_KEY,
} from '@api/types';

import {
  TOperationListTableData,
  TMappedOperationListTableData,
  OPERATION_LIST_TABLE_KEY_NAMES,
} from './models';

const operationListTableSlice = createSlice({
  name: 'operationListTableSlice',
  initialState,
  reducers: {
    setTableDataOLT(state: IInitialState, action: PayloadAction<TOperationListTableData[]>) {
      state.data = action.payload;
    },
    setSortTypeDataOLT(
      state: IInitialState,
      action: PayloadAction<{ columnName: string; order: string }>,
    ) {
      const { columnName, order } = action.payload;

      const key = columnName as unknown as keyof TOperationListTableData;

      if (columnName === OPERATION_LIST_TABLE_KEY_NAMES.OPERATION_DATE) {
        state.data = dateSort<TOperationListTableData>(state.data, key, order);
      } else {
        state.data = simpleSort<TOperationListTableData>(state.data, key, order);
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getOperationListTableData.pending, (state: IInitialState) => {
      state.isError = false;
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(
      getOperationListTableData.fulfilled,
      (state: IInitialState, action: PayloadAction<TMappedOperationListTableData | void>) => {
        if (action.payload) {
          const { data, totalAccrued } = action.payload;
          state.data = dateSort<TOperationListTableData>(data, 'operationDate', 'desc');
          state.totalAccrued = totalAccrued;
        }
        state.isSuccess = true;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getOperationListTableData.rejected,
      (
        state: IInitialState,
        action: PayloadAction<TRejectResponse | TRejectResponse2 | undefined>,
      ) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
              state.isLoading = false;
            } else {
              return;
            }
          }
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
          }
        } else {
          state.isError = true;
        }
        state.isLoading = false;
      },
    );
  },
});

export const operationListTableReducer = operationListTableSlice.reducer;
export const { setTableDataOLT, setSortTypeDataOLT } = operationListTableSlice.actions;
