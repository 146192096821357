import React, { memo, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Icon } from '@atoms/icon';
import { Toaster } from '@uikit/Toast';

type TToastsMessages = {
  /**
   * Текст сообщения об ошибке
   * @param {string}
   */
  errorMessage: string;
  /**
   * Текст предупреждения
   * @param {string}
   */
  warningMessage?: string;
  /**
   * Текст сообщения об успехе
   * @param {string}
   */
  successMessage: string;
  /**
   * Флаг показа сообщения об ошибке
   * @param {boolean}
   */
  isShowErrorToast: boolean;
  /**
   * Флаг показа предупреждающего сообщения
   * @param {boolean}
   */
  isShowWarningToast?: boolean;
  /**
   * Флаг показа сообщения об ошибке
   * @param {boolean}
   */
  isShowSuccessToast: boolean;
  /**
   * Время показа сообщения
   * @param {number}
   */
  successToastTimeout?: number;
  /**
   * Время показа сообщения
   * @param {number}
   */
  warningToastTimeout?: number;
  /**
   * Очищает состояние ошибки
   * @param {() => void}
   */
  clearErrorCallback?: () => void;
  /**
   * Очищает состояние успеха
   * @param {() => void}
   */
  clearSuccessStatusCallback?: () => void;
  /**
   * Опциональный таймаут на вызов функции очистки статусов
   * @param{number}
   * @default 0
   */
  callClearSuccessStatusCallbackTimeOut?: number;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const ToastsMessages = memo(
  ({
    errorMessage,
    successMessage,
    warningMessage = '',
    isShowErrorToast,
    isShowWarningToast = false,
    isShowSuccessToast,
    successToastTimeout = 2000,
    warningToastTimeout = 4000,
    clearErrorCallback = () => '',
    clearSuccessStatusCallback = () => '',
    callClearSuccessStatusCallbackTimeOut = 0,
    className = '',
  }: TToastsMessages) => {
    const { t } = useTranslation();

    // Объект настроек successToastMessage
    const successToast = useMemo(
      () => ({
        message: successMessage ? t(successMessage) : '',
        icon: <Icon variant='successToast' className='ml-4' />,
        timeout: successToastTimeout,
      }),
      [successToastTimeout, successMessage, t],
    );

    // Объект настроек successToastMessage
    const warningToast = useMemo(
      () => ({
        message: warningMessage ? t(warningMessage) : '',
        icon: <Icon variant='infoToast' className='ml-4' />,
        timeout: warningToastTimeout,
      }),
      [warningToastTimeout, warningMessage, t],
    );

    // Объект настроек errorToastMessage
    const errorToast = useMemo(
      () => ({
        message: errorMessage ? t(errorMessage) : '',
        icon: <Icon variant='errorToast' className='ml-4' />,
        onDismiss: clearErrorCallback,
        timeout: 0,
      }),
      [errorMessage, clearErrorCallback, t],
    );

    // реф для сообщения о успехе
    const successToastRef = useRef(successToast);

    // реф для сообщения об ошибке
    const errorToastRef = useRef(errorToast);

    // реф для предупреждения
    const warningToastRef = useRef(warningToast);

    // Эффект показывает сообщение об успешном действии
    useEffect(() => {
      if (isShowSuccessToast) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        successToastRef.current.show(successToast);
        setTimeout(clearSuccessStatusCallback, callClearSuccessStatusCallbackTimeOut);
      }
    }, [
      successToast,
      isShowSuccessToast,
      clearSuccessStatusCallback,
      callClearSuccessStatusCallbackTimeOut,
    ]);

    // Эффект показывает сообщение об ошибке
    useEffect(() => {
      if (isShowErrorToast) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        errorToastRef.current.show(errorToast);
      }
    }, [isShowErrorToast, errorToast]);

    // Эффект показывает  сообщение при ошибке копирования шаблона
    useEffect(() => {
      if (isShowWarningToast) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        warningToastRef.current.show(warningToast);
        setTimeout(clearSuccessStatusCallback, 0);
      }
    }, [isShowWarningToast, warningToast, warningToastRef, clearSuccessStatusCallback]);

    const memoizedSuccessToast = useMemo(
      () => (
        <Toaster
          withoutClose
          maxToasts={1}
          view='smooth'
          color='success'
          position='top-right'
          ref={successToastRef}
          className='!z-[999]'
        />
      ),
      [successToastRef],
    );

    const memoizedErrorToast = useMemo(
      () => (
        <Toaster
          maxToasts={1}
          view='smooth'
          color='danger'
          ref={errorToastRef}
          position='top-right'
          withoutClose={false}
          className='error !z-[999]'
        />
      ),
      [errorToastRef],
    );

    const memoizeWarningToast = useMemo(
      () => (
        <Toaster
          maxToasts={1}
          view='smooth'
          color='warning'
          position='top-right'
          withoutClose={false}
          ref={warningToastRef}
          className='error !z-[999]'
        />
      ),
      [warningToastRef],
    );

    return (
      <div className={className}>
        {memoizedErrorToast}
        {memoizeWarningToast}
        {memoizedSuccessToast}
      </div>
    );
  },
);

ToastsMessages.displayName = 'ToastsMessages';
