import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Checkbox } from '@uikit/CheckBox';
import { FormLabel } from '@blocks/formLabel';
import { TOnChangeHandler } from '@shared/types';
import { TEMPLATE_DATA_KEY_NAMES } from '@redux/template/models';

interface ITemplateRecordStatusProps {
  /**
   * Значение чекбокса "Клиент не пришел"
   * @param {boolean}
   */
  clientDidNotCome: boolean;
  /**
   * Значение чекбокса "Ожидание клиента"
   * @param {boolean}
   */
  clientExpectation: boolean;
  /**
   * Значение чекбокса "Клиент пришел"
   * @param {boolean}
   */
  clientCame: boolean;
  /**
   * Значение чекбокса "Клиент подтвердил"
   * @param {boolean}
   */
  clientConfirmed: boolean;
  /**
   * Параметр указывает что шаблон по времени по времени
   * временно отключен, не отслеживается
   * @param {boolean}
   */
  isTimeEvent: boolean;
  /**
   * Параметр указывает, что шаблон отправляется всем у кого есть запись
   * временно отключен, не отслеживается
   * @param {boolean}
   */
  isAllDayRecsNotifications: boolean;
  /**
   * Параметр указывает, что шаблон отправляется до события
   * временно отключен, не отслеживается
   * @param {boolean}
   */
  isBefore: boolean;
  /**
   * Параметр указывает, что шаблон отправляется после события
   * временно отключен, не отслеживается
   * @param {boolean}
   */
  isAfter: boolean;
  /**
   * Обработчик события onChange на чекбоксах
   * @param event
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Флаг блокирования чекбокса клиент не пришел
   * @param {boolean}
   */
  isDisableClientDidNotCome: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
  /**
   * Флаг отключения блока
   * @param {boolean}
   */
  disabled?: boolean;
}
// подумать над объединением внутренней логики с компонентом ReviewsRecordStatus
export const TemplateRecordStatus = memo(
  ({
    clientDidNotCome,
    clientExpectation,
    clientCame,
    clientConfirmed,
    // eslint-disable-next-line
    isTimeEvent,
    // eslint-disable-next-line
    isAllDayRecsNotifications,
    // eslint-disable-next-line
    isBefore,
    // eslint-disable-next-line
    isAfter,
    onChangeHandler,
    isDisableClientDidNotCome,
    className = '',
    disabled = false,
  }: ITemplateRecordStatusProps) => {
    const { t } = useTranslation();

    // отключена блокировка и изменение цвета чекбоксов, "логика" работы "описана/нарисована" в фигме
    // Илья не подтвердил правильность этой логики, пока отключено.
    return (
      <div className={`max-w-[30rem] ${className}`}>
        <FormLabel
          htmlFor='recordStatus'
          labelTextVariant='recordStatus'
          fontSize='!text-stormGray leading-6 tracking-[0.0275em]'
          className='mb-2'
        />
        <div className='flex flex-wrap flex-col sm:flex-row'>
          <div className='mb-2 mr-2'>
            <Checkbox
              color={
                // isTimeEvent
                //   ? isAfter
                //     ? 'default'
                //     : 'default'
                //   : isAllDayRecsNotifications
                //   ? isAfter
                //     ? 'success'
                //     : 'default'
                //   : 'success'
                disabled || isDisableClientDidNotCome ? 'default' : 'success'
              }
              // disabled={isBefore}
              checked={clientDidNotCome}
              onChange={onChangeHandler}
              label={t('LABEL_TEXT.clientDidNotCome')}
              disabled={disabled || isDisableClientDidNotCome}
              name={TEMPLATE_DATA_KEY_NAMES.CLIENT_DID_NOT_COME}
              className='w-[14.625rem]'
            />
          </div>
          <div className='mb-2'>
            <Checkbox
              color={
                // isTimeEvent
                //   ? isBefore
                //     ? 'default'
                //     : 'default'
                //   : isAllDayRecsNotifications
                //   ? isBefore
                //     ? 'success'
                //     : 'default'
                //   : 'success'
                disabled ? 'default' : 'success'
              }
              // disabled={isAfter}
              disabled={disabled}
              onChange={onChangeHandler}
              checked={clientExpectation}
              label={t('LABEL_TEXT.clientExpectation')}
              name={TEMPLATE_DATA_KEY_NAMES.CLIENT_EXPECTATION}
              className='w-[14.625rem]'
            />
          </div>
          <div className='mr-2 mb-2 sm:mb-0'>
            <Checkbox
              color={
                // isTimeEvent
                //   ? isAfter
                //     ? 'default'
                //     : 'default'
                //   : isAllDayRecsNotifications
                //   ? isAfter
                //     ? 'success'
                //     : 'default'
                //   : 'success'
                disabled ? 'default' : 'success'
              }
              // disabled={isBefore}
              disabled={disabled}
              checked={clientCame}
              onChange={onChangeHandler}
              label={t('LABEL_TEXT.clientCame')}
              name={TEMPLATE_DATA_KEY_NAMES.CLIENT_CAME}
              className='w-[14.625rem]'
            />
          </div>
          <div className=''>
            <Checkbox
              color={
                // isTimeEvent
                //   ? isBefore
                //     ? 'default'
                //     : 'default'
                //   : isAllDayRecsNotifications
                //   ? isBefore
                //     ? 'success'
                //     : 'default'
                //   : 'success'
                disabled ? 'default' : 'success'
              }
              // disabled={isAfter}
              disabled={disabled}
              checked={clientConfirmed}
              onChange={onChangeHandler}
              label={t('LABEL_TEXT.clientConfirmed')}
              name={TEMPLATE_DATA_KEY_NAMES.CLIENT_CONFIRMED}
              className='w-[14.625rem]'
            />
          </div>
        </div>
      </div>
    );
  },
);
