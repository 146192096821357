import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { TReviewLastStepInListData } from '@redux/reviews/models';

interface IReviewsStep5CardProps {
  /**
   * ID шага
   * @param {number}
   */
  stepId: number;
  /**
   * Данные карточки
   * @param {TReviewLastStepInListData}
   */
  data: TReviewLastStepInListData;
  /**
   * Флаг доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const ReviewsStep5Card = memo(
  ({
    data,
    stepId,
    className = '',
    // eslint-disable-next-line
  isEditTemplatesAccess,
  }: IReviewsStep5CardProps) => {
    const { t } = useTranslation();
    const { body: positive, fail_text: negative } = data;

    return (
      <div
        className={`bg-white border border-lightGray flex flex-col grow shrink font-inter text-[1.5rem] text-blackText p-4 rounded-lg ${className}`}>
        <div className='flex items-center mb-5'>
          {/* {isEditTemplatesAccess && (
          <ToggleSwitch
            name='status'
            checked={status}
            onChangeHandler={() =>
              switchStep(!status, String(itemCount ? stepId : 2), templateCardId)
            }
            className='mr-2 z-[1]'
          />
        )} */}
          <p className='font-bold text-darkGray m-0'>{t('REVIEW_PAGE_TEXT.step', { stepId })}</p>
        </div>
        <p className='text-body_text h-12 overflow-hidden mb-3'>{positive}</p>
        <p className='text-body_text h-12 overflow-hidden mb-3'>{negative}</p>
      </div>
    );
  },
);
