import React, { memo, useCallback, useEffect, useState } from 'react';
//
import { Icon } from '@atoms/icon';
import { Callout } from '@uikit/Callout';
import { storageDb } from '@api/storageApi';

interface IHeaderBannerProps {
  /**
   * Текст банера
   * @param {string}
   */
  bodyText: string;
  /**
   * Вариант цвета банера
   * @param {string}
   */
  variant: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const HeaderBanner = memo(({ bodyText, variant, className = '' }: IHeaderBannerProps) => {
  const [isViewHeaderBanner, setIsViewHeaderBanner] = useState<{ isHeaderBannerStatus: string }>({
    isHeaderBannerStatus: 'false',
  });

  useEffect(() => {
    const headerBannerStatus = storageDb.getHeaderBannerStatus();

    if (headerBannerStatus.isHeaderBannerStatus !== isViewHeaderBanner.isHeaderBannerStatus) {
      setIsViewHeaderBanner(storageDb.getHeaderBannerStatus());
    }
  }, [isViewHeaderBanner.isHeaderBannerStatus]);

  const hideBannerHandler = useCallback(() => {
    storageDb.setHeaderBannerStatus('false');

    setIsViewHeaderBanner({ isHeaderBannerStatus: 'false' });
  }, []);

  return !isViewHeaderBanner.isHeaderBannerStatus.includes('false') ? (
    <div className={`max-w-[82.5rem] ${className}`}>
      <Callout title={null} color={variant} view='smooth' icon='info-sign'>
        <div className='flex !pr-[3.4rem] relative leading-[2.4rem]'>
          {bodyText}
          <button
            className='absolute right-[0.5rem] p-[0.3rem] cursor-pointer h-8 w-8 flex items-center justify-center'
            onClick={hideBannerHandler}>
            <Icon variant='iconCloseBanner' />
          </button>
        </div>
      </Callout>
    </div>
  ) : null;
});

HeaderBanner.displayName = 'HeaderBanner';
