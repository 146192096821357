import { TRejectResponseData } from '@models/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { chatHistoryApi } from '@api/chatHistoryApi';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { TChatHistoryTableData } from '@redux/chatHistory/models';
import { REJECT_RESPONSE_KEY, CHAT_RESPONSE_KEY_NAMES } from '@api/types';
import { chatHistoryMappers } from '@redux/chatHistory/chatHistoryMappers';

export const getChatHistory = createAsyncThunk<
  TChatHistoryTableData[] | void,
  void,
  { rejectValue: TRejectResponseData }
>('chatHistory/getChatHistory', (data, { dispatch, rejectWithValue }) => {
  return chatHistoryApi
    .getChatHistory()
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      if (CHAT_RESPONSE_KEY_NAMES.MESSAGES in response.data) {
        return chatHistoryMappers.mapGetChatHistoryDataToStore(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getChatHistory()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});
