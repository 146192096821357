import { TAutoConnectionData } from '@redux/autoConnect/models';
import { autoConnectionEmptyData } from '@redux/autoConnect/mockData';

export interface IInitialState {
  hash: string;
  accId: string;
  isEdit: boolean;
  branchId: string;
  isError: boolean;
  isLoading: boolean;
  errorMessage: string;
  newPromoCode: string;
  newPromoCode2: string;
  successMessage: string;
  isShowEmojiPicker: boolean;
  callbackPhoneNumber: string;
  toNextStepSwitching: boolean;
  isShowSuccessMessage: boolean;
  isSendAmplitudeEvent: boolean;
  isAmplitudeEventSend: boolean;
  isShowRequestACallDialog: boolean;
  data: TAutoConnectionData;
}

export enum AUTO_CONNECTION_PAGE_DATA_KEYS_NAME {
  CALLBACK_PHONE_NUMBER = 'callbackPhoneNumber',
  NEW_PROMO_CODE = 'newPromoCode',
}

export const initialState: IInitialState = {
  hash: '',
  accId: '',
  branchId: '',
  isEdit: false,
  isError: false,
  isLoading: false,
  errorMessage: '',
  newPromoCode: '',
  newPromoCode2: '',
  successMessage: '',
  callbackPhoneNumber: '',
  isShowEmojiPicker: false,
  toNextStepSwitching: false,
  isShowSuccessMessage: false,
  isSendAmplitudeEvent: false,
  isAmplitudeEventSend: false,
  isShowRequestACallDialog: false,
  data: autoConnectionEmptyData,
};
