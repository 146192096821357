import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Chips } from '@uikit/Chips';
import { Table } from '@uikit/Table/Table';
import { useAppDispatch } from '@store/store';
import { CONSULTANT_OPERATION_LIST_STATUSES } from '@const/partners';
import { TOperationListTableData } from '@redux/operationListTable/models';
import { setSortTypeDataOLT } from '@redux/operationListTable/operationListTableSlice';
import { TAppLanguage } from '@models/index';

interface IOperationListTableProps {
  /**
   * Сумма к начислению
   * @param {string}
   */
  totalAccrued: string;
  /**
   * Данные таблицы
   * @param {TOperationListTableData[]}
   */
  data: TOperationListTableData[];
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const OperationListTable = memo(
  ({ data, appLanguage, totalAccrued, className = '' }: IOperationListTableProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const getStatusChips = useCallback(
      (status: string) => (
        <Chips
          round
          noClick
          type='dense'
          icon={
            status === CONSULTANT_OPERATION_LIST_STATUSES[appLanguage].PAID
              ? 'small-tick'
              : status === CONSULTANT_OPERATION_LIST_STATUSES[appLanguage].IN_PROGRESS
              ? 'time'
              : 'disable'
          }
          color={
            status === CONSULTANT_OPERATION_LIST_STATUSES[appLanguage].PAID
              ? 'success'
              : status === CONSULTANT_OPERATION_LIST_STATUSES[appLanguage].IN_PROGRESS
              ? 'warning'
              : 'danger'
          }
          tag={status}
        />
      ),
      [appLanguage],
    );

    const Head = useMemo(
      () => [
        { id: 'operationDate', label: t('PARTNER_PAGE_TEXT.operationListTable.operationDate') },
        { id: 'filialName', label: t('PARTNER_PAGE_TEXT.operationListTable.filialName') },
        { id: 'paySum', label: t('PARTNER_PAGE_TEXT.operationListTable.paySum') },
        { id: 'sumToPay', label: t('PARTNER_PAGE_TEXT.operationListTable.sumToPay') },
        { id: 'status', label: t('PARTNER_PAGE_TEXT.operationListTable.status'), noSort: true },
      ],
      [t],
    );

    const getTableData = useCallback(
      (tableData: TOperationListTableData[]) => {
        const emptyData = [
          {
            operationDate: ' ',
            filialName: ' ',
            paySum: ' ',
            sumToPay: ' ',
            status: ' ',
          },
        ];
        if (!tableData.length) {
          return emptyData;
        }
        return tableData.map(({ operationDate, branch, filialName, paySum, sumToPay, status }) => ({
          operationDate,
          filialName: `${branch} ${filialName}`,
          paySum:
            String(paySum) === CONSULTANT_OPERATION_LIST_STATUSES[appLanguage].NOT_PAID ||
            String(paySum) === CONSULTANT_OPERATION_LIST_STATUSES[appLanguage].NOT_NEW_PAY
              ? getStatusChips(String(paySum))
              : paySum,
          sumToPay:
            String(sumToPay) === CONSULTANT_OPERATION_LIST_STATUSES[appLanguage].NOT_PAID ||
            String(sumToPay) === CONSULTANT_OPERATION_LIST_STATUSES[appLanguage].NOT_NEW_PAY
              ? getStatusChips(String(sumToPay))
              : sumToPay,
          status: getStatusChips(status),
        }));
      },
      [appLanguage, getStatusChips],
    );

    // функция сортировки, получает название столбца отправляет в редакс
    const setSortTypeHandler = useCallback(
      (columnName: string, order: string) => {
        dispatch(setSortTypeDataOLT({ columnName, order }));
      },
      [dispatch],
    );

    return (
      <div className={className}>
        <p className='flex justify-end font-medium text-black text-h3Mobile leading-8 mb-4 tracking-[0.004em]'>
          {totalAccrued
            ? t('PARTNER_PAGE_TEXT.operationListTable.header', { totalAccrued })
            : t('PARTNER_PAGE_TEXT.operationListTable.headerWithOutTotalAccrued')}
        </p>
        <Table
          name={Head}
          data={getTableData(data)}
          position={['left', 'left', 'right', 'right', 'left']}
          type='def'
          view='flat'
          color='default'
          pages
          labelRowsPerPage={t('PARTNER_PAGE_TEXT.operationListTable.pagination')}
          rowsPerPageOptions={[5, 8, 20]}
          defRows={8}
          checkboxes={false}
          paperClassName='no_shadow'
          onSortCallback={setSortTypeHandler}
          style={{ boxShadow: 'none', marginLeft: '6px' }}
        />
      </div>
    );
  },
);

OperationListTable.displayName = 'OperationListTable';
