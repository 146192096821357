import React, { forwardRef, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Chips } from '@uikit/Chips';
import { TAppLanguage } from '@models/index';
import { TSetStartSendOutDay } from '@shared/types';
import { TSendOutElement } from '@redux/mailing/models';
import { CalendarSendOutElement } from '@blocks/calendarSendOutElement';

type TCalendarElementProps = {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Флаг изменяет отображения элемента
   * @param {boolean}
   */
  isMobileView: boolean;
  /**
   * ID рассылок запущенных в этот день
   * @param {string[]}
   */
  sendOutNumbers: string[];
  /**
   * Текстовое сокращение дня недели
   * @param {string}
   */
  weekDay: string;
  /**
   * Флаг серого текста, означает прошедшие дни или дни следующего месяца
   * @param {boolean}
   */
  isGrayText: boolean;
  /**
   * День месяца
   * @param {string}
   */
  day: string;
  /**
   * Флаг означает что день в прошлом от текущей даты
   * по флагу меняется внешний вид компонента и отключаются обработчики
   * @param {boolean}
   */
  isPast: boolean;
  /**
   * Callback для выбора дня в календаре
   * @param {TSetStartSendOutDay}
   */
  setStartSendOutDay: TSetStartSendOutDay;
  /**
   * Удаляет из календаря новую рассылку
   * @param {() => void}
   */
  deleteNewSendOut: () => void;
  /**
   * Максимальное количество сообщений в день
   * @param {number}
   */
  daySendOutLimit: number;
  /**
   * Данные для отображения рассылок в текущем дне
   * @param {TSendOutElement[]}
   */
  sendOutElementsData: TSendOutElement[];
  /**
   * Флаг добавления новой рассылки в календарь
   * @param {boolean}
   */
  isNewSendOutAdded: boolean;
  /**
   * Флаг показывает тэг сегодня в календаре
   * @param {boolean}
   */
  isToday: boolean;
  /**
   * Флаг блокировки дня
   * @param {boolean}
   */
  blockThisDay: boolean;
  /**
   * Callback для получения данных по рассылкам в выбранном дне
   * @param {(isShowWarningData: boolean) => void}
   */
  getSendOutDataHandler: (isShowWarningData: boolean) => void;
  /**
   * Флаг состояния запроса за детальной информацией по рассылкам за день
   * @param {boolean}
   */
  isGettingSendOutDetailData: boolean;
  // /**
  //  * Текущая дата для элемента
  //  * @param {Date}
  //  */
  // date: Date;
  // /**
  //  * Данные по новой рассылке
  //  * @param {TSendOutsData}
  //  */
  // newSendOutData: TSendOutsData;
  // /**
  //  * Массив рассылок для отображения в календаре
  //  * @param {TSendOutsData[]}
  //  */
  // sendOuts: TSendOutsData[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const CalendarElement = forwardRef<HTMLDivElement, TCalendarElementProps>(
  (
    {
      day,
      isPast,
      weekDay,
      isToday,
      isGrayText,
      appLanguage,
      blockThisDay,
      isMobileView,
      sendOutNumbers,
      daySendOutLimit,
      deleteNewSendOut,
      isNewSendOutAdded,
      setStartSendOutDay,
      sendOutElementsData,
      getSendOutDataHandler,
      isGettingSendOutDetailData,
      className = '',
    },
    ref,
  ) => {
    const { t } = useTranslation();

    // Удаляет новую рассылку из сетки календаря
    const deleteNewSendOutHandler = useCallback(
      (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        event.stopPropagation();
        deleteNewSendOut();
      },
      [deleteNewSendOut],
    );

    // Массив остатков квоты на день
    const remainders: number[] = [];

    // Флаг головы рассыки
    const isHeadElement = useRef(false);
    isHeadElement.current = false;

    // Флаг тела рассылки
    const isBodyElement = useRef(false);
    isBodyElement.current = false;

    // Флаг конца рассылки
    const isTailElement = useRef(false);
    isTailElement.current = false;

    // Дата окончания рассылки
    const endDateOfSendOut = useRef<null | Date>(null);
    endDateOfSendOut.current = null;

    // Элементы календаря для рендеренга
    const calendarElementsToRender = sendOutElementsData.map(
      (
        {
          isHead,
          isTail,
          endDate,
          isActive,
          remainder,
          isNewSendOut,
          sendOutNumber,
          widthInPercents,
        },
        index,
      ) => {
        const key = index + sendOutNumber;

        if (isTail) {
          remainders.push(remainder);
        }

        isHeadElement.current = isHead;

        isBodyElement.current =
          (isNewSendOut || isActive || (!isActive && !!sendOutNumber)) && !isHead && !isTail;

        isTailElement.current = isTail;

        endDateOfSendOut.current = null;

        const style = `${
          (isHeadElement.current || isBodyElement.current || isTailElement.current) && !isNewSendOut
            ? '!cursor-help'
            : ''
        } ${isGettingSendOutDetailData ? '!cursor-wait' : ''} mt-2`;

        const showSendOutData = (event: React.MouseEvent<HTMLDivElement>) => {
          event.stopPropagation();

          if ((isHeadElement || isBodyElement || isTailElement) && !isGettingSendOutDetailData) {
            getSendOutDataHandler(false);
          }
        };

        if (isPast) {
          return null;
        }

        return (
          <CalendarSendOutElement
            key={key}
            isHead={isHead}
            isTail={isTail}
            isActive={isActive}
            appLanguage={appLanguage}
            endDateOfSendOut={endDate}
            isNewSendOut={isNewSendOut}
            sendOutNumber={sendOutNumber}
            sendOutNumbers={sendOutNumbers}
            showSendOutData={showSendOutData}
            widthInPercents={widthInPercents}
            deleteNewSendOut={deleteNewSendOutHandler}
            className={style}
          />
        );
      },
    );

    // Получаем минимальный остаток если в дне несколько рассылок
    const minimalDayRemainder = remainders.length > 1 ? Math.min(...remainders) : remainders[0];

    // Рассчитываем остаток квоты на день
    const remainDayLimit =
      minimalDayRemainder ||
      (minimalDayRemainder === 0
        ? 0
        : isBodyElement.current || (isHeadElement.current && !isTailElement.current)
        ? 0
        : daySendOutLimit);

    // Флаг наличия рассылки в этот день
    const isSendOutPresentInDay =
      isHeadElement.current || isBodyElement.current || isTailElement.current;

    // // Дата на начало дня для проверки возможности запуска новой рассыки в текущем дне
    // let startOfDayDate = new Date(getCurrentTimeStampAtStartOfDay(date));

    // // Шаг проверки
    // const startSendOutCheckStep = 300000;
    //
    // // Массив результатов попыток запуска новой рассылки
    // const isSendOutFit: boolean[] = [];
    //
    // // счетчик шагов проверки, для предотвращения бесконечного цикла
    // let steps = 0;
    //
    // // Цикл проверки запуска рассылки
    // while (checkDateIsEqual(startOfDayDate, date) && steps < 300 && remainDayLimit) {
    //   let newEndDate = new Date(startOfDayDate.getTime() + newSendOutData.duration);
    //   const newRemainder = newSendOutData.totalCount - remainDayLimit;
    //
    //   if (newRemainder > 0) {
    //     newEndDate = new Date(newEndDate.setDate(newEndDate.getDate() + 1));
    //   }
    //
    //   const newMockSendOut = {
    //     ...newSendOutData,
    //     startDate: startOfDayDate,
    //     endDate: newEndDate,
    //   };
    //
    //   isSendOutFit.push(checkNewSendOut(newMockSendOut, sendOuts));
    //
    //   startOfDayDate = new Date(startOfDayDate.getTime() + startSendOutCheckStep);
    //
    //   steps++;
    // }

    // Флаг возможности запустить рассылку
    const isCanStartSendOut = !blockThisDay; // && remainDayLimit && isSendOutFit.some(Boolean);

    // Обработчик наатия на элемент календаря
    const calendarElementOnClickHandler = () => {
      setStartSendOutDay(endDateOfSendOut.current, remainDayLimit);
      // if (isCanStartSendOut) {
      //   setStartSendOutDay(endDateOfSendOut.current, remainDayLimit);
      // }
      // if (isSendOutPresentInDay && !isCanStartSendOut && !isToday) {
      //   getSendOutDataHandler();
      // }
    };

    // стили курсора
    const cursorStyle = `${isSendOutPresentInDay && !isToday ? '' : ''} ${
      isGettingSendOutDetailData ? '!cursor-wait' : ''
    } ${isCanStartSendOut ? '!cursor-pointer' : ''}`;
    // ${isNewSendOutAdded ? '!cursor-not-allowed' : ''}`;

    // стили
    const componentWrapperStyle = `cursor-not-allowed ${cursorStyle} relative border-r border-t border-quartz grid gap-2 grid-rows-calendarElementRow dashed_right_border items-stretch ${className}`;

    // стили
    const cellWrapperStyle = `${
      isPast ? 'cursor-not-allowed' : 'cursor-pointer'
    } relative border-r border-t border-quartz dashed_right_border w-full`;

    // стили
    const textColorStyle = `${isGrayText ? 'text-dGray' : 'text-black'}`;

    // стили
    const textStyle = 'text-[1rem] leading-6 tracking-[0.0275em] text-end h-fit';

    // стили
    const calendarElementWrapperStyle = 'flex w-full min-h-[3rem]';

    return (
      <div
        className={`grid ${isPast ? 'bg-gray1' : ''} ${
          isCanStartSendOut && !isPast ? 'bg-ivory' : ''
        } mb-2`}>
        {!isMobileView ? (
          <div onClick={calendarElementOnClickHandler} className={componentWrapperStyle}>
            <div className='flex items-center justify-between px-2 mt-1'>
              {isToday ? (
                <Chips
                  round
                  noClick
                  type='dense'
                  id='todayTag'
                  color='success'
                  withTick={false}
                  tag={t('MAILING_PAGE_TEXT.mailingCalendar.todayTag')}
                />
              ) : (
                <div />
              )}
              <p className={`${textColorStyle} ${textStyle}`}>{day}</p>
            </div>
            {/* {isTailElement && remainders.length ? (
              <span className='absolute bottom-0 right-0 text-tooltips text-gray3 leading-5 tracking-[0.018em]'>
                {t('MAILING_PAGE_TEXT.mailingCalendar.reminderText', {
                  remainDayLimit,
                  daySendOutLimit,
                })}
              </span>
            ) : null} */}
            <div className={calendarElementWrapperStyle}>{calendarElementsToRender}</div>
          </div>
        ) : (
          <div
            onClick={calendarElementOnClickHandler}
            className={`flex items-start ${isPast ? 'bg-gray1' : ''} ${
              isCanStartSendOut && !isPast && !isNewSendOutAdded ? 'bg-ivory' : ''
            } mb-2 ${className}`}>
            <span className={`min-w-[2rem] ${textStyle}`}>{weekDay}</span>
            <div className={cellWrapperStyle}>
              <div
                ref={isToday ? ref : undefined}
                className='flex items-center justify-end px-2 mt-1 mb-2'>
                {isToday ? (
                  <Chips
                    round
                    noClick
                    type='dense'
                    id='todayTag'
                    color='success'
                    withTick={false}
                    tag={t('MAILING_PAGE_TEXT.mailingCalendar.todayTag')}
                  />
                ) : null}
                <p className={`${textColorStyle} ${textStyle}`}>{day}</p>
              </div>
              {/* {isTailElement && remainders.length ? (
                <span className='absolute bottom-0 right-0 text-tooltips'>
                  {t('MAILING_PAGE_TEXT.mailingCalendar.reminderText', {
                    remainDayLimit,
                    daySendOutLimit,
                  })}
                </span>
              ) : null} */}
              <div className={calendarElementWrapperStyle}>{calendarElementsToRender}</div>
            </div>
          </div>
        )}
      </div>
    );
  },
);
