import React, { memo, useCallback, useState } from 'react';
//
import { createPortal } from 'react-dom';
import { Overlay } from '@atoms/overlay';
import { TimePicker } from '@uikit/DateTime/TimePicker';
import { SetTimeDropdown } from '@blocks/setTimeDropdown';
import { TOnFocusHandlerInputElement } from '@shared/types';
import { getRoundedTime, getTime, selectTextContentOnFocus } from '@helpers/index';

type TWhenTimeWithDropDownProps = {
  /**
   * Флаг отключает возможность изменения значения в блоке
   * @param {boolean}
   */
  disabled?: boolean;
  /**
   * Время в формате HH:MM
   * @param {string}
   */
  whenTime: string;
  /**
   * Опциональный параметр при передаче этого флага можно изменять только часы
   * @param {string}
   */
  isSetOnlyHour?: boolean;
  /**
   * Callback обрабатывает изменение данных в input
   * @param {(value: string) => void}
   */
  onChangeHandler: (value: string) => void;
  /**
   * Значение с которого начинается формирование списка минут
   * @param {number}
   */
  startMinutes: number;
  /**
   * Значение которым заканчивается формирование списка минут
   * @param {number}
   */
  endMinutes: number;
  /**
   * Шаг увеличения значения минут
   * @param {number}
   */
  minIncreaseStep: number;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const WhenTimeWithDropDown = memo(
  ({
    disabled,
    whenTime,
    endMinutes,
    startMinutes,
    isSetOnlyHour,
    onChangeHandler,
    minIncreaseStep,
    className = '',
  }: TWhenTimeWithDropDownProps) => {
    const [isShowDropdown, setIsShowDropdown] = useState(false);

    // срабатывает при вводе нового времени, округляет минуты до четверти часа, формирует строку времени
    // и записывает в редакс вызовом callback
    const setTimeHandler = useCallback(
      (date: Date) => {
        onChangeHandler(getRoundedTime(date, !!isSetOnlyHour, minIncreaseStep));
      },
      [onChangeHandler, isSetOnlyHour, minIncreaseStep],
    );

    const [setHour, setMinutes] = whenTime.split(':');

    // показывает выпадающий список при фокусе на TimePicker
    const onFocusHandler: TOnFocusHandlerInputElement = useCallback(
      event => {
        if (!isShowDropdown) {
          setIsShowDropdown(true);
        }
        selectTextContentOnFocus(event);
      },
      [isShowDropdown],
    );

    // показывает/скрывает выпадающий список
    const dropDownToggle = useCallback(() => {
      setIsShowDropdown(prevState => !prevState);
    }, []);

    // Обрабатывает нажатия на часы в выпадающем списке,
    // формирует новый элемент Date и записывает в него выбранные часы
    const setHourByClick = useCallback(
      (hour: string) => () => {
        const date = new Date();
        date.setHours(+hour);
        date.setMinutes(+setMinutes);
        onChangeHandler(getRoundedTime(date, !!isSetOnlyHour));
      },
      [isSetOnlyHour, setMinutes, onChangeHandler],
    );

    // Обрабатывает нажатия на минуты в выпадающем списке,
    // формирует новый элемент Date и записывает в него выбранные минуты
    const setMinutesByClick = useCallback(
      (minutes: string) => () => {
        const date = new Date();
        date.setHours(+setHour);
        date.setMinutes(+minutes);
        onChangeHandler(getRoundedTime(date, !!isSetOnlyHour));
      },
      [isSetOnlyHour, onChangeHandler, setHour],
    );

    return (
      <div className={`relative ${isShowDropdown ? 'z-[60]' : ''} ${className}`}>
        <TimePicker
          color='primary'
          useAmPm={false}
          disabled={disabled}
          precision='MINUTE'
          onFocus={onFocusHandler}
          showArrowButtons={false}
          onChange={setTimeHandler}
          value={getTime(whenTime)}
          className='border border-dGray rounded'
        />
        {isShowDropdown && !disabled ? (
          <SetTimeDropdown
            setTime={whenTime}
            endMinutes={endMinutes}
            startMinutes={startMinutes}
            setHourCallback={setHourByClick}
            minIncreaseStep={minIncreaseStep}
            setMinutesCallback={setMinutesByClick}
            closeDropdownCallback={dropDownToggle}
            className='absolute top-[2.85rem] z-[60]'
          />
        ) : null}
        {isShowDropdown && !disabled
          ? createPortal(<Overlay onClickCallback={dropDownToggle} />, document.body)
          : null}
      </div>
    );
  },
);

WhenTimeWithDropDown.displayName = 'WhenTimeWithDropDown';
