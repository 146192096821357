import React, { forwardRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import Component from './ProgressBar';

const propTypes = {
  /**
   ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    "default",
    "primary",
    "warning",
    "danger",
    "success",
    "primary_alt",
    "warning_alt",
    "danger_alt",
    "success_alt"
  ]),
  /**
   * Whether the background should be striped.
   */
  stripes: PropTypes.bool,
  /**
   * Whether the background should animate.
   */
  animate: PropTypes.bool,
  /**
   * A value between 0 and 1 (inclusive) representing how far along the operation is.
   * Values below 0 or above 1 will be interpreted as 0 or 1, respectively.
   * Omitting this prop will result in an "indeterminate" progress meter that fills the entire bar.
   */
  value: PropTypes.number
};

type TComponentPropsType = InferProps<typeof propTypes>;

interface IComponentProps extends TComponentPropsType {
  [key: string]: any;
}

export const ProgressBar: React.FC<IComponentProps> = forwardRef((props, ref) => <Component {...props} ref={ref} />);