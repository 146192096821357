import React, { memo } from 'react';
import { Icon } from '@blueprintjs/core';

interface IInfoIconProps {
  /**
   * Callback для события mouseOver, необходим для показа подсказки
   * @param {event}
   */
  onMouseOver: (event: React.MouseEvent<HTMLImageElement>) => void;
  /**
   * Callback для события mouseOut, необходим для скрытия подсказки
   * @param {event}
   */
  onMouseOut: (event: React.MouseEvent<HTMLImageElement>) => void;
  /**
   * Опциональный параметр цвет иконки
   * @param {string}
   */
  iconColor?: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const InfoIcon = memo(
  ({ onMouseOut, onMouseOver, iconColor, className = '' }: IInfoIconProps) => (
    <Icon
      icon='info-sign'
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      color={iconColor || '#37833B'}
      className={className}
    />
  ),
);

InfoIcon.displayName = 'InfoIcon';
