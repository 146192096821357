import axios from 'axios';
import { RootState } from '@store/store';
import { storageDb } from '@api/storageApi';
import { mailingApi } from '@api/mailingApi';
import { myTrackerApi } from '@api/myTrackerApi';
import { QUERY_PARAMS } from '@const/apiConstants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TRejectResponseData } from '@models/index';
import { FORM_DATA_FIELD_NAME } from '@const/mailing';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { mailingMappers } from '@redux/mailing/mailingMappers';
import { setTableDataMP } from '@redux/mailing/mailingTableSlice';
import { MY_TRACKER_EVENTS, TSendoutEventData } from '@helpers/myTracker';
import {
  TSendOutsData,
  TMailingTableData,
  TConsentCategories,
  TParsedXlsxFileData,
  TSendOutsDetailData,
  TConsentParseXLSXFileData,
} from '@redux/mailing/models';
import {
  TStartMailingData,
  REJECT_RESPONSE_KEY,
  SEND_OUT_RESPONSE_KEYS,
  TSendTestMessagePostData,
  PARSE_XLSX_FILE_RESPONSE_KEYS,
  TStartMailingPostData,
} from '@api/types';
import {
  clearForm,
  setMediaFile,
  clearClientFileName,
  setStoppingSendOutId,
  setSendOutErrorMessage,
  hideTestMessageSendPopup,
  showTestMessageSendPopup,
} from '@redux/mailing/mailingSlice';

/**
 * Thnuk-экшен для загрузки медиа-файла.
 *
 * @param {string} url - URL для загрузки медиа-файла.
 * @rejects {number} - Число, представляющее статус ошибки в случае неудачной загрузки.
 */
export const uploadMailingMediaFile = createAsyncThunk<
  void,
  { url: string },
  { rejectValue: number }
>('templateSlice/uploadMediaFile', (data, { dispatch, rejectWithValue }) => {
  if (data.url) {
    return axios
      .get(data.url, { responseType: 'arraybuffer' })
      .then(response => new Blob([response.data], { type: response.headers['content-type'] }))
      .then(imgBlob => {
        const fileName = data.url;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const file = new File([imgBlob], fileName, {
          type: imgBlob.type,
          lastModified: new Date().getTime(),
        });
        const container = new DataTransfer();
        container.items.add(file);
        dispatch(setMediaFile({ files: container.files }));
      })
      .catch(error => rejectWithValue(error));
  }
});

/**
 * Thunk получает данные для запуска рассылки и сохраняет их в хранилище.
 * @returns {TMailingTableData[] | void} - Массив данных для хранения в хранилище или undefined.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const getPaymentStatusThunk = createAsyncThunk<boolean, void, { rejectValue: TRejectResponseData }>(
  'mailing/getPaymentStatusThunk',
  (_, { dispatch, rejectWithValue }) => {
    return mailingApi
      .getPaymentStatus()
      .then(response => {
        return response.data.ok;
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(getPaymentStatusThunk()), 200);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

/**
 * Thunk получает данные для запуска рассылки и сохраняет их в хранилище.
 * @returns {TMailingTableData[] | void} - Массив данных для хранения в хранилище или undefined.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const getStartedMailingTableData = createAsyncThunk<
  TMailingTableData[] | void,
  void,
  { rejectValue: TRejectResponseData }
>('mailing/getStartedMailingTableData', (data, { dispatch, rejectWithValue }) => {
  const { userId } = storageDb.getRequestData();

  return mailingApi
    .getMailingData()
    .then(response => {
      if (REJECT_RESPONSE_KEY.STATUS in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (SEND_OUT_RESPONSE_KEYS.DATA in response.data) {
        dispatch(setTableDataMP(mailingMappers.mapResponseToStore(response.data)));

        myTrackerApi.sendoutStartedPadeOpen({
          user_id: String(userId),
          event_properties: {},
          event_name: MY_TRACKER_EVENTS.SENDOUT_STARTED_TABLE,
        });
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getStartedMailingTableData()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk для скрытия всплывающего окна с сообщением об успешной отправке тестового сообщения.
 * @param {object} helpers - Объект с дополнительными функциями, предоставляемыми Redux Toolkit.
 */
const testMessageSendSuccessPopupHandler = createAsyncThunk(
  'mailing/hideTestMessageSuccessPopup',
  (data, { dispatch }) => {
    dispatch(showTestMessageSendPopup());
    setTimeout(() => {
      dispatch(hideTestMessageSendPopup());
    }, 3000);
  },
);

/**
 * Thunk отправляет тестовое сообщение и обрабатывает результат.
 * @param {TSendTestMessagePostData} data - Данные для отправки тестового сообщения.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const sendTestMessage = createAsyncThunk<
  void,
  TSendTestMessagePostData,
  { rejectValue: TRejectResponseData }
>('mailing/sendTestMessage', (data, { dispatch, rejectWithValue }) => {
  const formData = new FormData();

  if (data.file) {
    formData.append(FORM_DATA_FIELD_NAME.MEDIA_FILE, data.file, data.file.name);
  }

  formData.append(FORM_DATA_FIELD_NAME.MESSAGE_TEXT, data.text);

  formData.append(FORM_DATA_FIELD_NAME.KEYS, JSON.stringify(data.keys));

  return mailingApi
    .postTestMessage({ formData })
    .then(response => {
      if (!response.data.ok) {
        if (REJECT_RESPONSE_KEY.ERROR in response.data) {
          dispatch(setSendOutErrorMessage(String(response.data.error)));
        }

        if (REJECT_RESPONSE_KEY.STATUS in response.data) {
          throw new Error(String(response.data.status));
        }
      }
      dispatch(testMessageSendSuccessPopupHandler());
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(sendTestMessage(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk останавливает рассылку и обрабатывает результат.
 * @param {{ sendOutId: number }} data - Данные для остановки рассылки.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const stopSendOut = createAsyncThunk<
  void,
  { sendOutId: number },
  { rejectValue: TRejectResponseData }
>('mailing/stopSendOut', (data, { dispatch, rejectWithValue }) => {
  const { userId } = storageDb.getRequestData();

  const { sendOutId } = data;
  return mailingApi
    .stopSendOut({ sendOutId: String(data.sendOutId) })
    .then(response => {
      if (REJECT_RESPONSE_KEY.STATUS in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (!response.data.ok) {
        throw new Error(String(response.data.status));
      }
      dispatch(setStoppingSendOutId({ sendOutId }));

      myTrackerApi.sendoutStop({
        user_id: String(userId),
        event_properties: {},
        event_name: MY_TRACKER_EVENTS.SENDOUT_STOP,
      });
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(stopSendOut(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

export const consentParseXLSXFile = createAsyncThunk<
  TConsentParseXLSXFileData | void,
  { formData: FormData },
  { rejectValue: TRejectResponseData }
>('mailing/consentParseXLSXFile', (data, { dispatch, rejectWithValue }) => {
  return mailingApi
    .consentXSLXFile(data)
    .then(response => {
      if ('consent_count' in response.data) {
        return mailingMappers.mapConsentParsedXLSXDataToStore(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(consentParseXLSXFile(data)), 200);
      } else {
        const errorEventData: TSendoutEventData = {
          total: 0,
          media_file: false,
          visits_median: 0,
          last_visit_time_median: 0,
          sendout_date: '',
          error_code: '',
        };

        myTrackerApi.sendoutXLSXFileUploadError({
          ...errorEventData,
          error_code: String(error.response.status),
        });
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk Отправляет XLSX файл для парсинга и обрабатывает результат.
 * @param {{ formData: FormData; successCallback: () => void  }} data - Данные для отправки XLSX файла.
 * @returns {TParsedXlsxFileData | void} - Объект с данными о парсинге XLSX файла или в undefined.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const postXLSXFileToParse = createAsyncThunk<
  TParsedXlsxFileData | void,
  { formData: FormData; successCallback: () => void; consentCategories: TConsentCategories },
  { rejectValue: TRejectResponseData }
>('mailing/postXLSXFileToParse', (data, { dispatch, rejectWithValue }) => {
  return mailingApi
    .parseXSLXFile(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.STATUS in response.data && !response.data.ok) {
        dispatch(clearClientFileName());
        throw new Error(String(response.data.status));
      }
      if (PARSE_XLSX_FILE_RESPONSE_KEYS.DATA in response.data) {
        if (data.successCallback) {
          data.successCallback();
        }

        myTrackerApi.sendoutXLSXFileUpload();

        return mailingMappers.mapParsedXLSXFileDataToStore(
          response.data.data,
          response.data.allowed_start,
          response.data.ok,
        );
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(postXLSXFileToParse(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk Запускает рассылку и обрабатывает результат.
 * @param {TStartMailingData} data - Данные для запуска рассылки.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const startSendOut = createAsyncThunk<
  boolean,
  TStartMailingData,
  { state: RootState; rejectValue: TRejectResponseData }
>('mailing/startSendOut', (data: TStartMailingData, { getState, dispatch, rejectWithValue }) => {
  const { userId } = storageDb.getRequestData();

  const {
    mediaFileName,
    sendToNeutralRecipient,
    sendToConsentingRecipients,
    parsedXSLXFileData: { count, visitsMedian, lastVisitTimeMedian },
  } = getState().mailing;

  const requestData: TStartMailingPostData = {
    ...data,
    isNeutral: sendToNeutralRecipient,
    isConsent: sendToConsentingRecipients,
  };

  return mailingApi
    .startMailing(requestData)
    .then(() => {
      dispatch(clearForm());

      if (data.callback) {
        data.callback();
      }

      if (data.startTime) {
        const newEventData: TSendoutEventData = {
          total: count,
          error_code: '',
          sendout_date: data.startTime,
          media_file: !!mediaFileName,
          visits_median: visitsMedian,
          last_visit_time_median: lastVisitTimeMedian,
        };

        myTrackerApi.createScheduledSendout(newEventData);
      } else {
        myTrackerApi.sendoutStart({
          user_id: String(userId),
          event_properties: {},
          event_name: MY_TRACKER_EVENTS.SENDOUT_START,
        });
      }

      return !!data.startTime;
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(startSendOut(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk Получает данные о запущенных рассылках для отображения их в календаре.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const getCalendarDateThunk = createAsyncThunk<
  TSendOutsData[] | void,
  void,
  { state: RootState; rejectValue: TRejectResponseData }
>('mailing/getCalendarDateThunk', (_, { getState, dispatch, rejectWithValue }) => {
  const { daySendOutLimit } = getState().mailing;
  return mailingApi
    .getCalendarData({ quote_for_day: daySendOutLimit })
    .then(response => {
      if ('not_allowed' in response.data) {
        return mailingMappers.mapCalendarDataToStore(
          response.data.not_allowed,
          response.data.sendout_list,
          daySendOutLimit,
        );
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getCalendarDateThunk()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk Получает данные о рассылках для выбранной даты.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
export const getDetailSendOudDayData = createAsyncThunk<
  { date: Date; data: TSendOutsDetailData[]; isShowWarning: boolean } | void,
  { date: Date; isShowWarning: boolean },
  { rejectValue: TRejectResponseData }
>('mailing/getDetailSendOudDayData', (data, { dispatch, rejectWithValue }) => {
  const { isShowWarning, date } = data;

  const userTimezoneOffset = date.getTimezoneOffset() * 60000;

  return mailingApi
    .getSendOutDayData(new Date(date.getTime() - userTimezoneOffset))
    .then(response => {
      if (Array.isArray(response.data)) {
        return {
          date,
          isShowWarning,
          data: mailingMappers.mapDetailSendOudDayData(response.data),
        };
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getDetailSendOudDayData(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk Проверяет возможность запустить рассылку для выбранной даты и времени.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
export const getAllowedToRun = createAsyncThunk<
  { isTimeCheck: boolean; isNewSendOutFit: boolean } | void,
  {
    date: Date;
    isTimeCheck: boolean;
    fallbackCallback: () => void;
    onSuccessAddCallback: () => void;
  },
  { state: RootState; rejectValue: TRejectResponseData }
>('mailing/getAllowedToRun', (data, { getState, dispatch, rejectWithValue }) => {
  const { date, isTimeCheck, onSuccessAddCallback, fallbackCallback } = data;
  const { mailingStartTime } = getState().mailing;

  const startDateHours = date.getHours();
  const startDateMinutes = date.getMinutes();

  const userTimezoneOffset = date.getTimezoneOffset() * 60000;

  const userStartDate = new Date(date.getTime());

  const {
    parsedXSLXFileData: { instanceId, count, sendOutBatchLen, timeToMsk },
  } = getState().mailing;

  if (startDateHours === 0 && startDateMinutes === 0) {
    const [hour, minutes] = mailingStartTime.split(':');

    userStartDate.setHours(+hour);
    userStartDate.setMinutes(+minutes);
  }

  const requestData = {
    [QUERY_PARAMS.DATE]: new Date(userStartDate.getTime() - userTimezoneOffset)
      .toJSON()
      .slice(0, -5),
    [QUERY_PARAMS.INSTANCE_ID]: instanceId,
    [QUERY_PARAMS.SENDOUT_BATCH_LEN]: sendOutBatchLen,
    [QUERY_PARAMS.COUNT]: count,
    [QUERY_PARAMS.TIME_TO_MSK]: timeToMsk,
  };

  return mailingApi
    .getAllowToRun(requestData)
    .then(response => {
      if (response.data) {
        onSuccessAddCallback();
      } else {
        fallbackCallback();
      }
      if (typeof response.data === 'boolean') {
        return {
          isTimeCheck,
          isNewSendOutFit: response.data,
        };
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getAllowedToRun(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

export {
  stopSendOut,
  startSendOut,
  sendTestMessage,
  postXLSXFileToParse,
  getCalendarDateThunk,
  getPaymentStatusThunk,
  getStartedMailingTableData,
};
