import React, { memo } from 'react';
//
import { TClickHandler } from '@shared/types';

type TKeyTextProps = {
  /**
   * Название спец ключа
   * @param {string}
   */
  keyName: string;
  /**
   * Callback срабатывает н клик по ключу
   * @param {TClickHandler}
   */
  callBack: TClickHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

// компонент отображает спец ключи для формирования стилей отображения текста в сообщениях и рассылках или ключей с информацией
export const KeyText = memo(({ keyName, callBack, className = '' }: TKeyTextProps) => {
  const key = `${keyName}`;

  return (
    <span className={`cursor-pointer hover:italic ${className}`} onClick={callBack}>
      {key}&nbsp;-
    </span>
  );
});

KeyText.displayName = 'KeyText';
