export const LINK_TO_INSTRUCTION = {
  text: 'инструкции',
  link: 'https://telegra.ph/%E2%84%B9-Avtootvety-02-02',
};

export const LINK_TO_INSTRUCTION_ENG = {
  text: 'instructions',
  link: 'https://telegra.ph/%E2%84%B9-Avtootvety-02-02',
};

export const AUTO_RESPONSE_TOAST_MESSAGES = {
  SUCCESS: 'Автоответ создан',
  SUCCESS_UPDATE: 'Автоответ обновлен',
  SUCCESS_DELETED: 'Автоответ удален',
  ENTERED_DATA_ERROR: 'Заполните все поля',
  TOO_LONG_RESPONSE: `Текст автоответа превышает\n максимально возможную длину`,
};

export const AUTO_RESPONSE_TOAST_MESSAGES_ENG = {
  SUCCESS: 'Auto reply created',
  SUCCESS_UPDATE: 'Auto-reply updated',
  SUCCESS_DELETED: 'Auto-reply deleted',
  ENTERED_DATA_ERROR: 'Please fill in all fields',
  TOO_LONG_RESPONSE: `The auto-reply text exceeds\n the maximum possible length`,
};

export const AUTO_RESPONSE_MESSAGES = {
  SUCCESS: 'AUTO_RESPONSE_PAGE_TEXT.AUTO_RESPONSE_TOAST_MESSAGES.SUCCESS',
  SUCCESS_UPDATE: 'AUTO_RESPONSE_PAGE_TEXT.AUTO_RESPONSE_TOAST_MESSAGES.SUCCESS_UPDATE',
  SUCCESS_DELETED: 'AUTO_RESPONSE_PAGE_TEXT.AUTO_RESPONSE_TOAST_MESSAGES.SUCCESS_DELETED',
  ENTERED_DATA_ERROR: 'AUTO_RESPONSE_PAGE_TEXT.AUTO_RESPONSE_TOAST_MESSAGES.ENTERED_DATA_ERROR',
  TOO_LONG_RESPONSE: `AUTO_RESPONSE_PAGE_TEXT.AUTO_RESPONSE_TOAST_MESSAGES.TOO_LONG_RESPONSE`,
};

export const NEW_AUTO_RESPONSE_ID = 'NEW_AUTO_RESPONSE_ID';

export const AUTO_RESPONSE_MAX_LENGTH = 2000;

export const AUTO_RESPONSE_PAGE_TEXT = {
  header: 'Автоответы',
  createAutoResponseButton: 'Создать автоответ',
  pageDescription:
    'На данной странице Вы можете настроить автоответы на слова во входящих сообщениях. \n Например, при слове "Прайс" бот может отправлять клиенту ваши цены.\n Подробнее смотрите в',
  clientRequest: 'Символ (ответ клиента)',
  clientRequestPlaceholder: 'Символ (ответ клиента)',
  deleteButton: 'Удалить автоответ',
  saveButton: 'Сохранить',
  responseToClientPlaceholder: 'Введите текст',
  noAutoResponseItems: 'Нет настроенных автоответов',
  LINK_TO_INSTRUCTION,
  AUTO_RESPONSE_TOAST_MESSAGES,
};

export const AUTO_RESPONSE_PAGE_TEXT_ENG = {
  header: 'Auto-responses',
  createAutoResponseButton: 'Create auto response',
  pageDescription:
    'On this page you can set up automatic replies to words in incoming messages. \n For example, when the word “Price” is used, the bot can send your prices to the client.\n For more details, see',
  clientRequest: 'Symbol (client response)',
  clientRequestPlaceholder: 'Symbol (client response)',
  deleteButton: 'Delete auto-reply',
  saveButton: 'Save',
  responseToClientPlaceholder: 'Enter text',
  noAutoResponseItems: 'No auto replies configured',
  LINK_TO_INSTRUCTION: LINK_TO_INSTRUCTION_ENG,
  AUTO_RESPONSE_TOAST_MESSAGES: AUTO_RESPONSE_TOAST_MESSAGES_ENG,
};
