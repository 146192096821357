import { apiService } from '@api/ApiService';
import { END_POINTS_URL, QUERY_PARAMS } from '@const/apiConstants';
import { TAutoResponseItemActivityCheckData } from '@redux/autoResponse/models';
import {
  TRequestStatus,
  TGetAutoResponseData,
  TAutoResponseItemData,
  TUpdateAutoResponseItemSendData,
} from '@api/types';

/**
 * API страницы автоответов
 * @module autoResponseApi
 */

export const autoResponseApi = {
  /**
   * Метод выполняет запрос за данными страницы
   * @method getAutoResponseData
   * @return {Promise<AxiosResponse<TGetAutoResponseData | TRequestStatus, any>>}
   */
  getAutoResponseData() {
    return apiService.get<TGetAutoResponseData | TRequestStatus>(END_POINTS_URL.AUTO_RESPONSE);
  },
  /**
   * Метод отправляет POST запрос для создания нового блока автоответа
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   * @param data {TAutoResponseItemData}
   */
  createAutoResponse(data: TAutoResponseItemData) {
    return apiService.post<TRequestStatus>(END_POINTS_URL.AUTO_RESPONSE_ADD_ITEM, data);
  },

  /**
   * Метод отправляет PATCH запрос для изменения данных в блоке автоответа
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   * @param data {TUpdateAutoResponseItemData}
   */
  updateAutoResponseItem(data: TUpdateAutoResponseItemSendData) {
    return apiService.patch<TRequestStatus>(END_POINTS_URL.AUTO_RESPONSE_UPDATE_ITEM, data);
  },

  /**
   * Метод отправляет DELETE запрос для удаления блока автоответа
   * @return {Promise<AxiosResponse<TRequestStatus | TAutoResponseItem[], any>>}
   * @param clientRequest {string}
   */
  deleteAutoResponseItem(clientRequest: string) {
    return apiService.delete<TRequestStatus>(
      END_POINTS_URL.AUTO_RESPONSE_DELETE_ITEM,
      {},
      {
        params: {
          [QUERY_PARAMS.AUTO_RESPONSE_WORD]: clientRequest,
        },
      },
    );
  },
  /**
   * Метод отправляет PATCH запрос для изменения активности блока автоответа
   * @return {Promise<AxiosResponse<TRequestStatus | TAutoResponseItem[], any>>}
   * @param data {TAutoResponseItemActivityCheckData}
   */
  autoResponseItemActivitySwitch(data: TAutoResponseItemActivityCheckData) {
    return apiService.patch<TRequestStatus>(
      END_POINTS_URL.AUTO_RESPONSE_ACTIVITY_SWITCH,
      data.clientRequest,
      {
        params: {
          [QUERY_PARAMS.AUTO_RESPONSE_ACTIVE]: data.isActive,
        },
      },
    );
  },
};
