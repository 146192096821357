import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@uikit/Button';
import { Icon } from '@blueprintjs/core';
import { Spinner } from '@uikit/Spinner';
import icon from '@img/connect/telegram.svg';
import { TOnChangeHandler } from '@shared/types';
import { ChannelHeader } from '@blocks/channelHeader';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { PhoneNumberInput } from '@atoms/phoneNumberInput';
import { TELEGRAM_BOT_CONNECTION_STEPS } from '@const/connect';
import { TgChanelPinInput } from '@components/tgChanelPinInput';
import { TConnectionTGStatusData } from '@redux/connection/types';
import { PhoneNumberWithChangeButton } from '@blocks/phoneNumberWithChangeButton';

type TConnectionTgBotSetUpProps = {
  /**
   * Тест заголовка
   * @param {string}
   */
  headerText: string;
  /**
   * Текст пояснений в карточку
   * @param {string}
   */
  descriptionText: string;
  /**
   * Callback для изменения значения инпута
   * @param {(phone: string) => void}
   */
  setPhoneNumberHandler: (phone: string) => void;
  /**
   * Callback для отправки кода авторизации на введенный номер
   * @param {() => void}
   */
  sendCodeButtonHandler: () => void;
  /**
   * Callback для перевода формы настройки на шаг ввода телефона
   * @param {() => void}
   */
  changePhoneNumberHandler: () => void;
  /**
   * Callback для изменения значения инпута
   * @param {(value: string) => void}
   */
  setPinHandler: (value: string) => void;
  /**
   * Callback для изменения значения инпута
   * @param {TOnChangeHandler}
   */
  setPasswordHandler: TOnChangeHandler;
  /**
   * Callback для отправки кода двух этапной авторизации
   * @param {() => void}
   */
  connectButtonHandler: () => void;
  /**
   * Callback для переключения формы на первый шап, для повторного подключения бота
   * @param {() => void}
   */
  restartConnectionHandler: () => void;
  /**
   * Данные TG бота
   * @param {TConnectionWAStatusData}
   */
  tgData: TConnectionTGStatusData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const ConnectionTgBotSetUp = memo(
  ({
    headerText,
    setPinHandler,
    descriptionText,
    setPasswordHandler,
    connectButtonHandler,
    sendCodeButtonHandler,
    setPhoneNumberHandler,
    changePhoneNumberHandler,
    restartConnectionHandler,
    tgData,
    className = '',
  }: TConnectionTgBotSetUpProps) => {
    const { t } = useTranslation();

    const { pin, phone, password, stepNumber, isInputError, requestStatus, errorMessage } = tgData;

    const [isHidePassword, setIsHidePassword] = useState(true);

    const onKeyUpHandler = useCallback(() => {
      sendCodeButtonHandler();
    }, [sendCodeButtonHandler]);

    const passwordVisibleToggle = useCallback(() => {
      setIsHidePassword(prevState => !prevState);
    }, []);

    const isLoading = !(requestStatus === 'success' || requestStatus === 'error');

    const isHideDescriptionText = stepNumber === TELEGRAM_BOT_CONNECTION_STEPS.INPUT_ERROR;

    return (
      <div className={`flex flex-col grow justify-between ${className}`}>
        {!(
          stepNumber === TELEGRAM_BOT_CONNECTION_STEPS.LOADING_NEW_STATUS ||
          stepNumber === TELEGRAM_BOT_CONNECTION_STEPS.ERROR
        ) ? (
          <div className='mb-1'>
            <ChannelHeader
              iconSrc={icon}
              headerText={headerText}
              subheaderText={isHideDescriptionText ? '' : descriptionText}
            />
          </div>
        ) : null}
        {stepNumber === TELEGRAM_BOT_CONNECTION_STEPS.FIRST ? (
          <div className='flex flex-col'>
            <div className='flex flex-col mr-2 grow'>
              <p className='font-medium text-blackText text-[1.25rem] leading-8 tracking-[0.004em] my-6'>
                {t('CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpBlock.connectByPhone')}
              </p>
              <span className='text-grayText leading-6 tracking-[0.0275em] mb-0.5'>
                {t('CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpBlock.inputLabel')}
              </span>
              <div className='flex flex-col sm:flex-row grow'>
                <div className='flex flex-col grow'>
                  <PhoneNumberInput
                    value={phone}
                    placeholder=''
                    onBlur={onKeyUpHandler}
                    onChangeHandler={setPhoneNumberHandler}
                  />
                  {isInputError && !errorMessage ? (
                    <span className='text-small_text text-auChico leading-4 tracking-[0.033em]'>
                      {t('CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpBlock.inputError')}
                    </span>
                  ) : null}
                  {isInputError && errorMessage ? (
                    <span className='text-small_text text-auChico leading-4 tracking-[0.033em]'>
                      {errorMessage}
                    </span>
                  ) : null}
                </div>
                <div className='mt-4 sm:mt-0 sm:ml-2 sm:mb-0.5 min-w-[9.7rem]'>
                  <Button
                    fill
                    dense
                    type='action'
                    view='filled'
                    color='success'
                    disabled={false}
                    loading={isLoading}
                    onClick={sendCodeButtonHandler}
                    text={t(
                      'CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpBlock.sendCodeButton',
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {stepNumber === TELEGRAM_BOT_CONNECTION_STEPS.SECOND ? (
          <div className='flex flex-col items-start'>
            <p className='font-medium text-blackText text-[1.25rem] leading-8 tracking-[0.004em] my-6'>
              {t('CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpBlock.connectByPhone')}
            </p>
            <PhoneNumberWithChangeButton
              phoneNumber={phone}
              changePhoneNumberHandler={changePhoneNumberHandler}
              className='mb-4'
            />
            <p className='text-gray2 leading-6 tracking-[0.0275em] mb-6'>
              {t('CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpBlock.pinInputLabel')}
            </p>
            <div>
              <TgChanelPinInput
                pin={pin}
                disabled={isLoading}
                isError={isInputError}
                onChangeCallback={setPinHandler}
                className='mb-1'
              />
              {isInputError ? (
                <span className='text-small_text text-auChico leading-4 tracking-[0.033em]'>
                  {t('CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpBlock.codeError')}
                </span>
              ) : null}
            </div>
          </div>
        ) : null}
        {stepNumber === TELEGRAM_BOT_CONNECTION_STEPS.THIRD ? (
          <div className='flex flex-col items-start'>
            <p className='font-medium text-blackText text-[1.25rem] leading-8 tracking-[0.004em] my-6'>
              {t('CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpBlock.connectByPhone')}
            </p>
            <PhoneNumberWithChangeButton
              phoneNumber={phone}
              changePhoneNumberHandler={changePhoneNumberHandler}
              className='mb-4'
            />
            <p className='text-gray2 leading-6 tracking-[0.0275em] mb-4'>
              {t('CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpBlock.passwordDescription')}
            </p>
            <span className='text-grayText leading-6 tracking-[0.0275em] mb-0.5'>
              {t('CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpBlock.passwordLabel')}
            </span>
            <div className='flex flex-col sm:flex-row w-full'>
              <div className='relative flex items-center grow'>
                <TextInput
                  fill
                  color='default'
                  view='outlined'
                  value={password}
                  onChange={setPasswordHandler}
                  type={isHidePassword ? 'password' : 'text'}
                  placeholder={t(
                    'CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpBlock.passwordPlaceholder',
                  )}
                />
                <Icon
                  size={20}
                  color='#9696A0'
                  onClick={passwordVisibleToggle}
                  icon={isHidePassword ? 'eye-off' : 'eye-open'}
                  className='absolute right-2.5 cursor-pointer'
                />
              </div>
              <div className='mt-4 sm:mt-0 sm:ml-2 mb-0.5 min-w-[9.7rem]'>
                <Button
                  fill
                  dense
                  type='action'
                  view='outlined'
                  color='default'
                  disabled={false}
                  loading={isLoading}
                  onClick={connectButtonHandler}
                  text={t('CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpBlock.connectButton')}
                  className='!bg-white'
                />
              </div>
            </div>
          </div>
        ) : null}
        {stepNumber === TELEGRAM_BOT_CONNECTION_STEPS.LOADING_NEW_STATUS ? (
          <div className='flex flex-col grow items-center justify-center w-full'>
            <Spinner view='smooth' color='success' value={undefined} size='40' />
          </div>
        ) : null}
        {stepNumber === TELEGRAM_BOT_CONNECTION_STEPS.INPUT_ERROR ? (
          <div className='flex grow'>
            <div className='flex flex-col items-start justify-start mr-2 grow'>
              <p className='text-red2 leading-6 tracking-[0.0275em] my-6'>
                {t('CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpBlock.pinErrorDescription')}
              </p>
              <Button
                dense
                type='action'
                view='outlined'
                color='default'
                icon='refresh'
                disabled={false}
                onClick={restartConnectionHandler}
                text={t(
                  'CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpBlock.restartConnectionButton',
                )}
                className='!bg-white'
              />
            </div>
          </div>
        ) : null}
        {stepNumber === TELEGRAM_BOT_CONNECTION_STEPS.ERROR ? (
          <div className='flex'>
            <div className='flex flex-col mr-2 grow'>
              <h1 className='font-medium text-blackText text-h6Mobile leading-8 tracking-[0.004em] mb-1'>
                {t('CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpBlock.requestErrorLabel')}
              </h1>
              <p className='text-red2 leading-6 tracking-[0.0275em]'>
                {t(
                  'CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpBlock.requestErrorDescription',
                )}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    );
  },
);

ConnectionTgBotSetUp.displayName = 'ConnectionTgBotSetUp';
