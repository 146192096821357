import React, { forwardRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import Component from './SearchInput';

const propTypes = {
  /**
   `The type of the component.
   * Variants: `dense`
   * Default value (if undefined): `def` `
   */
  type: PropTypes.oneOf(["def", "dense"]),
  /**
   ` The view of the component.
   * Variants: `outlined` `raised`
   * Default value (if undefined): `smooth` `
   */
  view: PropTypes.oneOf(["smooth", "outlined", "raised", "filled"]),
  /**
   * Placeholder text in the absence of any value.
   */
  placeholder: PropTypes.string,
  /**
   * Form value of the input
   */
  value: PropTypes.string,
  /**Your custom search function */
  onSearch: PropTypes.func,
  /**If `true`, the component will take up the full width of its container. */
  fill: PropTypes.bool,
  onChange: PropTypes.func,
  clearSearchString: PropTypes.func,
};

type TComponentPropsType = InferProps<typeof propTypes>;

interface IComponentProps extends TComponentPropsType {
  [key: string]: any;
}

export const SearchInput: React.FC<IComponentProps> = forwardRef((props: IComponentProps, ref) => <Component {...props} ref={ref} />);