import { TMessages } from './types';

export interface IInitialState {
  searchString: string;
  totalMobilePages: number;
  pageSize: number;
  currentMobilePage: number;
  mobileData: TMessages[];
  filteredData: TMessages[];
  data: TMessages[];
}

export const initialState: IInitialState = {
  searchString: '',
  pageSize: 3,
  totalMobilePages: 0,
  currentMobilePage: 1,
  mobileData: [],
  filteredData: [],
  data: [],
};
