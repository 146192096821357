import React, { memo, useEffect, useMemo, useRef } from 'react';
//
import { Menu } from '@uikit/Menu';
import { Button } from '@uikit/Button';
import { Divider } from '@uikit/Divider';
import { getTimeList } from '@helpers/index';
import { MenuItem } from '@uikit/Menu/MenuItem';

type TSetTimeDropdownProps = {
  /**
   * Строка с установленным временем
   * @param {string}
   */
  setTime: string;
  /**
   * Шаг увеличения значения минут
   * @param {number}
   */
  minIncreaseStep: number;
  /**
   * Значение с которого начинается формирование списка минут
   * @param {number}
   */
  startMinutes: number;
  /**
   * Значение которым заканчивается формирование списка минут
   * @param {number}
   */
  endMinutes: number;
  /**
   * Callback для установки часов
   * @param {(hour: string) => () => void}
   */
  setHourCallback: (hour: string) => () => void;
  /**
   * Callback для установки минут
   * @param {(minutes: string) => () => void}
   */
  setMinutesCallback: (minutes: string) => () => void;
  /**
   * Callback для закрытия окна
   * @param {() => void}
   */
  closeDropdownCallback: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const SetTimeDropdown = memo(
  ({
    setTime,
    endMinutes,
    startMinutes,
    minIncreaseStep,
    setHourCallback,
    setMinutesCallback,
    closeDropdownCallback,
    className = '',
  }: TSetTimeDropdownProps) => {
    const [setHour, setMinutes] = setTime.split(':');

    const { hour, minutes } = getTimeList(minIncreaseStep, startMinutes, endMinutes);

    const hourDiv = useRef<HTMLDivElement>(null);
    const minutesDiv = useRef<HTMLDivElement>(null);

    const refHour = useRef<HTMLDivElement>(null);
    const refMinutes = useRef<HTMLDivElement>(null);

    // эффект осуществляет центровку выбранного значения в выпадающем списке
    useEffect(() => {
      if (hourDiv.current && refHour.current) {
        const { offsetTop } = refHour.current;

        hourDiv.current.scrollTop = offsetTop - 120;
      }

      if (minutesDiv.current && refMinutes.current) {
        const { offsetTop } = refMinutes.current;

        minutesDiv.current.scrollTop = offsetTop - 120;
      }
    });

    // формируем массив элементов для отображения списка часов
    const hourMenuItemList = useMemo(
      () =>
        hour.map(item => (
          <div key={item} ref={item === setHour ? refHour : null}>
            <MenuItem
              text={<span>{item}</span>}
              onClick={setHourCallback(item)}
              className={`${item === setHour ? 'bg-lightGray' : ''}`}
            />
          </div>
        )),
      [hour, setHour, setHourCallback],
    );

    // формируем массив элементов для отображения списка минут
    const minutesMenuItemList = useMemo(
      () =>
        minutes.map(item => (
          <div key={item} ref={item === setMinutes ? refMinutes : null}>
            <MenuItem
              text={<span>{item}</span>}
              onClick={setMinutesCallback(item)}
              className={`${item === setMinutes ? 'bg-lightGray' : ''} m-0`}
            />
          </div>
        )),
      [minutes, setMinutes, setMinutesCallback],
    );

    return (
      <div className={className}>
        <Menu type='def' view='raised' color='default' className='h-[19.1rem] min-w-[7.5rem]'>
          <div className='flex pt-2 h-[15rem]'>
            <div
              ref={hourDiv}
              className='dropdown-list-scrollbar dropdown-list-scrollbar-tiny overflow-y-auto'>
              {hourMenuItemList}
            </div>
            <div
              ref={minutesDiv}
              className='dropdown-list-scrollbar dropdown-list-scrollbar-tiny overflow-y-auto'>
              {minutesMenuItemList}
            </div>
          </div>
          <Divider color='default' colorStep={0} size={1} className='my-2' />
          <div className='flex justify-end px-2 pb-2 w-full'>
            <Button
              text='Ok'
              type='default'
              view='outlined'
              color='success'
              onClick={closeDropdownCallback}
            />
          </div>
        </Menu>
      </div>
    );
  },
);

SetTimeDropdown.displayName = 'SetTimeDropdown';
