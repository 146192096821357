import React, { memo, useCallback, useRef, useState } from 'react';
//
import { H1 } from '@atoms/h1';
import { createPortal } from 'react-dom';
import { EVENT_KEY_NAMES } from '@models/index';
import { TOnChangeHandler } from '@shared/types';

type THeaderWithEditProps = {
  /**
   * ID шаблона/цепочки
   * @param {string}
   */
  id: string;
  /**
   * Старое значение названия шаблона/цепочки
   * @param {string}
   */
  oldTitle: string;
  /**
   * Значение названия шаблона по умолчанию, подставляется если
   * @param {string}
   */
  emptyTitleName: string;
  /**
   * Callback для установки названия шаблона/цепочки, используется для очисти инпута с новым названием цепочки
   * @param value
   */
  setTitle: (value: string) => void;
  /**
   * Callback для обработки изменения oldTitle
   * @param value
   */
  setOldTitle: (value: string) => void;
  /**
   * Скрывает id
   * @param {boolean}
   */
  isHideId?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const HeaderWithEdit = memo(
  ({
    id,
    oldTitle,
    setTitle,
    setOldTitle,
    emptyTitleName,
    isHideId = false,
    className = '',
  }: THeaderWithEditProps) => {
    const ref = useRef<HTMLInputElement>(null);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const editHeaderHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;

        setOldTitle(value);
      },
      [setOldTitle],
    );

    const selectToRef = useCallback(() => ref.current && ref.current.select(), []);

    const headerEditToggle = useCallback(() => {
      setTimeout(() => selectToRef(), 100);

      setIsEdit(prevState => !prevState);

      if (isEdit && !oldTitle) {
        setOldTitle(emptyTitleName);
        setTitle('');
      }
    }, [emptyTitleName, isEdit, oldTitle, selectToRef, setOldTitle, setTitle]);

    const headerEditCloseByEnterKeyHandler = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = event;

        if (key === EVENT_KEY_NAMES.ENTER) {
          headerEditToggle();
        }
      },
      [headerEditToggle],
    );

    return (
      <div className={className}>
        {isEdit ? (
          <input
            ref={ref}
            spellCheck
            value={oldTitle}
            onChange={editHeaderHandler}
            onKeyUp={headerEditCloseByEnterKeyHandler}
            className='relative font-inter text-[1.5rem] lg:text-h1 md:text-h2Mobile font-bold text-blackHeader tracking-[-0.01rem] z-[51]'
          />
        ) : null}
        {!isEdit ? (
          <H1 onClick={headerEditToggle} className='cursor-edit tracking-[-0.01em]'>
            {oldTitle}
          </H1>
        ) : null}
        {isEdit
          ? createPortal(
              <div
                onClick={headerEditToggle}
                className='absolute top-0 right-0 bottom-0 left-0 z-[50]'
              />,
              document.body,
            )
          : null}
        {+id && !isHideId ? <p className='text-grayText'>№{id}</p> : null}
      </div>
    );
  },
);

HeaderWithEdit.displayName = 'HeaderWithEdit';
