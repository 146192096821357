import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Icon } from '@atoms/icon';

type TMailingFirstStepWarningProps = {
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const MailingFirstStepWarning = memo(({ className = '' }: TMailingFirstStepWarningProps) => {
  const { t } = useTranslation();

  const textStyle = 'text-black leading-6 tracking-[0.0275em]';

  const iconWrapperStyle = 'flex items-start mr-2';

  const iconStyle = 'p-1 min-h-[1.75rem] min-w-[1.75rem]';

  return (
    <div className={className}>
      <h2 className='text-red2 font-bold text-h6Mobile tracking-[0.0275em] leading-8 mb-2'>
        {t('MAILING_PAGE_TEXT.mailingSecondStepWarning.title')}
      </h2>
      <div className='flex mb-2'>
        <div className={iconWrapperStyle}>
          <Icon variant='errorRed' className={iconStyle} />
        </div>
        <p className={textStyle}>{t('MAILING_PAGE_TEXT.mailingSecondStepWarning.description')}</p>
      </div>
      <div className='flex'>
        <div className={iconWrapperStyle}>
          <Icon variant='errorRed' className={iconStyle} />
        </div>
        <p className={textStyle}>{t('MAILING_PAGE_TEXT.mailingSecondStepWarning.description2')}</p>
      </div>
    </div>
  );
});

MailingFirstStepWarning.displayName = 'MailingSecondStepWarning';
