import { createAsyncThunk } from '@reduxjs/toolkit';
import { REJECT_RESPONSE_KEY } from '@api/types';
import { TRejectResponseData } from '@models/index';
import { partnerProgramApi } from '@api/partnerProgramApi';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { TPayHistorySalonTableData } from '@redux/payHistorySalonTable/models';
import { mapGetPayHistorySalonTableData } from '@redux/payHistorySalonTable/payHistorySalonTableMappers';

/**
 * Thunk Получает данные таблицы истории выплат для салона и обрабатывает результат.
 * @returns {TPayHistorySalonTableData[] | void} - Данные таблицы истории выплат для салона или в undefined.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const getPayHistorySalonTableData = createAsyncThunk<
  TPayHistorySalonTableData[] | void,
  void,
  { rejectValue: TRejectResponseData }
>('payHistorySalonTableSlice/getPayHistorySalonTableData', (_, { dispatch, rejectWithValue }) => {
  return partnerProgramApi
    .getPayHistorySalonTableData()
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(response.data.status);
      }
      if ('data' in response.data) {
        return mapGetPayHistorySalonTableData(response.data.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getPayHistorySalonTableData()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

export { getPayHistorySalonTableData };
