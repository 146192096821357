import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@uikit/Dialog';
import { Button } from '@uikit/Button';

type TProps = {
  /**
   * Флаг открытия модального окна
   * @param {boolean}
   */
  isOpen: boolean;
  /**
   * Скрывает модальное окно
   * @param {() => void}
   */
  hideModal: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const InviteFriendActivateModal = memo(({ isOpen, hideModal, className = '' }: TProps) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Dialog
        Dialog
        type='default'
        view='raised'
        color='default'
        title={t('INVITE_FRIEND_PAGE_TEXT.warningModal.title')}
        text={<span>{t('INVITE_FRIEND_PAGE_TEXT.warningModal.activateWarningMessage')}</span>}
        backdropOpacity={40}
        rightButton={
          <Button
            dense
            type='action'
            view='outlined'
            color='default'
            onClick={hideModal}
            text={t('INVITE_FRIEND_PAGE_TEXT.warningModal.cancelButton')}
          />
        }
        isOpen={isOpen}
        onClose={hideModal}
      />
    </div>
  );
});

InviteFriendActivateModal.displayName = 'InviteFriendActivateModal';
