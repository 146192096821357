import React, { memo } from 'react';
//
import { Spinner } from '@uikit/Spinner';
import { MailingTable } from '@blocks/mailingTable';
import { stopSendOut } from '@redux/mailing/mailingThunks';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getMailingData, getMailingTableData } from '@redux/mailing/selectors';

interface IStartedMailingProps {
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const StartedMailing = memo(({ className = '' }: IStartedMailingProps) => {
  const dispatch = useAppDispatch();

  const { isStoppingSendOut } = useAppSelector(getMailingData);

  const { isFetchingMP } = useAppSelector(getMailingTableData);

  // Останавливает запущенные рассылки отправкой запроса на бэк с ID рассылки
  const stopSendOutHandler = (sendOutId: number) => {
    dispatch(stopSendOut({ sendOutId }));
  };

  // Ставит на паузу запущенную рассылку
  // const pauseSendOutHandler = (sendOutId: number) => {
  const pauseSendOutHandler = () => {
    // console.log(sendOutId);
  };

  // Запускает ранее остановленную рассылку
  // const startPausedSendOutHandler = (sendOutId: number) => {
  const startPausedSendOutHandler = () => {
    // console.log(sendOutId);
  };

  return (
    <div className={`relative ${className}`}>
      <MailingTable
        stopSendOut={stopSendOutHandler}
        pauseSendOut={pauseSendOutHandler}
        isStoppingSendOut={isStoppingSendOut}
        startPausedSendOut={startPausedSendOutHandler}
      />
      {isFetchingMP ? (
        <Spinner
          size={40}
          color='default'
          className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'
        />
      ) : null}
    </div>
  );
});
