import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Menu } from '@uikit/Menu';
import { Visits } from '@blocks/visits';
import { Callout } from '@uikit/Callout';
import { Icon } from '@blueprintjs/core';
import { Dropdown } from '@uikit/Dropdown';
import { useAppDispatch } from '@store/store';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { TAppLanguage, THelpKeys } from '@models/index';
import { BONUS_TYPE, BONUSES } from '@const/inviteFriend';
import { IconWithTooltips } from '@components/iconWithTooltips';
import { TOnChangeHandler, TOnFocusHandlerInputElement } from '@shared/types';
import { InviteFriendsNoCardCallout } from '@blocks/inviteFriendsNoCardCallout';
import {
  TCardType,
  TBonusValueInputs,
  ACTION_DATA_KEY_NAMES,
} from '@redux/inviteFriendsAction/type';
import {
  setBonusType,
  setBonusesValues,
  setRangeOfVisitsIF,
  setSelectedLoyaltyCard,
} from '@redux/inviteFriendsAction/inviteFriendSlice';

type TInviteFriendsBonusesProps = {
  /**
   * Выбранный бонус
   * @param {string}
   */
  bonusType: string;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguages: TAppLanguage;
  /**
   * Баллы начисляемые новому клиенту при первом посещении
   * @param {string}
   */
  newCustomerPoints: string;
  /**
   * Баллы начисляемые постоянному клиенту
   * @param {string}
   */
  loyaltyPoints: string;
  /**
   * Процент постоянному клиенту за оплату услуг новым клиентом
   * @param {string}
   */
  interestRegularCustomer: string;
  /**
   * Данные выбранной карты лояльности
   * @param {TCardType}
   */
  selectedLoyaltyCard: TCardType;
  /**
   * Данные кард лояльности
   * @param {TCardType[]}
   */
  loyaltyCardData: TCardType[];
  /**
   * Минимальная стоимость услуги в салоне
   * @param {string}
   */
  minimalPrice: string;
  /**
   * Значение счетчика визитов
   * @param {string}
   */
  rangeOfVisits: string;
  /**
   * Опциональны параметр доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const InviteFriendsBonuses = memo(
  ({
    bonusType,
    appLanguages,
    minimalPrice,
    rangeOfVisits,
    loyaltyPoints,
    loyaltyCardData,
    newCustomerPoints,
    selectedLoyaltyCard,
    interestRegularCustomer,
    isEditTemplatesAccess = false,
    className = '',
  }: TInviteFriendsBonusesProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    // Устанавливает тип выбранной карточки
    const setLoyaltyCardHandler = useCallback(
      (card: TCardType) => () => {
        dispatch(setSelectedLoyaltyCard(card));
      },
      [dispatch],
    );

    // Устанавливает тип выбранного бонуса
    const setBonusTypeHandler = useCallback(
      (bonus: string) => () => {
        dispatch(setBonusType(bonus));
      },
      [dispatch],
    );

    // Обрабатывает ввод новых значений в инпуты
    const inputOnChangeHandler: TOnChangeHandler = useCallback(
      event => {
        const { name, value } = event.currentTarget;

        dispatch(setBonusesValues({ name: name as TBonusValueInputs, value }));
      },
      [dispatch],
    );

    // Обрабатывает изменения в инпуте
    const inputOnClickHandler: TOnFocusHandlerInputElement = useCallback(event => {
      event.currentTarget.select();
    }, []);

    // Устанавливает кол-во визитов
    const setVisitsHandler = useCallback(
      (value: string) => {
        dispatch(setRangeOfVisitsIF({ value }));
      },
      [dispatch],
    );

    // Массив элементов для дропдауна выбора карточек
    const loyaltyCardList = useMemo(
      () =>
        loyaltyCardData.map(card => (
          <MenuItem
            key={card.id}
            text={<span>{card.title}</span>}
            onClick={setLoyaltyCardHandler(card)}
            className={selectedLoyaltyCard.id === card.id ? '!bg-lightGray' : ''}
          />
        )),
      [loyaltyCardData, selectedLoyaltyCard.id, setLoyaltyCardHandler],
    );

    // Массив элементов для дропдауна выбора бонусов
    const bonusTypeList = useMemo(
      () =>
        Object.entries(BONUS_TYPE[appLanguages]).map(([key, value]) => (
          <MenuItem
            key={key}
            text={<span>{value}</span>}
            onClick={setBonusTypeHandler(key)}
            className={bonusType === key ? '!bg-lightGray' : ''}
          />
        )),
      [appLanguages, bonusType, setBonusTypeHandler],
    );

    const dropdownData: {
      header: string;
      tooltipType: THelpKeys;
      selectedValueText: string;
      dropdownList: JSX.Element[];
    }[] = useMemo(
      () => [
        {
          header: `${t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.cardType')}*`,
          tooltipType: 'cardType',
          selectedValueText: selectedLoyaltyCard.title,
          dropdownList: loyaltyCardList,
        },
        {
          header: `${t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.bonusType')}*`,
          tooltipType: 'bonusType',
          selectedValueText: BONUS_TYPE[appLanguages][bonusType],
          dropdownList: bonusTypeList,
        },
      ],
      [t, appLanguages, bonusType, bonusTypeList, loyaltyCardList, selectedLoyaltyCard.title],
    );

    const inputsData: Record<
      string,
      {
        label: string;
        id: string;
        name: string;
        value: string;
        margin: string;
        description: string;
        tooltipType: THelpKeys;
      }[]
    > = useMemo(
      () => ({
        [BONUSES.NOT_SELECTED]: [],
        [BONUSES.FIRST_VISITS]: [
          {
            label: t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.inviteFriends'),
            tooltipType: 'inviteFriends',
            id: ACTION_DATA_KEY_NAMES.LOYALTY_POINTS,
            name: ACTION_DATA_KEY_NAMES.LOYALTY_POINTS,
            value: loyaltyPoints,
            description: t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.pointsDescription'),
            margin: 'mb-6',
          },
          {
            label: t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.newCustomerPoints'),
            tooltipType: 'newCustomerPoints',
            id: ACTION_DATA_KEY_NAMES.NEW_CUSTOMER_POINTS,
            name: ACTION_DATA_KEY_NAMES.NEW_CUSTOMER_POINTS,
            value: newCustomerPoints,
            description: t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.pointsDescription'),
            margin: 'mb-4',
          },
        ],
        [BONUSES.PERCENTS]: [
          {
            label: t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.interestRegularCustomer'),
            tooltipType: 'interestRegularCustomer',
            id: ACTION_DATA_KEY_NAMES.INTEREST_REGULAR_CUSTOMER,
            name: ACTION_DATA_KEY_NAMES.INTEREST_REGULAR_CUSTOMER,
            value: interestRegularCustomer,
            description: t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.percentDescription'),
            margin: 'mb-6',
          },
          {
            label: t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.newCustomerPoints'),
            tooltipType: 'newCustomerPoints',
            id: ACTION_DATA_KEY_NAMES.NEW_CUSTOMER_POINTS,
            name: ACTION_DATA_KEY_NAMES.NEW_CUSTOMER_POINTS,
            value: newCustomerPoints,
            description: t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.pointsDescription'),
            margin: 'mb-4',
          },
        ],
      }),
      [t, interestRegularCustomer, loyaltyPoints, newCustomerPoints],
    );

    const grayText = 'leading-6 tracking-[0.0275em] text-grayText';

    const sectionHeaderStyles = 'font-medium text-body1 leading-8 tracking-[0.004em]';

    const inputsList = useMemo(
      () =>
        inputsData[bonusType].map(
          ({ label, id, name, value, description, tooltipType, margin }) => (
            <div key={id} className={margin}>
              <div className='flex items-center mb-0.5'>
                <span className={`${grayText} mr-2`}>{label}*</span>
                <IconWithTooltips tooltips={tooltipType} />
              </div>
              <TextInput
                fill
                id={id}
                type='text'
                name={name}
                value={value}
                color='default'
                view='outlined'
                placeholder=''
                onClick={inputOnClickHandler}
                onChange={inputOnChangeHandler}
                disabled={!isEditTemplatesAccess}
                className='-mb-1 w-full'
              />
              <span className='text-small_text leading-4 text-grayText tracking-[0.033em]'>
                {description}
              </span>
            </div>
          ),
        ),
      [bonusType, inputOnChangeHandler, inputOnClickHandler, inputsData, isEditTemplatesAccess],
    );

    const dropdownListToRender = useMemo(
      () =>
        dropdownData.map(({ dropdownList, header, selectedValueText, tooltipType }) => (
          <div key={header} className='mb-6 w-full'>
            <div className='flex items-center mb-0.5'>
              <span className={`${grayText} mr-2`}>{header}</span>
              <IconWithTooltips tooltips={tooltipType} />
            </div>
            <div className='mb-2 mr-2 w-full'>
              <Dropdown
                color='default'
                view='outlined'
                position='bottom-left'
                disabled={!isEditTemplatesAccess}
                text={
                  <span
                    className={
                      !isEditTemplatesAccess ? 'cursor-not-allowed text-start w-full' : ''
                    }>
                    {selectedValueText}
                  </span>
                }
                content={
                  <Menu
                    view='raised'
                    className={`dropdown-list-scrollbar max-h-[50vh] overflow-y-auto ${
                      dropdownList.length === 1 ? `one_item_ul` : ''
                    }`}>
                    {dropdownList}
                  </Menu>
                }
                className='when_send_dropdown !m-0 w-full'
              />
            </div>
          </div>
        )),
      [dropdownData, isEditTemplatesAccess],
    );

    return (
      <div className={className}>
        <p className={`${sectionHeaderStyles} mb-4`}>
          {t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.bonusSettings')}
        </p>
        {dropdownListToRender[0]}
        {loyaltyCardList.length === 1 ? (
          <InviteFriendsNoCardCallout appLanguages={appLanguages} className='mb-4' />
        ) : null}
        {dropdownListToRender[1]}
        {inputsList[0]}
        {bonusType === BONUSES.PERCENTS ? (
          <Visits
            hideInfoIcon
            showInfinitySign
            visits={rangeOfVisits}
            onChangeHandler={setVisitsHandler}
            textBeforeCounter={t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.textBeforeCounter')}
            textAfterCounter={t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.textAfterCounter')}
            className='mb-6'
          />
        ) : null}
        {inputsList[1]}
        {bonusType !== BONUSES.NOT_SELECTED ? (
          <Callout
            type='def'
            view='smooth'
            color='warning'
            icon={<Icon icon='info-sign' size={20} />}>
            <span className='block text-black leading-6 tracking-[0.0275em] mt-1'>
              {t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.minimalPriceWarning', { minimalPrice })}
            </span>
          </Callout>
        ) : null}
      </div>
    );
  },
);

InviteFriendsBonuses.displayName = 'InviteFriendsBonuses';
