import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Chips } from '@uikit/Chips';
import { Table } from '@uikit/Table/Table';
import { TAppLanguage } from '@models/index';
import { useAppDispatch } from '@store/store';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { MESSAGES_DIRECTION } from '@const/chatHistory';
import { setSortTypeDataCHT } from '@redux/chatHistory/chatHistorySlice';
import { MobileChatHistoryTableCard } from '@blocks/mobileChatHistoryTableCard';
import { ChatHistoryTableTextBlock } from '@components/chatHistoryTableTextBlock';
import { TChatHistoryTableData, TMessageDirection } from '@redux/chatHistory/models';

type TChatHistoryTable = {
  /**
   * Callback для установки номера по клику на ячейку с номером в таблице
   * @param {(number: string) => void}
   */
  setSearchPhoneNumberCallback: (number: string) => void;
  /**
   * Номер телефона по которому произведен поиск
   * @param {string}
   */
  phoneNumberUsedToSearch: string;
  /**
   * Данные для таблицы
   * @param {TChatHistoryTableData[]}
   */
  tableData: TChatHistoryTableData[];
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const ChatHistoryTable = memo(
  ({
    tableData,
    appLanguage,
    phoneNumberUsedToSearch,
    setSearchPhoneNumberCallback,
    className = '',
  }: TChatHistoryTable) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const width = useWindowWidth();

    const getStatusChips = useCallback(
      (direction: TMessageDirection) => {
        const tag =
          direction === MESSAGES_DIRECTION.OUTGOING
            ? t('CHAT_HISTORY_PAGE_TEXT.chatHistoryTable.outgoing')
            : direction === MESSAGES_DIRECTION.INCOMING
            ? t('CHAT_HISTORY_PAGE_TEXT.chatHistoryTable.incoming')
            : t('CHAT_HISTORY_PAGE_TEXT.chatHistoryTable.bot');

        const color =
          direction === MESSAGES_DIRECTION.OUTGOING
            ? 'success_alt'
            : MESSAGES_DIRECTION.INCOMING
            ? 'primary_alt'
            : 'success';

        return <Chips round noClick tag={tag} type='dense' color={color} />;
      },
      [t],
    );

    const setSearchPhoneNumberByClick = useCallback(
      (number: string) => () => {
        setSearchPhoneNumberCallback(number);
      },
      [setSearchPhoneNumberCallback],
    );

    const Head = useMemo(
      () => [
        {
          id: 'phoneNumber',
          label: t('CHAT_HISTORY_PAGE_TEXT.chatHistoryTable.phoneNumber'),
          noSort: true,
        },
        {
          id: 'messageText',
          label: t('CHAT_HISTORY_PAGE_TEXT.chatHistoryTable.messageText'),
          noSort: true,
        },
        { id: 'date', label: t('CHAT_HISTORY_PAGE_TEXT.chatHistoryTable.date') },
        {
          id: 'direction',
          label: t('CHAT_HISTORY_PAGE_TEXT.chatHistoryTable.direction'),
          noSort: true,
        },
      ],
      [t],
    );

    const getTableData = useCallback(
      (data: TChatHistoryTableData[]) => {
        const emptyData = [
          {
            phoneNumber: ' ',
            messageText: ' ',
            date: ' ',
            direction: ' ',
          },
        ];
        if (!data.length) {
          return emptyData;
        }

        return data.map(({ phoneNumber, body, date, direction, type, fileData, caption }) => {
          const indexOfSlash = date.lastIndexOf(':');

          const textBlock = (
            <ChatHistoryTableTextBlock
              type={type}
              body={body}
              isMobile={false}
              caption={caption}
              fileData={fileData}
              appLanguage={appLanguage}
            />
          );

          return {
            phoneNumber: (
              <span onClick={setSearchPhoneNumberByClick(phoneNumber)} className='cursor-pointer'>
                {phoneNumber}
              </span>
            ),
            messageText: textBlock,
            date: <p className='min-w-[9rem]'>{date.slice(0, indexOfSlash)}</p>,
            direction: getStatusChips(direction),
          };
        });
      },
      [appLanguage, getStatusChips, setSearchPhoneNumberByClick],
    );

    // функция сортировки, получает название столбца отправляет в редакс
    const setSortTypeHandler = useCallback(
      (columnName: string, order: string) => {
        dispatch(setSortTypeDataCHT({ columnName, order }));
      },
      [dispatch],
    );

    const mobileCards = useMemo(
      () =>
        tableData.map(({ phoneNumber, body, date, direction, type, fileData, caption }, index) => {
          const textBlock = (
            <ChatHistoryTableTextBlock
              isMobile
              type={type}
              body={body}
              caption={caption}
              fileData={fileData}
              appLanguage={appLanguage}
            />
          );

          return (
            <MobileChatHistoryTableCard
              key={date}
              date={date}
              textBlock={textBlock}
              phoneNumber={phoneNumber}
              statusChips={getStatusChips(direction)}
              setSearchPhoneNumberCallback={setSearchPhoneNumberCallback}
              className={index === tableData.length - 1 ? '' : 'mb-6'}
            />
          );
        }),
      [appLanguage, getStatusChips, setSearchPhoneNumberCallback, tableData],
    );

    return (
      <div className={className}>
        <h3 className='font-medium text-black text-h3Mobile leading-8 mb-4 tracking-[0.004em]'>
          {t('CHAT_HISTORY_PAGE_TEXT.chatHistoryTable.header', {
            additionalText: phoneNumberUsedToSearch
              ? t('CHAT_HISTORY_PAGE_TEXT.chatHistoryTable.contact', { phoneNumberUsedToSearch })
              : '',
          })}
        </h3>
        {width > 980 ? (
          <Table
            name={Head}
            data={getTableData(tableData)}
            position={['left', 'left', 'left', 'left']}
            type='def'
            view='flat'
            color='default'
            pages
            labelRowsPerPage={t('CHAT_HISTORY_PAGE_TEXT.chatHistoryTable.pagination')}
            rowsPerPageOptions={[5, 8, 20]}
            defRows={8}
            checkboxes={false}
            paperClassName='no_shadow'
            onSortCallback={setSortTypeHandler}
            style={{ boxShadow: 'none', marginLeft: '6px' }}
            paginationInText={t('CHAT_HISTORY_PAGE_TEXT.chatHistoryTable.paginationInText')}
          />
        ) : null}
        {width < 981 ? <div className='overflow-y-auto'>{mobileCards}</div> : null}
      </div>
    );
  },
);

ChatHistoryTable.displayName = 'ChatHistoryTable';
