import { createAsyncThunk } from '@reduxjs/toolkit';
import { partnerProgramApi } from '@api/partnerProgramApi';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { TMappedOperationListTableData } from '@redux/operationListTable/models';
import { operationListTableMappers } from '@redux/operationListTable/operationListTableMappers';
import {
  TRejectResponse,
  TRejectResponse2,
  REJECT_RESPONSE_KEY,
  GET_FULL_TABLE_RESPONSE_KEY_NAME,
} from '@api/types';

/**
 * Thunk Получает данные для таблицы операций и обрабатывает результат.
 * @param {string} data - Данные для получения таблицы операций.
 * @returns {TOperationListTableData[] | void} - Массив данных таблицы операций или в undefined.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const getOperationListTableData = createAsyncThunk<
  TMappedOperationListTableData | void,
  string,
  { rejectValue: TRejectResponse | TRejectResponse2 }
>('operationListTableSlice/getOperationListTableData', (data, { dispatch, rejectWithValue }) => {
  return partnerProgramApi
    .getOperationListTableData(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(response.data.status);
      }
      if (GET_FULL_TABLE_RESPONSE_KEY_NAME.DATA in response.data) {
        return operationListTableMappers.mapGetOperationListTableData(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getOperationListTableData(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

export { getOperationListTableData };
