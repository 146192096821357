import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@uikit/Button';
import { PATH_NAMES } from '@data/dict';
import { TAppLanguage } from '@models/index';
import { QUERY_PARAMS } from '@const/apiConstants';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { REVIEWS_STATS_SORT_DROPDOWN } from '@const/reviews';
import { useAppDispatch, useAppSelector } from '@store/store';
import { SortTypeDropdown } from '@components/sortTypeDropdown';
import { DateRangeDashboardPicker } from '@components/dateRangePicker';
import { getReviewStatsPageData } from '@redux/reviewsStats/selectors';
import { ReviewStatisticsCard } from '@components/reviewStatisticsCard';
import { CustomTablePagination } from '@components/customTablePagination';
import {
  setDateRangeRSP,
  setRowsPerPageRSP,
  setSelectedPageRSP,
  setSortTypeDataRSP,
  setSortDirectionRSP,
} from '@redux/reviewsStats/slice';

type TProps = {
  /**
   * ID выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const ReviewsStats = memo(({ appLanguage, selectedFilialAccId, className = '' }: TProps) => {
  const { t } = useTranslation();
  const width = useWindowWidth();
  const dispatch = useAppDispatch();

  const {
    data,
    status,
    sortedBy,
    totalCount,
    currentPage,
    rowsPerPage,
    endDate: end,
    sortDirection,
    startDate: start,
  } = useAppSelector(getReviewStatsPageData);

  const isLoading = status === 'loading';
  const isSuccess = status === 'success';

  // Обрабатывает изменение диапазона дат
  const setDateRangeHandler = useCallback(
    ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
      if (isLoading) return;

      const newStartDate = startDate.toISOString().slice(0, -5);
      const newEndDate = endDate.toISOString().slice(0, -5);

      dispatch(setDateRangeRSP({ startDate: newStartDate, endDate: newEndDate }));
    },
    [isLoading, dispatch],
  );

  // Обрабатывает изменение типа сортировки
  const setSortByHandler = useCallback(
    (fieldName: string) => () => {
      if (isLoading) return;

      dispatch(setSortTypeDataRSP({ columnName: fieldName }));
    },
    [isLoading, dispatch],
  );

  // Обрабатывает изменение направления сортировки
  const setSortDirectionHandler = useCallback(() => {
    if (isLoading) return;

    dispatch(setSortDirectionRSP(sortDirection));
  }, [isLoading, sortDirection, dispatch]);

  // Обрабатывает изменение страницы пагинации
  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      if (isLoading) return;

      dispatch(setSelectedPageRSP(newPage));
    },
    [dispatch, isLoading],
  );

  // Обрабатывает изменение количества строк на странице
  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isLoading) return;

      const { value } = event.target;
      dispatch(setRowsPerPageRSP(Number(value)));
    },
    [dispatch, isLoading],
  );

  const showMoreHandler = useCallback(() => {
    dispatch(setSelectedPageRSP(currentPage + 1));
  }, [currentPage, dispatch]);

  const statisticsCardList = data.map(cardData => (
    <ReviewStatisticsCard
      key={cardData.id}
      data={cardData}
      link={`${PATH_NAMES.REVIEW}?${QUERY_PARAMS.ACC_ID}=${selectedFilialAccId}&${QUERY_PARAMS.ID}=${cardData.id}`}
    />
  ));

  return (
    <div className={className}>
      <div className='px-4 sm:px-0'>
        <div className='flex flex-col sm:flex-row justify-between mb-8'>
          <DateRangeDashboardPicker
            startDate={new Date(start)}
            endDate={new Date(end)}
            minDateRange={0}
            setSendingDate={setDateRangeHandler}
            className='z-[51]'
          />
          <SortTypeDropdown
            sortedBy={sortedBy}
            sortDirection={sortDirection}
            setSortByHandler={setSortByHandler}
            dropdownMimWidthRem={width > 641 ? 21 : undefined}
            setSortDirectionHandler={setSortDirectionHandler}
            dropdownOptionDict={REVIEWS_STATS_SORT_DROPDOWN[appLanguage]}
            textBeforeDropdown={t('REVIEW_PAGE_TEXT.statistics.sortBy')}
            className='mt-4 sm:mt-0'
          />
        </div>
        {!isLoading && data.length && isSuccess ? (
          <div className='grid col-end-1 gap-1.2rem'>{statisticsCardList}</div>
        ) : null}
        {!isLoading && !data.length && isSuccess ? (
          <span>{t('REVIEW_PAGE_TEXT.statistics.noData')}</span>
        ) : null}
        {!isLoading && isSuccess && data.length ? (
          <>
            <CustomTablePagination
              totalCount={totalCount}
              rowsPerPage={rowsPerPage}
              currentPage={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t('REVIEW_PAGE_TEXT.statistics.paginatorText')}
              className='hidden sm:block'
            />
            <div className='p-px sm:hidden'>
              <Button
                dense
                type='action'
                view='outlined'
                color='default'
                onClick={showMoreHandler}
                disabled={data.length === totalCount}
                text={t('CONNECTION_PAGE_TEXT.table.showMore')}
                className='md:hidden mt-6 w-full'
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
});

ReviewsStats.displayName = 'ReviewsStats';
