import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { Loader } from '@blocks/loader';
import { numWord } from '@helpers/index';
import { NUMBERS } from '@const/blacklist';
import { TAppLanguage } from '@models/index';
import { TKeyboardClickEvent } from '@shared/types';
import { TBlackListData } from '@redux/blackList/models';
import { BlackListItem } from '@components/blackListItem';
import { PhoneNumberInput } from '@atoms/phoneNumberInput';
import { EventTemplateListTitle } from '@blocks/eventTemplateListTitle';

type TProps = {
  /**
   * Флаг получения данныз для ЧС
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Значение инпута для добавлния нового номера в ЧС
   * @param {string}
   */
  newNumber: string;
  /**
   * Обработчик изменения значения в инпуте
   * @param {(value: string) => void}
   */
  setNewNumber: (value: string) => void;
  /**
   * Добавляет введенный номер нажатием на кнопку Enter
   * @param {TKeyboardClickEvent}
   */
  onKeyDownAddNumberHandler: TKeyboardClickEvent;
  /**
   * Добавляет номер в ЧС по нажатию на кнопку "Добавить"
   * @param {() => void}
   */
  addNumberHandler: () => void;
  /**
   * Флаг добавления номера в ЧС
   * @param {boolean}
   */
  isAddingNumber: boolean;
  /**
   * Удаляет номер из списка
   * @param {(number: string) => void}
   */
  deleteNumberHandler: (number: string) => () => void;
  /**
   * Флаг ошибки удаления номера из ЧС
   * @param {boolean}
   */
  isDeleteNumberError: boolean;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Список номеров в ЧС
   * @param {TBlackListData[]}
   */
  blackListData: TBlackListData[];
  /**
   * Текст в заголовок
   * @param {string}
   */
  headerText: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const BlackListComponent = memo(
  ({
    isLoading,
    newNumber,
    headerText,
    appLanguage,
    setNewNumber,
    isAddingNumber,
    blackListData,
    addNumberHandler,
    isDeleteNumberError,
    deleteNumberHandler,
    onKeyDownAddNumberHandler,
    className = '',
  }: TProps) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(true);

    const litLength = blackListData.length;

    // Проверяет введенный номер на валидность
    const isNumberValid = newNumber.length > 9;

    // Тогл для списка с номерами скрывает/показывает список
    const listToggleHandler = () => {
      setIsOpen(prevState => !prevState);
    };

    // Подготавливает список чипсов с телефонами для рендеринга
    const commonBlacklist = useMemo(
      () =>
        blackListData.map(({ number }) => {
          return (
            <BlackListItem
              key={number}
              number={number}
              isDeleteError={isDeleteNumberError}
              deleteNumber={deleteNumberHandler(number)}
              className='mr-2 mb-2'
            />
          );
        }),
      [isDeleteNumberError, deleteNumberHandler, blackListData],
    );

    // флаг наличия номера в списке
    const isNumberExist = !!blackListData.find(item => item.number === newNumber);

    // текст в кнопку добавления/удаления номера
    const buttonText = isNumberExist
      ? t('BLACKLIST_PAGE_TEXT.deleteButton')
      : t('BLACKLIST_PAGE_TEXT.addButton');

    return (
      <div className={className}>
        <div className=''>
          <div className='flex items-center'>
            <EventTemplateListTitle
              isOpen={isOpen}
              text={headerText}
              onClickCallback={listToggleHandler}
            />
          </div>
          <span className='text-textareaBlack text-small_text tracking-[0.018em] pl-10'>
            {litLength
              ? t('BLACKLIST_PAGE_TEXT.listItemsQuality', {
                  quality: litLength,
                  text: numWord(litLength, NUMBERS[appLanguage]),
                })
              : t('BLACKLIST_PAGE_TEXT.noItemsInList')}
          </span>
        </div>
        <div className={`pl-10 mb-6 ${isOpen ? 'max-h-auto' : 'max-h-0 overflow-hidden'}`}>
          <div className='flex flex-wrap flex-col sm:flex-row mt-2 mb-4'>
            <div className='flex flex-col mr-4 w-full max-w-[21.25rem]'>
              <PhoneNumberInput
                value={newNumber}
                onChangeHandler={setNewNumber}
                onKeyDownHandler={onKeyDownAddNumberHandler}
                placeholder={t('BLACKLIST_PAGE_TEXT.numberInputPlaceholder')}
              />
              <span className='font-inter text-[0.75rem] text-stormGray tracking-[0.033em] max-w-[24em] break-words'>
                {t('BLACKLIST_PAGE_TEXT.numberInputDescription')}
              </span>
            </div>
            <div className='pt-[0.145rem] mt-4 sm:mt-0'>
              <Button
                dense
                fill={false}
                type='action'
                view='outlined'
                text={buttonText}
                loading={isAddingNumber}
                icon={isNumberExist ? 'cross' : 'plus'}
                disabled={isAddingNumber || !isNumberValid}
                color={isNumberExist ? 'danger' : 'default'}
                onClick={isNumberExist ? deleteNumberHandler(newNumber) : addNumberHandler}
              />
            </div>
          </div>
          <div>
            {isLoading ? (
              <Loader className='h-[50vh]' />
            ) : (
              <ul className='flex flex-wrap items-center relative max-h-[40vh] h-fit dropdown-list-scrollbar overflow-y-auto'>
                {!commonBlacklist.length ? (
                  <p className='m-0'>{t('BLACKLIST_PAGE_TEXT.noNumbers')}</p>
                ) : (
                  commonBlacklist
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  },
);

BlackListComponent.displayName = 'BlackListComponent';
