import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { Dialog } from '@uikit/Dialog';

type TYclientsChatEnableModalProps = {
  /**
   * Флаг показа модалки для подтверждения включения чата
   * @param {boolean}
   */
  isShowChatEnableModal: boolean;
  /**
   * Callback для закрытия модалки
   * @param {() => void}
   */
  closeModalCallback: () => void;
  /**
   * Callback для подтверждения включения чата в журнале Yclients
   * @param {() => void}
   */
  enableChatCallback: () => void;
};

export const YclientsChatEnableModal = memo(
  ({
    enableChatCallback,
    closeModalCallback,
    isShowChatEnableModal,
  }: TYclientsChatEnableModalProps) => {
    const { t } = useTranslation();

    return (
      <Dialog
        type='default'
        view='raised'
        color='default'
        title={t('SETTINGS_PAGE_TEXT.chatEnableModal.title')}
        text={
          <span className='leading-6 tracking-[0.0275em]'>
            {t('SETTINGS_PAGE_TEXT.chatEnableModal.description')}
          </span>
        }
        backdropOpacity={40}
        rightButton={
          <div className='flex flex-wrap'>
            <Button
              dense
              type='action'
              view='outlined'
              onClick={closeModalCallback}
              text={t('SETTINGS_PAGE_TEXT.chatEnableModal.cancelButton')}
              className='mt-2 mr-6'
            />
            <Button
              dense
              type='action'
              view='filled'
              color='success'
              onClick={enableChatCallback}
              text={t('SETTINGS_PAGE_TEXT.chatEnableModal.confirmButton')}
              className='mt-2'
            />
          </div>
        }
        isOpen={isShowChatEnableModal}
        onClose={closeModalCallback}
        style={{ maxWidth: '30.5rem', width: '100%' }}
      />
    );
  },
);

YclientsChatEnableModal.displayName = 'YclientsChatEnableModal';
