import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { DAYS } from '@const/common';
import { useAppSelector } from '@store/store';
import { getAppData } from '@redux/app/selectors';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { numWord, selectTextContentOnFocus } from '@helpers/index';
import { TOnFocusHandlerInputElement, TSetWhenSend2Days } from '@shared/types';

type TBirthdayWhenSend2Props = {
  /**
   * Значение дропдауна whenSend2
   * @param {string}
   */
  whenSend2: string;
  /**
   * Через сколько дней отправлять шаблон
   * @param {string}
   */
  whenSend2Days: string;
  /**
   * Callback для изменения whenSend2Days
   * @param {TSetWhenSend2Days}
   */
  setWhenSend2Days: TSetWhenSend2Days;
  /**
   * Флаг отключает возможность изменения значения в блоке
   * @param {boolean}
   */
  disabled: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const BirthdayWhenSend2 = memo(
  ({
    disabled,
    whenSend2,
    whenSend2Days,
    setWhenSend2Days,
    className = '',
  }: TBirthdayWhenSend2Props) => {
    const { t } = useTranslation();

    const { appLanguage } = useAppSelector(getAppData);

    // Выделяет значение в инпуте при фокусе
    const selectValueOnFocus: TOnFocusHandlerInputElement = useCallback(event => {
      selectTextContentOnFocus(event);
    }, []);

    return (
      <div className={`flex items-center ${className}`}>
        <span className='text-black leading-6 tracking-[0.0275em] mr-1'>
          {+whenSend2 > 0
            ? t('TEMPLATE_PAGE_TEXT.birthdayWhenSend2Before')
            : t('TEMPLATE_PAGE_TEXT.birthdayWhenSend2After')}
        </span>
        <div className='w-[4rem]'>
          <TextInput
            fill
            type='text'
            placeholder=''
            color='default'
            view='outlined'
            disabled={disabled}
            value={whenSend2Days}
            onFocus={selectValueOnFocus}
            onChange={setWhenSend2Days({ maxValue: 400, minValue: 1 })}
            className='m-0'
          />
        </div>
        <span className='text-black leading-6 tracking-[0.0275em] ml-2.5'>
          {numWord(+whenSend2Days, DAYS[appLanguage])}
        </span>
      </div>
    );
  },
);

BirthdayWhenSend2.displayName = 'BirthdayWhenSend2';
