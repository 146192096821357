import React from 'react';
import { useTranslation } from 'react-i18next';
//
import { Menu } from '@uikit/Menu';
import { Dropdown } from '@uikit/Dropdown';
import { useAppSelector } from '@store/store';
import { getAppData } from '@redux/app/selectors';
import { CheckboxToDropdown } from '@blocks/checkboxToDropdown';
import { IconWithTooltips } from '@components/iconWithTooltips';
import { DEFAULT_ALL_OPTIONS, DEFAULT_OPTIONS_FILTER } from '@const/templates';

type TTemplatesDetailStatsSegmentSelectProps = {
  /**
   * Массив объектов с версиями и их названиями
   * @type {Record<string, string>[]}
   */
  versions: Record<string, string>[];
  /**
   * Массив отфильтрованных версий
   * @type {string[]}
   */
  filtered: string[];
  /**
   * Слушатель обработки клика по пункту меню
   * @type {(key: string) => void}
   */
  onItemClickHandler: (key: string) => void;
};
export const TemplatesDetailStatsSegmentSelect = ({
  versions,
  filtered,
  onItemClickHandler,
}: TTemplatesDetailStatsSegmentSelectProps) => {
  const { t } = useTranslation();
  const { appLanguage } = useAppSelector(getAppData);

  const renderText = (text: string) => {
    return text === DEFAULT_ALL_OPTIONS
      ? t('TEMPLATE_PAGE_TEXT.templatesDetailStatsSegmentSelect.allVersion')
      : t('TEMPLATE_PAGE_TEXT.templatesDetailStatsSegmentSelect.oneVersion', { version: text });
  };

  const checkBoxElementsList = [...DEFAULT_OPTIONS_FILTER[appLanguage], ...versions].map(
    ({ key, text }) => {
      if (key === 'divider') {
        return <span key={key} className='border-b border-quartz1 block my-2 w-full' />;
      }

      return (
        <CheckboxToDropdown
          key={key}
          name={key}
          checked={filtered.includes(key)}
          onChangeHandler={() => onItemClickHandler(key)}
          text={key !== DEFAULT_ALL_OPTIONS ? renderText(text) : text}
          className='w-fit'
        />
      );
    },
  );

  return (
    <div className='flex flex-col mb-4 lg:flex-row lg:mb-0 items-baseline'>
      <div className='flex mb-4 lg:flex-row lg:mb-0 items-center mr-2'>
        <span className='mr-2'>{t('LABEL_TEXT.statisticSegment')}</span>
        <IconWithTooltips tooltips='statisticSegment' />
      </div>
      <Dropdown
        color='default'
        view='outlined'
        text={<span>{renderText(DEFAULT_ALL_OPTIONS)}</span>}
        content={
          <Menu view='raised' className='!p-2'>
            {checkBoxElementsList}
          </Menu>
        }
        className='when_send_dropdown min-w-[12rem] w-full'
      />
    </div>
  );
};
