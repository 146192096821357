import { TTemplateDetailStatsData } from '@api/types';
import {
  DEFAULT_TIME_FROM,
  TEMPLATE_DETAIL_FILTER_GROUP_BY,
  TEMPLATE_DETAIL_TABS,
  TEMPLATE_DETAIL_TIME_TABS,
} from '@const/templates';

import {
  TTemplateDetailFilter,
  TTemplateStatisticData,
  TTemplatesStatisticsData,
  TTemplateStatisticTableData,
  TTemplateStatisticDataByTime,
} from './models';

export interface IInitialState {
  isRetries: boolean;
  retries: number;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  isAccessDenied: boolean;
  isTemplateFetching: boolean;
  isTemplateSuccess: boolean;
  isTemplateError: boolean;
  templates: TTemplatesStatisticsData[];
  template: TTemplateStatisticData;
  templateByTime: TTemplateStatisticDataByTime;
  templateStatTableData: TTemplateStatisticTableData[];
  templateDetailStatsData: TTemplateDetailStatsData;
  templateDetailActiveTab: TEMPLATE_DETAIL_TABS;
  templateDetailActiveTimeTab: TEMPLATE_DETAIL_TIME_TABS;
  templateDetailFilter: TTemplateDetailFilter;
  errorMessage: string;
  successMessage: string;
}

export const initialState: IInitialState = {
  isRetries: false,
  retries: 1,
  isError: false,
  isFetching: false,
  isSuccess: false,
  isTemplateFetching: false,
  isTemplateSuccess: false,
  isTemplateError: false,
  templates: [],
  template: {
    count: 0,
    data: [],
  },
  templateByTime: {
    count: 0,
    data: [],
  },
  templateStatTableData: [],
  templateDetailStatsData: [],
  templateDetailActiveTab: TEMPLATE_DETAIL_TABS.VERSION,
  templateDetailActiveTimeTab: TEMPLATE_DETAIL_TIME_TABS.WEEK,
  templateDetailFilter: {
    value: '',
    filteredDetailTableDropdown: [],
    dateFrom: DEFAULT_TIME_FROM,
    dateTo: new Date().toISOString(),
    groupBy: TEMPLATE_DETAIL_FILTER_GROUP_BY.WEEK,
  },
  errorMessage: '',
  successMessage: '',
  isAccessDenied: false,
};
