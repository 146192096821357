import { TAddLeadData } from './models';

export interface IInitialSlice {
  isLoading: boolean;
  isError: boolean;
  isCreateLead: boolean;
  errorMessage: string;
  successMessage: string;
  isAccessDenied: boolean;
  newLeadData: TAddLeadData;
  tableData: TAddLeadData[];
}

export enum ADD_LEADS_KEY_NAMES {
  PHONE = 'phone',
  BRANCH_ID = 'branchId',
  LEAD_NAME = 'leadName',
  COMMENT = 'comment',
}

export const initialState: IInitialSlice = {
  isError: false,
  isLoading: false,
  isCreateLead: false,
  isAccessDenied: false,
  errorMessage: '',
  successMessage: '',
  tableData: [],
  newLeadData: {
    promoCode: '',
    phone: '',
    branchId: '',
    leadName: '',
    comment: '',
    createdAt: '',
    branchName: '',
  },
};
