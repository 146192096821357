import { TAppLanguage, TSimpleStringObj } from '@models/index';

export enum CHAT_HISTORY_API_ERRORS_TEXT {
  MESSAGES_NOT_FOUND = 'messages not found',
}
export enum MESSAGES_TYPE {
  TEXT = 'text',
  FILE = 'file',
  GEO = 'geo',
  CONTACT = 'contact',
}

export enum MESSAGES_DIRECTION {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
  BOT = 'bot',
}

export const arrChatImage = ['JPEG', 'JPG', 'GIF', 'PNG', 'APNG', 'SVG', 'BMP', 'ICO'];
export const arrChatVideo = ['MP4', 'MOV', 'WMV', 'AVI', 'AVCHD', 'FLV', 'F4V', 'SWF', 'MKV'];
export const arrChatAudio = ['OGG', 'MPEG', 'WAV', 'WEBM', 'X-CAF', 'FLAC', 'MP3'];

export const FILE_EXTENSION = [
  'ai',
  'avi',
  'doc',
  'gif',
  'jpg',
  'mkv',
  'mp3',
  'pdf',
  'ppt',
  'psd',
  'svg',
  'txt',
  'xls',
  'zip',
  'xlsx',
  'xls',
  'file',
  'lost',
];

export const chatHistoryTableTextBlock: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    locationMessageTag: 'Location',
    contactMessageTag: 'Contact: ',
  },
  rus: {
    locationMessageTag: 'Location',
    contactMessageTag: 'Контакт: ',
  },
};

export const CHAT_HISTORY_PAGE_TEXT = {
  header: 'История переписки',
  searchButton: 'Найти',
  searchInputPlaceholder: 'Найти клиента',
  searchInputDescription: 'По номеру телефона',
  chatHistoryTable: {
    pagination: 'Показать строк:',
    paginationInText: 'из',
    header: 'Список всех сообщений {{additionalText}}',
    contact: 'контакта +{{phoneNumberUsedToSearch}}',
    phoneNumber: 'Номер клиента',
    messageText: 'Текст сообщения',
    date: 'Дата и время',
    direction: 'Тип',
    incoming: 'Входящее',
    outgoing: 'Исходящее',
    bot: 'Бот',
  },
  tableTextBlock: {
    header: 'Текст сообщения',
  },
  chatHistoryMediaFilePreview: {
    previewTextImage: 'Изображение',
    previewTextVideo: 'Видео',
    downloadLink: 'Скачать',
    fileNotFound: 'Файл не найден',
    file: 'Файл:',
    closeButton: 'Закрыть',
  },
  chatHistoryTableTextBlock: {
    locationMessageTag: 'Location',
    contactMessageTag: 'Контакт: {{contact}}',
  },
};

export const CHAT_HISTORY_PAGE_TEXT_ENG = {
  header: 'Chat history',
  searchButton: 'Find',
  searchInputPlaceholder: 'Find client',
  searchInputDescription: 'By phone number',
  chatHistoryTable: {
    pagination: 'Show lines:',
    paginationInText: 'in',
    header: 'List of all messages {{additionalText}}',
    contact: 'contact +{{phoneNumberUsedToSearch}}',
    phoneNumber: 'Customer number',
    messageText: 'Message text',
    date: 'Date and time',
    direction: 'Type',
    incoming: 'Incoming',
    outgoing: 'Outgoing',
    bot: 'Bot',
  },
  tableTextBlock: {
    header: 'Message text',
  },
  chatHistoryMediaFilePreview: {
    previewTextImage: 'Image',
    previewTextVideo: 'Video',
    downloadLink: 'Download',
    fileNotFound: 'File not found',
    file: 'File:',
    closeButton: 'Close',
  },
  chatHistoryTableTextBlock: {
    locationMessageTag: 'Location',
    contactMessageTag: 'Contact: {{contact}}',
  },
};
