import React, { forwardRef } from 'react';

import Component from './Badge';
import PropTypes, { InferProps } from 'prop-types';

const propType = {
  Component: PropTypes.string,
  value: PropTypes.string,
  /**
   ` The view of the component.
   * Variants: `flat` `smooth` `outlined` `raised`
   * Default value (if undefined): `filled` `
   */
  view: PropTypes.oneOf(["filled", "smooth", "outlined"]),
  /**
   ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    "default",
    "primary",
    "warning",
    "danger",
    "success",
    "primary_alt",
    "warning_alt",
    "danger_alt",
    "success_alt"
  ]),
  /**
   * Dense size
   */
  dense: PropTypes.bool,
  /**
   * Dot
   */
  dot: PropTypes.bool,
  /**
   * Hide badge
   */
  hide: PropTypes.bool,
  left: PropTypes.bool,
  bottom: PropTypes.bool,
  middle: PropTypes.bool,
};

type TComponentPropType = InferProps<typeof propType>;

interface IComponentProps extends TComponentPropType {
  [key: string]: any;
}

export const Badge: React.FC<IComponentProps> = forwardRef((props, ref) => <Component {...props} ref={ref} />);