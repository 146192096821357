import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { ToggleSwitch } from '@blocks/toggleSwitch';
import { DeleteButton } from '@blocks/deleteButton';
import { TReviewSecondStepInListData } from '@api/types';
import { TDeleteStep, TSwitchStep } from '@shared/types';

interface IReviewSep24CardProps {
  /**
   * Данные в карточку
   * @param {TReviewSecondStepInListData}
   */
  data: TReviewSecondStepInListData;
  /**
   * Callback для удаления шага в цепочке
   * @param {TDeleteStep}
   */
  deleteStep: TDeleteStep;
  /**
   * Callback для переключения активности шага
   * @param {TSwitchStep}
   */
  switchStep: TSwitchStep;
  /**
   * ID шага
   * @param {number}
   */
  stepId: number;
  /**
   * ID карточки
   * @param {string}
   */
  templateCardId: string;
  /**
   * Флаг доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const ReviewSep24Card = memo(
  ({
    data,
    stepId,
    switchStep,
    deleteStep,
    templateCardId,
    isEditTemplatesAccess,
    className = '',
  }: IReviewSep24CardProps) => {
    const { t } = useTranslation();
    const { active: status, body: whatsapp } = data;

    return (
      <div
        className={`border border-lightGray bg-white flex flex-col grow shrink font-inter text-[1.5rem] text-blackText p-4 rounded-lg ${className}`}>
        <div className='flex items-center justify-between mb-5'>
          <div className='flex items-center'>
            {isEditTemplatesAccess && (
              <ToggleSwitch
                name='status'
                checked={status}
                onChangeHandler={() =>
                  switchStep({
                    status: !status,
                    stepId: String(stepId),
                    templateId: templateCardId,
                  })
                }
                className='mr-2 z-[1]'
              />
            )}
            <p className='font-bold text-darkGray m-0'>{t('REVIEW_PAGE_TEXT.step', { stepId })}</p>
          </div>
          {isEditTemplatesAccess && (
            <DeleteButton
              callback={() => deleteStep({ templateId: templateCardId, stepId: String(stepId) })}
              className='mr-[0.375rem]'
            />
          )}
        </div>
        <p className='text-body_text h-12 overflow-hidden mb-3'>{whatsapp}</p>
      </div>
    );
  },
);

ReviewSep24Card.displayName = 'ReviewSep24Card';
