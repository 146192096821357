import { allSendOutFiltersIsOn } from '@const/mailing';
import { TSendOutDetailsStatisticsData } from '@models/index';
import { TSelectedFilters } from '@redux/sendOutStatisticsTable/models';

export interface IInitialState {
  isRetries: boolean;
  retries: number;
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  isAccessDenied: boolean;
  searchString: string;
  downloadLink: string;
  selectedFilters: TSelectedFilters[];
  tableData: TSendOutDetailsStatisticsData[];
  filteredTableData: TSendOutDetailsStatisticsData[];
  errorMessage: string;
  successMessage: string;
}

export const initialState: IInitialState = {
  isRetries: false,
  retries: 1,
  isError: false,
  isLoading: false,
  isFetching: false,
  isSuccess: false,
  isAccessDenied: false,
  searchString: '',
  tableData: [],
  downloadLink: '',
  selectedFilters: allSendOutFiltersIsOn,
  filteredTableData: [],
  errorMessage: '',
  successMessage: '',
};
