import React, { memo } from 'react';

interface IAddEmojiProps {
  /**
   * Callback функция которая срабатывает при нажатии на кнопку
   * @param {() => void}
   */
  callback: () => void;
  /**
   * Опциональный параметр отключает компонент
   * @param {boolean}
   * @default
   */
  disabled?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const AddEmoji = memo(({ disabled, callback, className = '' }: IAddEmojiProps) => {
  const style = `${
    disabled ? 'cursor-not-allowed' : 'hover:bg-quartz'
  } bg-transparent border-none rounded-full flex items-center justify-center p-[5px] h-[30px] w-[30px]`;
  return (
    <button aria-label='add emoji' className={`${style} ${className}`} onClick={callback}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 20 20'
        height={20}
        width={20}>
        <g clipPath='url(#a)'>
          <path
            fill='#4A4B57'
            fillRule='evenodd'
            d='M0 10C0 4.5 4.5 0 10 0s10 4.5 10 10-4.5 10-10 10S0 15.5 0 10Zm2 0c0 4.4 3.6 8 8 8s8-3.6 8-8-3.6-8-8-8-8 3.6-8 8Zm4.534 2a1 1 0 0 0-1.73 1A5.998 5.998 0 0 0 10 16a5.998 5.998 0 0 0 5.197-3 1 1 0 0 0-1.732-1A3.998 3.998 0 0 1 10 14a3.998 3.998 0 0 1-3.466-2ZM8 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM13.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z'
            clipRule='evenodd'
          />
        </g>
        <defs>
          <clipPath id='a'>
            <path fill='#fff' d='M0 0h20v20H0z' />
          </clipPath>
        </defs>
      </svg>
    </button>
  );
});

AddEmoji.displayName = 'AddEmoji';
