import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

type TTodayTagProps = {
  /**
   * Обрабатывает нажатие на тег
   * @param {() = void}
   */
  setTodayCallback: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const TodayTag = memo(({ setTodayCallback, className = '' }: TTodayTagProps) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={setTodayCallback}
      className={`bg-green3 cursor-pointer flex items-center rounded py-[0.375rem] px-[0.75rem] justify-center ${className}`}>
      <span className='font-medium text-small_text text-green1 leading-5 tracking-[0.018em]'>
        {t('MAILING_PAGE_TEXT.mailingCalendar.todayTag')}
      </span>
    </div>
  );
});

TodayTag.displayName = 'TodayTag';
