import { TKeyData } from '@redux/template/models';
import { TFilialData } from '@redux/accounts/models';
import { actionEmptyData } from '@redux/inviteFriendsAction/mockData';
import { TAppLanguage, TSimpleStringObj, TSimpleStringObjLevel2 } from '@models/index';
import {
  TCardType,
  TActionData,
  TIFTextareaNames,
  ACTION_DATA_KEY_NAMES,
} from '@redux/inviteFriendsAction/type';
import {
  inviteFriendKeysDict,
  WHEN_SEND_SELECT_OPTION_INVITE_FRIENDS,
  WHEN_SEND2_SELECT_OPTION_VALUE_INVITE_FRIENDS,
} from '@const/inviteFriend';

export interface IInitialState {
  isError: boolean;
  isLoading: boolean;
  isAccessDenied: boolean;
  isFetching: boolean;
  isSaving: boolean;
  isSavingSuccess: boolean;
  errorMessage: string;
  successMessage: string;
  textareaKeysData: TKeyData[];
  blurFromTextareaName: TIFTextareaNames;
  selectionEnd: number;
  selectionStart: number;
  servicesNewValue: string;
  categoriesNewValue: string;
  staffNewValue: string;
  clientCategoryNewValue: string;
  blacklistNewValue: string;
  loyaltyCards: TCardType[];
  dropdownDataList: TSimpleStringObjLevel2;
  inviteFriendsKeysDict: TSimpleStringObj;
  whenSendSelectOption: Record<TAppLanguage, TSimpleStringObj>;
  whenSend2SelectOption: Record<TAppLanguage, TSimpleStringObjLevel2>;
  minimalPrice: string;
  isBlockTemplate: boolean;
  oldData: TActionData;
  data: TActionData;
  filialsToCopy: TFilialData[];
  isCopyTemplatesError: boolean;
  isCopyTemplatesSuccess: boolean;
  isCopyTemplatesLoading: boolean;
  isShowWarningModal: boolean;
  errorInGetLoyaltyCard: boolean;
}

export const initialState: IInitialState = {
  isError: false,
  isLoading: false,
  isFetching: false,
  isAccessDenied: false,
  isSaving: false,
  isSavingSuccess: false,
  errorMessage: '',
  successMessage: '',
  staffNewValue: '',
  textareaKeysData: [],
  servicesNewValue: '',
  dropdownDataList: {},
  blacklistNewValue: '',
  categoriesNewValue: '',
  clientCategoryNewValue: '',
  loyaltyCards: [],
  blurFromTextareaName: ACTION_DATA_KEY_NAMES.FIRST_MESSAGE,
  selectionEnd: 0,
  selectionStart: 0,
  inviteFriendsKeysDict: inviteFriendKeysDict.rus.common,
  whenSendSelectOption: WHEN_SEND_SELECT_OPTION_INVITE_FRIENDS,
  whenSend2SelectOption: WHEN_SEND2_SELECT_OPTION_VALUE_INVITE_FRIENDS,
  minimalPrice: '0',
  isBlockTemplate: false,
  oldData: actionEmptyData,
  data: actionEmptyData,
  filialsToCopy: [],
  isCopyTemplatesError: false,
  isCopyTemplatesSuccess: false,
  isCopyTemplatesLoading: false,
  isShowWarningModal: false,
  errorInGetLoyaltyCard: false,
};
