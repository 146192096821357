import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import icon from '@img/connect/telegram.svg';
import { ChannelHeader } from '@blocks/channelHeader';

type TTgBotAuthenticatedProps = {
  /**
   * Флаг состояния запроса на отключения бота
   * @param {boolean}
   */
  isLogout: boolean;
  /**
   * Флаг состояния запроса на перезапуск бота
   * @param {boolean}
   */
  isRestart: boolean;
  /**
   * Callback для отключения бота
   * @param {() => void}
   */
  logoutBotHandler: () => void;
  /**
   * Callback для перезагрузки бота
   * @param {() => void}
   */
  restartBotHandler: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const TgBotAuthenticated = memo(
  ({
    isLogout,
    isRestart,
    logoutBotHandler,
    restartBotHandler,
    className = '',
  }: TTgBotAuthenticatedProps) => {
    const { t } = useTranslation();

    return (
      <div className={className}>
        <div className='mb-4'>
          <ChannelHeader
            iconSrc={icon}
            subheaderText=''
            headerText={t('CONNECTION_PAGE_TEXT.telegramBotConnection.authenticatedHeader')}
            className='mb-6'
          />
          <p className='text-gray2 leading-6 tracking-[0.0275em] mb-6'>
            {t('CONNECTION_PAGE_TEXT.telegramBotConnection.authenticatedDescription')}
          </p>
        </div>
        <div className='flex flex-col sm:flex-row sm:items-center'>
          <Button
            dense
            type='action'
            icon='disable'
            view='outlined'
            color='default'
            loading={isLogout}
            onClick={logoutBotHandler}
            text={t('CONNECTION_PAGE_TEXT.telegramBotConnection.authenticatedDisableButton')}
            className='!bg-white mb-4 sm:mb-0 sm:mr-4'
          />
          <Button
            dense
            type='action'
            icon='refresh'
            view='outlined'
            color='default'
            loading={isRestart}
            onClick={restartBotHandler}
            text={t('CONNECTION_PAGE_TEXT.telegramBotConnection.authenticatedRestartButton')}
            className='!bg-white'
          />
        </div>
      </div>
    );
  },
);

TgBotAuthenticated.displayName = 'ConnectionStatusCardTgBot';
