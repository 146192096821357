import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { REG_EXP } from '@const/common';
import { INPUT_NAMES } from '@models/index';
import { FormLabel } from '@blocks/formLabel';
import { InputWithDescription } from '@blocks/inputWithDescription';
import {
  TOnChangeHandler,
  TOnFocusHandlerInputElement,
  TVisitQuantityOnChangeHandler,
} from '@shared/types';

type TVisitQuantityProps = {
  /**
   * Значение от
   * @param {string}
   */
  quantityFrom: string;
  /**
   * Значение до
   * @param {string}
   */
  quantityTo: string;
  /**
   * Callback обрабатывает изменение данных в input
   * @param {TVisitQuantityOnChangeHandler}
   */
  onChangeHandler: TVisitQuantityOnChangeHandler;
  /**
   * Опциональный параметр tailwind класс для изменения цвета текста label
   * @param {string}
   * @default
   */
  textColor?: string;
  /**
   * Опциональный параметр, отключает возможность ввести данне в инпуты
   * @param {boolean}
   */
  disabled?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const VisitQuantity = memo(
  ({
    quantityFrom,
    quantityTo,
    textColor,
    onChangeHandler,
    disabled = false,
    className = '',
  }: TVisitQuantityProps) => {
    const { t } = useTranslation();

    const sanitizeValue = (value: string, minValue: number) => Math.max(minValue, Number(value));

    const handleQuantityFromChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        const newValue = value.replace(REG_EXP.onlyNumbers, '');

        const sanitizedValue = sanitizeValue(newValue, 0);
        const adjustedToValue =
          +quantityTo < sanitizedValue ? sanitizedValue + 1 : Number(quantityTo);

        onChangeHandler({
          newQuantityFromValue: sanitizedValue.toString(),
          newQuantityToValue: adjustedToValue.toString(),
        });
      },
      [quantityTo, onChangeHandler],
    );

    const handleQuantityToChange: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.target;

        const newValue = value.replace(REG_EXP.onlyNumbers, '');

        const sanitizedValue = sanitizeValue(newValue, 1);
        const adjustedFromValue =
          +quantityFrom > sanitizedValue ? sanitizedValue - 1 : Number(quantityFrom);

        onChangeHandler({
          newQuantityFromValue: adjustedFromValue.toString(),
          newQuantityToValue: sanitizedValue.toString(),
        });
      },
      [quantityFrom, onChangeHandler],
    );

    const clearValueHandler = useCallback(() => {
      onChangeHandler({ newQuantityFromValue: '', newQuantityToValue: '' });
    }, [onChangeHandler]);

    const onFocusHandler: TOnFocusHandlerInputElement = useCallback(event => {
      event.currentTarget.select();
    }, []);

    return (
      <div className={className}>
        <FormLabel
          showInfo
          textColor={textColor}
          labelTextVariant='visitQuantity'
          htmlFor={INPUT_NAMES.QUANTITY_FROM}
          className='mb-2'
        />
        <div className='flex flex-wrap items-center'>
          <InputWithDescription
            disabled={disabled}
            value={quantityFrom}
            onFocusCallback={onFocusHandler}
            inputName={INPUT_NAMES.QUANTITY_FROM}
            onChangeCallback={handleQuantityFromChange}
            // textUnderInput={t('TEMPLATE_PAGE_TEXT.inclusive')}
            textBeforeInput={t('TEMPLATE_PAGE_TEXT.visitsQuantityFrom')}
            className='mr-2.5 mb-2'
          />
          <InputWithDescription
            value={quantityTo}
            disabled={disabled}
            onFocusCallback={onFocusHandler}
            inputName={INPUT_NAMES.QUANTITY_TO}
            onChangeCallback={handleQuantityToChange}
            // textUnderInput={t('TEMPLATE_PAGE_TEXT.inclusive')}
            textBeforeInput={t('TEMPLATE_PAGE_TEXT.visitsQuantityTo')}
            className='mr-4 mb-2'
          />
          <Button
            icon='cross'
            view='outlined'
            color='default'
            disabled={disabled}
            onClick={clearValueHandler}
            text={t('TEMPLATE_PAGE_TEXT.visitsQuantityClearButton')}
            className='self-start mt-0.5 mb-2'
          />
        </div>
      </div>
    );
  },
);

VisitQuantity.displayName = 'VisitQuantity';
