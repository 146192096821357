import { TPayHistoryConsultantTableData } from './models';

export interface IInitialState {
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  sortColumnName: string;
  isAccessDenied: boolean;
  data: TPayHistoryConsultantTableData[];
}

export const initialState: IInitialState = {
  isError: false,
  isLoading: false,
  isFetching: false,
  isSuccess: false,
  isAccessDenied: false,
  sortColumnName: '',
  data: [],
};
