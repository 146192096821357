import React, { memo } from 'react';
//
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { TOnChangeHandler, TOnFocusHandlerInputElement } from '@shared/types';

type TInputWithDescriptionProps = {
  /**
   * Текст перед инпутом
   * @param {string}
   */
  textBeforeInput: string;
  /**
   * Флаг отключения инпута
   * @param {boolean}
   */
  disabled: boolean;
  /**
   * Значение параметра name в input
   * @param {string}
   */
  inputName: string;
  /**
   * Значение в инпут
   * @param {string}
   */
  value: string;
  /**
   * Callback для изменения значения в инпте
   * @param {TOnChangeHandler}
   */
  onChangeCallback: TOnChangeHandler;
  /**
   * Текст под инпут
   * @param {string}
   */
  textUnderInput?: string;
  /**
   * Опциональный callback для действий при фокусе инпута
   * @param {TOnFocusHandlerInputElement}
   */
  onFocusCallback?: TOnFocusHandlerInputElement;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const InputWithDescription = memo(
  ({
    value,
    disabled,
    inputName,
    textUnderInput,
    textBeforeInput,
    onFocusCallback,
    onChangeCallback,
    className = '',
  }: TInputWithDescriptionProps) => {
    return (
      <div className={`flex flex-col items-center justify-center w-fit ${className}`}>
        <div className='flex items-center'>
          <span className='leading-6 tracking-[0.0275em] mr-2.5'>{textBeforeInput}</span>
          <TextInput
            min='0'
            type='number'
            color='default'
            view='outlined'
            value={value}
            name={inputName}
            disabled={disabled}
            onFocus={onFocusCallback}
            onChange={onChangeCallback}
            className='max-w-[5.785rem]'
          />
        </div>
        {textUnderInput ? (
          <span className='text-statusRed text-[0.75rem] self-end mr-1'>{textUnderInput}</span>
        ) : null}
      </div>
    );
  },
);

InputWithDescription.displayName = 'InputWithDescription';
