import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//
import { PATH_NAMES } from '@data/dict';
import { getLinkHref } from '@helpers/index';
import { Status } from '@blocks/colorStatus';
import { FilialStatus } from '@atoms/filialStatus';
import { CONNECTION_STATUSES } from '@models/index';
import { ROUTES_ACCESS_RIGHTS } from '@const/access';
import { TFilialData } from '@redux/accounts/models';

interface IFilialCardProps {
  /**
   * Данные филиала
   * @param {TFilialData}
   */
  filialData: TFilialData;
  /**
   * Callback для установки выбранного филиала в store при клике на NavLink
   * @param {() => void}
   */
  setChosenFilialId: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const FilialCard = memo(
  ({ filialData, setChosenFilialId, className = '' }: IFilialCardProps) => {
    const { t } = useTranslation();
    const isOwner = filialData.rights.includes(ROUTES_ACCESS_RIGHTS.OWNER);
    const isSettings = filialData.rights.includes(ROUTES_ACCESS_RIGHTS.TRANSLATION_SWITCH);

    const commonClassNames = {
      container: `border border-quartz p-4 rounded w-full ${className}`,
      section: 'flex items-center px-2 border-b border-quartz1 min-h-[4rem]',
      text: 'font-medium px-2 text-grayText text-[0.75rem] max-w-[7.125rem] w-full',
      navLinkDefault: 'text-blackText hover:text-blackText',
      statusNavLink: 'flex items-center text-blackText hover:text-blackText min-h-[4rem] w-full',
      settingsButton: `flex justify-self-start items-center justify-center border border-dGray px-[0.75rem] py-[0.375rem] bg-white font-medium rounded text-[0.875rem] text-blackText h-[1.75rem] hover:no-underline
      ${
        isOwner || isSettings
          ? 'hover:text-blackText hover:shadow-[0_0_0_1px_rgb(180,180,187,1)] transition-all duration-300 ease-[cubic-bezier(0.25, 0.46, 0.45, 0.94)]'
          : 'pointer-events-none text-dGray border-dGray'
      }`,
    };

    return (
      <div className={commonClassNames.container}>
        <div className={`${commonClassNames.section} cursor-default`}>
          <span className={commonClassNames.text}>{t('ACCOUNTS_PAGE_TEXT.tableHead.filial')}</span>
          <div className='flex flex-col items-start justify-start'>
            {filialData.sendOutAccount ? (
              <NavLink
                onClick={setChosenFilialId}
                to={getLinkHref({ route: PATH_NAMES.MAILING, accId: filialData?.accId })}
                className={`flex flex-wrap items-center h-16 ${commonClassNames.navLinkDefault}`}>
                <span className='mr-2'>{filialData.address}</span>
                <span className='bg-lightGray rounded-full py-0.5 px-[0.375rem] text-[0.75rem] text-darkGray'>
                  {t('ACCOUNTS_PAGE_TEXT.filialCard.sendOutAccountTag')}
                </span>
              </NavLink>
            ) : (
              <NavLink
                onClick={setChosenFilialId}
                to={getLinkHref({ route: PATH_NAMES.CONNECTION, accId: filialData?.accId })}
                className={`flex items-center h-16 ${commonClassNames.navLinkDefault}`}>
                {filialData.address}
              </NavLink>
            )}
          </div>
        </div>
        <div className={commonClassNames.section}>
          <span className={commonClassNames.text}>{t('ACCOUNTS_PAGE_TEXT.tableHead.status')}</span>
          <NavLink
            onClick={setChosenFilialId}
            to={getLinkHref({ route: PATH_NAMES.CONNECTION, accId: filialData?.accId })}
            className={commonClassNames.statusNavLink}>
            <FilialStatus status={filialData.status} className='!h-5 !w-5' />
            <span className='font-medium ml-[0.75rem]'>
              {filialData.status === CONNECTION_STATUSES.AUTHENTICATED
                ? t('ACCOUNTS_PAGE_TEXT.filialCard.connectionStatus.connected')
                : t('ACCOUNTS_PAGE_TEXT.filialCard.connectionStatus.notConnected')}
            </span>
          </NavLink>
        </div>
        <div className={commonClassNames.section}>
          <span className={commonClassNames.text}>
            {t('ACCOUNTS_PAGE_TEXT.tableHead.templates')}
          </span>
          <NavLink
            onClick={setChosenFilialId}
            to={getLinkHref({ route: PATH_NAMES.TEMPLATE_LIST_ALL, accId: filialData?.accId })}
            className={`leading-[4rem] w-full ${commonClassNames.navLinkDefault}`}>
            {filialData.quantityOfTemplates}
          </NavLink>
        </div>
        <div className={commonClassNames.section}>
          <span className={commonClassNames.text}>
            {t('ACCOUNTS_PAGE_TEXT.tableHead.startedMailing')}
          </span>
          <NavLink
            onClick={setChosenFilialId}
            to={getLinkHref({ route: PATH_NAMES.STARTED_MAILING, accId: filialData?.accId })}
            className={`leading-[4rem] w-full ${commonClassNames.navLinkDefault}`}>
            {filialData.actionSendOut}
          </NavLink>
        </div>
        <div className={commonClassNames.section}>
          <span className={commonClassNames.text}>{t('ACCOUNTS_PAGE_TEXT.tableHead.payment')}</span>
          <NavLink
            onClick={setChosenFilialId}
            to={getLinkHref({ route: PATH_NAMES.PAY, accId: filialData?.accId })}
            className={`w-full ${commonClassNames.navLinkDefault}`}>
            <div className='flex items-center'>
              <Status
                isPayStatus
                status={filialData.payStatus}
                title={t('ACCOUNTS_PAGE_TEXT.filialCard.paymentTitle')}
                className='mr-[0.75rem]'
              />
              <div className='flex flex-wrap'>
                <span className='font-medium mr-1'>
                  {filialData.payStatus
                    ? t('ACCOUNTS_PAGE_TEXT.filialCard.paymentStatus.paidUntil')
                    : t('ACCOUNTS_PAGE_TEXT.filialCard.paymentStatus.notPaid')}
                </span>
                {filialData.payStatus ? (
                  <span className='font-medium'>{filialData.payPeriod}</span>
                ) : null}
              </div>
            </div>
          </NavLink>
        </div>
        <div className={commonClassNames.section}>
          <span className={commonClassNames.text}>
            {t('ACCOUNTS_PAGE_TEXT.tableHead.settings')}
          </span>
          <NavLink
            onClick={setChosenFilialId}
            to={getLinkHref({ route: PATH_NAMES.SETTINGS, accId: filialData?.accId })}
            className={commonClassNames.settingsButton}>
            {t('ACCOUNTS_PAGE_TEXT.filialCard.settingsButton')}
          </NavLink>
        </div>
      </div>
    );
  },
);

FilialCard.displayName = 'FilialCard';
