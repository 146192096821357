import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//
import { ADD_LEAD_TOAST } from '@const/addLead';
import { TRejectResponseData } from '@models/index';
import { COMMON_TOAST, REG_EXP } from '@const/common';
import { addLidsEmptyData } from '@redux/addLeads/mockData';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { REJECT_RESPONSE_KEY, REJECT_RESPONSE_2_KEY } from '@api/types';
import { getLeadDataThunk, postLeadDataThunk } from '@redux/addLeads/addLeadsThunk';
import { ADD_LEADS_KEY_NAMES, IInitialSlice, initialState } from '@redux/addLeads/initialState';

import { TAddLeadData } from './models';

export const addLeadsSlice = createSlice({
  name: 'addLeadSlice',
  initialState,
  reducers: {
    leadOnChangeHandler(
      state: IInitialSlice,
      action: PayloadAction<{ name: keyof TAddLeadData; value: string }>,
    ) {
      const { name, value } = action.payload;

      switch (name) {
        case ADD_LEADS_KEY_NAMES.PHONE: {
          state.newLeadData[name] = value.replace(REG_EXP.onlyNumbers, '');
          break;
        }
        default: {
          state.newLeadData[name] = value;
        }
      }
    },
    clearAllErrorsAL(state: IInitialSlice) {
      state.isError = false;
      state.isLoading = false;
      state.errorMessage = '';
      state.successMessage = '';
    },
  },
  extraReducers: builder => {
    builder.addCase(getLeadDataThunk.pending, (state: IInitialSlice) => {
      state.isError = false;
      state.isLoading = true;
      state.errorMessage = '';
      state.successMessage = '';
    });
    builder.addCase(
      getLeadDataThunk.fulfilled,
      (state: IInitialSlice, action: PayloadAction<TAddLeadData[]>) => {
        state.isLoading = false;
        state.tableData = action.payload.reverse();
      },
    );
    builder.addCase(
      getLeadDataThunk.rejected,
      (state: IInitialSlice, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
              state.isLoading = false;
            } else {
              return;
            }
          }
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
            state.isLoading = false;
          }
        } else {
          state.isError = true;
          state.isLoading = false;
        }
        state.isLoading = false;
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
    builder.addCase(postLeadDataThunk.pending, (state: IInitialSlice) => {
      state.isError = false;
      state.errorMessage = '';
      state.isCreateLead = true;
      state.successMessage = '';
    });
    builder.addCase(
      postLeadDataThunk.fulfilled,
      (state: IInitialSlice, action: PayloadAction<TAddLeadData[]>) => {
        state.isCreateLead = false;
        state.newLeadData = addLidsEmptyData;
        state.tableData = action.payload.reverse();
        state.successMessage = ADD_LEAD_TOAST.SUCCESS;
      },
    );
    builder.addCase(
      postLeadDataThunk.rejected,
      (state: IInitialSlice, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
              state.isCreateLead = false;
            } else {
              return;
            }
          }
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
            state.isCreateLead = false;
          }
        } else {
          state.isError = true;
          state.isCreateLead = false;
        }
        state.isCreateLead = false;
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
  },
});

export const addLeadsReducer = addLeadsSlice.reducer;
export const { leadOnChangeHandler, clearAllErrorsAL } = addLeadsSlice.actions;
