import React, { memo, useCallback, useMemo } from 'react';
//
import { Label } from '@atoms/label';
import { Chips } from '@uikit/Chips';
import { THelpKeys } from '@models/index';
import { PhoneNumberInput } from '@atoms/phoneNumberInput';
import { IconWithTooltips } from '@components/iconWithTooltips';
import { TFocusToInput, TDeleteBalloonSet } from '@shared/types';

type TProps = {
  /**
   * Массив данных для отображения Chips components
   * @param {string[]}
   */
  value: string[];
  /**
   * Id на input для ввода нового значения
   * @param {string}
   */
  inputId: string;
  /**
   * Значение input
   * @param {boolean}
   */
  newValue: string;
  /**
   * Вариант вариант текста в label, берется по ключу из словаря
   * @param {THelpKeys}
   */
  variant: THelpKeys;
  /**
   * Скрывает label элемент
   * @param {boolean}
   */
  noLabel?: boolean;
  /**
   * Опциональный параметр tailwind класс для изменения цвета текста label
   * @param {string}
   * @default
   */
  textColor?: string;
  /**
   * Опциональный параметр placeholder текст в input
   * @param {string}
   * @default
   */
  placeholder?: string;
  /**
   * Опциональный параметр показывает или скрывает иконку информации с всплывающей подсказкой
   * @param {boolean}
   * @default
   */
  isShowInfoIcon?: boolean;
  /**
   * Callback для изменения нового значения
   * @param {(value: string) => void}
   */
  setNewValue: (value: string) => void;
  /**
   * Callback для удаления Chips component
   * @param {TDeleteBalloonSet}
   */
  deleteBalloon: TDeleteBalloonSet;
  /**
   * Callback для добавления Chips component при событии blur
   * @param {(value: string) => void;}
   */
  addBalloonOnBlur: (value: string) => void;
  /**
   * Опциональный параметр задает размер текста в заголовке передавать стили tailwind
   * @param {string}
   */
  textSize?: string;
  /**
   * Опциональный параметр, отключает возможность ввести данне в инпуты
   * @param {boolean}
   */
  disabled?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const FormBlackListInput = memo(
  ({
    value,
    variant,
    noLabel,
    inputId,
    newValue,
    textSize = '',
    textColor,
    placeholder = '',
    setNewValue,
    deleteBalloon,
    isShowInfoIcon = false,
    addBalloonOnBlur,
    className = '',
    disabled = false,
  }: TProps) => {
    // Устанавливает фокус на поле ввода, фокус устанавливается при клике на контейнер в котором расположен элемент
    const focusToInput: TFocusToInput = useCallback(event => {
      const inputElement = event.currentTarget.querySelector('input');
      inputElement?.focus();
    }, []);

    // Добавляет новый "шарик", если введенные данные валидны, в противном случае очищает поле ввода
    const addBalloons = useCallback(
      (phone: string) => {
        addBalloonOnBlur(phone);
      },
      [addBalloonOnBlur],
    );

    // Подготовка "шариков" для рендеринга
    const balloons = useMemo(
      () =>
        value.map((item, index) => {
          const key = `${index}${item}`;
          return (
            <Chips
              key={key}
              removable
              type='def'
              tag={item}
              color='danger'
              onRemove={deleteBalloon(item, inputId)}
              className='tracking-wide mb-2.5 mr-2.5'
              disabled={disabled}
            />
          );
        }),
      [value, deleteBalloon, inputId, disabled],
    );

    return (
      <div className={`flex-col ${className}`} onClick={focusToInput}>
        {!noLabel ? (
          <Label
            htmlFor={inputId}
            variant={variant}
            className={`${textColor || 'text-grayText'} ${textSize} mb-2.5`}>
            {isShowInfoIcon ? <IconWithTooltips tooltips={variant} className='mb-0.5' /> : null}
          </Label>
        ) : null}
        <PhoneNumberInput
          value={newValue}
          disabled={disabled}
          onBlur={addBalloons}
          placeholder={placeholder}
          onChangeHandler={setNewValue}
          className='mb-2'
        />
        <div className='flex items-center flex-wrap w-full'>{balloons}</div>
      </div>
    );
  },
);

FormBlackListInput.displayName = 'FormBlackListInput';
