import React, { memo } from 'react';
import { Button } from '@uikit/Button';
import { TSimpleStringObj } from '@models/index';
import { NavItem } from '@blocks/templateNavItem';
import { QUERY_PARAMS } from '@const/apiConstants';
import { NavDropDown } from '@components/navDropDown';

type TProps = {
  /**
   * Путь из URL полученный хуком useLocation в родительском компоненте
   * @param {string}
   */
  path: string;
  /**
   * Параметр для отключения кнопки "новая задача"
   * @param {boolean}
   */
  isDisable: boolean;
  /**
   * Callback на кнопку Новый шаблон
   * @param {() => void}
   */
  callBack: () => void;
  /**
   * Id выбранного филиала используется для формирования ссылок
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Объект с данными для формирования массива ссылок длья навигации
   * @param {TSimpleStringObj}
   */
  pathNameObj: TSimpleStringObj;
  /**
   * Текст в кнопку
   * @param {string}
   */
  newButtonText: string;
  /**
   * Определяет доступ пользователя для редактирования шаблонов.
   * @param {boolean}
   */
  isEditTemplatesAccess?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const PageNavigate = memo(
  ({
    path,
    callBack,
    isDisable,
    pathNameObj,
    newButtonText,
    selectedFilialAccId,
    isEditTemplatesAccess,
    className = '',
  }: TProps) => {
    const pageData = Object.entries(pathNameObj);

    const linksToRender = pageData.map(([link, text], index) => {
      const isLastElement = index === pageData.length - 1;

      const fullLink = `${link}?${QUERY_PARAMS.ACC_ID}=${selectedFilialAccId}`;

      return (
        <NavItem
          key={text}
          text={text}
          to={fullLink}
          isActive={path.includes(link)}
          className={`mb-4 ${!isLastElement ? 'mr-2' : ''}`}
        />
      );
    });

    return (
      <div className={className}>
        <div className='flex flex-wrap sm:flex-nowrap justify-between'>
          <div className='mb-4 lg:hidden w-full'>
            <NavDropDown
              pageNameDict={pathNameObj}
              selectedFilialAccId={selectedFilialAccId}
              className='nav_dropdown sm:max-w-[20rem]'
            />
          </div>
          <div className='hidden flex-wrap lg:flex '>{linksToRender}</div>
          {isEditTemplatesAccess ? (
            <Button
              dense
              icon='plus'
              type='action'
              view='filled'
              color='success'
              onClick={callBack}
              disabled={isDisable}
              text={newButtonText}
              className='min-w-[12rem] w-full sm:w-auto'
            />
          ) : null}
        </div>
      </div>
    );
  },
);

PageNavigate.displayName = 'PageNavigate';
