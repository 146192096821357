import React, { memo } from 'react';
//
import { TCurrency } from '@api/types';
import { TAppLanguage } from '@models/index';
import { ChangeModal } from '@components/changeModal';
import { GetBillModal } from '@components/billDownloadModal';
import { ConnectAutoPayModal } from '@components/connectAutoPayModal';
import { AutoPayDisableModal } from '@components/autoPayDisableModal';

type TPayPageModals = {
  /**
   * Валюта для оплаты
   * @param {TCurrency}
   */
  currency: TCurrency;
  /**
   * Строка с данными о филиале
   * @param {string}
   */
  rate: string;
  /**
   * Имя филиала
   * @param {string}
   */
  filialName: string;
  /**
   * Ссылка для включения автоплатежа
   * @param {string}
   */
  autoPayLink: string;
  /**
   * Callback для перезагрузки страницы
   * @param {() => void}
   */
  reloadPage: () => void;
  /**
   * Количество сотрудников в филиале
   * @param {string}
   */
  branchEmployees: string;
  /**
   * Callback для закрытия всех модалок
   * @param {() => void}
   */
  closeAllModal: () => void;
  /**
   * Ссылка дла изменения карты автоплатежа
   * @param {string}
   */
  changeCardDataLink: string;
  /**
   * Флаг открытия модального окна изменения карты автоплатежа
   * @param {boolean}
   */
  isChangeModalOpen: boolean;
  /**
   * Callback для закрытия модалки изменения карты автоплатежа
   * @param {() => void}
   */
  changeModalClose: () => void;
  /**
   * Callback для закрытия модалки получения счета для оплаты
   * @param {() => void}
   */
  getBillModalClose: () => void;
  /**
   * Сумма выбранного периода
   * @param {number}
   */
  totalAmount: number;
  /**
   * Callback для загрузки счета
   * @param {() => void}
   */
  downloadBillHandler: () => void;
  /**
   * Callback для отправки счета в TG счета
   * @param {() => void}
   */
  sendBillInTGHandler: () => void;
  /**
   * Флаг открытия модального окна получения счета для оплаты
   * @param {boolean}
   */
  isBillDownloadModalOpen: boolean;
  /**
   * Флаг открытия модального окна для отключения автоплатежа
   * @param {boolean}
   */
  isAutoPayDisableModalOpen: boolean;
  /**
   * Флаг открытия модального окна для подключения автоплатежа
   * @param {boolean}
   */
  isConnectAutoPayModalOpen: boolean;
  /**
   * Callback для закрытия модалки подключения автоплатежа
   * @param {() => void}
   */
  connectAutoPayModalClose: () => void;
  /**
   * Флаг начала процесса получения ссылки на отключение автоплатежа
   * @param {boolean}
   */
  turnOffAutoPaymentIsLoading: boolean;
  /**
   * Флаг успешного получения ссылки на отключение автоплатежа
   * @param {boolean}
   */
  turnOffAutoPaymentIsSuccess: boolean;
  /**
   * Callback для отключения автоплатежа
   * @param {function}
   */
  turnOffAutoPaymentHandler: () => void;
  /**
   * Флаг показывает состояние запроса для отправки счета в TG
   * @param {boolean}
   */
  isSendingInvoiceToTg: boolean;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Опциональный параметр - строка классов
   * @param {string}
   */
  className?: string;
};

export const PayPageModals = memo(
  ({
    rate,
    currency,
    filialName,
    reloadPage,
    autoPayLink,
    appLanguage,
    totalAmount,
    closeAllModal,
    branchEmployees,
    changeModalClose,
    getBillModalClose,
    isChangeModalOpen,
    changeCardDataLink,
    downloadBillHandler,
    sendBillInTGHandler,
    isSendingInvoiceToTg,
    isBillDownloadModalOpen,
    connectAutoPayModalClose,
    isAutoPayDisableModalOpen,
    isConnectAutoPayModalOpen,
    turnOffAutoPaymentHandler,
    turnOffAutoPaymentIsSuccess,
    turnOffAutoPaymentIsLoading,
    className = '',
  }: TPayPageModals) => {
    return (
      <div className={className}>
        {isConnectAutoPayModalOpen ? (
          <ConnectAutoPayModal
            rate={rate}
            reloadPage={reloadPage}
            autoPayLink={autoPayLink}
            connectAutoPayModalClose={connectAutoPayModalClose}
          />
        ) : null}
        {isChangeModalOpen ? (
          <ChangeModal
            rate={rate}
            reloadPage={reloadPage}
            changeModalClose={changeModalClose}
            changeCardDataLink={changeCardDataLink}
          />
        ) : null}
        {isAutoPayDisableModalOpen ? (
          <AutoPayDisableModal
            rate={rate}
            reloadPage={reloadPage}
            closeAllModal={closeAllModal}
            turnOffAutoPaymentHandler={turnOffAutoPaymentHandler}
            turnOffAutoPaymentIsLoading={turnOffAutoPaymentIsLoading}
            turnOffAutoPaymentIsSuccess={turnOffAutoPaymentIsSuccess}
          />
        ) : null}
        {isBillDownloadModalOpen ? (
          <GetBillModal
            currency={currency}
            filialName={filialName}
            totalAmount={totalAmount}
            appLanguage={appLanguage}
            branchEmployees={branchEmployees}
            closeModalCallback={getBillModalClose}
            downloadBillCallback={downloadBillHandler}
            sendBillInTgCallback={sendBillInTGHandler}
            isSendingInvoiceToTg={isSendingInvoiceToTg}
          />
        ) : null}
      </div>
    );
  },
);

PayPageModals.displayName = 'PayPageModals';
