import { TSimpleStringObj } from '@models/index';
import { TInviteActionRecordLabel } from '@api/types';

export type TInviteFriendsKeys = {
  common: TSimpleStringObj;
};

export type TCardType = {
  id: number;
  title: string;
  salonGroupId: number;
};

export type TActionData = {
  title: string;
  oldTitle: string;
  cardType: TCardType;
  bonusType: string;
  loyaltyPoints: string;
  interestRegularCustomer: string;
  newCustomerPoints: string;
  rangeOfVisits: string;
  firstMessage: string;
  secondMessage: string;
  services: string[];
  categories: string[];
  staff: string[];
  recordLabels: TInviteActionRecordLabel[];
  clientCategory: string[];
  afterEnd: string;
  beforeStart: string;
  allDayRecsNotifications: {
    day: string;
    time: string;
  };
  visits: string[];
  blacklist: string[];
  active: boolean;
  sendingBonuses: boolean;
  id: string;
  whenSend: string;
  whenSend2: string;
  whenSend2Days: string;
  whenSend2Min: string;
  whenTime: string;
  onlineRecord: boolean;
  dateCreateTemplate: string | null;
  dateFirstActivateTemplate: string | null;
  loyaltyPermissionStatus: boolean;
};

export type TIFBalloonsKeyNames =
  | 'services'
  | 'categories'
  | 'staff'
  | 'clientCategory'
  | 'blacklist';

export type TBonusValueInputs = 'loyaltyPoints' | 'newCustomerPoints' | 'interestRegularCustomer';

export enum ACTION_DATA_KEY_NAMES {
  TITLE = 'title',
  OLD_TITLE = 'oldTitle',
  ACTIVE = 'active',
  FIRST_MESSAGE = 'firstMessage',
  SECOND_MESSAGE = 'secondMessage',
  CLIENT_CATEGORY = 'clientCategory',
  STAFF = 'staff',
  CATEGORIES = 'categories',
  SERVICES = 'services',
  BLACKLIST = 'blacklist',
  ONLINE_RECORD = 'onlineRecord',
  LOYALTY_POINTS = 'loyaltyPoints',
  NEW_CUSTOMER_POINTS = 'newCustomerPoints',
  INTEREST_REGULAR_CUSTOMER = 'interestRegularCustomer',
}

export type TIFTextareaNames =
  | ACTION_DATA_KEY_NAMES.FIRST_MESSAGE
  | ACTION_DATA_KEY_NAMES.SECOND_MESSAGE;
