import { TMediaFileType, TSimpleStringObj } from '@models/index';

export type TTemplatePageData = {
  [key: string]: string | boolean | string[] | null | File | TMediaFileType;
  id: string;
  statusEnable: boolean;
  title: string;
  oldTitle: string;
  textWhatsapp: string;
  textSms: string;
  files: File | null;
  mediaFile: File | null;
  mediaFileType: TMediaFileType;
  mediaFileName: string;
  mediaFileURL: string | null;
  whenSend: string;
  whenSend2: string;
  whenSend2Days: string;
  whenSend2Min: string;
  whenTime: string;
  error: boolean;
  recordTag: string[];
  services: string[];
  categories: string[];
  products: string[];
  staff: string[];
  clientCategory: string[];
  clientDidNotCome: boolean;
  clientExpectation: boolean;
  clientCame: boolean;
  clientConfirmed: boolean;
  visits: string;
  quantityFrom: string;
  quantityTo: string;
  blacklist: string[];
  sendOnlyLostClient: boolean;
  onlineRecord: boolean;
  confirmRecord: boolean;
  textConfirmation: string;
  textCancel: string;
  thoseWhoWrote: string;
  integrationLink: string;
};

export enum TEMPLATE_DATA_KEY_NAMES {
  ID = 'id',
  STATUS_ENABLE = 'statusEnable',
  TEXT_WHATSAPP = 'textWhatsapp',
  TEXT_SMS = 'textSms',
  FILES = 'files',
  MEDIA_FILE_TYPE = 'mediaFileType',
  MEDIA_FILE_NAME = 'mediaFileName',
  MEDIA_FILE_URL = 'mediaFileURL',
  WHEN_SEND = 'whenSend',
  WHEN_SEND2 = 'whenSend2',
  WHEN_SEND2_DAY = 'whenSend2Day',
  WHEN_TIME = 'whenTime',
  ERROR = 'error',
  SERVICES = 'services',
  CATEGORIES = 'categories',
  PRODUCTS = 'products',
  RECORD_TAG = 'recordTag',
  STAFF = 'staff',
  CLIENT_CATEGORY = 'clientCategory',
  CLIENT_DID_NOT_COME = 'clientDidNotCome',
  CLIENT_EXPECTATION = 'clientExpectation',
  CLIENT_CAME = 'clientCame',
  CLIENT_CONFIRMED = 'clientConfirmed',
  VISITS = 'visits',
  QUANTITY_FROM = 'quantityFrom',
  QUANTITY_TO = 'quantityTo',
  BLACKLIST = 'blacklist',
  SEND_ONLY_LOST_CLIENTS = 'sendOnlyLostClient',
  ONLINE_RECORD = 'onlineRecord',
  CONFIRM_RECORD = 'confirmRecord',
  TEXT_CONFIRMATION = 'textConfirmation',
  TEXT_CANCEL = 'textCancel',
  THOSE_WHO_WROTE = 'thoseWhoWrote',
}

export type TSaveTemplateData = {
  formData: FormData;
  templateData: TTemplatePageData;
};

export type TCopyTemplateData = {
  branchList: string[];
  templateId: string;
};

export type TTemplateKeys = {
  [key: string]: TSimpleStringObj;
  common: TSimpleStringObj;
  birthday: TSimpleStringObj;
  mailing: TSimpleStringObj;
  integration: TSimpleStringObj;
};

export type TKeyData = {
  keyName: string;
  start: number;
  end: number;
};

export type TTextareaNames =
  | TEMPLATE_DATA_KEY_NAMES.TEXT_WHATSAPP
  | TEMPLATE_DATA_KEY_NAMES.TEXT_SMS;
