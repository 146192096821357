import { createAsyncThunk } from '@reduxjs/toolkit';
import { reviewsApi } from '@api/reviewsApi';
import { TGetReviewStatsRequest } from '@api/types';
import { TRejectResponseData } from '@models/index';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { mapStatsDataToStore } from '@redux/reviewsStats/mappers';
import { TFullStatsData, TStatsRequestData } from '@redux/reviewsStats/types';

/**
 * Thunk-экшен для получения данных статистики цепочек отзывов.
 * @returns {TFullStatsData} - Данные статистики.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
export const getStatsDataThunk = createAsyncThunk<
  TFullStatsData | void,
  TStatsRequestData,
  { rejectValue: TRejectResponseData }
>('reviewStats/getStatsDataThunk', (data, { dispatch, rejectWithValue }) => {
  const requestData: TGetReviewStatsRequest = {
    sort: 'total_sends',
    sort_desc: data.sortDesc,
    date_start: `${data.dateStart.slice(0, -9)}T00:00:00`,
    date_end: `${data.dateEnd.slice(0, -9)}T23:59:59`,
    page_size: data.pageSize,
    page: data.page,
  };

  return reviewsApi
    .getStatsData(requestData)
    .then(response => {
      if ('data' in response.data) {
        return mapStatsDataToStore(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getStatsDataThunk(data)), 200);
      }

      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшен для отправки данных статистики цепочек отзывов в TG.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
export const sendStatsDataToTGThunk = createAsyncThunk<
  void,
  void,
  { rejectValue: TRejectResponseData }
>('reviewStats/sendStatsDataToTGThunk', (_, { dispatch, rejectWithValue }) => {
  return reviewsApi
    .sendStatsDataToTG()
    .then(() => {
      // console.log(response.data)
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(sendStatsDataToTGThunk()), 200);
      }

      return rejectWithValue(error.response.data);
    });
});
