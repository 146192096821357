import React, { memo } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import Component from './Divider';

const propTypes = {
  /**
   ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    "default",
    "primary",
    "warning",
    "danger",
    "success",
    "primary_alt",
    "warning_alt",
    "danger_alt",
    "success_alt"
  ]),
  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className: PropTypes.string,
  /**
   * Optional header title.
   */
  title: PropTypes.string
};

type TComponentPropsType = InferProps<typeof propTypes>;

interface IComponentProps extends TComponentPropsType {
  [key: string]: any;
}

export const MenuDivider: React.FC<IComponentProps> = memo((props) => <Component {...props} />)