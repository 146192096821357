import { TAccessDataItem, TAccessDataItemResponse } from '@redux/access/models';

/**
 * Преобразует данные ответа из API в данные для хранилища.
 *
 * @param {TAccessDataItemResponse[]} data - Данные ответа из API.
 * @return {TAccessDataItem[]} Отображенные данные в формате хранилища.
 */
const mapResponseDataToStore = (data: TAccessDataItemResponse[]): TAccessDataItem[] => {
  return data.map(({ user_id, name, rights }) => ({
    userId: String(user_id),
    name,
    rights,
  }));
};

/**
 * Преобразует данные доступа в формат для сохранения.
 *
 * @param {TAccessDataItem[]} data - Массив данных доступа.
 * @returns {object} - Объект в формате для сохранения.
 * @property {number} count - Количество элементов.
 * @property {object[]} data - Массив данных.
 * @property {number} data[].user_id - Идентификатор пользователя.
 * @property {string} data[].name - Имя пользователя.
 * @property {string[]} data[].rights - Права доступа пользователя.
 */
export const mapAccessDataToSave = (data: TAccessDataItem[]) => {
  return {
    count: data.length,
    data: [
      ...data.map(({ userId, name, rights }) => ({
        user_id: +userId,
        name,
        rights,
      })),
    ],
  };
};

export const accessMappers = {
  mapResponseDataToStore,
  mapAccessDataToSave,
};
