import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart } from '@components/barChart';
//
import { TEMPLATE_DETAIL_TABS } from '@const/templates';
import { TTemplateStatisticData, TTemplateStatisticDataByTime } from '@redux/templateStats/models';

type TTemplateStatChartProps = {
  /**
   * Варионт отображения данных графика в зависимости от выбранного вида отображения версия\период
   */
  version: TEMPLATE_DETAIL_TABS;
  /**
   * Данные шаблона
   * @param {TTemplateStatisticData}
   */
  template: TTemplateStatisticData | TTemplateStatisticDataByTime;
  /**
   * Опциональный параметр строки классов
   * @param {string}
   */
  className?: string;
};

export const TemplateStatChart = memo(
  ({ template, className = '', version }: TTemplateStatChartProps) => {
    const { t } = useTranslation();

    // Создаем массив названий для колонок чарта
    const columnLabels = useMemo(() => {
      return template.data.map(item => {
        if (version === TEMPLATE_DETAIL_TABS.TIME && 'period' in item) {
          return item.period;
        }

        if (version === TEMPLATE_DETAIL_TABS.VERSION && 'version' in item) {
          return t('TEMPLATE_PAGE_TEXT.statistics.version', { text: item.version });
        }

        return '';
      });
    }, [t, template, version]);

    // Создаем массив данных для чарта
    const chartData = useMemo(() => {
      const answeredRate = template.data.map(item => item.answeredRate);
      const recordedRate = template.data.map(item => item.recordedRate);

      return [
        {
          label: t('TEMPLATE_PAGE_TEXT.statistics.answered'),
          data: answeredRate,
        },
        {
          label: t('TEMPLATE_PAGE_TEXT.statistics.registered'),
          data: recordedRate,
        },
      ];
    }, [t, template]);

    return (
      <div
        className={`relative border border-lightGray flex flex-col grow shrink font-inter text-[1.25rem] text-blackText rounded-lg p-[1.5rem] mb-[2.5rem] w-full ${className}`}>
        <div className='mb-[1.5rem]'>{t('TEMPLATE_PAGE_TEXT.statistics.percent')}</div>
        <BarChart
          columnLabels={columnLabels}
          chartTitle={t('TEMPLATE_PAGE_TEXT.statistics.percent')}
          data={chartData}
        />
      </div>
    );
  },
);

TemplateStatChart.displayName = 'TemplateStatChart';
