import React, { memo, useCallback, useState } from 'react';
//
import { Icon } from '@blueprintjs/core';
import { useCopyToClipboard } from '@hooks/useClipboard';

interface IPartnersPageLinkBlockProps {
  /**
   * Текст в поле для копирования в буфер обмена
   * @param {string}
   */
  link: string;
  /**
   * Подпись для переданного текста в параметре link
   * @param {string}
   */
  labelText: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const PartnersPageLinkBlock = memo(
  ({ link, labelText, className = '' }: IPartnersPageLinkBlockProps) => {
    // Из хука получаем функцию которая копирует в буфер обмена переданную строку
    const [, copy] = useCopyToClipboard();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    // Функция использует функцию copy из хука useCopyToClipboard, сохраняет переданную строку
    // в буфер обмена, если копирование прошло успешно, выставляет флаг isSuccess в true
    // запускает таймаут и через 2 секунды в isSuccess записывается false
    const copyToClipboardHandler = useCallback(() => {
      copy(link)
        .then(() => {
          setIsSuccess(true);
          setTimeout(() => setIsSuccess(false), 2000);
        })
        .catch(() => setIsSuccess(false));
    }, [copy, link]);

    return (
      <div className={className}>
        <p className='leading-6 text-body_text text-stormGray tracking-[0.0275em] w-full'>
          {labelText}
        </p>
        <div className='bg-gray1 rounded flex py-2 px-4 h-14 w-full'>
          <p className='border border-dGray border-b flex items-center rounded overflow-hidden h-10 px-3 py-2 text-blackText whitespace-pre w-full'>
            {link}
          </p>
          <div className='flex items-center justify-end cursor-pointer p-2 ml-2 min-w-[3rem]'>
            <Icon
              size={16}
              onClick={copyToClipboardHandler}
              htmlTitle='Скопировать в буфер обмена'
              color={isSuccess ? 'green' : '#4A4B57'}
              icon={isSuccess ? 'tick' : 'duplicate'}
            />
          </div>
        </div>
      </div>
    );
  },
);

PartnersPageLinkBlock.displayName = 'PartnersPageLinkBlock';
