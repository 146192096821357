import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Spinner } from '@uikit/Spinner';

interface IFileIsProcessingProps {
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const FileIsProcessing = memo(({ className = '' }: IFileIsProcessingProps) => {
  const { t } = useTranslation();

  return (
    <div className={`flex mt-6 ${className}`}>
      <Spinner view='smooth' color='success' size='20' />
      <p className='font-medium text-black leading-6 tracking-[0.022em] ml-3'>
        {t('MAILING_PAGE_TEXT.fileProcessed')}
      </p>
    </div>
  );
});

FileIsProcessing.displayName = 'FileIsProcessing';
