import { createAsyncThunk } from '@reduxjs/toolkit';
import { TRejectResponseData } from '@models/index';
import { partnerProgramApi } from '@api/partnerProgramApi';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { TPartnersFullTableStoreData } from '@redux/partnersFullTable/models';
import { GET_FULL_TABLE_RESPONSE_KEY_NAME, REJECT_RESPONSE_KEY } from '@api/types';
import { mapGetFullTableDataToStore } from '@redux/partnersFullTable/partnerFullTableMappers';

/**
 * Thunk Получает данные полной таблицы партнеров и обрабатывает результат.
 * @returns {TPartnersFullTableStoreData | void} - Данные полной таблицы партнеров или в undefined.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const getPartnerFullTableData = createAsyncThunk<
  TPartnersFullTableStoreData | void,
  void,
  { rejectValue: TRejectResponseData }
>('partnerFullTableSlice/getPartnerFullTableData', (_, { dispatch, rejectWithValue }) => {
  return partnerProgramApi
    .getFullTableData()
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(response.data.status);
      }
      if (GET_FULL_TABLE_RESPONSE_KEY_NAME.DATA in response.data) {
        return mapGetFullTableDataToStore(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getPartnerFullTableData()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

export { getPartnerFullTableData };
