import { getEUDateFromString } from '@helpers/index';
import { TGetFullTableResponse, TGetOperationListTableData } from '@api/types';
import { TMappedOperationListTableData } from '@redux/operationListTable/models';

export const operationListTableMappers = {
  mapGetOperationListTableData(
    response: TGetFullTableResponse<TGetOperationListTableData>,
  ): TMappedOperationListTableData {
    const tableData = response.data.map(
      ({ branch, operation_date, income_amount, accrued_amount, branch_name, status }) => ({
        branch,
        operationDate: getEUDateFromString(operation_date),
        paySum: income_amount,
        sumToPay: accrued_amount,
        filialName: branch_name,
        status,
      }),
    );

    return { data: tableData, totalAccrued: response?.total_accrued || '0' };
  },
};
