import { TGetPayHistorySalonTableData } from '@api/types';
import { convertDateString, numWord } from '@helpers/index';
import { TPayHistorySalonTableData } from '@redux/payHistorySalonTable/models';

type TMapGetPayHistorySalonTableData = (
  data: TGetPayHistorySalonTableData,
) => TPayHistorySalonTableData[];

/**
 * Преобразует данные полученные из getPayHistorySalonTable в структуру данных хранилища Redux.
 * @param {TGetPayHistorySalonTableData} data - Данные полученные из getPayHistorySalonTable.
 * @returns {TPayHistorySalonTableData[]} - Преобразованные данные для хранилища Redux.
 */
const mapGetPayHistorySalonTableData: TMapGetPayHistorySalonTableData = data => {
  return Object.entries(data).map(([period, monthsAccrued]) => {
    const formattedPeriod = convertDateString(String(period));
    const formattedMonthsAccrued = `${monthsAccrued} ${numWord(monthsAccrued, [
      'месяц',
      'месяца',
      'месяцев',
    ])}`;

    return {
      period: formattedPeriod,
      monthsAccrued: formattedMonthsAccrued,
    };
  });
};

export { mapGetPayHistorySalonTableData };
