import React, { forwardRef } from 'react';

import Component from './Checkbox';
import PropTypes, { InferProps } from 'prop-types';

const propTypes = {
  /**
   `The type of the component.
   * Variants: `dense`
   * Default value (if undefined): `def` `
   */
  type: PropTypes.oneOf(["def", "dense"]),
  /**
   ` The view of the component.
   * Variants: `flat` `smooth` `outlined` `raised`
   * Default value (if undefined): `flat` `
   */
  view: PropTypes.oneOf(["flat", "smooth", "outlined", "raised"]),
  /**
   ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    "default",
    "primary",
    "warning",
    "danger",
    "success",
    "primary_alt",
    "warning_alt",
    "danger_alt",
    "success_alt"
  ]),
  /**
   * Whether the control is checked.
   */
  checked: PropTypes.bool,
  /**
   * Text label for the control. This prop actually supports JSX elements.
   */
  label: PropTypes.any,
  /**
   * If `true`, the component will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Whether this checkbox is indeterminate
   */
  indeterminate: PropTypes.bool,
  /**
   * Second line text
   */
  helperText: PropTypes.string,
  /**
   * RTL text
   */
  rtl: PropTypes.bool,
  /**
   * Alignment of the indicator within container.
   */
  right: PropTypes.bool,
  /**
   * Whether this component should expand to fill its container.
   */
  fill: PropTypes.bool,
  /**
   * Event handler invoked when input value is changed.
   */
  onChange: PropTypes.func
};

type TComponentPropsType = InferProps<typeof propTypes>;

interface ICheckboxProps extends TComponentPropsType {
  [key: string]: any;
}

export const Checkbox:React.FC<ICheckboxProps> = forwardRef((props, ref) => <Component {...props} ref={ref} />);