import { apiService } from '@api/ApiService';
import { END_POINTS_URL } from '@const/apiConstants';
import { TChatHistoryResponse, TRequestStatus } from '@api/types';

/**
 * API страницы история сообщений
 * @module blackListApi
 */

export const chatHistoryApi = {
  /**
   * Метод получает массив сообщений
   * @method getBlackList
   * @return {Promise}
   */
  getChatHistory() {
    return apiService.get<TChatHistoryResponse | TRequestStatus>(END_POINTS_URL.CHAT_HISTORY);
  },
};
