import React, { ComponentType, ReactNode } from 'react';
import ErrorComponent from '@components/ErrorComponent';

type ErrorBoundaryState = {
  error: Error | null;
};

const withErrorBoundary = <TP extends object>(WrappedComponent: ComponentType<TP>) => {
  return class ErrorBoundary extends React.PureComponent<TP, ErrorBoundaryState> {
    constructor(props: TP) {
      super(props);
      this.state = { error: null };
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
      return { error };
    }

    render(): ReactNode {
      const { error } = this.state;

      if (error) {
        return <ErrorComponent error={error} />;
      }

      return <WrappedComponent {...this.props} />;
    }
  };
};

export default withErrorBoundary;
