import React, { memo, useEffect } from 'react';
import { NoAccess } from '@components/noAccess';
import { getAppData } from '@redux/app/selectors';
import { QUERY_PARAMS } from '@const/apiConstants';
import { useSearchParams } from 'react-router-dom';
import { ErrorMessage } from '@blocks/errorMessage';
import { getFeedbackData } from '@redux/feedback/selectors';
import { getAccountsData } from '@redux/accounts/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { InviteFriendAction } from '@templates/inviteFriendAction';
import { getInviteFriendsListPageData } from '@redux/inviteFriendList/selectors';
import { getInviteFriendsActionData } from '@redux/inviteFriendsAction/selectors';
import { actionEmptyData, newActionData } from '@redux/inviteFriendsAction/mockData';
import { getInviteFriendActionThunk } from '@redux/inviteFriendsAction/inviteFriendsActionThunk';
import {
  clearAllErrorIF,
  clearAllErrorStateIF,
  setInviteFriendsActionPageData,
} from '@redux/inviteFriendsAction/inviteFriendSlice';

type TInviteFriendsActionProps = {
  /**
   * Флаг нового шаблона
   * @param {boolean}
   */
  isNewAction: boolean;
};

const InviteFriendsActionPage = memo(({ isNewAction }: TInviteFriendsActionProps) => {
  const dispatch = useAppDispatch();
  const { appLanguage } = useAppSelector(getAppData);
  const { selectedFilial } = useAppSelector(getAccountsData);

  const [searchParams] = useSearchParams();

  const id = searchParams.get(QUERY_PARAMS.ID);

  const { isLoading: isFeedbackSending } = useAppSelector(getFeedbackData);

  const { feedbackText, fastReview } = useAppSelector(getInviteFriendsListPageData);

  const { isError, isLoading, isAccessDenied, isBlockTemplate, data, oldData } = useAppSelector(
    getInviteFriendsActionData,
  );

  useEffect(() => {
    if (isNewAction) {
      dispatch(setInviteFriendsActionPageData(newActionData));
    }
    if (id && !isNewAction) {
      dispatch(getInviteFriendActionThunk(id));
    }

    return () => {
      dispatch(clearAllErrorIF());
      dispatch(clearAllErrorStateIF());
      dispatch(setInviteFriendsActionPageData(actionEmptyData));
    };
  }, [isNewAction, id, selectedFilial?.accId, dispatch]);

  return (
    <div>
      {isAccessDenied ? <NoAccess /> : null}
      {isError ? <ErrorMessage /> : null}
      {!isAccessDenied && !isError && !isLoading ? (
        <InviteFriendAction
          actionData={data}
          actionOldData={oldData}
          fastReview={fastReview}
          isNewAction={isNewAction}
          appLanguage={appLanguage}
          feedbackText={feedbackText}
          isFeedbackSending={isFeedbackSending}
          isEditTemplatesAccess={!isBlockTemplate}
        />
      ) : null}
    </div>
  );
});

InviteFriendsActionPage.displayName = 'InviteFriendsActionPage';

export default InviteFriendsActionPage;
