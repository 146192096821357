import { TAutoConnectionFormData } from '@api/types';
import { TGetFormData, TAutoConnectionData, TGetFormRequestData } from '@redux/autoConnect/models';
import {
  AGE_OF_WHATSAPP_ACCOUNT,
  AGE_OF_WHATSAPP_ACCOUNT_KEYS,
  AGE_OF_WHATSAPP_ACCOUNT_RESPONSE,
} from '@const/autoConnect';

export const autoConnectionMappers = {
  mapResponseToStore(data: TAutoConnectionFormData): TAutoConnectionData {
    const {
      form_step,
      branch_name,
      owner_phone,
      // salon_type,
      emoji_list,
      emoji,
      salon_phone,
      wa_life_time,
      main_url,
      other_urls,
      address,
      deep_link,
      qr_code,
      from_ref,
      promo_bonus,
    } = data;
    const newEmojiList = !emoji_list
      ? []
      : emoji_list && emoji_list.filter(item => item === emoji).length
      ? emoji_list
      : emoji
      ? [emoji || '', ...emoji_list]
      : emoji_list;
    return {
      stepId: form_step,
      promoCode: from_ref,
      promoCodeData: {
        discount: promo_bonus?.discount || 0,
        bonusDays: promo_bonus?.bonus_days || 0,
      },
      salonName: branch_name,
      // salonType: salon_type || NOT_SELECTED,
      salonType: '',
      emojiList: newEmojiList || [],
      salonEmoji: emoji || '',
      salonAddress: address || '',
      salonPhoneNumber: salon_phone || '',
      ageOfWhatsappAccount:
        AGE_OF_WHATSAPP_ACCOUNT_RESPONSE[wa_life_time] || AGE_OF_WHATSAPP_ACCOUNT_KEYS.NOT_SELECTED,
      linksOnSalon: [main_url || '', ...(other_urls || [])],
      ownerPhoneNumber: owner_phone || '',
      botLink: deep_link || '',
      imageSrc: qr_code || '',
      phoneNumberForSend: '',
    };
  },
  mapDataToSave(data: TAutoConnectionData): TAutoConnectionFormData {
    const {
      stepId,
      ownerPhoneNumber,
      salonPhoneNumber,
      linksOnSalon,
      salonName,
      salonEmoji,
      emojiList,
      salonAddress,
      botLink,
      promoCode,
      ageOfWhatsappAccount,
      imageSrc,
    } = data;
    const links = linksOnSalon.filter(link => !!link);

    const wa_life_time =
      ageOfWhatsappAccount === AGE_OF_WHATSAPP_ACCOUNT_KEYS.NOT_SELECTED
        ? '0'
        : AGE_OF_WHATSAPP_ACCOUNT[ageOfWhatsappAccount];

    return {
      form_step: stepId,
      branch_name: salonName,
      from_ref: promoCode,
      owner_phone: ownerPhoneNumber,
      emoji_list: emojiList,
      emoji: salonEmoji,
      salon_phone: salonPhoneNumber,
      wa_life_time,
      main_url: links[0],
      other_urls: links.slice(1),
      address: salonAddress,
      deep_link: botLink,
      qr_code: imageSrc,
      promo_bonus: {
        bonus_days: 0,
        discount: 0,
      },
    };
  },
  getFormRequestData(data: TGetFormRequestData): TGetFormData {
    return {
      params: {
        acc_id: data.accId,
        form_hash: data.hash,
      },
    };
  },
};
