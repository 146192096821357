export type TPartnersFullTableData = {
  accId: string;
  filialName: string;
  connectDate: string;
  firstPayDate: string;
  status: string;
};

export type TPartnersFullTableStoreData = {
  qrCode: string;
  promoUrl: string;
  promoCode: string;
  data: TPartnersFullTableData[];
};

export enum PARTNERS_FULL_TABLE_KEY_NAMES {
  ACC_ID = 'accId',
  FILIAL_NAME = 'filialName',
  CONNECT_DATE = 'connectDate',
  FIRST_PAY_DATE = 'firstPayDate',
  STATUS = 'status',
}
