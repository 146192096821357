import React, { memo, useCallback } from 'react';
//
import { Label } from '@atoms/label';
import { Chips } from '@uikit/Chips';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { EVENT_KEY_NAMES, THelpKeys } from '@models/index';
import { IconWithTooltips } from '@components/iconWithTooltips';
import {
  TFocusToInput,
  TOnChangeHandler,
  TAddBalloonOnBlur,
  TDeleteBalloonSet,
  TKeyboardClickEvent,
  TAddBalloonByEnterKey,
} from '@shared/types';

type TFormInputProps = {
  /**
   * Флаг для сокрытия иконки с подсказкой
   * @param {boolean}
   */
  noIcon?: boolean;
  /**
   * Id на input для ввода нового значения
   * @param {string}
   */
  inputId: string;
  /**
   * Массив данных для отображения Chips components
   * @param {string[]}
   */
  value: string[];
  /**
   * Значение input
   * @param {boolean}
   */
  newValue: string;
  /**
   * Вариант текста для метки, берется по ключу из словаря
   * @param {THelpKeys}
   */
  variant: THelpKeys;
  /**
   * Опциональный параметр включает или отключает валидацию нового значения, если параметр true
   * в input нельзя ввести текст, только число.
   * @param {boolean}
   * @default
   */
  typeOfValueIsNumber?: boolean;
  /**
   * Callback для изменения нового значения
   * @param {TOnChangeHandler}
   */
  setNewValue: TOnChangeHandler;
  /**
   * Callback для очистки нового значения
   * @param {(name: string) => void}
   */
  clearNewValue: (name: string) => void;
  /**
   * Callback для удаления Chips component
   * @param {TDeleteBalloonSet}
   */
  deleteBalloon: TDeleteBalloonSet;
  /**
   * Callback для добавления Chips component при событии blur
   * @param {TAddBalloonOnBlur}
   */
  addBalloonOnBlur: TAddBalloonOnBlur;
  /**
   * Callback для добавления Chips component при нажатии на клавише Enter
   * @param {TAddBalloonByEnterKey}
   */
  addBalloonByEnterKey: TAddBalloonByEnterKey;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const SettingsFormInput = memo(
  ({
    noIcon,
    inputId,
    value,
    variant,
    newValue,
    setNewValue,
    clearNewValue,
    deleteBalloon,
    addBalloonOnBlur,
    typeOfValueIsNumber,
    addBalloonByEnterKey,
    className = '',
  }: TFormInputProps) => {
    // Устанавливает фокус на поле ввода, фокус устанавливается при клике на контейнер в котором расположен элемент
    const focusToInput: TFocusToInput = useCallback(event => {
      event.currentTarget.focus();
    }, []);

    // Валидирует введенное значение, валидация происходит если передан опциональный проп "typeOfValueIsNumber"
    const isBalloonValueValid = useCallback(
      (valueToValidate: string) => {
        if (typeOfValueIsNumber) {
          return !Number.isNaN(Number(valueToValidate));
        }
        return true;
      },
      [typeOfValueIsNumber],
    );

    // Добавляет новый "шарик" по событию Blur, если введенные данные валидны, в противном случае очищает поле ввода
    const onBlurAddBalloon: TOnChangeHandler = useCallback(
      event => {
        if (isBalloonValueValid(newValue)) {
          addBalloonOnBlur(event);
        } else {
          clearNewValue(event.currentTarget.name);
        }
      },
      [addBalloonOnBlur, clearNewValue, isBalloonValueValid, newValue],
    );

    // Добавляет новый "шарик" по событию KeyUp, если введенные данные валидны, в противном случае очищает поле ввода
    const onKeyUpAddBalloon: TKeyboardClickEvent = useCallback(
      event => {
        if (event.key === EVENT_KEY_NAMES.ENTER) {
          if (isBalloonValueValid(newValue)) {
            addBalloonByEnterKey(event);
          } else {
            clearNewValue(event.currentTarget.name);
          }
        }
      },
      [addBalloonByEnterKey, clearNewValue, isBalloonValueValid, newValue],
    );

    // Подготовка "шариков" для рендеринга
    const balloons = value.map(item => {
      return (
        <Chips
          key={item}
          removable
          type='def'
          tag={item}
          color='danger'
          icon={noIcon ? null : 'disable'}
          onRemove={deleteBalloon(item, inputId)}
          className='!pr-[0.875rem] tracking-wide mr-2.5 mb-2.5'
        />
      );
    });

    return (
      <div className={`flex-col ${className}`} onClick={focusToInput}>
        <Label htmlFor={inputId} variant={variant} className='mb-2.5'>
          <IconWithTooltips tooltips={variant} className='mb-0.5' />
        </Label>
        <TextInput
          fill
          type='text'
          id={inputId}
          name={inputId}
          color='default'
          view='outlined'
          value={newValue}
          onChange={setNewValue}
          onBlur={onBlurAddBalloon}
          onKeyUp={onKeyUpAddBalloon}
          className='mb-2'
        />
        <div className='flex items-center flex-wrap w-full'>{balloons}</div>
      </div>
    );
  },
);

SettingsFormInput.displayName = 'SettingsFormInput';
