import { apiService } from '@api/ApiService';
import { END_POINTS_URL, QUERY_PARAMS } from '@const/apiConstants';
import { TCreateInvoiceResponse, TCrateInvoice, TInvoiceData } from '@redux/pay/types';

import {
  TRequestStatus,
  TGetPayLinkData,
  TCalculatedPrice,
  TPayResponseData,
  TGetCalculatedPrice,
  TGetPayLinkResponse,
  TGetResponseWithData,
  TPaymentHistoryResponseData,
} from './types';

/**
 * API страницы оплаты
 * @module payApi
 */

export const payApi = {
  /**
   * Метод запрашивает данные для страницы оплаты
   * @method getPayData
   * @param isTgChannelAdd {boolean} данные для формирования запроса
   * @return {Promise<AxiosResponse<TPayResponseData | TRequestStatus, any>>}
   */
  getPayData(isTgChannelAdd: boolean) {
    return apiService.get<TPayResponseData | TRequestStatus>(END_POINTS_URL.PAY, {
      [QUERY_PARAMS.TG]: isTgChannelAdd,
    });
  },
  /**
   * Метод запрашивает данные для отображения страницы с историей оплат
   * @method getPaymentHistoryData
   * @return {Promise<AxiosResponse<TRequestStatus | TGetResponseWithData<TPaymentHistoryResponseData>, any>>}
   */
  getPaymentHistoryData() {
    return apiService.get<TGetResponseWithData<TPaymentHistoryResponseData> | TRequestStatus>(
      END_POINTS_URL.PAYMENT_HISTORY,
    );
  },
  /**
   * Метод запрашивает данные по ценам на выбранные опции и общую сумму
   * @method getCalculatedPrice
   * @param data {TGetCalculatedPrice} данные для формирования запроса
   * @return {Promise<AxiosResponse<TRequestStatus | TCalculatedPrice, any>>}
   */
  getCalculatedPrice({ amount, months, pay_period, modules, currency }: TGetCalculatedPrice) {
    return apiService.post<TCalculatedPrice | TRequestStatus>(
      END_POINTS_URL.CALCULATE_PRICE,
      modules,
      {
        params: {
          [QUERY_PARAMS.AMOUNT]: amount,
          [QUERY_PARAMS.MONTHS]: months,
          [QUERY_PARAMS.PAY_PERIOD]: pay_period,
          [QUERY_PARAMS.CURRENCY]: currency,
        },
      },
    );
  },
  /**
   * Метод запрашивает ссылку для перехода на страницу оплаты
   * @method getPayLink
   * @param data {TGetPayLinkData} данные для формирования запроса
   * @return {Promise<AxiosResponse<string | TRequestStatus, any>>}
   */
  getPayLink({ months, modules }: TGetPayLinkData) {
    return apiService.post<string | TRequestStatus>(END_POINTS_URL.GET_PAY_LINK, modules, {
      params: {
        [QUERY_PARAMS.MONTHS]: months,
      },
    });
  },
  /**
   * Метод запрашивает ссылку для включения автоплатежа
   * @method getAutoPayLink
   * @return {Promise<AxiosResponse<TPayResponseData | TRequestStatus, any>>}
   */
  getAutoPayLink() {
    return apiService.get<TGetPayLinkResponse | TRequestStatus>(END_POINTS_URL.AUTO_PAY_LINK);
  },
  /**
   * Метод запрашивает ссылку для изменения сохраненной карточки используемой для автоплатежа
   * @method changeCard
   * @return {Promise<AxiosResponse<TRequestStatus | TGetPayLinkResponse, any>>}
   */
  changeCard() {
    return apiService.get<TGetPayLinkResponse | TRequestStatus>(END_POINTS_URL.CHANGE_CARD);
  },
  /**
   * Метод отключает автоплатёж
   * @method turnOffAutoPayment
   * @return {Promise<AxiosResponse<TRequestStatus | TGetPayLinkResponse, any>>}
   */
  turnOffAutoPayment() {
    return apiService.post<TGetPayLinkResponse | TRequestStatus>(END_POINTS_URL.AUTO_PAY_OFF);
  },
  /**
   * Метод запрашивает ссылку на скачивание счета
   * @method createInvoice
   * @param data {TCrateInvoice} данные для формирования запроса
   * @return {Promise<AxiosResponse<TCreateInvoiceResponse | TRequestStatus, any>>}
   */
  createInvoice(data: TCrateInvoice) {
    return apiService.post<TCreateInvoiceResponse | TRequestStatus>(
      END_POINTS_URL.CREATE_INVOICE,
      data.modules,
      {
        params: {
          [QUERY_PARAMS.MONTHS]: data.months,
        },
      },
    );
  },
  /**
   * Метод отправляет счет овнеру в TG
   * @method sendInvoiceToTg
   * @param data {TCreateInvoiceResponse} данные для формирования запроса
   * @return {Promise<AxiosResponse<TCreateInvoiceResponse | TRequestStatus, any>>}
   */
  sendInvoiceToTg(data: TInvoiceData) {
    return apiService.post<TCreateInvoiceResponse | TRequestStatus>(
      END_POINTS_URL.SEND_INVOICE_TO_TG,
      {},
      {
        params: {
          [QUERY_PARAMS.INVOICE_ID]: data.invoiceId,
        },
      },
    );
  },
};
