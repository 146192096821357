export const ADD_LID_MESSAGES = {
  SUCCESS: 'Лид добавлен',
};

export const ADD_LID_MESSAGES_ENG = {
  SUCCESS: 'Lead was added',
};

export const ADD_LEAD_TOAST = {
  SUCCESS: 'ADD_LID_MESSAGES.SUCCESS',
};
