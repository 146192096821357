import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//
import { COMMON_TOAST } from '@const/common';
import { TRejectResponseData } from '@models/index';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { IInitialState, initialState } from '@redux/pay/initialState';
import { dateSort, getDateUSFormat, simpleSort } from '@helpers/index';
import { numberOfMonth, OPTIONS_NAME, PAY_PAGE_SUCCESS_TOAST_MESSAGES } from '@const/pay';
import { REJECT_RESPONSE_2_KEY, REJECT_RESPONSE_KEY, TChannels, TModuleCost } from '@api/types';
import { TCalculatePrice, TInvoiceData, TPayData, TPaymentData } from '@redux/pay/types';
import {
  changeCartData,
  createInvoiceThunk,
  getAutoPayLinkData,
  getCalculatedPriceThunk,
  getPayLinkThunk,
  getPaymentHistoryDataThunk,
  getPayPageData,
  sendInvoiceToTg,
  turnOffAutoPayment,
} from '@redux/pay/payThunks';

export const paySlice = createSlice({
  name: 'pay',
  initialState,
  reducers: {
    showConnectAutoPayModal(state: IInitialState) {
      state.isConnectAutoPayModalOpen = true;
    },
    hideConnectAutoPayModal(state: IInitialState) {
      state.isConnectAutoPayModalOpen = false;
    },
    showChangeCardModal(state: IInitialState) {
      state.isChangeModalOpen = true;
    },
    hideChangeCardModal(state: IInitialState) {
      state.isChangeModalOpen = false;
    },
    showAutoPayDisableModal(state: IInitialState) {
      state.isAutoPayDisableModalOpen = true;
    },
    hideAutoPayDisableModal(state: IInitialState) {
      state.isAutoPayDisableModalOpen = false;
    },
    showGetBillModal(state: IInitialState) {
      state.isBillDownloadModalOpen = true;
    },
    hideGetBillModal(state: IInitialState) {
      state.invoiceData = null;
      state.isBillDownloadModalOpen = false;
    },
    setNewCardNumber(state: IInitialState, action: PayloadAction<number>) {
      state.newCardNumber = action.payload;
    },
    setSelectedCardNumber(
      state: IInitialState,
      action: PayloadAction<{ totalAmount: number; cardNumber: number; monthAmount: number }>,
    ) {
      const { cardNumber, totalAmount, monthAmount } = action.payload;

      if (state.selectedCardNumber === cardNumber) {
        state.selectedCardNumber = 0;
        state.selectedCardTotalAmount = 0;
        state.selectedCardMonthAmount = 0;
        state.selectedModules = state.data.activeModules;
      } else {
        if (!state.selectedModules.includes(OPTIONS_NAME.WA)) {
          state.selectedModules.push(OPTIONS_NAME.WA);
        }

        state.newCardNumber = 0;
        state.payTariffDays = false;
        state.isGetNewCalculatingPrice = true;
        state.selectedCardNumber = cardNumber;
        state.selectedCardTotalAmount = totalAmount;
        state.selectedCardMonthAmount = monthAmount;
      }
    },
    selectModule(state: IInitialState, action: PayloadAction<TChannels>) {
      const optionName = action.payload;

      if (optionName !== OPTIONS_NAME.WA) {
        const isSelected = state.selectedModules.includes(optionName);
        const isActiveModule = state.data.activeModules.includes(optionName);

        const canUnselectModule = state.selectedCardNumber || !isActiveModule;

        if (isSelected && canUnselectModule) {
          state.isGetNewCalculatingPrice = true;
          state.selectedModules = state.selectedModules.filter(channel => channel !== optionName);
        }
        if (!isSelected) {
          state.isGetNewCalculatingPrice = true;
          state.selectedModules.push(optionName);
        }
      }
    },
    setSortTypeDataPP(
      state: IInitialState,
      action: PayloadAction<{ columnName: string; order: string }>,
    ) {
      const { columnName, order } = action.payload;
      const key = columnName as unknown as keyof TPaymentData;

      if (columnName === 'date' || columnName === 'period') {
        state.filteredData = dateSort<TPaymentData>(state.filteredData, key, order);
      } else {
        state.filteredData = simpleSort<TPaymentData>(state.filteredData, key, order);
      }
    },
    showMorePayHistoryCard(state: IInitialState) {
      const nextPageNumber = state.mobilePage + 1;
      const startPageIndex = nextPageNumber * 3;

      state.mobilePage = nextPageNumber;

      if (startPageIndex + 3 <= state.filteredData.length) {
        state.mobileTableData = state.mobileTableData.concat(
          state.filteredData.slice(startPageIndex, startPageIndex + 3),
        );
      } else {
        state.mobileTableData = state.filteredData;
      }
    },
    setPromoCode(state: IInitialState, action: PayloadAction<string>) {
      state.promoCode = action.payload;
    },
    setPayTariffDays(state: IInitialState, action: PayloadAction<boolean>) {
      state.payTariffDays = action.payload;
    },
    clearStatuses(state: IInitialState) {
      state.isFetchingAutoPayLink = false;
      state.isFetchingAutoPayLinkError = false;
      state.isFetchingAutoPayLinkSuccess = false;
      state.isFetchingInvoiceLink = false;
      state.isFetchingInvoiceLinkError = false;
      state.isSendingInvoiceToTgError = false;
      state.isSendingInvoiceToTg = false;
      state.errorMessage = '';
      state.successMessage = '';
    },
  },
  extraReducers: builder => {
    builder.addCase(getPayPageData.pending, (state: IInitialState) => {
      if (!state.isRetries) {
        state.isLoading = true;
      }
      state.isError = false;
      state.autoPayLink = '';
      state.isFetchingAutoPayLink = false;
      state.isFetchingAutoPayLinkError = false;
      state.isFetchingAutoPayLinkSuccess = false;
      state.isFetchingInvoiceLink = false;
      state.isFetchingInvoiceLinkError = false;
      state.isSendingInvoiceToTgError = false;
      state.isSendingInvoiceToTg = false;
      state.changeCardDataLink = '';
      state.changeCardIsLoading = false;
      state.changeCardIsError = false;
      state.changeCardIsSuccess = false;
      state.turnOffAutoPaymentIsLoading = false;
      state.turnOffAutoPaymentIsError = false;
      state.turnOffAutoPaymentIsSuccess = false;
      state.isAccessOnPayPageDenied = false;
      state.isChangeModalOpen = false;
      state.isConnectAutoPayModalOpen = false;
      state.isAutoPayDisableModalOpen = false;
    });
    builder.addCase(
      getPayPageData.fulfilled,
      (state: IInitialState, action: PayloadAction<TPayData | void>) => {
        if (action.payload) {
          const data = action.payload;

          state.data = data;
          state.selectedModules = data.activeModules;

          if (data.lastPayLength) {
            if (!state.selectedModules.includes(OPTIONS_NAME.WA)) {
              state.selectedModules.push(OPTIONS_NAME.WA);
            }

            const { lastPayLength, activeModules, amount, modulesPrice } = data;

            const selectedCardNumber = numberOfMonth.indexOf(lastPayLength) + 1;

            // const monthAmount = monthCost[lastPayLength];
            const monthAmount = +amount;

            const totalAmount = monthAmount * lastPayLength;

            let activeModuleTotalCost = 0;

            const modulesCost = activeModules.reduce<TModuleCost[]>((accum, module) => {
              const cost = module !== 'WA' ? modulesPrice[module] * lastPayLength : totalAmount;

              const moduleItem: TModuleCost = {
                module,
                months: lastPayLength,
                days: null,
                cost,
              };

              activeModuleTotalCost += cost;

              return [...accum, moduleItem];
            }, []);

            state.calculatedPrice = {
              fullCoast: activeModuleTotalCost,
              modulesCost,
              newPayTillDate: '',
              paidPeriodReducedBy: null,
            };

            state.newCardNumber = 0;
            state.payTariffDays = false;
            state.selectedCardNumber = selectedCardNumber;
            state.selectedCardTotalAmount = totalAmount;
            state.selectedCardMonthAmount = lastPayLength;
          }
        }
        if (state.isRetries) {
          state.isRetries = false;
        }
        state.isLoading = false;
      },
    );
    builder.addCase(
      getPayPageData.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessOnPayPageDenied = true;
            } else {
              state.isError = true;
            }
            state.isLoading = false;
          }
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      },
    );
    builder.addCase(getAutoPayLinkData.pending, (state: IInitialState) => {
      state.isFetchingAutoPayLink = true;
      state.isFetchingAutoPayLinkError = false;
      state.isFetchingAutoPayLinkSuccess = false;
    });
    builder.addCase(
      getAutoPayLinkData.fulfilled,
      (state: IInitialState, action: PayloadAction<string | void>) => {
        if (action.payload) {
          state.autoPayLink = action.payload;
          state.isConnectAutoPayModalOpen = true;
          state.isFetchingAutoPayLinkSuccess = true;
        }
        state.isFetchingAutoPayLink = false;
      },
    );
    builder.addCase(
      getAutoPayLinkData.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessOnPayPageDenied = true;
          } else {
            state.isFetchingAutoPayLinkError = true;
          }
          state.isFetchingAutoPayLink = false;
        } else if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isFetchingAutoPayLink = false;
          } else {
            state.isFetchingAutoPayLink = false;
            state.isFetchingAutoPayLinkError = true;
          }
        } else {
          state.isFetchingAutoPayLink = false;
          state.isFetchingAutoPayLinkError = true;
        }
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
    builder.addCase(changeCartData.pending, (state: IInitialState) => {
      state.changeCardIsLoading = true;
      state.changeCardIsSuccess = false;
      state.changeCardIsError = false;
    });
    builder.addCase(
      changeCartData.fulfilled,
      (state: IInitialState, action: PayloadAction<string | void>) => {
        if (action.payload) {
          state.isChangeModalOpen = true;
          state.changeCardIsSuccess = true;
          state.changeCardDataLink = action.payload;
        }
        state.changeCardIsLoading = false;
      },
    );
    builder.addCase(
      changeCartData.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessOnPayPageDenied = true;
          } else {
            state.changeCardIsError = true;
          }
          state.changeCardIsLoading = false;
        } else if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.changeCardIsLoading = false;
          } else {
            state.changeCardIsLoading = false;
            state.changeCardIsError = true;
          }
        } else {
          state.changeCardIsLoading = false;
          state.changeCardIsError = true;
        }
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
    builder.addCase(turnOffAutoPayment.pending, (state: IInitialState) => {
      state.turnOffAutoPaymentIsError = false;
      state.turnOffAutoPaymentIsLoading = true;
      state.turnOffAutoPaymentIsSuccess = false;
    });
    builder.addCase(turnOffAutoPayment.fulfilled, (state: IInitialState) => {
      state.turnOffAutoPaymentIsSuccess = true;
      state.turnOffAutoPaymentIsLoading = false;
    });
    builder.addCase(
      turnOffAutoPayment.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessOnPayPageDenied = true;
          } else {
            state.turnOffAutoPaymentIsError = true;
          }
          state.turnOffAutoPaymentIsLoading = false;
        } else if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.changeCardIsLoading = false;
          } else {
            state.turnOffAutoPaymentIsLoading = false;
            state.turnOffAutoPaymentIsError = true;
          }
        } else {
          state.turnOffAutoPaymentIsLoading = false;
          state.turnOffAutoPaymentIsError = true;
        }
        state.isAutoPayDisableModalOpen = false;
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
    builder.addCase(createInvoiceThunk.pending, (state: IInitialState) => {
      state.isFetchingInvoiceLink = true;
      state.isFetchingInvoiceLinkError = false;
    });
    builder.addCase(
      createInvoiceThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<TInvoiceData | void>) => {
        if (action.payload) {
          state.invoiceData = action.payload;
          state.isBillDownloadModalOpen = true;
        }
        state.isFetchingInvoiceLink = false;
      },
    );
    builder.addCase(
      createInvoiceThunk.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessOnPayPageDenied = true;
          } else {
            state.isFetchingInvoiceLinkError = true;
          }
          state.isFetchingInvoiceLink = false;
        } else if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isFetchingInvoiceLink = false;
          } else {
            state.isFetchingInvoiceLink = false;
            state.isFetchingInvoiceLinkError = true;
          }
        } else {
          state.isFetchingInvoiceLink = false;
          state.isFetchingInvoiceLinkError = true;
        }
        state.isFetchingInvoiceLink = false;
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
    builder.addCase(sendInvoiceToTg.pending, (state: IInitialState) => {
      state.isSendingInvoiceToTg = true;
      state.isSendingInvoiceToTgError = false;
    });
    builder.addCase(sendInvoiceToTg.fulfilled, (state: IInitialState) => {
      state.isSendingInvoiceToTg = false;
      state.successMessage = PAY_PAGE_SUCCESS_TOAST_MESSAGES.SEND_INVOICE_IN_TG;
    });
    builder.addCase(
      sendInvoiceToTg.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
          const { status } = action.payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessOnPayPageDenied = true;
          } else {
            state.isSendingInvoiceToTgError = true;
          }
          state.turnOffAutoPaymentIsLoading = false;
        } else if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
          const { detail } = action.payload;
          if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isSendingInvoiceToTg = false;
          } else {
            state.isSendingInvoiceToTg = false;
            state.isSendingInvoiceToTgError = true;
          }
        } else {
          state.isSendingInvoiceToTg = false;
          state.isSendingInvoiceToTgError = true;
        }
        state.isSendingInvoiceToTg = false;
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
    builder.addCase(getPaymentHistoryDataThunk.pending, (state: IInitialState) => {
      state.isLoading = true;
    });
    builder.addCase(
      getPaymentHistoryDataThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<TPaymentData[] | void>) => {
        if (action.payload) {
          state.isLoading = false;
          state.filteredData = action.payload;
          state.mobileTableData = action.payload.slice(0, 3);
        }
      },
    );
    builder.addCase(
      getPaymentHistoryDataThunk.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessOnPayPageDenied = true;
            } else {
              state.isError = true;
            }
            state.isLoading = false;
          } else if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
            }
          }
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      },
    );
    builder.addCase(getCalculatedPriceThunk.pending, (state: IInitialState) => {
      state.isCalculatingCoast = true;
    });
    builder.addCase(
      getCalculatedPriceThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<TCalculatePrice | void>) => {
        if (action.payload) {
          state.isCalculatingCoast = false;
          state.isGetNewCalculatingPrice = false;

          const payTillDate = new Date(getDateUSFormat(state.data.payTillDate)).getTime();
          const newPayTillDate = new Date(getDateUSFormat(action.payload.newPayTillDate)).getTime();

          const paidPeriodReducedBy = Math.ceil((payTillDate - newPayTillDate) / 86400000);

          state.calculatedPrice = { ...action.payload, paidPeriodReducedBy };
        }
      },
    );
    builder.addCase(
      getCalculatedPriceThunk.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessOnPayPageDenied = true;
            } else {
              state.isError = true;
            }
            state.isCalculatingCoast = false;
          } else {
            state.isError = true;
            state.isCalculatingCoast = false;
          }
        } else {
          state.isError = true;
          state.isCalculatingCoast = false;
        }
        state.isGetNewCalculatingPrice = false;
      },
    );
    builder.addCase(getPayLinkThunk.pending, (state: IInitialState) => {
      state.isGettingPayLink = true;
    });
    builder.addCase(getPayLinkThunk.fulfilled, (state: IInitialState) => {
      state.isGettingPayLink = false;
      state.selectedCardNumber = 0;
      state.selectedCardTotalAmount = 0;
      state.selectedCardMonthAmount = 0;
      state.selectedModules = state.data.activeModules;
    });
    builder.addCase(
      getPayLinkThunk.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessOnPayPageDenied = true;
            } else {
              state.isError = true;
            }
            state.isGettingPayLink = false;
          } else {
            state.isGettingPayLink = false;
          }
        } else {
          state.isError = true;
          state.isGettingPayLink = false;
        }
      },
    );
  },
});

export const payReducer = paySlice.reducer;
export const {
  setPromoCode,
  selectModule,
  clearStatuses,
  hideGetBillModal,
  setNewCardNumber,
  setPayTariffDays,
  showGetBillModal,
  setSortTypeDataPP,
  showChangeCardModal,
  hideChangeCardModal,
  setSelectedCardNumber,
  showMorePayHistoryCard,
  showConnectAutoPayModal,
  hideConnectAutoPayModal,
  showAutoPayDisableModal,
  hideAutoPayDisableModal,
} = paySlice.actions;
