import { RootState } from '@store/store';
import { accessApi } from '@api/accessApi';
import { REJECT_RESPONSE_KEY } from '@api/types';
import { TRejectResponseData } from '@models/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { accessMappers } from '@redux/access/accessMappers';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { ACCESS_RESPONSE_KEYS, TAccessDataItem } from '@redux/access/models';

/**
 * Thunk для получения данных доступа.
 *
 * @return {TAccessDataItem[] | void} Данные доступа или void.
 * @throws {Error} Если получен неверный статус ответа.
 * @param {void} data - Неиспользуемый параметр.
 * @param {TRejectResponseData} rejectWithValue - Функция для обработки ошибок запроса.
 */
const getAccessData = createAsyncThunk<
  TAccessDataItem[] | void,
  void,
  { rejectValue: TRejectResponseData }
>('access/getAccessData', (data, { rejectWithValue }) => {
  return accessApi
    .getAccessData()
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (ACCESS_RESPONSE_KEYS.DATA in response.data) {
        return accessMappers.mapResponseDataToStore(response.data.data);
      }
    })
    .catch(error => rejectWithValue(error.response.data));
});

/**
 * Thunk для отправки или обновления данных доступа.
 *
 * @param {TAccessDataItem[]} data - Массив данных доступа.
 * @param {object} thunkAPI - Объект с методами для работы с thunk.
 * @param {object} thunkAPI.getState - Функция для получения текущего состояния Redux.
 * @param {object} thunkAPI.dispatch - Функция для диспатча других actions.
 * @param {Function} thunkAPI.rejectWithValue - Функция для обработки ошибок запроса.
 *
 * @return {TAccessDataItem[] | void} Данные доступа или void.
 * @throws {Error} Если получен неверный статус ответа.
 */
const postAccesses = createAsyncThunk<
  TAccessDataItem[] | void,
  TAccessDataItem[],
  { state: RootState; rejectValue: TRejectResponseData }
>('access/postData', (data: TAccessDataItem[], { getState, dispatch, rejectWithValue }) => {
  return accessApi
    .postAccessesData({ data })
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (ACCESS_RESPONSE_KEYS.DATA in response.data) {
        return accessMappers.mapResponseDataToStore(response.data.data);
      }
    })
    .catch(error => {
      if (error?.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        const { data: accessData } = getState().accesses;
        setTimeout(() => dispatch(postAccesses(accessData)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

export { getAccessData, postAccesses };
