import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Menu } from '@uikit/Menu';
import { Chips } from '@uikit/Chips';
import { Dialog } from '@uikit/Dialog';
import { Button } from '@uikit/Button';
import { Icon } from '@blueprintjs/core';
import { Checkbox } from '@uikit/CheckBox';
import { Dropdown } from '@uikit/Dropdown';
import { TFilialData } from '@redux/accounts/models';

type TProps = {
  /**
   * Флаг копирования шаблонов
   * @param {boolean}
   */
  isTemplatesCopying: boolean;
  /**
   * Список аккаунтов фииала
   * @param {TFilialData[]}
   */
  accounts: TFilialData[];
  /**
   * Флаг успешного завершения процесса копирования
   * @param {boolean}
   */
  isCopyTemplatesSuccess: boolean;
  /**
   * Копирует шаблон в переданный список филиалов
   * @param {(filialToCopy: string[]) => void}
   */
  copyHandler: (filialToCopy: string[]) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const CopyTemplates = memo(
  ({
    accounts,
    copyHandler,
    isTemplatesCopying,
    isCopyTemplatesSuccess,
    className = '',
  }: TProps) => {
    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [filialsToCopy, setFilialsToCopy] = useState<TFilialData[]>([]);

    useEffect(() => {
      if (isCopyTemplatesSuccess) {
        setFilialsToCopy([]);
        setIsModalOpen(false);
      }
    }, [isCopyTemplatesSuccess]);

    const handleOpenModal = useCallback(() => {
      setIsModalOpen(true);
    }, []);

    const handleCloseModal = useCallback(() => {
      setFilialsToCopy([]);
      setIsModalOpen(false);
    }, []);

    const setFilialToCopyHandler = useCallback(
      (filial: TFilialData) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.currentTarget;

        if (checked) {
          setFilialsToCopy(prevState => [...prevState, filial]);
        } else {
          setFilialsToCopy(prevState =>
            prevState.filter(item => item.branchId !== filial.branchId),
          );
        }
      },
      [],
    );

    const deleteBalloonHandler = useCallback(
      (filial: TFilialData) => () => {
        setFilialsToCopy(prevState => prevState.filter(item => item.branchId !== filial.branchId));
      },
      [],
    );

    const copyTemplateHandler = useCallback(
      (filials: TFilialData[]) => () => {
        copyHandler(filials.map(item => item.branchId));
      },
      [copyHandler],
    );

    const filialsRender = accounts.map(filial => {
      const isChecked = filialsToCopy.includes(filial);

      return (
        <div key={filial.branchId} className='py-1 px-2'>
          <div
            className={`flex items-center rounded min-h-7 ${
              isChecked ? 'bg-aquaSpring' : ''
            } w-full`}>
            <Checkbox
              fill
              color='success'
              checked={isChecked}
              name={filial.branchId}
              label={filial.address}
              onChange={setFilialToCopyHandler(filial)}
              className={className}
            />
          </div>
        </div>
      );
    });

    const dropDownText = useMemo(() => {
      return (
        <span>
          {t('TEMPLATE_PAGE_TEXT.templateCopy.selectedFilials', {
            filialsToCopy: filialsToCopy.length,
          })}
        </span>
      );
    }, [t, filialsToCopy]);

    const balloons = useMemo(() => {
      return filialsToCopy.map((filial, index) => {
        const key = `${index}${filial.address}`;

        return (
          <Chips
            key={key}
            removable
            type='def'
            color='default'
            tag={filial.address}
            onRemove={deleteBalloonHandler(filial)}
            className='tracking-wide mr-2.5 mb-2.5'
          />
        );
      });
    }, [filialsToCopy, deleteBalloonHandler]);

    /**
     * Рендер дропдауна со списком филиалов
     */
    const dialogText = useMemo(
      () => (
        <div style={{ width: 600 }}>
          <p className='mb-4'>{t('TEMPLATE_PAGE_TEXT.templateCopy.description')}</p>
          <div className='mb-4'>
            <Dropdown
              view='outlined'
              color='default'
              position='bottom-left'
              text={dropDownText}
              content={
                <Menu view='raised' className='!p-2'>
                  {filialsRender}
                </Menu>
              }
              className='w-full nav_dropdown'
            />
          </div>
          <div className='flex items-center flex-wrap w-full'>{balloons}</div>
        </div>
      ),
      [t, balloons, dropDownText, filialsRender],
    );

    return (
      <div className={className}>
        <div className='w-full'>
          <Dialog
            size='small'
            view='raised'
            color='default'
            text={dialogText}
            backdropOpacity={40}
            isOpen={isModalOpen}
            style={{ maxWidth: 680 }}
            onClose={handleCloseModal}
            title={t('TEMPLATE_PAGE_TEXT.templateCopy.header')}
            rightButton={
              <div className='flex'>
                <Button
                  dense
                  type='action'
                  text={t('TEMPLATE_PAGE_TEXT.templateCopy.cancelButton')}
                  view='outlined'
                  color='default'
                  onClick={handleCloseModal}
                  className='mr-6'
                />
                <Button
                  dense
                  type='action'
                  view='filled'
                  color='success'
                  loading={isTemplatesCopying}
                  disabled={!filialsToCopy.length}
                  onClick={copyTemplateHandler(filialsToCopy)}
                  text={t('TEMPLATE_PAGE_TEXT.templateCopy.toCopyButton')}
                />
              </div>
            }
          />
          <Button
            fill
            dense
            type='action'
            view='outlined'
            onClick={handleOpenModal}
            icon={<Icon icon='duplicate' size={24} />}>
            {t('TEMPLATE_PAGE_TEXT.templateCopy.copyButton')}
          </Button>
        </div>
      </div>
    );
  },
);

CopyTemplates.displayName = 'CopyTemplates';
