import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Table } from '@uikit/Table/Table';
import { useAppDispatch } from '@store/store';
import { TPayHistorySalonTableData } from '@redux/payHistorySalonTable/models';
import { setSortTypeDataHST } from '@redux/payHistorySalonTable/payHistorySalonTableSlice';

interface IPartnersPayHistoryTableSalonProps {
  /**
   * Данные для таблицы
   * @param {TPayHistorySalonTableData[]}
   */
  data: TPayHistorySalonTableData[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const PartnersPayHistorySalonTable = memo(
  ({ data, className = '' }: IPartnersPayHistoryTableSalonProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const Head = useMemo(
      () => [
        { id: 'period', label: t('PARTNER_PAGE_TEXT.partnersPayHistorySalonTable.period') },
        {
          id: 'monthsAccrued',
          label: t('PARTNER_PAGE_TEXT.partnersPayHistorySalonTable.monthsAccrued'),
        },
      ],
      [t],
    );

    const getTableData = useCallback((tableData: TPayHistorySalonTableData[]) => {
      const emptyData = [
        {
          period: ' ',
          monthsAccrued: ' ',
        },
      ];

      if (!tableData.length) {
        return emptyData;
      }

      return tableData.map(item => {
        return {
          period: item.period,
          monthsAccrued: item.monthsAccrued,
        };
      });
    }, []);

    // функция сортировки, получает название столбца отправляет в редакс
    const setSortTypeHandler = useCallback(
      (columnName: string, order: string) => {
        dispatch(setSortTypeDataHST({ columnName, order }));
      },
      [dispatch],
    );

    return (
      <div className={className}>
        <Table
          name={Head}
          data={getTableData(data)}
          position={['left', 'right']}
          type='def'
          view='flat'
          color='default'
          pages
          labelRowsPerPage={t('PARTNER_PAGE_TEXT.partnersPayHistorySalonTable.pagination')}
          rowsPerPageOptions={[5, 8, 20]}
          defRows={8}
          checkboxes={false}
          paperClassName='no_shadow'
          onSortCallback={setSortTypeHandler}
          style={{ boxShadow: 'none', marginLeft: '6px' }}
        />
      </div>
    );
  },
);

PartnersPayHistorySalonTable.displayName = 'PartnersPayHistorySalonTable';
