import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { Icon } from '@blueprintjs/core';
import { numWord } from '@helpers/index';
import { MESSAGES } from '@const/mailing';
import { TAppLanguage } from '@models/index';
import { useAppDispatch } from '@store/store';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { CalendarGrid } from '@components/calendarGrid';
import { useCalendar } from '@helpers/calendar/hooks/useCalendar';
import { CalendarSelectDate } from '@components/calendarSelectDate';
import { SendOutDetailDataModal } from '@blocks/sendOutDetailDataModal';
import { TSendOutsData, TSendOutsDetailData } from '@redux/mailing/models';
import { getAllowedToRun, getDetailSendOudDayData } from '@redux/mailing/mailingThunks';
import {
  TSetSelectedYearHandler,
  TSetStartSendOutDayHandler,
  TSetSelectedMonthIndexHandler,
} from '@shared/types';
import {
  setSelectedYear,
  setSelectedMonth,
  deleteNewSendOut,
  setMailingStartDate,
  hideSendOutDetailDataModal,
} from '@redux/mailing/mailingSlice';

type TCalendarModalProps = {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Callback для закрытия календаря
   * @param {() => void}
   */
  hideCalendar: () => void;
  /**
   * Callback для подтверждения выбранной даты и закрытия календаря
   * @param {() => void}
   */
  confirmSelectedStartDate: () => void;
  /**
   * Выбранный месяц
   * @param {number}
   */
  selectedMonth: number;
  /**
   * Выбранный год
   * @param {number}
   */
  selectedYear: number;
  /**
   * Массив рассылок для отображения в календаре
   * @param {TSendOutsData[]}
   */
  sendOuts: TSendOutsData[];
  /**
   * Отключает кнопку подтверждения выбранной даты
   * @param {boolean}
   */
  disableConfirmButton: boolean;
  /**
   * Максимальное количество сообщений в день
   * @param {number}
   */
  daySendOutLimit: number;
  /**
   * Минимально возможное время начала рассылки
   * @param {number}
   */
  minimalRecommendedStartTime: number;
  /**
   * Флаг показа модалки с данными о запущенных рассылках в выбранном дне
   * @param {boolean}
   */
  isShowSendOutDetailDataModal: boolean;
  /**
   * Выбранная в календаре дата для отображения данных рассылок в этот день
   * @param {Date}
   */
  dateForGetDetailData: Date;
  /**
   * Детальные данные рассылок за день
   * @param {TSendOutsDetailData[]}
   */
  sendOutDetailData: TSendOutsDetailData[];
  /**
   * Флаг состояния запроса за детальной информацией по рассылкам за день
   * @param {boolean}
   */
  isGettingSendOutDetailData: boolean;
  /**
   * Флаг показа сообщения о невозможности запустить рассылку
   * @param {boolean}
   */
  isShowWarning: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const CalendarModal = memo(
  ({
    sendOuts,
    appLanguage,
    selectedYear,
    hideCalendar,
    isShowWarning,
    selectedMonth,
    daySendOutLimit,
    sendOutDetailData,
    disableConfirmButton,
    dateForGetDetailData,
    confirmSelectedStartDate,
    isGettingSendOutDetailData,
    minimalRecommendedStartTime,
    isShowSendOutDetailDataModal,
    className = '',
  }: TCalendarModalProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const width = useWindowWidth();

    // из хука получаем данные для формирования компонентов календаря
    const { calendarDays } = useCalendar({
      locale: 'default',
      firstWeekDayNumber: 2,
      selectedDate: new Date(selectedYear, selectedMonth, 1),
    }).state;

    // Устанавливает выбранный год
    const setSelectedYearHandler: TSetSelectedYearHandler = useCallback(
      year => {
        dispatch(setSelectedYear(year));
      },
      [dispatch],
    );

    // Устанавливает выбранный месяц
    const setSelectedMonthIndexHandler: TSetSelectedMonthIndexHandler = useCallback(
      ({ index, setPrevYear, setNextYear }) => {
        dispatch(setSelectedMonth(index));

        if (setPrevYear) {
          dispatch(setSelectedYear(selectedYear - 1));
        }
        if (setNextYear) {
          dispatch(setSelectedYear(selectedYear + 1));
        }
      },
      [selectedYear, dispatch],
    );

    // Устанавливает дату начала новой рассылки
    const setStartSendOutDayHandler: TSetStartSendOutDayHandler = useCallback(
      startDate => (date, remainDayLimit) => {
        const mailingStartDate = date || startDate;

        const onSuccessAddCallback = () => {
          dispatch(
            setMailingStartDate({ mailingStartDate, remainDayLimit, isSetTimeFromState: !date }),
          );
        };

        const fallbackCallback = () => {
          dispatch(getDetailSendOudDayData({ date: mailingStartDate, isShowWarning: true }));
        };

        dispatch(
          getAllowedToRun({
            date: mailingStartDate,
            onSuccessAddCallback,
            fallbackCallback,
            isTimeCheck: false,
          }),
        );
      },
      [dispatch],
    );

    // Удаляет новую рассыку из сетки календаря
    const deleteNewSendOutHandler = useCallback(() => {
      dispatch(deleteNewSendOut());
    }, [dispatch]);

    // Переводит календарь на текущий месяц и год
    const setTodayHandler = useCallback(() => {
      const nowDate = new Date();

      const month = nowDate.getMonth();
      const year = nowDate.getFullYear();

      dispatch(setSelectedMonth(month));
      dispatch(setSelectedYear(year));
    }, [dispatch]);

    // Получает данные о рассылках за день
    const getSendOutDataHandler = useCallback(
      (date: Date) => (isShowWarningData: boolean) => {
        dispatch(getDetailSendOudDayData({ date, isShowWarning: isShowWarningData }));
      },
      [dispatch],
    );

    // Закрывает модалку с данными по рассылкам
    const closeSendOutDataHandler = useCallback(() => {
      dispatch(hideSendOutDetailDataModal());
    }, [dispatch]);

    return (
      <div
        className={`absolute top-0 sm:top-4 left-0 sm:left-4 right-0 sm:right-4 bottom-0 sm:bottom-4 flex bg-white sm:rounded-[1.5rem] p-4 sm:p-10 shadow-calendarShadow h-[100vh] sm:h-[calc(100vh-2rem)] w-full sm:w-[calc(100%-2rem)] z-[53] ${className}`}>
        <div className='flex flex-col grow'>
          <div className='flex items-start justify-between mb-4 h-fit'>
            <p className='font-bold text-[1.5rem] leading-[2.25rem]'>
              {t('MAILING_PAGE_TEXT.mailingCalendar.modalTitle')}
            </p>
            <button
              onClick={hideCalendar}
              className='bg-none border-none flex items-start justify-end h-[2.25rem] w-[2.25rem]'>
              <Icon icon='cross' size={24} color='#4A4B57' />
            </button>
          </div>
          <CalendarSelectDate
            appLanguage={appLanguage}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            setTodayHandler={setTodayHandler}
            setSelectedYearHandler={setSelectedYearHandler}
            setSelectedMonthIndexHandler={setSelectedMonthIndexHandler}
            className='mb-4 sm:mb-2'
          />
          <div className='flex items-center mb-4'>
            <Icon icon='info-sign' size={20} color='#4F91FF' className='mr-3' />
            <p className='leading-6 tracking-[0.0275em]'>
              {t('MAILING_PAGE_TEXT.mailingCalendar.limit', {
                messages: numWord(daySendOutLimit, MESSAGES[appLanguage]),
                daySendOutLimit,
              })}
            </p>
          </div>
          <div className='flex grow dropdown-list-scrollbar overflow-y-auto'>
            <CalendarGrid
              sendOuts={sendOuts}
              appLanguage={appLanguage}
              calendarDays={calendarDays}
              selectedMonth={selectedMonth}
              daySendOutLimit={daySendOutLimit}
              isNewSendOutAdded={!disableConfirmButton}
              deleteNewSendOut={deleteNewSendOutHandler}
              getSendOutDataHandler={getSendOutDataHandler}
              setStartSendOutDay={setStartSendOutDayHandler}
              isGettingSendOutDetailData={isGettingSendOutDetailData}
              minimalRecommendedStartTime={minimalRecommendedStartTime}
            />
          </div>
          <div className='flex justify-between sm:justify-end mt-12'>
            <Button
              dense
              type='action'
              view='outlined'
              color='default'
              onClick={hideCalendar}
              text={t('MAILING_PAGE_TEXT.mailingCalendar.cancelButton')}
              className='mr-4 w-full sm:w-fit'
            />
            <Button
              dense
              type='action'
              view='filled'
              color='success'
              disabled={disableConfirmButton}
              onClick={confirmSelectedStartDate}
              text={t('MAILING_PAGE_TEXT.mailingCalendar.selectButton')}
              className='w-full sm:w-fit'
            />
          </div>
        </div>
        {isShowSendOutDetailDataModal ? (
          <SendOutDetailDataModal
            isShowWarning={isShowWarning}
            selectedDate={dateForGetDetailData}
            sendOutDetailData={sendOutDetailData}
            closeSendOutDataHandler={closeSendOutDataHandler}
            className={
              width < 981 ? 'absolute top-0 right-0 bottom-0 left-0 max-w-[unset]' : 'grow ml-6'
            }
          />
        ) : null}
      </div>
    );
  },
);

CalendarModal.displayName = 'CalendarModal';
