import React, { memo, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
//
import { PATH_NAMES } from '@data/dict';
import { NoAccess } from '@components/noAccess';
import { QUERY_PARAMS } from '@const/apiConstants';
import { ErrorMessage } from '@blocks/errorMessage';
import { MailingTemplate } from '@templates/mailing';
import { getAccountsData } from '@redux/accounts/selectors';
import { clearAllErrors } from '@redux/mailing/mailingSlice';
import { useAppDispatch, useAppSelector } from '@store/store';
import { clearMailingStatisticsError } from '@redux/statistics/statisticsSlice';
import {
  getMailingData,
  getStatisticsPageData,
  getSendOutStatisticsTableData,
} from '@redux/mailing/selectors';

const MailingPage = memo(() => {
  const dispatch = useAppDispatch();
  const path = useLocation().pathname;
  const [, setSearch] = useSearchParams();
  const { selectedFilial } = useAppSelector(getAccountsData);

  const {
    isError,
    isAccessDenied,
    isShowCalendar,
    isLoading: mailingDataLoading,
  } = useAppSelector(getMailingData);

  const {
    endDate,
    sortedBy,
    startDate,
    rowsPerPage,
    currentPage,
    searchString,
    sortDirection,
    isError: isStatisticError,
    isAccessOnStatisticsPageDenied,
  } = useAppSelector(getStatisticsPageData);

  const {
    isError: isSendOutStatisticsError,
    isLoading: senOutStatisticsLoading,
    isAccessDenied: isSendOutStatisticsAccessDenied,
  } = useAppSelector(getSendOutStatisticsTableData);

  useEffect(() => {
    if (path.includes(PATH_NAMES.STATISTICS) && selectedFilial?.accId) {
      setSearch({
        [QUERY_PARAMS.ACC_ID]: selectedFilial?.accId || '',
        [QUERY_PARAMS.SORTED_BY]: String(sortedBy),
        [QUERY_PARAMS.CURRENT_PAGE]: String(currentPage),
        [QUERY_PARAMS.ROWS_PER_PAGE]: String(rowsPerPage),
        [QUERY_PARAMS.SEARCH_STRING]: String(searchString),
        [QUERY_PARAMS.START_DATE]: String(startDate),
        [QUERY_PARAMS.END_DATE]: String(endDate),
        [QUERY_PARAMS.SORT_DIRECTION]: String(sortDirection),
      });
    }

    return () => {
      dispatch(clearAllErrors());
      dispatch(clearMailingStatisticsError());
    };
  }, [
    path,
    endDate,
    sortedBy,
    startDate,
    setSearch,
    currentPage,
    rowsPerPage,
    searchString,
    sortDirection,
    selectedFilial?.accId,
    dispatch,
  ]);

  const isErrorPageShow = isError || isStatisticError || isSendOutStatisticsError;

  const isAccessDeniedPageShow =
    isAccessDenied || isAccessOnStatisticsPageDenied || isSendOutStatisticsAccessDenied;

  const isLoading = mailingDataLoading || senOutStatisticsLoading;

  return (
    <>
      {isErrorPageShow && !isLoading && !isAccessDeniedPageShow ? <ErrorMessage /> : null}
      {isAccessDeniedPageShow && !isLoading && !isError ? <NoAccess /> : null}
      {!isError && !isErrorPageShow && !isAccessDeniedPageShow && selectedFilial?.accId ? (
        <MailingTemplate
          isLoading={isLoading}
          className={`px-4 m:px-4 sm:px-0 md:px-2 lg:px-0 ${
            isShowCalendar ? 'overflow-hidden h-[50vh]' : ''
          }`}
        />
      ) : null}
    </>
  );
});

MailingPage.displayName = 'MailingPage';

export default MailingPage;
