import React from 'react';
//
import icon404 from '@img/404/404.svg';

const Page404 = () => (
  <div className='flex flex-col items-center justify-center min-h-[calc(100vh-10.5rem)]'>
    <div className='flex items-center justify-center grow'>
      <img src={icon404} alt='' />
    </div>
  </div>
);

export default Page404;
