import { SORTING_TYPES } from '@const/common';
import { INVITE_FRIENDS_SORT_DROPDOWN_VALUES } from '@const/inviteFriend';
import {
  TStatisticsSortFields,
  TInviteFriendsStatisticsItemData,
} from '@redux/inviteFrendsStatistics/types';

export interface IInitialState {
  isError: boolean;
  isLoading: boolean;
  isAccessDenied: boolean;
  errorMessage: string;
  sortBy: TStatisticsSortFields;
  sortDirection: string;
  data: TInviteFriendsStatisticsItemData[];
}

export const initialState: IInitialState = {
  isError: false,
  isLoading: false,
  isAccessDenied: false,
  errorMessage: '',
  sortDirection: SORTING_TYPES.DESCENDING,
  sortBy: INVITE_FRIENDS_SORT_DROPDOWN_VALUES.SUM,
  data: [],
};
