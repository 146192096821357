import React, { memo, useCallback } from 'react';
import ru from 'react-phone-input-2/lang/ru.json';
import PhoneInput, { CountryData } from 'react-phone-input-2';
//
import { TKeyboardClickEvent } from '@shared/types';

import 'react-phone-input-2/lib/plain.css';

type TProps = {
  /**
   * Значение для элемента input
   * @param {string}
   */
  value: string;
  /**
   * Значение placeholder в input
   * @param {string}
   */
  placeholder: string;
  /**
   * Параметр name для инпута
   * @param {string}
   */
  name?: string;
  /**
   * Обрабатывает событие blur
   * @param {(value: string) => void}
   */
  onBlur?: (value: string) => void;
  /**
   * Callback для обработки события change для элемента input
   * @param {(value: string) => void}
   */
  onChangeHandler: (value: string) => void;
  /**
   * Опциональный callback для добавления номер по нажатию на кнопку Enter
   * @param {TKeyboardClickEvent}
   */
  onKeyDownHandler?: TKeyboardClickEvent;
  /**
   * Опциональный параметр, отключает возможность ввести данне в инпуты
   * @param {boolean}
   */
  disabled?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const PhoneNumberInput = memo(
  ({
    name,
    value,
    onBlur,
    disabled,
    placeholder,
    onChangeHandler,
    onKeyDownHandler,
    className = '',
  }: TProps) => {
    const isInnerError = false;

    const onBlurHandler = useCallback(() => {
      if (onBlur) {
        onBlur(value);
      }
    }, [onBlur, value]);

    const style = `relative pr-10 transition-all duration-150 ease-in-out ${
      isInnerError && !disabled
        ? 'shadow-[0_0_0_1px_rgb(250,169,163,1)] active:shadow-[0_0_0_2px_rgb(156,43,35,1)] focus:shadow-[0_0_0_2px_rgb(156,43,35,1)] hover:shadow-[0_0_0_1px_rgb(156,43,35,1)] innerError'
        : 'shadow-[0_0_0_1px_rgb(180,180,187,1)] active:shadow-[0_0_0_2px_rgb(58,58,68,1)] focus:shadow-[0_0_0_2px_rgb(58,58,68,1)] hover:shadow-[0_0_0_1px_rgb(58,58,68,1)]'
    } ${isInnerError && !disabled ? 'placeholder-lightRed' : ''} ${
      disabled ? 'hover:!shadow-[0_0_0_1px_rgb(180,180,187,1)] bg-white cursor-not-allowed' : ''
    } rounded whitespace-pre-wrap break-words px-3 py-2 mb-2 w-full`;

    const innerOnChangeHandler = useCallback(
      (phone: string, data: CountryData) => {
        const { dialCode } = data;

        if (!phone) {
          onChangeHandler(phone);
          return;
        }
        if (phone.startsWith(dialCode)) {
          onChangeHandler(phone);
        } else {
          onChangeHandler(`${dialCode}${phone.slice(dialCode.length)}`);
        }
      },
      [onChangeHandler],
    );

    return (
      <div className={className}>
        <PhoneInput
          enableSearch
          value={value}
          inputProps={{
            name,
          }}
          country='ru'
          localization={ru}
          disabled={disabled}
          onBlur={onBlurHandler}
          placeholder={placeholder}
          onKeyDown={onKeyDownHandler}
          containerClass='!mt-[0.1rem]'
          onChange={innerOnChangeHandler}
          buttonClass='!border-none rounded-l'
          dropdownClass='dropdown-list-scrollbar p-4 rounded mt-1'
          inputClass={`!border-none font-inter !text-[1rem] text-blackText rounded !h-10 !w-full ${style}`}
        />
      </div>
    );
  },
);

PhoneNumberInput.displayName = 'BlackListInput';
