import { TSimpleStringObj } from '@models/index';
import { reportPreviewDict, KEY_WRAPPER_SYMBOLS } from '@const/preview';

export interface IPreviewMaker {
  getPreviewMarkUp: (description: string) => string;
}

export class PreviewMaker implements IPreviewMaker {
  private readonly keyWrapperSymbols;

  private readonly reportPreviewDictionary;

  private description: string;

  constructor({
    keyWrapperSymbol,
    reportPreviewDictionary,
  }: {
    reportPreviewDictionary: TSimpleStringObj;
    keyWrapperSymbol: typeof KEY_WRAPPER_SYMBOLS;
  }) {
    this.description = '';
    this.keyWrapperSymbols = keyWrapperSymbol;
    this.reportPreviewDictionary = reportPreviewDictionary;
  }

  private getDividedText(
    startIndex: number,
    endIndex: number,
    keyLength?: number,
  ): {
    textBeforeKey: string;
    textInKey: string;
    textAfterKey: string;
  } {
    const textBeforeKey = this.description.slice(0, startIndex);
    const textInKey = this.description.slice(startIndex + (keyLength || 1), endIndex);
    const textAfterKey = this.description.slice(endIndex + (keyLength || 1));
    return {
      textAfterKey,
      textBeforeKey,
      textInKey,
    };
  }

  private replaceStatisticKeyByMockData() {
    let openSymbolIndex = 0;
    let isOpenIndexFound = false;
    let closedSymbolIndex = 0;
    let isCloseIndexFound = false;

    for (let i = 0; i < this.description.length; i++) {
      if (this.description[i] === this.keyWrapperSymbols.OPEN_SYMBOL) {
        openSymbolIndex = i;
        isOpenIndexFound = true;
      }

      if (this.description[i] === this.keyWrapperSymbols.CLOSE_SYMBOL) {
        closedSymbolIndex = i;
        isCloseIndexFound = true;
      }

      if (isOpenIndexFound && isCloseIndexFound) {
        const { textBeforeKey, textInKey, textAfterKey } = this.getDividedText(
          openSymbolIndex,
          closedSymbolIndex,
        );

        const replacedKeyLength = closedSymbolIndex - openSymbolIndex;

        const mockData = this.reportPreviewDictionary[textInKey];

        if (mockData) {
          const mockDataLength = mockData.length;

          const iCorrectValue = mockDataLength - replacedKeyLength;

          this.description = `${textBeforeKey}${mockData}${textAfterKey}`;

          i += iCorrectValue - 2;
        }

        openSymbolIndex = 0;
        isOpenIndexFound = false;
        closedSymbolIndex = 0;
        isCloseIndexFound = false;
      }
    }
  }

  private convertMarkdownToHtml(text: string) {
    let newText = text;

    // Handle bold text
    newText = newText.replace(/\*(.*?)\*/g, '<strong>$1</strong>');

    // Handle italic text, but ignore links
    newText = newText.replace(/(^|[^!])\b_(.*?)_\b/g, (match: string, p1: string, p2: string) => {
      // Match URLs starting with http:// or https:// and keep these intact
      if (/(http|https):\/\/[^\s]+/.test(p1 + p2)) {
        return `${p1}_${p2}_`;
      }
      return `${p1}<em>${p2}</em>`;
    });

    // Handle strikethrough text
    newText = newText.replace(/~(.*?)~/g, '<del>$1</del>');

    // Handle monospaced text
    newText = newText.replace(/```(.*?)```/g, '<code>$1</code>');

    this.description = newText;
  }

  getPreviewMarkUp(description: string): string {
    this.description = description;
    this.replaceStatisticKeyByMockData();
    this.convertMarkdownToHtml(this.description);
    return this.description;
  }
}

export const preview = new PreviewMaker({
  keyWrapperSymbol: KEY_WRAPPER_SYMBOLS,
  reportPreviewDictionary: reportPreviewDict,
});

Object.seal<IPreviewMaker>(preview);
