import React from 'react';
//
import { SearchInput } from '@uikit/Inputs/SearchInput';
import { TKeyboardClickEvent, TOnChangeHandler } from '@shared/types';

interface IBlackListSearchInputProps {
  /**
   * Значения поисковой строки
   * @param {string}
   */
  value: string;
  /**
   * Значение placeholder в input
   * @param {string}
   */
  placeholder: string;
  /**
   * Callback вызывается для очистки введенного значения в input
   * @param {() => void}
   */
  clearSearchString: () => void;
  /**
   * Callback для обработки ввода поисковой строки
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Опциональный callback для обработки нажатия кнопки Enter
   * @param {TOnChangeHandler}
   */
  onKeyDownHandler?: TKeyboardClickEvent;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const BlackListSearchInput = ({
  value,
  placeholder,
  onChangeHandler,
  onKeyDownHandler,
  clearSearchString,
  className = '',
}: IBlackListSearchInputProps) => (
  <SearchInput
    type='def'
    value={value}
    view='outlined'
    color='default'
    className={className}
    placeholder={placeholder}
    onChange={onChangeHandler}
    onKeyDown={onKeyDownHandler}
    clearSearchString={clearSearchString}
  />
);
