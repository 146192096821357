import React, { forwardRef } from 'react';

import Component from './Tooltip';
import PropTypes, { InferProps } from 'prop-types';

const propTypes = {
  /**
   ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    "default",
    "primary",
    "warning",
    "danger",
    "success",
    "primary_alt",
    "warning_alt",
    "danger_alt",
    "success_alt"
  ]),
  /**
   * The position (relative to the target) at which the popover should appear.
   * Variants: "auto", "top", "left", "right", "bottom"
   */
  position: PropTypes.oneOf(["auto", "top", "left", "right", "bottom"]),
  /**
   * The content that will be displayed inside of the tooltip.
   */
  content: PropTypes.element.isRequired,
  /**
   * Whether the popover is visible.
   * Passing this prop puts the popover in controlled mode, where the only way to change visibility is by updating this property.
   * If disabled={true}, this prop will be ignored, and the popover will remain closed.
   */
  isOpen: PropTypes.bool,
  /**
   * Prevents the popover from appearing when true.
   */
  disabled: PropTypes.bool
};

type TComponentPropTypes = InferProps<typeof propTypes>;

interface IComponentProps extends TComponentPropTypes {
  [key: string]: any;
}

export const Tooltip: React.FC<IComponentProps> = forwardRef((props, ref) => <Component {...props} ref={ref} />);