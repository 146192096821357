import React, { memo } from 'react';
//
import { Checkbox } from '@uikit/CheckBox';
import { TOnChangeHandler } from '@shared/types';

type TAccessCheckboxProps = {
  /**
   * Значение параметра name в input для onChangeHandlers
   * @param {string}
   */
  name: string;
  /**
   * Текст для label для input
   * @param {helpKeys}
   */
  text: string;
  /**
   * Значение checked для input
   * @param {boolean}
   */
  checked: boolean;
  /**
   * Callback для обработки события onChange
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const AccessCheckbox = memo(
  ({ text, name, checked, onChangeHandler, className = '' }: TAccessCheckboxProps) => (
    <div className={`flex items-center rounded min-h-7 ${checked ? 'bg-aquaSpring' : ''} w-full`}>
      <Checkbox
        fill
        name={name}
        label={text}
        color='success'
        checked={checked}
        className={className}
        onChange={onChangeHandler}
      />
    </div>
  ),
);

AccessCheckbox.displayName = 'AccessCheckbox';
