import React, { memo } from 'react';
//
import { THelpKeys } from '@models/index';
import { TOnChangeHandler } from '@shared/types';
import { SettingsCheckbox } from '@blocks/settingsCheckbox';
import { YclientsChatEnableModal } from '@components/yclientsChatEnableModal';
import { SETTINGS_DATA_KEY_NAMES, SHOW_CHECKBOX_FOR_SEND_OUT_ACCOUNT } from '@redux/settings/type';

type TSettingsCheckboxComponentProps = {
  /**
   * Флаг показа модалки для подтверждения включения чата
   * @param {boolean}
   */
  isShowChatEnableModal: boolean;
  /**
   * Состояние чекбокса Включить чат в журнале Yclients
   * @param {boolean}
   */
  enableChat: boolean;
  /**
   * Состояние чекбокса Проверка на ответ
   * @param {boolean}
   */
  replyMsgCheck: boolean;
  /**
   * Состояние чекбокса Отвечать на сообщения в телеграм
   * @param {boolean}
   */
  enableReplyInBot: boolean;
  /**
   * Состояние чекбокса Отправлять коды подтверждения в СМС
   * @param {boolean}
   */
  smsConfirm: boolean;
  /**
   * Состояние чекбокса Отправка контакта
   * @param {boolean}
   */
  sendContact: boolean;
  /**
   * Callback для обработки нажатий на чекбоксы
   * @param{TOnChangeHandler}
   */
  onChangeSelectHandler: TOnChangeHandler;
  /**
   * Callback для закрытия модалки
   * @param {() => void}
   */
  closeModalCallback: () => void;
  /**
   * Callback для подтверждения включения чата в журнале Yclients
   * @param {() => void}
   */
  enableChatCallback: () => void;
  /**
   * Указывает является ли выьранный филиал аккаунтом расслок
   * @param {boolean}
   */
  isSendOutAccount: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const SettingsCheckboxComponent = memo(
  ({
    enableChat,
    smsConfirm,
    sendContact,
    replyMsgCheck,
    enableReplyInBot,
    isSendOutAccount,
    enableChatCallback,
    closeModalCallback,
    onChangeSelectHandler,
    isShowChatEnableModal,
    className = '',
  }: TSettingsCheckboxComponentProps) => {
    const checkBoxData = [
      {
        isFirstTooltip: !isSendOutAccount,
        checked: enableChat,
        variant: SETTINGS_DATA_KEY_NAMES.ENABLE_CHAT_YCLIENTS,
        classNameText: '!mr-1',
      },
      {
        isFirstTooltip: false,
        checked: replyMsgCheck,
        variant: SETTINGS_DATA_KEY_NAMES.REPLAY_MESSAGE_CHECK,
        classNameText: '!mr-1',
      },
      {
        isFirstTooltip: isSendOutAccount,
        checked: enableReplyInBot,
        variant: SETTINGS_DATA_KEY_NAMES.ENABLE_REPLAY_IN_BOT,
        classNameText: '!mr-1',
      },
      {
        isFirstTooltip: false,
        checked: smsConfirm,
        variant: SETTINGS_DATA_KEY_NAMES.SMS_CONFIRM,
        classNameText: '!mr-1',
      },
      {
        isFirstTooltip: false,
        checked: sendContact,
        variant: SETTINGS_DATA_KEY_NAMES.SEND_CONTACT,
        classNameText: '!mr-1',
      },
    ];

    const checkBoxToRender = checkBoxData.map(
      ({ checked, classNameText, isFirstTooltip, variant }, index) => {
        const isLastElement = index === checkBoxData.length - 1;
        if (isSendOutAccount && SHOW_CHECKBOX_FOR_SEND_OUT_ACCOUNT[variant]) {
          return (
            <div key={variant} className={!isLastElement ? 'mb-2' : ''}>
              <SettingsCheckbox
                checked={checked}
                htmlFor={variant}
                variant={variant as THelpKeys}
                isFirstTooltip={isFirstTooltip}
                onChangeHandler={onChangeSelectHandler}
                className={classNameText}
              />
            </div>
          );
        }
        if (!isSendOutAccount) {
          return (
            <div key={variant} className={!isLastElement ? 'mb-2' : ''}>
              <SettingsCheckbox
                checked={checked}
                htmlFor={variant}
                variant={variant as THelpKeys}
                isFirstTooltip={isFirstTooltip}
                onChangeHandler={onChangeSelectHandler}
                className={classNameText}
              />
            </div>
          );
        }
        return null;
      },
    );

    return (
      <div className={`flex flex-col ${className}`}>
        {checkBoxToRender}
        <YclientsChatEnableModal
          enableChatCallback={enableChatCallback}
          closeModalCallback={closeModalCallback}
          isShowChatEnableModal={isShowChatEnableModal}
        />
      </div>
    );
  },
);

SettingsCheckboxComponent.displayName = 'SettingsCheckboxComponent';
