import { getEUDateFromString } from '@helpers/index';
import { TGetFullTableData, TGetFullTableResponse } from '@api/types';
import { partnerFullTableFirstPayValuesArray } from '@const/partners';
import { TPartnersFullTableStoreData } from '@redux/partnersFullTable/models';

type TMapGetFullTableDataToStore = (
  data: TGetFullTableResponse<TGetFullTableData>,
) => TPartnersFullTableStoreData;

/**
 * Преобразует данные полученные из getFullTable в структуру данных хранилища Redux.
 * @param {TGetFullTableResponse<TGetFullTableData>} data - Данные полученные из getFullTable.
 * @returns {TPartnersFullTableStoreData} - Преобразованные данные для хранилища.
 */
const mapGetFullTableDataToStore: TMapGetFullTableDataToStore = ({
  promo_code,
  promo_url,
  qr_code,
  data,
}) => {
  return {
    qrCode: qr_code,
    promoUrl: promo_url,
    promoCode: promo_code,
    data: data.map(({ branch, branch_name, first_payment_date, created_at, pay_status }) => {
      const isDate = partnerFullTableFirstPayValuesArray.includes(first_payment_date);

      return {
        accId: branch,
        filialName: branch_name,
        connectDate: getEUDateFromString(created_at),
        firstPayDate: isDate ? first_payment_date : getEUDateFromString(first_payment_date),
        status: pay_status,
      };
    }),
  };
};

export { mapGetFullTableDataToStore };
