export const chartColors: { [key: string]: string } = {
  'green-30': '#87C289',
  'orange-50': '#FB982E',
  'indigo-40': '#97A0D6',
  'pink-60': '#EC407A',
  'blue-40': '#76A9FF',
  'yellow-40': '#EBBF67',
  'teal-40': '#60BCBC',
  'braon-drk': '#9D3C4D',
  'green-50': '#65B168',
  'orange-60': '#FB8200',
  'indigo-60': '#5C6BC0',
  'pink-50': '#EF6292',
  'blue-60': '#2979FF',
  'yellow-60': '#D6981B',
  'teal-60': '#069697',
  'red-40': '#F8877F',
  'green-70': '#37833B',
  'orange-40': '#FCAF5C',
  'indigo-70': '#4C589E',
  'pink-40': '#F285AA',
  'blue-50': '#4F91FF',
  'yellow-50': '#E5AE40',
  'teal-70': '#057B7C',
  'red-50': '#F6655A',
  'green-60': '#43A047',
  'teal-sea': '#009688',
  'braon-grey': '#795548',
  'pink-new': '#FF6384',
  'wet-sky': '#3F729B',
  'light-blue': '#9DC2FF',
  'pink-deep': '#DA4771',
  'blue-mint': '#8DCFCF',
  'gray-asphalt': '#607D8B',
  'yellow-vivid': '#FFD700',
  'blue-flo': '#76A9FF',
  'burgund-dark': '#581845',
  violet: '#976BFC',
  'green-grass': '#8BC34A',
  'bright-pink': '#FF5252',
  'yellow-bright': '#FFCC5D',
};
