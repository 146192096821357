import { apiService } from '@api/ApiService';
import { TRequestStatus } from '@api/types';
import { TFeedbackData } from '@models/index';
import { END_POINTS_URL, QUERY_PARAMS } from '@const/apiConstants';

/**
 * API обратной связи
 * @module connectionApi
 */

export const feedbackApi = {
  /**
   * Метод отправляет текст отзыва
   * @method sendFeedback
   * @return { Promise<AxiosResponse<TRequestStatus, any>>}
   */
  sendFeedback(data: TFeedbackData) {
    const sendData = { text: data.text, topic: data.topic };
    return apiService.post<TRequestStatus>(END_POINTS_URL.SEND_FEEDBACK_TO_ASANA, sendData, {
      params: {
        [QUERY_PARAMS.BRANCH_NAME]: data.filialName,
      },
    });
  },
};
