import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import vkWallpaper from '@img/inviteFriend/vkWallpaper.jpg';
import { useCloseByOutSideClick } from '@hooks/useCloseByOutSideClick';
import { ReactComponent as PlayButtonIcon } from '@img/inviteFriend/play_circle.svg';

type TProps = {
  /**
   * Ссылка на заставку для видео
   * @param {string}
   */
  wallpaper?: string;
  /**
   * Ссылка на видео в VK
   * @param {string}
   */
  videoLink: string;
  /**
   * Время на которое нужно перемотать ролик
   * @param {string}
   */
  startTime: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

declare global {
  interface IWindow {
    // eslint-disable-next-line
    VK: Record<string, (...args: any[]) => void>;
  }
}

export const VkVideoInstruction = memo(
  ({ videoLink, startTime, wallpaper, className = '' }: TProps) => {
    const { t } = useTranslation();
    const [isShowWallpaper, setIsShowWallpaper] = useState(!!wallpaper);

    // Добавляем типизацию для VK
    const win = window as unknown as IWindow;

    // Реф для хранения плеера
    // eslint-disable-next-line
    const player = useRef<any>(null);

    // Реф для iframe
    const ref = useRef<HTMLIFrameElement>(null);

    // Показывает заставку(картинку) и ставит на паузу воспроизведение
    const showWallpaper = useCallback(() => {
      player?.current?.pause();

      if (wallpaper) {
        setIsShowWallpaper(true);
      }
    }, [wallpaper]);

    // Создает плеер и записывает его в реф
    const setPlayer = useCallback(() => {
      if (win.VK && ref.current && !player.current) {
        player.current = win.VK.VideoPlayer(ref.current);
      }
    }, [win.VK]);

    // Устанавливает время начала воспроизведения видео
    const setStartTime = useCallback(() => {
      if (startTime && player?.current) {
        player?.current?.seek(startTime);
      }
    }, [startTime]);

    // Хук для закрытия плеера в случае клика вне зоны компонента
    useCloseByOutSideClick({ ref, closeElementCallback: wallpaper ? showWallpaper : () => '' });

    // Скрывает заставку запускает создание плеера и устанавливает время начала воспроизведения
    const hideWallpaper = useCallback(() => {
      setIsShowWallpaper(false);

      player?.current?.play();
    }, []);

    // формируем тэг script и добавляем его в DOM
    useEffect(() => {
      const script = document.createElement('script');
      const wrapper = document.getElementById('wrapper');

      script.src = 'https://vk.com/js/api/videoplayer.js';
      script.async = true;

      wrapper?.appendChild(script);

      return () => {
        wrapper?.removeChild(script);
      };
    }, []);

    useEffect(() => {
      setPlayer();

      setStartTime();

      if (!wallpaper) {
        player?.current?.play();
      }
    }, [setPlayer, setStartTime, wallpaper]);

    return (
      <div id='wrapper' className={className}>
        <div
          onClick={hideWallpaper}
          className='relative cursor-pointer flex items-center justify-center w-full'>
          <PlayButtonIcon
            className={`absolute ${isShowWallpaper ? '' : 'hidden'} h-[5rem] w-[5rem]`}
          />
          <img
            alt='vkWallpaper'
            src={wallpaper || vkWallpaper}
            className={`rounded-lg ${isShowWallpaper ? '' : 'hidden'} h-auto w-full`}
          />
        </div>
        <iframe
          ref={ref}
          title='vk'
          frameBorder='0'
          allowFullScreen
          src={`${videoLink}&js_api=1`}
          allow='autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;'
          className={`aspect-video rounded-lg ${isShowWallpaper ? 'hidden' : ''} w-full`}
        />
        <p className='text-black font-medium text-center leading-6 tracking-[0.022em] mt-3 w-full'>
          {t('INVITE_FRIEND_PAGE_TEXT.videoInstructionHeader')}
        </p>
      </div>
    );
  },
);

VkVideoInstruction.displayName = 'VkVideoInstruction';
