import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Menu } from '@uikit/Menu';
import { Dropdown } from '@uikit/Dropdown';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { WhenSendDay } from '@blocks/whenSendDay';
import { TSetWhenSend2Days } from '@shared/types';
import { TSimpleStringObjLevel2 } from '@models/index';
import { getSortedWhenSend2OptionArray } from '@helpers/index';
import { WHEN_SEND_2_VALUES, WHEN_SEND_VALUE } from '@const/templates';

type TAllDayRecWhenSend2 = {
  /**
   * Флаг отключает возможность изменения значения в блоке
   * @param {boolean}
   */
  disabled: boolean;
  /**
   * Значение дропдауна whenSend
   * @param {string}
   */
  whenSend: string;
  /**
   * Значение дропдауна whenSend2
   * @param {string}
   */
  whenSend2: string;
  /**
   * Объект с данными для формирования опций в выпадающем списке
   * @param {TSimpleStringObjLevel2}
   */
  whenSend2SelectOption: TSimpleStringObjLevel2;
  /**
   * Callback для изменения параметра whenSend2
   * @param {(name: string) => void;}
   */
  setWhenSend2: (name: string) => () => void;
  /**
   * Через сколько дней отправлять шаблон
   * @param {string}
   */
  whenSend2Days: string;
  /**
   * Callback для изменения whenSend2Days
   * @param {TSetWhenSend2Days}
   */
  setWhenSend2Days: TSetWhenSend2Days;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const AllDayRecWhenSend2 = memo(
  ({
    disabled,
    whenSend,
    whenSend2,
    setWhenSend2,
    whenSend2Days,
    setWhenSend2Days,
    whenSend2SelectOption,
    className = '',
  }: TAllDayRecWhenSend2) => {
    const { t } = useTranslation();

    // Получаем текст выбранного пункта для dropdown
    const selectedWhenSend2OptionText = useMemo(
      () => whenSend2SelectOption[whenSend][whenSend2] || t('TEMPLATE_PAGE_TEXT.whenSendSelect'),
      [t, whenSend, whenSend2, whenSend2SelectOption],
    );

    // получаем массив для опций в селекте whenSend2
    const whenSend2OptionArray = useMemo(
      () => getSortedWhenSend2OptionArray(whenSend, whenSend2SelectOption[whenSend]),
      [whenSend, whenSend2SelectOption],
    );

    // формируем список опций для селекта whenSend2
    const whenSend2OptionList = useMemo(
      () =>
        whenSend2OptionArray.map(([key, value]) => (
          <MenuItem
            key={key}
            text={<span>{value}</span>}
            onClick={setWhenSend2(key)}
            className={whenSend2 === key ? '!bg-ivory' : ''}
          />
        )),
      [setWhenSend2, whenSend2, whenSend2OptionArray],
    );

    return (
      <div className={`flex flex-col sm:flex-row sm:items-center w-full ${className}`}>
        <div
          className={`${
            whenSend === WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION &&
            whenSend2 !== WHEN_SEND_2_VALUES.ALL_DAY_REC_TODAY
              ? 'sm:max-w-[19.7rem] min-w-[12rem]'
              : 'min-w-[15rem]'
          } mt-2 w-full`}>
          <Dropdown
            color='default'
            view='outlined'
            disabled={disabled}
            position='bottom-left'
            className='when_send_dropdown w-full'
            text={
              <span className={disabled ? 'cursor-not-allowed text-start w-full' : ''}>
                {selectedWhenSend2OptionText}
              </span>
            }
            content={
              <Menu
                view='raised'
                className={`dropdown-list-scrollbar max-h-[50vh] overflow-y-auto ${
                  whenSend2OptionList.length === 1 ? `one_item_ul` : ''
                }`}>
                {whenSend2OptionList}
              </Menu>
            }
          />
        </div>
        {whenSend === WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION &&
        whenSend2 !== WHEN_SEND_2_VALUES.ALL_DAY_REC_TODAY ? (
          <WhenSendDay
            disabled={disabled}
            whenSend2={whenSend2}
            value={whenSend2Days}
            onChangeCallback={setWhenSend2Days({ maxValue: 999, minValue: 1 })}
            className='ml-[2px] mt-2 sm:ml-[0.5625rem] min-w-[10rem]'
          />
        ) : null}
      </div>
    );
  },
);

AllDayRecWhenSend2.displayName = 'AllDayRecWhenSend2';
