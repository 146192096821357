import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//
import { PATH_NAMES } from '@data/dict';
import { Status } from '@blocks/colorStatus';
import { getLinkHref } from '@helpers/index';
import { FilialStatus } from '@atoms/filialStatus';
import { CONNECTION_STATUSES } from '@models/index';
import { ROUTES_ACCESS_RIGHTS } from '@const/access';
import { TFilialData } from '@redux/accounts/models';

interface IFilialListItemProps {
  /**
   * Данные филиала
   * @param {TFilialData}
   */
  filialData: TFilialData;
  /**
   * Callback для установки выбранного филиала в store при клике на NavLink
   * @param {() => void}
   */
  setChosenFilialId: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const FilialListItem = memo(
  ({ filialData, setChosenFilialId, className = '' }: IFilialListItemProps) => {
    const { t } = useTranslation();
    const isOwner = filialData.rights.includes(ROUTES_ACCESS_RIGHTS.OWNER);
    const isSettings = filialData.rights.includes(ROUTES_ACCESS_RIGHTS.TRANSLATION_SWITCH);

    const commonClasses = 'flex mr-[0.75rem] items-center text-blackText hover:text-blackText h-16';

    const settingsClasses = `flex items-center justify-center border 
    border-dGray px-[0.75rem] py-[0.375rem] bg-white font-medium 
    rounded text-[0.875rem] text-blackText h-[1.75rem] hover:no-underline ${
      isOwner || isSettings
        ? 'hover:text-blackText hover:shadow-[0_0_0_1px_rgb(180,180,187,1)] transition-all duration-300 ease-[cubic-bezier(0.25, 0.46, 0.45, 0.94)]'
        : 'pointer-events-none text-dGray border-dGray'
    }`;

    return (
      <tr className={`border-b border-quartz1 max-h-16 ${className}`}>
        <td className='px-2 cursor-default'>
          {filialData.sendOutAccount ? (
            <NavLink
              onClick={setChosenFilialId}
              to={getLinkHref({ route: PATH_NAMES.MAILING, accId: filialData?.accId })}
              className={commonClasses}>
              <span className='mr-2'>{filialData.address}</span>
              <span className='bg-lightGray rounded-full py-0.5 px-[0.375rem] ml-2 text-[0.75rem] text-darkGray'>
                {t('ACCOUNTS_PAGE_TEXT.tableRow.mailing')}
              </span>
            </NavLink>
          ) : (
            <NavLink
              onClick={setChosenFilialId}
              to={getLinkHref({ route: PATH_NAMES.CONNECTION, accId: filialData?.accId })}
              className={commonClasses}>
              {filialData.address}
            </NavLink>
          )}
        </td>
        <td className='px-2'>
          <NavLink
            onClick={setChosenFilialId}
            to={getLinkHref({ route: PATH_NAMES.CONNECTION, accId: filialData?.accId })}
            className={commonClasses}>
            <FilialStatus status={filialData.status} className='!h-5 !w-5' />
            <span className='font-medium ml-2'>
              {filialData.status === CONNECTION_STATUSES.AUTHENTICATED
                ? t('ACCOUNTS_PAGE_TEXT.tableRow.connectionStatus.connected')
                : t('ACCOUNTS_PAGE_TEXT.tableRow.connectionStatus.notConnected')}
            </span>
          </NavLink>
        </td>
        <td className='align-middle text-right px-2 h-16'>
          <NavLink
            onClick={setChosenFilialId}
            to={getLinkHref({ route: PATH_NAMES.TEMPLATE_LIST_ALL, accId: filialData?.accId })}
            className={commonClasses}>
            {filialData.quantityOfTemplates}
          </NavLink>
        </td>
        <td className='align-middle px-2 h-16'>
          <NavLink
            onClick={setChosenFilialId}
            to={getLinkHref({ route: PATH_NAMES.STARTED_MAILING, accId: filialData?.accId })}
            className={commonClasses}>
            {filialData.actionSendOut}
          </NavLink>
        </td>
        <td className='px-2'>
          <NavLink
            onClick={setChosenFilialId}
            to={getLinkHref({ route: PATH_NAMES.PAY, accId: filialData?.accId })}
            className='text-blackText hover:text-blackText h-16'>
            <div className='flex items-center mr-[0.75rem] '>
              <Status isPayStatus status={filialData.payStatus} title='статус оплаты' />
              <span className='flex flex-wrap font-medium ml-2'>
                {filialData.payStatus
                  ? t('ACCOUNTS_PAGE_TEXT.tableRow.paymentStatus.paidUntil')
                  : t('ACCOUNTS_PAGE_TEXT.tableRow.paymentStatus.notPaid')}
                {filialData.payStatus ? (
                  <span className='ml-[0.3125rem]'>{filialData.payPeriod}</span>
                ) : null}
              </span>
            </div>
          </NavLink>
        </td>
        <td className={`px-2 ${isOwner || isSettings ? '' : 'cursor-not-allowed'}`}>
          <NavLink
            onClick={setChosenFilialId}
            to={getLinkHref({ route: PATH_NAMES.SETTINGS, accId: filialData?.accId })}
            className={settingsClasses}>
            {t('ACCOUNTS_PAGE_TEXT.tableRow.settingsButton')}
          </NavLink>
        </td>
      </tr>
    );
  },
);

FilialListItem.displayName = 'FilialListItem';
