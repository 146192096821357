import React from 'react';
//
import { Checkbox } from '@uikit/CheckBox';
import { TOnChangeHandler } from '@shared/types';

interface ICheckboxToDropdownProps {
  /**
   * Значение параметра name в инпуте
   * @param {string}
   */
  name: string;
  /**
   * Текст метки для инпута
   * @param {string}
   */
  text: string;
  /**
   * Состояние инпута
   * @param {boolean}
   */
  checked: boolean;
  /**
   * Callback для обработки события change для элемента input
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const CheckboxToDropdown = ({
  text,
  name,
  checked,
  onChangeHandler,
  className = '',
}: ICheckboxToDropdownProps) => {
  return (
    <div className='py-1 px-2'>
      <div
        className={`flex items-center rounded min-h-7 text-[1rem] ${
          checked ? 'bg-aquaSpring' : ''
        } w-full`}>
        <Checkbox
          fill
          name={name}
          label={text}
          color='success'
          checked={checked}
          className={className}
          onChange={onChangeHandler}
        />
      </div>
    </div>
  );
};
