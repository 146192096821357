import React, { memo } from 'react';
//
import { TAppLanguage } from '@models/index';
import { TClickHandler } from '@shared/types';
import { TCustomerKeys } from '@redux/mailing/models';
import { MailingInstruction } from '@blocks/mailingInstruction';
import { MailingExamplesBanner } from '@blocks/mailingExamplesBanner';
import { TemplateAdditionalInfo } from '@blocks/templateAdditionalInfo';

interface IImportantMailingInfoProps {
  /**
   * Отключает кнопку открытия drawer с примерами для рассылки
   * @param {boolean}
   */
  disableOpenDrawerButton: boolean;
  /**
   * Показывает/скрывает блок с примерами рассылок
   * @param {boolean}
   */
  isShowExampleBlock: boolean;
  /**
   * Флаг показывает блок с ключами и форматированием текста
   * @param {boolean}
   */
  isShowAdditionalInfo?: boolean;
  /**
   * Флаг переключения вида компонента, если true, компонент принимает мобильный вид
   * @param {boolean}
   */
  isMobileView: boolean;
  /**
   * Callback для добавления ключа в текст
   * @param {(key: string) => () => void}
   */
  setKeyInTextarea: (key: string) => () => void;
  /**
   * Callback для оборачивания выделенного текста в символы форматирования
   * @param {TClickHandler}
   */
  wrapSelectedTextInTextarea: TClickHandler;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Callback для открытия drawer с примерами шаблонов
   * @param {() => void}
   */
  openDrawer?: () => void;
  /**
   * Пользовательские ключи полученные после парсинга файла с данными клиентов
   * @param {TCustomerKeys}
   */
  customerKeys: TCustomerKeys;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}
// блок с важной информацией о рассылках, текст берется из /data/dict.ts
export const ImportantMailingInfo = memo(
  ({
    openDrawer,
    appLanguage,
    customerKeys,
    isMobileView,
    setKeyInTextarea,
    isShowExampleBlock,
    disableOpenDrawerButton,
    wrapSelectedTextInTextarea,
    isShowAdditionalInfo = false,
    className = '',
  }: IImportantMailingInfoProps) => {
    return (
      <div className={`flex flex-col ${className}`}>
        {isShowAdditionalInfo ? (
          <TemplateAdditionalInfo
            forMailingTemplate
            forMobile={isMobileView}
            specialKeyDict={customerKeys}
            setKeyInTextarea={setKeyInTextarea}
            wrapSelectedTextInTextarea={wrapSelectedTextInTextarea}
            className='mb-6'
          />
        ) : null}
        <div className='flex flex-col sm:flex-row xl:flex-col'>
          {isShowExampleBlock ? (
            <MailingExamplesBanner
              openDrawer={openDrawer}
              disableOpenDrawerButton={disableOpenDrawerButton}
              className={`${isMobileView ? 'sm:basis-[20rem] sm:grow sm:mr-6' : ''} mb-6`}
            />
          ) : null}
          <MailingInstruction
            appLanguage={appLanguage}
            className={`${isMobileView ? 'sm:basis-[20rem] sm:grow' : ''} w-full`}
          />
        </div>
      </div>
    );
  },
);
