import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { Icon } from '@blueprintjs/core';
import { numWord } from '@helpers/index';
import { useAppSelector } from '@store/store';
import { getAppData } from '@redux/app/selectors';
import { TIME, VERSIONS } from '@const/templates';
import { getAccountsData } from '@redux/accounts/selectors';
import { IconWithTooltips } from '@components/iconWithTooltips';
import { END_POINTS_URL, QUERY_PARAMS } from '@const/apiConstants';
import { TemplatesProgressBar } from '@blocks/templatesProgressBar';
import { TemplatesVersionDropdown } from '@blocks/templatesVersionDropdown';
import {
  TTemplatesStatisticsData,
  TTemplatesStatisticsVersionData,
} from '@redux/templateStats/models';

type TStatisticCardProps = {
  /**
   * Данные статистики шаблона
   * @param {TTemplatesStatisticsData}
   * */
  cardData: TTemplatesStatisticsData;
  /**
   * Опциональная строка классов
   * @param {string}
   * @default ''
   * */
  className?: string;
};

export const StatisticCard = memo(({ cardData, className = '' }: TStatisticCardProps) => {
  const { t } = useTranslation();

  const { appLanguage } = useAppSelector(getAppData);
  const { selectedFilial } = useAppSelector(getAccountsData);
  const [activeCard, setActiveCard] = useState<TTemplatesStatisticsVersionData | null>(null);

  const { id, count, versions } = cardData;

  useEffect(() => {
    if (!activeCard && id) {
      setActiveCard(versions[0]);
    }
  }, [activeCard, versions, id]);

  const setActiveVersionHandler = useCallback(
    (versionID: string) => {
      setActiveCard(versions.find(versionItem => versionItem.version === versionID) || null);
    },
    [versions],
  );

  const link = `${END_POINTS_URL.TEMPLATE_STAT_SEND_ONLY_LOST}?${QUERY_PARAMS.ACC_ID}=${
    selectedFilial?.accId || ''
  }&${QUERY_PARAMS.TEMPL_ID}=${id}`;

  const getReadyVersions = useMemo(() => {
    return versions.map(({ version }) => version);
  }, [versions]);

  if (!activeCard) return null;

  const {
    text,
    time,
    earnOneMsg,
    allSendCount,
    allEarnedCount,
    allRecordedRate,
    allAnsweredRate,
    allRecordedCount,
    allAnsweredCount,
  } = activeCard;

  return (
    <div
      className={`relative border border-lightGray flex flex-col grow shrink font-inter text-[1.5rem] text-blackText rounded-lg hover:shadow-btnHoverShadow transition-all ${className}`}>
      <div className='p-4'>
        <div className='flex flex-col md:flex-row justify-between mb-5'>
          <div className='flex flex-wrap'>
            <p className='break-words font-medium text-black text-h3Mobile tracking-[0.004em] leading-[1.5rem] overflow-hidden mb-0 pr-[0.5rem] h-[3rem]'>
              {t('TEMPLATE_PAGE_TEXT.statistics.templateNumber', { id })}
            </p>
            <p className='font-medium text-body_text text-darkGray tracking-[0.004em] leading-[1.5rem] overflow-hidden mb-0 pr-[2rem] h-[3rem]'>
              {`(${count} ${numWord(Number(count), VERSIONS[appLanguage])})`}
            </p>
          </div>
          <div className='flex flex-col md:flex-row'>
            <TemplatesVersionDropdown
              versions={getReadyVersions}
              selectedVersion={activeCard.version}
              onVersionSelect={setActiveVersionHandler}
              className='mb-4 md:mb-0 md:mr-4 z-10'
            />
            <Button
              dense
              type='action'
              view='outlined'
              icon={<Icon icon='timeline-bar-chart' size={24} />}>
              {t('TEMPLATE_PAGE_TEXT.statistics.detailStatistics')}
            </Button>
          </div>
        </div>
        <p className='text-body_text overflow-hidden mb-[1rem]'>{text}</p>
        <p className='bg-dot bg-left-0.625rem bg-no-repeat pl-[2.25rem] text-body_text text-grayText max-w-[85%] mb-4'>
          {time}
        </p>
        <TemplatesProgressBar
          recorded={Math.trunc(allRecordedRate)}
          answered={Math.trunc(allAnsweredRate)}
        />
      </div>
      <div className='grid m:grid-rows-4 md:grid-rows-1 md:grid-cols-templateStatsCard m:gap-y-[1rem] md:gap-x-5 px-4 pb-4 items-center'>
        <div className=''>
          <p className='text-body_text text-grayText'>
            {t('TEMPLATE_PAGE_TEXT.statistics.templateSent')}
          </p>
          <p className='mr-[0.5rem] text-h6Mobile leading-[1.975rem]'>
            {`${allSendCount} `}
            <span className='text-inner_text text-grayText leading-[1.25125rem]'>
              {numWord(allSendCount, TIME[appLanguage])}
            </span>
          </p>
        </div>

        <div className=''>
          <p className='text-body_text text-grayText'>
            {t('TEMPLATE_PAGE_TEXT.statistics.answered')}
          </p>
          <p className='mr-[0.5rem] text-h6Mobile leading-[1.975rem]'>
            {`${allAnsweredCount} `}
            <span className='text-inner_text text-grayText leading-[1.25125rem]'>{`${allAnsweredRate}%`}</span>
          </p>
        </div>

        <div className=''>
          <p className='text-body_text text-grayText'>
            {t('TEMPLATE_PAGE_TEXT.statistics.registered')}
          </p>
          <p className='mr-[0.5rem] text-h6Mobile leading-[1.975rem]'>
            {`${allRecordedCount} `}
            <span className='text-inner_text text-grayText leading-[1.25125rem]'>{`${allRecordedRate}%`}</span>
          </p>
        </div>

        <div className=''>
          <p className='text-body_text text-grayText'>
            {t('TEMPLATE_PAGE_TEXT.statistics.sumOfRecords')}
          </p>
          <p className='mr-[0.5rem] text-h6Mobile leading-[1.975rem]'>
            {allEarnedCount}
            <span className='text-inner_text text-grayText leading-[1.25125rem]'>₽</span>
          </p>
        </div>

        <div className=''>
          <div className='flex items-baseline relative z-10'>
            <span className='text-body_text text-grayText mr-2'>
              {t('TEMPLATE_PAGE_TEXT.statistics.earnings')}
            </span>
            <IconWithTooltips tooltips='statisticEarnOneMessage' />
          </div>
          <p className='mr-[0.5rem] text-h6Mobile leading-[1.975rem]'>
            {earnOneMsg}
            <span className='text-inner_text text-grayText leading-[1.25125rem]'>
              {t('TEMPLATE_PAGE_TEXT.statistics.rublSign')}
            </span>
          </p>
        </div>
      </div>
      <Link
        to={link}
        aria-label={t('TEMPLATE_PAGE_TEXT.statistics.template', { id })}
        className='absolute top-0 right-0 bottom-0 left-0'
      />
    </div>
  );
});

StatisticCard.displayName = 'StatisticCard';
