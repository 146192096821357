import { TConsultantData } from '@api/types';
import { TBannerDataType, TFilialData } from '@redux/accounts/models';

export interface IInitialState {
  isLoading: boolean;
  isError: boolean;
  retries: number;
  isRetries: boolean;
  isAccessDenied: boolean;
  isNoFilialError: boolean;
  selectedFilial: TFilialData | null;
  data: TFilialData[];
  consultantData: TConsultantData | null;
  bannerData: TBannerDataType;
  isSalon: boolean;
  isSalonConsultant: boolean;
}

export const initialState: IInitialState = {
  isLoading: true,
  isError: false,
  isRetries: false,
  retries: 1,
  isAccessDenied: false,
  isNoFilialError: false,
  selectedFilial: null,
  data: [],
  consultantData: null,
  bannerData: {
    imageUrl: '',
    infoUrl: '',
  },
  isSalon: true,
  isSalonConsultant: false,
};
