import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { DeleteButton } from '@blocks/deleteButton';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { ACCESS_DATA_KEY_NAMES } from '@redux/access/models';
import { AccessSelectBlock } from '@components/accessSelectBlock';

interface IAccessBlockProps {
  /**
   * @param {number}
   */
  id: number;
  /**
   * Telegram ID пользователя
   * @param {string}
   */
  userId: string;
  /**
   * Имя пользователя
   * @param {string}
   */
  name: string;
  /**
   * Массив прав
   * @param {string[]}
   */
  rights: string[];
  /**
   * Callback для обработки изменений userId и name
   * @param {(id: number) => (event: React.ChangeEvent<HTMLInputElement>) => void}
   */
  onChangeHandlerInput: (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Callback для обработки изменений массива rights
   * @param {(id: number) => (event: React.ChangeEvent<HTMLInputElement>) => void}
   */
  onChangeSelectHandler: (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Callback для обработки изменения userId после смены фокуса
   */
  onBlurHandler: (id: number) => (event: React.FocusEvent<HTMLInputElement>) => void;
  /**
   * Callback для удаления блока
   * @param {(id: string) => () => void}
   */
  deleteBlock: (id: string) => () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const AccessBlock = memo(
  ({
    id,
    name,
    rights,
    userId,
    deleteBlock,
    onBlurHandler,
    onChangeHandlerInput,
    onChangeSelectHandler,
    className = '',
  }: IAccessBlockProps) => {
    const { t } = useTranslation();

    return (
      <div
        id={id.toString()}
        className={`relative bg-gray1 flex flex-wrap items-center justify-between rounded pl-4 pr-[4.5rem] min-h-[3.5rem] max-w-[57.5rem] w-full ${className}`}>
        <TextInput
          type='text'
          value={userId}
          color='default'
          view='outlined'
          onBlur={onBlurHandler(id)}
          onChange={onChangeHandlerInput(id)}
          name={ACCESS_DATA_KEY_NAMES.USER_ID}
          placeholder={t('ACCESS_PAGE_TEXT.accessBlockFirstInputPlaceholder')}
          className='mt-2 xl:my-2 md:max-w-[49%] xl:max-w-[11.5rem] min-w-[11.25rem] !w-full'
        />
        <TextInput
          type='text'
          value={name}
          color='default'
          view='outlined'
          name={ACCESS_DATA_KEY_NAMES.NAME}
          onChange={onChangeHandlerInput(id)}
          placeholder={t('ACCESS_PAGE_TEXT.accessBlockSecondInputPlaceholder')}
          className='my-2 md:mb-0 md:mt-2 xl:my-2 md:max-w-[49%] xl:max-w-[22rem] min-w-[12.5rem] !w-full'
        />
        <AccessSelectBlock
          rights={rights}
          onChangeHandler={onChangeSelectHandler(id)}
          className='mb-2 md:my-2 xl:max-w-[17.5rem] min-w-[12.5rem] !w-full'
        />
        <DeleteButton callback={deleteBlock(userId)} className='absolute right-6' />
      </div>
    );
  },
);

AccessBlock.displayName = 'AccessBlock';
