import { createAsyncThunk } from '@reduxjs/toolkit';
import { TRejectResponseData } from '@models/index';
import { inviteFriendsApi } from '@api/inviteFriendsApi';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { TInviteFriendsStatisticsItemData } from '@redux/inviteFrendsStatistics/types';
import { inviteFriendsStatisticsMappers } from '@redux/inviteFrendsStatistics/inviteFriendsStatisticsMappers';

/**
 * Thunk Запрашивает данные статистики/аналитики.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
export const getInviteFriendsAnalytics = createAsyncThunk<
  TInviteFriendsStatisticsItemData[] | void,
  void,
  { rejectValue: TRejectResponseData }
>('inviteFriendSlice/getInviteFriendsAnalytics', (_, { dispatch, rejectWithValue }) => {
  return inviteFriendsApi
    .getAnalytics()
    .then(response => {
      return inviteFriendsStatisticsMappers.responseToStore(response.data.data);
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getInviteFriendsAnalytics()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});
