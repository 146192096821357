import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//
import { AUTH_PAGE_TEXT, AUTH_PAGE_TEXT_ENG } from '@const/auth';
import { ACCESS_PAGE_TEXT, ACCESS_PAGE_TEXT_ENG } from '@const/access';
import { LOADER_PAGE_TEXT, LOADER_PAGE_TEXT_ENG } from '@const/loader';
import { ADD_LID_MESSAGES, ADD_LID_MESSAGES_ENG } from '@const/addLead';
import { PARTNER_PAGE_TEXT, PARTNER_PAGE_TEXT_ENG } from '@const/partners';
import { ACCOUNTS_PAGE_TEXT, ACCOUNTS_PAGE_TEXT_ENG } from '@const/accounts';
import { SETTINGS_PAGE_TEXT, SETTINGS_PAGE_TEXT_ENG } from '@const/settings';
import { TEMPLATE_PAGE_TEXT, TEMPLATE_PAGE_TEXT_ENG } from '@const/templates';
import { BLACKLIST_PAGE_TEXT, BLACKLIST_PAGE_TEXT_ENG } from '@const/blacklist';
import { CONNECTION_PAGE_TEXT, CONNECTION_PAGE_TEXT_ENG } from '@const/connect';
import { BASE_TEMPLATE_PAGE_TEXT, BASE_TEMPLATE_PAGE_TEXT_ENG } from '@const/base';
import { CHAT_HISTORY_PAGE_TEXT, CHAT_HISTORY_PAGE_TEXT_ENG } from '@const/chatHistory';
import { FEEDBACK_TOAST_MESSAGES, FEEDBACK_TOAST_MESSAGES_ENG } from '@const/feedback';
import { LABEL_TEXT, LABEL_TEXT_ENG, TOOLTIPS_TEXT, TOOLTIPS_TEXT_ENG } from '@data/dict';
import { AUTO_RESPONSE_PAGE_TEXT, AUTO_RESPONSE_PAGE_TEXT_ENG } from '@const/autoResponse';
import { INVITE_FRIEND_PAGE_TEXT, INVITE_FRIEND_PAGE_TEXT_ENG } from '@const/inviteFriend';
import { TEMPLATE_LIST_PAGE_TEXT, TEMPLATE_LIST_PAGE_TEXT_ENG } from '@const/templtateList';
import { YCLIENTS_START_PAGE_TEXT, YCLIENTS_START_PAGE_TEXT_ENG } from '@const/yclientsStartPage';
import {
  AUTO_CONNECTION_PAGE_TEXT,
  AUTO_CONNECT_TOAST_MESSAGES,
  AUTO_CONNECTION_PAGE_TEXT_ENG,
  AUTO_CONNECT_TOAST_MESSAGES_ENG,
} from '@const/autoConnect';
import {
  REVIEW_PAGE_TEXT,
  REVIEW_PAGE_TEXT_ENG,
  REVIEW_TOAST_MESSAGE,
  REVIEW_TOAST_MESSAGE_ENG,
} from '@const/reviews';
import {
  PAY_PAGE_TEXT,
  PAY_PAGE_TEXT_ENG,
  PAY_PAGE_SUCCESS_MESSAGES,
  PAY_PAGE_SUCCESS_MESSAGES_ENG,
} from '@const/pay';
import {
  MAILING_PAGE_TEXT,
  MAILING_PAGE_TEXT_ENG,
  MAILING_TOAST_MESSAGES,
  MAILING_TOAST_MESSAGES_ENG,
} from '@const/mailing';
import {
  CURRENCY,
  CURRENCY_ENG,
  FIRST_TOOLTIP,
  FIRST_TOOLTIP_ENG,
  ERROR_MESSAGES_TEXT,
  COMMON_TOAST_MESSAGES,
  ERROR_MESSAGES_TEXT_ENG,
  TEMPLATE_WARNING_MESSAGE,
  COMMON_TOAST_MESSAGES_ENG,
  TEMPLATE_WARNING_MESSAGE_ENG,
} from '@const/common';

i18n // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  .use(initReactI18next)
  // pass the i18n instance to react-i18next.
  .init({
    lng: 'rus',
    debug: false,
    fallbackLng: 'rus',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      eng: {
        translation: {
          CURRENCY: CURRENCY_ENG,
          AUTH_PAGE_TEXT: AUTH_PAGE_TEXT_ENG,
          PAY_PAGE_TEXT: PAY_PAGE_TEXT_ENG,
          LABEL_TEXT: LABEL_TEXT_ENG,
          TOOLTIPS_TEXT: TOOLTIPS_TEXT_ENG,
          FIRST_TOOLTIP: FIRST_TOOLTIP_ENG,
          ACCESS_PAGE_TEXT: ACCESS_PAGE_TEXT_ENG,
          ACCOUNTS_PAGE_TEXT: ACCOUNTS_PAGE_TEXT_ENG,
          BASE_TEMPLATE_PAGE_TEXT: BASE_TEMPLATE_PAGE_TEXT_ENG,
          CONNECTION_PAGE_TEXT: CONNECTION_PAGE_TEXT_ENG,
          TEMPLATE_PAGE_TEXT: TEMPLATE_PAGE_TEXT_ENG,
          ERROR_MESSAGES_TEXT: ERROR_MESSAGES_TEXT_ENG,
          TEMPLATE_LIST_PAGE_TEXT: TEMPLATE_LIST_PAGE_TEXT_ENG,
          REVIEW_PAGE_TEXT: REVIEW_PAGE_TEXT_ENG,
          MAILING_PAGE_TEXT: MAILING_PAGE_TEXT_ENG,
          AUTO_RESPONSE_PAGE_TEXT: AUTO_RESPONSE_PAGE_TEXT_ENG,
          BLACKLIST_PAGE_TEXT: BLACKLIST_PAGE_TEXT_ENG,
          SETTINGS_PAGE_TEXT: SETTINGS_PAGE_TEXT_ENG,
          PARTNER_PAGE_TEXT: PARTNER_PAGE_TEXT_ENG,
          CHAT_HISTORY_PAGE_TEXT: CHAT_HISTORY_PAGE_TEXT_ENG,
          COMMON_TOAST_MESSAGES: COMMON_TOAST_MESSAGES_ENG,
          ADD_LID_MESSAGES: ADD_LID_MESSAGES_ENG,
          AUTO_CONNECT_TOAST_MESSAGES: AUTO_CONNECT_TOAST_MESSAGES_ENG,
          FEEDBACK_TOAST_MESSAGES: FEEDBACK_TOAST_MESSAGES_ENG,
          MAILING_TOAST_MESSAGES: MAILING_TOAST_MESSAGES_ENG,
          PAY_PAGE_SUCCESS_MESSAGES: PAY_PAGE_SUCCESS_MESSAGES_ENG,
          REVIEW_TOAST_MESSAGE: REVIEW_TOAST_MESSAGE_ENG,
          LOADER_PAGE_TEXT: LOADER_PAGE_TEXT_ENG,
          AUTO_CONNECTION_PAGE_TEXT: AUTO_CONNECTION_PAGE_TEXT_ENG,
          TEMPLATE_WARNING_MESSAGE: TEMPLATE_WARNING_MESSAGE_ENG,
          INVITE_FRIEND_PAGE_TEXT: INVITE_FRIEND_PAGE_TEXT_ENG,
          YCLIENTS_START_PAGE_TEXT: YCLIENTS_START_PAGE_TEXT_ENG,
        },
      },
      rus: {
        translation: {
          CURRENCY,
          AUTH_PAGE_TEXT,
          PAY_PAGE_TEXT,
          LABEL_TEXT,
          TOOLTIPS_TEXT,
          FIRST_TOOLTIP,
          ACCESS_PAGE_TEXT,
          ACCOUNTS_PAGE_TEXT,
          BASE_TEMPLATE_PAGE_TEXT,
          CONNECTION_PAGE_TEXT,
          TEMPLATE_PAGE_TEXT,
          ERROR_MESSAGES_TEXT,
          TEMPLATE_LIST_PAGE_TEXT,
          REVIEW_PAGE_TEXT,
          MAILING_PAGE_TEXT,
          AUTO_RESPONSE_PAGE_TEXT,
          BLACKLIST_PAGE_TEXT,
          SETTINGS_PAGE_TEXT,
          PARTNER_PAGE_TEXT,
          CHAT_HISTORY_PAGE_TEXT,
          COMMON_TOAST_MESSAGES,
          ADD_LID_MESSAGES,
          AUTO_CONNECT_TOAST_MESSAGES,
          FEEDBACK_TOAST_MESSAGES,
          MAILING_TOAST_MESSAGES,
          PAY_PAGE_SUCCESS_MESSAGES,
          REVIEW_TOAST_MESSAGE,
          LOADER_PAGE_TEXT,
          AUTO_CONNECTION_PAGE_TEXT,
          TEMPLATE_WARNING_MESSAGE,
          INVITE_FRIEND_PAGE_TEXT,
          YCLIENTS_START_PAGE_TEXT,
        },
      },
    },
  });

export default i18n;
