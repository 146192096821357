import { TChatHistoryResponse } from '@api/types';
import { getMessageSendTime } from '@helpers/index';
import { MESSAGES_DIRECTION } from '@const/chatHistory';
import { TChatHistoryTableData } from '@redux/chatHistory/models';

export const chatHistoryMappers = {
  /**
   * Преобразует данные в формат, подходящий для использования в хранилище.
   *
   * @param {TChatHistoryResponse} data - Объект с массивом сообщений.
   * @return {TChatHistoryTableData[]} - Объект с преобразованными данными для сохранения в store
   */
  mapGetChatHistoryDataToStore(data: TChatHistoryResponse): TChatHistoryTableData[] {
    return data.messages.map(
      ({ phone, body, time, type, direction, caption, sent_api, file_data, name }) => ({
        type,
        name: name || '',
        date: typeof time === 'number' ? getMessageSendTime(+time * 1000) : time,
        body,
        phoneNumber: phone,
        direction: sent_api ? MESSAGES_DIRECTION.BOT : direction,
        fileData: {
          size: file_data?.size || '',
          fileName: file_data?.file_name || '',
          contentType: file_data?.content_type || '',
        },
        caption: caption || '',
      }),
    );
  },
};
