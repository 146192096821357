import React from 'react';

interface IH2Props {
  children: React.ReactNode;
  className?: string;
}

export const H2: React.FC<IH2Props> = ({ children, className = '' }) => (
  <h2 className={`font-inter text-h2_header font-medium ${className}`}>{children}</h2>
);
