import React, { memo, useMemo } from 'react';
//
import { Menu } from '@uikit/Menu';
import { Dropdown } from '@uikit/Dropdown';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { TStatsPeriodItem } from '@shared/types';
import { TTemplateDetailFilterGroupBy } from '@redux/templateStats/models';

type TTemplatePeriodSelectProps = {
  /**
   * Массив элементов для отображения вариатов периодов
   * @param {TStatsPeriodItem[]}
   */
  periods: TStatsPeriodItem[];
  /**
   * Текущий активный период
   * @param {TTemplateDetailFilterGroupBy}
   */
  activePeriod: TTemplateDetailFilterGroupBy;
  /**
   * Функция обработчик выбора элемента из выпадающего списка
   * @param {(key: TTemplateDetailFilterGroupBy) => void}
   */
  onItemClickHandler: (key: TTemplateDetailFilterGroupBy) => void;
  /**
   * Опциональный параметр строки классов
   * @param {string}
   * @default ''
   */
  className?: string;
};
export const TemplatePeriodSelect = memo(
  ({ periods, activePeriod, className = '', onItemClickHandler }: TTemplatePeriodSelectProps) => {
    const { t } = useTranslation();

    const renderTitle = useMemo(() => {
      return periods.find(({ key }) => key === activePeriod)?.text;
    }, [periods, activePeriod]);

    const renderMenuItems = useMemo(() => {
      return periods.map(({ key, text }) => {
        return (
          <MenuItem key={key} onClick={() => onItemClickHandler(key)} text={<span>{text}</span>} />
        );
      });
    }, [periods, onItemClickHandler]);

    return (
      <div className='flex items-baseline h-full'>
        <span className='mr-2'>{t('TEMPLATE_PAGE_TEXT.statistics.table.details')}</span>
        <Dropdown
          color='default'
          view='outlined'
          position='bottom-left'
          text={<span>{renderTitle}</span>}
          content={
            <Menu view='raised' color='default' className='pr-8'>
              {renderMenuItems}
            </Menu>
          }
          className={`when_send_dropdown !m-0 !w-full ${className}`}
        />
      </div>
    );
  },
);

TemplatePeriodSelect.displayName = 'TemplatePeriodSelect';
