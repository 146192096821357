import { formatDateAndTime, numWord } from '@helpers/index';
import {
  TTemplateDetailStatsData,
  TTemplateDetailStatsResponse,
  TTemplatesStatisticsResponseData,
  TTemplateStatisticDataResponse,
  TTemplateStatisticDataResponseByTime,
} from '@api/types';

import {
  TTemplateStatisticData,
  TTemplatesStatisticsData,
  TTemplateStatisticTableData,
  TTemplateStatisticDataByTime,
} from './models';

/**
 * Отображает массив объектов TTemplatesStatisticsResponseData в массив объектов TTemplatesStatisticsData.
 *
 * @param {TTemplatesStatisticsResponseData[]} data - Массив объектов TTemplatesStatisticsResponseData, который нужно отобразить.
 * @return {TTemplatesStatisticsData[]} Массив объектов TTemplatesStatisticsData, полученный в результате отображения.
 */

const mapTemplatesToStore = ({
  data,
}: TTemplatesStatisticsResponseData): TTemplatesStatisticsData[] => {
  return data.map(statItem => {
    return {
      ...statItem,
      count: statItem.count_versions,
      versions: statItem.versions.map(
        ({
          answered_count,
          answered_rate,
          earned_count,
          recorded_count,
          recorded_rate,
          send_count,
          id,
          text,
          version,
          time,
          earn_one_msg,
        }) => {
          return {
            allAnsweredCount: answered_count,
            allAnsweredRate: answered_rate,
            allEarnedCount: earned_count,
            allRecordedCount: recorded_count,
            allRecordedRate: recorded_rate,
            allSendCount: send_count,
            id,
            text,
            version,
            time,
            earnOneMsg: earn_one_msg,
          };
        },
      ),
    };
  });
};
/**
 * Преобразует статистические данные шаблона в данные таблицы.
 *
 * @param {ITemplateStatisticData} data - Данные статистики шаблона.
 * @return {TTemplateStatisticTableData[]} Отображенные данные таблицы.
 */
const mapTemplateToTableData = ({
  data,
}: TTemplateStatisticData): TTemplateStatisticTableData[] => {
  return data.map(
    ({
      version,
      date,
      text,
      sendAfterDays,
      sendTime,
      sendCount,
      sendRate,
      answeredCount,
      answeredRate,
      recordedCount,
      recordedRate,
      earnedCount,
      earnOneMsg,
    }) => ({
      version,
      date: formatDateAndTime(date)[0],
      time: formatDateAndTime(date)[1],
      text,
      sendTime: `${sendAfterDays} ${numWord(Number(sendAfterDays), [
        'день',
        'дня',
        'дней',
      ])}, ${sendTime.replace(' ', ':')}`,
      sended: sendCount,
      sendRate,
      answered: answeredCount,
      answeredRate,
      recorded: recordedCount,
      recordedRate,
      earned: earnedCount,
      earnOneMsg,
    }),
  );
};

/**
 * Отображает данные ответа статистики шаблона в формат данных статистики шаблона.
 *
 * @param {TTemplateStatisticDataResponse} data - Ответ статистики шаблона для отображения.
 * @return {ITemplateStatisticData} Отображенные данные статистики шаблона.
 */

const mapTemplateToStore = ({
  count,
  data,
}: TTemplateStatisticDataResponse): TTemplateStatisticData => {
  return {
    count,
    data: data.map(
      ({
        answered_count,
        answered_rate,
        sent,
        earned_count,
        id,
        recorded_count,
        recorded_rate,
        send_after_days,
        send_count,
        send_rate,
        send_time,
        text,
        version,
        earn_one_msg,
      }) => ({
        answeredCount: answered_count,
        answeredRate: answered_rate,
        date: sent,
        earnedCount: earned_count,
        id,
        recordedCount: recorded_count,
        recordedRate: recorded_rate,
        sendAfterDays: send_after_days,
        sendCount: send_count,
        sendRate: send_rate,
        sendTime: send_time,
        text,
        version,
        earnOneMsg: earn_one_msg,
      }),
    ),
  };
};

const mapTemplatesByTimeToStore = ({
  count,
  data,
}: TTemplateStatisticDataResponseByTime): TTemplateStatisticDataByTime => {
  return {
    count,
    data: data.map(
      ({
        id,
        period,
        versions,
        send_rate,
        send_count,
        earn_one_msg,
        earned_count,
        answered_rate,
        recorded_rate,
        recorded_count,
        answered_count,
      }) => {
        return {
          id,
          period,
          sendCount: send_count,
          sendRate: send_rate,
          earnOneMsg: earn_one_msg,
          versions: versions.sort(),
          earnedCount: earned_count,
          answeredRate: answered_rate,
          recordedRate: recorded_rate,
          recordedCount: recorded_count,
          answeredCount: answered_count,
        };
      },
    ),
  };
};

const mapTemplateDetailDataToStore = ({
  data,
}: TTemplateDetailStatsResponse): TTemplateDetailStatsData => {
  return data.map(
    ({
      sum,
      name,
      phone,
      version,
      services,
      updated_at,
      created_at,
      reply_time,
      record_time,
      answer_process,
      record_process,
    }) => ({
      sum,
      name,
      phone,
      version,
      services,
      replyTime: reply_time,
      updatedAt: updated_at,
      createdAt: created_at,
      recordTime: record_time,
      answerProcess: answer_process,
      recordProcess: record_process,
    }),
  );
};

export {
  mapTemplateToStore,
  mapTemplatesToStore,
  mapTemplateToTableData,
  mapTemplatesByTimeToStore,
  mapTemplateDetailDataToStore,
};
