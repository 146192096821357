import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { WhenTimeWithDropDown } from '@components/whenTimeWithDropdown';

interface ISendTimeProps {
  /**
   * Флаг отключения возможности редактирования
   * @param {boolean}
   */
  disabled?: boolean;
  /**
   * Шаг увеличения значения минут
   * @param {number}
   */
  minIncreaseStep: number;
  /**
   * Значение с которого начинается формирование списка минут
   * @param {number}
   */
  startMinutes: number;
  /**
   * Значение которым заканчивается формирование списка минут
   * @param {number}
   */
  endMinutes: number;
  /**
   * Время передается в формате HH:MM
   * @param {string}
   */
  sendingTime: string;
  /**
   * Опциональный параметр включает округление минут до 00
   * @param {boolean}
   */
  isSetOnlyHour?: boolean;
  /**
   * Callback для установки времени
   * @param {(value: string) => void}
   */
  setSendingTime: (value: string) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const SendTime = memo(
  ({
    endMinutes,
    sendingTime,
    startMinutes,
    isSetOnlyHour,
    setSendingTime,
    minIncreaseStep,
    disabled = false,
    className = '',
  }: ISendTimeProps) => {
    const { t } = useTranslation();

    return (
      <div className={`flex items-center ${className}`}>
        <span className='text-black mr-2'>{t('TEMPLATE_PAGE_TEXT.whenSendSendSendTime')}</span>
        <WhenTimeWithDropDown
          disabled={disabled}
          whenTime={sendingTime}
          endMinutes={endMinutes}
          startMinutes={startMinutes}
          isSetOnlyHour={isSetOnlyHour}
          onChangeHandler={setSendingTime}
          minIncreaseStep={minIncreaseStep}
        />
      </div>
    );
  },
);

SendTime.displayName = 'SendTime';
