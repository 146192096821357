import { TAppDrawerData } from '@redux/app/models';

export interface IInitialState {
  appLanguage: 'eng' | 'rus';
  showAppDrawer: boolean;
  appDrawerData: TAppDrawerData;
}

export const initialState: IInitialState = {
  appLanguage: 'rus',
  showAppDrawer: false,
  appDrawerData: {
    title: '',
    description: '',
    isMarkdown: false,
  },
};
