import React from 'react';

type TOverlayProps = {
  /**
   * Функция которая срабатывает при нажатии на оверлай
   * @param {() => void}
   */
  onClickCallback: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const Overlay = ({ onClickCallback, className = '' }: TOverlayProps) => {
  return (
    <div
      onClick={onClickCallback}
      className={`overlay absolute top-0 right-0 bottom-0 left-0 z-[50] ${className}`}
    />
  );
};
