import React, { memo, useEffect, useRef } from 'react';
//
import { weekDays } from '@const/mailing';
import { TAppLanguage } from '@models/index';
import { checkIsToday } from '@helpers/calendar';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { getCalendarElementData } from '@helpers/index';
import { CalendarElement } from '@blocks/calendarElement';
import { TSetStartSendOutDayHandler } from '@shared/types';
import { TCalendarDays, TSendOutsData } from '@redux/mailing/models';

type TCalendarGridProps = {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Выбранный месяц
   * @param {number}
   */
  selectedMonth: number;
  /**
   * Массив дней для формирования календаря
   * @param {TCalendarDays[]}
   */
  calendarDays: TCalendarDays[];
  /**
   * Callback для выбора дня в календаре
   * @param {TSetStartSendOutDayHandler}
   */
  setStartSendOutDay: TSetStartSendOutDayHandler;
  /**
   * Удаляет из календаря новую рассылку
   * @param {() => void}
   */
  deleteNewSendOut: () => void;
  /**
   * Массив рассылок для отображения в календаре
   * @param {TSendOutsData[]}
   */
  sendOuts: TSendOutsData[];
  /**
   * Максимальное количество сообщений в день
   * @param {number}
   */
  daySendOutLimit: number;
  /**
   * Флаг добавления новой рассылки в календарь
   * @param {boolean}
   */
  isNewSendOutAdded: boolean;
  /**
   * Минимально возможное время начала рассылки
   * @param {number}
   */
  minimalRecommendedStartTime: number;
  /**
   * Callback для получения данных по рассылкам в выбранном дне
   * @param {(date: Date) => (isShowWarningData: boolean) => void}
   */
  getSendOutDataHandler: (date: Date) => (isShowWarningData: boolean) => void;
  /**
   * Флаг состояния запроса за детальной информацией по рассылкам за день
   * @param {boolean}
   */
  isGettingSendOutDetailData: boolean;
  // /**
  //  * Данные по новой рассылке
  //  * @param {TSendOutsData}
  //  */
  // newSendOutData: TSendOutsData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  classname?: string;
};

export const CalendarGrid = memo(
  ({
    sendOuts,
    appLanguage,
    calendarDays,
    selectedMonth,
    daySendOutLimit,
    deleteNewSendOut,
    isNewSendOutAdded,
    setStartSendOutDay,
    getSendOutDataHandler,
    isGettingSendOutDetailData,
    minimalRecommendedStartTime,
    classname = '',
  }: TCalendarGridProps) => {
    // Реф на тыг сегодняшнего для для скрола на текущую дату на мобильниках
    const todayTagRef = useRef<HTMLDivElement>(null);

    // Скролим сетку календаря на текущий день
    useEffect(() => {
      const scrollToElement = (ref: React.RefObject<HTMLDivElement>) => {
        const { current } = ref;
        if (current) {
          current.scrollIntoView({ behavior: 'smooth' });
        }
      };

      scrollToElement(todayTagRef);
    }, [todayTagRef]);

    // получаем размер экрана из хука
    const windowsWidth = useWindowWidth();

    // создаем объект Date с минимально рекомендованной датой
    const minimalRecommendedStartDate = new Date(minimalRecommendedStartTime);

    // вычитаем 1 день от созданной даты
    minimalRecommendedStartDate.setDate(minimalRecommendedStartDate.getDate() - 1);

    // создаем объект дата на пред день от минимально рекомендованной
    const minimalRecommendedStartPreviousDay = new Date(
      minimalRecommendedStartDate.getTime(),
    ).getTime();

    // Формируем массив элементов с названием дней недели
    const weekDaysToRender = weekDays[appLanguage].map(day => (
      <span key={day} className='justify-self-end'>
        {day}
      </span>
    ));

    // Массив компонентов для отображения дней в календаре
    const calendarDaysToRender = calendarDays.map(calendarDay => {
      // Деструктурируем данные, получаем день и дату в миллисекундах
      const { dayNumber, timestamp } = calendarDay;

      // флаг сегодняшнего для
      const isToday = checkIsToday(new Date(timestamp));

      // флаг блокировки дня для срабатывания события onClick
      const blockThisDay = timestamp < minimalRecommendedStartPreviousDay;

      // Парсим массив с запущенными рассылками и получем данные для отображения рассылок в календаре
      const { isPast, isGrayText, weekDayText, sendOutElements, sendOutIdStartedInCurrentDay } =
        getCalendarElementData({
          sendOuts,
          calendarDay,
          appLanguage,
          selectedMonth,
        });

      // Получаем объект Date с датой дня в ячейке календаря
      const currentDate = new Date(timestamp);

      return (
        <CalendarElement
          key={timestamp}
          isPast={isPast}
          ref={todayTagRef}
          isToday={isToday}
          weekDay={weekDayText}
          isGrayText={isGrayText}
          appLanguage={appLanguage}
          blockThisDay={blockThisDay}
          daySendOutLimit={daySendOutLimit}
          isMobileView={windowsWidth < 770}
          deleteNewSendOut={deleteNewSendOut}
          isNewSendOutAdded={isNewSendOutAdded}
          sendOutElementsData={sendOutElements}
          day={String(dayNumber).padStart(2, '0')}
          setStartSendOutDay={setStartSendOutDay(currentDate)}
          isGettingSendOutDetailData={isGettingSendOutDetailData}
          sendOutNumbers={Array.from(sendOutIdStartedInCurrentDay)}
          getSendOutDataHandler={getSendOutDataHandler(currentDate)}
        />
      );
    });

    return (
      <div className={`flex flex-col grow overflow-auto dropdown-list-scrollbar ${classname}`}>
        {windowsWidth > 769 ? (
          <div className='grid grid-cols-calendar pb-2'>{weekDaysToRender}</div>
        ) : null}
        <div className='relative flex flex-col grow'>
          <div
            className={`absolute inset-0 ${
              windowsWidth > 769 ? 'grid grid-cols-calendar grid-rows-calendarRow' : ''
            }`}>
            {calendarDaysToRender}
          </div>
        </div>
      </div>
    );
  },
);
