import React, { memo } from 'react';
import { StatisticCard } from '@components/StatisticCard';
import { TemplatesTypeSwitcher } from '@blocks/templatesTypeSwitcher';
import { TTemplatesStatisticsData } from '@redux/templateStats/models';

type TTemplatesStatsListProps = {
  /**
   * Данные статистики
   * @param {TTemplatesStatisticsData[]}
   */
  data: TTemplatesStatisticsData[];
};

export const TemplatesStatsList = memo(({ data }: TTemplatesStatsListProps) => {
  const renderStatisticCardsList = data.map(cardData => (
    <StatisticCard key={cardData.id} cardData={cardData} />
  ));

  return (
    <div className='grid grid-cols-temp gap-[1.5rem] px-4 sm:px-0'>
      <TemplatesTypeSwitcher />
      {renderStatisticCardsList}
    </div>
  );
});

TemplatesStatsList.displayName = 'TemplatesStatsList';
