import { TCreateLeadsData, TGetLeadsResponseData } from '@api/types';

import { TAddLeadData } from './models';

export const addLeadsMappers = {
  mapResponseToStore(data: TGetLeadsResponseData[]): TAddLeadData[] {
    return data.map(item => ({
      promoCode: item.promo_code,
      phone: item.phone,
      branchId: item.branch,
      leadName: item.branch_name,
      comment: item.comment,
      branchName: item.branch_name,
      createdAt: item.created_at,
      // createdBranches: data.created_branches,
    }));
  },
  mapStateToBack(data: TAddLeadData): TCreateLeadsData {
    return {
      promo_code: data.promoCode,
      phone: data.phone,
      branch_id: data.branchId,
      branch_name: data.leadName,
      comment: data.comment,
    };
  },
};
