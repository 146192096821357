import React, { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Dialog } from '@uikit/Dialog';
import { Button } from '@uikit/Button';
import { ReactComponent as Maximize } from '@img/chatHistory/maximize.svg';

type TChatHistoryMediaIconWithModalProps = {
  /**
   * Флаг показа модального окна
   * @param {boolean}
   */
  isOpen: boolean;
  /**
   * Флаг изменяет стили компонента для корректного отображения на мобильных
   * @param {boolean}
   */
  isMobile: boolean;
  /**
   * Текст подписи
   * @param{string}
   */
  caption: string;
  /**
   * Текст описания блока
   * @param {string}
   */
  previewText: string;
  /**
   * Флаг показа подписи, если true, компонент раскрывается на нужную высоту, что бы показать текст подписи
   * @param {boolean}
   */
  isShowCaption: boolean;
  /**
   * Src для отображения картинки
   * @param {string}
   */
  iconExampleSrc: string;
  /**
   * Callback для показа модального окна с переданным в children компонентом
   * @param {() => void}
   */
  showModalToggle: () => void;
  /**
   * Компонент иконки отображающийся перед основным блоком
   * @param {FunctionComponent}
   */
  IconComponent: FunctionComponent;
  /**
   * Компонент для отображения в модальном окне
   * @param {React.ReactNode}
   */
  children: React.ReactNode;
};

export const ChatHistoryMediaIconWithModal = memo(
  ({
    isOpen,
    caption,
    isMobile,
    previewText,
    IconComponent,
    isShowCaption,
    iconExampleSrc,
    showModalToggle,
    children,
  }: TChatHistoryMediaIconWithModalProps) => {
    const { t } = useTranslation();

    return (
      <div className='flex w-full'>
        {!isMobile ? <IconComponent /> : null}
        <div
          className={`bg-gray1 border border-quartz rounded-lg flex flex-col ${
            isShowCaption ? 'justify-center h-fit' : 'justify-start h-[3rem]'
          } px-4 py-[0.45rem] ml-2 overflow-hidden max-w-[15rem] w-full`}>
          <div className='flex justify-between'>
            <div className='flex items-center'>
              <img src={iconExampleSrc} alt='icon' />
              <p className='font-medium text-blackText leading-6 tracking-[0.022em] mx-2 whitespace-pre overflow-hidden'>
                {previewText}
              </p>
            </div>
            <button onClick={showModalToggle} className='bg-none border-none cursor-pointer'>
              <Maximize />
            </button>
          </div>
          {caption ? (
            <p className='text-grayText text-small_text leading-6 tracking-[0.022em] mt-1'>
              {caption}
            </p>
          ) : null}
        </div>
        <Dialog
          type='default'
          view='raised'
          color='success'
          icon={null}
          title={null}
          text={<div>{children}</div>}
          backdropOpacity={40}
          rightButton={
            <Button
              type='default'
              view='smooth'
              color='success'
              dense={false}
              onClick={showModalToggle}
              text={t('CHAT_HISTORY_PAGE_TEXT.chatHistoryMediaFilePreview.closeButton')}
            />
          }
          isOpen={isOpen}
          onClose={showModalToggle}
        />
      </div>
    );
  },
);

ChatHistoryMediaIconWithModal.displayName = 'ChatHistoryMediaIconWithModal';
