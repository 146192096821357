import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { TOnChangeHandler } from '@shared/types';
import { DeleteButton } from '@blocks/deleteButton';
import { ToggleSwitch } from '@blocks/toggleSwitch';

interface IReviewsStepsWrapperProps {
  /**
   * Id шага
   * @param {number}
   */
  stepId: number;
  /**
   * Id цепочки
   * @param {number}
   */
  chainId: number;
  /**
   * Флаг активности шага
   * @param {boolean}
   */
  status: boolean;
  /**
   * Показывать или скрывать кнопку удаления шага
   * @param {boolean}
   */
  isDeleteButton: boolean;
  /**
   * Параметр name для checkbox
   * @param {string}
   */
  toggleSwitchName: string;
  /**
   * Содержимое шага
   * @param {React.ReactNode}
   */
  children: React.ReactNode;
  /**
   * Callback для обработки нажатия на переключатель
   * @param {TOnclickHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Callback для удаления шага
   * @param {() => void}
   */
  deleteStep?: () => void;
  /**
   * Флаг последнего шага, используется для сокрытия переключателя активности
   * @param {boolean}
   */
  isLastStep?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const ReviewsStepsWrapper = memo(
  ({
    stepId,
    status,
    chainId,
    children,
    isLastStep,
    isDeleteButton,
    onChangeHandler,
    toggleSwitchName,
    deleteStep = () => () => '',
    className = '',
  }: IReviewsStepsWrapperProps) => {
    const { t } = useTranslation();

    return (
      <div
        className={`border border-dGray rounded-lg pt-4 pr-4 pb-6 pl-4 flex flex-col w-full ${className}`}>
        <div
          data-stepid={stepId}
          data-chainid={chainId}
          className={`flex items-center justify-between ${stepId !== 1 ? 'mb-10' : ''}`}>
          <div className='flex items-center'>
            {!isLastStep ? (
              <ToggleSwitch
                checked={status}
                name={toggleSwitchName}
                onChangeHandler={onChangeHandler}
                className='mr-4'
              />
            ) : null}
            <p className='font-inter text-[1.5rem] font-bold text-blackHeader m-0 text-[1.375rem]'>
              {t('REVIEW_PAGE_TEXT.step', { stepId: stepId + 1 })}{' '}
              {isLastStep ? t('REVIEW_PAGE_TEXT.stepPostfix') : ''}
            </p>
          </div>
          {isDeleteButton ? <DeleteButton callback={deleteStep} className='ml-1' /> : null}
        </div>
        {stepId === 1 ? (
          <p className='mt-4 mb-10 text-gray2 tracking-[0.0275em]'>
            {t('REVIEW_PAGE_TEXT.requiredFields')}
            <span className='text-[red] ml-[0.2em]'>*</span>
          </p>
        ) : null}
        <div className='flex flex-col'>{children}</div>
      </div>
    );
  },
);

ReviewsStepsWrapper.displayName = 'ReviewsStepsWrapper';
