import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Menu } from '@uikit/Menu';
import { Icon } from '@blueprintjs/core';
import { Dropdown } from '@uikit/Dropdown';
import { SORTING_TYPES } from '@const/common';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { TSimpleStringObj } from '@models/index';

type TInviteFriendsSortTypeDropdownProps = {
  /**
   * Имя поля покторому осуществляется сортировка
   */
  sortBy: string;
  /**
   * Устанавливает поле для сортировки
   * @param {(fieldName: string) => void}
   */
  setSortByHandler: (fieldName: string) => () => void;
  /**
   * Установленное направление сортировки
   * @param {string}
   */
  sortDirection: string;
  /**
   * Callback для изменения направления сортировки
   * @param {() => void}
   */
  setSortDirectionHandler: () => void;
  /**
   * Обьект с данными для формирования выпадающего списка
   * @param {TSimpleStringObj}
   */
  dropdownOptionDict: TSimpleStringObj;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const InviteFriendsSortTypeDropdown = memo(
  ({
    sortBy,
    sortDirection,
    setSortByHandler,
    dropdownOptionDict,
    setSortDirectionHandler,
    className = '',
  }: TInviteFriendsSortTypeDropdownProps) => {
    const { t } = useTranslation();

    // список опций для дропдауна
    const dropdownOptions = Object.entries(dropdownOptionDict).map(([fieldName, text]) => (
      <MenuItem
        key={fieldName}
        text={<span>{text}</span>}
        onClick={setSortByHandler(fieldName)}
        className={sortBy === fieldName ? '!bg-ivory' : ''}
      />
    ));

    // Текст выбранного пункта
    const selectedFieldsName = dropdownOptionDict[sortBy];

    return (
      <div className={`flex flex-col sm:flex-row sm:items-center sm:justify-end ${className}`}>
        <span className='mr-2 mb-2 sm:mb-0'>{t('INVITE_FRIEND_PAGE_TEXT.statistics.sortBy')}</span>
        <div className='flex max-w-[25.5rem] w-full'>
          <div className='mr-2 sm:max-w-[22.75rem] w-full'>
            <Dropdown
              color='default'
              view='outlined'
              position='bottom-left'
              text={<span>{selectedFieldsName}</span>}
              content={
                <Menu className={dropdownOptions.length === 1 ? 'one_item_ul' : ''} view='raised'>
                  {dropdownOptions}
                </Menu>
              }
              className='when_send_dropdown min-w-[13.75rem] w-full'
            />
          </div>
          <div
            onClick={setSortDirectionHandler}
            className='cursor-pointer flex items-center justify-center h-10 w-10'>
            <Icon
              size={20}
              icon={sortDirection === SORTING_TYPES.ASCENDING ? 'sort-asc' : 'sort-desc'}
            />
          </div>
        </div>
      </div>
    );
  },
);

InviteFriendsSortTypeDropdown.displayName = 'InviteFriendsSortTypeDropdown';
