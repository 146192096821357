import { RootState } from '@store/store';
import { TGetLeadsData } from '@api/types';
import { addLidsApi } from '@api/addLidsApi';
import { TRejectResponseData } from '@models/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TAddLeadData } from '@redux/addLeads/models';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { addLeadsMappers } from '@redux/addLeads/addLeadsMappers';

export const getLeadDataThunk = createAsyncThunk<
  TAddLeadData[],
  TGetLeadsData,
  { rejectValue: TRejectResponseData }
>('addLeadSlice/getLeadDataThunk', (data, { dispatch, rejectWithValue }) => {
  return addLidsApi
    .getLeadsData(data)
    .then(response => {
      return addLeadsMappers.mapResponseToStore(response.data);
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getLeadDataThunk(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

export const postLeadDataThunk = createAsyncThunk<
  TAddLeadData[],
  TAddLeadData,
  { state: RootState; rejectValue: TRejectResponseData }
>('addLeadSlice/postLeadDataThunk', (data, { getState, dispatch, rejectWithValue }) => {
  const { consultantData } = getState().accounts;
  const promoCode = consultantData?.promoCode || '';

  return addLidsApi
    .postLeadData(addLeadsMappers.mapStateToBack({ ...data, promoCode }))
    .then(response => {
      return addLeadsMappers.mapResponseToStore(response.data);
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(postLeadDataThunk(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});
