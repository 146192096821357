import React, { memo, useCallback, useMemo, useRef } from 'react';
import { Icon } from '@blueprintjs/core';
//
import { Menu } from '@uikit/Menu';
import { monthsName } from '@const/mailing';
import { TAppLanguage } from '@models/index';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { useCloseByOutSideClick } from '@hooks/useCloseByOutSideClick';
import { TSetSelectedMonthButtonHandler, TSetSelectedMonthHandler } from '@shared/types';

type TMonthListProps = {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Флаг показа выпадающего списка
   * @param {boolean}
   */
  isShowMonthList: boolean;
  /**
   * Callback для показа списка месяцев
   * @param {() => void}
   */
  showListCallback: () => void;
  /**
   * Callback для скрытия списка месяцев
   * @param {() => void}
   */
  hideListCallback: () => void;
  /**
   * Индекс выбранного месяца
   * @param {number}
   */
  selectedMonthIndex: number;
  /**
   * Callback для выбора месяца
   * @param index
   */
  setSelectedMonthCallback: TSetSelectedMonthHandler;
  /**
   * Callback для выбора месяца нажатием на кнопки прокрутки
   * @param {TSetSelectedMonthButtonHandler}
   */
  setSelectedMonthButtonHandler: TSetSelectedMonthButtonHandler;
  /**
   * Массив с названиями месяцев
   * @param {string[]}
   */
  monthList: string[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const MonthList = memo(
  ({
    monthList,
    appLanguage,
    isShowMonthList,
    showListCallback,
    hideListCallback,
    selectedMonthIndex,
    setSelectedMonthCallback,
    setSelectedMonthButtonHandler,
    className = '',
  }: TMonthListProps) => {
    // реф на элемент выпадающего списка
    const dropdownRef = useRef<HTMLDivElement>(null);

    useCloseByOutSideClick({ closeElementCallback: hideListCallback, ref: dropdownRef });

    // обработчик выбора месяца
    const setSelectedMonthHandler = useCallback(
      (index: number) => () => {
        setSelectedMonthCallback({
          index,
          setNextYear: false,
          setPrevYear: false,
          hideListCallback,
        });
      },
      [hideListCallback, setSelectedMonthCallback],
    );

    // тоггл для выпадающего списка
    const monthListToggle = useCallback(
      (isShow: boolean) => () => {
        if (isShow) {
          hideListCallback();
        } else {
          showListCallback();
        }
      },
      [hideListCallback, showListCallback],
    );

    // массив месяцев для рендеренга
    const monthListToRender = useMemo(
      () =>
        monthList.map((month, index) => {
          const correctMonthIndex = monthList.indexOf(monthsName[appLanguage][selectedMonthIndex]);

          return (
            <MenuItem
              key={month}
              text={<span>{month}</span>}
              onClick={setSelectedMonthHandler(index)}
              className={correctMonthIndex === index ? '!bg-ivory' : ''}
            />
          );
        }),
      [appLanguage, monthList, selectedMonthIndex, setSelectedMonthHandler],
    );

    const buttonStyle = 'hidden sm:flex cursor-pointer items-center justify-center h-10 w-10';

    const dropdownStyle =
      'cursor-pointer relative text-green1 flex items-center justify-between tracking-[0.018em] px-2.5 h-[1.75rem] w-[7.6rem]';

    const controlElementColor = '#37833B';

    return (
      <div className={`flex items-center h-10 ${className}`}>
        <button
          onClick={setSelectedMonthButtonHandler({
            direction: 'decrease',
            hideListCallback,
            isShowMonthList,
          })}
          className={buttonStyle}>
          <Icon icon='chevron-left' size={20} color={controlElementColor} />
        </button>
        <div ref={dropdownRef} onClick={monthListToggle(isShowMonthList)} className={dropdownStyle}>
          <span className='mr-2'>{monthsName[appLanguage][selectedMonthIndex]}</span>
          <Icon icon='caret-down' color={controlElementColor} size={16} />
          {isShowMonthList ? (
            <Menu view='raised' className='absolute top-[2rem] z-[52]'>
              {monthListToRender}
            </Menu>
          ) : null}
        </div>
        <button
          onClick={setSelectedMonthButtonHandler({
            direction: 'increase',
            isShowMonthList,
            hideListCallback,
          })}
          className={buttonStyle}>
          <Icon icon='chevron-right' size={20} color={controlElementColor} />
        </button>
      </div>
    );
  },
);

MonthList.displayName = 'MonthList';
