import { getLocaleData } from '@helpers/index';
import { checkDateIsEqual } from '@helpers/calendar';
import {
  TSendOutsData,
  TMailingTableData,
  TParsedXlsxFileData,
  TSendOutsDetailData,
  TConsentParseXLSXFileData,
} from '@redux/mailing/models';
import {
  TMailingProcessData,
  TSendOutResponseData,
  TCalendarResponseData,
  TCalendarSendOutsData,
  TConsentParseXLSXFile,
  TResponseWithOkAndData,
  TGetSendOutDayResponseData,
} from '@api/types';

export const mailingMappers = {
  /**
   * Преобразует ответ API в массив данных для хранения в Store.
   * @param {TResponseWithOkAndData<TSendOutResponseData>} response - Ответ API с данными и статусом.
   * @returns {TMailingTableData[]} - Массив данных для хранения в Store.
   */
  mapResponseToStore({ data }: TResponseWithOkAndData<TSendOutResponseData>): TMailingTableData[] {
    return data.map(({ sendout_id, date, msg, process, status }) => {
      const { dateString, time } = getLocaleData(date);
      return {
        id: sendout_id,
        date: dateString,
        time,
        text: msg,
        process,
        status,
        do: '',
      };
    });
  },
  mapConsentParsedXLSXDataToStore({
    consent_count,
    neutral_count,
    declined_count,
    active_count,
  }: TConsentParseXLSXFile): TConsentParseXLSXFileData {
    return {
      consentCount: consent_count,
      neutralCount: neutral_count,
      declinedCount: declined_count,
      activeCount: active_count,
    };
  },
  mapParsedXLSXFileDataToStore(
    data: TMailingProcessData,
    allowedStart: string,
    immediateStart: boolean,
  ): TParsedXlsxFileData {
    const {
      count,
      duration,
      send_days,
      sendout_id,
      instance_id,
      time_to_msk,
      visits_median,
      sendout_batch_len,
      last_visit_time_median,
      keys,
    } = data;
    return {
      keys,
      count,
      duration,
      allowedStart,
      immediateStart,
      sendDays: send_days,
      sendOutId: sendout_id,
      timeToMsk: time_to_msk,
      instanceId: instance_id,
      visitsMedian: visits_median || 0,
      sendOutBatchLen: sendout_batch_len,
      lastVisitTimeMedian: last_visit_time_median || 0,
    };
  },
  mapCalendarDataToStore(
    notAvailableData: TCalendarResponseData[],
    sendOutList: TCalendarSendOutsData[],
    daySendOutLimit: number,
  ): TSendOutsData[] {
    const sortedSendOutList = sendOutList.sort((a, b) => {
      return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
    });

    const sortedNotAvailableData = notAvailableData.sort((a, b) => {
      return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
    });

    const getRemainder = (date: string) => {
      let quotaBalance = 0;

      sortedNotAvailableData.forEach(day => {
        const { end_date: endDate, quota_balance } = day;
        const isSameDay = checkDateIsEqual(new Date(endDate), new Date(date));

        if (isSameDay) {
          quotaBalance = quota_balance;
        }
      });

      return quotaBalance;
    };

    const sendOuts: TSendOutsData[] = sortedSendOutList.map(item => {
      const { start_date, end_date, sendout_id } = item;
      const startDateTimeStamp = new Date(start_date).getTime();
      const endDateTimeStamp = new Date(end_date).getTime();

      return {
        isNew: false,
        startDate: new Date(start_date),
        endDate: new Date(endDateTimeStamp),
        duration: endDateTimeStamp - startDateTimeStamp || 1,
        isActiveSendOut: true,
        sendOutId: sendout_id.toString(),
        totalCount: 0,
        daySendOutLimit,
        remainder: getRemainder(end_date),
      };
    });

    const todaySendOut: TSendOutsData[] = [];

    sortedNotAvailableData.forEach(date => {
      if (checkDateIsEqual(new Date(), new Date(date.start_date))) {
        if (date.quota_balance < daySendOutLimit) {
          const startDate = new Date(date.start_date);
          const newStartDate = new Date(startDate.setDate(startDate.getDate() - 1));

          const remainder = getRemainder(date.end_date);

          todaySendOut.push({
            isNew: false,
            startDate: newStartDate,
            endDate: new Date(date.end_date),
            duration: 1,
            isActiveSendOut: true,
            sendOutId: '0000',
            totalCount: 0,
            daySendOutLimit,
            remainder: remainder === 0 ? remainder : daySendOutLimit - remainder,
          });
        }
      }
    });

    if (todaySendOut.length) {
      sendOuts.unshift(...todaySendOut);
    }

    return sendOuts;
  },
  mapDetailSendOudDayData(data: TGetSendOutDayResponseData[]): TSendOutsDetailData[] {
    return data.map(
      ({ msg, last_visit_time_median, visits_median, sendout_id, time_start, batch_len }) => ({
        sendOutId: String(sendout_id),
        startDate: new Date(time_start),
        totalCount: batch_len,
        visitsMedian: visits_median,
        lastVisitTimeMedian: last_visit_time_median,
        sendOutText: msg,
      }),
    );
  },
};
