import { getLocaleData } from '@helpers/index';
import { TStatisticsResponse } from '@redux/statistics/models';
import { TGetResponseWithData, TStatisticsTableData } from '@api/types';

/**
 * Функция для преобразования ответа сервера в формат, подходящий для хранилища Redux.
 *
 * @param {TGetResponseWithData<TStatisticsTableData>} response - Ответ сервера с данными.
 * @param {number} response.count - Общее количество записей.
 * @param {TStatisticsTableData[]} response.data - Массив данных таблицы статистики.
 * @returns {TStatisticsResponse} - Объект с преобразованными данными статистики для хранилища Redux.
 */
const mapResponseToStore = ({
  count,
  data,
}: TGetResponseWithData<TStatisticsTableData>): TStatisticsResponse => {
  return {
    totalCount: count,
    data: data.map(
      ({
        text,
        date,
        summ,
        visits,
        answers,
        sign_up,
        recipients,
        sendout_id,
        summ_one_msg,
        last_visit_days,
        conversion_answers,
        conversion_sign_up,
        date_finish_sendout,
        sendout_stop_flag,
      }) => {
        const { dateString, time } = getLocaleData(date);
        const { dateString: dateFinishString, time: finishTime } =
          getLocaleData(date_finish_sendout);
        return {
          text,
          time,
          visits,
          answers,
          sum: summ,
          recipients,
          id: sendout_id,
          signUp: sign_up,
          date: dateString,
          sumOneMsg: summ_one_msg,
          lastVisitDays: last_visit_days,
          conversionSignUp: conversion_sign_up,
          conversionAnswers: conversion_answers,
          dateFinishSendout: dateFinishString,
          timeFinishSendout: finishTime,
          sendoutStopFlag: !!sendout_stop_flag,
        };
      },
    ),
  };
};

export const statisticsMappers = { mapResponseToStore };
