export const REG_EXP = {
  onlyNumbers: /\D/g,
  // eslint-disable-next-line
  onlyCutEmoji: /([а-яА-яёЁ]|[\d]|[\w]|[\s]|[!\]\[@#$%^&*()_+=`~<>,./?"№;:|])/gm,
  openKey: /{/gm,
  closeKey: /}/gm,
  openKeyWithSpace: /\s{/gm,
  closeKeyWithSpace: /}\s/gm,
};

/**
 * Сообщения для toast
 * @const COMMON_TOAST_MESSAGES
 */
export const COMMON_TOAST_MESSAGES = {
  SAVE_SUCCESS: 'Сохранено',
  COMMON_ERROR_MESSAGE: 'Ошибка\n перезагрузите страницу \n и попробуйте еще раз',
  FILE_TO_BIG: 'Размер файла \n превышает 20 мегабайт, \n выберите файл меньшего размера',
  UNSUPPORTED_FILE_TYPE: 'Тип файла \n не поддерживается, \n выберите другой файл',
  COMMON_SAVE_ERROR_MESSAGE: 'Ошибка сохранения, \n перезагрузите страницу \n и попробуйте еще раз',
  UNSUPPORTED_FILE: 'Тип файла \n не поддерживается, \n выберите другой файл',
  UNIQUE_TG_ID_ERROR: 'Ошибка сохранения, \n пользователь с таким telegram id \n уже существует',
  EMPTY_TG_INPUT_ERROR: 'Поле telegram id не может быть пустым',
  MAILING_ACCOUNT_NOT_PAYED: 'Отправка рассылок\n доступна только на платном тарифе',
  VISITS_QUANTITY_ERROR:
    "Нельзя устанавливать этот параметр выше,\n чем параметр 'До' в блоке 'Клиентам с количеством визитов'",
  CONNECTION_GET_UNIQUE_CODE_TIMEOUT_ERROR: 'Ошибка, попробуйте запросить код позднее',
};

/**
 * Сообщения для toast
 * @const COMMON_TOAST_MESSAGES_ENG
 */
export const COMMON_TOAST_MESSAGES_ENG = {
  SAVE_SUCCESS: 'Saved',
  COMMON_ERROR_MESSAGE: 'Error\n please reload the page\n and try again',
  FILE_TO_BIG: 'The file size \n is greater than 20 megabytes, \n please select a smaller file',
  UNSUPPORTED_FILE_TYPE: 'The file type \n is not supported, \n please select another file',
  COMMON_SAVE_ERROR_MESSAGE: 'Saving error, \n please reload the page \n and try again',
  UNSUPPORTED_FILE: 'File type \n is not supported, \n please select another file',
  UNIQUE_TG_ID_ERROR: 'Saving error, \n a user with the same telegram id \n already exists',
  EMPTY_TG_INPUT_ERROR: 'The telegram id field cannot be empty',
  MAILING_ACCOUNT_NOT_PAYED: 'Sending mailings\n is only available on a paid plan',
  VISITS_QUANTITY_ERROR:
    "You cannot set this parameter higher\nthan the 'To' parameter in the 'Clients with number of visits' block",
  CONNECTION_GET_UNIQUE_CODE_TIMEOUT_ERROR: 'Error, please try requesting code later',
};

export const COMMON_TOAST = {
  SAVE_SUCCESS: 'COMMON_TOAST_MESSAGES.SAVE_SUCCESS',
  COMMON_ERROR_MESSAGE: 'COMMON_TOAST_MESSAGES.COMMON_ERROR_MESSAGE',
  FILE_TO_BIG: 'COMMON_TOAST_MESSAGES.FILE_TO_BIG',
  UNSUPPORTED_FILE_TYPE: 'COMMON_TOAST_MESSAGES.UNSUPPORTED_FILE_TYPE',
  COMMON_SAVE_ERROR_MESSAGE: 'COMMON_TOAST_MESSAGES.COMMON_SAVE_ERROR_MESSAGE',
  UNSUPPORTED_FILE: 'COMMON_TOAST_MESSAGES.UNSUPPORTED_FILE',
  UNIQUE_TG_ID_ERROR: 'COMMON_TOAST_MESSAGES.UNIQUE_TG_ID_ERROR',
  EMPTY_TG_INPUT_ERROR: 'COMMON_TOAST_MESSAGES.EMPTY_TG_INPUT_ERROR',
  MAILING_ACCOUNT_NOT_PAYED: 'COMMON_TOAST_MESSAGES.MAILING_ACCOUNT_NOT_PAYED',
  CONNECTION_GET_UNIQUE_CODE_TIMEOUT_ERROR:
    'COMMON_TOAST_MESSAGES.CONNECTION_GET_UNIQUE_CODE_TIMEOUT_ERROR',
};

export const TEMPLATE_WARNING_MESSAGE = {
  DISABLE_CLIENT_DID_NOT_COME_CHECKBOX: 'Нельзя использовать со статусом не пришел',
};

export const TEMPLATE_WARNING_MESSAGE_ENG = {
  DISABLE_CLIENT_DID_NOT_COME_CHECKBOX: 'Cannot be used with not arrived status',
};

export const TEMPLATE_WARNING = {
  DISABLE_CLIENT_DID_NOT_COME_CHECKBOX:
    'TEMPLATE_WARNING_MESSAGE.DISABLE_CLIENT_DID_NOT_COME_CHECKBOX',
};

export const CURRENCY = '₽';
export const CURRENCY_ENG = '$';

export enum PROMISE_STATUS {
  REJECTED = 'rejected',
}

export enum SORTING_TYPES {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export const ONE_DAY_IN_MS = 86395000;

export const TWO_DAY_IN_MS = ONE_DAY_IN_MS * 2;

export const DAYS_IN_WEEK = 7;

export const ONE_WEEK_IN_MS = 518400000;

export const TEXTAREA_TEXT_MAX_LENGTH = 2000;

export enum BALLOONS_ACTIONS {
  ADD = 'add',
}

export const DAYS = {
  eng: ['day', 'days', 'days'],
  rus: ['день', 'дня', 'дней'],
};

export const FIRST_TOOLTIP = {
  text: 'Нажмите на значок «i», чтобы прочитать подсказку',
};

export const FIRST_TOOLTIP_ENG = {
  text: 'Click on the "i" icon to read the hint',
};

export const HOUR = {
  eng: ['hour', 'hours', 'hours'],
  rus: ['час', 'часа', 'часов'],
};

export const ERROR_MESSAGES_TEXT = {
  nothingFound: 'Ничего не найдено',
};

export const ERROR_MESSAGES_TEXT_ENG = {
  nothingFound: 'Nothing found',
};

export type TInstructionData = {
  headerText: string;
  videoLink: string;
  startTime: string;
};

// const VIDEO_INSTRUCTION_LINKS_YOU_TUBE = {
//   LOGIN: 'https://www.youtube.com/embed/rTmWOxqtNWs?si=hZ280vDFt28MRviN',
//   CONNECTION: 'https://www.youtube.com/embed/PnjYbTxSN_g?si=iQwnp1U-DNvwoL5I',
//   TEMPLATES: 'https://www.youtube.com/embed/ZB_HHlOaXQg?si=h14hm74soCpPZpwM&amp',
//   MAILING: 'https://www.youtube.com/embed/u64lNL_jNaw?si=ZGNVmnishhTXNKD6&amp',
//   AUTO_RESPONSE: 'https://www.youtube.com/embed/fCEhwt0zZ_s?si=6XiVT7mOY4EyEuV3&amp',
//   // Исправить ссылку после записи видео
//   INVITE_FRIENDS: 'https://www.youtube.com/embed/fesh3Jj5Pbc?si=Tc4n-YRMWKKL80M-&amp',
//   BLACK_LIST: 'https://www.youtube.com/embed/fesh3Jj5Pbc?si=Tc4n-YRMWKKL80M-&amp',
//   SETTINGS: 'https://www.youtube.com/embed/5YKrPJNi9CM?si=9c9LfgG4S2ED_lz4&amp',
//   PARTNERS: 'https://www.youtube.com/embed/7Mo8rF1xbJE?si=qt6DHPjbKeGp0tlD&amp',
//   CHAT_HISTORY: 'https://www.youtube.com/embed/zA1HTF9GRLs?si=VQnToJcs7R4bJfjQ&amp',
//   PAY: 'https://www.youtube.com/embed/dVAqszlgIP4?si=MPL9biJA1_l0ixfJ&amp',
// };

const VIDEO_INSTRUCTION_LINKS_VK = {
  LOGIN: 'https://vk.com/video_ext.php?oid=-210950343&id=456239178&hd=3',
  CONNECTION: 'https://vk.com/video_ext.php?oid=-210950343&id=456239179&hd=3',
  TEMPLATES: 'https://vk.com/video_ext.php?oid=-210950343&id=456239180&hd=3',
  AUTO_RESPONSE: 'https://vk.com/video_ext.php?oid=-210950343&id=456239189&hd=3',
  INVITE_FRIENDS: 'https://vk.com/video_ext.php?oid=-210950343&id=456239190&hd=3',
  MAILING: 'https://vk.com/video_ext.php?oid=-210950343&id=456239188&hd=3',
  BLACK_LIST: 'https://vk.com/video_ext.php?oid=-210950343&id=456239182&hd=3',
  SETTINGS: 'https://vk.com/video_ext.php?oid=-210950343&id=456239183&hd=3',
  PARTNERS: 'https://vk.com/video_ext.php?oid=-210950343&id=456239184&hd=3',
  CHAT_HISTORY: 'https://vk.com/video_ext.php?oid=-210950343&id=456239185&hd=3',
  PAY: 'https://vk.com/video_ext.php?oid=-210950343&id=456239186&hd=3',
};

const VIDEO_INSTRUCTION_LINKS_KINESCOPE = {
  LOGIN: 'https://kinescope.io/aGcFekW7ccAQHVEX3h4Z3o',
  CONNECTION: 'https://kinescope.io/kT76hAFPhmgQnhSqr2x5Fi',
  TEMPLATES: 'https://kinescope.io/6yiwqXuBFM5xN72QxPniJG',
  AUTO_RESPONSE: 'https://kinescope.io/ijWnJudCEM8oZFx2169UFU',
  INVITE_FRIENDS: 'https://kinescope.io/4YJH4HUDiaxsNcXkjNZTTu',
  MAILING: 'https://kinescope.io/uh47P2xtENXbfY9KiJCfms',
  BLACK_LIST: 'https://kinescope.io/5H9SBQkpQ4wLjC7Erpiamz',
  SETTINGS: 'https://kinescope.io/hqzyS7NfNDA9jcm1rW7aQG',
  PARTNERS: 'https://kinescope.io/3AHFBSznC9djsg7Zagwfwj',
  CHAT_HISTORY: 'https://kinescope.io/ojbiUR518o8HJd11id5Qyo',
  PAY: 'https://kinescope.io/4AhjjWJftWv5eT8X9hrJ3G',
};

export const VIDEO_INSTRUCTIONS: Record<string, TInstructionData[]> = {
  login: [
    {
      headerText: 'Как войти в личный кабинет',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.LOGIN,
      startTime: '1',
    },
    {
      headerText: 'Два способа войти в личный кабинет',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.LOGIN,
      startTime: '12',
    },
    {
      headerText: 'Номер телефона для входа в личный кабинет',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.LOGIN,
      startTime: '30',
    },
    {
      headerText: 'Вход в личный кабинет по ссылке',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.LOGIN,
      startTime: '110',
    },
    {
      headerText: 'Первый способ войти в личный кабинет',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.LOGIN,
      startTime: '130',
    },
    {
      headerText: 'Инструкция по получению кода подтверждения в телеграм',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.LOGIN,
      startTime: '148',
    },
    {
      headerText: 'Второй способ входа в личный кабинет по телеграм ID',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.LOGIN,
      startTime: '190',
    },
    {
      headerText: 'Получение ID через личный кабинет Бьюти Бота',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.LOGIN,
      startTime: '205',
    },
    {
      headerText: 'Получение телеграм ID через Бьюти Бот в телеграм',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.LOGIN,
      startTime: '225',
    },
    {
      headerText: 'Получение пин кода',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.LOGIN,
      startTime: '275',
    },
  ],
  connection: [
    {
      headerText: 'Подключение Бьюти Бота к рабочему номеру салона красоты',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.CONNECTION,
      startTime: '1',
    },
    {
      headerText: 'Получение QR кода для сканирования с рабочего номера салона',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.CONNECTION,
      startTime: '19',
    },
    {
      headerText: 'Привязка к воцап с рабочим номером с помощью QR кода из личного кабинета',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.CONNECTION,
      startTime: '34',
    },
    {
      headerText: 'Получение QR через телеграм',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.CONNECTION,
      startTime: '127',
    },
    {
      headerText: 'Подключение бота к другому рабочему номеру и его остановка',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.CONNECTION,
      startTime: '203',
    },
  ],
  templates: [
    {
      headerText: 'Типы шаблонов',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.TEMPLATES,
      startTime: '1',
    },
    {
      headerText: 'Тип шаблонов “при событии”',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.TEMPLATES,
      startTime: '15',
    },
    {
      headerText: 'Тип шаблонов “по времени”',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.TEMPLATES,
      startTime: '40',
    },
    {
      headerText: 'Включение и отключение рассылки шаблона',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.TEMPLATES,
      startTime: '72',
    },
    {
      headerText: 'Удаление шаблона',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.TEMPLATES,
      startTime: '84',
    },
    {
      headerText: 'Создание и редактирование шаблона',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.TEMPLATES,
      startTime: '91',
    },
    {
      headerText: 'Отправка рассылки потерявшимся клиентам',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.TEMPLATES,
      startTime: '565',
    },
    {
      headerText: 'Цепочка отзывов',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.TEMPLATES,
      startTime: '633',
    },
    {
      headerText: 'Настройка шаблона для получения отзывов',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.TEMPLATES,
      startTime: '643',
    },
    {
      headerText: 'Градация отзывов и принцип их публикации в гео сервисах',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.TEMPLATES,
      startTime: '735',
    },
    {
      headerText: 'Получение негативных отзывов на телефон',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.TEMPLATES,
      startTime: '760',
    },
    {
      headerText: 'Статистика по шаблонам',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.TEMPLATES,
      startTime: '769',
    },
    {
      headerText: 'Копирование шаблонов и их настроек',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.TEMPLATES,
      startTime: '785',
    },
    {
      headerText: 'Редактирование шаблонов из тг бота',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.TEMPLATES,
      startTime: '814',
    },
  ],
  mailing: [
    {
      headerText: 'Создание новых рассылок',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.MAILING,
      startTime: '1',
    },
    {
      headerText: 'Работа с файлом клиентов',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.MAILING,
      startTime: '43',
    },
    {
      headerText: 'Добавляем описание и картинку к тексту',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.MAILING,
      startTime: '75',
    },
    {
      headerText: 'Установление даты и времени рассылки',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.MAILING,
      startTime: '119',
    },
    {
      headerText: 'Особенности отправки тестового сообщения',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.MAILING,
      startTime: '161',
    },
    {
      headerText: 'В каком случае может не прийти тестовое сообщение',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.MAILING,
      startTime: '183',
    },
    {
      headerText: 'Отслеживание запущенных рассылок',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.MAILING,
      startTime: '210',
    },
    {
      headerText: 'Повторная отправка рассылки',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.MAILING,
      startTime: '225',
    },
    {
      headerText: 'Статистика по завершенным рассылками',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.MAILING,
      startTime: '257',
    },
    {
      headerText: 'Отправка рассылки через телеграм по шагам',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.MAILING,
      startTime: '261',
    },
    {
      headerText: 'Как выгрузить файл для рассылки из Yclients',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.MAILING,
      startTime: '308',
    },
    {
      headerText: 'Как привести файл к корректному виду для рассылки',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.MAILING,
      startTime: '345',
    },
  ],
  autoResponse: [
    {
      headerText: 'Автоматические ответы бота',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.AUTO_RESPONSE,
      startTime: '1',
    },
    {
      headerText: 'Инструкция по настройке автоответов',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.AUTO_RESPONSE,
      startTime: '17',
    },
    {
      headerText: 'Создание нового автоответа',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.AUTO_RESPONSE,
      startTime: '23',
    },
    {
      headerText: 'Отключение автоответа',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.AUTO_RESPONSE,
      startTime: '49',
    },
    {
      headerText: 'Редактирование автоответа',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.AUTO_RESPONSE,
      startTime: '55',
    },
    {
      headerText: 'Как удалить автоответ',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.AUTO_RESPONSE,
      startTime: '59',
    },
    {
      headerText: 'Добавление дополнительных ключевых слов',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.AUTO_RESPONSE,
      startTime: '62',
    },
    {
      headerText: 'Важно: про ключевые слова',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.AUTO_RESPONSE,
      startTime: '74',
    },
  ],
  inviteFriends: [
    {
      headerText: 'Типы акций',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.INVITE_FRIENDS,
      startTime: '1',
    },
  ],
  blacklist: [
    {
      headerText: 'Черный список клиентов салона красоты',
      videoLink: VIDEO_INSTRUCTION_LINKS_VK.BLACK_LIST,
      startTime: '1',
    },
    {
      headerText: 'Добавление номера клиента в черный список через личный кабинет',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.BLACK_LIST,
      startTime: '10',
    },
    {
      headerText: 'Добавление номера клиента в черный список через телеграм',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.BLACK_LIST,
      startTime: '30',
    },
    {
      headerText: 'Вежливое сообщение клиенту о прекращении оповещения',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.BLACK_LIST,
      startTime: '53',
    },
    {
      headerText: 'Инструкция для клиента по отписке от всех рассылок',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.BLACK_LIST,
      startTime: '82',
    },
  ],
  settings: [
    {
      headerText: '«Проверка на ответ»',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.SETTINGS,
      startTime: '1',
    },
    {
      headerText: 'Опция, которая позволяет отвечать в WhatsApp из телеграма',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.SETTINGS,
      startTime: '42',
    },
    {
      headerText: 'Функция «включить трансляцию»',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.SETTINGS,
      startTime: '70',
    },
    {
      headerText: 'Ответ в WhatsApp из телеграм',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.SETTINGS,
      startTime: '110',
    },
    {
      headerText: 'Как получать коды подтверждения в смс',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.SETTINGS,
      startTime: '144',
    },
    {
      headerText: 'Добавить телефон салона в контакты клиенту при первом обращении',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.SETTINGS,
      startTime: '180',
    },
    {
      headerText: 'Выбор сотрудников, по которым не нужно отправлять шаблоны и уведомления',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.SETTINGS,
      startTime: '235',
    },
    {
      headerText: 'Скрытие номеров клиентов',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.SETTINGS,
      startTime: '265',
    },
    {
      headerText: 'Временные лимиты для отправки сообщений',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.SETTINGS,
      startTime: '308',
    },
    {
      headerText: 'Получение счета в личный кабинет или телеграм',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.SETTINGS,
      startTime: '410',
    },
    {
      headerText: 'Выбор смс провайдера',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.SETTINGS,
      startTime: '425',
    },
    {
      headerText: 'Раздел с доступами администраторов для владельца аккаунта',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.SETTINGS,
      startTime: '485',
    },
    {
      headerText: 'Добавление администратора',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.SETTINGS,
      startTime: '505',
    },
    {
      headerText: 'Получение телеграм ID для администратора',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.SETTINGS,
      startTime: '550',
    },
  ],
  partners: [
    {
      headerText: 'Партнерская программа бота для салонов красоты',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.PARTNERS,
      startTime: '1',
    },
    {
      headerText: 'Условия партнерской программы',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.PARTNERS,
      startTime: '20',
    },
    {
      headerText: 'Вкладка “условия и партнеры”',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.PARTNERS,
      startTime: '26',
    },
    {
      headerText: 'Вкладка “история выплат”',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.PARTNERS,
      startTime: '35',
    },
    {
      headerText: 'Условия для подключения нескольких своих филиалов',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.PARTNERS,
      startTime: '43',
    },
  ],
  chatHistory: [
    {
      headerText: 'История переписки в приложении для парикмахерских и салонов красоты Бьюти Бот',
      videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.CHAT_HISTORY,
      startTime: '1',
    },
  ],
  // pay: [
  //   {
  //     headerText: 'Подключение автоплатежа и оплата выбранного тарифа',
  //     videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.PAY,
  //     startTime: '1',
  //   },
  //   {
  //     headerText: 'Оплата картой',
  //     videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.PAY,
  //     startTime: '30',
  //   },
  //   {
  //     headerText: 'Оплата по счёту',
  //     videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.PAY,
  //     startTime: '40',
  //   },
  //   {
  //     headerText: 'Продление работы бота до получения оплаты по счёту',
  //     videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.PAY,
  //     startTime: '75',
  //   },
  //   {
  //     headerText: 'Оплата и продление бота из телеграм',
  //     videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.PAY,
  //     startTime: '90',
  //   },
  //   {
  //     headerText: 'Оплата нескольких филиалов',
  //     videoLink: VIDEO_INSTRUCTION_LINKS_KINESCOPE.PAY,
  //     startTime: '114',
  //   },
  // ],
};

// export const VIDEO_INSTRUCTIONS: Record<string, TInstructionData[]> = {
//   login: [
//     {
//       headerText: 'Как войти в личный кабинет',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.LOGIN,
//       startTime: '1',
//     },
//   ],
//   connection: [
//     {
//       headerText: 'Подключение Бьюти Бота к рабочему номеру салона красоты',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.CONNECTION,
//       startTime: '1',
//     },
//   ],
//   templates: [
//     {
//       headerText: 'Типы шаблонов',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.TEMPLATES,
//       startTime: '1',
//     },
//     {
//       headerText: 'Включение и отключение рассылки шаблона',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.TEMPLATES,
//       startTime: '72',
//     },
//     {
//       headerText: 'Удаление шаблона',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.TEMPLATES,
//       startTime: '84',
//     },
//     {
//       headerText: 'Создание и редактирование шаблона',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.TEMPLATES,
//       startTime: '91',
//     },
//     {
//       headerText: 'Отправка рассылки потерявшимся клиентам',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.TEMPLATES,
//       startTime: '554',
//     },
//     {
//       headerText: 'Цепочка отзывов',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.TEMPLATES,
//       startTime: '663',
//     },
//     {
//       headerText: 'Настройка шаблона для получения отзывов',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.TEMPLATES,
//       startTime: '643',
//     },
//     {
//       headerText: 'Градация отзывов и принцип их публикации в гео сервисах',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.TEMPLATES,
//       startTime: '735',
//     },
//     {
//       headerText: 'Получение негативных отзывов на телефон',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.TEMPLATES,
//       startTime: '760',
//     },
//     {
//       headerText: 'Статистика по шаблонам',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.TEMPLATES,
//       startTime: '769',
//     },
//     {
//       headerText: 'Копирование шаблонов и их настроек',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.TEMPLATES,
//       startTime: '785',
//     },
//     {
//       headerText: 'Редактирование шаблонов из тг бота',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.TEMPLATES,
//       startTime: '814',
//     },
//   ],
//   mailing: [
//     {
//       headerText: 'Создание новых рассылок',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.MAILING,
//       startTime: '1',
//     },
//     {
//       headerText: 'Работа с файлом клиентов',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.MAILING,
//       startTime: '43',
//     },
//     {
//       headerText: 'Добавляем описание и картинку к тексту',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.MAILING,
//       startTime: '75',
//     },
//     {
//       headerText: 'Установление даты и времени рассылки',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.MAILING,
//       startTime: '119',
//     },
//     {
//       headerText: 'Особенности отправки тестового сообщения',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.MAILING,
//       startTime: '161',
//     },
//     {
//       headerText: 'В каком случае может не прийти тестовое сообщение',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.MAILING,
//       startTime: '183',
//     },
//     {
//       headerText: 'Отслеживание запущенных рассылок',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.MAILING,
//       startTime: '210',
//     },
//     {
//       headerText: 'Повторная отправка рассылки',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.MAILING,
//       startTime: '225',
//     },
//     {
//       headerText: 'Статистика по завершенным рассылками',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.MAILING,
//       startTime: '256',
//     },
//     {
//       headerText: 'Отправка рассылки через телеграм по шагам',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.MAILING,
//       startTime: '261',
//     },
//     {
//       headerText: 'Как выгрузить файл для рассылки из Yclients',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.MAILING,
//       startTime: '305',
//     },
//     {
//       headerText: 'Как привести файл к корректному виду для рассылки',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.MAILING,
//       startTime: '345',
//     },
//   ],
//   autoResponse: [
//     {
//       headerText: 'Автоматические ответы бота',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.AUTO_RESPONSE,
//       startTime: '1',
//     },
//     {
//       headerText: 'Инструкция по настройке автоответов',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.AUTO_RESPONSE,
//       startTime: '17',
//     },
//     {
//       headerText: 'Создание нового автоответа',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.AUTO_RESPONSE,
//       startTime: '23',
//     },
//     {
//       headerText: 'Отключение автоответа',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.AUTO_RESPONSE,
//       startTime: '49',
//     },
//     {
//       headerText: 'Редактирование автоответа',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.AUTO_RESPONSE,
//       startTime: '55',
//     },
//     {
//       headerText: 'Как удалить автоответ',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.AUTO_RESPONSE,
//       startTime: '59',
//     },
//     {
//       headerText: 'Добавление дополнительных ключевых слов',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.AUTO_RESPONSE,
//       startTime: '62',
//     },
//     {
//       headerText: 'Важно: про ключевые слова',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.AUTO_RESPONSE,
//       startTime: '74',
//     },
//   ],
//   inviteFriends: [
//     {
//       headerText: 'Типы акций',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.INVITE_FRIENDS,
//       startTime: '1',
//     },
//   ],
//   blacklist: [
//     {
//       headerText: 'Черный список клиентов салона красоты',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.BLACK_LIST,
//       startTime: '1',
//     },
//   ],
//   settings: [
//     {
//       headerText: '«Проверка на ответ»',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.SETTINGS,
//       startTime: '1',
//     },
//     {
//       headerText: 'Опция, которая позволяет отвечать в WhatsApp из телеграма',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.SETTINGS,
//       startTime: '42',
//     },
//     {
//       headerText: 'Функция «включить трансляцию»',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.SETTINGS,
//       startTime: '70',
//     },
//     {
//       headerText: 'Ответ в WhatsApp из телеграм',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.SETTINGS,
//       startTime: '110',
//     },
//     {
//       headerText: 'Как получать коды подтверждения в смс',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.SETTINGS,
//       startTime: '144',
//     },
//     {
//       headerText: 'Выбор сотрудников, по которым не нужно отправлять шаблоны и уведомления',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.SETTINGS,
//       startTime: '235',
//     },
//     {
//       headerText: 'Скрытие номеров клиентов',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.SETTINGS,
//       startTime: '265',
//     },
//     {
//       headerText: 'Временные лимиты для отправки сообщений',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.SETTINGS,
//       startTime: '308',
//     },
//     {
//       headerText: 'Получение счета в личный кабинет или телеграм',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.SETTINGS,
//       startTime: '410',
//     },
//     {
//       headerText: 'Выбор смс провайдера',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.SETTINGS,
//       startTime: '425',
//     },
//     {
//       headerText: 'Раздел с доступами администраторов для владельца аккаунта',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.SETTINGS,
//       startTime: '485',
//     },
//     {
//       headerText: 'Добавление администратора',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.SETTINGS,
//       startTime: '505',
//     },
//     {
//       headerText: 'Получение телеграм ID для администратора',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.SETTINGS,
//       startTime: '550',
//     },
//   ],
//   partners: [
//     {
//       headerText: 'Партнерская программа бота для салонов красоты',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.PARTNERS,
//       startTime: '1',
//     },
//   ],
//   chatHistory: [
//     {
//       headerText: 'История переписки в приложении для парикмахерских и салонов красоты Бьюти Бот',
//       videoLink: VIDEO_INSTRUCTION_LINKS_VK.CHAT_HISTORY,
//       startTime: '1',
//     },
//   ],
//   // pay: [
//   //   {
//   //     headerText: 'Подключение автоплатежа и оплата выбранного тарифа',
//   //     videoLink: VIDEO_INSTRUCTION_LINKS_VK.PAY,
//   //     startTime: '1',
//   //   },
//   //   {
//   //     headerText: 'Оплата картой',
//   //     videoLink: VIDEO_INSTRUCTION_LINKS_VK.PAY,
//   //     startTime: '30',
//   //   },
//   //   {
//   //     headerText: 'Оплата по счёту',
//   //     videoLink: VIDEO_INSTRUCTION_LINKS_VK.PAY,
//   //     startTime: '40',
//   //   },
//   //   {
//   //     headerText: 'Продление работы бота до получения оплаты по счёту',
//   //     videoLink: VIDEO_INSTRUCTION_LINKS_VK.PAY,
//   //     startTime: '75',
//   //   },
//   //   {
//   //     headerText: 'Оплата и продление бота из телеграм',
//   //     videoLink: VIDEO_INSTRUCTION_LINKS_VK.PAY,
//   //     startTime: '90',
//   //   },
//   //   {
//   //     headerText: 'Оплата нескольких филиалов',
//   //     videoLink: VIDEO_INSTRUCTION_LINKS_VK.PAY,
//   //     startTime: '114',
//   //   },
//   // ],
// };

export const ConversionColors = {
  2: '#ff0000',
  2.1: '#ffa500',
  4.6: '#e7e713',
  7.6: '#008000',
};
