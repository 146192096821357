import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { TChannel } from '@api/types';
import { Loader } from '@blocks/loader';
import whatsApp from '@img/connect/whatsApp.svg';
import { getAppData } from '@redux/app/selectors';
import { VIDEO_INSTRUCTIONS } from '@const/common';
import { CONNECTION_STATUSES } from '@models/index';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { ConnectionTable } from '@blocks/connectionTable';
import { getAccountsData } from '@redux/accounts/selectors';
import { ToastsMessages } from '@components/toastsMessages';
import { useAppDispatch, useAppSelector } from '@store/store';
import { clearError } from '@redux/connection/connectionSlice';
import { HeaderWithButton } from '@components/headerWithButton';
import { IconWithTooltips } from '@components/iconWithTooltips';
import { ChannelConnectionStatus } from '@blocks/connectionStatusTgBot';
import { ConnectionMobileWrapper } from '@blocks/connectionMobileWrapper';
import { ConnectionTelegramBot } from '@components/connectionTelegramBot';
import { ConnectionWhatsAppBot } from '@components/connectionWhatsAppBot';
import { PHONE_CONNECTION_STEP, TPhoneConnectionStatus } from '@redux/connection/initialState';
import { clearMessages, getQrData, restartBot, stopBot } from '@redux/connection/connectionThunks';
import {
  TQrCodeData,
  TConnectionTGStatusData,
  TConnectionWAStatusData,
} from '@redux/connection/types';
import { myTrackerApi } from '@api/myTrackerApi';
import { getCommonEventData } from '@helpers/index';
import { MY_TRACKER_EVENTS } from '@helpers/myTracker';

type TConnectionProps = {
  /**
   * Данные QR кода
   * @param {TQrCodeData | undefined}
   */
  qrData: TQrCodeData | undefined;
  /**
   * Флаг процесса получения QR кода
   * @param {boolean}
   */
  isFetchingQR: boolean;
  /**
   * Флаг ошибки получения QR кода
   * @param {boolean}
   */
  isGetQRError: boolean;
  /**
   * Флаг процесса очистки очереди сообщений
   * @param {boolean}
   */
  isFetchingCleanQuery: boolean;
  /**
   * Флаг ошибки очистки очереди сообщений
   * @param {boolean}
   */
  isCleanQueryError: boolean;
  /**
   * Флаг процесса остановки бота
   * @param {boolean}
   */
  isBotStoppingQuery: boolean;
  /**
   * Флаг ошибки остановки бота
   * @param {boolean}
   */
  isBotStoppingError: boolean;
  /**
   * Флаг процесса перезапуска бота
   * @param {boolean}
   */
  isRestartingBot: boolean;
  /**
   * Флаг ошибки перезапуска бота
   * @param {boolean}
   */
  isBotRestartError: boolean;
  /**
   * Сообщение об ошибке
   * @param {string}
   */
  errorMessage: string;
  /**
   * Флаг состояния загрузки
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Статус подключения по телефону
   * @param {TPhoneConnectionStatus}
   */
  phoneConnectionStatus: TPhoneConnectionStatus;
  /**
   * Шаг формы подключения по телефону
   * @param {PHONE_CONNECTION_STEP}
   */
  phoneConnectionStep: PHONE_CONNECTION_STEP;
  /**
   * Номер телефона для отправки кода
   * @param {string}
   */
  phoneNumber: string;
  /**
   * Уникальный код для WA
   * @param {string}
   */
  uniqueCode: string;
  /**
   * Запрашивает данные по подключению каналов
   * @param {() => void}
   */
  getConnectionStatusCallback: () => void;
  /**
   * Данные WA бота
   * @param {TConnectionWAStatusData}
   */
  waData: TConnectionWAStatusData;
  /**
   * Данные TG бота
   * @param {TConnectionWAStatusData}
   */
  tgData: TConnectionTGStatusData;
};

export const ConnectionTemplate = memo(
  ({
    qrData,
    isLoading,
    uniqueCode,
    phoneNumber,
    errorMessage,
    isFetchingQR,
    isGetQRError,
    isRestartingBot,
    isBotRestartError,
    isCleanQueryError,
    isBotStoppingQuery,
    isBotStoppingError,
    phoneConnectionStep,
    isFetchingCleanQuery,
    phoneConnectionStatus,
    getConnectionStatusCallback,
    tgData,
    waData,
  }: TConnectionProps) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const { appLanguage } = useAppSelector(getAppData);

    const width = useWindowWidth();

    const [chanelToView, setChanelToView] = useState<TChannel>('WA');

    // деструктурезируем state
    const { status, messages } = waData;

    // Данные выбранного филиала
    const { selectedFilial } = useAppSelector(getAccountsData);

    // Создаем callback для получения QR кода
    const getQrHandler = useCallback(() => {
      dispatch(getQrData());
    }, [dispatch]);

    // Создаем callback для очистки очереди сообщений
    const clearMessagesHandler = useCallback(() => {
      dispatch(clearMessages());
    }, [dispatch]);

    // Создаем callback для остановки бота
    const stopWhatsAppBot = useCallback(() => {
      dispatch(stopBot());
    }, [dispatch]);

    // Создаем callback для перезапуска бота
    const restartWhatsAppBot = useCallback(() => {
      dispatch(restartBot());
    }, [dispatch]);

    const isShowErrorToast =
      isGetQRError ||
      isCleanQueryError ||
      isBotStoppingError ||
      isBotRestartError ||
      !!errorMessage;

    const tabStyle = 'border border-quartz cursor-pointer pt-3 px-3 pb-[0.375rem]';

    const isWaTbSelected = chanelToView === 'WA';

    const setChanelToViewHandler = useCallback(
      (chanel: TChannel) => () => {
        setChanelToView(chanel);
      },
      [],
    );

    const clearAllError = useCallback(() => {
      dispatch(clearError());
    }, [dispatch]);

    const sendMyTrackerEvent = useCallback(() => {
      myTrackerApi.openVideoInstruction(
        getCommonEventData(MY_TRACKER_EVENTS.CONNECTION_VIDEO_INSTRUCTION_VIEW),
      );
    }, []);

    return (
      <div className='flex flex-col'>
        <HeaderWithButton
          statisticsEventSender={sendMyTrackerEvent}
          instructionData={VIDEO_INSTRUCTIONS.connection}
          headerText={t('CONNECTION_PAGE_TEXT.header')}
          className='px-4 sm:px-0 mt-0 mb-10 lg:mb-8'
        />
        <div className='px-4'>
          {isLoading ? (
            <Loader className='h-[50vh]' />
          ) : (
            <>
              {width > 641 ? (
                <>
                  <div className='mb-6'>
                    <p className='font-inter text-body_text text-grayText'>
                      {t('CONNECTION_PAGE_TEXT.filial', {
                        filialAdders: selectedFilial?.address || '',
                      })}
                    </p>
                  </div>
                  <div className='hidden sm:grid grid-cols-2 gap-[1.5rem] mb-6 w-fit'>
                    <div className='relative flex flex-col'>
                      <div
                        onClick={setChanelToViewHandler('WA')}
                        className={`relative ${
                          isWaTbSelected
                            ? 'bg-gray1 rounded-t-lg border-b-gray1'
                            : 'bg-white rounded-lg'
                        } ${tabStyle}`}>
                        <ChannelConnectionStatus
                          iconSrc={whatsApp}
                          status={waData.status}
                          appLanguage={appLanguage}
                          headerText={t('CONNECTION_PAGE_TEXT.botConnection.botWhatsApp')}
                        />
                      </div>
                      {isWaTbSelected ? (
                        <div className='left-edge-negative-borders absolute bottom-1 border-l border-quartz bg-gray1 h-9 w-[calc(100%+1rem)] translate-y-[100%] z-10' />
                      ) : null}
                    </div>
                    {/* <div className='relative flex flex-col justify-center'>
                      <div
                        onClick={setChanelToViewHandler('TG')}
                        className={`${
                          !isWaTbSelected
                            ? 'bg-gray1 rounded-t-lg border-b-gray1'
                            : 'bg-white rounded-lg'
                        } ${tabStyle}`}>
                        <ChannelConnectionStatus
                          iconSrc={telegram}
                          status={tgData.tgStatus}
                          appLanguage={appLanguage}
                          headerText={t('CONNECTION_PAGE_TEXT.telegramBotConnection.botTelegram')}
                        />
                      </div>
                      {!isWaTbSelected ? (
                        <div className='middle-negative-borders absolute left-0 bottom-1 bg-gray1 h-9 w-[calc(100%+1.4rem)] translate-y-[100%] translate-x-[-0.7rem] z-10' />
                      ) : null}
                    </div> */}
                  </div>
                  <div className='border border-quartz bg-gray1 rounded-lg pt-[1.6875rem] px-4 sm:px-6 py-6 w-full'>
                    <div className=''>
                      {isWaTbSelected ? (
                        <ConnectionWhatsAppBot
                          qrData={qrData}
                          getQr={getQrHandler}
                          uniqueCode={uniqueCode}
                          phoneNumber={phoneNumber}
                          isFetchingQR={isFetchingQR}
                          isGetQRError={isGetQRError}
                          clearQuery={clearMessagesHandler}
                          isRestartingBot={isRestartingBot}
                          stopWhatsAppBot={stopWhatsAppBot}
                          isBotStoppingQuery={isBotStoppingQuery}
                          restartWhatsAppBot={restartWhatsAppBot}
                          phoneConnectionStep={phoneConnectionStep}
                          phoneConnectionStatus={phoneConnectionStatus}
                          getConnectionStatusCallback={getConnectionStatusCallback}
                          data={waData}
                          className=''
                        />
                      ) : (
                        <ConnectionTelegramBot
                          tgData={tgData}
                          selectedFilialAccId={selectedFilial?.accId || ''}
                          className=''
                        />
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <ConnectionMobileWrapper
                    iconSrc={whatsApp}
                    status={waData.status}
                    appLanguage={appLanguage}
                    isBlockSelected={isWaTbSelected}
                    setChanelToViewHandler={setChanelToViewHandler('WA')}
                    headerText={t('CONNECTION_PAGE_TEXT.botConnection.botWhatsApp')}
                    className='mb-6'>
                    <ConnectionWhatsAppBot
                      qrData={qrData}
                      getQr={getQrHandler}
                      uniqueCode={uniqueCode}
                      phoneNumber={phoneNumber}
                      isFetchingQR={isFetchingQR}
                      isGetQRError={isGetQRError}
                      clearQuery={clearMessagesHandler}
                      isRestartingBot={isRestartingBot}
                      stopWhatsAppBot={stopWhatsAppBot}
                      isBotStoppingQuery={isBotStoppingQuery}
                      restartWhatsAppBot={restartWhatsAppBot}
                      phoneConnectionStep={phoneConnectionStep}
                      phoneConnectionStatus={phoneConnectionStatus}
                      getConnectionStatusCallback={getConnectionStatusCallback}
                      data={waData}
                      className=''
                    />
                  </ConnectionMobileWrapper>
                  {/* <ConnectionMobileWrapper
                    iconSrc={telegram}
                    status={tgData.tgStatus}
                    appLanguage={appLanguage}
                    isBlockSelected={!isWaTbSelected}
                    setChanelToViewHandler={setChanelToViewHandler('TG')}
                    headerText={t('CONNECTION_PAGE_TEXT.telegramBotConnection.botTelegram')}>
                    <ConnectionTelegramBot
                      tgData={tgData}
                      selectedFilialAccId={selectedFilial?.accId || ''}
                      className=''
                    />
                  </ConnectionMobileWrapper> */}
                </>
              )}
              {status !== CONNECTION_STATUSES.AUTHENTICATED &&
              status !== CONNECTION_STATUSES.BLOCKED ? (
                <div className='px-4 sm:px-0'>
                  <div className='flex items-center mt-[2.2rem] mb-4'>
                    <h2 className='font-inter font-medium text-[1.25rem] tracking-[0.025rem] mr-[0.3125rem]'>
                      {t('CONNECTION_PAGE_TEXT.table.headerWhatsApp')}
                    </h2>
                    <IconWithTooltips tooltips='messageQuery' />
                  </div>
                  <ConnectionTable
                    messages={messages}
                    isFetchingCleanQuery={isFetchingCleanQuery}
                    cleanMessagesCallBack={clearMessagesHandler}
                  />
                </div>
              ) : null}
            </>
          )}
        </div>
        <ToastsMessages
          successMessage=''
          isShowSuccessToast={false}
          errorMessage={errorMessage}
          clearErrorCallback={clearAllError}
          isShowErrorToast={isShowErrorToast}
        />
      </div>
    );
  },
);

ConnectionTemplate.displayName = 'ConnectionTemplate';
