import React, { memo, useEffect } from 'react';
//
import isEqual from '@helpers/isEqual';
import { NoAccess } from '@components/noAccess';
import { ErrorMessage } from '@blocks/errorMessage';
import { getAccountsData } from '@redux/accounts/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getAutoResponseData } from '@redux/autoResponse/selectors';
import { AutoResponseTemplate } from '@templates/autoResponseTemplate';
import { getAutoResponseDataThunk } from '@redux/autoResponse/autoResponseThunk';

const AutoResponsePage = memo(() => {
  const dispatch = useAppDispatch();
  const {
    isError,
    isSaving,
    isLoading,
    isDeleting,
    activeItemId,
    errorMessage,
    isAccessDenied,
    successMessage,
    data,
    oldData,
  } = useAppSelector(getAutoResponseData);

  const { selectedFilial } = useAppSelector(getAccountsData);

  useEffect(() => {
    if (selectedFilial?.accId) {
      dispatch(getAutoResponseDataThunk());
    }
  }, [selectedFilial?.accId, dispatch]);

  const isAutoResponseDataNotChanged = isEqual(data, oldData);

  const isSaveButtonDisable = isAutoResponseDataNotChanged || isSaving;

  return (
    <>
      {isAccessDenied && !isError && !isLoading ? <NoAccess /> : null}
      {isError && !isLoading && !isAccessDenied ? <ErrorMessage /> : null}
      {!isError && !isAccessDenied && !isLoading ? (
        <AutoResponseTemplate
          data={data}
          isSaving={isSaving}
          isLoading={isLoading}
          isDeleting={isDeleting}
          activeItemId={activeItemId}
          errorMessage={errorMessage}
          successMessage={successMessage}
          isSaveButtonDisable={isSaveButtonDisable}
          className='px-4 sm:px-2 lg:px-0'
        />
      ) : null}
    </>
  );
});

export default AutoResponsePage;
