import { TAccountsResponse } from '@api/types';
import { CONNECTION_STATUSES } from '@models/index';
import { TBannerDataType, TFilialData } from '@redux/accounts/models';

export const consultantFilial: TFilialData = {
  status: CONNECTION_STATUSES.AUTHENTICATED,
  address: '',
  quantityOfTemplates: 0,
  accId: '',
  sendOutAccount: false,
  branchId: '',
  isAdminBotIntegrated: false,
  payPeriod: '',
  payStatus: true,
  actionSendOut: '0',
  rights: ['consultant'],
  testLoyalty: false,
};

export const filialMockData: TFilialData[] = [
  {
    status: CONNECTION_STATUSES.LOADING,
    address: 'Napravo Марьино',
    quantityOfTemplates: 23,
    accId: '85j4hm',
    sendOutAccount: false,
    branchId: '',
    isAdminBotIntegrated: false,
    payPeriod: '02.02.99',
    payStatus: true,
    actionSendOut: '0',
    rights: [],
    testLoyalty: false,
  },
  {
    status: CONNECTION_STATUSES.GOT_QR_CODE,
    address: 'Testos',
    quantityOfTemplates: 9,
    accId: 's8v3ng',
    sendOutAccount: false,
    branchId: '',
    isAdminBotIntegrated: true,
    payPeriod: '01.01.01',
    payStatus: false,
    actionSendOut: '0',
    rights: ['owner'],
    testLoyalty: true,
  },
];

export const getAccountsResponseMockData: TAccountsResponse = {
  accounts: true,
  accounts_data: [
    {
      status: 'no response',
      address: 'Testos3',
      quantity_of_templates: 17,
      acc_id: 'testo3',
      sendout_account: false,
      branch_id: '367678',
      is_admin_bot_integrated: false,
      action_sendout: 0,
      pay_period: '2024-10-07',
      rights: ['owner'],
      pay_status: true,
      test_loyalty: true,
    },
  ],
  acc_count: 1,
  consultant: true,
  consultant_data: {
    name: '',
    phone: '',
    telegram_id: 12345,
    partner_chat_url: '',
    bonus_days: 30,
    regular_pay_rate: 0.15,
    one_time_pay_rate: 0.0,
    one_time_discount: 0.2,
    one_time_payment: 1000,
    payments_detail: [
      {
        branch: '286238',
        branch_name: 'Argento Республики',
        payed: true,
        payments: [],
      },
      {
        branch: '270829',
        branch_name: 'Argento Ломако ',
        payed: true,
        payments: [],
      },
      {
        branch: '149160',
        branch_name: 'Argento Линейная',
        payed: true,
        payments: [],
      },
      {
        branch: '522002',
        branch_name: 'Marafet Строительная',
        payed: true,
        payments: [],
      },
      {
        branch: '329062',
        branch_name: 'Laser Love Южная',
        payed: true,
        payments: [],
      },
      {
        branch: '81441',
        branch_name: 'MATES Луков переулок',
        payed: true,
        payments: [],
      },
      {
        branch: '96535',
        branch_name: 'Shic Новинки',
        payed: true,
        payments: [],
      },
      {
        branch: '668799',
        branch_name: 'Катюша Парковая',
        payed: true,
        payments: [],
      },
      {
        branch: '446454',
        branch_name: 'Sasha Shu Королева',
        payed: true,
        payments: [],
      },
      {
        branch: '229379',
        branch_name: 'Мята Юбилейный',
        payed: true,
        payments: [],
      },
      {
        branch: '679791',
        branch_name: 'Прованс Лесная',
        payed: true,
        payments: [],
      },
      {
        branch: '106779',
        branch_name: 'Между нами бульвар Зелёные Аллеи',
        payed: true,
        payments: [],
      },
      {
        branch: '556385',
        branch_name: 'Filatova Studio Чайковского',
        payed: true,
        payments: [],
      },
      {
        branch: '467143',
        branch_name: 'EK Studio Короленко',
        payed: true,
        payments: [],
      },
      {
        branch: '510183',
        branch_name: 'Nails Up Подмосковный',
        payed: true,
        payments: [],
      },
      {
        branch: '78751',
        branch_name: 'GolovoreZ Красноармейская',
        payed: true,
        payments: [],
      },
      {
        branch: '639449',
        branch_name: 'Golovorez Монтажников',
        payed: true,
        payments: [],
      },
      {
        branch: '120745',
        branch_name: 'Персик Бакунинская',
        payed: true,
        payments: [],
      },
      {
        branch: '179051',
        branch_name: 'Simmetrika Солнечная',
        payed: true,
        payments: [],
      },
      {
        branch: '382962',
        branch_name: 'SIMMETRIKA Челюскинцев',
        payed: true,
        payments: [],
      },
      {
        branch: '70194',
        branch_name: "Sёmin's Nails Железнодорожная",
        payed: true,
        payments: [],
      },
      {
        branch: '464554',
        branch_name: 'Парикмахерская Ореховый',
        payed: true,
        payments: [],
      },
      {
        branch: '641511',
        branch_name: 'Bez_Pafosa_Aktau 2 микр',
        payed: false,
        payments: [],
      },
      {
        branch: '190100',
        branch_name: 'Школа Студия Виктории Крамаренко Валовая',
        payed: false,
        payments: [],
      },
    ],
    promo_code: 'milk',
  },
  banner_data: {
    image_url: 'https://storage.yandexcloud.net/bb.bucket/1001832946_1683249673.jpg',
    info_link: 'https://ru.wikipedia.org/wiki/Статья_(жанр_журналистики)',
  },
};

export const emptyBannerData: TBannerDataType = {
  imageUrl: '',
  infoUrl: '',
};
