import { TSimpleStringObj } from '@models/index';

// export const TEXT_STYLE_SYMBOLS: TSimpleStringObj = {
//   BOLD: '*',
//   ITALIC: '_',
//   UNDERLINED_TEXT: '~',
//   MONOSPACE_TEXT: '`',
// };
//
// export const HTML_TEXT_STYLE_TAG: TSimpleStringObj = {
//   '*': 'b',
//   _: 'i',
//   '~': 's',
//   '`': 'pre',
// };

export enum KEY_WRAPPER_SYMBOLS {
  OPEN_SYMBOL = '{',
  CLOSE_SYMBOL = '}',
}

export const reportPreviewDict: TSimpleStringObj = {
  name: 'Имя клиента',
  phone: 'Телефон клиента',
  full_name: 'Полное имя клиента, как в YCL',
  digital_date: '02.05',
  day_month: '12 декабря',
  day_of_week: 'понедельник',
  start_time: '10:00',
  end_time: '11:00',
  start_end_time: '10:00-11:00',
  master: 'Имя мастера',
  price: '100',
  filial_name: 'Имя филиала',
  client_phone: '79111111111',
  services: '▫️ Услуга 1 \n',
  time_service: '👉 в 12:00: ▫️ Услуга 1',
  time_master: '👉 в 12:00 - Имя мастера',
  review_link: 'Ссылка на отзыв',
  record_link: 'Ссылка на запись',
  tips_link: 'Ссылка на чаевые',
  bonus_add: '150',
  bonus_withdraw: '40',
  bonus_balance: '250',
};
