import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { getPastDate } from '@helpers/index';
import { ButtonGroup } from '@uikit/ButtonGroup';
import { getAppData } from '@redux/app/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { TSetSendingDate, TTemplatesButton } from '@shared/types';
import { TemplatePeriodSelect } from '@blocks/templatePeriodSelect';
import { DateRangeDashboardPicker } from '@components/dateRangePicker';
import { TTemplateDetailFilterGroupBy } from '@redux/templateStats/models';
import { getTemplatesStatisticsPageData } from '@redux/templateStats/selectors';
import {
  PERIODS,
  DEFAULT_TIME_FROM,
  TEMPLATE_DETAIL_TABS,
  TEMPLATE_DETAIL_TIME_TABS,
} from '@const/templates';
import {
  setTemplateDetailFilter,
  setTemplateDetailActiveTab,
  setTemplateDetailActiveTimeTab,
} from '@redux/templateStats/templateStatsSlice';

type TTemplatesTypeSwitcherProps = {
  /**
   * Опциональный параметр отображения блока выбора отображения графика неделя\месяц
   * @param {boolean}
   * @default false
   */
  isShowPeriodSelect?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};
export const TemplatesTypeSwitcher = memo(
  ({ className = '', isShowPeriodSelect = false }: TTemplatesTypeSwitcherProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { appLanguage } = useAppSelector(getAppData);

    const {
      templateDetailActiveTab,
      templateDetailFilter: { dateFrom, dateTo, groupBy },
    } = useAppSelector(getTemplatesStatisticsPageData);

    const isTime = templateDetailActiveTab === TEMPLATE_DETAIL_TABS.TIME;

    const setTimeFilter = useCallback(
      (dateFromValue: string, dateToValue: string) => {
        dispatch(setTemplateDetailFilter({ dateFrom: dateFromValue, dateTo: dateToValue }));
      },
      [dispatch],
    );

    const setPeriodFilter = useCallback(
      (period: TTemplateDetailFilterGroupBy) => {
        dispatch(setTemplateDetailFilter({ groupBy: period }));
      },
      [dispatch],
    );

    const TEMPLATES_BUTTONS: TTemplatesButton[] = useMemo(
      () => [
        {
          id: 0,
          isActive: templateDetailActiveTab === TEMPLATE_DETAIL_TABS.VERSION,
          text: t('TEMPLATE_PAGE_TEXT.statistics.versionButton'),
          label: TEMPLATE_DETAIL_TABS.VERSION,
          onClick: () => {
            setTimeFilter(DEFAULT_TIME_FROM, new Date().toISOString());
            dispatch(setTemplateDetailActiveTab(TEMPLATE_DETAIL_TABS.VERSION));
          },
        },
        {
          id: 1,
          isActive: templateDetailActiveTab === TEMPLATE_DETAIL_TABS.TIME,
          text: t('TEMPLATE_PAGE_TEXT.statistics.timeButton'),
          label: TEMPLATE_DETAIL_TABS.TIME,
          onClick: () => {
            setTimeFilter(getPastDate('months', 1), new Date().toISOString());
            dispatch(setTemplateDetailActiveTab(TEMPLATE_DETAIL_TABS.TIME));
            dispatch(setTemplateDetailActiveTimeTab(TEMPLATE_DETAIL_TIME_TABS.WEEK));
          },
        },
      ],
      [t, dispatch, templateDetailActiveTab, setTimeFilter],
    );

    const setDateRangeHandler: TSetSendingDate = useCallback(
      ({ startDate, endDate }) => {
        setTimeFilter(startDate.toISOString(), endDate.toISOString());
      },
      [setTimeFilter],
    );

    return (
      <div className={`grid lg:flex lg:justify-between ${className}`}>
        <ButtonGroup
          type='default'
          view='outlined'
          color='success'
          data={TEMPLATES_BUTTONS}
          className='grid grid-cols-2 mb-8 lg:mb-0'
        />
        {isTime ? (
          <div
            className={`grid ${isShowPeriodSelect ? 'grid-rows-2' : 'grid-rows-1'} lg:grid-rows-1 ${
              isShowPeriodSelect ? 'lg:grid-cols-2' : 'lg:grid-cols-1'
            } cursor-pointer`}>
            <DateRangeDashboardPicker
              startDate={new Date(dateFrom)}
              endDate={new Date(dateTo)}
              setSendingDate={setDateRangeHandler}
              positionClasses='right-0'
              className='mb-4 w-full lg:max-w-[16rem] xl:mb-0  xl:mr-4'
            />
            {isShowPeriodSelect ? (
              <TemplatePeriodSelect
                activePeriod={groupBy}
                periods={PERIODS[appLanguage]}
                onItemClickHandler={setPeriodFilter}
                className='!pr-28'
              />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  },
);

TemplatesTypeSwitcher.displayName = 'TemplatesTypeSwitcher';
