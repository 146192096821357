import React, { memo } from 'react';
//
import { useTranslation } from 'react-i18next';
import { TAppLanguage, THelpKeys } from '@models/index';
import { getConversionColor, numWord } from '@helpers/index';
import { IconWithTooltips } from '@components/iconWithTooltips';
import { ReactComponent as VisitsIcon } from '@img/inviteFriend/visits.svg';
import { InviteFriendStaticsColors, POINTS_TEXT } from '@const/inviteFriend';
import { ReactComponent as EfficiencyIcon } from '@img/inviteFriend/efficiency.svg';
import { TInviteFriendsStatisticsItemData } from '@redux/inviteFrendsStatistics/types';

type TInviteFriendsStatisticsCardProps = {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Данные в карточку
   * @param {TInviteFriendsStatisticsItemData}
   */
  data: TInviteFriendsStatisticsItemData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const InviteFriendsStatisticsCard = memo(
  ({ appLanguage, data, className }: TInviteFriendsStatisticsCardProps) => {
    const { t } = useTranslation();

    const {
      countChild,
      templateId,
      templateName,
      countChildPaid,
      childHaveRecords,
      childSignupRatio,
      childPaymentRatio,
      amountPaymentsChild,
      medianPaymentsChild,
      medianChildrenRecords,
      dateFirstActivateTemplate,
      amountAccruedBonusesParents,
      amountAccruedBonusesChildren,
    } = data;

    const grayTextStyle = 'font-medium leading-5 tracking-[0.018em] text-grayText text-inner_text';

    const payedData = [
      {
        sum: amountAccruedBonusesParents,
        text: t('INVITE_FRIEND_PAGE_TEXT.statistics.bonusesPaidToNewClients'),
      },
      {
        sum: amountAccruedBonusesChildren,
        text: t('INVITE_FRIEND_PAGE_TEXT.statistics.bonusesPaidToOldClients'),
      },
    ];

    const conversionData = [
      {
        sum: countChild,
        percent: 100,
        text: t('INVITE_FRIEND_PAGE_TEXT.statistics.invitationsSent'),
        conversionColor: '#787885',
        infoIcon: '',
      },
      {
        sum: childHaveRecords,
        percent: childSignupRatio,
        text: t('INVITE_FRIEND_PAGE_TEXT.statistics.acceptedInvitation'),
        conversionColor: getConversionColor(childSignupRatio, InviteFriendStaticsColors),
        infoIcon: 'childSignupRatio',
      },
      {
        sum: countChildPaid,
        percent: childPaymentRatio,
        text: t('INVITE_FRIEND_PAGE_TEXT.statistics.visitedSalon'),
        conversionColor: getConversionColor(childPaymentRatio, InviteFriendStaticsColors),
        infoIcon: 'childPaymentRatio',
      },
    ];

    const visitsOfActionData = [
      {
        sum: countChildPaid,
        text: t('INVITE_FRIEND_PAGE_TEXT.statistics.numberOfCustomer'),
      },
      {
        sum: medianChildrenRecords,
        text: t('INVITE_FRIEND_PAGE_TEXT.statistics.averageVisits'),
      },
      {
        sum: medianPaymentsChild,
        text: t('INVITE_FRIEND_PAGE_TEXT.statistics.averageVisitBill'),
      },
    ];

    const rub = new Intl.NumberFormat('ru-RU', {
      style: 'currency',
      currency: 'RUB',
      useGrouping: true,
      maximumFractionDigits: 0,
    });

    const payedBonusesToRender = payedData.map(({ sum, text }) => {
      return (
        <div
          key={`${sum}${text}`}
          className='font-medium text-blackText leading-6 tracking-[0.004em] mb-2 sm:mb-4 w-full'>
          <p className='mb-0.5'>
            {rub.format(+sum).slice(0, -2)}
            <span className='ml-2'>{numWord(+sum, POINTS_TEXT[appLanguage])}</span>
          </p>
          <p className={grayTextStyle}>{text}</p>
        </div>
      );
    });

    const efficiencyOfActionToRender = conversionData.map(
      ({ sum, text, percent, conversionColor, infoIcon }, index) => {
        const isLstIndex = index === conversionData.length - 1;
        return (
          <div key={`${sum}${text}`} className={`flex flex-col ${isLstIndex ? '' : 'mb-5'}`}>
            <div className='flex items-center text-blackText text-[1.25rem] mb-0.5'>
              <span className='font-medium tracking-[0.004em]'>
                {rub.format(+sum).slice(0, -2)}
              </span>
              <span className='text-stormGray mx-2'>{`\u00b7`}</span>
              <span style={{ color: conversionColor }} className='mr-2'>
                {percent}%
              </span>
              {infoIcon ? (
                <div className='flex items-center'>
                  <IconWithTooltips tooltips={infoIcon as THelpKeys} className='mb-2.5' />
                </div>
              ) : null}
            </div>
            <span className='font-medium text-stormGray text-tooltips'>{text}</span>
          </div>
        );
      },
    );

    const visitsOfActionToRender = visitsOfActionData.map(({ sum, text }, index) => {
      const isLstIndex = index === conversionData.length - 1;
      return (
        <div key={`${sum}${text}`} className={`flex flex-col ${isLstIndex ? '' : 'mb-5'}`}>
          <div className='flex text-blackText text-[1.25rem] mb-0.5'>
            {isLstIndex ? (
              <>
                <span className='font-medium tracking-[0.004em]'>
                  {rub.format(sum).slice(0, -2)}
                </span>
                <span className='font-medium tracking-[0.004em]'>{t('CURRENCY')}</span>
              </>
            ) : (
              <span className='font-medium tracking-[0.004em]'>{sum}</span>
            )}
          </div>
          <span className='font-medium text-stormGray text-tooltips'>{text}</span>
        </div>
      );
    });

    const wrapperClass =
      'border border-quartz1 rounded-lg pt-4 px-4 sm:px-6 lg:px-[2.75rem] pb-6 sm:pb-8 lg:pb-6';
    const grayText = 'text-grayText tracking-[0.0275em]';

    return (
      <div className={`${wrapperClass} ${className}`}>
        <div className='flex flex-col sm:flex-row sm:items-center justify-between mb-8 sm:mb-10'>
          <div className='mb-2 sm:mb-0'>
            <span className='text-darkGray leading-6 tracking-[0.0275em] mr-2'>#{templateId}</span>
            <span className='font-bold leading-8 tracking-[0.004em] text-black text-[1.25rem]'>
              {templateName}
            </span>
          </div>
          <div>
            <span className='font-medium text-borderGrey text-[1.125rem] leading-8 tracking-[0.004em]'>
              {dateFirstActivateTemplate
                ? t('INVITE_FRIEND_PAGE_TEXT.statistics.startOfAction', {
                    date: new Date(dateFirstActivateTemplate).toLocaleDateString('ru-Ru', {
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                    }),
                  })
                : ''}
            </span>
          </div>
        </div>

        <div className='flex flex-col justify-between sm:flex-row'>
          <div className='flex grow sm:pr-4'>
            <div className='flex flex-col grow'>
              <p className='font-bold leading-[2.75rem] text-blackText text-[2.125rem] mb-2'>
                {`${rub.format(amountPaymentsChild).slice(0, -2)} ${t('CURRENCY')}`}
              </p>
              <div className='flex'>
                <p className={`${grayText} mr-2`}>
                  {t('INVITE_FRIEND_PAGE_TEXT.statistics.earnedFromNewClients')}
                </p>
                <IconWithTooltips tooltips='earnedFromNewClients' />
              </div>
              <div className='flex flex-col mt-6 mb-8 sm:mb-0 w-full'>{payedBonusesToRender}</div>
            </div>
          </div>

          <div className='flex flex-col grow basis-[18rem] mb-8 sm:mb-0 sm:pr-4'>
            <div className='flex items-center mb-8'>
              <EfficiencyIcon className='mr-4' />
              <span className='font-medium text-[1.25rem] leading-6 tracking-[0.004em]'>
                {t('INVITE_FRIEND_PAGE_TEXT.statistics.efficiencyOfAction')}
              </span>
            </div>
            {efficiencyOfActionToRender}
          </div>

          <div className='flex flex-col grow basis-[10rem] mb-8 sm:mb-0'>
            <div className='flex items-center mb-8'>
              <VisitsIcon className='mr-4' />
              <span className='font-medium text-[1.25rem] leading-6 tracking-[0.004em]'>
                {t('INVITE_FRIEND_PAGE_TEXT.statistics.visitsOfAction')}
              </span>
            </div>
            {visitsOfActionToRender}
          </div>
        </div>
      </div>
    );
  },
);

InviteFriendsStatisticsCard.displayName = 'InviteFriendsStatisticsCard';
