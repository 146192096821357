import React, { memo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//
import { Loader } from '@blocks/loader';
import { PATH_NAMES } from '@data/dict';
import { PayNav } from '@blocks/payNav';
import { getAppData } from '@redux/app/selectors';
import { clearStatuses } from '@redux/pay/paySlice';
import { PayTemplate } from '@templates/payTemplate';
import { getPayPageState } from '@redux/pay/selectors';
import { getAccountsData } from '@redux/accounts/selectors';
import { ToastsMessages } from '@components/toastsMessages';
import { PayHistoryTable } from '@components/payHistoryTable';
import { useAppDispatch, useAppSelector } from '@store/store';
import { HeaderWithButton } from '@components/headerWithButton';
import {
  changeCartData,
  getPayPageData,
  getAutoPayLinkData,
  turnOffAutoPayment,
} from '@redux/pay/payThunks';

type TPayCommonTemplateProps = {
  /**
   * Опциональный параметр - строка классов
   * @param {string}
   */
  className?: string;
};

export const PayCommonTemplate = memo(({ className = '' }: TPayCommonTemplateProps) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const {
    isLoading,
    autoPayLink,
    errorMessage,
    filteredData,
    successMessage,
    mobileTableData,
    changeCardIsError,
    changeCardDataLink,
    changeCardIsLoading,
    isFetchingAutoPayLink,
    isSendingInvoiceToTgError,
    turnOffAutoPaymentIsError,
    isFetchingAutoPayLinkError,
    isFetchingInvoiceLinkError,
    turnOffAutoPaymentIsLoading,
    turnOffAutoPaymentIsSuccess,
    data,
  } = useAppSelector(getPayPageState);

  const { appLanguage } = useAppSelector(getAppData);

  // Получаем имя сохраняется при выборе филиала на странице /accounts
  const { selectedFilial } = useAppSelector(getAccountsData);

  // Callback вызывает запрос за данными страницы
  const refetch = useCallback(() => {
    dispatch(getPayPageData({ isTgChanelAdd: false }));
  }, [dispatch]);

  // Создаем callback для получения ссылки на подключение автоплатежа
  const getAutoPayLinkHandler = useCallback(() => {
    dispatch(getAutoPayLinkData());
  }, [dispatch]);

  // Создаем callback для получения ссылки на изменение карты подключенной в автоплатеже
  const changeCardHandler = useCallback(() => {
    dispatch(changeCartData());
  }, [dispatch]);

  // Создаем callback для отключения автоплатежа
  const turnOffAutoPaymentHandler = useCallback(() => {
    dispatch(turnOffAutoPayment());
  }, [dispatch]);

  // Функция очищает все статусы запросов
  const clearStatusesHandler = useCallback(() => {
    dispatch(clearStatuses());
  }, [dispatch]);

  const isShowPayTemplate =
    !isLoading && selectedFilial?.accId && data.amount && pathname.includes(PATH_NAMES.PAY);

  const isShowPayHistoryTable =
    !isLoading && selectedFilial?.accId && pathname.includes(PATH_NAMES.PAY_HISTORY);

  const isShowErrorToast =
    turnOffAutoPaymentIsError ||
    changeCardIsError ||
    isFetchingAutoPayLinkError ||
    isFetchingInvoiceLinkError ||
    isSendingInvoiceToTgError;

  return (
    <div className={`flex flex-col ${className}`}>
      <div className='px-4 sm:px-0 mb-6'>
        <HeaderWithButton
          headerText={t('PAY_PAGE_TEXT.header')}
          className={pathname !== PATH_NAMES.PAY ? 'mb-6' : 'mb-0'}
        />
        {pathname !== PATH_NAMES.PAY ? (
          <PayNav
            path={pathname}
            appLanguage={appLanguage}
            selectedFilialAccId={selectedFilial?.accId || ''}
            className='px-4 sm:px-0'
          />
        ) : null}
      </div>
      {isLoading ? <Loader className='h-[50vh]' /> : null}
      {isShowPayTemplate ? (
        <PayTemplate
          refetch={refetch}
          autoPayLink={autoPayLink}
          changeCardHandler={changeCardHandler}
          changeCardDataLink={changeCardDataLink}
          changeCardIsLoading={changeCardIsLoading}
          getAutoPayLinkHandler={getAutoPayLinkHandler}
          isFetchingAutoPayLink={isFetchingAutoPayLink}
          turnOffAutoPaymentHandler={turnOffAutoPaymentHandler}
          turnOffAutoPaymentIsLoading={turnOffAutoPaymentIsLoading}
          turnOffAutoPaymentIsSuccess={turnOffAutoPaymentIsSuccess}
          data={data}
        />
      ) : null}
      {isShowPayHistoryTable ? (
        <PayHistoryTable
          filteredData={filteredData}
          mobileTableData={mobileTableData}
          className='px-4 sm:px-0'
        />
      ) : null}
      <ToastsMessages
        errorMessage={errorMessage}
        successMessage={successMessage}
        isShowErrorToast={isShowErrorToast}
        isShowSuccessToast={!!successMessage}
        clearErrorCallback={clearStatusesHandler}
        clearSuccessStatusCallback={clearStatusesHandler}
      />
    </div>
  );
});

PayCommonTemplate.displayName = 'PayCommonTemplate';
