import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Chips } from '@uikit/Chips';
import { useAppDispatch } from '@store/store';
import { ToggleSwitch } from '@blocks/toggleSwitch';
import { TFilialData } from '@redux/accounts/models';
import { TSimpleOnchangeHandler } from '@shared/types';
import { CopyTemplates } from '@components/copyTemplates';
import { HeaderWithEdit } from '@components/headerWithEdit';
import { ACTION_DATA_KEY_NAMES } from '@redux/inviteFriendsAction/type';
import {
  setTitle,
  setTitleInHeader,
  setTemplateActivity,
} from '@redux/inviteFriendsAction/inviteFriendSlice';

type TInviteFriendsActionHeaderProps = {
  /**
   * Id шаблона
   * @param {string}
   */
  id: string;
  /**
   * Флаг активности шаблона
   * @param {boolean}
   */
  active: boolean;
  /**
   * Title шаблона
   * @param {string}
   */
  oldTitle: string;
  /**
   * Флаг копирования шаблонов
   * @param {boolean}
   */
  isTemplatesCopying: boolean;
  /**
   * Список аккаунтов фииала
   * @param {TFilialData[]}
   */
  accounts: TFilialData[];
  /**
   * Флаг наличия изменений в шаблоне
   * @param {boolean}
   */
  isTemplateNotChanged: boolean;
  /**
   * Копирует шаблон в переданный список филиалов
   * @param {(filialToCopy: string[]) => void}
   */
  copyHandler: (filialToCopy: string[]) => void;
  /**
   * Флаг успешного завершения процесса копирования
   * @param {boolean}
   */
  isCopyTemplatesSuccess: boolean;
  /**
   * Опциональны параметр доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const InviteFriendsActionHeader = memo(
  ({
    id,
    active,
    oldTitle,
    accounts,
    copyHandler,
    isTemplatesCopying,
    isTemplateNotChanged,
    isCopyTemplatesSuccess,
    isEditTemplatesAccess = false,
    className = '',
  }: TInviteFriendsActionHeaderProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const emptyTitle = id
      ? t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.templateHeader', { id })
      : t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.newTemplateHeader');

    // Обрабатывает изменения в поле ввода названия шаблона
    const setTitleInHeaderHandler = useCallback(
      (value: string) => {
        dispatch(setTitleInHeader({ title: value }));
      },
      [dispatch],
    );

    // Обрабатывает изменения в поле ввода названия шаблона
    const setTitleHandler = useCallback(
      (value: string) => {
        dispatch(setTitle({ title: value }));
      },
      [dispatch],
    );

    // Переключает активность шаблона
    const setTemplateActivityHandler: TSimpleOnchangeHandler = useCallback(
      event => {
        const { checked } = event.currentTarget;

        dispatch(setTemplateActivity({ checked }));
      },
      [dispatch],
    );

    return (
      <div className={className}>
        <div className='flex flex-col mb-4'>
          {id ? (
            <div className='flex items-center'>
              <span className='text-darkGray leading-6 tracking-[0.0275em] mr-2'>
                {t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.templateId', { id })}
              </span>
              {!isTemplateNotChanged ? (
                <Chips
                  round
                  removable
                  type='dense'
                  color='warning'
                  tag={t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.draftTag')}
                />
              ) : null}
            </div>
          ) : null}
          <div className='flex flex-wrap items-center justify-between'>
            <HeaderWithEdit
              id={id}
              isHideId
              oldTitle={oldTitle}
              setTitle={setTitleHandler}
              emptyTitleName={emptyTitle}
              setOldTitle={setTitleInHeaderHandler}
              className='mb-4'
            />
            {/* <VideoInstructionWithDropdown instructionData={VIDEO_INSTRUCTIONS.inviteFriends} /> */}
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center justify-between mb-10'>
          <div className='flex items-center mb-4 md:mb-0'>
            {isEditTemplatesAccess && (
              <ToggleSwitch
                checked={active}
                disabled={!isEditTemplatesAccess}
                name={ACTION_DATA_KEY_NAMES.ACTIVE}
                onChangeHandler={setTemplateActivityHandler}
              />
            )}
            <div className='ml-4'>
              <p className='font-medium text-blackText m-0'>
                {t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.templateSwitchTitle')}
              </p>
              <p className='m-0 text-[0.875rem] text-textareaBlack'>
                {t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.templateSwitch')}
              </p>
            </div>
          </div>
          {accounts.length > 1 ? (
            <CopyTemplates
              accounts={accounts}
              copyHandler={copyHandler}
              isTemplatesCopying={isTemplatesCopying}
              isCopyTemplatesSuccess={isCopyTemplatesSuccess}
              className='w-full sm:w-fit'
            />
          ) : null}
        </div>
      </div>
    );
  },
);

InviteFriendsActionHeader.displayName = 'InviteFriendsActionHeader';
