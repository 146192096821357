import React, { useRef, useState } from 'react';
import { Icon } from '@blueprintjs/core';
//
import { Button } from '@uikit/Button';

type TMailingTextExampleElementProps = {
  /**
   * Текст в блок
   * @param {string}
   */
  text: string;
  /**
   * Заголовок блока
   * @param {string}
   */
  title: string;
  /**
   * Флаг анимации
   * @param {boolean}
   */
  isAnimate: boolean;
  /**
   * Callback для записи текста в поле ввода
   * @param {() => void}
   */
  setSelectedTextTemplate: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const MailingTextExampleElement = ({
  text,
  title,
  isAnimate,
  setSelectedTextTemplate,
  className = '',
}: TMailingTextExampleElementProps) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const height = `max-h-[${Number(ref.current?.scrollHeight) / 16}rem]`;

  const toggle = () => {
    setOpen(prevState => !prevState);
  };

  const animation = isAnimate ? ' transition-all duration-700 ease-in-out' : '';

  return (
    <div
      className={`border border-quartz cursor-pointer rounded px-1 pt-1 ${
        open ? 'pb-4' : 'pb-1'
      } ${className}`}>
      <div
        className={`${
          open ? 'bg-aquaSpring' : ''
        } flex items-center justify-between py-2 px-3 ${animation}`}
        onClick={toggle}>
        <p className='cursor-pointer text-blackText leading-6 tracking-[0.0275em] whitespace-pre-line'>
          {title}
        </p>
        <Icon
          size={20}
          icon='caret-right'
          color={open ? '#37833B' : '#4A4B57'}
          className={`${animation} ${open ? 'rotate-90' : ''}`}
        />
      </div>
      <div
        ref={ref}
        className={`flex flex-col items-end px-4 ${
          open ? height : 'max-h-0'
        } overflow-hidden ${animation}`}>
        <p className='whitespace-pre-line mb-4'>{text}</p>
        <div className='h-10'>
          <Button
            dense
            type='action'
            view='filled'
            color='success'
            text='Вставить в шаблон'
            onClick={setSelectedTextTemplate}
          />
        </div>
      </div>
    </div>
  );
};
