import { createAsyncThunk } from '@reduxjs/toolkit';
import { TRejectResponseData } from '@models/index';
import { autoResponseApi } from '@api/autoResponseApi';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { autoResponseMappers } from '@redux/autoResponse/autoResponseMappers';
import { AUTO_RESPONSE_DATA_KEY_NAMES, REJECT_RESPONSE_KEY } from '@api/types';
import {
  TAutoResponseItem,
  TDeleteAutoResponseItemData,
  TCreateAutoResponseThunkData,
  TUpdateAutoResponseThunkData,
  TAutoResponseItemActivityCheckData,
} from '@redux/autoResponse/models';

/**
 * Thunk для получения данных страницы
 * @return {TAutoResponseItem[] | void} Данные страницы или void.
 * @throws {Error} Если получен неверный статус ответа.
 * @param {void} data - Неиспользуемый параметр.
 * @param {TRejectResponseData} rejectWithValue - Функция для обработки ошибок запроса.
 */
export const getAutoResponseDataThunk = createAsyncThunk<
  TAutoResponseItem[] | void,
  void,
  { rejectValue: TRejectResponseData }
>('autoResponse/getAutoResponseDataThunk', (_, { dispatch, rejectWithValue }) => {
  return autoResponseApi
    .getAutoResponseData()
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      if (AUTO_RESPONSE_DATA_KEY_NAMES.ITEMS in response.data) {
        return autoResponseMappers.responseToStore(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getAutoResponseDataThunk()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk для создания блока автоответа
 * @return {TAutoResponseItem | string} Данные созданного автоответа или статус ошибки.
 * @throws {Error} Если получен неверный статус ответа.
 * @param {TCreateAutoResponseThunkData} data - Данные для отправки запроса
 * @param {TRejectResponseData} rejectWithValue - Функция для обработки ошибок запроса.
 */
export const createAutoResponseThunk = createAsyncThunk<
  string | void,
  TCreateAutoResponseThunkData,
  { rejectValue: TRejectResponseData }
>('autoResponse/postAutoResponseDataThunk', (data, { dispatch, rejectWithValue }) => {
  return autoResponseApi
    .createAutoResponse(autoResponseMappers.responseToBack(data.data, data.activeItemId))
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        return response.data.status;
      }
      data.callback();
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(createAutoResponseThunk(data)), 200);
      }
      return rejectWithValue(error.response?.data);
    });
});

/**
 * Thunk для изменения данных в блоке автоответа
 * @return {string | void} Возвращает сообщение об ошибке или void.
 * @throws {Error} Если получен неверный статус ответа.
 * @param {TDeleteAutoResponseItemData} data - Данные для отправки запроса
 * @param {TRejectResponseData} rejectWithValue - Функция для обработки ошибок запроса.
 */
export const updateAutoResponseItemThunk = createAsyncThunk<
  string | void,
  TUpdateAutoResponseThunkData,
  { rejectValue: TRejectResponseData }
>('autoResponse/updateAutoResponseItemThunk', (data, { dispatch, rejectWithValue }) => {
  return autoResponseApi
    .updateAutoResponseItem(autoResponseMappers.updateAutoResponseItemData(data))
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        data.callback();
        return response.data.status;
      }
      data.callback();
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(updateAutoResponseItemThunk(data)), 200);
      }
      return rejectWithValue(error.response?.data);
    });
});

/**
 * Thunk для создания блока автоответа
 * @return {string | void} Возвращает ID активного автоответа или void.
 * @throws {Error} Если получен неверный статус ответа.
 * @param {TDeleteAutoResponseItemData} data - Данные для отправки запроса
 * @param {TRejectResponseData} rejectWithValue - Функция для обработки ошибок запроса.
 */
export const deleteAutoResponseItemThunk = createAsyncThunk<
  string | void,
  TDeleteAutoResponseItemData,
  { rejectValue: TRejectResponseData }
>('autoResponse/deleteAutoResponseItemThunk', (data, { dispatch, rejectWithValue }) => {
  const { activeItemId, data: autoResponseData } = data;
  const [activeActiveResponseItem] = autoResponseData.filter(item => item.id === activeItemId);

  return autoResponseApi
    .deleteAutoResponseItem(activeActiveResponseItem.clientRequest[0])
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      return activeItemId;
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(deleteAutoResponseItemThunk(data)), 200);
      }
      return rejectWithValue(error.response?.data);
    });
});

/**
 * Thunk для изменения активности блока автоответа
 * @return {TAutoResponseItemActivityCheckData} Возвращает данные для изменения значений в STATE.
 * @throws {Error} Если получен неверный статус ответа.
 * @param {TAutoResponseItemActivityCheckData} data - Данные для отправки запроса
 * @param {TRejectResponseData} rejectWithValue - Функция для обработки ошибок запроса.
 */
export const autoResponseItemActivitySwitchThunk = createAsyncThunk<
  TAutoResponseItemActivityCheckData,
  TAutoResponseItemActivityCheckData,
  { rejectValue: TRejectResponseData }
>('autoResponse/autoResponseItemActivitySwitchThunk', (data, { dispatch, rejectWithValue }) => {
  return autoResponseApi
    .autoResponseItemActivitySwitch(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      return data;
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(autoResponseItemActivitySwitchThunk(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});
