import { TAppLanguage, TSimpleStringObj } from '@models/index';

export const NOT_SELECTED_OLD = 'notSelected';

export enum TYPE_OF_SALON {
  NAIL_SALON = 'nail_salon',
  BARBERSHOP = 'barbershop',
  COLOR_SALON_HAIR_SALON = 'color_salon_hair_salon',
  FULL_CYCLE_SALON = 'full_cycle_salon',
}

export const typeOfSalon: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    [NOT_SELECTED_OLD]: 'Not selected',
    'Beauty salon': 'Beauty salon',
    'SPA salon': 'SPA salon',
    'Tattoo salon': 'Tattoo salon',
    'Tanning studio': 'Tanning studio',
    Cosmetology: 'Cosmetology',
    'Nail service': 'Nail service',
    'Eyebrows and eyelashes': 'Eyebrows and eyelashes',
    "Children's hairdresser": "Children's hairdresser",
    'Private master': 'Private master',
    Epilation: 'Epilation',
    'Other services': 'Other services',
    'Other beauty business': 'Other beauty business',
  },
  rus: {
    [NOT_SELECTED_OLD]: 'Не выбрано',
    'Салон красоты': 'Салон красоты',
    'СПА салон': 'СПА салон',
    'Тату салон': 'Тату салон',
    'Студия загара': 'Студия загара',
    Косметология: 'Косметология',
    'Ногтевой сервис': 'Ногтевой сервис',
    'Брови и ресницы': 'Брови и ресницы',
    'Детская парикмахерская': 'Детская парикмахерская',
    'Частный мастер': 'Частный мастер',
    Эпиляция: 'Эпиляция',
    'Другие услуги': 'Другие услуги',
    'Другие beauty-бизнес': 'Другие beauty-бизнес',
  },
};

export const LIST_OF_SKILLS: Record<TAppLanguage, string[]> = {
  eng: [
    '👉 remind clients about the visit',
    '👉 make mailings',
    '👉 collect feedback',
    '👉 bring back lost customers',
    'and much more...',
  ],
  rus: [
    '👉 напоминать клиентам о визите',
    '👉 делать рассылки',
    '👉 собирать обратную связь',
    '👉 возвращать потерявшихся клиентов',
    'и многое другое...',
  ],
};

export const AGE_OF_WHATSAPP_ACCOUNT: Record<string, string> = {
  NOT_SELECTED: 'notSelected',
  LESS_THAN_ONE_MONTH: 'less than 1',
  UP_TO_TWO_MONTH: '2',
  UP_TO_SIX_MONTH: '6',
  UP_TO_A_YEAR: '12',
  MORE_THAT_A_YEAR: '13',
};

export const AGE_OF_WHATSAPP_ACCOUNT_RESPONSE: Record<string, string> = Object.fromEntries(
  Object.entries(AGE_OF_WHATSAPP_ACCOUNT).map(([key, value]) => [value, key]),
);

// export const enum AGE_OF_WHATSAPP_ACCOUNT_KEYS {
//   NOT_SELECTED = 'NOT_SELECTED',
//   LESS_THAN_ONE_MONTH = 'LESS_THAN_ONE_MONTH',
//   UP_TO_TWO_MONTH = 'UP_TO_TWO_MONTH',
//   UP_TO_SIX_MONTH = 'UP_TO_SIX_MONTH',
//   UP_TO_A_YEAR = 'UP_TO_A_YEAR',
//   MORE_THAT_A_YEAR = 'MORE_THAT_A_YEAR',
// }

export const AGE_OF_WHATSAPP_ACCOUNT_KEYS: Record<string, string> = Object.fromEntries(
  Object.entries(AGE_OF_WHATSAPP_ACCOUNT).map(([key]) => [key, key]),
);

export const ageOfWaAccount2: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    [AGE_OF_WHATSAPP_ACCOUNT_KEYS.NOT_SELECTED]: 'Not selected',
    [AGE_OF_WHATSAPP_ACCOUNT_KEYS.LESS_THAN_ONE_MONTH]: 'less than 1 month',
    [AGE_OF_WHATSAPP_ACCOUNT_KEYS.UP_TO_TWO_MONTH]: 'less than 2 months',
    [AGE_OF_WHATSAPP_ACCOUNT_KEYS.UP_TO_SIX_MONTH]: 'less than six months',
    [AGE_OF_WHATSAPP_ACCOUNT_KEYS.UP_TO_A_YEAR]: 'six months to a year',
    [AGE_OF_WHATSAPP_ACCOUNT_KEYS.MORE_THAT_A_YEAR]: 'a year or more',
  },
  rus: {
    [AGE_OF_WHATSAPP_ACCOUNT_KEYS.NOT_SELECTED]: 'Не выбрано',
    [AGE_OF_WHATSAPP_ACCOUNT_KEYS.LESS_THAN_ONE_MONTH]: 'менее 1 месяца',
    [AGE_OF_WHATSAPP_ACCOUNT_KEYS.UP_TO_TWO_MONTH]: 'менее 2 месяцев',
    [AGE_OF_WHATSAPP_ACCOUNT_KEYS.UP_TO_SIX_MONTH]: 'менее полугода',
    [AGE_OF_WHATSAPP_ACCOUNT_KEYS.UP_TO_A_YEAR]: 'полгода-год',
    [AGE_OF_WHATSAPP_ACCOUNT_KEYS.MORE_THAT_A_YEAR]: 'год и более',
  },
};

const rusMap = new Map<string, string>();

rusMap.set(NOT_SELECTED_OLD, 'Не выбрано');
rusMap.set(AGE_OF_WHATSAPP_ACCOUNT.LESS_THAN_ONE_MONTH, 'менее 1 месяца');
rusMap.set(AGE_OF_WHATSAPP_ACCOUNT.UP_TO_TWO_MONTH, 'менее 2 месяцев');
rusMap.set(AGE_OF_WHATSAPP_ACCOUNT.UP_TO_SIX_MONTH, 'менее полугода');
rusMap.set(AGE_OF_WHATSAPP_ACCOUNT.MORE_THAT_A_YEAR, 'год и более');

const engMap = new Map<string, string>();

engMap.set(NOT_SELECTED_OLD, 'Not selected');
engMap.set(AGE_OF_WHATSAPP_ACCOUNT.LESS_THAN_ONE_MONTH, 'less than 1 month');
engMap.set(AGE_OF_WHATSAPP_ACCOUNT.UP_TO_TWO_MONTH, 'less than 2 months');
engMap.set(AGE_OF_WHATSAPP_ACCOUNT.UP_TO_SIX_MONTH, 'less than six months');
engMap.set(AGE_OF_WHATSAPP_ACCOUNT.UP_TO_A_YEAR, 'six months to a year');
engMap.set(AGE_OF_WHATSAPP_ACCOUNT.MORE_THAT_A_YEAR, 'a year or more');

export const ageOfWaAccount: Record<TAppLanguage, Map<string, string>> = {
  eng: engMap,
  rus: rusMap,
};

export const AUTO_CONNECTION_PAGE_TEXT = {
  productName: 'Бьюти Бот',
  greeting: `Привет, я\u00A0—\u00A0Бьюти\u00A0Бот`,
  greeting2: 'Вот, что я умею:',
  formGreeting: 'Ответьте на мои вопросы,\n чтобы подключить бота',
  fillOutForm: 'Заполнить анкету',
  pathUlItems: [
    'Название салона',
    'Номер телефона',
    'Промокод',
    'Эмодзи',
    'Телефон салона',
    'Возраст Whatsapp',
    'Ссылки на салон',
    'Адрес салона',
  ],
  callbackForm: {
    dialogTitle: 'Введите ваш телефон и мы перезвоним',
    dialogInputLabel: 'Ваш номер телефона',
    cancelButton: 'Отмена',
    successButton: 'Перезвонить',
  },
  footerText: {
    support: 'Поддержка:',
    telegramLinkButton: 'Telegram',
    requestACallbackCallButton: 'Заказать звонок',
  },
  continueButton: 'Далее',
  findButton: 'Найти',
  deleteEmojiButton: 'Удалить эмодзи',
  correctButton: 'Верно',
  notCorrectButton: 'Неверно',
  confirmButton: 'Да',
  skipButton: 'Нет, пропустить',
  applyButton: 'Применить',
  goToBotButton: 'Перейти в бота',
  addLinkButton: 'Добавить ссылку',
  promoCode: {
    iFound: 'Я нашла промокод',
    whichGives: ' который даёт ',
    promoCodeData: ' {{bonus}} бесплатных {{days}} ',
    whichFixesCostOfMonth: ' который фиксирует стоимость 1-го месяца в',
    whichReducesEachPayment: 'который уменьшает каждый платеж на',
    fixedCoast: ' {{discount}} р.',
    great: 'Отлично! Этот промокод даст',
    great2: 'Отлично! Этот промокод дает',
    great3: 'Отлично! Этот промокод фиксирует стоимость 1-го месяца в',
    great4: 'Отлично! Этот промокод уменьшает каждый платеж на',
    promoCodeData2: ' скидку {{discount}}% ',
    eachPayment: 'для каждого платежа',
  },
  additionalOption: {
    header: 'Не можете отсканировать код или перейти в телеграм?',
    getLink: 'Получить ссылку на бота через номер WhatsApp',
    phoneInputPlaceholder: 'Введите номер',
    phoneInputLabel: 'Ваш номер WhatsApp',
    sendButton: 'Отправить',
  },
  formStepMessages: {
    step0: 'Я кое-что знаю о вас. Например, название вашего салона. Давайте проверим его',
    step1:
      'А это ваш номер телефона? Проверьте его корректность, он нужен для отправки уведомлений об оплате и решения важных вопросов',
    step2: 'У вас есть промокод?',
    step3: 'Выберите специализацию вашего салона',
    step3_1:
      'Я подобрала смайлики, подходящие вашему салону. Выберите свой эмодзи, он будет располагаться рядом с названием салона во всех сообщениях клиенту',
    step3_2: 'У вас отличный вкус!',
    step4: '',
    step5: 'Укажите номер телефона, на который звонят клиенты',
    step6:
      'Укажите срок использования WhatsApp к номеру телефона салона, с которого будут отправляться сообщения клиентам',
    step6_1: 'Осталось совсем немного и вы получите лучшего помощника у себя в телефоне',
    step7: 'Укажите ссылку на отзыв о вашем салоне для автоматической публикации отзывов',
    step8:
      'Я определила адрес вашего салона. Я молодец? Если адрес был определен некорректно, исправьте его',
    // step9: `Остался последний шаг - создать бота! Вы можете сделать это:\n ${'\u2022'} отсканировав камерой вашего смартфона указанный QR-код\n ${'\u2022'} перейдя по ссылке телеграм ниже\n ${'\u2022'} или нажав на «Перейти в бота»\n\n ❗ Важно: сделать это необходимо с телеграм аккаунта владельца салона`,
    step9:
      'Остался последний шаг - создать бота!\n' +
      'Вы можете сделать это:\n' +
      '- отсканировав камерой вашего смартфона указанный QR-код\n' +
      '- перейдя по ссылке телеграм ниже\n' +
      '- или нажав на «Перейти в бота»\n' +
      '\n' +
      '❗ Важно: сделать это необходимо с телеграм аккаунта владельца салона',
  },
  formLabelTexts: {
    step0: 'Проверьте название вашего салона',
    step1: 'Проверьте номер телефона владельца салона',
    step2: 'Введите промокод',
    step3: 'Укажите тип вашего салона',
    step4: '',
    step5: 'Укажите номер телефона вашего салона',
    step6: '',
    step7: 'Вставьте ссылку на отзыв о салоне в Яндекс.Картах, Google или 2ГИС',
    step8: 'Проверьте адрес вашего салона',
    step9: '',
  },
  formInputPlaceholders: {
    step0: 'Введите название салона',
    step1: 'Введите номер',
    step2: 'Введите промокод',
    step3: '',
    step4: '',
    step5: 'Введите номер',
    step7: 'Введите ссылку на отзыв о салоне',
    step8: 'Введите адрес салона',
    step9: '',
  },
};

export const AUTO_CONNECTION_PAGE_TEXT_ENG = {
  productName: 'Beauty Bot',
  greeting: `Hello, I\u00A0—\u00A0Beauty\u00A0Bot`,
  greeting2: "Here's what I can do:",
  formGreeting: 'Answer my questions\nto connect the bot',
  fillOutForm: 'Fill out the form',
  pathUlItems: [
    'Salon name',
    'Phone number',
    'Promo code',
    'Emoji',
    'Salon telephone',
    'Whatsapp Age',
    'Links to the salon',
    'Salon address',
  ],
  callbackForm: {
    dialogTitle: 'Enter your phone number and we will call you back',
    dialogInputLabel: 'Your phone number',
    cancelButton: 'Cancel',
    successButton: 'Call back',
  },
  footerText: {
    support: 'Support:',
    telegramLinkButton: 'Telegram',
    requestACallbackCallButton: 'Request a call',
  },
  continueButton: 'Next',
  findButton: 'Find',
  deleteEmojiButton: 'Delete Emoji',
  correctButton: 'Correct',
  notCorrectButton: 'Incorrect',
  confirmButton: 'Yes',
  skipButton: 'No, skip',
  applyButton: 'Apply',
  goToBotButton: 'Go to bot',
  addLinkButton: 'Add link',
  promoCode: {
    iFound: 'I found a promotional code',
    whichGives: ' which gives ',
    promoCodeData: '{{bonus}} free {{days}}',
    whichFixesCostOfMonth: 'which fixes the cost of the 1st month in',
    whichReducesEachPayment: 'which reduces each payment by',
    fixedCoast: ' {{discount}} r.',
    great: 'Great! This promotional code will give',
    great2: 'Great! This promotional code gives',
    great3: 'Great! This promotional code fixes the cost of the 1st month in ',
    great4: 'Great! This promotional code reduces each payment by',
    promoCodeData2: ' discount {{discount}}% ',
    eachPayment: 'for each payment',
  },
  additionalOption: {
    header: "Can't scan the code or go to telegram?",
    getLink: 'Get a link to the bot via WhatsApp number',
    phoneInputPlaceholder: 'Enter a number',
    phoneInputLabel: 'Your WhatsApp number',
    sendButton: 'Send',
  },
  formStepMessages: {
    step0: "I know something about you. For example, the name of your salon. Let's check it out",
    step1:
      'Is this your phone number? Check its correctness, it is needed to send payment notifications and resolve important issues',
    step2: 'Do you have a promotional code?',
    step3: 'Choose the specialization of your salon',
    step3_1:
      'I have selected emoticons that suit your salon. Select your emoji, it will be located next to the name of the salon in all messages to the client',
    step3_2: 'You have excellent taste!',
    step4: '',
    step5: 'Indicate the phone number that clients call',
    step6:
      'Indicate the period of use of WhatsApp to the phone number of the salon from which messages will be sent to clients',
    step6_1: 'Very little time left and you will get the best assistant on your phone',
    step7: 'Insert a link to a review about your salon to automatically publish reviews',
    step8:
      "I have determined the address of your salon. I'm done? If the address was determined incorrectly, correct it",
    step9: 'The last step remains: follow the link in telegram and launch the bot!',
  },
  formLabelTexts: {
    step0: 'Check your salon name',
    step1: "Check the salon owner's phone number",
    step2: 'Enter promotional code',
    step3: 'Indicate the type of your salon',
    step4: '',
    step5: 'Indicate the phone number of your salon',
    step6: '',
    step7: 'Insert a link to a review about the salon in Yandex.Maps, Google or 2GIS',
    step8: 'Check your salon address',
    step9: '',
  },
  formInputPlaceholders: {
    step0: 'Enter the name of the salon',
    step1: 'Enter number',
    step2: 'Enter promotional code',
    step3: '',
    step4: '',
    step5: 'Enter number',
    step7: 'Enter a link to a review about the salon',
    step8: 'Enter the address of the salon',
    step9: '',
  },
};

export const AUTO_CONNECT_TOAST_MESSAGES = {
  CALLBACK: 'Перезвоним вам в течение часа',
  SEND_LINK_TO_WA: 'Ссылка отправлена',
};

export const AUTO_CONNECT_TOAST_MESSAGES_ENG = {
  CALLBACK: "We'll call you back within an hour",
  SEND_LINK_TO_WA: 'Link sent',
};

export const AUTO_CONNECT_MESSAGES = {
  CALLBACK: 'AUTO_CONNECT_TOAST_MESSAGES.CALLBACK',
  SEND_LINK_TO_WA: 'AUTO_CONNECT_TOAST_MESSAGES.SEND_LINK_TO_WA',
};

export const ERROR_MESSAGES: Record<string, string> = {
  'Promo code not found': 'Неправильный промокод',
  'Yandex geo data not found': 'Не найдены геоданные,\n проверьте ссылку',
};

export const RECOMMENDED_EMOJI_LIST = [
  '🩷',
  '❤',
  '🧡',
  '💛',
  '💚',
  '🩵',
  '💙',
  '💜',
  '🖤',
  '🩶',
  '🌸',
  '🌼',
  '🔥',
  '💅',
  '💈',
  '💇',
  '✂️',
  '💇',
  '💇',
  '💇‍♂️',
  '💇',
  '❣️',
  '💕💞',
  '💓',
  '💗',
  '💖',
  '💘',
  '💝',
  '❤️‍🩹',
  '❤️‍🔥',
  '🌱',
  '🌿',
  '🍀',
  '✨',
  '⚡',
  '🐶',
  '😻',
  '🤍',
  '🤎',
  '💃',
];
