import { authApi } from '@api/authApi';
import { storageDb } from '@api/storageApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setAuthUserData } from '@redux/auth/authSlice';
import {
  THeaderBannerInfo,
  AUTH_RESPONSE_KEYS,
  REJECT_RESPONSE_KEY,
  AUTH_ERROR_RESPONSE_KEY,
} from '@api/types';
import {
  TLoginData,
  TCheckPinData,
  TTelegramAuthResponseData,
  TELEGRAM_ID_AUTH_RESPONSE_KEY_NAMES,
} from '@redux/auth/models';
import { authMappers } from '@redux/auth/authMappers';

/**
 * Thunk для выполнения авторизации.
 *
 * @param {TTelegramAuthResponseData} data - Данные авторизации Telegram.
 * @returns {TLoginData | void} Данные авторизации или void.
 * @throws {Error} Если получена ошибка авторизации.
 * @param {any} dispatch - Функция для отправки экшенов Redux.
 * @param {any} rejectWithValue - Функция для обработки ошибок запроса.
 */
const login = createAsyncThunk<
  TLoginData | void,
  TTelegramAuthResponseData,
  { rejectValue: string }
>('auth/login', (data, { dispatch, rejectWithValue }) => {
  return authApi
    .loginUser(data)
    .then(response => {
      if (AUTH_ERROR_RESPONSE_KEY.MESSAGE in response.data) {
        throw new Error(String(response.data.message));
      }
      if (AUTH_RESPONSE_KEYS.AUTH in response.data) {
        if (response.data.auth) {
          storageDb.setAuth(response.data.auth);
          storageDb.setUserId(response.data.user_id);
          storageDb.setToken(response.data.access_token);
          storageDb.setUserData(authMappers.mapAuthUserData(data));
          dispatch(setAuthUserData(authMappers.mapAuthUserData(data)));
        }
        return {
          accessToken: response.data.access_token,
          userId: String(response.data.user_id),
          auth: response.data.auth,
        };
      }
    })
    .catch(error => rejectWithValue(error.response.status));
});

/**
 * Thunk для обновления токена авторизации.
 *
 * @param {string} data - Токен для обновления.
 * @returns {TLoginData | void} Данные авторизации или void.
 * @throws {Error} Если получена ошибка авторизации.
 * @param {any} rejectWithValue - Функция для обработки ошибок запроса.
 */
const updateToken = createAsyncThunk<TLoginData | void, string, { rejectValue: string }>(
  'auth/updateToken',
  (data, { rejectWithValue }) => {
    return authApi
      .updateToken(data)
      .then(response => {
        if (AUTH_ERROR_RESPONSE_KEY.MESSAGE in response.data) {
          throw new Error(String(response.data.message));
        }
        if (AUTH_RESPONSE_KEYS.AUTH in response.data) {
          if (response.data.auth) {
            storageDb.setAuth(response.data.auth);
            storageDb.setUserId(response.data.user_id);
            storageDb.setToken(response.data.access_token);
          }
          return {
            accessToken: response.data.access_token,
            userId: String(response.data.user_id),
            auth: response.data.auth,
          };
        }
      })
      .catch(error => rejectWithValue(error.response.status));
  },
);

/**
 * Thunk для получения информации о баннере.
 *
 * @returns {THeaderBannerInfo | void} Информация о  баннере или void.
 * @param {any} rejectWithValue - Функция для обработки ошибок запроса.
 */
const headerBanner = createAsyncThunk<THeaderBannerInfo | void, void, { rejectValue: string }>(
  'auth/headerBanner',
  (_, { rejectWithValue }) => {
    return authApi
      .getInfoBanner()
      .then(response => {
        return {
          status: response.data.status,
          type: response.data.type,
        };
      })
      .catch(error => rejectWithValue(error.response.status));
  },
);

/**
 * Thunk для выполнения авторизации через Telegram.
 *
 * @param {TCheckPinData} data - Данные проверки пин-кода.
 * @returns {TLoginData | void} Данные авторизации или void.
 * @throws {Error} Если получена ошибка авторизации.
 * @param {any} dispatch - Функция для отправки экшенов Redux.
 * @param {any} rejectWithValue - Функция для обработки ошибок запроса.
 */
const tgLogin = createAsyncThunk<TLoginData | void, TCheckPinData, { rejectValue: string }>(
  'auth/tgLogin',
  (data, { dispatch, rejectWithValue }) => {
    return authApi
      .checkPin(data)
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(String(response.data.status));
        }
        if (TELEGRAM_ID_AUTH_RESPONSE_KEY_NAMES.USER_ID in response.data) {
          if (response.data.auth) {
            storageDb.setAuth(response.data.auth);
            storageDb.setUserId(response.data.user_id);
            storageDb.setToken(response.data.access_token);
            storageDb.setUserData(authMappers.mapAuthPinUserData(response.data));
            dispatch(setAuthUserData(authMappers.mapAuthPinUserData(response.data)));
          }
          return {
            accessToken: response.data.access_token,
            userId: String(response.data.user_id),
            auth: response.data.auth,
          };
        }
      })
      .catch(error => rejectWithValue(error.response.status));
  },
);

/**
 * Thunk для получения идентификатора Telegram.
 *
 * @param {{ userId: number }} data - Данные идентификатора пользователя Telegram.
 * @returns {void} Без данных.
 * @throws {Error} Если получена ошибка при получении идентификатора.
 * @param {any} rejectWithValue - Функция для обработки ошибок запроса.
 */
const telegramId = createAsyncThunk<void, { userId: number }, { rejectValue: string }>(
  'auth/telegramId',
  (data, { rejectWithValue }) => {
    return authApi
      .getPin(data.userId)
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(String(response.data.status));
        }
      })
      .catch(error => rejectWithValue(error.response.status));
  },
);

export { login, updateToken, headerBanner, tgLogin, telegramId };
