import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { useAppSelector } from '@store/store';
import { getAppData } from '@redux/app/selectors';
import { NavItem } from '@blocks/templateNavItem';
import { QUERY_PARAMS } from '@const/apiConstants';
import { NavDropDown } from '@components/navDropDown';
import { TEMPLATE_PAGES_NAME_DICT } from '@const/templates';

interface ITemplateNavProps {
  /**
   * Путь из URL полученный хуком useLocation в родительском компоненте
   * @param {string}
   */
  path: string;
  /**
   * Параметр для отключения кнопки "новая задача"
   * @param {boolean}
   */
  isDisable: boolean;
  /**
   * Callback на кнопку Новый шаблон
   * @param {() => void}
   */
  callBack: () => void;
  /**
   * Параметр настраивает отображение кнопки добавления новой цепочки
   * @param {boolean}
   */
  isReviewsList: boolean;
  /**
   * Id выбранного филиала используется для формирования ссылок
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Callback на кнопку новая цепочка
   * @param {() => void}
   */
  addReviewCallback: () => void;
  /**
   * Определяет доступ пользователя для редактирования шаблонов.
   * @param {boolean}
   */
  isEditTemplatesAccess?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const TemplateNav = memo(
  ({
    path,
    callBack,
    isDisable,
    isReviewsList,
    addReviewCallback,
    selectedFilialAccId,
    isEditTemplatesAccess,
    className = '',
  }: ITemplateNavProps) => {
    const { t } = useTranslation();
    const { appLanguage } = useAppSelector(getAppData);

    const pageData = Object.entries(TEMPLATE_PAGES_NAME_DICT[appLanguage]);

    const linksToRender = pageData.map(([link, text], index) => {
      const isLastElement = index === pageData.length - 1;
      const penultimateElement = index === pageData.length - 2;

      const fullLink = `${link}?${QUERY_PARAMS.ACC_ID}=${selectedFilialAccId}`;

      if (isLastElement) {
        return null;
      }

      if (penultimateElement) {
        return (
          <NavItem
            key={text}
            text={text}
            to={fullLink}
            isActive={path.includes(link) || path.includes(pageData[pageData.length - 1][0])}
            className={`mb-4 ${!isLastElement ? 'mr-2' : ''}`}
          />
        );
      }

      return (
        <NavItem
          key={text}
          text={text}
          to={fullLink}
          isActive={path.includes(link)}
          className={`mb-4 ${!isLastElement ? 'mr-2' : ''}`}
        />
      );
    });

    return (
      <div className={`flex flex-wrap sm:flex-nowrap justify-between ${className}`}>
        <div className='mb-4 lg:hidden w-full'>
          <NavDropDown
            selectedFilialAccId={selectedFilialAccId}
            pageNameDict={TEMPLATE_PAGES_NAME_DICT[appLanguage]}
            className='nav_dropdown sm:max-w-[20rem]'
          />
        </div>
        <div className='hidden flex-wrap lg:flex'>{linksToRender}</div>
        {isEditTemplatesAccess && isReviewsList ? (
          <Button
            dense
            icon='plus'
            type='action'
            view='filled'
            color='success'
            disabled={isDisable}
            onClick={addReviewCallback}
            text={t('TEMPLATE_PAGE_TEXT.templateNav.newReviews')}
            className='min-w-[12rem] w-full sm:w-auto'
          />
        ) : null}
        {isEditTemplatesAccess && !isReviewsList ? (
          <Button
            dense
            icon='plus'
            type='action'
            view='filled'
            color='success'
            onClick={callBack}
            disabled={isDisable}
            text={t('TEMPLATE_PAGE_TEXT.templateNav.newTemplateButton')}
            className='min-w-[12rem] w-full sm:w-auto'
          />
        ) : null}
      </div>
    );
  },
);

TemplateNav.displayName = 'TemplateNav';
