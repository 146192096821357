import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { storageDb } from '@api/storageApi';
import { myTrackerApi } from '@api/myTrackerApi';
import { connectionApi } from '@api/connectionApi';
import { GET_UNIQUE_CODE_TIMEOUT_MS } from '@const/connect';
import { TConnectionStatus } from '@redux/connection/types';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { showToSoonRequestError } from '@redux/connection/connectionSlice';
import { MY_TRACKER_EVENTS, TEventFullErrorData } from '@helpers/myTracker';
import { setSelectedFilialInstanceStatus } from '@redux/accounts/accountsSlice';
import { mapTgStatusToStore, qrMapper } from '@redux/connection/connectionMappers';
import { CONNECTION_STATUSES, TQRCodeData, TRejectResponseData } from '@models/index';
import { getCommonEventData, getGetWACodeTime, setGetWACodeTime } from '@helpers/index';
import {
  CONNECTION_ACTION_RESULTS,
  GET_CONNECTION_STATUS_KEY_NAMES,
  GET_QR_CODE_RESPONSE_KEY_NAMES,
  REJECT_RESPONSE_KEY,
  TGetConnectionStatusResponse,
  TGetQRCodeResponse,
} from '@api/types';

/**
 * Thunk для получения статуса подключения WA бота.
 *
 * @param {void} data - Данные запроса (не используются).
 * @returns {TGetConnectionStatusResponse | void} - Данные статуса подключения или undefined.
 * @throws {Error} - Ошибка, если получен некорректный ответ.
 */
const getConnectionPageStatus = createAsyncThunk<
  TGetConnectionStatusResponse | void,
  void,
  { rejectValue: TRejectResponseData }
>('connection/getConnectionPageStatus', (data, { dispatch, rejectWithValue }) => {
  return connectionApi
    .getConnectionStatus()
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        dispatch(setSelectedFilialInstanceStatus(CONNECTION_STATUSES.BLOCKED));

        throw new Error(String(response.data));
      }

      if (GET_CONNECTION_STATUS_KEY_NAMES.MESSAGES in response.data) {
        myTrackerApi.openConnectionPage(
          getCommonEventData(MY_TRACKER_EVENTS.CONNECT_OPEN, {
            connect: response.data.status === CONNECTION_STATUSES.AUTHENTICATED,
          }),
        );

        dispatch(setSelectedFilialInstanceStatus(response.data.status));

        return response.data;
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getConnectionPageStatus()), 200);
        return;
      }
      dispatch(setSelectedFilialInstanceStatus(CONNECTION_STATUSES.BLOCKED));
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk для получения данных QR-кода.
 *
 * @param {void} data - Данные запроса (не используются).
 * @returns {TQRCodeData | void} - Данные QR-кода или undefined.
 * @throws {Error} - Ошибка, если получен некорректный ответ.
 */
const getQrData = createAsyncThunk<
  TQRCodeData | void,
  void,
  { state: RootState; rejectValue: TRejectResponseData }
>('connection/getQrData', (data, { dispatch, getState, rejectWithValue }) => {
  const { userId } = storageDb.getRequestData();
  const { selectedFilial } = getState().accounts;

  const eventData: TEventFullErrorData = {
    user_id: String(userId),
    branch_id: selectedFilial?.branchId || '0',
    error_code: '0',
  };

  myTrackerApi.getQrCodeEvent(eventData);

  return connectionApi
    .getQRCodeString()
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.status));
      }

      if (
        GET_QR_CODE_RESPONSE_KEY_NAMES.RESULT in response.data &&
        response.data.result !== CONNECTION_ACTION_RESULTS.QR_CREATE
      ) {
        throw new Error(String(response.data));
      }

      if (GET_QR_CODE_RESPONSE_KEY_NAMES.QR_CODE in response.data && response.data.ok) {
        myTrackerApi.qrCodeGotEvent();

        return qrMapper(response.data as TGetQRCodeResponse);
      }
    })
    .catch(error => {
      if (error?.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getQrData()), 200);
      }

      myTrackerApi.getQrCodeError({ error_code: error?.response?.status || String(error) });

      return rejectWithValue(error?.response?.data);
    });
});

/**
 * Thunk для отправления кода авторизации.
 *
 * @param {void} data - Данные запроса (не используются).
 * @returns {TQRCodeData | void} - Данные QR-кода или undefined.
 * @throws {Error} - Ошибка, если получен некорректный ответ.
 */
const getAuthCode = createAsyncThunk<
  string | void,
  string,
  { state: RootState; rejectValue: TRejectResponseData }
>('connection/getAuthCode', (phone, { dispatch, getState, rejectWithValue }) => {
  const { oldPhoneNumber } = getState().connection;

  const waCodeGetTime = getGetWACodeTime();

  if (waCodeGetTime && +waCodeGetTime > new Date().getTime() - GET_UNIQUE_CODE_TIMEOUT_MS) {
    if (oldPhoneNumber === phone) {
      dispatch(showToSoonRequestError());
      return;
    }
  }

  return connectionApi
    .getAuthCodeString(phone)
    .then(response => {
      if ('auth_code' in response.data && response.data.ok) {
        setGetWACodeTime();

        return response.data.auth_code;
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getAuthCode(phone)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk для очистки сообщений.
 *
 * @param {void} data - Данные запроса (не используются).
 * @throws {Error} - Ошибка, если получен некорректный ответ.
 */
const clearMessages = createAsyncThunk<void, void, { rejectValue: TRejectResponseData }>(
  'connection/clearMessages',
  (data, { dispatch, rejectWithValue }) => {
    return connectionApi
      .clearQuery()
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(String(response.data));
        }
        if (
          GET_QR_CODE_RESPONSE_KEY_NAMES.RESULT in response.data &&
          response.data.result !== CONNECTION_ACTION_RESULTS.CLEAR_MESSAGE_Q
        ) {
          throw new Error(String(response.data));
        }
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(clearMessages()), 200);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

/**
 * СThunk для остановки бота.
 *
 * @param {void} data - Данные запроса (не используются).
 * @throws {Error} - Ошибка, если получен некорректный ответ.
 */
const stopBot = createAsyncThunk<void, void, { rejectValue: TRejectResponseData }>(
  'connection/stopBot',
  (data, { dispatch, rejectWithValue }) => {
    return connectionApi
      .logOutInstance()
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(String(response.data.ok));
        }
        if (
          GET_QR_CODE_RESPONSE_KEY_NAMES.RESULT in response.data &&
          response.data.result !== CONNECTION_ACTION_RESULTS.LOGOUT
        ) {
          throw new Error(String(response.data));
        }
        dispatch(getConnectionPageStatus());

        myTrackerApi.connectionStop();
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(stopBot()), 200);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

/**
 * Thunk для перезапуска бота.
 *
 * @param {void} data - Данные запроса (не используются).
 * @throws {Error} - Ошибка, если получен некорректный ответ.
 */
const restartBot = createAsyncThunk<void, void, { rejectValue: TRejectResponseData }>(
  'connection/restartBot',
  (data, { dispatch, rejectWithValue }) => {
    return connectionApi
      .reloadBot()
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(String(response.data.ok));
        }
        if (
          GET_QR_CODE_RESPONSE_KEY_NAMES.RESULT in response.data &&
          response.data.result !== CONNECTION_ACTION_RESULTS.REBOOT
        ) {
          throw new Error(String(response.data));
        }
        dispatch(getConnectionPageStatus());

        myTrackerApi.connectionReload();
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(restartBot()), 200);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

/**
 * Thunk для получения статуса подключения TG бота.
 *
 * @param {void} data - Данные запроса (не используются).
 * @returns {void} - Данные статуса подключения или undefined.
 * @throws {Error} - Ошибка, если получен некорректный ответ.
 */
const getConnectionTGPageStatusThunk = createAsyncThunk<
  keyof TConnectionStatus | void,
  void,
  { rejectValue: TRejectResponseData }
>('connection/getConnectionTGPageStatusThunk', (data, { dispatch, rejectWithValue }) => {
  return connectionApi
    .getConnectionTGStatus('TG')
    .then(response => {
      if (typeof response.data === 'string') {
        return mapTgStatusToStore(response.data);
      }

      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        dispatch(setSelectedFilialInstanceStatus(CONNECTION_STATUSES.BLOCKED));
        throw new Error(String(response.data));
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getConnectionTGPageStatusThunk()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk для перезапуска канала получения сообщений.
 *
 * @param {void} data - Данные запроса (не используются).
 * @throws {Error} - Ошибка, если получен некорректный ответ.
 */
const restartChanelThunk = createAsyncThunk<
  boolean | void,
  void,
  { rejectValue: TRejectResponseData }
>('connection/restartChanelThunk', (data, { dispatch, rejectWithValue }) => {
  return connectionApi
    .restartChannel('TG')
    .then(response => {
      if (typeof response.data === 'boolean') {
        if (!response.data) {
          throw new Error(String(response.data));
        }
        return response.data;
      }
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.ok));
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(restartChanelThunk()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk для отключения канала получения сообщений.
 *
 * @param {void} data - Данные запроса (не используются).
 * @throws {Error} - Ошибка, если получен некорректный ответ.
 */
const logoutChanelThunk = createAsyncThunk<
  boolean | void,
  void,
  { rejectValue: TRejectResponseData }
>('connection/logoutChanelThunk', (data, { dispatch, rejectWithValue }) => {
  return connectionApi
    .logoutChannel('TG')
    .then(response => {
      if (typeof response.data === 'boolean') {
        return response.data;
      }
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.ok));
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(restartChanelThunk()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk отправляет телефон для получения кода авторизации.
 *
 * @param {string} phone - номер телефона.
 * @throws {Error} - Ошибка, если получен некорректный ответ.
 */
const sendPhoneThunk = createAsyncThunk<
  string | void,
  string,
  { rejectValue: TRejectResponseData }
>('connection/sendPhoneThunk', (phone, { dispatch, rejectWithValue }) => {
  return connectionApi
    .sendPhone(phone)
    .then(response => {
      if (REJECT_RESPONSE_KEY.STATUS in response.data && !response.data?.ok) {
        return response.data.status;
      }
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(sendPhoneThunk(phone)), 200);
      }
      return rejectWithValue(error.response?.data);
    });
});

/**
 * Thunk отправляет код для 2х факторной авторизации.
 *
 * @param {string} authCode - код авторизации.
 * @throws {Error} - Ошибка, если получен некорректный ответ.
 */
const sendAuthCodeThunk = createAsyncThunk<
  string | void,
  string,
  { rejectValue: TRejectResponseData }
>('connection/sendAuthCodeThunk', (authCode, { dispatch, rejectWithValue }) => {
  return connectionApi
    .sendAuthCode(authCode)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data) {
        return response.data.status;
      }
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(restartChanelThunk()), 200);
      }
      return rejectWithValue(error.response?.data);
    });
});

/**
 * Thunk отправляет код для авторизации.
 *
 * @param {string} password - пароль двух этапной авторизации.
 * @throws {Error} - Ошибка, если получен некорректный ответ.
 */
const send2FaPasswordThunk = createAsyncThunk<
  string | void,
  string,
  { rejectValue: TRejectResponseData }
>('connection/send2FaPasswordThunk', (password, { dispatch, rejectWithValue }) => {
  return connectionApi
    .send2FaAuthCode(password)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data) {
        return response.data.status;
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(restartChanelThunk()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk удаляет инстанс TG канала.
 *
 * @throws {Error} - Ошибка, если получен некорректный ответ.
 */
const dropTGInstanceThunk = createAsyncThunk<void, void, { rejectValue: TRejectResponseData }>(
  'connection/dropTGInstanceThunk',
  (data, { dispatch, rejectWithValue }) => {
    return connectionApi
      .dropTGInstance()
      .then(() => {
        // console.log(response);
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(restartChanelThunk()), 200);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

export {
  stopBot,
  getQrData,
  restartBot,
  getAuthCode,
  clearMessages,
  sendPhoneThunk,
  logoutChanelThunk,
  sendAuthCodeThunk,
  restartChanelThunk,
  dropTGInstanceThunk,
  send2FaPasswordThunk,
  getConnectionPageStatus,
  getConnectionTGPageStatusThunk,
};
