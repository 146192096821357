import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { getFileExtensionFromURL } from '@helpers/index';
import videoExample from '@img/chatHistory/videoExample.jpg';
import { TMessageFileData } from '@redux/chatHistory/models';
import { ReactComponent as File } from '@img/chatHistory/file.svg';
import { ReactComponent as Audio } from '@img/chatHistory/audio.svg';
import { ReactComponent as Video } from '@img/chatHistory/video.svg';
import { ReactComponent as Image } from '@img/chatHistory/image.svg';
import { ReactComponent as FileDownload } from '@img/chatHistory/fileDownload.svg';
import { ChatHistoryMediaIconWithModal } from '@components/chatHistoryMediaIconWithModal';

interface IMessageProps {
  /**
   * Строка с url
   * @param {string}
   */
  url: string;
  /**
   * Объект с данными по файлу
   * @param {TMessageFileData}
   */
  fileData: TMessageFileData;
  /**
   * Текст подписи
   * @param{string}
   */
  caption?: string;
  /**
   * Флаг показа подписи, если true, компонент раскрывается на нужную высоту, что бы показать текст подписи
   * @param {boolean}
   */
  isShowCaption: boolean;
  /**
   * Изменяет классы для блока с ссылкой на скачивание файла
   * @param {boolean}
   */
  isMobile: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const ChatHistoryMediaFilePreview = memo(
  ({ url, fileData, isMobile, caption = '', isShowCaption, className = '' }: IMessageProps) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { fileName, isVideo, isImage, isAudio } = getFileExtensionFromURL(url, fileData);

    const isFileNotFound = url === 'File not found';
    const isImageToShow = url && isImage;
    const isVideoToShow = url && isVideo;
    const isAudioToPlay = url && isAudio;
    const ifFileToDownload = url && !isImage && !isVideo && !isAudio;

    const showMediaModalToggle = useCallback(() => {
      setIsOpen(prevState => !prevState);
    }, []);

    return (
      <div className={`flex flex-col grow w-full ${className}`}>
        {isImageToShow ? (
          <ChatHistoryMediaIconWithModal
            isOpen={isOpen}
            caption={caption}
            isMobile={isMobile}
            IconComponent={Image}
            iconExampleSrc={videoExample}
            isShowCaption={isShowCaption}
            showModalToggle={showMediaModalToggle}
            previewText={t('CHAT_HISTORY_PAGE_TEXT.chatHistoryMediaFilePreview.previewTextImage')}>
            <div>
              <img src={url} alt='uploaded' />
              <p className='text-blackText leading-6 tracking-[0.022em] mt-1'>{caption}</p>
            </div>
          </ChatHistoryMediaIconWithModal>
        ) : null}
        {isVideoToShow ? (
          <ChatHistoryMediaIconWithModal
            isOpen={isOpen}
            caption={caption}
            isMobile={isMobile}
            IconComponent={Video}
            iconExampleSrc={videoExample}
            isShowCaption={isShowCaption}
            showModalToggle={showMediaModalToggle}
            previewText={t('CHAT_HISTORY_PAGE_TEXT.chatHistoryMediaFilePreview.previewTextVideo')}>
            <div>
              <video src={url} controls className=''>
                <track src='' kind='captions' srcLang='ru' label='english_captions' />
                {`Sorry, your browser doesn't support embedded videos.`}
              </video>
              <p className='text-blackText leading-6 tracking-[0.022em] mt-1'>{caption}</p>
            </div>
          </ChatHistoryMediaIconWithModal>
        ) : null}
        {isAudioToPlay ? (
          <div className='flex'>
            {!isMobile ? <Audio className='mr-2' /> : null}
            <audio controls src={url} className='custom-audio h-[3rem] max-w-[18.75rem] w-full'>
              <track src={url} kind='captions' srcLang='ru' label='captions' />
              <a href={url}>
                {t('CHAT_HISTORY_PAGE_TEXT.chatHistoryMediaFilePreview.downloadLink')}
              </a>
            </audio>
          </div>
        ) : null}
        {ifFileToDownload ? (
          <div className={`flex ${isMobile ? 'items-start' : 'items-center h-[3rem]'}`}>
            {!isMobile ? <File className='mr-2' /> : null}
            <div className='bg-gray1 border border-quartz rounded-lg flex items-center px-4 py-2 h-[3rem] max-w-[28rem] w-full'>
              {isFileNotFound ? (
                t('CHAT_HISTORY_PAGE_TEXT.chatHistoryMediaFilePreview.fileNotFound')
              ) : (
                <p className='font-medium text-blackText leading-6 tracking-[0.022em] overflow-hidden whitespace-pre w-full'>
                  <span className='mr-[0.4rem]'>
                    {t('CHAT_HISTORY_PAGE_TEXT.chatHistoryMediaFilePreview.file')}
                  </span>
                  <a
                    href={url}
                    download
                    target='_blank'
                    className='underline text-green1'
                    rel='noreferrer'>{`${fileName}`}</a>
                </p>
              )}
              <a href={url} download target='_blank' className='underline' rel='noreferrer'>
                <FileDownload className='ml-2' />
              </a>
            </div>
          </div>
        ) : null}
      </div>
    );
  },
);

ChatHistoryMediaFilePreview.displayName = 'ChatHistoryMediaFilePreview';
