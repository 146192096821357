import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { TSimpleStringObj } from '@models/index';
import { getAppData } from '@redux/app/selectors';
import { TKeyData } from '@redux/template/models';
import { EmojiClickData } from 'emoji-picker-react';
import { DivWithLabel } from '@components/divWithLabel';
import { getSelectionOffset } from '@helpers/selection';
import { PLACEHOLDER_VARIANTS } from '@const/templates';
import { emojiPickerToggle } from '@redux/emoji/emojiSlice';
import { useAppDispatch, useAppSelector } from '@store/store';
import { ReviewsStepsWrapper } from '@blocks/reviewStepsWrapper';
import { addStringEndInPreviewReview, parseDivValue } from '@helpers/index';
import { REVIEW_STEP_TWO_FOUR_KEY_NAMES, TReviewsStep24Data } from '@redux/reviews/models';
import {
  onChange,
  addKeyInTextareaRV,
  setTextareaValueRV,
  setAbandonedTextareaDataRV,
} from '@redux/reviews/reviewsSlice';
import {
  TOnChangeHandler,
  TDivOnKeyUpHandler,
  TDivOnClickHandler,
  TDivOnInputHandler,
  TSetEmojiInTextarea,
  TShowPreviewHandler,
  TSetViewEmojiPicker,
  TOnEmojiViewChangeReview,
} from '@shared/types';

interface IReviewStep24Props {
  /**
   * ID шага
   * @param {number}
   */
  stepId: number;
  /**
   * ID цепочки
   * @param {number}
   */
  chainId: number;
  /**
   * Callback для удаления шага в цепочке
   * @param {() => void}
   */
  deleteStep: () => void;
  /**
   * Имя textarea для добавления ключей или эмодзи
   * @param {string}
   */
  blurFromTextareaName: string;
  /**
   * Данные шага
   * @param {TReviewsStep24Data}
   */
  data: TReviewsStep24Data;
  /**
   * Callback для предпросмотра текста сообщения
   * @param {string}
   */
  showPreviewHandler: TShowPreviewHandler;
  /**
   * Словарь ключей для рендеренга HTML
   * @param {TSimpleStringObj}
   */
  specialKeyDict: TSimpleStringObj;
  /**
   * ref на contenteditable div для передачи в него новых данных
   * @param {React.MutableRefObject<(EventTarget & HTMLDivElement) | null>}
   */
  div: React.MutableRefObject<(EventTarget & HTMLDivElement) | null>;
  /**
   * Данные о расположении спец ключей
   * @param {TKeyData[]}
   */
  textareaKeysData: TKeyData[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const ReviewStep24 = memo(
  ({
    div,
    data,
    stepId,
    chainId,
    deleteStep,
    specialKeyDict,
    textareaKeysData,
    showPreviewHandler,
    blurFromTextareaName,
    className = '',
  }: IReviewStep24Props) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { appLanguage } = useAppSelector(getAppData);

    // Обрабатывает событие change на чекбоксе отключения/включения шага, собирает данные из события
    // создает объект с данными
    const selectOnChangeHandler: TOnChangeHandler = event => {
      const { name, checked } = event.currentTarget;
      dispatch(onChange({ stepId, stepData: [{ ...data, [name]: checked }] }));
    };

    const divOnInputHandler: TDivOnInputHandler = useCallback(
      ({ name, value, selectionEnd, selectionStart, newTextareaKeysData }) => {
        dispatch(
          setTextareaValueRV({
            stepId,
            name: name || '',
            value,
            selectionEnd,
            selectionStart,
            newTextareaKeysData,
          }),
        );
      },
      [stepId, dispatch],
    );

    const divOnClickHandler: TDivOnClickHandler = useCallback(
      event => {
        const {
          dataset: { name },
        } = event.currentTarget;

        const [start, end] = getSelectionOffset(event.currentTarget);

        div.current = event.currentTarget;

        dispatch(
          setAbandonedTextareaDataRV({
            stepId,
            name: name || '',
            selectionStart: start,
            selectionEnd: end,
          }),
        );
      },
      [div, dispatch, stepId],
    );

    const setCursorPositionHandler: TDivOnKeyUpHandler = useCallback(
      event => {
        const {
          dataset: { name },
        } = event.currentTarget;

        const [start, end] = getSelectionOffset(event.currentTarget);

        div.current = event.currentTarget;

        dispatch(
          setAbandonedTextareaDataRV({
            name: name || '',
            stepId,
            selectionStart: start,
            selectionEnd: end,
          }),
        );
      },
      [div, stepId, dispatch],
    );

    const setEmojiInTextarea: TSetEmojiInTextarea = useCallback(
      (emoji: EmojiClickData) => {
        if (blurFromTextareaName && div.current) {
          dispatch(
            addKeyInTextareaRV({ innerText: emoji.emoji, callback: parseDivValue(div.current) }),
          );
          div.current.focus();
        }
      },
      [div, blurFromTextareaName, dispatch],
    );

    const onEmojiViewChange: TOnEmojiViewChangeReview = useCallback(
      ({ textAreaName, textAreaValue, stepIdEmoji }) =>
        () => {
          dispatch(
            setAbandonedTextareaDataRV({
              name: textAreaName,
              stepId: stepIdEmoji,
              selectionStart: textAreaValue.length,
              selectionEnd: textAreaValue.length,
            }),
          );
        },
      [dispatch],
    );

    const setViewEmojiPicker: TSetViewEmojiPicker = ({ name, divRef, isOpenEmoji }) => {
      if (divRef) {
        div.current = divRef;
      }

      dispatch(emojiPickerToggle({ isOpen: isOpenEmoji, name, stepIdEmoji: String(stepId) }));
    };

    const memoizedShowSecondStepPreviewButton = useMemo(
      () => (
        <Button
          dense
          type='default'
          view='outlined'
          color='default'
          rightIcon='application'
          text={t('REVIEW_PAGE_TEXT.REVIEW_BUTTON_TEXT.preview')}
          onClick={showPreviewHandler(addStringEndInPreviewReview(data.whatsapp))}
          className='w-[11rem]'
        />
      ),
      [t, showPreviewHandler, data],
    );

    return (
      <ReviewsStepsWrapper
        toggleSwitchName={REVIEW_STEP_TWO_FOUR_KEY_NAMES.STATUS}
        isDeleteButton
        stepId={stepId}
        chainId={chainId}
        status={data.status}
        className={className}
        deleteStep={deleteStep}
        onChangeHandler={selectOnChangeHandler}>
        <DivWithLabel
          isEmoji
          isRequired
          value={data.whatsapp}
          stepId={String(stepId)}
          isError={!data.whatsapp}
          specialKeyDict={specialKeyDict}
          textareaKeysData={textareaKeysData}
          divOnInputHandler={divOnInputHandler}
          divOnClickHandler={divOnClickHandler}
          setEmojiInTextarea={setEmojiInTextarea}
          setViewEmojiPicker={setViewEmojiPicker}
          setCursorPosition={setCursorPositionHandler}
          htmlFor={REVIEW_STEP_TWO_FOUR_KEY_NAMES.WHATSAPP}
          showEmojiPicker={onEmojiViewChange({
            textAreaName: REVIEW_STEP_TWO_FOUR_KEY_NAMES.WHATSAPP,
            stepIdEmoji: stepId,
            textAreaValue: data.whatsapp,
          })}
          placeholder={PLACEHOLDER_VARIANTS[appLanguage].whatsapp24}
          className='mb-4'
        />
        {memoizedShowSecondStepPreviewButton}
      </ReviewsStepsWrapper>
    );
  },
);

ReviewStep24.displayName = 'ReviewStep24';
