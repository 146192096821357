import React, { forwardRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import ComponentToast from './Toast';
import ComponentToaster from './Toaster';
import { IToastProps } from '@blueprintjs/core/lib/esm/components/toast/toast';

const propTypes = {
  /**
   ` The view of the component.
   * Variants: `filled` `smooth` `outlined` `raised`
   * Default value (if undefined): `filled` `
   */
  view: PropTypes.oneOf(["filled", "smooth", "outlined", "raised"]),
  /**
   ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    "default",
    "primary",
    "warning",
    "danger",
    "success",
    "primary_alt",
    "warning_alt",
    "danger_alt",
    "success_alt"
  ]),
  /**
   * Show or not Close button
   */
  withoutClose: PropTypes.bool,
  /**
   *Position of Toaster within its container.
   */
  position: PropTypes.oneOf([
    "top",
    "top-left",
    "top-right",
    "bottom",
    "bottom-left",
    "bottom-right"
  ]),
  /**
   * Whether the toaster should be rendered into a new element attached to document.body.
   * If false, then positioning will be relative to the parent element.
   */
  usePortal: PropTypes.bool,
  /**
   *
   */
  setRef: PropTypes.func
};

type TComponentProps = InferProps<typeof propTypes>

interface IComponentProps extends TComponentProps {
  [key: string]: any;
}

export const Toast: React.FC<IComponentProps> = forwardRef((props, ref) => <ComponentToast {...props} ref={ref} />)

export const Toaster: React.FC<IComponentProps> = forwardRef((props, ref) => <ComponentToaster {...props} ref={ref} />)
