export enum MY_TRACKER_END_POINT_URL {
  START_CONNECT_REQUEST = '/metrics/start_connect_request', //
  AUTOCONNECT_BUTTON = '/metrics/autoconnect_button', //
  AUTOCONNECT_PAGE_OPEN = '/metrics/autoconnect_page_opened', //
  LK_LOGIN = '/metrics/lk_login', //
  CONNECT_OPEN = '/metrics/????',
  CONNECT_QR = '/metrics/connect_qr',
  CONNECT_QR_OPEN = '/metrics/connect_qr_open',
  CONNECT_QE_ERROR = '/metrics/connect_qr_error',
  CONNECT_STOP = '/metrics/connect_stop',
  CONNECT_RELOAD = '/metrics/connect_reload',
  SEND_CUSTOM_EVENT = '/metrics/send_custom_event',
  OPEN_TEMPLATE_ALL = '/metrics/open_template_all',
  OPEN_TEMPLATE_EVENT = '/metrics/open_template_event',
  OPEN_TEMPLATE_TIME = '/metrics/open_template_time',
  OPEN_TEMPLATE_CHAIN = '/metrics/open_template_chain',
  VIEW_TEMPLATE_PAGE = '/metrics/view_templates_page',
  VIEW_TEMPLATE_STATS = '/metrics/view_template_stats',
  OPEN_TEMPLATE = '/metrics/open_template',
  TEMPLATE_UPDATE = '/metrics/template_update',
  TEMPLATE_CREATE = '/metrics/template_create',
  SENDOUT_START = '/metrics/sendout_start',
  SAVE_TEMPLATE_CHANGES = '/metrics/save_template_changes',
  TOGGLE_TEMPLATE_ON = '/metrics/toggle_template_on',
  TOGGLE_TEMPLATE_OFF = '/metrics/toggle_template_off',
  OPEN_NEW_TEMPLATE = '/metrics/open_new_template',
  SAVE_NEW_TEMPLATE = '/metrics/save_new_template',
  SAVE_TEMPLATE_CHAIN = '/metrics/save_template_chain',
  OPEN_NEW_REVIEW = '/metrics/????',
  OPEN_SENDOUT_PAGE = '/metrics/open_sendout_page',
  DOWNLOAD_TEMPLATE = '/metrics/download_template',
  IMPORT_SENDOUT_LIST = '/metrics/import_sendout_list',
  SENDOU_LIST_ERROR = '/metrics/sendout_list_error',
  SENDOU_SECOND_STEP = '/metrics/????',
  PREVIEW_SENDOUT = '/metrics/preview_sendout',
  SHEDULE_SENDOUT = '/metrics/schedule_sendout',
  SELECT_BLACKLIST = '/metrics/select_blacklist',
  SENDOUT_ADVANCED_STATS = '/metrics/sendout_advanced_stats',
  EXPORT_SENDOUT_STATS = '/metrics/export_sendout_stats',
  INVITE_FRIENDS_NEW_ACTION_PAGE_OPEN = '/metrics/friend_new_promo_open',
  INVITE_FRIENDS_NEW_ACTION_CREATE = '/metrics/friend_promo_create',
  INVITE_FRIENDS_STOP_ACTION = '/metrics/friend_promo_stop',
  INVITE_FRIENDS_READ_MORE = '/metrics/friend_read',
  INVITE_FRIENDS_VIDEO_OPEN = '/metrics/friend_video',
}

export type TMyTrackerEventData = {
  eventName: string;
  eventProperties: {
    interface: string;
    branch_id?: string;
    template_id?: string;
    total?: number;
    media_file?: boolean;
    visits_median?: number;
    last_visit_time_median?: number;
  };
};

export type TCreateTemplateEventData = {
  sample_name: string;
  sample_trigger: string;
  sample_text: string;
  sample_condition: Record<string, any>;
  sample_black: boolean;
  sample_online: boolean;
  sample_confirm: boolean;
  sample_media: boolean;
};

export type TEditTemplateEventData = {
  sample_on: boolean;
  sample_off: boolean;
  sample_name: string;
  sample_trigger: string;
  sample_text: string;
  sample_condition: Record<string, any>;
  sample_black: boolean;
  sample_online: boolean;
};

export type TCommonEventData = {
  event_name: string;
  user_id: string;
  event_properties: object;
};

export type TEventFullErrorData = {
  user_id: string;
  branch_id: string;
  error_code: string;
};

export type TEventErrorData = {
  error_code: string;
};

export type TSampleSource = 'all' | 'event' | 'time';

export type TTemplateEventData = {
  sample_id: string;
  sample_status: boolean;
  sample_source: TSampleSource;
};

export type TReviewEventData = {
  user_id: string;
  chain_id: string;
  chain_steps: number;
};

export type TSendoutEventData = {
  total: number;
  media_file: boolean;
  visits_median: number;
  last_visit_time_median: number;
  sendout_date: string;
  error_code: string;
};

export type TPromoSource = 'promo' | 'stat';

export type TInviteFriendsNewPageOpenEvent = {
  promo_source: TPromoSource;
};

export type TPaymentType = 'cash' | 'point';

export type TInviteFriendsNewActionCreate = {
  payment_type: TPaymentType;
  sample_id: string;
};

export type TInviteFriendsActionStop = {
  sample_id: string;
};

export enum MY_TRACKER_EVENTS {
  LK_LOGIN = 'login',
  CONNECT_OPEN = 'Connect_open',
  CONNECTION_STOP = 'Connect_stop',
  CONNECTION_RELOAD = 'Connect_reload',
  CONNECTION_VIDEO_INSTRUCTION_VIEW = 'Connect_video',
  OPEN_ALL_TEMPLATES_LIST = 'Sample_open_all',
  OPEN_EVENT_TEMPLATES_LIST = 'Sample_open_event',
  OPEN_TIME_TEMPLATES_LIST = 'Sample_open_time',
  OPEN_REVIEW_LIST_TEMPLATES = 'Sample_open_chain',
  OPEN_TEMPLATES_STATS = 'Sample_open_stat',
  OPEN_NEW_TEMPLATE = 'Sample_new_open',
  CREATE_NEW_TEMPLATE = 'Sample_new_done',
  OPEN_NEW_REVIEW_PAGE = 'Sample_chain_create',
  SAVE_NEW_REVIEW = 'Sample_chain_create_done',
  OPEN_TEMPLATE = '',
  TEMPLATE_STATS_ON_TIME_BUTTON = 'Sample_stat_time',
  TEMPLATE_STATS_ON_VERSION_BUTTON = 'Sample_stat_version',
  TEMPLATE_DETAIL_STATS = 'Sample_stat_version', // Есть такая кнопка?
  TEMPLATE_STATS_EXPORT_TO_TG = 'Sample_stat_advance',
  OPEN_SENDOUT_PAGE = 'Spam_new_open',
  SENDOUT_EXAMPLE_TEMPLATE_DOWNLOAD = 'Spam_sample_download',
  SENDOUT_SECOND_STEP = 'Spam_gostep2',
  SENDOUT_THIRD_STEP = 'Spam_gostep3',
  SENDOUT_HINT_VIDEO = 'Spam_hint_video',
  SENDOUT_HINT_TEXT = 'Spam_hint_text',
  SENDOUT_HINT_EXAMPLE = 'Spam_hint_example',
  SENDOUT_TEXT_TYPE = 'Spam_text',
  SENDOUT_EMOJI_ADD = 'Spam_emoji',
  SENDOUT_PREVIEW = 'Spam_preview', //
  SENDOUT_MEDIA_FILE_ADD = 'Spam_media',
  SENDOUT_MEDIA_FILE_ERROR = 'Spam_media_error',
  SENDOUT_SEND_DATE_SELECT = 'Spam_date',
  SENDOUT_STARTED_TABLE = 'Spam_active_open',
  SENDOUT_STOP = 'Spam_active_stop',
  SENDOUT_STATISTICS_OPEN = 'Spam_stat',
  TEMPLATE_UPDATE = 'template_update',
  TEMPLATE_CREATE = 'template_create',
  SEND_TEMPLATE_STATISTICS_TO_TG = 'Sample_stat_export',
  SENDOUT_START = 'Spam_go',
  START_CONNECT_REQUEST = 'start_connect_request',
  SENDOUT_XLSX_FILE_UPLOAD = 'Smap_list_import',
  SENDOUT_XLSX_FILE_UPLOAD_ERROR = 'Spam_list_error',
  SENDOUT_PREVIEW_OPEN = 'Spam_preview',
  SENDOUT_DETAIL_STATISTICS_OPEN = 'Spam_stat_advance',
  SENDOUT_SEARCH_IN_STATISTICS = 'Spam_stat_search',
  SENDOUT_PERIOD_SET_IN_STATISTICS = 'Spam_stat_period',
  SENDOUT_SORT_IN_STATISTICS = 'Spam_stat_sort',
  MANUAL_CONNECTION = 'autoconnect_button',
  AUTOCONNECT_PAGE_OPENED = 'autoconnect_page_openned',
  INVITE_FRIEND_NEW_PAGE_OPEN = 'Friend_new_promo_open',
  INVITE_FRIEND_NEW_ACTION_CREATE = 'Friend_promo_create',
  INVITE_FRIEND_READ_MORE = 'Friend_read',
  INVITE_FRIEND_VIDEO_INSTRUCTION = 'Friend_video',
}

export const MY_TRACKER_INTERFACE = 'lk';
