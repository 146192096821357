import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Icon } from '@atoms/icon';
import { FileNameBlock } from '@blocks/fileNameBlock';
import { FILE_TYPES, TMediaFileType } from '@models/index';

interface IMediaPreviewProps {
  /**
   * Полная ссылка на файл
   * @param {string}
   */
  fullFileName?: string;
  /**
   * Имя загруженного файла
   * @param {string}
   */
  fileName: string;
  /**
   * Тип загруженного файла
   * @param {TMediaFileType}
   */
  fileType: TMediaFileType;
  /**
   * URL загруженного файла, используется для отображения картинок и видео
   * @param {string | null}
   */
  fileURL: string | null;
  /**
   * Флаг ошибки, если true отобразится сообщение о превышение размера загруженного файла
   * @param {boolean}
   */
  isFileToBig: boolean;
  /**
   * Callback для инпута с файлом
   * @param {() => void}
   */
  clearInputCallback: () => void;
  /**
   * Флаг изменяет внешний вид блока для отображения в превью сообщения/рассылки
   * @param {boolean}
   */
  isMessageStyle?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const MediaPreview = memo(
  ({
    fileURL,
    fileName,
    fileType,
    isFileToBig,
    isMessageStyle,
    fullFileName = '',
    clearInputCallback,
    className = '',
  }: IMediaPreviewProps) => {
    const { t } = useTranslation();

    return (
      <div className={className}>
        {fileType !== FILE_TYPES.UNKNOWN && fileURL ? (
          <div
            className={`relative ${
              isMessageStyle ? '' : 'shadow-[0_0.5rem_1rem_rgb(0,0,0,0.15)]'
            } w-fit ${className}`}>
            {fileType === FILE_TYPES.IMAGE ? (
              <img src={fileURL} className='!h-[16.25rem]' alt='preview' />
            ) : null}
            {fileType === FILE_TYPES.VIDEO ? (
              <video src={fileURL} controls className='!h-[16.25rem]'>
                <track src='' kind='captions' srcLang='ru' label='english_captions' />
                {`Sorry, your browser doesn't support embedded videos.`}
              </video>
            ) : null}
            {!isMessageStyle ? (
              <button
                onClick={clearInputCallback}
                className='absolute top-[0.375rem] right-[0.375rem] bg-none m-0 p-2 '>
                <Icon
                  variant='deleteFile'
                  className='!h-[2.5rem] !w-[2.5rem] rounded-full hover:shadow-[0_0_0.375rem_rgb(128,128,128)]'
                />
              </button>
            ) : null}
          </div>
        ) : null}
        {fileType === FILE_TYPES.DOC && !fileURL ? (
          <FileNameBlock
            clientFileName={fileName}
            fullFileName={fullFileName}
            isMessageStyle={isMessageStyle}
            clearClientFileHandler={clearInputCallback}
          />
        ) : null}
        {isFileToBig ? (
          <p className='text-auChico text-[0.75rem] leading-5 tracking-[0.033em]'>
            {t('TEMPLATE_PAGE_TEXT.mediaFileSizeError')}
          </p>
        ) : null}
      </div>
    );
  },
);

MediaPreview.displayName = 'MediaPreview';
