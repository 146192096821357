import React, { memo, useEffect } from 'react';
//
import { Loader } from '@blocks/loader';
import { NoAccess } from '@components/noAccess';
import { ErrorMessage } from '@blocks/errorMessage';
import { AccountsTemplate } from '@templates/accounts';
import { ACCOUNTS_TOAST_MESSAGES } from '@const/accounts';
import { getAccountsData } from '@redux/accounts/selectors';
import { ToastsMessages } from '@components/toastsMessages';
import { getAccounts } from '@redux/accounts/accountsThunks';
import { useAppDispatch, useAppSelector } from '@store/store';
import { hideNoFilialError } from '@redux/accounts/accountsSlice';

const AccountsPage = memo(() => {
  // получаем диспатч из хука
  const dispatch = useAppDispatch();

  // получаем данные из store
  const { data, isError, isLoading, isRetries, isAccessDenied, isNoFilialError } =
    useAppSelector(getAccountsData);

  // При первой загрузке страницы запрашиваем список филиалов
  useEffect(() => {
    if (!isRetries) {
      dispatch(getAccounts({ checkStatus: true }));
    }
  }, [dispatch, isRetries]);

  // Запрашивает список филиалов повторно при ошибке токена
  useEffect(() => {
    if (isRetries) {
      setTimeout(() => dispatch(getAccounts({ checkStatus: true })), 100);
    }
  }, [dispatch, isRetries]);

  const clearErrorHandler = () => {
    dispatch(hideNoFilialError());
  };

  return (
    <>
      {isLoading ? <Loader className='h-[50vh]' /> : null}
      {!isLoading && !isError && !isAccessDenied ? (
        <AccountsTemplate data={data || []} className='px-4 pb-8 sm:px-[1.5rem]' />
      ) : null}
      {isAccessDenied && !isLoading ? <NoAccess /> : null}
      {!isAccessDenied && isError ? <ErrorMessage /> : null}
      <ToastsMessages
        successMessage=''
        isShowSuccessToast={false}
        isShowErrorToast={isNoFilialError}
        clearErrorCallback={clearErrorHandler}
        errorMessage={ACCOUNTS_TOAST_MESSAGES.FILIAL_ERROR}
      />
    </>
  );
});

AccountsPage.displayName = 'AccountsPage';

export default AccountsPage;
