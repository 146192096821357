import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { TSimpleStringObj } from '@models/index';
import { QUERY_PARAMS } from '@const/apiConstants';
import { NavDropDown } from '@components/navDropDown';
import { NavItem } from '@blocks/templateNavItem';

type TNavComponentProps = {
  /**
   * Путь из URL полученный хуком useLocation в родительском компоненте
   * @param {string}
   */
  path: string;
  /**
   * Объект с данными для формирования ссылок на страницы
   * @param {TSimpleStringObj}
   */
  pagesNames: TSimpleStringObj;
  /**
   * Id выбранного филиала используется для формирования ссылок
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Параметр для отключения кнопки
   * @param {boolean}
   */
  isDisable: boolean;
  /**
   * Callback на кнопку
   * @param {() => void}
   */
  addNewActionTemplate: () => void;
  /**
   * Определяет доступ пользователя для редактирования шаблонов.
   * @param {boolean}
   */
  isEditTemplatesAccess?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const NavComponent = memo(
  ({
    path,
    isDisable,
    pagesNames,
    selectedFilialAccId,
    addNewActionTemplate,
    isEditTemplatesAccess,
    className = '',
  }: TNavComponentProps) => {
    const { t } = useTranslation();

    const pageNamesArray = Object.entries(pagesNames);

    const linksToRender = pageNamesArray.map(([link, text], index) => {
      const isLastElement = pageNamesArray.length === index - 1;
      const fullLink = `${link}?${QUERY_PARAMS.ACC_ID}=${selectedFilialAccId}`;

      return (
        <NavItem
          key={text}
          text={text}
          to={fullLink}
          isActive={path.includes(link)}
          className={`mb-4 ${!isLastElement ? 'mr-2' : ''}`}
        />
      );
    });

    return (
      <div className={`flex flex-wrap sm:flex-nowrap justify-between ${className}`}>
        <div className='mb-4 lg:hidden sm:max-w-[20rem] w-full'>
          <NavDropDown
            pageNameDict={pagesNames}
            selectedFilialAccId={selectedFilialAccId}
            className='nav_dropdown w-full'
          />
        </div>
        <div className='flex flex-wrap lg:flex hidden'>{linksToRender}</div>
        {isEditTemplatesAccess ? (
          <Button
            dense
            icon='plus'
            type='action'
            view='filled'
            color='success'
            disabled={isDisable}
            onClick={addNewActionTemplate}
            text={t('INVITE_FRIEND_PAGE_TEXT.createAction')}
            className='min-w-[12rem] w-full sm:w-auto'
          />
        ) : null}
      </div>
    );
  },
);

NavComponent.displayName = 'NavComponent';
