import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Icon } from '@blueprintjs/core';
import { THelpKeys } from '@models/index';
import { storageDb } from '@api/storageApi';
import { useAppDispatch } from '@store/store';
import { showAppDrawer } from '@redux/app/slice';
import { FirstTooltip } from '@blocks/firstTooltip';

interface IIconWithTooltipsProps {
  /**
   * Параметр для выбора из словаря подсказок текста подсказки
   * @param {THelpKeys}
   */
  tooltips: THelpKeys;
  /**
   * Title для иконки с подсказкой
   * @param {string}
   */
  iconTitle?: string;
  /**
   * Флаг для сокрытия иконки с подсказкой
   * @param {boolean}
   */
  noIcon?: boolean;
  /**
   * Опциональный параметр отображает обучающую подсказку
   * @param {boolean}
   */
  isFirstTooltip?: boolean;
  /**
   * Флаг для переключения цвета иконки на красный
   * @param {boolean}
   */
  isRed?: boolean;
  /**
   * Опциональный параметр цвет иконки
   * @param {string}
   */
  iconColor?: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const IconWithDrawer = memo(
  ({
    isRed,
    noIcon,
    tooltips,
    iconColor,
    iconTitle,
    isFirstTooltip,
    className = '',
  }: IIconWithTooltipsProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    // Проверяем содержит ли текст подсказки ключевое слово RED
    const isRedTooltip = t(`TOOLTIPS_TEXT.${tooltips}`).includes('RED');

    const [isViewTooltip, setIsViewTooltip] = useState<{ tooltip: string }>({ tooltip: 'false' });

    useEffect(() => {
      if (isFirstTooltip) {
        const tooltipStatus = storageDb.getTooltipStatus();

        if (tooltipStatus.tooltip !== isViewTooltip.tooltip) {
          setIsViewTooltip(storageDb.getTooltipStatus());
        }
      }
    }, [isViewTooltip.tooltip, isFirstTooltip]);

    const hintText = isRedTooltip
      ? t(`TOOLTIPS_TEXT.${tooltips}`).slice(0, -4)
      : t(`TOOLTIPS_TEXT.${tooltips}`);

    const hideTooltipHandler = () => {
      storageDb.setTooltipStatus('false');
      setIsViewTooltip({ tooltip: 'false' });
    };

    const showAppDrawerHandler = () => {
      dispatch(
        showAppDrawer({
          title: t(`LABEL_TEXT.${tooltips}`),
          description: hintText,
          isMarkdown: true,
        }),
      );
    };

    return (
      <div className='relative flex items-center justify-center'>
        {noIcon ? null : (
          <div onClick={showAppDrawerHandler} className='cursor-pointer flex items-center'>
            <Icon
              icon='info-sign'
              color={isRed ? 'red' : iconColor || '#43A047'}
              className={`mr-[0.375rem] ${className}`}
            />
            <span className='font-medium text-green1 text-tooltips leading-6 tracking-[0.018em]'>
              {iconTitle || t('TOOLTIPS_TEXT.tooltipTitle')}
            </span>
          </div>
        )}
        {isFirstTooltip && !isViewTooltip.tooltip.includes('false') ? (
          <FirstTooltip
            bodyText={t('TOOLTIPS_TEXT.firstTooltipText')}
            color='default'
            view='filled'
            className='top-0 left-0 translate-y-[-111%] translate-x-[-49.8%] absolute w-[334px] z-[999]'
            callback={hideTooltipHandler}
          />
        ) : null}
      </div>
    );
  },
);
