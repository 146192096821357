import React, {forwardRef} from 'react';

import Component from './ButtonGroup';
import PropTypes, {InferProps} from 'prop-types';

const propTypes = {
  /**
   `The type of the component.
   * Variants: `default` `dense`
   * Default value (if undefined): `default` `
   */
  type: PropTypes.oneOf(["default", "dense"]),
  /**
   ` The view of the component.
   * Variants: `flat` `smooth` `outlined` `raised`
   * Default value (if undefined): `filled` `
   */
  view: PropTypes.oneOf(["filled", "flat", "smooth", "outlined", "raised"]),
  /**
   ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    "default",
    "primary",
    "warning",
    "danger",
    "success",
    "primary_alt",
    "warning_alt",
    "danger_alt",
    "success_alt"
  ]),
  /**
   * Click event handler.
   */
  onClick: PropTypes.func,
  /**
   * Whether this component should expand to fill its container.
   */
  fill: PropTypes.bool,
  /**
   * Name of a Blueprint UI icon (or an icon element) to render before the text.
   */
  icon: PropTypes.any,
  /**
   * Name of a Blueprint UI icon (or an icon element) to render after the text.
   */
  rightIcon: PropTypes.any,
  className: PropTypes.string
};

type TComponentPropType = InferProps<typeof propTypes>;

interface IComponentPops extends TComponentPropType {
  [key: string]: any
}

export const ButtonGroup: React.FC<IComponentPops> = forwardRef((props, ref) => <Component {...props} ref={ref} />);