import React, { memo } from 'react';
//
import { TAppLanguage } from '@models/index';
import { getStatusText } from '@helpers/index';
import { ConnectionStatusWithText } from '@blocks/connectionStatusWithText';
import { connectionStatusFallback, connectionStatusText } from '@const/connect';

type TConnectionStatusTgBotProps = {
  /**
   * Ссылка на картинку
   * @param {string}
   */
  iconSrc: string;
  /**
   * Текст заголовка
   * @param {string}
   */
  headerText: string;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Статус бота
   * @param {string}
   */
  status: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const ChannelConnectionStatus = memo(
  ({ status, iconSrc, headerText, appLanguage, className = '' }: TConnectionStatusTgBotProps) => {
    return (
      <div className={`flex flex-col ${className}`}>
        <div className='flex items-center'>
          <img
            src={iconSrc}
            alt='channel'
            height={28}
            width={28}
            className='mr-3 h-[1.75rem] w-[1.75rem]'
          />
          <span className='font-medium text-blackText leading-6 tracking-[0.0075em]'>
            {headerText}
          </span>
        </div>
        <div className='mt-1'>
          <ConnectionStatusWithText
            status={status}
            statusText={getStatusText({
              status,
              appLanguage,
              connectionStatusText,
              connectionStatusFallback,
            })}
          />
        </div>
      </div>
    );
  },
);

ChannelConnectionStatus.displayName = 'ConnectionStatusTgBot';
