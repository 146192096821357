import { Fragment, memo, useMemo } from 'react';
import Markdown from 'react-markdown';
//
import { Drawer } from '@uikit/Drawer';
import { useAppSelector } from '@store/store';
import { getAppData } from '@redux/app/selectors';
import { useWindowWidth } from '@hooks/useWindowWidth';

import './style.scss';

type TAppDrawerProps = {
  /**
   * Закрывает открытый drawer
   * @param {() => void}
   */
  onCloseHandler: () => void;
};
export const AppDrawer = memo(({ onCloseHandler }: TAppDrawerProps) => {
  const width = useWindowWidth();

  const {
    showAppDrawer,
    appDrawerData: { title, description, isMarkdown },
  } = useAppSelector(getAppData);

  const replaceNewlinesWithBrTags = useMemo(
    () => (text: string) => {
      const lines = text.split('\n');

      return lines.map((line, index) => (
        <Fragment key={line}>
          {line}
          {index !== lines.length - 1 && <br />}
        </Fragment>
      ));
    },
    [],
  );

  const renderTitle = useMemo(() => {
    return <span className='text-h4 font-bold text-black tracking-[0.15px]'>{title}</span>;
  }, [title]);

  return (
    <div>
      {showAppDrawer ? (
        <Drawer
          type='icon'
          position='right'
          isCloseButtonShown
          title={renderTitle}
          isOpen={showAppDrawer}
          onClose={onCloseHandler}
          size={width < 780 ? '100%' : 540}>
          <div className='flex flex-col px-3 py-6 overflow-y-auto'>
            {isMarkdown ? (
              <Markdown className='md-wrapper'>{description}</Markdown>
            ) : (
              <p className='mb-6 text-black text-body_text font-normal tracking-[0.44px]'>
                {replaceNewlinesWithBrTags(description)}
              </p>
            )}
          </div>
        </Drawer>
      ) : null}
    </div>
  );
});

AppDrawer.displayName = 'AppDrawer';
