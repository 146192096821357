export const YCLIENTS_START_PAGE_TEXT = {
  title: 'Бьюти Бот',
  subTitle: 'повышает оборот',
  header: 'Добро пожаловать в Бьюти Бот!',
  description1: 'Мы уже нашли вам',
  description2: 'Персонального менеджера🍒.',
  description3:
    '\nНаш специалист👱🏽‍♀️ всё настроит и поможет запустить рассылки, а в конце бесплатного периода, посчитает ваш дополнительный доход за первую неделю 🤝 🚀 ⏬',
  name: 'Имя',
  namePlaceholder: 'Как к вам обращаться?',
  nameError: 'Введите ваше имя',
  phone: 'Ваш номер для связи',
  phonePlaceholder: 'Введите ваш номер WhatsApp',
  phoneError: 'Введите номер телефона для связи',
  radioButtonLabel: 'Где с вами лучше связаться?',
  whatsAppRadioButton: 'В WhatsApp',
  phoneRadioButton: 'По телефону',
  buttonText: 'Связаться со специалистом',
  linkText: 'или подключить самостоятельно',
  thanks: 'Спасибо! 👌',
  successMessage: 'Ваша заявка принята, с Вами свяжется наш специалист.',
  closeButton: 'Закрыть',
};

export const YCLIENTS_START_PAGE_TEXT_ENG = {
  title: 'Beauty Bot',
  subTitle: 'increases turnover',
  header: 'Welcome to Beauty Bot!',
  description1: "We've already found you",
  description2: 'A personal manager🍒',
  description3:
    'Our specialist👱🏽‍♀️ will set everything up and help launch mailings, and at the end of the free period, will calculate your additional income for the first week 🤝 🚀 ⏬',
  name: 'Name',
  namePlaceholder: 'How should I contact you?',
  nameError: 'Enter your name',
  phone: 'Your contact number',
  phonePlaceholder: 'Enter your WhatsApp number',
  phoneError: 'Enter a telephone number to contact',
  radioButtonLabel: 'Where is the best place to contact you?',
  whatsAppRadioButton: 'On WhatsApp',
  phoneRadioButton: 'On the phone',
  buttonText: 'Contact a specialist',
  linkText: 'or connect yourself',
  thanks: 'Thank you! 👌',
  successMessage: 'Your application has been accepted, our specialist will contact you.',
  closeButton: 'Close',
};
