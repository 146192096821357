import React, { memo } from 'react';
//
import { THelpKeys } from '@models/index';
import { ToggleSwitch } from '@blocks/toggleSwitch';
import { TSimpleOnchangeHandler } from '@shared/types';

interface ITemplateRadioSelectProps {
  /**
   * Значение параметра name для переключателя
   * @param {THelpKeys}
   */
  htmlFor: THelpKeys;
  /**
   * Значение переключателя
   * @param {boolean}
   */
  value: boolean;
  /**
   * Текст заголовка
   * @param {string}
   */
  headerText: string;
  /**
   * Текст "описания"
   * @param {string}
   */
  descriptionText: string;
  /**
   * Callback для обработки события onChange для переключателя
   * @param {TSimpleOnchangeHandler}
   */
  onChangeHandler: TSimpleOnchangeHandler;
  /**
   * Опциональный параметр позволяет отключить кнопку
   * @param {boolean}
   */
  disabled?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const TemplateToggleSwitch = memo(
  ({
    htmlFor,
    value,
    headerText,
    descriptionText,
    onChangeHandler,
    className = '',
    disabled = false,
  }: ITemplateRadioSelectProps) => (
    <div className={`flex items-center ${className}`}>
      <div className='flex items-center'>
        <ToggleSwitch
          name={htmlFor}
          checked={value}
          onChangeHandler={onChangeHandler}
          className='mr-6'
          disabled={disabled}
        />
        <div>
          <p className='font-medium text-body_text text-blackText tracking-[0.0275em]'>
            {headerText}
          </p>
          <p className='text-tooltips leading-[1.3rem] text-textareaBlack tracking-[0.018em]'>
            {descriptionText}
          </p>
        </div>
      </div>
    </div>
  ),
);

TemplateToggleSwitch.displayName = 'TemplateToggleSwitch';
