import React from 'react';
import { useTranslation } from 'react-i18next';
//
import { Spinner } from '@uikit/Spinner';

type TLoaderProps = {
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const Loader = ({ className = '' }: TLoaderProps) => {
  const { t } = useTranslation();

  return (
    <div className={`flex items-center justify-center ${className}`}>
      <Spinner color='default' size={40} />
      <p className='ml-4 my-0'>{t('LOADER_PAGE_TEXT.loading')}</p>
    </div>
  );
};
