import { PATH_NAMES } from '@data/dict';
import { TAppLanguage, TSimpleStringObj } from '@models/index';

export const PARTNERS_PAGES_NAME_DICT: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    [PATH_NAMES.PARTNERS_PROGRAM]: 'Terms and partners',
    [PATH_NAMES.LIST_OF_OPERATION]: 'List of operations',
    [PATH_NAMES.PAYMENT_HISTORY]: 'Payment History',
    [PATH_NAMES.ADD_LEADS]: 'Adding leads',
  },
  rus: {
    [PATH_NAMES.PARTNERS_PROGRAM]: 'Условия и партнёры',
    [PATH_NAMES.LIST_OF_OPERATION]: 'Список операций',
    [PATH_NAMES.PAYMENT_HISTORY]: 'История выплат',
    [PATH_NAMES.ADD_LEADS]: 'Добавление лидов',
  },
};

export const PARTNERS_LIST_STATUSES: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    ON_TEST: 'On test',
    PAID: 'Accrued',
    NOT_PAID: 'Not accrued',
  },
  rus: {
    ON_TEST: 'На тесте',
    PAID: 'Начислено',
    NOT_PAID: 'Не начислено',
  },
};

export const CONSULTANT_OPERATION_LIST_STATUSES: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    PAID: 'Accrued',
    IN_PROGRESS: 'Accrual',
    NOT_PAID: 'Not paid',
    NOT_NEW_PAY: 'No new payments',
    LEAD: 'lead',
  },
  rus: {
    PAID: 'Начислено',
    IN_PROGRESS: 'Начисление',
    NOT_PAID: 'Не оплачен',
    NOT_NEW_PAY: 'Нет новых оплат',
    LEAD: 'lead',
  },
};

export const PARTNER_FULL_TABLE_FIRST_PAY_DATE_VALUE = {
  NOT_PAID: 'Не оплачен',
  ON_TEST: 'На тестовом',
};

export const partnerFullTableFirstPayValuesArray = Object.values(
  PARTNER_FULL_TABLE_FIRST_PAY_DATE_VALUE,
);

export const monthsName = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const PARTNER_PAGE_TEXT = {
  header: 'Партнерская программа',
  partnerProgramTemplate: {
    header: 'Партнёрские условия',
    bonus: 'Бонус за приглашенный салон — 1 месяц, начисляется после первой оплаты услуг салоном',
    yourBonus: '💳 Ваши бонусы',
    yourBonus2: ' за подключенные салоны:',
    firstPayBonus: 'За первую оплату {{percent}}% от суммы',
    everyPayBonus: 'От каждой оплаты {{percent}}% от суммы',
    oneTimePayment: 'Выплата за подключение: {{oneTimePayment}}',
    partnerLink: 'Партнерская ссылка',
    partnerPromo: 'Партнерский промокод',
    downloadQRButton: 'Скачать (png)',
  },
  partnersFullTable: {
    header: 'Список партнёров, подключившихся по вашей ссылке.',
    pagination: 'Показать строк:',
    accId: 'Номер филиала',
    filialName: 'Название филиала',
    connectDate: 'Дата подключения',
    firstPayDate: 'Дата первой оплаты',
    status: 'Статус бонуса',
  },
  consultantFullTable: {
    header: 'Список партнёров, подключившихся по вашей ссылке.',
    pagination: 'Показать строк:',
    accId: 'Номер филиала',
    filialName: 'Название филиала',
    lastPayDate: 'Дата последней оплаты',
    payTillDate: 'До какой даты оплачен',
  },
  operationListTable: {
    header: 'Итого к начислению: {{totalAccrued}} ₽',
    headerWithOutTotalAccrued: 'Итого к начислению: ₽',
    pagination: 'Показать строк:',
    operationDate: 'Дата операции',
    filialName: 'Номер и название филиала',
    paySum: 'Сумма оплаты, ₽',
    sumToPay: 'Сумма к начислению, ₽',
    status: 'Статус операции',
  },
  partnersPayHistorySalonTable: {
    pagination: 'Показать строк:',
    period: 'Период',
    monthsAccrued: 'Количество начисленных месяцев',
  },
  partnersPayHistoryConsultantTable: {
    paid: 'Выплачено',
    header: 'Выплаты производятся с 1 по 5 число каждого месяца',
    pagination: 'Показать строк:',
    notPaid: 'Не выплачено',
    period: 'Период',
    sum: 'Сумма, ₽',
    status: 'Статус выплаты',
  },
  addLeadForm: {
    header: 'Добавьте лидов, которым вы отправляли ссылку.',
    phoneLabel: 'Номер телефона',
    phonePlaceholder: 'Номер телефона',
    leadNameLabel: 'Имя лида',
    leadNamePlaceholder: 'Имя лида',
    commentLabel: 'Комментарий',
    commentPlaceholder: 'Введите текст',
    addLeadButton: 'Добавить лида',
    branchId: 'ID филиала',
    branchIdPlaceholder: 'ID филиала',
  },
  leadsTable: {
    header: 'Список лидов',
    pagination: 'Показать строк:',
    phone: 'Номер телефона',
    leadName: 'Название',
    comment: 'Комментарий',
    leadChips: 'Лид',
    branchId: 'ID филиала',
  },
};

export const PARTNER_PAGE_TEXT_ENG = {
  header: 'Affiliate program',
  partnerProgramTemplate: {
    header: 'Affiliate terms',
    bonus:
      'Bonus for invited salon - 1 month, accrued after the first payment for services by the salon',
    yourBonus: '💳 Your bonuses',
    yourBonus2: ' for connected salons:',
    firstPayBonus: 'From the first payment {{percent}}% of the amount',
    everyPayBonus: 'On each payment {{percent}}% of the amount',
    oneTimePayment: 'Connection payment: {{oneTimePayment}}',
    partnerLink: 'Affiliate link',
    partnerPromo: 'Affiliate promo code',
    downloadQRButton: 'Download (png)',
  },
  partnersFullTable: {
    header: 'List of partners who connected via your link.',
    pagination: 'Show lines:',
    accId: 'Branch number',
    filialName: 'Name of the branch',
    connectDate: 'Connection date',
    firstPayDate: 'First payment date',
    status: 'Bonus status',
  },
  consultantFullTable: {
    header: 'List of partners who connected via your link.',
    pagination: 'Show lines:',
    accId: 'Branch number',
    filialName: 'Name of the branch',
    lastPayDate: 'Last payment date',
    payTillDate: 'Until what date was paid',
  },
  operationListTable: {
    header: 'Total accrued: {{totalAccrued}} ₽',
    headerWithOutTotalAccrued: 'Total accrued: ₽',
    pagination: 'Show lines:',
    operationDate: 'Operation date',
    filialName: 'Number and name of the branch',
    paySum: 'Payment amount, ₽',
    sumToPay: 'Amount to be credited, ₽',
    status: 'Operation status',
  },
  partnersPayHistorySalonTable: {
    pagination: 'Show lines:',
    period: 'Period',
    monthsAccrued: 'Number of months accrued',
  },
  partnersPayHistoryConsultantTable: {
    paid: 'Paid',
    header: 'Payments are made from the 1st to the 5th of each month',
    pagination: 'Show lines:',
    notPaid: 'Not paid',
    period: 'Period',
    sum: 'Amount, ₽',
    status: 'Payment status',
  },
  addLeadForm: {
    header: 'Add the leads you sent the link to.',
    phoneLabel: 'Phone number',
    phonePlaceholder: 'Phone number',
    leadNameLabel: 'Lead name',
    leadNamePlaceholder: 'Lead Name',
    commentLabel: 'Comment',
    commentPlaceholder: 'Enter text',
    addLeadButton: 'Add lead',
    branchId: 'Branch ID',
    branchIdPlaceholder: 'Branch ID',
  },
  leadsTable: {
    header: 'List of leads',
    pagination: 'Show lines:',
    phone: 'Phone number',
    leadName: 'Title',
    comment: 'Comment',
    leadChips: 'Lead',
    branchId: 'Branch ID',
  },
};
