import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Icon } from '@atoms/icon';
import { Button } from '@uikit/Button';
import { Drawer } from '@uikit/Drawer';
import { getIsScroll } from '@helpers/index';

interface ITableTextBlock {
  /**
   * Текст в компоненте
   * @param {string}
   */
  text: string;
  /**
   * Дочерний компонент, рендерится перед текстом
   */
  children?: React.ReactNode;
  /**
   * Опциональный callback в который передается внутреннее состояние для использования во вне компонента
   * @param {(isOpen: boolean) => JSX.Element}
   */
  renderProps?: (isOpen: boolean) => JSX.Element;
  /**
   * Изменяет внешний вид и поведение компонента
   * @param {boolean}
   */
  isMobile?: boolean;
  /**
   * Опциональный флаг принудительного отображения кнопки раскрытия бока и применение стилей
   * @param {boolean}
   */
  showButton?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const TableTextBlock = memo(
  ({
    text,
    isMobile,
    children,
    renderProps,
    showButton = false,
    className = '',
  }: ITableTextBlock) => {
    const { t } = useTranslation();

    const ref = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isScroll, setIsScroll] = useState<boolean | null>(null);
    const [showTextWindow, setShowTextWindow] = useState(false);

    const isOpenToggle = useCallback(() => setIsOpen(prevState => !prevState), []);

    const showTextWindowToggle = useCallback(() => setShowTextWindow(prevState => !prevState), []);

    useEffect(() => {
      if (text?.length && isScroll === null && !showButton) {
        setIsScroll(getIsScroll(ref));
      }

      if (showButton) {
        setIsScroll(true);
      }

      return () => {
        setIsScroll(getIsScroll(ref));
      };
    }, [text, isScroll, showButton]);

    return (
      <div
        ref={ref}
        className={`relative flex flex-col ${isScroll ? '' : 'justify-center'} pr-14 ${
          isOpen ? 'max-h-max h-auto whitespace-pre-line' : 'max-h-16 !h-[3rem]'
        } break-all overflow-hidden ${className}`}>
        {text ? <p className={renderProps || children ? 'mb-2' : ''}>{text}</p> : null}
        {renderProps ? renderProps(isOpen) : null}
        {children}
        {!isMobile && isScroll ? (
          <div className='absolute top-[0.3rem] right-2'>
            <Button
              dense
              type='icon'
              color='default'
              view='outlined'
              icon={isOpen ? 'chevron-up' : 'chevron-down'}
              onClick={isOpenToggle}
              className='!h-10 !w-10'
            />
          </div>
        ) : null}
        {isMobile && isScroll ? (
          <div className='absolute top-[50%] right-2 translate-y-[-50%]'>
            <Button
              dense
              type='icon'
              color='default'
              view='outlined'
              icon={isOpen ? 'minimize' : 'maximize'}
              onClick={showTextWindowToggle}
            />
          </div>
        ) : null}
        {showTextWindow && isMobile ? (
          <Drawer
            size='100%'
            position='left'
            isOpen={showTextWindow}
            onClose={showTextWindowToggle}>
            <div className='bg-white p-10'>
              <div className='flex justify-between  mb-4'>
                <p className='font-bold text-h1_header leading-9'>
                  {t('CHAT_HISTORY_PAGE_TEXT.tableTextBlock.header')}
                </p>
                <Icon variant='delete' onClick={showTextWindowToggle} className='h-4 w-4' />
              </div>
              <div>
                <span className='font-normal leading-6 tracking-[0.0275em] whitespace-pre-line break-all'>
                  {text}
                </span>
                {renderProps ? renderProps(isOpen) : null}
                {children}
              </div>
            </div>
          </Drawer>
        ) : null}
      </div>
    );
  },
);

TableTextBlock.displayName = 'TableTextBlock';
