import { COMMON_TOAST } from '@const/common';
import { FEEDBACK_MESSAGES } from '@const/feedback';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sendFeedbackData } from '@redux/feedback/feedbackThunk';
import { getMediaFileType, isFileSizeIncorrect } from '@helpers/index';
import { IInitialState, initialState } from '@redux/feedback/initialState';

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setFeedbackText(state: IInitialState, action: PayloadAction<string>) {
      state.feedbackText = action.payload;
    },
    feedbackModalToggle(state: IInitialState) {
      state.isModalOpen = !state.isModalOpen;
    },
    addMediaFileForFeedback(state: IInitialState, action: PayloadAction<{ files: FileList }>) {
      if (!action.payload.files) return;
      const file = action.payload.files[0];
      if (file) {
        if (isFileSizeIncorrect(file)) {
          state.mediaFile = null;
          state.mediaFileURL = '';
          state.mediaFileName = '';
          state.mediaFileTooBigError = true;
        } else {
          const fileName = file.name;
          const fileType = getMediaFileType(file);
          const mediaFileURL = URL.createObjectURL(file);

          state.mediaFile = file;
          state.mediaFileType = fileType;
          state.mediaFileName = fileName;
          state.mediaFileURL = mediaFileURL;
          state.mediaFileTooBigError = false;
        }
      }
    },
    clearFeedbackMediaFile(state: IInitialState) {
      state.mediaFile = null;
      state.mediaFileURL = '';
      state.mediaFileName = '';
      state.mediaFileType = null;
      state.mediaFileTooBigError = false;
    },
    clearAllRequestStatuses(state: IInitialState) {
      state.isError = false;
      state.isSuccess = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(sendFeedbackData.pending, (state: IInitialState) => {
      state.isError = false;
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(
      sendFeedbackData.fulfilled,
      (state: IInitialState, action: PayloadAction<boolean>) => {
        state.isLoading = false;
        state.isModalOpen = false;
        state.feedbackText = '';
        state.mediaFile = null;
        state.mediaFileName = '';
        state.mediaFileURL = null;
        state.mediaFileType = null;

        if (action.payload) {
          state.isSuccess = true;
          state.successMessage = FEEDBACK_MESSAGES.SUCCESS_MESSAGE;
        }
      },
    );
    builder.addCase(sendFeedbackData.rejected, (state: IInitialState) => {
      state.isError = true;
      state.isLoading = false;
      state.isModalOpen = false;
      state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
    });
  },
});

export const feedbackReducer = feedbackSlice.reducer;
export const {
  setFeedbackText,
  feedbackModalToggle,
  clearFeedbackMediaFile,
  addMediaFileForFeedback,
  clearAllRequestStatuses,
} = feedbackSlice.actions;
