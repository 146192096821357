import { TAutoConnectionData } from '@redux/autoConnect/models';
import { AGE_OF_WHATSAPP_ACCOUNT_KEYS } from '@const/autoConnect';
import { TAutoConnectionFormData } from '@api/types';

export const autoConnectionEmptyData: TAutoConnectionData = {
  stepId: 1,
  promoCode: '',
  promoCodeData: {
    bonusDays: 0,
    discount: 0,
  },
  phoneNumberForSend: '',
  salonName: '',
  salonType: '',
  salonEmoji: '',
  emojiList: [],
  linksOnSalon: [],
  salonAddress: '',
  imageSrc: '',
  botLink: '',
  ageOfWhatsappAccount: AGE_OF_WHATSAPP_ACCOUNT_KEYS.NOT_SELECTED,
  ownerPhoneNumber: '',
  salonPhoneNumber: '',
};

export const autoConnectionMockData: TAutoConnectionData = {
  stepId: 9,
  promoCode: '',
  promoCodeData: {
    bonusDays: 0,
    discount: 0,
  },
  phoneNumberForSend: '79630840601',
  salonName: 'test',
  salonType: '',
  salonEmoji: '❤',
  emojiList: ['❤', '💘'],
  linksOnSalon: ['https://www.ya.ru'],
  salonAddress: 'test address',
  imageSrc: '',
  botLink: 'https://www.ya.ru',
  ageOfWhatsappAccount: AGE_OF_WHATSAPP_ACCOUNT_KEYS.NOT_SELECTED,
  ownerPhoneNumber: '79630840600',
  salonPhoneNumber: '79630840600',
};

export const autoConnectionResponseMockData: TAutoConnectionFormData = {
  form_step: 0,
  branch_name: 'Тест',
  owner_phone: '375777777777',
  from_ref: 'test123232456578kfvjsdhbvkjsgkasjygfkauweyfgkauweyfgdjyawtfjxyawtefcjayew265347588',
  promo_bonus: {
    type: null,
    telegram_id: null,
    phone: null,
    bonus_days: null,
    discount: null,
    one_time_payment: null,
  },
  salon_type: 'Партнер YCLIENTS',
  emoji_list: [
    '🌞',
    '🌿',
    '💅',
    '🛁',
    '🌸',
    '🕊️',
    '🕯️',
    '🧖‍♀️',
    '💆‍♀️',
    '🌺',
    '🩷',
    '❤️',
    '🧡',
    '💛',
    '💚',
    '🩵',
    '💙',
    '💜',
    '🖤',
    '🩶',
    '🌸',
    '🌼',
    '🔥',
    '💅',
    '💈',
    '💇',
    '✂️',
    '💇',
    '💇‍♂️',
    '💇',
    '❣️',
    '💕',
    '💞',
    '💓',
    '💗',
    '💖',
    '💘',
    '💝',
    '❤️‍🩹',
    '❤️‍🔥',
    '🌱',
    '🌿',
    '🍀',
    '✨',
    '⚡',
    '🐶',
    '😻',
    '🤍',
    '🤎',
    '💃',
  ],
  emoji: '😻',
  salon_phone: '37477777777',
  wa_life_time: '6',
  main_url: 'https://www.google.com/maps/test',
  other_urls: ['https://www.google.com/maps/pl(*&^%++++++'],
  address: 'Москва,',
  deep_link: 'https://t.me/BoRiD_bot?start=acc_id=ppy12x',
  qr_code:
    'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://t.me/BoRiD_bot?start=acc_id=ppy12x',
};
