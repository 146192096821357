import React, { memo } from 'react';
//
import { Button } from '@uikit/Button';
import { PAY_PAGE_TEXT } from '@const/pay';
import { TOnChangeHandler } from '@shared/types';
import { InputWithLabel } from '@blocks/inputWithLabel';

type TPayPromoCodeProps = {
  /**
   * Флаг применения промокода
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Промокод
   * @param {string}
   */
  promoCode: string;
  /**
   * Обработчик поля ввода промокода
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Callback в кнопку для применения промокода
   * @param {() => void}
   */
  applyPromoCode: () => void;
  /**
   * Опциональный параметр - строка классов
   * @param {string}
   */
  className?: string;
};

export const PayPromoCode = memo(
  ({
    isLoading,
    promoCode,
    applyPromoCode,
    onChangeHandler,
    className = '',
  }: TPayPromoCodeProps) => {
    return (
      <div className={`flex items-end ${className}`}>
        <InputWithLabel
          name='promoCode'
          value={promoCode}
          onChangeCallback={onChangeHandler}
          labelText={PAY_PAGE_TEXT.promoCodeLabel}
          placeholderText={PAY_PAGE_TEXT.promoCodePlaceholder}
          className='text-stormGray max-w-[12.875rem] mr-2'
        />
        <div className='bg-white rounded-full mb-0.5'>
          <Button
            dense
            type='action'
            view='outlined'
            loading={isLoading}
            onClick={applyPromoCode}
            text={PAY_PAGE_TEXT.setPromCode}
          />
        </div>
      </div>
    );
  },
);

PayPromoCode.displayName = 'PayPromoCode';
