import { apiService } from '@api/ApiService';
import { TRejectResponseData } from '@models/index';
import { TCopyTemplateData } from '@redux/template/models';
import { END_POINTS_URL, QUERY_PARAMS } from '@const/apiConstants';
import {
  TRequestStatus,
  TResponseWithOkAndData,
  TSwitchActionRequestData,
  TGetInviteFriendsCartType,
  TMinimalPriceResponseData,
  TGetInviteFriendsAnalytics,
  TGetResponseWithDataAndOrder,
  TInviteFriendsActionResponse,
  TInviteFriendsActionListResponse,
} from '@api/types';

export const inviteFriendsApi = {
  getActionList() {
    return apiService.get<TGetResponseWithDataAndOrder<TInviteFriendsActionListResponse[]>>(
      END_POINTS_URL.INVITE_FRIENDS_ACTION_LIST,
    );
  },
  getActionById(actionId: string) {
    return apiService.get<TResponseWithOkAndData<TInviteFriendsActionResponse[]>>(
      `${END_POINTS_URL.INVITE_FRIENDS_ACTION}/${actionId}`,
      {
        [QUERY_PARAMS.LOYALTY_MESSAGE_ID]: actionId,
      },
    );
  },
  createAction(data: TInviteFriendsActionResponse) {
    return apiService.post<TInviteFriendsActionResponse | TRejectResponseData>(
      END_POINTS_URL.INVITE_FRIENDS_CREATE_ACTION,
      data,
    );
  },
  updateAction(data: TInviteFriendsActionResponse) {
    return apiService.put<TInviteFriendsActionResponse | TRejectResponseData>(
      `${END_POINTS_URL.INVITE_FRIENDS_ACTION}/${data.id}`,
      data,
    );
  },
  switchAction(data: TSwitchActionRequestData) {
    return apiService.put<TRejectResponseData>(END_POINTS_URL.INVITE_FRIENDS_SWITCH_TEMPLATE, data);
  },
  deleteAction(id: string) {
    return apiService.delete<TRejectResponseData>(
      `${END_POINTS_URL.INVITE_FRIENDS_ACTION}/${id}`,
      {},
      {
        params: {
          [QUERY_PARAMS.LOYALTY_MESSAGE_ID]: id,
        },
      },
    );
  },
  getAnalytics() {
    return apiService.get<TResponseWithOkAndData<TGetInviteFriendsAnalytics[]>>(
      END_POINTS_URL.INVITE_FRIENDS_ANALYTICS,
    );
  },
  getCardsTypes() {
    return apiService.get<TResponseWithOkAndData<TGetInviteFriendsCartType[]>>(
      END_POINTS_URL.INVITE_FRIENDS_CARDS,
    );
  },
  getMinimalPrice() {
    return apiService.get<TResponseWithOkAndData<TMinimalPriceResponseData[]>>(
      END_POINTS_URL.INVITE_FRIENDS_MINIMAL_PRICE,
    );
  },
  copyActionToBranch(data: TCopyTemplateData) {
    return apiService.post<TInviteFriendsActionResponse[] | TRequestStatus>(
      END_POINTS_URL.INVITE_FRIENDS_COPY_TEMPLATE,
      data.branchList,
      {
        params: {
          [QUERY_PARAMS.LOYALTY_MESSAGE_ID]: data.templateId,
        },
      },
    );
  },
};
