import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Icon } from '@blueprintjs/core';
import { TSendOutsDetailData } from '@redux/mailing/models';

type TSendOutDetailDataItemProps = {
  /**
   * Массив с данными рассылок в выбранном дне
   * @param {TSendOutsDetailData}
   */
  sendOutDetailData: TSendOutsDetailData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const SendOutDetailDataItem = memo(
  ({ sendOutDetailData, className = '' }: TSendOutDetailDataItemProps) => {
    const { t } = useTranslation();

    const { sendOutId, sendOutText, lastVisitTimeMedian, visitsMedian, totalCount, startDate } =
      sendOutDetailData;

    // Формируем дату начала рассылки
    const startDataText = new Date(startDate || 0).toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });

    // Массив с данными для формирования блоков с информацией
    const dataToRender = [
      {
        text: t('MAILING_PAGE_TEXT.sendOutDetailModal.numberOfRecipients'),
        value: totalCount,
      },
      {
        text: t('MAILING_PAGE_TEXT.sendOutDetailModal.visitsMedian'),
        value: visitsMedian,
      },
      {
        text: t('MAILING_PAGE_TEXT.sendOutDetailModal.lastVisitTimeMedian'),
        value: lastVisitTimeMedian,
      },
    ];

    // Блоки с информацией для рендера
    const sendOutDataToRender = dataToRender.map(({ value, text }) => {
      if (value === null) return null;

      return (
        <div key={text} className='mb-4'>
          <p className='font-medium leading-8 tracking-[0.004em] mb-1'>{value}</p>
          <p className='text-stormGray text-inner_text leading-5 tracking-[0.018em]'>{text}</p>
        </div>
      );
    });

    // Проверяем является ли текущая рассылка активной
    const isStartTimeInPaste = new Date().getTime() > new Date(startDate || 0).getTime();

    return (
      <div className={`bg-white rounded-lg p-4 ${className}`}>
        <p className='leading-6 tracking-[0.0275em] mb-2'>№{sendOutId}</p>
        <p className='leading-6 tracking-[0.0275em] text-darkGray mb-6'>
          {isStartTimeInPaste
            ? t('MAILING_PAGE_TEXT.sendOutDetailModal.started', { startDataText })
            : t('MAILING_PAGE_TEXT.sendOutDetailModal.willBeStarted', { startDataText })}
        </p>
        <div className='border border-quartz1 rounded-lg flex items-center justify-center mb-4 h-16 w-16'>
          <Icon icon='people' size={32} />
        </div>
        <div>{sendOutDataToRender}</div>
        <p className='text-stormGray text-inner_text leading-5 tracking-[0.018em] mb-2'>
          {t('MAILING_PAGE_TEXT.sendOutDetailModal.sendOutText')}
        </p>
        <p className='text-black leading-6 tracking-[0.0275em] whitespace-pre-line'>
          {sendOutText}
        </p>
      </div>
    );
  },
);
