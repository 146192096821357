import { TTelegramAuthData } from './models';

export interface IInitialState {
  [key: string]: boolean | number | string | null | TTelegramAuthData;
  auth: boolean;
  userId: string;
  accessToken: string;
  isTimerSet: boolean;
  newAccessToken: string | null;
  isError: boolean;
  isLoading: boolean;
  authUserData: TTelegramAuthData;
  pin: string;
  isLoginInput: boolean;
  isTgIdError: boolean;
  isPinError: boolean;
  isInputError: boolean;
  status: string;
  type: string;
  isUpdTokenPending: boolean;
  isUpdTokenSuccess: boolean;
  isUpdTokenError: boolean;
  isRedirectToConnectionPage: boolean;
}

export const initialState: IInitialState = {
  auth: false,
  userId: '',
  pin: '',
  accessToken: '',
  isTimerSet: false,
  isLoginInput: true,
  newAccessToken: null,
  isError: false,
  isLoading: false,
  isTgIdError: false,
  isPinError: false,
  isInputError: false,
  isUpdTokenPending: false,
  isUpdTokenSuccess: false,
  isUpdTokenError: false,
  authUserData: {
    id: 0,
    firstName: '',
    lastName: '',
    username: '',
    photoUrl: '',
  },
  status: '',
  type: '',
  isRedirectToConnectionPage: false,
};
