export type TMessageType = 'text' | 'file' | 'contact' | 'geo';

export type TMessageDirection = 'incoming' | 'outgoing' | 'bot';

export type TMessageFileData = {
  fileName: string;
  size: string;
  contentType: string;
};

export type TChatHistoryTableData = {
  phoneNumber: string;
  body: string;
  date: string;
  type: TMessageType;
  direction: TMessageDirection;
  fileData: TMessageFileData;
  name: string;
  caption?: string;
};

export enum CHAT_HISTORY_TABLE_DATA_KEY_NAMES {
  PHONE_NUMBER = 'phoneNumber',
  MESSAGE_TEXT = 'messageText',
  DATE = 'date',
  DIRECTION = 'direction',
}
