import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//
import { TRejectResponseData } from '@models/index';
import { COMMON_TOAST, REG_EXP } from '@const/common';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { getAccessData, postAccesses } from '@redux/access/accessThunks';
import { REJECT_RESPONSE_KEY, REJECT_RESPONSE_2_KEY } from '@api/types';
import { RIGHT_ARRAY, NEW_EMPTY_ACCESS_BLOCK, RIGHT_ITEM_NAMES } from '@const/access';
import { ACCESS_DATA_KEY_NAMES, TAccessDataItem, TAccessRight } from '@redux/access/models';

import { IInitialAccessesState, initialState } from './initialState';

export const accessSlice = createSlice({
  name: 'access',
  initialState,
  reducers: {
    addNewAccessBlock(state: IInitialAccessesState) {
      state.data = state.data.concat(NEW_EMPTY_ACCESS_BLOCK);
    },
    deleteAccessBlock(state: IInitialAccessesState, action: PayloadAction<{ userId: string }>) {
      state.data = state.data.filter(item => item.userId !== action.payload.userId);
    },
    changeInputValue(
      state: IInitialAccessesState,
      action: PayloadAction<{ name: string; value: string; id: string }>,
    ) {
      const { name, value, id } = action.payload;
      if (name === ACCESS_DATA_KEY_NAMES.USER_ID) {
        state.data[+id][name] = value.replace(REG_EXP.onlyNumbers, '');
      } else {
        state.data[+id][name] = value;
      }
    },
    changeSelectValue(
      state: IInitialAccessesState,
      action: PayloadAction<{ name: string; checked: boolean; id: string }>,
    ) {
      const { name, checked } = action.payload;
      const id = Number(action.payload.id);

      if (name === RIGHT_ITEM_NAMES.ALL && checked) {
        state.data[id].rights = RIGHT_ARRAY;
        return;
      }

      if (name === RIGHT_ITEM_NAMES.ALL && !checked) {
        state.data[id].rights = [];
        return;
      }

      switch (checked) {
        case true: {
          state.data[id].rights = [...state.data[id].rights, name] as unknown as TAccessRight[];
          break;
        }
        case false: {
          state.data[id].rights = state.data[id].rights.filter(item => item !== name);
          break;
        }
        default: {
          break;
        }
      }
    },
    setAccessDenied(state: IInitialAccessesState) {
      state.isAccessDenied = true;
    },
    hideSaveSuccessMessage(state: IInitialAccessesState) {
      state.isPostSuccess = false;
    },
    hideErrorMessageAP(state: IInitialAccessesState) {
      state.isError = false;
      state.isPostError = false;
      state.isUniqueTgIdError = false;
      state.isEmptyInputError = false;
      state.errorMessage = '';
    },
    clearAccessDeniedErrorAP(state: IInitialAccessesState) {
      state.isAccessDenied = false;
    },
    setUniqueErrorMessage(state: IInitialAccessesState) {
      state.errorMessage = COMMON_TOAST.UNIQUE_TG_ID_ERROR;
      state.isUniqueTgIdError = true;
    },
    setEmptyInputErrorMessage(state: IInitialAccessesState) {
      state.errorMessage = COMMON_TOAST.EMPTY_TG_INPUT_ERROR;
      state.isEmptyInputError = true;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAccessData.pending, (state: IInitialAccessesState) => {
      state.isError = false;
      state.isPostError = false;
      state.isGetSuccess = false;
      state.isAccessDenied = false;
      state.isLoading = true;
    });
    builder.addCase(
      getAccessData.fulfilled,
      (state: IInitialAccessesState, action: PayloadAction<TAccessDataItem[] | void>) => {
        if (action.payload) {
          state.data = action.payload;
          state.oldData = action.payload;
        } else {
          state.data = [];
        }
        if (state.isRetries) {
          state.retries = 1;
          state.isRetries = false;
        }
        state.isGetSuccess = true;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getAccessData.rejected,
      (state: IInitialAccessesState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
              state.isRetries = true;
              state.retries -= 1;
            } else {
              state.isError = true;
              state.isLoading = false;
            }
          } else if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.FAIL_RIGHT
            ) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
            state.isLoading = false;
          } else {
            state.isLoading = false;
            state.isError = true;
          }
        } else {
          state.isError = true;
          state.isLoading = false;
        }
        state.isGetSuccess = false;
      },
    );
    builder.addCase(postAccesses.pending, (state: IInitialAccessesState) => {
      state.isAccessDenied = false;
      state.isError = false;
      state.isPostError = false;
      state.isPostSuccess = false;
      state.isGetSuccess = false;
      state.isLoading = false;
      state.isPosting = true;
    });
    builder.addCase(
      postAccesses.fulfilled,
      (state: IInitialAccessesState, action: PayloadAction<TAccessDataItem[] | void>) => {
        if (action.payload) {
          state.isPosting = false;
          state.isPostSuccess = true;
          state.data = action.payload;
          state.successMessage = COMMON_TOAST.SAVE_SUCCESS;
        }
      },
    );
    builder.addCase(
      postAccesses.rejected,
      (state: IInitialAccessesState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessDenied = true;
            } else {
              state.isPostError = true;
            }
            state.isPosting = false;
          }
          if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isPosting = true;
            } else {
              state.isPosting = false;
              state.isPostError = true;
            }
          }
        }
        if (!action.payload) {
          state.isPosting = false;
          state.isPostError = true;
        }
        state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
      },
    );
  },
});

export const {
  setAccessDenied,
  changeInputValue,
  changeSelectValue,
  addNewAccessBlock,
  deleteAccessBlock,
  hideErrorMessageAP,
  setUniqueErrorMessage,
  hideSaveSuccessMessage,
  clearAccessDeniedErrorAP,
  setEmptyInputErrorMessage,
} = accessSlice.actions;

export const accessesReducer = accessSlice.reducer;
