import React, { memo, useCallback, useMemo, useState } from 'react';
//
import { monthsName } from '@const/mailing';
import { TodayTag } from '@blocks/todayTag';
import { TAppLanguage } from '@models/index';
import { MonthList } from '@blocks/monthList';
import { YearsList } from '@blocks/yearsList';
import {
  TSetSelectedYearHandler,
  TSetSelectedMonthHandler,
  TSetSelectedMonthIndexHandler,
  TSetSelectedMonthButtonHandler,
} from '@shared/types';

type TCalendarSelectDateProps = {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Выбранный месяц
   * @param {number}
   */
  selectedMonth: number;
  /**
   * Устанавливает выбранный месяц
   * @param {TSetSelectedMonthIndexHandler}
   */
  setSelectedMonthIndexHandler: TSetSelectedMonthIndexHandler;
  /**
   * Выбранный год
   * @param {number}
   */
  selectedYear: number;
  /**
   * Устанавливает выбранный год
   * @param {TSetSelectedYearHandler}
   */
  setSelectedYearHandler: TSetSelectedYearHandler;
  /**
   * Переводит дату в календаре на текущий год и месяц
   * @param {() => void}
   */
  setTodayHandler: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const CalendarSelectDate = memo(
  ({
    appLanguage,
    selectedYear,
    selectedMonth,
    setTodayHandler,
    setSelectedYearHandler,
    setSelectedMonthIndexHandler,
    className = '',
  }: TCalendarSelectDateProps) => {
    // Флаг отображения выпадающего списка для выбора месяца
    const [isShowMonth, setIsShowMonth] = useState(false);

    // Флаг отображения выпадающего списка для выбора года
    const [isShowYear, setIsShowYear] = useState(false);

    // Показывает выпадающий список
    const showMonthList = useCallback(() => {
      setIsShowMonth(true);
    }, [setIsShowMonth]);

    // Скрывает выпадающий список
    const hideMonthList = useCallback(() => {
      setIsShowMonth(false);
    }, [setIsShowMonth]);

    // Показывает выпадающий список
    const showYearList = useCallback(() => {
      setIsShowYear(true);
    }, [setIsShowYear]);

    // Скрывает выпадающий список
    const hideYearList = useCallback(() => {
      setIsShowYear(false);
    }, [setIsShowYear]);

    const todayDate = new Date();

    const currentMonth = todayDate.getMonth();

    const currentYear = todayDate.getFullYear();

    // формирует список годов
    const createYearList = useCallback((startYear?: number, listLength?: number) => {
      const year = startYear || new Date().getFullYear();
      const lastYear = listLength ? listLength + year : year + 3;
      const list = [];

      for (let i = year; i <= lastYear; i++) {
        list.push(i);
      }

      return list;
    }, []);

    // Отфильтрованный список, в списке текущей месяц и следующие
    const filteredMontList = useMemo(
      () =>
        monthsName[appLanguage].filter((_, index) => {
          if (selectedYear > currentYear) {
            return true;
          }
          if (selectedYear === currentYear) {
            return index >= currentMonth;
          }
          return false;
        }),
      [appLanguage, currentMonth, currentYear, selectedYear],
    );

    // обработчик выбора месяца
    const setSelectedMonthHandler: TSetSelectedMonthHandler = useCallback(
      ({ index, setPrevYear, setNextYear, hideListCallback }) => {
        const correctMonthIndex = monthsName[appLanguage].indexOf(filteredMontList[index]);

        if (hideListCallback) {
          hideListCallback();
        }

        setSelectedMonthIndexHandler({
          setNextYear,
          setPrevYear,
          index: correctMonthIndex,
        });
      },
      [appLanguage, filteredMontList, setSelectedMonthIndexHandler],
    );

    // обработчик выбора месяца через нажатие на кнопки "прокрутки"
    const setSelectedMonthButtonHandler: TSetSelectedMonthButtonHandler = useCallback(
      ({ direction, isShowMonthList, hideListCallback }) =>
        () => {
          const yearToSelect = createYearList();

          const maxYearToSelect = yearToSelect[yearToSelect.length - 1];

          if (direction === 'increase') {
            const newMonthIndex = selectedMonth + 1;

            const isNotPast = !(newMonthIndex < currentMonth && selectedYear <= currentYear);

            if (isNotPast) {
              if (newMonthIndex > 11) {
                if (selectedYear + 1 < maxYearToSelect) {
                  setSelectedMonthIndexHandler({
                    setNextYear: true,
                    setPrevYear: false,
                    index: 0,
                  });
                }
              } else {
                setSelectedMonthIndexHandler({
                  setNextYear: false,
                  setPrevYear: false,
                  index: newMonthIndex,
                });
              }
            }
          } else {
            const newMonthIndex = selectedMonth - 1;

            const isNotPast = !(newMonthIndex < currentMonth && selectedYear <= currentYear);

            if (isNotPast) {
              if (newMonthIndex < 0) {
                setSelectedMonthIndexHandler({
                  setNextYear: false,
                  index: 11,
                  setPrevYear: true,
                });
              } else {
                setSelectedMonthIndexHandler({
                  setNextYear: false,
                  setPrevYear: false,
                  index: newMonthIndex,
                });
              }
            }
          }

          if (isShowMonthList) {
            hideListCallback();
          }
        },
      [
        currentYear,
        currentMonth,
        selectedYear,
        selectedMonth,
        createYearList,
        setSelectedMonthIndexHandler,
      ],
    );

    return (
      <div className={`flex flex-wrap items-center justify-between ${className}`}>
        <div className='flex flex-wrap m:flex-nowrap items-center pr-4 sm:mb-2'>
          <MonthList
            appLanguage={appLanguage}
            monthList={filteredMontList}
            isShowMonthList={isShowMonth}
            showListCallback={showMonthList}
            hideListCallback={hideMonthList}
            selectedMonthIndex={selectedMonth}
            setSelectedMonthCallback={setSelectedMonthHandler}
            setSelectedMonthButtonHandler={setSelectedMonthButtonHandler}
          />
          <YearsList
            yearList={createYearList()}
            selectedYear={selectedYear}
            isShowYearList={isShowYear}
            showListCallback={showYearList}
            hideListCallback={hideYearList}
            setSelectedYearCallback={setSelectedYearHandler}
          />
        </div>
        <TodayTag setTodayCallback={setTodayHandler} className='sm:mb-2' />
      </div>
    );
  },
);

CalendarSelectDate.displayName = 'CalendarSelectDate';
