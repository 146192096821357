export type TPayHistoryConsultantTableData = {
  period: string;
  sum: number;
  status: boolean;
};

export enum PAY_HISTORY_CONSULTANT_TABLE_KEY_NAMES {
  PERIOD = 'period',
  SUM = 'sum',
  STATUS = 'status',
}
