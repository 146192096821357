import { SORTING_TYPES } from '@const/common';
import { statisticsApi } from '@api/statisticsApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TRejectResponseData } from '@models/index';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { statisticsMappers } from '@redux/statistics/statisticsMappers';
import { SORT_DROPDOWN_VALUES, SORT_TYPE_TO_REQUEST } from '@const/mailing';
import {
  TStatisticsResponse,
  TStatisticsTableDataRequestParams,
  TStatisticsTableDataCountRequestParams,
} from '@redux/statistics/models';
import {
  REJECT_RESPONSE_KEY,
  STATISTICS_RESPONSE_KEYS,
  TStatisticsTableDataQueryParams,
  TStatisticsTableDataCountQueryParams,
} from '@api/types';

/**
 * Thunk-экшен для получения количества записей в таблице статистики.
 *
 * @returns {number |void} - Данные о количестве записей в таблице статистики.
 * @throws {TRejectResponse|TRejectResponse2} - Объект с данными об ошибке.
 */
const getStatisticDataCountThunk = createAsyncThunk<
  number | void,
  TStatisticsTableDataCountRequestParams,
  { rejectValue: TRejectResponseData }
>('statisticsTable/getStatisticDataCountThunk', (data, { dispatch, rejectWithValue }) => {
  const { endDate, startDate, searchString } = data;

  const queryParams: TStatisticsTableDataCountQueryParams = {
    date_start: `${startDate.slice(0, -9)}T00:00:00`,
    date_end: `${endDate.slice(0, -9)}T23:59:59`,
    text: searchString,
  };
  return statisticsApi
    .getStatisticDataCount(queryParams)
    .then(response => {
      if (
        typeof response.data !== 'number' &&
        REJECT_RESPONSE_KEY.OK in response.data &&
        !response.data.ok
      ) {
        throw new Error(String(response.data.status));
      }
      if (typeof response.data === 'number') {
        return response.data;
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getStatisticDataCountThunk(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшен для получения данных таблицы статистики.
 *
 * @returns {TStatisticsResponse|void} - Данные таблицы статистики или void в случае ошибки.
 * @throws {TRejectResponse|TRejectResponse2} - Объект с данными об ошибке.
 */
const getStatisticTableData = createAsyncThunk<
  TStatisticsResponse | void,
  TStatisticsTableDataRequestParams,
  { rejectValue: TRejectResponseData }
>('statisticsTable/getStatisticTableData', (data, { dispatch, rejectWithValue }) => {
  const { sortedBy, sortDirection, currentPage, rowsPerPage, endDate, startDate, searchString } =
    data;

  const queryParams: TStatisticsTableDataQueryParams = {
    date_start: `${startDate.slice(0, -9)}T00:00:00`,
    page_size: rowsPerPage,
    date_end: `${endDate.slice(0, -9)}T23:59:59`,
    order_desc: sortDirection === SORTING_TYPES.DESCENDING,
    text: searchString,
    page: currentPage + 1,
    sort: SORT_TYPE_TO_REQUEST[sortedBy] || SORT_TYPE_TO_REQUEST[SORT_DROPDOWN_VALUES.ID],
  };

  return statisticsApi
    .getStatisticsData(queryParams)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (STATISTICS_RESPONSE_KEYS.DATA in response.data) {
        return statisticsMappers.mapResponseToStore(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getStatisticTableData(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

export { getStatisticDataCountThunk, getStatisticTableData };
