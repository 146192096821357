import React, { memo } from 'react';
//
import { ACTIVE_SEND_OUT_STATUSES } from '@const/mailing';
import { MailingDoButton } from '@blocks/mailingDoButton';
import { TMailingTableData } from '@redux/mailing/models';
import { TableTextBlock } from '@components/tableTextBlock';
import { MailingProcessBar } from '@blocks/mailingProcessBar';

interface IMobileTableCardProps {
  /**
   * Массив данных для отображение "шапки" карточки
   * @param {{ id: string; label: string; noSort?: boolean; dataType?: string }[]}
   */
  head: { id: string; label: string; noSort?: boolean; dataType?: string }[];
  /**
   * Массив данных для отображение "тела" карточки
   * @param {TMailingTableData[]}
   */
  data: TMailingTableData[];
  /**
   * Флаг сообщает о запущенном процессе остановки рассылки,
   * по этому флагу блокируются кнопки остановки рассылок
   * @param {boolean}
   */
  isStoppingSendOut: boolean;
  /**
   * Callback для остановки запущенной рассылки
   * @param {(id: number) => () => void}
   */
  stopSendOutHandler: (id: number) => () => void;
  /**
   * Callback для постановки запущенной рассылки на паузу
   * @param {(sendOutId: number) => void}
   */
  pauseSendOut: (sendOutId: number) => void;
  /**
   * Callback для запуска ранее остановленной рассылки
   * @param {(sendOutId: number) => void}
   */
  startPausedSendOut: (sendOutId: number) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const MobileMailingTableCardList = memo(
  ({
    head,
    data,
    pauseSendOut,
    isStoppingSendOut,
    stopSendOutHandler,
    startPausedSendOut,
    className = '',
  }: IMobileTableCardProps) => {
    const tableCardList = data.map(({ id, date, text, process, status }, index) => {
      const indexOfDivider = process.indexOf('/');
      const send = Number(process.slice(0, indexOfDivider));
      const total = Number(process.slice(indexOfDivider + 1));
      return (
        <div
          key={id}
          className={`border border-quartz flex flex-col p-4 rounded-lg ${
            index === data.length - 1 ? '' : 'mb-6'
          }`}>
          <div className='border-b border-quartz1 flex items-center h-16'>
            <span className='text-grayText px-2 w-[39%]'>{head[0].label}</span>
            <span className='px-2 w-[61%]'>{id}</span>
          </div>
          <div className='border-b border-quartz1 flex items-center h-16'>
            <span className='text-grayText px-2 w-[39%]'>{head[1].label}</span>
            <span className='px-2 w-[61%]'>{date}</span>
          </div>
          <div className='border-b border-quartz1 flex items-center h-16'>
            <span className='text-grayText px-2 w-[39%]'>{head[2].label}</span>
            <div className='px-2 w-[61%]'>
              <TableTextBlock isMobile text={text} />
            </div>
          </div>
          <div className='border-b border-quartz1 flex items-center h-16'>
            <span className='text-grayText px-2 w-[39%]'>{head[3].label}</span>
            <span className='px-2 w-[61%]'>{process}</span>
          </div>
          <div className='border-b border-quartz1 flex items-center px-2 h-16'>
            <MailingProcessBar
              id={id}
              send={send}
              isMobileView
              total={total}
              process={process}
              isShowButtons={false}
              pauseSendOut={pauseSendOut}
              startPausedSendOut={startPausedSendOut}
              isPlaned={status === ACTIVE_SEND_OUT_STATUSES.IS_PLANED}
              isStarted={status === ACTIVE_SEND_OUT_STATUSES.IS_STARTED}
              isOnPause={status === ACTIVE_SEND_OUT_STATUSES.IS_ON_PAUSE}
            />
          </div>
          <div className='flex items-center h-16'>
            <span className='text-grayText px-2 w-[39%]'>{head[4].label}</span>
            <span className='w-[61%]'>
              <div className='px-2 w-full'>
                <MailingDoButton
                  isMove={false}
                  isStatistics={false}
                  isStoppingSendOut={isStoppingSendOut}
                  stopSendOutHandler={stopSendOutHandler(id)}
                />
              </div>
            </span>
          </div>
        </div>
      );
    });
    return <div className={`flex flex-col ${className}`}>{tableCardList}</div>;
  },
);
