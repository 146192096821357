import { mapToNumberArray, mapToStringArray } from '@helpers/index';
import { TActionData, TCardType } from '@redux/inviteFriendsAction/type';
import { notSelectedCardResponse } from '@redux/inviteFriendsAction/mockData';
import { BONUSES, WHEN_SEND_VALUE_INVITE_FRIENDS } from '@const/inviteFriend';
import { TGetInviteFriendsCartType, TInviteFriendsActionResponse } from '@api/types';

import i18n from 'i18n';

/**
 * Эта функция вынесена для уменьшения дублирования кода и упрощения поддержки в будущем.
 * Она вычисляет значения whenSend2, whenSend2Days и whenSend2Min.
 *
 * @param {string} when_send - Время отправки.
 * @param {string} type - Тип шаблона уведомлений.
 * @returns {{ whenSend2: string, whenSend2Days: string, whenSend2Min: string }} - Объект, содержащий значения whenSend2, whenSend2Days и whenSend2Min.
 */
const calculateWhenSendValues = (
  when_send: string,
  type: string,
): { whenSend2: string; whenSend2Days: string; whenSend2Min: string } => {
  const isAllDayRecNotification = type === WHEN_SEND_VALUE_INVITE_FRIENDS.ALL_DAY_RECS_NOTIFICATION;
  const isBeforeOrAfter =
    type === WHEN_SEND_VALUE_INVITE_FRIENDS.BEFORE_START ||
    type === WHEN_SEND_VALUE_INVITE_FRIENDS.AFTER_END;

  const whenSend2 = isAllDayRecNotification
    ? +when_send > 0
      ? '1'
      : +when_send < 0
      ? '-1'
      : '0'
    : isBeforeOrAfter
    ? String(Math.floor(+when_send))
    : when_send;

  const whenSend2Days = isAllDayRecNotification
    ? String(+when_send * +whenSend2)
    : isBeforeOrAfter
    ? whenSend2
    : '1';

  const whenSend2Min = isBeforeOrAfter ? String((+when_send - +whenSend2) * 60) : '15';

  return { whenSend2, whenSend2Days, whenSend2Min };
};

const calculateWhenSend = ({
  whenSend,
  whenSend2Min,
  whenSend2Days,
  whenSend2,
}: {
  whenSend: string;
  whenSend2: string;
  whenSend2Days: string;
  whenSend2Min: string;
}): number => {
  if (whenSend === WHEN_SEND_VALUE_INVITE_FRIENDS.ALL_DAY_RECS_NOTIFICATION) {
    return +whenSend2Days * +whenSend2;
  }

  if (
    whenSend === WHEN_SEND_VALUE_INVITE_FRIENDS.AFTER_END ||
    whenSend === WHEN_SEND_VALUE_INVITE_FRIENDS.BEFORE_START
  ) {
    return +whenSend2Days + +whenSend2Min / 60;
  }

  return +whenSend2;
};

export const inviteFriendsActionMappers = {
  actionDataToStore({
    id,
    active,
    all_day_recs_notifications,
    before_start,
    staff,
    blacklist,
    bonus_type,
    card_type,
    categories,
    client_category,
    first_message,
    second_message,
    interest_regular_customer,
    new_customer_points,
    loyalty_points,
    range_of_visits,
    visits,
    record_labels,
    title,
    services,
    after_end,
    date_first_activate_template,
    date_create_template,
    loyalty_permission_status,
  }: TInviteFriendsActionResponse): TActionData {
    let allDayRecDay = '';
    let allDayRecTime = '';

    if (all_day_recs_notifications) {
      allDayRecDay = all_day_recs_notifications.day;
      allDayRecTime = all_day_recs_notifications.time;
    }

    const type = String(after_end ?? '')
      ? WHEN_SEND_VALUE_INVITE_FRIENDS.AFTER_END
      : String(before_start ?? '')
      ? WHEN_SEND_VALUE_INVITE_FRIENDS.BEFORE_START
      : all_day_recs_notifications
      ? WHEN_SEND_VALUE_INVITE_FRIENDS.ALL_DAY_RECS_NOTIFICATION
      : '';

    const whenSend =
      String(after_end ?? '') ||
      String(before_start ?? '') ||
      (all_day_recs_notifications ? +allDayRecDay : 0.25);

    const { whenSend2, whenSend2Days, whenSend2Min } = calculateWhenSendValues(
      String(whenSend),
      type,
    );

    const cardType = card_type || notSelectedCardResponse;

    return {
      id,
      active,
      allDayRecsNotifications: { day: '0', time: '13:50' },
      beforeStart: String(before_start),
      staff: mapToStringArray(staff || []),
      blacklist: blacklist || [],
      bonusType: bonus_type,
      cardType: {
        id: cardType.id,
        title: cardType.title,
        salonGroupId: cardType.salon_group_id,
      },
      categories: mapToStringArray(categories || []),
      afterEnd: String(after_end),
      clientCategory: client_category || [],
      firstMessage: first_message,
      interestRegularCustomer: interest_regular_customer ? String(interest_regular_customer) : '0',
      loyaltyPoints: String(loyalty_points || '0'),
      newCustomerPoints: String(new_customer_points || '0'),
      rangeOfVisits: String(range_of_visits?.length ? range_of_visits[1] : ''),
      visits: visits ? mapToStringArray(visits) : ['', ''],
      title,
      oldTitle: title || i18n.t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.templateHeader', { id }),
      services: mapToStringArray(services || []),
      recordLabels: record_labels || [],
      secondMessage: second_message,
      sendingBonuses: true,
      whenTime: allDayRecTime.replace(' ', ':') || '00:00',
      whenSend2,
      whenSend2Days,
      whenSend2Min,
      whenSend: type,
      onlineRecord: false,
      dateCreateTemplate: date_create_template || null,
      dateFirstActivateTemplate: date_first_activate_template || null,
      loyaltyPermissionStatus: !!loyalty_permission_status,
    };
  },
  actionToBack({
    id,
    active,
    recordLabels,
    visits,
    title,
    services,
    rangeOfVisits,
    secondMessage,
    firstMessage,
    newCustomerPoints,
    loyaltyPoints,
    interestRegularCustomer,
    categories,
    clientCategory,
    cardType,
    bonusType,
    blacklist,
    staff,
    whenSend,
    whenSend2,
    whenSend2Days,
    whenSend2Min,
    whenTime,
    dateCreateTemplate,
    dateFirstActivateTemplate,
  }: TActionData): TInviteFriendsActionResponse {
    let after_end: number | null = null;
    let before_start: number | null = null;
    let all_day_recs_notifications: { day: string; time: string } | null = null;

    if (whenSend === WHEN_SEND_VALUE_INVITE_FRIENDS.ALL_DAY_RECS_NOTIFICATION) {
      all_day_recs_notifications = {
        day: String(calculateWhenSend({ whenSend, whenSend2Min, whenSend2Days, whenSend2 })),
        time: whenTime.replace(':', ' '),
      };
    }

    if (whenSend === WHEN_SEND_VALUE_INVITE_FRIENDS.BEFORE_START) {
      before_start = calculateWhenSend({ whenSend, whenSend2Min, whenSend2Days, whenSend2 });
    }

    if (whenSend === WHEN_SEND_VALUE_INVITE_FRIENDS.AFTER_END) {
      after_end = calculateWhenSend({ whenSend, whenSend2Min, whenSend2Days, whenSend2 });
    }

    const cardTypeToBack =
      cardType.id !== 0
        ? {
            id: cardType.id,
            title: cardType.title,
            salon_group_id: cardType.salonGroupId,
          }
        : null;

    return {
      id,
      active,
      all_day_recs_notifications,
      blacklist: blacklist.length ? blacklist : null,
      bonus_type: bonusType,
      categories: categories.length ? mapToNumberArray(categories) : null,
      client_category: clientCategory.length ? clientCategory : null,
      before_start,
      staff: staff.length ? mapToNumberArray(staff) : null,
      card_type: cardTypeToBack,
      title,
      services: services.length ? mapToNumberArray(services) : null,
      after_end,
      visits: visits[0] && visits[1] ? mapToNumberArray(visits) : null,
      record_labels: recordLabels.length
        ? bonusType === BONUSES.PERCENTS
          ? recordLabels
          : null
        : null,
      first_message: firstMessage,
      loyalty_points: bonusType === BONUSES.FIRST_VISITS ? +loyaltyPoints : null,
      new_customer_points: +newCustomerPoints,
      range_of_visits: rangeOfVisits ? [0, +rangeOfVisits] : null,
      second_message: secondMessage,
      sending_bonuses: true,
      interest_regular_customer: bonusType === BONUSES.PERCENTS ? +interestRegularCustomer : null,
      date_create_template: dateCreateTemplate,
      date_first_activate_template: dateFirstActivateTemplate,
    };
  },
  loyaltyCardsToStore(data: TGetInviteFriendsCartType[]): TCardType[] {
    return data.map(({ id, title, salon_group_id }) => ({
      id,
      title,
      salonGroupId: salon_group_id,
    }));
  },
};
