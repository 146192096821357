import { TFeedbackPostData } from '@api/types';
import { feedbackApi } from '@api/feedbackApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TRejectResponseData } from '@models/index';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';

/**
 * Thunk отправляет текст обратной связи сообщение и обрабатывает результат.
 * @param {TFeedbackPostData} data - Данные для отправки обратной связи.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
export const sendFeedbackData = createAsyncThunk<
  boolean,
  TFeedbackPostData,
  { rejectValue: TRejectResponseData }
>('feedback/sendFeedbackData', (data, { dispatch, rejectWithValue }) => {
  return feedbackApi
    .sendFeedback(data)
    .then(response => {
      if (!response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (data.showSuccessModalCallback) {
        data.showSuccessModalCallback();
      }
      return data.topic === 'review';
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(sendFeedbackData(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});
