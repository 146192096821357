import { TInviteFriendListData, TInviteFriendsActionListObj } from '@redux/inviteFriendList/types';

export interface IInitialState {
  isError: boolean;
  isLoading: boolean;
  fastReview: string;
  isDeleting: boolean;
  errorMessage: string;
  isSwitching: boolean;
  feedbackText: string;
  successMessage: string;
  isAccessDenied: boolean;
  isDeletingError: boolean;
  isSwitchingError: boolean;
  isFeedbackSending: boolean;
  isDeletingSuccess: boolean;
  isShowWarningModal: boolean;
  actionOrder: number[];
  actionObj: TInviteFriendsActionListObj;
  data: TInviteFriendListData[];
}

export const initialState: IInitialState = {
  isError: false,
  fastReview: '',
  isLoading: false,
  feedbackText: '',
  errorMessage: '',
  isDeleting: false,
  successMessage: '',
  isSwitching: false,
  isAccessDenied: false,
  isDeletingError: false,
  isSwitchingError: false,
  isDeletingSuccess: false,
  isFeedbackSending: false,
  isShowWarningModal: false,
  actionObj: {},
  actionOrder: [],
  data: [],
};
