import { simpleSort } from '@helpers/index';
import { SORTING_TYPES } from '@const/common';
import { TRejectResponseData } from '@models/index';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REJECT_RESPONSE_2_KEY, REJECT_RESPONSE_KEY } from '@api/types';
import { getInviteFriendsAnalytics } from '@redux/inviteFrendsStatistics/inviteFriendsStatisticsThunk';
import {
  TStatisticsSortFields,
  TInviteFriendsStatisticsItemData,
} from '@redux/inviteFrendsStatistics/types';

import { IInitialState, initialState } from './inviteFriendStatisticsState';

export const initialFriendsStatisticsSlice = createSlice({
  name: 'initialFriendsStatistics',
  initialState,
  reducers: {
    setStatisticsPageData(
      state: IInitialState,
      action: PayloadAction<TInviteFriendsStatisticsItemData[]>,
    ) {
      state.data = action.payload;
    },
    setSortField(state: IInitialState, action: PayloadAction<TStatisticsSortFields>) {
      state.sortBy = action.payload;
      state.data = simpleSort<TInviteFriendsStatisticsItemData>(
        state.data,
        state.sortBy,
        state.sortDirection,
      );
    },
    setSortDirection(state: IInitialState) {
      const newSortDirection =
        state.sortDirection === SORTING_TYPES.ASCENDING
          ? SORTING_TYPES.DESCENDING
          : SORTING_TYPES.ASCENDING;
      state.sortDirection = newSortDirection;
      state.data = simpleSort<TInviteFriendsStatisticsItemData>(
        state.data,
        state.sortBy,
        newSortDirection,
      );
    },
    clearAllErrorIFA(state: IInitialState) {
      state.isError = false;
      state.isAccessDenied = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(getInviteFriendsAnalytics.pending, (state: IInitialState) => {
      state.isError = false;
      state.isLoading = true;
    });
    builder.addCase(
      getInviteFriendsAnalytics.fulfilled,
      (state: IInitialState, action: PayloadAction<TInviteFriendsStatisticsItemData[] | void>) => {
        state.isLoading = false;

        if (action.payload) {
          state.data = simpleSort<TInviteFriendsStatisticsItemData>(
            action.payload,
            state.sortBy,
            state.sortDirection,
          );
        }
      },
    );
    builder.addCase(
      getInviteFriendsAnalytics.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
              state.isLoading = false;
            } else {
              return;
            }
          }
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
          }
        } else {
          state.isError = true;
        }
        state.isLoading = false;
      },
    );
  },
});

export const initialFriendsStatisticsReducer = initialFriendsStatisticsSlice.reducer;

export const { setStatisticsPageData, setSortField, setSortDirection, clearAllErrorIFA } =
  initialFriendsStatisticsSlice.actions;
