import { templateKeysDict } from '@data/dict';
import { TFilialData } from '@redux/accounts/models';
import { emptyTemplateMockData } from '@redux/template/mockData';
import { TKeyData, TTemplatePageData } from '@redux/template/models';
import { TAppLanguage, TSimpleStringObj, TSimpleStringObjLevel2 } from '@models/index';
import { WHEN_SEND2_SELECT_OPTION_VALUE_NEW, WHEN_SEND_SELECT_OPTION } from '@const/templates';

export interface IInitialState {
  [key: string]:
    | string
    | boolean
    | number
    | TTemplatePageData
    | TSimpleStringObjLevel2
    | TSimpleStringObj
    | TFilialData[]
    | string[]
    | TKeyData[]
    | Record<TAppLanguage, TSimpleStringObjLevel2>;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isFetching: boolean;
  isCopyTemplatesError: boolean;
  isCopyTemplatesSuccess: boolean;
  isCopyTemplatesLoading: boolean;
  isSavingTemplate: boolean;
  isTemplateSaveError: boolean;
  isTemplateSaveSuccess: boolean;
  selectionEnd: number;
  selectionStart: number;
  blurFromTextareaName: string;
  mediaFileTooBigError: boolean;
  servicesNewValue: string;
  categoriesNewValue: string;
  productsNewValue: string;
  staffNewValue: string;
  clientCategoryNewValue: string;
  blacklistNewValue: string;
  isAccessDenied: boolean;
  isRetries: boolean;
  retries: number;
  templateTestFieldsParsError: string;
  categoriesDataList: TSimpleStringObjLevel2;
  isGettingDropdownListError: boolean;
  whenSendSelectOption: Record<TAppLanguage, TSimpleStringObj>;
  whenSend2SelectOption: Record<TAppLanguage, TSimpleStringObjLevel2>;
  data: TTemplatePageData;
  oldData: TTemplatePageData;
  filialsToCopy: TFilialData[];
  successMessage: string;
  errorMessage: string;
  isFileTypeError: boolean;
  fullFileName: string;
  textareaKeysData: TKeyData[];
  specialKeyDict: TSimpleStringObj;
  isShowWarningMessage: boolean;
  warningMessage: string;
}

export const initialState: IInitialState = {
  isError: false,
  isLoading: true,
  isFetching: false,
  isSuccess: false,
  isSavingTemplate: false,
  isTemplateSaveError: false,
  isTemplateSaveSuccess: false,
  isCopyTemplatesError: false,
  isCopyTemplatesSuccess: false,
  isCopyTemplatesLoading: false,
  selectionEnd: 0,
  selectionStart: 0,
  mediaFileTooBigError: false,
  blurFromTextareaName: '',
  servicesNewValue: '',
  categoriesNewValue: '',
  productsNewValue: '',
  staffNewValue: '',
  clientCategoryNewValue: '',
  blacklistNewValue: '',
  isAccessDenied: false,
  isRetries: false,
  retries: 1,
  servicesList: {},
  categoriesDataList: {},
  isGettingDropdownListError: false,
  templateTestFieldsParsError: '',
  whenSendSelectOption: WHEN_SEND_SELECT_OPTION,
  whenSend2SelectOption: WHEN_SEND2_SELECT_OPTION_VALUE_NEW,
  whenSend2Days: '',
  data: emptyTemplateMockData,
  oldData: emptyTemplateMockData,
  filialsToCopy: [],
  errorMessage: '',
  successMessage: '',
  isFileTypeError: false,
  fullFileName: '',
  textareaKeysData: [],
  specialKeyDict: templateKeysDict.rus.common,
  isShowWarningMessage: false,
  warningMessage: '',
};
