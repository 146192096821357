import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { DAYS } from '@const/common';
import { TAppLanguage } from '@models/index';
import { TOnChangeHandler } from '@shared/types';
import { TChannels, TCurrency } from '@api/types';
import { TCalculatePrice } from '@redux/pay/types';
import { PayButtons } from '@components/payButtons';
import { OptionToPayData } from '@blocks/optionToPayData';
import { numWord } from '@helpers/index';
import { ReactComponent as CheckboxIcon } from '@img/pay/checkbox-round.svg';
import { ReactComponent as NoSelectedTariff } from '@img/pay/noSelectedTariff.svg';
import { MONTH_WORDS } from '@const/pay';
import { ReactComponent as CheckboxCheckedIcon } from '@img/pay/checkbox-round_checked.svg';

type TPaymentBlockProps = {
  /**
   * Валюта для оплаты
   * @param {TCurrency}
   */
  currency: TCurrency;
  /**
   * Флаг подключения автоплатежа
   * @param {boolean}
   */
  isAutoPayEnable: boolean;
  /**
   * Флаг применения промокода
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Скрывает/показывает блок с промокодом
   * @param {boolean}
   */
  isHidePromoCodeBlock: boolean;
  /**
   * Промокод
   * @param {string}
   */
  promoCode: string;
  /**
   * Обработчик поля ввода промокода
   * @param {TOnChangeHandler}
   */
  setPromoCodeHandler: TOnChangeHandler;
  /**
   * Callback в кнопку для применения промокода
   * @param {() => void}
   */
  applyPromoCode: () => void;
  /**
   * Опциональный параметр - строка классов
   * @param {string}
   */
  /**
   * Флаг показывает или скрывает кнопку скачать счет
   * @param {boolean}
   */
  createBill: boolean;
  /**
   * Флаг показывает состояние запроса данных для получения счета
   * @param {boolean}
   */
  isFetchingInvoiceLink: boolean;
  /**
   * Номер выбранной карточки оплаты
   * @param {number}
   */
  selectedCardNumber: number;
  /**
   * Callback для открытия страницы платежной системы
   * @param {() => void}
   */
  changePageOnClick: () => void;
  /**
   * Callback для подключения автоплатежа
   * @param {() => void}
   */
  getAutoPayLinkHandler: () => void;
  /**
   * Callback для открытия модалки получения счета на оплату
   * @param {() => void}
   */
  getBillModalOpen: () => void;
  /**
   * Список активных опций
   * @param {TChannels[]}
   */
  activeModules: TChannels[];
  /**
   * Список выбранных опций для оплаты
   * @param {TChannels[]}
   */
  selectedModules: TChannels[];
  /**
   * Toggle для выбора опций
   * @param {(moduleName: TChannels) => void}
   */
  selectModuleHandler: (moduleName: TChannels) => void;
  /**
   * Стоимость одного месяца
   * @param {number}
   */
  selectedCardMonthAmount: number;
  /**
   * Рассчитанная цена выбранных опций
   * @param {TCalculatePrice}
   */
  calculatedPrice: TCalculatePrice;
  /**
   * Флаг получения ссылки на платежную систему
   * @param {boolean}
   */
  isGettingPayLink: boolean;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Флаг оплаты опции днями тарифа
   * @param {boolean}
   */
  payTariffDays: boolean;
  /**
   * Изменяет чекбокс оплаты днями тарифа
   * @param {() => void}
   */
  setPayTariffDaysHandler: () => void;
  /**
   * Флаг запроса цен
   * @param {boolean}
   */
  isCalculatingCoast: boolean;
  /**
   * Опциональный параметр - строка классов
   * @param {string}
   */
  className?: string;
};

export const PaymentBlock = memo(
  ({
    currency,
    isLoading,
    promoCode,
    createBill,
    appLanguage,
    activeModules,
    payTariffDays,
    applyPromoCode,
    calculatedPrice,
    isAutoPayEnable,
    selectedModules,
    getBillModalOpen,
    isGettingPayLink,
    changePageOnClick,
    isCalculatingCoast,
    selectedCardNumber,
    setPromoCodeHandler,
    selectModuleHandler,
    isHidePromoCodeBlock,
    getAutoPayLinkHandler,
    isFetchingInvoiceLink,
    selectedCardMonthAmount,
    setPayTariffDaysHandler,
    className = '',
  }: TPaymentBlockProps) => {
    const { t } = useTranslation();

    // const dayWords = useMemo(() => DAYS[appLanguage], [appLanguage]);
    const monthWords = useMemo(() => MONTH_WORDS[appLanguage], [appLanguage]);
    // const yearWords = useMemo(() => YEAR_WORDS[appLanguage], [appLanguage]);

    const tariffText = t('PAY_PAGE_TEXT.tariffDescription', {
      month: selectedCardMonthAmount,
      monthText: numWord(selectedCardMonthAmount, monthWords),
    });

    const tariffPrice = calculatedPrice.modulesCost[0]?.cost;

    const perMonthTariffPrice = tariffPrice / selectedCardMonthAmount;

    const perMonthTP =
      parseInt(String(perMonthTariffPrice), 10) === perMonthTariffPrice
        ? perMonthTariffPrice
        : perMonthTariffPrice.toFixed(2);

    const tariffDescription = `${t(
      currency === 'RUB' ? 'PAY_PAGE_TEXT.pricePerMonth' : 'PAY_PAGE_TEXT.pricePerMonthUSD',
      {
        pricePerMonth: +perMonthTP,
      },
    )} ${t('PAY_PAGE_TEXT.monthQuantity', {
      monthQuantity: selectedCardMonthAmount,
      monthText: numWord(selectedCardMonthAmount, monthWords),
    })}`;

    const modulesList = selectedCardMonthAmount
      ? selectedModules
      : selectedModules.filter(item => !activeModules.includes(item));

    // const selectedModulesToRender = modulesList.map(module => {
    //   const moduleData = calculatedPrice.modulesCost.find(option => option.module === module);
    //
    //   if (!moduleData) {
    //     return null;
    //   }
    //
    //   const quantity = moduleData.months ? moduleData.months : moduleData.days;
    //
    //   const words = moduleData.months ? monthWords : dayWords;
    //
    //   const monthQuantity = moduleData.months
    //     ? t('PAY_PAGE_TEXT.monthQuantity', {
    //         monthQuantity: quantity,
    //         monthText: numWord(quantity || 0, words),
    //       })
    //     : getFormatedStringFromDays({
    //         numberOfDays: quantity || 0,
    //         yearWords,
    //         monthWords,
    //         dayWords,
    //       });
    //
    //   const name = PAY_OPTION_NAME[appLanguage][module];
    //
    //   const pricePM = moduleData.cost / (moduleData?.months || 1);
    //
    //   const finalPrice = parseInt(String(pricePM), 10) === pricePM ? pricePM : pricePM.toFixed(2);
    //
    //   const monthPrice = t(
    //     currency === 'RUB' ? 'PAY_PAGE_TEXT.pricePerMonth' : 'PAY_PAGE_TEXT.pricePerMonthUSD',
    //     {
    //       pricePerMonth: +finalPrice,
    //     },
    //   );
    //
    //   const totalPeriodPrice = t(
    //     currency === 'RUB'
    //       ? 'PAY_PAGE_TEXT.fullPriceForPeriod'
    //       : 'PAY_PAGE_TEXT.fullPriceForPeriodUSD',
    //     {
    //       pricePerMonth: +finalPrice,
    //     },
    //   );
    //
    //   const description = `${
    //     selectedCardMonthAmount && moduleData.months ? monthPrice : totalPeriodPrice
    //   } ${monthQuantity}`;
    //
    //   const price = t(currency === 'RUB' ? 'PAY_PAGE_TEXT.price' : 'PAY_PAGE_TEXT.priceUSD', {
    //     amount: moduleData.cost,
    //   });
    //
    //   const modulePrice =
    //     module !== OPTIONS_NAME.WA
    //       ? price
    //       : currency === 'RUB'
    //       ? `0 ${t('PAY_PAGE_TEXT.rublSign')}`
    //       : `${t('PAY_PAGE_TEXT.dollarSign')}0`;
    //
    //   return (
    //     <OptionToPayData
    //       key={module}
    //       optionName={name}
    //       price={modulePrice}
    //       selectModuleHandler={
    //         module !== OPTIONS_NAME.WA ? () => selectModuleHandler(module) : undefined
    //       }
    //       optionDescription={
    //         module !== OPTIONS_NAME.WA ? description : t('PAY_PAGE_TEXT.waDescription')
    //       }
    //       className='mb-4'
    //     />
    //   );
    // });

    const totalText =
      currency === 'USD'
        ? `${t('PAY_PAGE_TEXT.dollarSign')}${calculatedPrice.fullCoast}`
        : `${calculatedPrice.fullCoast} ${t('PAY_PAGE_TEXT.rublSign')}`;

    const isEnableAutoPayButton = selectedCardNumber === 1 && !isAutoPayEnable;

    return (
      <div
        className={`border border-quartz rounded-lg flex flex-col p-6 ${
          !modulesList.length ? 'bg-gray1 min-h-[32.25rem]' : ''
        } ${className}`}>
        {modulesList.length ? (
          <>
            <div className='mb-6'>
              <div className='mb-6'>
                <p className='font-medium ext-black text-h6Mobile leading-8 tracking-[0.004em] mb-4'>
                  {t('PAY_PAGE_TEXT.toPay')}
                </p>
                <hr className='border-none bg-quartz1 h-0.5 mb-4' />
                <div>
                  {selectedCardMonthAmount ? (
                    <OptionToPayData
                      optionName={tariffText}
                      optionDescription={tariffDescription}
                      price={t(
                        currency === 'RUB' ? 'PAY_PAGE_TEXT.price' : 'PAY_PAGE_TEXT.priceUSD',
                        { amount: tariffPrice },
                      )}
                      className='mb-4'
                    />
                  ) : null}
                  {/* {selectedModulesToRender} */}
                  {!selectedCardMonthAmount && calculatedPrice.paidPeriodReducedBy ? (
                    <div className='flex items-center'>
                      <span onClick={setPayTariffDaysHandler} className='cursor-pointer'>
                        {payTariffDays ? <CheckboxCheckedIcon /> : <CheckboxIcon />}
                      </span>
                      <span className='font-medium leading-5 tracking-[0.018em] text-btnGreen text-tooltips'>
                        {t('PAY_PAGE_TEXT.payTariffDays')}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
              <hr className='border-none bg-quartz1 h-0.5 mb-6' />
              {!isEnableAutoPayButton ? (
                <div className='flex items-center justify-between font-medium text-blackText text-[1.125rem] leading-8'>
                  <span className='tracking-[0.022em]'>{t('PAY_PAGE_TEXT.amount')}</span>
                  <span>{totalText}</span>
                </div>
              ) : null}
              {payTariffDays ? (
                <div className='bg-bgWarning rounded-lg flex flex-col px-4 py-3 mt-4'>
                  <span className='text-tooltips leading-5 tracking-[0.022em]'>
                    {t('PAY_PAGE_TEXT.reductionOfTariffDuration')}
                  </span>
                  <span className='font-medium text-tooltips leading-5 tracking-[0.022em]'>
                    {t('PAY_PAGE_TEXT.reductionOfTariffData', {
                      day: calculatedPrice.paidPeriodReducedBy,
                      dayText: numWord(calculatedPrice.paidPeriodReducedBy || 0, DAYS[appLanguage]),
                      date: calculatedPrice.newPayTillDate,
                    })}
                  </span>
                </div>
              ) : null}
            </div>
            <PayButtons
              currency={currency}
              promoCode={promoCode}
              isLoading={isLoading}
              createBill={createBill}
              payTariffDays={payTariffDays}
              applyPromoCode={applyPromoCode}
              isAutoPayEnable={isAutoPayEnable}
              isGettingPayLink={isGettingPayLink}
              getBillModalOpen={getBillModalOpen}
              onChangeHandler={setPromoCodeHandler}
              changePageOnClick={changePageOnClick}
              isCalculatingCoast={isCalculatingCoast}
              isModuleSelected={!!modulesList.length}
              selectedCardNumber={selectedCardNumber}
              isHidePromoCodeBlock={isHidePromoCodeBlock}
              getAutoPayLinkHandler={getAutoPayLinkHandler}
              isFetchingInvoiceLink={isFetchingInvoiceLink}
              selectedCardTotalAmount={calculatedPrice.fullCoast}
            />
          </>
        ) : (
          <div className='flex flex-col items-center justify-center grow'>
            <NoSelectedTariff className='mb-9' />
            <div className='flex flex-col items-center justify-center text-gray8 text-[1.125rem] leading-6'>
              <span className=''> {t('PAY_PAGE_TEXT.selectPaymentTerm1')}</span>
              <span> {t('PAY_PAGE_TEXT.selectPaymentTerm2')}</span>
            </div>
          </div>
        )}
      </div>
    );
  },
);

PaymentBlock.displayName = 'PaymentBlock';
