import React, { memo } from 'react';
//
import { Icon } from '@atoms/icon';

interface IStatusProps {
  /**
   * Значение title в img
   * @param {string}
   */
  title: string;
  /**
   * Статус индикатора
   * @param {boolean}
   */
  status: boolean;
  /**
   * Опциональный параметр изменяет внешний вид индикатора
   * @param {boolean}
   */
  isPayStatus?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const Status = memo(
  ({ title, status, className = '', isPayStatus = false }: IStatusProps) => {
    const variant = isPayStatus ? (status ? 'paid' : 'notPaid') : status ? 'statusOn' : 'statusOff';

    return <Icon title={title} variant={variant} className={`h-5 w-5 ${className}`} />;
  },
);

Status.displayName = 'Status';
