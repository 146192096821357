import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
//
import { Button } from '@uikit/Button';
import { Loader } from '@blocks/loader';
import { PATH_NAMES } from '@data/dict';
import { numWord } from '@helpers/index';
import { NoAccess } from '@components/noAccess';
import { getAppData } from '@redux/app/selectors';
import { QUERY_PARAMS } from '@const/apiConstants';
import { Breadcrumb, Icon } from '@blueprintjs/core';
import { getAccountsData } from '@redux/accounts/selectors';
import { ToastsMessages } from '@components/toastsMessages';
import { TemplateStatTable } from '@blocks/templateStatTable';
import { TemplateStatChart } from '@blocks/templateStatChart';
import { useAppDispatch, useAppSelector } from '@store/store';
import { TEMPLATE_DETAIL_TABS, VERSIONS } from '@const/templates';
import { TemplatesTypeSwitcher } from '@blocks/templatesTypeSwitcher';
import { getTemplatesStatisticsPageData } from '@redux/templateStats/selectors';
import { TemplatesDetailStatsTable } from '@components/templatesDetailStatsTable';
import { clearErrorMessage, clearSuccessMessage } from '@redux/templateStats/templateStatsSlice';
import {
  getTemplateStatistic,
  getTemplateDetailStatsThunk,
  getTemplateStatisticByTimeThunk,
} from '@redux/templateStats/templateStatsThunks';

export const TemplateStat = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { appLanguage } = useAppSelector(getAppData);

  // Получаем id шаблона
  const id = searchParams.get(QUERY_PARAMS.TEMPL_ID);

  // Получаем текущий филиал
  const { selectedFilial } = useAppSelector(getAccountsData);

  // Получаем статистику шаблона
  const {
    template,
    errorMessage,
    isAccessDenied,
    successMessage,
    templateByTime,
    isTemplateFetching,
    templateDetailFilter,
    templateStatTableData,
    templateDetailStatsData,
    templateDetailActiveTab,
  } = useAppSelector(getTemplatesStatisticsPageData);
  const { dateFrom, dateTo, groupBy } = templateDetailFilter;
  const versionsCount = template.count || templateByTime.count || 0;

  // Функция переадресации
  const navToTemplatesStatsList = useCallback(() => {
    navigate(
      `${PATH_NAMES.TEMPLATE_LIST_STATISTICS}?${QUERY_PARAMS.ACC_ID}=${selectedFilial?.accId}`,
    );
  }, [selectedFilial, navigate]);

  // Эффект выполняет переадресацию, если в query параметрах не найден айди шаблона
  useEffect(() => {
    if (!id) {
      navToTemplatesStatsList();
    }
  }, [navToTemplatesStatsList, id]);

  // Эффект получает данные шаблона при загрузке, при наличии айди
  useEffect(() => {
    if (id) {
      if (templateDetailActiveTab === TEMPLATE_DETAIL_TABS.VERSION) {
        dispatch(getTemplateStatistic({ id }));
      } else {
        dispatch(
          getTemplateStatisticByTimeThunk({
            id,
            date_from: dateFrom,
            date_to: dateTo,
            group_by: groupBy,
          }),
        );
      }
      dispatch(getTemplateDetailStatsThunk({ templ_id: id }));
    }
  }, [dispatch, id, dateFrom, dateTo, templateDetailActiveTab, groupBy]);

  const clearErrorCallback = () => {
    dispatch(clearErrorMessage());
  };

  const clearSuccessMessageCallback = () => {
    dispatch(clearSuccessMessage());
  };

  const getVersionsForFilter = useMemo(() => {
    return templateDetailStatsData.reduce<Record<string, string>[]>((acc, { version }) => {
      const versionStr = String(version);
      if (!acc.some(v => v.key === versionStr)) {
        acc.push({ key: versionStr, text: versionStr });
      }
      return acc;
    }, []);
  }, [templateDetailStatsData]);

  return (
    <div className='px-4'>
      {isTemplateFetching ? (
        <Loader className='h-[50vh]' />
      ) : isAccessDenied ? (
        <NoAccess className='h-[50vh]' />
      ) : (
        <>
          <div className='flex flex-col sm:flex-row flex-wrap mb-6 m:mb-10'>
            <Button
              dense
              view='outlined'
              onClick={navToTemplatesStatsList}
              icon={<Icon icon='chevron-left' size={16} />}
              className='mb-4 sm:mb-0 max-w-[9.175rem]'>
              <span className='leading-[0.018em]'>
                {t('TEMPLATE_PAGE_TEXT.statistics.allTemplates')}
              </span>
            </Button>
            <div className='flex flex-col m:flex-row m:items-center sm:ml-4'>
              <Breadcrumb
                className='mr-[1rem] text-black tracking-[0.022rem]'
                text={t('TEMPLATE_PAGE_TEXT.statistics.template', { id })}
                icon={<Icon icon='chevron-right' size={20} />}
              />
              <span className='text-darkGray mt-4 m:mt-0'>{`(${versionsCount} ${numWord(
                versionsCount,
                VERSIONS[appLanguage],
              )})`}</span>
            </div>
          </div>
          <TemplatesTypeSwitcher isShowPeriodSelect className='mb-8' />
          <TemplateStatChart
            version={templateDetailActiveTab}
            template={
              templateDetailActiveTab === TEMPLATE_DETAIL_TABS.VERSION ? template : templateByTime
            }
          />
          <TemplateStatTable
            templateByTime={templateByTime}
            template={templateStatTableData}
            version={templateDetailActiveTab}
          />
          <TemplatesDetailStatsTable
            id={id || ''}
            filter={templateDetailFilter}
            versions={getVersionsForFilter}
            templateDetailStatsData={templateDetailStatsData}
            className='mt-10'
          />
          <ToastsMessages
            errorMessage={errorMessage}
            successMessage={successMessage}
            isShowErrorToast={!!errorMessage}
            isShowSuccessToast={!!successMessage}
            clearErrorCallback={clearErrorCallback}
            clearSuccessStatusCallback={clearSuccessMessageCallback}
          />
        </>
      )}
    </div>
  );
});

TemplateStat.displayName = 'TemplateStat';
