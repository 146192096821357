import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { createPortal } from 'react-dom';
import { Overlay } from '@atoms/overlay';
import { useAppDispatch } from '@store/store';
import { EmojiClickData } from 'emoji-picker-react';
import { ToggleSwitch } from '@blocks/toggleSwitch';
import { validateAutoResponseWords } from '@helpers/index';
import { TextAreaWithLabel } from '@blocks/textAreaWithLabel';
import { ClientRequestInput } from '@blocks/clientRequestInput';
import { AUTO_RESPONSE_ITEM_KEYS } from '@redux/autoResponse/models';
import { NEW_AUTO_RESPONSE_ID, AUTO_RESPONSE_MAX_LENGTH } from '@const/autoResponse';
import {
  setErrorAR,
  setActiveItemId,
  addClientRequestField,
  deleteClientRequestField,
  deleteAutoResponseElement,
  clearEmptyClientRequestField,
} from '@redux/autoResponse/autoResponseSlice';
import {
  TOnChangeHandler,
  TTextareaFocusHandler,
  TSetCursorPositionHandler,
  TetViewEmojiPickerCallback,
  TOnChangeHandlerInputTextarea,
  TClientRequestFieldOnChangeHandler,
  TInnerClientRequestFieldOnChangeHandler,
} from '@shared/types';

type TAutoResponseItemProps = {
  /**
   * Флаг сохранения изменений на странице
   * @param {boolean}
   */
  isSaving: boolean;
  /**
   * Идентификатор блока с автоответами
   * @param {string}
   */
  id: string;
  /**
   * Флаг отображает активен ли блок или нет
   * @param {boolean}
   */
  isActive: boolean;
  /**
   * Флаг удаления блока автоответов
   * @param {boolean}
   */
  isDeleting: boolean;
  /**
   * Строки на которые триггерится автоответ
   * @param {string[]}
   */
  clientRequest: string[];
  /**
   * Строка авто ответа
   * @param {string}
   */
  responseToClient: string;
  /**
   * Callback для удаления поля ввода строки запроса клиента
   * @param {() => void}
   */
  deleteAutoResponseItemsHandler: () => void;
  /**
   * Обработчик изменения строки ответа
   * @param {TOnChangeHandlerInputTextarea}
   */
  textareaOnChangeHandler: TOnChangeHandlerInputTextarea;
  /**
   * Callback включает/отключает блок автоответа
   * @param {TOnChangeHandler}
   */
  toggleAutoResponseItemActivityHandler: TOnChangeHandler;
  /**
   * Callback сохраняет изменения в блоке и отключает режим редактирования
   * @param {(callback: () => void) => void}
   */
  postNewAutoResponseItemsHandler: (callback: () => void) => void;
  /**
   * Обрабатывает изменения полей ввода запроса клиента
   * @param {TClientRequestFieldOnChangeHandler}
   */
  clientRequestFieldOnChangeHandler: TClientRequestFieldOnChangeHandler;
  /**
   * Флаг отключения кнопки сохранения
   * @param {boolean}
   */
  isSaveButtonDisable: boolean;
  /**
   * Callback для показа/скрытия блока с эмодзи
   * @param {TetViewEmojiPickerCallback}
   */
  setViewEmojiPickerCallback: TetViewEmojiPickerCallback;
  /**
   * Вставляет выбранный емодзи в textarea
   * @param {(emoji: EmojiClickData) => void}
   */
  setEmojiCallback: (emoji: EmojiClickData) => void;
  /**
   * Записывает в стейт позицию курсора
   * @param {TSetCursorPositionHandler}
   */
  setCursorPosition: TSetCursorPositionHandler;
  /**
   * Опциональный параметр - строка классов
   * @param {string}
   */
  className?: string;
};

export const AutoResponseItem = memo(
  ({
    id,
    isActive,
    isSaving,
    isDeleting,
    clientRequest,
    responseToClient,
    setEmojiCallback,
    setCursorPosition,
    isSaveButtonDisable,
    textareaOnChangeHandler,
    setViewEmojiPickerCallback,
    deleteAutoResponseItemsHandler,
    postNewAutoResponseItemsHandler,
    clientRequestFieldOnChangeHandler,
    toggleAutoResponseItemActivityHandler,
    className = '',
  }: TAutoResponseItemProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isEdit, setIsEdit] = useState(false);
    const refOnLastClientRequestItem = useRef<HTMLInputElement>(null);

    // Активирует режим редактирования для нового блока
    useEffect(() => {
      if (id === NEW_AUTO_RESPONSE_ID) {
        setIsEdit(true);
      }
    }, [id, dispatch]);

    // Скроллит страницу на новый блок
    useEffect(() => {
      if (isEdit) {
        setTimeout(() => refOnLastClientRequestItem.current?.focus(), 200);
      }
    }, [isEdit]);

    // обработчик изменения активности блока с автоответами
    const changeActivityHandler: TOnChangeHandler = useCallback(
      event => {
        toggleAutoResponseItemActivityHandler(event);
      },
      [toggleAutoResponseItemActivityHandler],
    );

    // обрабатывает изменения строки автоответа
    const innerTextareaOnChangeHandler: TOnChangeHandlerInputTextarea = useCallback(
      event => {
        textareaOnChangeHandler(event);
      },
      [textareaOnChangeHandler],
    );

    // обрабатывает изменение в полях ввода запроса клиента
    const innerClientRequestFieldOnChangeHandler: TInnerClientRequestFieldOnChangeHandler =
      useCallback(
        fieldIndex => event => {
          const { value } = event.currentTarget;
          clientRequestFieldOnChangeHandler({ value, fieldIndex });
        },
        [clientRequestFieldOnChangeHandler],
      );

    // Добавляет новые поля ввода запроса клиента
    const addClientRequestFieldHandler = useCallback(
      (itemId: string) => () => {
        dispatch(addClientRequestField({ itemId }));
      },
      [dispatch],
    );

    // Удаляет новые поля ввода запроса клиента
    const deleteClientRequestFieldHandler = useCallback(
      (itemId: string, index: number) => () => {
        dispatch(deleteClientRequestField({ itemId, fieldIndex: index }));
      },
      [dispatch],
    );

    // Удаляет пустое поле запроса клиента
    const clearEmptyClientRequestFieldHandler = useCallback(
      (itemId: string) => () => {
        dispatch(clearEmptyClientRequestField({ itemId }));
      },
      [dispatch],
    );

    // Обрабатывает переключение редактирования блока автоответов и сохраняет изменения
    const isEditToggle = useCallback(() => {
      if (isSaveButtonDisable) {
        setIsEdit(prevState => !prevState);
      } else if (isEdit) {
        postNewAutoResponseItemsHandler(() => setIsEdit(prevState => !prevState));
      } else {
        dispatch(setActiveItemId(id));
        setIsEdit(prevState => !prevState);
      }
      setViewEmojiPickerCallback({
        name: AUTO_RESPONSE_ITEM_KEYS.CLIENT_REQUEST,
        isOpenEmoji: false,
      });
    }, [
      id,
      isEdit,
      dispatch,
      isSaveButtonDisable,
      setViewEmojiPickerCallback,
      postNewAutoResponseItemsHandler,
    ]);

    // Удаляет блок
    const deleteItem = useCallback(() => {
      deleteAutoResponseItemsHandler();
    }, [deleteAutoResponseItemsHandler]);

    const isClientRequestValid = !!validateAutoResponseWords(clientRequest).length;

    // Проверяем можем ли сохранить автоответ
    const canISave =
      isClientRequestValid &&
      !!responseToClient.trim() &&
      !(responseToClient.length > AUTO_RESPONSE_MAX_LENGTH);

    // Условие для удаления автоответа
    const canIDelete = canISave && id !== NEW_AUTO_RESPONSE_ID;

    // Выводит ошибки если новый бок автоответа заполнен не верно
    const emptyBlockHandler = useCallback(
      (message: string) => () => {
        if (id === NEW_AUTO_RESPONSE_ID) {
          dispatch(deleteAutoResponseElement({ id }));
        } else if (responseToClient.length > AUTO_RESPONSE_MAX_LENGTH) {
          dispatch(
            setErrorAR(t('AUTO_RESPONSE_PAGE_TEXT.AUTO_RESPONSE_TOAST_MESSAGES.TOO_LONG_RESPONSE')),
          );
        } else {
          dispatch(setErrorAR(message));
        }
      },
      [responseToClient.length, id, t, dispatch],
    );

    const setEmojiHandler = useCallback(
      (emoji: EmojiClickData) => {
        setEmojiCallback(emoji);
      },
      [setEmojiCallback],
    );

    const setCursorPositionHandler: TTextareaFocusHandler = useCallback(
      event => {
        const { selectionStart, selectionEnd } = event.target;
        setCursorPosition({ selectionStart, selectionEnd });
      },
      [setCursorPosition],
    );

    // Массив полей ввода для рендеринга
    const clientRequests = clientRequest.map((inputValue, index) => {
      const key = index;
      const clientRequestLength = clientRequest.length;
      const isLastClientRequestElement = index === clientRequestLength - 1;

      const buttonIcon = clientRequestLength
        ? clientRequestLength === index + 1
          ? 'plus'
          : 'trash'
        : 'plus';

      const buttonOnClickHandler =
        clientRequestLength === index + 1
          ? addClientRequestFieldHandler(id)
          : deleteClientRequestFieldHandler(id, index);

      return (
        <ClientRequestInput
          key={key}
          isEdit={isEdit}
          buttonIcon={buttonIcon}
          inputValue={inputValue}
          buttonOnClickHandler={buttonOnClickHandler}
          onChangeCallback={innerClientRequestFieldOnChangeHandler(index)}
          ref={isLastClientRequestElement ? refOnLastClientRequestItem : undefined}
          onBlurCallback={
            isLastClientRequestElement ? clearEmptyClientRequestFieldHandler(id) : undefined
          }
          className='mb-4 max-w-[39rem] w-full'
        />
      );
    });

    return (
      <div
        className={`border border-quartz rounded-lg relative flex-wrap p-4 ${
          isEdit ? 'z-[60]' : ''
        } ${className}`}>
        <div className='flex items-center justify-between mb-4'>
          <ToggleSwitch
            checked={isActive}
            disabled={!canISave}
            onChangeHandler={changeActivityHandler}
            name={AUTO_RESPONSE_ITEM_KEYS.IS_ACTIVE}
            className='mr-4'
          />
          <div className='flex flex-col-reverse sm:flex-row'>
            {isEdit ? (
              <Button
                dense
                icon='trash'
                type='action'
                color='danger'
                view='outlined'
                onClick={deleteItem}
                loading={isDeleting}
                disabled={!canIDelete}
                text={t('AUTO_RESPONSE_PAGE_TEXT.deleteButton')}
                className='mt-4 sm:mt-0 sm:mr-2'
              />
            ) : null}
            <Button
              dense
              type='action'
              loading={isSaving}
              onClick={isEditToggle}
              icon={isEdit ? false : 'edit'}
              view={isEdit ? 'filled' : 'outlined'}
              color={isEdit ? 'success' : 'default'}
              text={isEdit ? t('AUTO_RESPONSE_PAGE_TEXT.saveButton') : ''}
              disabled={!isEdit ? false : !canISave || isSaveButtonDisable}
              className={isEdit ? '' : 'h-10 w-10'}
            />
          </div>
        </div>
        <div className='flex flex-wrap sm:flex-nowrap items-start justify-between'>
          <div className='flex flex-col sm:mr-10 max-w-[39rem] w-full'>{clientRequests}</div>
          <TextAreaWithLabel
            isEmoji
            stepId={id}
            minHeight={197}
            showInfo={false}
            disabled={!isEdit}
            value={responseToClient}
            maxLength={AUTO_RESPONSE_MAX_LENGTH}
            setEmojiInTextarea={setEmojiHandler}
            onBlurHandler={setCursorPositionHandler}
            onChangeHandler={innerTextareaOnChangeHandler}
            setViewEmojiPicker={setViewEmojiPickerCallback}
            htmlFor={AUTO_RESPONSE_ITEM_KEYS.RESPONSE_TO_CLIENT}
            isError={responseToClient.length > AUTO_RESPONSE_MAX_LENGTH}
            placeholder={t('AUTO_RESPONSE_PAGE_TEXT.responseToClientPlaceholder')}
            className={`${responseToClient ? '' : 'pb-2.5'} max-w-[39rem] w-full`}
          />
        </div>
        {isEdit
          ? createPortal(
              <Overlay
                onClickCallback={
                  canISave
                    ? isEditToggle
                    : emptyBlockHandler(
                        t(
                          'AUTO_RESPONSE_PAGE_TEXT.AUTO_RESPONSE_TOAST_MESSAGES.ENTERED_DATA_ERROR',
                        ),
                      )
                }
              />,
              document.body,
            )
          : null}
      </div>
    );
  },
);

AutoResponseItem.displayName = 'AutoResponseItem';
