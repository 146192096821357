import React, { memo, useCallback } from 'react';

type TMobileChatHistoryTableCard = {
  /**
   * Дата отправления сообщения
   * @param{string}
   */
  date: string;
  /**
   * Номер телефона на который отправлено сообщение
   * @param{string}
   */
  phoneNumber: string;
  /**
   * Чипс с типом сообщения
   * @param{React.ReactNode}
   */
  statusChips: React.ReactNode;
  /**
   * Callback для установки номера по клику на ячейку с номером в таблице
   * @param {(number: string) => void}
   */
  setSearchPhoneNumberCallback: (number: string) => void;
  /**
   * Компонент которы отображается в карточке
   */
  textBlock: React.ReactNode;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const MobileChatHistoryTableCard = memo(
  ({
    date,
    textBlock,
    phoneNumber,
    statusChips,
    setSearchPhoneNumberCallback,
    className = '',
  }: TMobileChatHistoryTableCard) => {
    const setSearchPhoneNumberByClick = useCallback(
      (number: string) => () => {
        setSearchPhoneNumberCallback(number);
      },
      [setSearchPhoneNumberCallback],
    );

    return (
      <div className={`border border-quartz flex flex-col pt-4 px-4 pb-2 rounded-lg ${className}`}>
        <p
          onClick={setSearchPhoneNumberByClick(phoneNumber)}
          className='cursor-pointer mb-4 text-right text-inner_text tracking-[0.018em] underline'>
          {phoneNumber}
        </p>
        {textBlock}
        <div className='flex flex-wrap items-center justify-between mt-4'>
          <div className='mb-2'>{statusChips}</div>
          <p className='mb-2 text-inner_text tracking-[0.018em]'>{date}</p>
        </div>
      </div>
    );
  },
);

MobileChatHistoryTableCard.displayName = 'MobileChatHistoryTableCard';
