import React, { memo, useEffect } from 'react';
//
import { TPromiseAbort } from '@shared/types';
import { NoAccess } from '@components/noAccess';
import { ErrorMessage } from '@blocks/errorMessage';
import { getAccountsData } from '@redux/accounts/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { ChatHistoryTemplate } from '@templates/chatHistoryTemplate';
import { getChatHistory } from '@redux/chatHistory/chatHistoryThunk';
import { getChatHistoryPageData } from '@redux/chatHistory/selectors';

const ChatHistory = memo(() => {
  const dispatch = useAppDispatch();

  const { selectedFilial } = useAppSelector(getAccountsData);

  const { isError, isAccessDenied } = useAppSelector(getChatHistoryPageData);

  useEffect(() => {
    let abort: TPromiseAbort | null = null;

    if (selectedFilial?.accId) {
      abort = dispatch(getChatHistory()).abort;
    }

    return () => {
      if (abort) {
        abort('component has been unmounted');
      }
    };
  }, [selectedFilial?.accId, dispatch]);

  return (
    <>
      {isError && !isAccessDenied ? <ErrorMessage /> : null}
      {isAccessDenied && !isError ? <NoAccess /> : null}
      {!isError && !isAccessDenied ? <ChatHistoryTemplate /> : null}
    </>
  );
});

ChatHistory.displayName = 'ChatHistory';

export default ChatHistory;
