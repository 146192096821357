import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REVIEW_MESSAGE } from '@const/reviews';
import { TRejectResponseData } from '@models/index';
import { TFullStatsData } from '@redux/reviewsStats/types';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { COMMON_TOAST_MESSAGES, SORTING_TYPES } from '@const/common';
import { REJECT_RESPONSE_2_KEY, REJECT_RESPONSE_KEY } from '@api/types';
import { getStatsDataThunk, sendStatsDataToTGThunk } from '@redux/reviewsStats/thunks';

import { IInitialState, initialState } from './initialState';

const reviewStatsSlice = createSlice({
  name: 'reviewStatsSlice',
  initialState,
  reducers: {
    setDateRangeRSP(
      state: IInitialState,
      { payload }: PayloadAction<{ startDate: string; endDate: string }>,
    ) {
      const { startDate, endDate } = payload;

      state.startDate = startDate;
      state.endDate = endDate;
      state.currentPage = 0;
    },
    setSortTypeDataRSP(state: IInitialState, { payload }: PayloadAction<{ columnName: string }>) {
      const { columnName } = payload;

      state.sortedBy = columnName;
    },
    setSortDirectionRSP(state: IInitialState, { payload }: PayloadAction<string>) {
      state.sortDirection =
        payload === SORTING_TYPES.ASCENDING ? SORTING_TYPES.DESCENDING : SORTING_TYPES.ASCENDING;
    },
    setSelectedPageRSP(state: IInitialState, { payload }: PayloadAction<number>) {
      state.currentPage = payload;
    },
    setRowsPerPageRSP(state: IInitialState, { payload }: PayloadAction<number>) {
      state.rowsPerPage = payload;
    },
    clearAllSuccessStatusesRSP(state: IInitialState) {
      state.successMessage = '';
    },
    clearAllErrorRSP(state: IInitialState) {
      state.status = 'success';
      state.errorMessage = '';
    },
  },
  extraReducers: builder => {
    builder.addCase(getStatsDataThunk.pending, (state: IInitialState) => {
      state.status = 'loading';
    });
    builder.addCase(
      getStatsDataThunk.fulfilled,
      (state: IInitialState, { payload }: PayloadAction<TFullStatsData | void>) => {
        if (payload) {
          state.status = 'success';
          state.data = payload.data;
          state.totalCount = payload.totalCount;
        }
      },
    );
    builder.addCase(
      getStatsDataThunk.rejected,
      (state: IInitialState, { payload }: PayloadAction<TRejectResponseData | undefined>) => {
        if (payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in payload) {
            const { detail } = payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.status = 'error';
            } else {
              return;
            }
          }
          if (REJECT_RESPONSE_KEY.STATUS in payload) {
            const { status } = payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.status = 'accessDenied';
            } else {
              state.status = 'error';
            }
          }
        } else {
          state.status = 'error';
        }
      },
    );
    builder.addCase(sendStatsDataToTGThunk.pending, (state: IInitialState) => {
      state.status = 'sendStatsToTG';
    });
    builder.addCase(sendStatsDataToTGThunk.fulfilled, (state: IInitialState) => {
      state.status = 'success';
      state.successMessage = REVIEW_MESSAGE.SEND_STATS_SUCCESS;
    });
    builder.addCase(
      sendStatsDataToTGThunk.rejected,
      (state: IInitialState, { payload }: PayloadAction<TRejectResponseData | undefined>) => {
        if (payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in payload) {
            const { detail } = payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.status = 'success';
              state.errorMessage = COMMON_TOAST_MESSAGES.COMMON_ERROR_MESSAGE;
            } else {
              return;
            }
          }
          if (REJECT_RESPONSE_KEY.STATUS in payload) {
            const { status } = payload;
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.status = 'accessDenied';
            }
          }
        } else {
          state.status = 'success';
          state.errorMessage = COMMON_TOAST_MESSAGES.COMMON_ERROR_MESSAGE;
        }
      },
    );
  },
});

export const reviewStatsReducer = reviewStatsSlice.reducer;

export const {
  setDateRangeRSP,
  clearAllErrorRSP,
  setRowsPerPageRSP,
  setSortTypeDataRSP,
  setSelectedPageRSP,
  setSortDirectionRSP,
  clearAllSuccessStatusesRSP,
} = reviewStatsSlice.actions;
