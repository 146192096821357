import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { TCurrency } from '@api/types';
import { TOnChangeHandler } from '@shared/types';
import { PayPromoCode } from '@components/payPromoCode';
import { ReactComponent as PayCardIcon } from '@img/pay/payCard.svg';
import { ReactComponent as BillDownloadIcon } from '@img/pay/download.svg';

type TPayButtons = {
  /**
   * Валюта для оплаты
   * @param {TCurrency}
   */
  currency: TCurrency;
  /**
   * Флаг подключения автоплатежа
   * @param {boolean}
   */
  isAutoPayEnable: boolean;
  /**
   * Флаг применения промокода
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Скрывает/показывает блок с промокодом
   * @param {boolean}
   */
  isHidePromoCodeBlock: boolean;
  /**
   * Промокод
   * @param {string}
   */
  promoCode: string;
  /**
   * Обработчик поля ввода промокода
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Callback в кнопку для применения промокода
   * @param {() => void}
   */
  applyPromoCode: () => void;
  /**
   * Опциональный параметр - строка классов
   * @param {string}
   */
  /**
   * Флаг показывает или скрывает кнопку скачать счет
   * @param {boolean}
   */
  createBill: boolean;
  /**
   * Флаг показывает состояние запроса данных для получения счета
   * @param {boolean}
   */
  isFetchingInvoiceLink: boolean;
  /**
   * Общая сумма выбранного периода оплаты
   * @param {number}
   */
  selectedCardTotalAmount: number;
  /**
   * Номер выбранной карточки оплаты
   * @param {number}
   */
  selectedCardNumber: number;
  /**
   * Callback для открытия страницы платежной системы
   * @param {() => void}
   */
  changePageOnClick: () => void;
  /**
   * Callback для подключения автоплатежа
   * @param {() => void}
   */
  getAutoPayLinkHandler: () => void;
  /**
   * Callback для открытия модалки получения счета на оплату
   * @param {() => void}
   */
  getBillModalOpen: () => void;
  /**
   * Флаг оплат опции юнями тарифа
   * @param {boolean}
   */
  payTariffDays: boolean;
  /**
   * Флаг показывает что добавлен модуль в оплату
   * @param {boolean}
   */
  isModuleSelected: boolean;
  /**
   * Флаг получения ссылки на платежную систему
   * @param {boolean}
   */
  isGettingPayLink: boolean;
  /**
   * Флаг запроса цен
   * @param {boolean}
   */
  isCalculatingCoast: boolean;
};

export const PayButtons = memo(
  ({
    currency,
    isLoading,
    promoCode,
    createBill,
    payTariffDays,
    applyPromoCode,
    isAutoPayEnable,
    onChangeHandler,
    getBillModalOpen,
    isGettingPayLink,
    isModuleSelected,
    changePageOnClick,
    isCalculatingCoast,
    selectedCardNumber,
    isHidePromoCodeBlock,
    getAutoPayLinkHandler,
    isFetchingInvoiceLink,
    selectedCardTotalAmount,
  }: TPayButtons) => {
    const { t } = useTranslation();

    const totalAmount =
      parseInt(String(selectedCardTotalAmount), 10) === selectedCardTotalAmount
        ? selectedCardTotalAmount
        : selectedCardTotalAmount.toFixed(2);

    const selectedCardTotalAmountText = t(
      currency === 'RUB'
        ? 'PAY_PAGE_TEXT.selectedCardTotalAmountText'
        : 'PAY_PAGE_TEXT.selectedCardTotalAmountTextUSD',
      {
        selectedCardTotalAmount: +totalAmount,
        paymentMethodText:
          selectedCardNumber === 1 ? t('PAY_PAGE_TEXT.cardPay') : t('PAY_PAGE_TEXT.linkPay'),
      },
    );

    const payButtonText = t('PAY_PAGE_TEXT.payButtonText', {
      selectedCardTotalAmount: +totalAmount,
      selectedCardTotalAmountText,
    });

    const payDayText = t('PAY_PAGE_TEXT.payTariffDays');

    const isPayButtonDisabled = !isModuleSelected;

    const isEnableAutoPayButton = selectedCardNumber === 1 && !isAutoPayEnable;

    const hidePayButton = selectedCardNumber === 1 && isAutoPayEnable;

    const textInPayButton = payTariffDays
      ? payDayText
      : isEnableAutoPayButton
      ? t('PAY_PAGE_TEXT.enableAutoPayment')
      : payButtonText;

    const payButtonClickHandler = useCallback(() => {
      if (isEnableAutoPayButton) {
        getAutoPayLinkHandler();
      } else {
        changePageOnClick();
      }
    }, [changePageOnClick, getAutoPayLinkHandler, isEnableAutoPayButton]);

    return (
      <div className='flex flex-col'>
        {!isHidePromoCodeBlock ? (
          <PayPromoCode
            promoCode={promoCode}
            isLoading={isLoading}
            applyPromoCode={applyPromoCode}
            onChangeHandler={onChangeHandler}
            className='mb-6'
          />
        ) : null}
        <div className='flex flex-col w-full'>
          {!hidePayButton ? (
            <Button
              fill
              dense
              type='action'
              view='filled'
              color='success'
              text={textInPayButton}
              disabled={isPayButtonDisabled}
              onClick={payButtonClickHandler}
              loading={isLoading || isGettingPayLink || isCalculatingCoast}
              icon={<PayCardIcon fill={isPayButtonDisabled ? '#87C289FF' : '#FFFFFF'} />}
              className='!h-fit w-full'
            />
          ) : null}
          {!hidePayButton &&
          createBill &&
          !isEnableAutoPayButton &&
          !payTariffDays &&
          currency === 'RUB' ? (
            <Button
              fill
              dense
              type='action'
              view='outlined'
              onClick={getBillModalOpen}
              disabled={isPayButtonDisabled}
              text={t('PAY_PAGE_TEXT.downloadBill')}
              loading={isFetchingInvoiceLink || isCalculatingCoast}
              icon={<BillDownloadIcon fill={isPayButtonDisabled ? '#9696A0FF' : '#4A4B57'} />}
              className='mt-4 w-full'
            />
          ) : null}
        </div>
      </div>
    );
  },
);

PayButtons.displayName = 'PayButtons';
