import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Icon } from '@blueprintjs/core';
import { TAppLanguage } from '@models/index';
import { NEW_SEND_OUT_ID } from '@const/mailing';

type TCalendarSendOutElementProps = {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Флаг отображения начального элемента рассылки
   * @param {boolean}
   */
  isHead: boolean;
  /**
   * Флаг отображения конечного элемента рассылки
   * @param {boolean}
   */
  isTail: boolean;
  /**
   * Флаг активной рассылки
   * @param {boolean}
   */
  isActive: boolean;
  /**
   * Опциональный номер рассылки
   * @param {number}
   */
  sendOutNumber?: string;
  /**
   * Флаг новой рассылки
   * @param {boolean}
   */
  isNewSendOut: boolean;
  /**
   * Ширина элемента в процентах, показывает остаток квоты дня
   * @param {number}
   */
  widthInPercents: number;
  /**
   * ID рассылок запущенных в этот день
   * @param {string[]}
   */
  sendOutNumbers: string[];
  /**
   * Удаляет из календаря новую рассылку
   * @param {() => void}
   */
  deleteNewSendOut: (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
  /**
   * Дата окончания рассылки
   * @param {Date | null}
   */
  endDateOfSendOut: Date | null;
  /**
   * Callback для получения данных по рассылкам в выбранном дне
   * @param {(event: React.MouseEvent<HTMLDivElement>) => void}
   */
  showSendOutData: (event: React.MouseEvent<HTMLDivElement>) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const CalendarSendOutElement = memo(
  ({
    // eslint-disable-next-line
    appLanguage,
    isHead,
    isTail,
    isActive,
    isNewSendOut,
    sendOutNumber,
    sendOutNumbers,
    showSendOutData,
    widthInPercents,
    deleteNewSendOut,
    endDateOfSendOut,
    className = '',
  }: TCalendarSendOutElementProps) => {
    const { t } = useTranslation();

    // Флаг наведения на иконку удаления новой рассылки,
    // используется для увеличения размера иконки при наведении
    const [isHovered, setIsHovered] = useState(false);

    const borderStyle = isHead ? 'border-l-[0.125rem]' : '';

    const styles = {
      newSendOut: `${borderStyle} ${isHead ? 'border-green1' : ''} text-white bg-green5`,
      sentSendOut: `${borderStyle} ${isHead ? 'border-stormGray' : ''} text-darkGray bg-gray7`,
      activeSendOut: `${borderStyle} ${isHead ? 'border-btnGreen' : ''} text-green1 bg-green4`,
    };

    const sendOutIndicatorStyle = isNewSendOut
      ? styles.newSendOut
      : isActive
      ? styles.activeSendOut
      : !isActive && sendOutNumber
      ? styles.sentSendOut
      : '';

    const buttonStyle = 'bg-none border-none flex flex items-center justify-center h-6 w-6';

    // const sendOutNumbersLength = sendOutNumbers.length;

    const sendOutIdStyle = 'text-small_text leading-5 tracking-[0.018em] pr-2';

    const sendOutQuantityWithOutNewSendOut = sendOutNumbers.filter(
      sendOut => sendOut !== NEW_SEND_OUT_ID,
    ).length;

    /* const sendOutsId =
      sendOutNumbersLength <= 2 ? (
        sendOutNumbers.map((item, index) => {
          const isLast = index === sendOutNumbersLength - 1;

          return (
            <span key={item} className={`flex ${sendOutIdStyle}`}>
              {isHead
                ? isNewSendOut
                  ? t('MAILING_PAGE_TEXT.mailingCalendar.newSendOuts')
                  : item
                  ? `${!isLast ? '№' : ''}${item}${!isLast ? ', ' : ''}`
                  : ''
                : ''}
              {isHead
                ? isNewSendOut
                  ? t('MAILING_PAGE_TEXT.mailingCalendar.newSendOuts')
                  : ''
                : ''}
            </span>
          );
        })
      ) : (
        <span className={sendOutIdStyle}>
          {`${sendOutNumbers.length} ${numWord(sendOutNumbers.length, MAILING[appLanguage])}`}
        </span>
      ); */

    const sendOutsId = (
      <span className={`flex ${isNewSendOut ? 'break-word' : 'break-all'} ${sendOutIdStyle}`}>
        {/* {isHead
              ? isNewSendOut
                ? t('MAILING_PAGE_TEXT.mailingCalendar.newSendOuts')
                : item
                ? `${!isLast ? '№' : ''}${item}${!isLast ? ', ' : ''}`
                : ''
              : ''} */}
        {isHead
          ? isNewSendOut
            ? t('MAILING_PAGE_TEXT.mailingCalendar.newSendOuts')
            : sendOutNumbers.length < 3
            ? `№${sendOutNumber}`
            : ''
          : ''}
      </span>
    );

    const width =
      (isNewSendOut && isTail && isHead) || isHead
        ? 'fit-content'
        : `clamp(2.5rem, ${widthInPercents / (sendOutQuantityWithOutNewSendOut || 1)}%, 100%)`;

    const deleteNewSendOutHandler = () => {
      if (isNewSendOut) {
        // deleteNewSendOut(event);
      }
    };

    // Изменяет локальный стэйт при хлвере на иконку
    const setIconHoveredHandler = (state: boolean) => () => {
      setIsHovered(state);
    };

    const title =
      endDateOfSendOut && !isNewSendOut
        ? t('MAILING_PAGE_TEXT.calendarSendOutElementTitle', {
            date: endDateOfSendOut.toLocaleString('ru-RU', {
              dateStyle: 'short',
              timeStyle: 'medium',
            }),
          })
        : '';

    return (
      <div
        title={title}
        style={isTail ? { width } : {}}
        onClick={isNewSendOut ? deleteNewSendOutHandler : showSendOutData}
        className={`flex pl-2 min-h-[2.5rem] w-full ${
          (isNewSendOut && isHead) || isHead ? 'ml-1' : ''
        } ${sendOutIndicatorStyle} ${isNewSendOut ? 'cursor-pointer' : ''} ${className}`}>
        {sendOutsId}
        {isTail && isNewSendOut ? (
          <div className='flex justify-end w-full'>
            <button
              onClick={deleteNewSendOut}
              onFocus={setIconHoveredHandler(true)}
              onMouseOver={setIconHoveredHandler(true)}
              onMouseLeave={setIconHoveredHandler(false)}
              className={buttonStyle}>
              <Icon icon='cross' color='white' size={isHovered ? 20 : 14} />
            </button>
          </div>
        ) : null}
      </div>
    );
  },
);
