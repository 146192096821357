import React, { memo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@uikit/Tooltip';
import { TStatsData } from '@redux/reviewsStats/types';
import { ReactComponent as Common } from '@img/mailing/segment.svg';
import { ReactComponent as LinkIcon } from '@img/reviews/to_review.svg';
import { ReactComponent as Conversion } from '@img/mailing/conversion.svg';

type TProps = {
  /**
   * Ссылка для открытия цепочки отзывов
   * @param {string}
   */
  link: string;
  /**
   * Данные для карточки
   * @param {TStatsData}
   */
  data: TStatsData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const ReviewStatisticsCard = memo(({ link, data, className = '' }: TProps) => {
  const { t } = useTranslation();

  const [isShowTooltips, setIsShowTooltips] = useState(false);

  const { negativeResults, firstStepConversion, fullConversion, totalSends } = data;

  const answersOnFirstStepPercent = (firstStepConversion / totalSends) * 100;

  const isAnswersOnFirstStepPercentInteger =
    parseInt(String(answersOnFirstStepPercent), 10) === answersOnFirstStepPercent;

  const fullPassReviewPercent = (fullConversion / totalSends) * 100;

  const isFullPassReviewPercentInteger =
    parseInt(String(fullPassReviewPercent), 10) === fullPassReviewPercent;

  const grayTextClass = 'font-medium text-stormGray text-inner_text tracking-[0.018em]';
  const statLabelClass = 'font-medium text-[1.25rem] leading-8 tracking-[0.004em]';
  const conversionValueClass = 'text-[1.25rem] leading-8 tracking-[0.0075em] mb-0.5';

  return (
    <div className={className}>
      <div className='bg-white border border-quartz rounded-lg px-4 py-6'>
        <div className='flex flex-wrap justify-between'>
          <div className='flex flex-col justify-between w-full lg:max-w-[25.375rem]'>
            <div className='flex items-center'>
              <span className='font-[600] text-blackText leading-8 tracking-[0.004em] hover:no-underline hover:text-blackText mr-2'>
                {t('REVIEW_PAGE_TEXT.statisticsCard.reviewLink')}
              </span>
              <div className='h-[1.3rem]'>
                <Tooltip
                  position='top'
                  color='default'
                  isOpen={isShowTooltips}
                  content={<span>{t('REVIEW_PAGE_TEXT.statisticsCard.tooltip')}</span>}>
                  <NavLink to={link}>
                    <LinkIcon
                      onMouseOver={() => setIsShowTooltips(true)}
                      onMouseLeave={() => setIsShowTooltips(false)}
                    />
                  </NavLink>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className='flex flex-col sm:flex-row grow lg:ml-6 mt-6 lg:mt-0'>
            <div className='flex flex-col grow basis-[33%] mb-6 sm:mb-0'>
              <div className='flex items-start mb-2'>
                <Conversion className='mr-4' />
                <span className={statLabelClass}>
                  {t('REVIEW_PAGE_TEXT.statisticsCard.conversion')}
                </span>
              </div>
              <div className='flex flex-col mb-4'>
                <div className='flex items-center'>
                  <span className={`${statLabelClass} text-blackText mr-1 mb-0.5`}>
                    {firstStepConversion}
                  </span>
                  <span className={`${statLabelClass} text-stormGray mr-1 mb-0.5`}>{`\u00B7`}</span>
                  <span className={`${conversionValueClass} text-orangeText`}>
                    {isAnswersOnFirstStepPercentInteger
                      ? answersOnFirstStepPercent
                      : answersOnFirstStepPercent.toFixed(2)}
                    %
                  </span>
                </div>
                <span className={grayTextClass}>
                  {t('REVIEW_PAGE_TEXT.statisticsCard.firstStepConversion')}
                </span>
              </div>
              <div className='flex flex-col'>
                <div className='flex items-center'>
                  <span className={`${statLabelClass} text-blackText mr-1 mb-0.5`}>
                    {fullConversion}
                  </span>
                  <span className={`${statLabelClass} text-stormGray mr-1 mb-0.5`}>{`\u00B7`}</span>
                  <span className={`${conversionValueClass} text-btnGreen`}>
                    {isFullPassReviewPercentInteger
                      ? fullPassReviewPercent
                      : fullPassReviewPercent.toFixed(2)}
                    %
                  </span>
                </div>
                <span className={grayTextClass}>
                  {t('REVIEW_PAGE_TEXT.statisticsCard.fullConversion')}
                </span>
              </div>
            </div>
            <div className='flex flex-col grow basis-[33%]'>
              <div className='flex items-start mb-2'>
                <Common className='mr-4' />
                <span className={statLabelClass}>
                  {t('REVIEW_PAGE_TEXT.statisticsCard.common')}
                </span>
              </div>
              <div className='flex flex-col mb-4'>
                <span className={`font-medium text-blackText ${statLabelClass} mb-0.5`}>
                  {totalSends}
                </span>
                <span className={grayTextClass}>
                  {t('REVIEW_PAGE_TEXT.statisticsCard.totalSends')}
                </span>
              </div>
              <div className='flex flex-col'>
                <span className={`font-medium text-blackText ${statLabelClass} mb-0.5`}>
                  {negativeResults}
                </span>
                <span className={grayTextClass}>
                  {t('REVIEW_PAGE_TEXT.statisticsCard.negativeResults')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

ReviewStatisticsCard.displayName = 'ReviewStatisticsCard';
