import React, { useEffect } from 'react';
import { log } from '@helpers/index';
import { ErrorMessage } from '@blocks/errorMessage';

type TErrorComponent = {
  error: Error | null;
};

const ErrorComponent: React.FC<TErrorComponent> = ({ error }) => {
  useEffect(() => {
    log('Error:', error);
  }, [error]);

  return (
    <main className='h-screen flex flex-col items-center justify-center'>
      <ErrorMessage />
    </main>
  );
};

export default ErrorComponent;
