import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//
import { dateSort, simpleSort } from '@helpers/index';
import { TMailingTableData } from '@redux/mailing/models';
import { IInitialState, tableInitialState } from '@redux/mailing/tableInitialState';

export const mailingSlice = createSlice({
  name: 'mailingTable',
  initialState: tableInitialState,
  reducers: {
    setTableDataMP(state: IInitialState, action: PayloadAction<TMailingTableData[]>) {
      state.data = action.payload;
      state.filteredData = action.payload;
      state.mobileData = action.payload.slice(0, state.pageSize);
      state.totalMobilePages = Math.ceil(action.payload.length / state.pageSize);
    },
    showMoreMP(state: IInitialState) {
      const { pageSize, currentMobilePage, data } = state;
      const startOfPageSlice = pageSize * currentMobilePage;
      const endOfPageSlice = startOfPageSlice + pageSize;
      const nextSlice = data.slice(startOfPageSlice, endOfPageSlice);
      state.mobileData = [...state.mobileData, ...nextSlice];
      state.currentMobilePage += 1;
    },
    setTableSearchStringMP(state: IInitialState, action: PayloadAction<{ value: string }>) {
      const { value: searchString } = action.payload;
      if (!searchString) {
        state.filteredData = state.data;
      }
      state.searchString = searchString;

      state.filteredData = state.data.filter(
        item =>
          Object.entries(item).filter(([, value]) => {
            if (!value) return false;
            return value.toString().includes(searchString);
          }).length !== 0,
      );
    },
    clearSearchStringMP(state: IInitialState) {
      state.searchString = '';
      state.filteredData = state.data;
    },
    search(state: IInitialState) {
      const { searchString } = state;
      state.filteredData = state.data.filter(
        item =>
          Object.entries(item).filter(([, value]) => {
            if (!value) return false;
            return value.toString().includes(searchString);
          }).length !== 0,
      );
    },
    setSortTypeDataMP(
      state: IInitialState,
      action: PayloadAction<{ columnName: string; order: string }>,
    ) {
      const { columnName, order } = action.payload;
      const key = columnName as unknown as keyof TMailingTableData;
      if (columnName === 'date') {
        state.filteredData = dateSort<TMailingTableData>(state.filteredData, columnName, order);
      } else {
        state.filteredData = simpleSort<TMailingTableData>(state.filteredData, key, order);
      }
    },
  },
});

export const mailingTableReducer = mailingSlice.reducer;
export const {
  search,
  showMoreMP,
  setTableDataMP,
  setSortTypeDataMP,
  clearSearchStringMP,
  setTableSearchStringMP,
} = mailingSlice.actions;
