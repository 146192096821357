import { TGetFullTableResponse, TGetOperationListTableData } from '@api/types';

export type TOperationListTableData = {
  branch: string;
  operationDate: string;
  filialName: string;
  paySum: number;
  sumToPay: number;
  status: string;
};

export enum OPERATION_LIST_TABLE_KEY_NAMES {
  OPERATION_DATE = 'operationDate',
  FILIAL_NAME = 'filialName',
  PAY_SUM = 'paySum',
  SUM_TO_PAY = 'sumToPay',
  STATUS = 'status',
}

export type TMappedOperationListTableData = {
  data: TOperationListTableData[];
  totalAccrued: string;
};

export type TMapGetOperationListTableData = (
  data: TGetFullTableResponse<TGetOperationListTableData>,
) => TMappedOperationListTableData;
