import { getEUDateFromString } from '@helpers/index';
import { TBannerDataType, TFilialData } from '@redux/accounts/models';
import { TFilialDataResponse, TConsultantData, TConsultantResponse } from '@api/types';

export const accountsMappers = {
  /**
   * Преобразует данные ответа из API для хранилища.
   *
   * @param {TFilialDataResponse[]} data - Данные ответа из API.
   * @return {TFilialData[]} Отображенные данные в формате хранилища.
   */
  mapResponseDataToStore(data: TFilialDataResponse[]): TFilialData[] {
    return data.map(
      ({
        status,
        address,
        quantity_of_templates,
        acc_id,
        sendout_account,
        branch_id,
        is_admin_bot_integrated,
        pay_period,
        action_sendout,
        rights,
        pay_status,
      }) => ({
        status,
        address,
        quantityOfTemplates: quantity_of_templates,
        accId: acc_id,
        sendOutAccount: sendout_account,
        branchId: branch_id,
        isAdminBotIntegrated: is_admin_bot_integrated,
        payPeriod: getEUDateFromString(pay_period),
        actionSendOut: String(action_sendout),
        rights: rights || [],
        payStatus: pay_status,
        // testLoyalty: test_loyalty || false,
        testLoyalty: true,
      }),
    );
  },
  /**
   * Преобразует данные ответа для консультанта из API для хранилища.
   *
   * @param {TConsultantResponse} response - Данные ответа для консультанта.
   * @return {TConsultantData} Отображенные данные в формате хранилища.
   */
  mapConsultantResponseDataToStore({
    name,
    phone,
    bonus_days,
    promo_code,
    telegram_id,
    payments_detail,
    one_time_payment,
    partner_chat_url,
    regular_pay_rate,
    one_time_pay_rate,
    one_time_discount,
  }: TConsultantResponse): TConsultantData {
    const paymentsDetail = (payments_detail || []).map(({ branch, branch_name, payments }) => ({
      branch,
      branchName: branch_name,
      payments: payments.map(({ amount, date, first_payment, paid_till }) => ({
        amount,
        date,
        firstPayment: first_payment,
        paidTill: paid_till,
      })),
    }));

    return {
      bonusDay: bonus_days,
      name,
      oneTimePayRate: one_time_pay_rate,
      partnerChatUrl: partner_chat_url,
      paymentsDetail,
      phone,
      promoCode: promo_code,
      regularPayRate: regular_pay_rate,
      telegramId: telegram_id,
      oneTimePayment: one_time_payment,
      oneTimeDiscount: one_time_discount,
    };
  },
  /**
   * Преобразует данные баннера из ответа в хранилище.
   *
   * @param {Object} data - Данные для отображения.
   * @param {string} data.image_url - URL изображения.
   * @param {string} data.info_link - URL ссылки.
   * @returns {TBannerDataType} Отображенные данные баннера.
   */
  mapResponseBannerDataToStore({
    image_url,
    info_link,
  }: {
    image_url: string;
    info_link: string;
  }): TBannerDataType {
    return {
      imageUrl: image_url,
      infoUrl: info_link,
    };
  },
};
