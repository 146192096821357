import { COMMON_TOAST } from '@const/common';
import { validateAutoResponseWords } from '@helpers/index';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { autoResponseEmptyItem } from '@redux/autoResponse/mockData';
import { IInitialState, initialState } from '@redux/autoResponse/initialState';
import { AUTO_RESPONSE_MESSAGES, NEW_AUTO_RESPONSE_ID } from '@const/autoResponse';
import { TAutoResponseItem, TAutoResponseItemActivityCheckData } from '@redux/autoResponse/models';
import {
  TRejectResponse,
  TRejectResponse2,
  REJECT_RESPONSE_KEY,
  REJECT_RESPONSE_2_KEY,
} from '@api/types';
import {
  createAutoResponseThunk,
  getAutoResponseDataThunk,
  deleteAutoResponseItemThunk,
  updateAutoResponseItemThunk,
  autoResponseItemActivitySwitchThunk,
} from '@redux/autoResponse/autoResponseThunk';

export const autoResponseSlice = createSlice({
  name: 'autoResponse',
  initialState,
  reducers: {
    setData(state: IInitialState, action: PayloadAction<TAutoResponseItem[]>) {
      state.data = action.payload;
    },
    textareaOnChangeAR(
      state: IInitialState,
      action: PayloadAction<{ blockIndex: number; name: string; value: string }>,
    ) {
      const { blockIndex, name, value } = action.payload;
      state.data[blockIndex][name] = value;
    },
    addClientRequestField(state: IInitialState, action: PayloadAction<{ itemId: string }>) {
      const { itemId } = action.payload;

      state.data = state.data.map(item => {
        if (item.id === itemId) {
          item.clientRequest.push('');
        }
        return item;
      });
    },
    deleteClientRequestField(
      state: IInitialState,
      action: PayloadAction<{ itemId: string; fieldIndex: number }>,
    ) {
      const { itemId, fieldIndex } = action.payload;
      state.data = state.data.map(item => {
        if (item.id === itemId) {
          const newClientRequestBlock = item.clientRequest.filter(
            (_, index) => index !== fieldIndex,
          );
          return { ...item, clientRequest: newClientRequestBlock };
        }
        return item;
      });
    },
    clearEmptyClientRequestField(state: IInitialState, action: PayloadAction<{ itemId: string }>) {
      const { itemId } = action.payload;
      state.data = state.data.map(item => {
        if (
          item.id === itemId &&
          itemId !== NEW_AUTO_RESPONSE_ID &&
          item.clientRequest.length > 1
        ) {
          const newClientRequestBlock = item.clientRequest.filter(
            clientRequestItem => !!clientRequestItem,
          );

          return { ...item, clientRequest: newClientRequestBlock };
        }
        return item;
      });
    },
    onChangeClientRequestField(
      state: IInitialState,
      action: PayloadAction<{ blockIndex: number; fieldIndex: number; value: string }>,
    ) {
      const { blockIndex, fieldIndex, value } = action.payload;
      state.data[blockIndex].clientRequest[fieldIndex] = value;
    },
    addNewAutoResponseElement(state: IInitialState) {
      state.activeItemId = NEW_AUTO_RESPONSE_ID;
      state.data.push(autoResponseEmptyItem);
    },
    deleteAutoResponseElement(state: IInitialState, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      const newData = state.data.filter(item => item.id !== id);

      if (newData.length) {
        state.data = newData;
      } else {
        state.data = [autoResponseEmptyItem];
      }
    },
    setActiveItemId(state: IInitialState, action: PayloadAction<string>) {
      state.activeItemId = action.payload;
    },
    addEmojiInTextarea(
      state: IInitialState,
      action: PayloadAction<{ blockId: string; emoji: string }>,
    ) {
      const { emoji, blockId } = action.payload;
      const { selectionStart, selectionEnd } = state;

      state.data = state.data.map(autoResponse => {
        if (autoResponse.id === blockId) {
          const { responseToClient } = autoResponse;

          const newResponseToClient = `${responseToClient.substring(
            0,
            selectionStart,
          )}${emoji}${responseToClient.substring(selectionEnd)}`;

          return { ...autoResponse, responseToClient: newResponseToClient };
        }
        return autoResponse;
      });
    },
    setCursorPosition(
      state: IInitialState,
      action: PayloadAction<{ selectionStart: number; selectionEnd: number }>,
    ) {
      const { selectionEnd, selectionStart } = action.payload;

      state.selectionEnd = selectionEnd;
      state.selectionStart = selectionStart;
    },
    setErrorAR(state: IInitialState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    clearAllStatusesAR(state: IInitialState) {
      state.isError = false;
      state.errorMessage = '';
      state.successMessage = '';
    },
  },
  extraReducers: builder => {
    builder.addCase(getAutoResponseDataThunk.pending, (state: IInitialState) => {
      state.isError = false;
      state.isLoading = true;
    });
    builder.addCase(
      getAutoResponseDataThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<TAutoResponseItem[] | void>) => {
        if (action.payload) {
          const isEmptyResponse = !action.payload.length;
          const newData = isEmptyResponse ? [autoResponseEmptyItem] : action.payload;
          state.data = newData;
          state.oldData = newData;
        } else {
          state.data = [];
          state.oldData = [];
        }

        state.isLoading = false;
      },
    );
    builder.addCase(
      getAutoResponseDataThunk.rejected,
      (
        state: IInitialState,
        action: PayloadAction<TRejectResponse | TRejectResponse2 | undefined>,
      ) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
            } else {
              return;
            }
          } else if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessDenied = true;
            }
          } else {
            state.isError = true;
          }
        } else {
          state.isError = true;
        }
        state.isLoading = false;
      },
    );
    builder.addCase(createAutoResponseThunk.pending, (state: IInitialState) => {
      state.isError = false;
      state.isSaving = true;
      state.errorMessage = '';
      state.successMessage = '';
    });
    builder.addCase(
      createAutoResponseThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<string | void>) => {
        state.isSaving = false;
        state.activeItemId = '';
        if (action.payload) {
          state.errorMessage = action.payload;
        } else {
          const newData = state.data.map(item => {
            if (item.id === NEW_AUTO_RESPONSE_ID) {
              return {
                ...item,
                id: item.clientRequest.join(','),
                clientRequest: validateAutoResponseWords(item.clientRequest),
              };
            }
            return item;
          });
          state.data = newData;
          state.oldData = newData;
          state.successMessage = AUTO_RESPONSE_MESSAGES.SUCCESS;
        }
      },
    );
    builder.addCase(
      createAutoResponseThunk.rejected,
      (
        state: IInitialState,
        action: PayloadAction<TRejectResponse | TRejectResponse2 | undefined>,
      ) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
            } else {
              return;
            }
          } else if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessDenied = true;
            }
          } else {
            state.isError = true;
          }
        } else {
          state.isError = true;
        }
        state.isSaving = false;
        state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
      },
    );
    builder.addCase(updateAutoResponseItemThunk.pending, (state: IInitialState) => {
      state.isError = false;
      state.isSaving = true;
      state.errorMessage = '';
      state.successMessage = '';
    });
    builder.addCase(updateAutoResponseItemThunk.fulfilled, (state: IInitialState) => {
      const { activeItemId } = state;

      state.isSaving = false;
      state.activeItemId = '';
      const newData = state.data.map(item => {
        if (item.id === activeItemId) {
          return {
            ...item,
            id: item.clientRequest.join(','),
            clientRequest: validateAutoResponseWords(item.clientRequest),
          };
        }
        return item;
      });

      state.data = newData;
      state.oldData = newData;
      state.successMessage = AUTO_RESPONSE_MESSAGES.SUCCESS_UPDATE;
    });
    builder.addCase(
      updateAutoResponseItemThunk.rejected,
      (
        state: IInitialState,
        action: PayloadAction<TRejectResponse | TRejectResponse2 | undefined>,
      ) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
            } else {
              return;
            }
          } else if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessDenied = true;
            }
          } else {
            state.isError = true;
          }
        } else {
          state.isError = true;
        }
        state.isSaving = false;
        state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
      },
    );
    builder.addCase(deleteAutoResponseItemThunk.pending, (state: IInitialState) => {
      state.isError = false;
      state.isDeleting = true;
    });
    builder.addCase(
      deleteAutoResponseItemThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<string | void>) => {
        state.isDeleting = false;
        state.successMessage = AUTO_RESPONSE_MESSAGES.SUCCESS_DELETED;
        if (action.payload) {
          const id = action.payload;
          state.activeItemId = '';

          const newData = state.data.filter(item => item.id !== id);

          if (newData.length) {
            state.data = newData;
          } else {
            state.data = [autoResponseEmptyItem];
          }
        }
      },
    );
    builder.addCase(
      deleteAutoResponseItemThunk.rejected,
      (
        state: IInitialState,
        action: PayloadAction<TRejectResponse | TRejectResponse2 | undefined>,
      ) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
            } else {
              return;
            }
          } else if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessDenied = true;
            }
          } else {
            state.isError = true;
          }
        } else {
          state.isError = true;
        }
        state.isDeleting = false;
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
    builder.addCase(autoResponseItemActivitySwitchThunk.pending, (state: IInitialState) => {
      state.isError = false;
    });
    builder.addCase(
      autoResponseItemActivitySwitchThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<TAutoResponseItemActivityCheckData>) => {
        const { clientRequest, isActive } = action.payload;
        state.data = state.data.map(item => {
          if (item.clientRequest[0] === clientRequest[0]) {
            return { ...item, isActive };
          }
          return item;
        });
      },
    );
    builder.addCase(
      autoResponseItemActivitySwitchThunk.rejected,
      (
        state: IInitialState,
        action: PayloadAction<TRejectResponse | TRejectResponse2 | undefined>,
      ) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
            } else {
              return;
            }
          } else if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessDenied = true;
            }
          } else {
            state.isError = true;
          }
        } else {
          state.isError = true;
        }
        state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
      },
    );
  },
});

export const autoResponseReducer = autoResponseSlice.reducer;

export const {
  setData,
  setErrorAR,
  setActiveItemId,
  setCursorPosition,
  clearAllStatusesAR,
  textareaOnChangeAR,
  addEmojiInTextarea,
  addClientRequestField,
  deleteClientRequestField,
  deleteAutoResponseElement,
  addNewAutoResponseElement,
  onChangeClientRequestField,
  clearEmptyClientRequestField,
} = autoResponseSlice.actions;
