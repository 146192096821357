import { dateSort, simpleSort } from '@helpers/index';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TSelectedFilters } from '@redux/sendOutStatisticsTable/models';
import { REJECT_RESPONSE_KEY, REJECT_RESPONSE_2_KEY } from '@api/types';
import { IInitialState, initialState } from '@redux/sendOutStatisticsTable/initialState';
import {
  TRejectResponseData,
  TSendOutDetailsStatisticsData,
  SEND_OUT_DETAIL_STATISTICS_KEY_NAMES,
} from '@models/index';
import {
  getDetailSendOutStatistics,
  getDetailSendOutStatisticsDownloadLink,
} from '@redux/sendOutStatisticsTable/sendOutStatisticsTableThunks';
import {
  allSendOutFiltersIsOn,
  DETAILED_MAILING_STATISTICS_TABLE,
  SEND_OUT_DETAIL_STATISTICS_FILTERS,
} from '@const/mailing';
import i18n from 'i18next';

export const sendOutStatisticsTableSlice = createSlice({
  name: 'sendOutStatistics',
  initialState,
  reducers: {
    setDetailStatisticsData(
      state: IInitialState,
      action: PayloadAction<TSendOutDetailsStatisticsData[]>,
    ) {
      state.tableData = action.payload;
      state.filteredTableData = action.payload;
    },
    setTableSearchStringSDS(state: IInitialState, action: PayloadAction<{ value: string }>) {
      const { value: searchString } = action.payload;
      if (!searchString) {
        state.filteredTableData = state.tableData;
      }
      state.searchString = searchString;

      state.filteredTableData = state.tableData.filter(
        item =>
          Object.entries(item).filter(([, value]) => value?.toString().includes(searchString))
            .length !== 0,
      );
    },
    clearSearchStringSDS(state: IInitialState) {
      state.searchString = '';
      state.filteredTableData = state.tableData;
    },
    startSearchSDS(state: IInitialState) {
      const { searchString } = state;
      state.filteredTableData = state.tableData.filter(
        item =>
          Object.entries(item).filter(([, value]) => value?.toString().includes(searchString))
            .length !== 0,
      );
    },
    setSortTypeDataSDS(
      state: IInitialState,
      action: PayloadAction<{ columnName: string; order: string }>,
    ) {
      const { columnName, order } = action.payload;

      const key = columnName as unknown as keyof TSendOutDetailsStatisticsData;

      if (
        columnName === SEND_OUT_DETAIL_STATISTICS_KEY_NAMES.REPLY_TIME ||
        columnName === SEND_OUT_DETAIL_STATISTICS_KEY_NAMES.RECORD_TIME
      ) {
        state.filteredTableData = dateSort<TSendOutDetailsStatisticsData>(
          state.filteredTableData,
          key,
          order,
        );
      } else {
        state.filteredTableData = simpleSort<TSendOutDetailsStatisticsData>(
          state.filteredTableData,
          key,
          order,
        );
      }
    },
    setTableFilters(
      state: IInitialState,
      action: PayloadAction<{ name: TSelectedFilters; checked: boolean }>,
    ) {
      const { name, checked } = action.payload;

      if (name === SEND_OUT_DETAIL_STATISTICS_FILTERS.ALL && checked) {
        state.selectedFilters = allSendOutFiltersIsOn;
      }

      if (name === SEND_OUT_DETAIL_STATISTICS_FILTERS.ALL && !checked) {
        state.selectedFilters = [];
      }

      if (name !== SEND_OUT_DETAIL_STATISTICS_FILTERS.ALL) {
        switch (checked) {
          case true: {
            state.selectedFilters.push(name);
            break;
          }
          default: {
            state.selectedFilters = state.selectedFilters.filter(item => item !== name);
          }
        }
      }

      const responded: TSendOutDetailsStatisticsData[] = state.selectedFilters.includes(
        SEND_OUT_DETAIL_STATISTICS_FILTERS.RESPOND,
      )
        ? state.tableData.filter(
            item => item.replyTime !== DETAILED_MAILING_STATISTICS_TABLE.NO_DATA,
          )
        : [];

      const notRespond: TSendOutDetailsStatisticsData[] = state.selectedFilters.includes(
        SEND_OUT_DETAIL_STATISTICS_FILTERS.NOT_RESPOND,
      )
        ? state.tableData.filter(
            item =>
              item.replyTime === DETAILED_MAILING_STATISTICS_TABLE.NO_DATA &&
              item.recordTime === DETAILED_MAILING_STATISTICS_TABLE.NO_DATA,
          )
        : [];

      const signedUp: TSendOutDetailsStatisticsData[] = state.selectedFilters.includes(
        SEND_OUT_DETAIL_STATISTICS_FILTERS.SIGNED_UP,
      )
        ? state.tableData.filter(
            item => item.recordTime !== DETAILED_MAILING_STATISTICS_TABLE.NO_DATA,
          )
        : [];

      const newFilteredData: TSendOutDetailsStatisticsData[] = [];

      if (state.selectedFilters.includes(SEND_OUT_DETAIL_STATISTICS_FILTERS.RESPOND)) {
        newFilteredData.push(...responded);
      }
      if (state.selectedFilters.includes(SEND_OUT_DETAIL_STATISTICS_FILTERS.NOT_RESPOND)) {
        newFilteredData.push(...notRespond);
      }

      if (state.selectedFilters.includes(SEND_OUT_DETAIL_STATISTICS_FILTERS.SIGNED_UP)) {
        newFilteredData.push(...signedUp);
      }

      state.filteredTableData = newFilteredData;
    },
    clearErrorMessageSDS(state: IInitialState) {
      state.errorMessage = '';
    },
    clearSuccessMessageSDS(state: IInitialState) {
      state.successMessage = '';
    },
  },
  extraReducers: builder => {
    builder.addCase(getDetailSendOutStatistics.pending, (state: IInitialState) => {
      state.isError = false;
      state.isLoading = true;
    });
    builder.addCase(
      getDetailSendOutStatistics.fulfilled,
      (state: IInitialState, action: PayloadAction<TSendOutDetailsStatisticsData[] | void>) => {
        if (action.payload) {
          const data = action.payload;

          state.tableData = data;
          state.filteredTableData = data;
        }
        state.isLoading = false;
      },
    );
    builder.addCase(
      getDetailSendOutStatistics.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
          }
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
            }
          }
          if (REJECT_RESPONSE_KEY.MESSAGE in action.payload) {
            state.isError = true;
          }
        } else {
          state.isError = true;
        }
        state.isLoading = false;
      },
    );
    builder.addCase(getDetailSendOutStatisticsDownloadLink.pending, (state: IInitialState) => {
      state.isError = false;
      state.isSuccess = false;
    });
    builder.addCase(
      getDetailSendOutStatisticsDownloadLink.fulfilled,
      (state: IInitialState, action: PayloadAction<string | void>) => {
        if (action.payload) {
          state.downloadLink = action.payload;
          state.successMessage = i18n.t('MAILING_PAGE_TEXT.mailingDetailStat.sendSuccessMessage');
        }
      },
    );
    builder.addCase(
      getDetailSendOutStatisticsDownloadLink.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
          }
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
            }
          }
          if (REJECT_RESPONSE_KEY.MESSAGE in action.payload) {
            state.isError = true;
          }
        } else {
          state.isError = true;
        }
        state.isLoading = false;
        state.errorMessage = i18n.t('COMMON_TOAST.COMMON_ERROR_MESSAGE');
      },
    );
  },
});

export const sendOutStatisticsTableReducer = sendOutStatisticsTableSlice.reducer;
export const {
  startSearchSDS,
  setTableFilters,
  setSortTypeDataSDS,
  clearErrorMessageSDS,
  clearSearchStringSDS,
  clearSuccessMessageSDS,
  setTableSearchStringSDS,
  setDetailStatisticsData,
} = sendOutStatisticsTableSlice.actions;
