import React, { memo, useCallback } from 'react';
//
import { Loader } from '@blocks/loader';
import { TOnChangeHandler } from '@shared/types';
import { AddLeadForm } from '@blocks/addLeadForm';
import { LeadsTable } from '@components/leadsTable';
import { TAddLeadData } from '@redux/addLeads/models';
import { getAddLeadsData } from '@redux/addLeads/selectors';
import { ToastsMessages } from '@components/toastsMessages';
import { useAppDispatch, useAppSelector } from '@store/store';
import { postLeadDataThunk } from '@redux/addLeads/addLeadsThunk';
import { ADD_LEADS_KEY_NAMES } from '@redux/addLeads/initialState';
import { leadOnChangeHandler, clearAllErrorsAL } from '@redux/addLeads/addLeadsSlice';

type TAddLeadsProps = {
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const AddLeads = memo(({ className = '' }: TAddLeadsProps) => {
  const dispatch = useAppDispatch();

  const { isLoading, newLeadData, errorMessage, tableData, successMessage } =
    useAppSelector(getAddLeadsData);

  const { leadName, phone, comment, branchId } = newLeadData;

  // функция обрабатывает изменения в инпутах и записывает их в стор
  const onChangeHandler: TOnChangeHandler = useCallback(
    event => {
      const { name, value } = event.currentTarget;

      dispatch(leadOnChangeHandler({ name: name as keyof TAddLeadData, value }));
    },
    [dispatch],
  );

  // функция валидирует и записывает валидное значение телефонного номера при событии blur
  const onChangeBlacklistCallback = useCallback(
    (newPhone: string) => {
      dispatch(leadOnChangeHandler({ name: ADD_LEADS_KEY_NAMES.PHONE, value: newPhone }));
    },
    [dispatch],
  );

  // функция создает новый лид
  const createLeadHandler = useCallback(
    (leadData: TAddLeadData) => () => {
      dispatch(postLeadDataThunk(leadData));
    },
    [dispatch],
  );

  // Очищает все ошибочные состояния для предотвращения
  // повторного отображения тостов с сообщениями
  const clearAllErrorHandler = useCallback(() => {
    dispatch(clearAllErrorsAL());
  }, [dispatch]);

  // Очищает все состояния успеха для предотвращения
  // повторного отображения тостов с сообщениями
  const clearAllSuccessStatusesHandler = useCallback(() => {
    dispatch(clearAllErrorsAL());
  }, [dispatch]);

  return (
    <div className={className}>
      {isLoading ? (
        <Loader className='h-[50vh]' />
      ) : (
        <>
          <AddLeadForm
            phone={phone}
            comment={comment}
            leadName={leadName}
            branchId={branchId}
            isLoading={isLoading}
            onChangeHandler={onChangeHandler}
            createLidHandler={createLeadHandler(newLeadData)}
            onChangeBlacklistCallback={onChangeBlacklistCallback}
          />
          <LeadsTable tableData={tableData} className='mt-6' />
          <ToastsMessages
            errorMessage={errorMessage}
            successMessage={successMessage}
            isShowErrorToast={!!errorMessage}
            isShowSuccessToast={!!successMessage}
            clearErrorCallback={clearAllErrorHandler}
            clearSuccessStatusCallback={clearAllSuccessStatusesHandler}
          />
        </>
      )}
    </div>
  );
});

AddLeads.displayName = 'AddLeads';
