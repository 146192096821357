import React, { memo, useCallback } from 'react';
//
import { TDeleteStep } from '@shared/types';
import { ReviewsCard } from '@components/reviewsCard';
import { DndContainer } from '@components/dndContainer';
import { SortableItem } from '@components/sortableItem';
import { ToastsMessages } from '@components/toastsMessages';
import { getReviewsPageData } from '@redux/reviews/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { deleteReview, reviewStepDelete } from '@redux/reviews/reviewsThunks';
import { setTemplatesOrderThunk } from '@redux/templateList/templateListThunks';
import {
  clearChainErrors,
  clearSuccessStatus,
  setReviewOrderListData,
} from '@redux/reviews/reviewsSlice';

interface IReviewsListProps {
  /**
   * ID выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Флаг доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const ReviewsList = memo(
  ({ selectedFilialAccId, className = '', isEditTemplatesAccess }: IReviewsListProps) => {
    const dispatch = useAppDispatch();

    const {
      reviewsObj,
      isSaveError,
      errorMessage,
      successMessage,
      isDeletingError,
      reviewsLitOrder,
      isSwitchingError,
      isDeleteStepSuccess,
      isChainDeletingSuccess,
    } = useAppSelector(getReviewsPageData);

    const deleteChainHandler = useCallback(
      (chainId: string) => () => {
        dispatch(deleteReview({ chainId }));
      },
      [dispatch],
    );

    const deleteStepHandler: TDeleteStep = useCallback(
      ({ stepId, templateId }) => {
        dispatch(reviewStepDelete({ templateId, stepId }));
      },
      [dispatch],
    );

    const clearAllErrorHandler = useCallback(() => {
      dispatch(clearChainErrors());
    }, [dispatch]);

    const clearAllSuccessStatusHandler = useCallback(() => {
      dispatch(clearSuccessStatus());
    }, [dispatch]);

    const reviewsLitOrderLength = reviewsLitOrder.length;

    const renderChain = reviewsLitOrder.map((id, index) => {
      const key = id;
      const isNotLastElement = index < reviewsLitOrderLength - 1;
      const item = reviewsObj[id];

      return (
        <SortableItem key={key} id={+item.firstStep.id}>
          <ReviewsCard
            data={item}
            deleteStep={deleteStepHandler}
            selectedFilialAccId={selectedFilialAccId}
            reviewsChanLength={reviewsLitOrderLength}
            templateCardId={String(item.firstStep.id)}
            isEditTemplatesAccess={isEditTemplatesAccess}
            deleteChain={deleteChainHandler(item.firstStep.id)}
            className={isNotLastElement ? 'mb-6' : ''}
          />
        </SortableItem>
      );
    });

    const isShowSuccessToast = isChainDeletingSuccess || isDeleteStepSuccess;

    const isShowErrorToast = isDeletingError || isSwitchingError || isSaveError;

    const onDragEndHandler = useCallback(
      (itemsId: number[]) => {
        dispatch(
          setTemplatesOrderThunk({
            order: itemsId,
            templatesType: 'review',
          }),
        );

        dispatch(setReviewOrderListData(itemsId));
      },
      [dispatch],
    );

    return (
      <div className={`flex flex-col ${className}`}>
        <DndContainer itemsId={reviewsLitOrder} onDragEndCallback={onDragEndHandler}>
          {renderChain}
        </DndContainer>
        <ToastsMessages
          errorMessage={errorMessage}
          successMessage={successMessage}
          isShowErrorToast={isShowErrorToast}
          isShowSuccessToast={isShowSuccessToast}
          clearErrorCallback={clearAllErrorHandler}
          clearSuccessStatusCallback={clearAllSuccessStatusHandler}
        />
      </div>
    );
  },
);

ReviewsList.displayName = 'ReviewsList';
