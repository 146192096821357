import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { EmojiClickData } from 'emoji-picker-react';
import { TAppLanguage, THelp } from '@models/index';
import { DivWithLabel } from '@components/divWithLabel';
import { TEXTAREA_TEXT_MAX_LENGTH } from '@const/common';
import { useAppDispatch, useAppSelector } from '@store/store';
import { PLACEHOLDER_VARIANTS_IF } from '@const/inviteFriend';
import { ACTION_DATA_KEY_NAMES } from '@redux/inviteFriendsAction/type';
import { InviteFriendsPreview } from '@components/inviteFriendsPreview';
import { getInviteFriendsActionData } from '@redux/inviteFriendsAction/selectors';
import { setTextareaValueIF } from '@redux/inviteFriendsAction/inviteFriendSlice';
import {
  TDivOnClickHandler,
  TDivOnKeyUpHandler,
  TOnEmojiViewChange,
  TSetViewEmojiPicker,
  TIFDivOnInputHandler,
} from '@shared/types';

type TInviteFriendsMessagesProps = {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Текст первого сообщения
   * @param {string}
   */
  firstMessage: string;
  /**
   * Текст второго сообщения
   * @param {string}
   */
  secondMessage: string;
  /**
   * Callback срабатывает на клик по диву
   * @param {TDivOnClickHandler}
   */
  divOnClickHandler: TDivOnClickHandler;
  /**
   * Callback устанавливает положение курсора
   * @param {TDivOnKeyUpHandler}
   */
  setCursorPositionHandler: TDivOnKeyUpHandler;
  /**
   * Опциональный параметр callback для записи выбранного эмодзи в textarea
   * @param {(emoji: EmojiClickData, event: MouseEvent) => void}
   */
  setEmojiInTextarea?: (emoji: EmojiClickData, event: MouseEvent) => void;
  /**
   * Callback для открытия emojiPicker
   * @param {TOnEmojiViewChange}
   */
  onEmojiViewChange: TOnEmojiViewChange;
  /**
   * Опциональный параметр
   * @param {TSetViewEmojiPicker}
   */
  setViewEmojiPicker?: TSetViewEmojiPicker;
  /**
   * Опциональны параметр доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const InviteFriendsMessages = memo(
  ({
    appLanguage,
    firstMessage,
    secondMessage,
    onEmojiViewChange,
    divOnClickHandler,
    setEmojiInTextarea,
    setViewEmojiPicker,
    isEditTemplatesAccess,
    setCursorPositionHandler,
    className = '',
  }: TInviteFriendsMessagesProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { textareaKeysData, inviteFriendsKeysDict } = useAppSelector(getInviteFriendsActionData);

    // Изменяет текст в поле ввода
    const divOnInputHandler: TIFDivOnInputHandler = useCallback(
      ({ name, value, selectionEnd, selectionStart, newTextareaKeysData }) => {
        dispatch(
          setTextareaValueIF({
            name,
            value,
            selectionEnd,
            selectionStart,
            newTextareaKeysData,
          }),
        );
      },
      [dispatch],
    );

    const textareaToRenderData = useMemo(
      () => [
        {
          value: firstMessage,
          isError: !firstMessage,
          htmlFor: ACTION_DATA_KEY_NAMES.FIRST_MESSAGE as keyof THelp,
          showEmojiPicker: onEmojiViewChange({
            textAreaName: ACTION_DATA_KEY_NAMES.FIRST_MESSAGE,
            textAreaValue: firstMessage,
          }),
          className: 'mb-6',
        },
        {
          value: secondMessage,
          isError: !secondMessage,
          htmlFor: ACTION_DATA_KEY_NAMES.SECOND_MESSAGE as keyof THelp,
          showEmojiPicker: onEmojiViewChange({
            textAreaName: ACTION_DATA_KEY_NAMES.SECOND_MESSAGE,
            textAreaValue: secondMessage,
          }),
          className: '',
        },
      ],
      [firstMessage, onEmojiViewChange, secondMessage],
    );

    const textAreasToRender = useMemo(
      () =>
        textareaToRenderData.map(data => (
          <DivWithLabel
            key={data.htmlFor}
            isEmoji
            isRequired
            value={data.value}
            isError={data.isError}
            htmlFor={data.htmlFor}
            disabled={!isEditTemplatesAccess}
            textareaKeysData={textareaKeysData}
            maxLength={TEXTAREA_TEXT_MAX_LENGTH}
            divOnInputHandler={divOnInputHandler}
            divOnClickHandler={divOnClickHandler}
            specialKeyDict={inviteFriendsKeysDict}
            showEmojiPicker={data.showEmojiPicker}
            setEmojiInTextarea={setEmojiInTextarea}
            setViewEmojiPicker={setViewEmojiPicker}
            setCursorPosition={setCursorPositionHandler}
            placeholder={PLACEHOLDER_VARIANTS_IF[appLanguage].enterText}
            className={data.className}
          />
        )),
      [
        appLanguage,
        textareaKeysData,
        divOnClickHandler,
        divOnInputHandler,
        setEmojiInTextarea,
        setViewEmojiPicker,
        textareaToRenderData,
        inviteFriendsKeysDict,
        isEditTemplatesAccess,
        setCursorPositionHandler,
      ],
    );

    return (
      <div className={className}>
        <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between mb-4'>
          <p className='font-medium text-[1.25rem] text-black tracking-[0.004em] mb-2 sm:mb-0'>
            {t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.messages')}
          </p>
          <InviteFriendsPreview firstMessage={firstMessage} secondMessage={secondMessage} />
        </div>
        {textAreasToRender}
      </div>
    );
  },
);

InviteFriendsMessages.displayName = 'InviteFriendsMessages';
