import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//
import { REJECT_RESPONSE_KEY } from '@api/types';
import { TRejectResponseData } from '@models/index';
import { COMMON_TOAST, REG_EXP } from '@const/common';
import { ERROR_MESSAGES, AUTO_CONNECT_MESSAGES } from '@const/autoConnect';
import { IInitialState, initialState } from '@redux/autoConnect/initialState';
import { AUTO_CONNECTION_DATA_KEYS_NAME, TAutoConnectionData } from '@redux/autoConnect/models';
import {
  sendLinkInWaThunk,
  acceptFormStepThunk,
  getCallbackCallThunk,
  getAutoConnectFormData,
} from '@redux/autoConnect/autoConnectionThunk';

export const autoConnectionSlice = createSlice({
  name: 'autoConnection',
  initialState,
  reducers: {
    setFormData(
      state: IInitialState,
      action: PayloadAction<{ accId: string; hash: string; branchId: string }>,
    ) {
      const { accId, hash, branchId } = action.payload;

      if (accId && hash && branchId) {
        state.hash = hash;
        state.accId = accId;
        state.branchId = branchId;
      } else {
        state.isError = true;
      }
    },
    onChangeAC(
      state: IInitialState,
      action: PayloadAction<{ name: AUTO_CONNECTION_DATA_KEYS_NAME; value: string }>,
    ) {
      const { name, value } = action.payload;
      state.data[name] = value;
    },
    setPreviousFieldEditingMode(state: IInitialState, action: PayloadAction<boolean>) {
      state.isEdit = action.payload;
    },
    setCallbackPhoneNumber(state: IInitialState, action: PayloadAction<string>) {
      state.callbackPhoneNumber = action.payload.replace(REG_EXP.onlyNumbers, '');
    },
    showEmojiPickerAC(state: IInitialState) {
      state.isShowEmojiPicker = true;
    },
    hideEmojiPickerAC(state: IInitialState) {
      state.isShowEmojiPicker = false;
    },
    showSuccessMessage(state: IInitialState, action: PayloadAction<string>) {
      state.isShowSuccessMessage = true;
      state.successMessage = action.payload;
    },
    hideSuccessStatus(state: IInitialState) {
      state.successMessage = '';
      state.isShowSuccessMessage = false;
    },
    showRequestACallDialog(state: IInitialState) {
      state.isShowRequestACallDialog = true;
    },
    hideRequestACallDialog(state: IInitialState) {
      state.callbackPhoneNumber = '';
      state.isShowRequestACallDialog = false;
    },
    setSelectedEmoji(state: IInitialState, action: PayloadAction<string>) {
      const newEmoji = action.payload;
      if (newEmoji) {
        const isNewEmojiInList = !!state.data.emojiList.filter(emoji => emoji === newEmoji).length;
        state.data.salonEmoji = action.payload;
        if (!isNewEmojiInList) {
          state.data.emojiList = [newEmoji, ...state.data.emojiList.slice(1)];
        }
      }
    },
    setStepId(state: IInitialState, action: PayloadAction<number>) {
      const stepId = action.payload;
      if (stepId === 2 && state.data.promoCode) {
        state.data.stepId = 3;
      } else {
        state.data.stepId = action.payload;
      }
    },
    setNewPromoCode(state: IInitialState, action: PayloadAction<string>) {
      state.newPromoCode = action.payload;
    },
    editPromoCode(state: IInitialState, action: PayloadAction<string>) {
      state.newPromoCode2 = action.payload;
    },
    linksOnChange(state: IInitialState, action: PayloadAction<{ index: number; value: string }>) {
      const { index, value } = action.payload;

      state.data.linksOnSalon[index] = value;
    },
    addLinksOnSalonItem(state: IInitialState) {
      if (state.data.linksOnSalon.length < 3) {
        state.data.linksOnSalon.push('');
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(sendLinkInWaThunk.pending, (state: IInitialState) => {
      state.isError = false;
      state.successMessage = '';
    });
    builder.addCase(sendLinkInWaThunk.fulfilled, (state: IInitialState) => {
      state.data.phoneNumberForSend = '';
      state.successMessage = AUTO_CONNECT_MESSAGES.SEND_LINK_TO_WA;
    });
    builder.addCase(sendLinkInWaThunk.rejected, (state: IInitialState) => {
      state.isError = true;
    });
    builder.addCase(getCallbackCallThunk.fulfilled, (state: IInitialState) => {
      state.callbackPhoneNumber = '';
      state.isShowRequestACallDialog = false;
      state.successMessage = AUTO_CONNECT_MESSAGES.CALLBACK;
    });
    builder.addCase(getCallbackCallThunk.rejected, (state: IInitialState) => {
      state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
    });
    builder.addCase(getAutoConnectFormData.pending, (state: IInitialState) => {
      state.isError = false;
      state.isLoading = true;
    });
    builder.addCase(
      getAutoConnectFormData.fulfilled,
      (state: IInitialState, action: PayloadAction<TAutoConnectionData | void>) => {
        if (action.payload) {
          state.isLoading = false;
          state.data = action.payload;
          state.newPromoCode2 = action.payload.promoCode;
        }
      },
    );
    builder.addCase(getAutoConnectFormData.rejected, (state: IInitialState) => {
      state.isError = true;
      state.isLoading = false;
    });
    builder.addCase(acceptFormStepThunk.pending, (state: IInitialState) => {
      state.errorMessage = '';
      state.toNextStepSwitching = true;
    });
    builder.addCase(
      acceptFormStepThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<TAutoConnectionData | void>) => {
        if (action.payload) {
          state.data = action.payload;
          state.newPromoCode2 = action.payload.promoCode;
        }
        state.toNextStepSwitching = false;
      },
    );
    builder.addCase(
      acceptFormStepThunk.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_KEY.ERROR in action.payload) {
            state.errorMessage = ERROR_MESSAGES[action.payload.error] || action.payload.error;
          }
        } else {
          state.isError = true;
        }
        state.toNextStepSwitching = false;
      },
    );
  },
});

export const autoConnectionReducer = autoConnectionSlice.reducer;
export const {
  setStepId,
  onChangeAC,
  setFormData,
  editPromoCode,
  linksOnChange,
  setNewPromoCode,
  setSelectedEmoji,
  showEmojiPickerAC,
  hideEmojiPickerAC,
  hideSuccessStatus,
  showSuccessMessage,
  addLinksOnSalonItem,
  hideRequestACallDialog,
  setCallbackPhoneNumber,
  showRequestACallDialog,
  setPreviousFieldEditingMode,
} = autoConnectionSlice.actions;
