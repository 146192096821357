import { PATH_NAMES } from '@data/dict';
import { TStatsPeriodItem } from '@shared/types';
import { TAppLanguage, TSimpleStringObj } from '@models/index';

export const TEMPLATE_PAGES_NAME_DICT: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    [PATH_NAMES.TEMPLATE_LIST_ALL]: 'All',
    [PATH_NAMES.TEMPLATE_LIST_EVENT]: 'On event',
    [PATH_NAMES.TEMPLATE_LIST_TIME]: 'By time',
    [PATH_NAMES.TEMPLATE_LIST_STATISTICS]: 'Statistics',
    [PATH_NAMES.TEMPLATE_STAT]: 'Detailed statistics',
  },
  rus: {
    [PATH_NAMES.TEMPLATE_LIST_ALL]: 'Все',
    [PATH_NAMES.TEMPLATE_LIST_EVENT]: 'При событии',
    [PATH_NAMES.TEMPLATE_LIST_TIME]: 'По времени',
    [PATH_NAMES.TEMPLATE_LIST_STATISTICS]: 'Статистика',
    [PATH_NAMES.TEMPLATE_STAT]: 'Детальная статистика',
  },
};

export enum TEMPLATE_SEND_TIME {
  SEND_BEFORE_EVENT = 'before',
  SEND_AFTER_EVENT = 'after',
}

export const TEMPLATE_SEND_EVENT_LIST: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    event: 'On event',
    beforeVisit: 'Before the visit',
    afterVisit: 'After the visit',
    allDayRecsNotifications: 'For those who have a record',
    birthday: 'For your birthday',
    integrationMessage: 'Integration message',
    reviews: 'Chain of reviews',
  },
  rus: {
    event: 'При событии',
    beforeVisit: 'До визита',
    afterVisit: 'После визита',
    allDayRecsNotifications: 'Тем у кого запись',
    birthday: 'На день рождения',
    integrationMessage: 'Сообщение интеграции',
    reviews: 'Цепочка отзывов',
  },
};

export enum TYPE_OF_TEMPLATE {
  INTEGRATION = 'integration',
  BIRTHDAY = 'birthday',
  MAILING_PAGE = 'mailing',
}

export const PLACEHOLDER_VARIANTS: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    textWhatsapp: 'Text for messenger',
    textSms: 'Text for SMS',
    whatsapp: 'Text for WhatsApp',
    whatsapp24: 'Enter text for evaluation',
    textConfirmation: 'Text when confirming the entry',
    textCancel: 'Text when canceling entry',
  },
  rus: {
    textWhatsapp: 'Текст для мессенджера',
    textSms: 'Текст для СМС',
    whatsapp: 'Текст для WhatsApp',
    whatsapp24: 'Введите текст для оценивания',
    textConfirmation: 'Текст при подтверждении записи',
    textCancel: 'Текст при отмене записи',
  },
};

export enum WHEN_SEND_VALUE {
  BEFORE_START = 'before_start',
  AFTER_END = 'after_end',
  ALL_DAY_RECS_NOTIFICATION = 'all_day_recs_notifications',
  EVENT = 'event',
  CONSENT = 'consent',
  BIRTHDAY = 'happy_birthday',
}

export enum WHEN_SEND2_VALUE {
  INTEGRATION = 'integration',
}

export const WHEN_SEND_SELECT_OPTION_NEW_TEMPLATE: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    [WHEN_SEND_VALUE.BEFORE_START]: 'Before your visit',
    [WHEN_SEND_VALUE.AFTER_END]: 'After the visit',
    [WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION]: 'For those who have a record',
    [WHEN_SEND_VALUE.EVENT]: 'On event',
    [WHEN_SEND_VALUE.BIRTHDAY]: 'For Birthday',
    [WHEN_SEND_VALUE.CONSENT]: 'Request for consent for mailings',
  },
  rus: {
    [WHEN_SEND_VALUE.BEFORE_START]: 'До визита',
    [WHEN_SEND_VALUE.AFTER_END]: 'После визита',
    [WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION]: 'Тем у кого запись',
    [WHEN_SEND_VALUE.EVENT]: 'При событии',
    [WHEN_SEND_VALUE.BIRTHDAY]: 'На День Рождения',
    [WHEN_SEND_VALUE.CONSENT]: 'Запрос согласия на рассылки',
  },
};

export const WHEN_SEND_SELECT_OPTION: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    [WHEN_SEND_VALUE.BEFORE_START]: 'Before visit',
    [WHEN_SEND_VALUE.AFTER_END]: 'After visit',
    [WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION]: 'For those who have a record',
    [WHEN_SEND_VALUE.EVENT]: 'On event',
    [WHEN_SEND_VALUE.BIRTHDAY]: 'Congratulations',
  },
  rus: {
    [WHEN_SEND_VALUE.BEFORE_START]: 'До визита',
    [WHEN_SEND_VALUE.AFTER_END]: 'После визита',
    [WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION]: 'Тем у кого запись',
    [WHEN_SEND_VALUE.EVENT]: 'При событии',
    [WHEN_SEND_VALUE.BIRTHDAY]: 'Поздравление',
  },
};

export const WHEN_SEND_SELECT_OPTION_FALLBACK = {
  eng: 'Not selected',
  rus: 'Не выбрано',
};

export enum TEMPLATE_EVENT_KEY_NAME {
  RECORD_CREATE = 'record_create',
  RECORD_UPDATE = 'record_update',
  RECORD_DELETE = 'record_delete',
  RECORD_PAID = 'record_paid',
  RECORD_DONE = 'record_done',
  RECORD_CANCELED = 'record_canceled',
  INTEGRATION = 'integration',
}

export enum CONSENT_KEY_NAMES {
  AFTER_CREATE_RECORD = 'record_create_consent',
  ON_CONFIRM_RECORD = 'record_confirm_consent',
}

export const WHEN_SEND2_SELECT_OPTION_VALUE_NEW: Record<
  TAppLanguage,
  { [key: string]: TSimpleStringObj }
> = {
  eng: {
    before_start: {
      '24.0': 'In 24 hours',
      '12.0': 'Before 12 hours',
      '6.0': 'In 6 hours',
      '3.0': 'In 3 hours',
      '2.0': 'In 2 hours',
      '1.5': 'In 1.5 hours',
      '1.0': 'In 1 hour',
      '0.5': 'In 30 minutes',
      '0.25': 'In 15 minutes',
    },
    after_end: {
      '24.0': 'In 24 hours',
      '12.0': 'In 12 hours',
      '6.0': 'In 6 hours',
      '3.0': 'In 3 hours',
      '2.0': 'In 2 hours',
      '1.5': 'In 1.5 hours',
      '1.0': 'In 1 hour',
      '0.5': 'In 30 minutes',
      '0.25': 'In 15 minutes',
    },
    all_day_recs_notifications: {
      '-1': 'Has been done',
      '0': 'Today',
      '1': 'Will be in',
    },
    event: {
      record_create: 'Creating a record',
      record_update: 'Record change',
      record_delete: 'Deleting a record',
      record_paid: 'Payment for recording',
      record_canceled: 'Record cancelled',
      record_done: 'The client has arrived',
      integration: 'Tilda Integration',
    },
    consent: {
      afterCreateRecord: 'After creating a record',
      onConfirmRecord: 'When confirming a record',
    },
    happy_birthday: {
      '1': 'Until your birthday',
      '0': 'On your birthday',
      '-1': 'After Birthday',
    },
  },
  rus: {
    before_start: {
      '24.0': 'За 24 часа',
      '12.0': 'За 12 часов',
      '6.0': 'За 6 часов',
      '3.0': 'За 3 часа',
      '2.0': 'За 2 часа',
      '1.5': 'За 1.5 часа',
      '1.0': 'За 1 час',
      '0.5': 'За 30 минут',
      '0.25': 'За 15 минут',
    },
    after_end: {
      '24.0': 'Через 24 часа',
      '12.0': 'Через 12 часов',
      '6.0': 'Через 6 часов',
      '3.0': 'Через 3 часа',
      '2.0': 'Через 2 часа',
      '1.5': 'Через 1.5 часа',
      '1.0': 'Через 1 час',
      '0.5': 'Через 30 минут',
      '0.25': 'Через 15 минут',
    },
    all_day_recs_notifications: {
      '-1': 'Была сделана',
      '0': 'Сегодня',
      '1': 'Будет через',
    },
    event: {
      record_create: 'Создание записи',
      record_update: 'Изменение записи',
      record_delete: 'Удаление записи',
      record_paid: 'Оплата записи',
      record_canceled: 'Отмена записи',
      record_done: 'Клиент пришел',
      integration: 'Интеграция Tilda',
    },
    consent: {
      record_create_consent: 'После создания записи',
      record_confirm_consent: 'При подтверждении записи',
    },
    happy_birthday: {
      '1': 'До дня рождения',
      '0': 'В День рождения',
      '-1': 'После Дня рождения',
    },
  },
};

export enum WHEN_SEND_2_VALUES {
  ALL_DAY_REC_BEFORE = '1',
  ALL_DAY_REC_TODAY = '0',
  ALL_DAY_REC_AFTER = '-1',
}

export const INPUT_WITH_DROPDOWN_LABEL_TEXT_VARIANT = [
  'services',
  'categories',
  'products',
  'staff',
  'clientCategory',
  'channels',
  'exceptionMastersNames',
];

export const testData = {
  id: '972558',
  title: 'за 30 дней до ДНЯ РОЖДЕНИЯ',
  active: true,
  text_whatsapp:
    'В преддверии Вашего дня рождения мы хотим порадовать Вас🎁\n⠀\nОт лица всего коллектива «Посольства Красоты» желаем Вам постоянного чувства уверенности в себе, благодаря безупречному внешнему виду! ⠀\n \nС заботой о Вас: Посольство Красоты🌿\n⠀\n \nПредварительная запись по телефону +7-987-404-05-88',
  text_sms: null,
  type: WHEN_SEND_VALUE.BIRTHDAY,
  when_send: '30',
  when_time: '00:00',
  attendance: [],
  services: [],
  goods: [],
  staff: [],
  categories: [],
  client_category: [],
  blacklist: [],
  visits: [],
  visits_frequency: null,
  online: false,
  confirm_record: '',
  declined_record: '',
  send_only_lost_client: false,
  send_confirm: false,
  file: 'https://storage.yandexcloud.net/bb.bucket/401871523_1690468159.jpeg',
};

export enum TIME_EVENT_NAME {
  TIMING_MESSAGE = 'timing_messages',
  EVENT_MESSAGE = 'event_messages',
}

// export const TEMPLATE_FILE_ACCEPT = ['image/*', 'video/*', '.pdf', '.doc', '.docx', '.txt'];

export const TEMPLATE_FILE_ACCEPT_MULTI_MEDEA = [
  '.jpeg',
  '.jpg',
  '.png',
  '.gif',
  '.apng',
  '.svg',
  '.bmp',
  '.ico',
  '.mp4',
  '.mov',
  '.wmv',
  '.avi',
  '.avchd',
  '.flv',
  '.f4v',
  '.swf',
  '.mkv',
];

export const TEMPLATE_FILE_ACCEPT_DOC = ['.pdf', '.doc', '.docx', '.txt'];

export const TEMPLATE_FILE_ACCEPT = [
  ...TEMPLATE_FILE_ACCEPT_MULTI_MEDEA,
  ...TEMPLATE_FILE_ACCEPT_DOC,
];

export const TEMPLATE_TOAST_MESSAGES: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    FILE_TYPE_ERROR: 'Invalid file type,\n acceptable image or video files are acceptable',
  },
  rus: {
    FILE_TYPE_ERROR: 'Неверный тип файла,\n допустимы файлы с изображениями или видео',
  },
};

export const SEND_TIME_INCREASE_STEP = {
  [WHEN_SEND_VALUE.BEFORE_START]: 15,
  [WHEN_SEND_VALUE.AFTER_END]: 15,
  [WHEN_SEND_VALUE.ALL_DAY_RECS_NOTIFICATION]: 5,
};

export enum TEMPLATE_FORM_DATA_FIELD_NAMES {
  MEDIA_FILE = 'templateMedia',
  MEDIA_FILE_NAME = 'name',
}

export enum TIME_PERIODS {
  WEEK_IN_HOURS = 168,
  WEEK_IN_DAYS = 7,
  HOUR_IN_MS = 3600000,
  TWENTY_THREE_HOURS = 64800000,
  FIFTEEN_MINUTES_IN_MS = 900000,
}

export enum TEMPLATE_DETAIL_TABS {
  VERSION = 'version',
  TIME = 'time',
}

export enum TEMPLATE_DETAIL_TIME_TABS {
  WEEK = 'week',
  MONTH = 'month',
  PERIOD = 'period',
}

export const DEFAULT_TIME_FROM = '2020-01-01T13:21:37.458Z';

export const DEFAULT_ALL_OPTIONS = 'all';

export const DEFAULT_OPTIONS_FILTER = {
  eng: [
    {
      key: DEFAULT_ALL_OPTIONS,
      text: 'All versions',
    },
    {
      key: 'divider',
      text: '',
    },
  ],
  rus: [
    {
      key: DEFAULT_ALL_OPTIONS,
      text: 'Все версии',
    },
    {
      key: 'divider',
      text: '',
    },
  ],
};

export enum TEMPLATE_DETAIL_FILTER_GROUP_BY {
  WEEK = 'week',
  MONTH = 'month',
}

export const PERIODS: Record<TAppLanguage, TStatsPeriodItem[]> = {
  eng: [
    {
      key: TEMPLATE_DETAIL_FILTER_GROUP_BY.WEEK,
      text: 'Week',
    },
    {
      key: TEMPLATE_DETAIL_FILTER_GROUP_BY.MONTH,
      text: 'Month',
    },
  ],
  rus: [
    {
      key: TEMPLATE_DETAIL_FILTER_GROUP_BY.WEEK,
      text: 'Неделя',
    },
    {
      key: TEMPLATE_DETAIL_FILTER_GROUP_BY.MONTH,
      text: 'Месяц',
    },
  ],
};

export const FILIALS: Record<TAppLanguage, string[]> = {
  eng: ['branch', 'branches', 'branches'],
  rus: ['филиал', 'филиала', 'филиалов'],
};

export const TEMPLATE_NEW_BALLOON_VALUE_POSTFIX = 'NewValue';

export const TEMPLATE_PAGE_TEXT = {
  lang: 'rus',
  template: 'Шаблон {{id}}',
  categoriesDataListNotRecord: 'Нет записей',
  templateSwitchTitle: 'Действующий шаблон',
  templateSwitch: 'Включите, чтобы шаблон начал действовать',
  templateRequired: 'Обязательные поля отмечены звёздочкой',
  templateCardAriaLabel: 'шаблон {{id}}',
  deleteModal: {
    title: 'Вы хотите удалить\n шаблон {{id}}?',
    cancelButton: 'Отмена',
    deleteButton: 'Удалить',
  },
  templateName: 'Название шаблона',
  inputPlaceholder: 'Введите название',
  templateContent: 'Контент для отправки',
  whenSendSelect: 'Выберите',
  whenSendSend: 'Отправлять',
  whenSendSendSendTime: 'в',
  whenSendDayAgo: 'назад',
  birthdayWhenSend2Before: 'За',
  birthdayWhenSend2After: 'Через',
  whenSendDayAgoPlaceholder: 'Кол-во дней',
  minutes: 'минут',
  previewTitle: 'Превью шаблона',
  showPreviewButton: 'Показать превью',
  newTemplateHeader: 'Новый шаблон',
  headerOfExistingTemplate: 'Шаблон # {{id}}',
  detailStatsButton: 'Статистика',
  templateAdditionalInfoTitle: 'Щелкните по тексту для добавления в сообщение',
  templateAdditionalInfoFullHeader: 'Ключи и форматирование текста',
  templateAdditionalInfoWRHeader: 'Форматирование текста',
  templateAdditionalInfoKeyDescription:
    'Вы можете подставить в сообщение любую информацию, для этого вставьте в текст любой из ключей:',
  templateAdditionalInfoSynonymsDescription: `Вы можете разнообразить отправляемые сообщения добавлением синонимов в квадратных скобках [], разделяя их символом \xa0|`,
  templateAdditionalInfoSynonymsDescription2: `Например: \n [Здравствуйте|Добрый день|Приветствую]`,
  templateAdditionalInfoSynonymsDescriptionLink: 'подробнее в статье',
  templateAdditionalInfoSpecialSymbolsDescription: 'Спецсимволы для форматирования текста:',
  templateAdditionalInfoSpecialSymbolsBold: 'текст жирным',
  templateAdditionalInfoSpecialSymbolsItalic: 'текст курсивом',
  templateAdditionalInfoSpecialSymbolsStrikethrough: 'зачеркнутый текст',
  templateAdditionalInfoSpecialSymbolsMonospace: 'моноширинный текст',
  templateAdditionalInfoCallout:
    'Вы можете нажать на ключ или спецсимвол и он подставится в текст. Чтобы добавить форматирование, выделите текст и нажмите символ форматирования, например *текст*',
  templateAdditionalInfoCalloutWR:
    'Чтобы добавить форматирование, выделите текст и нажмите символ форматирования, например *текст*',
  addMediaFile: 'Загрузить медиафайл',
  mediaFileSizeError: 'Размер файла превышает 20 мегабайт',
  sendCondition: 'Условия отправки',
  visitsQuantityFrom: 'От',
  visitsQuantityTo: 'до',
  inclusive: 'включительно',
  visitsQuantityClearButton: 'Очистить',
  visitsSend: 'Отправлять каждую',
  visits: 'запись',
  blacklistPlaceholder: 'Введите номер телефона',
  wrSend: 'Тем, кто писал',
  wrDayBefore: 'назад',
  wrSendAt: 'Отправка в',
  additionalSendConditions: 'Дополнительные условия',
  sendOnlyLostClientHeader: 'Отправлять только потерявшимся клиентам',
  sendOnlyLostClientDescription: 'Отправлять сообщения клиентам, которые давно не были',
  onlineRecordHeader: 'Отправлять только при онлайн записи',
  onlineRecordDescription: 'Отправлять сообщения клиентам, которые записались онлайн',
  confirmRecordHeader: 'Подтверждать запись',
  confirmRecordDescription: 'При подтверждении записи, клиентам отправятся сообщения',
  confirmRecordPlaceholder: 'Текст при подтверждении записи',
  cancelRecordPlaceholder: 'Текст при отмене записи',
  disableConfirmRecord: 'Отключено подтверждение записи,\n эти настройки не совместимы',
  disableClientDidNotCome:
    'Отключен статус записи\n «Клиент не пришел»,\n эти настройки не совместимы',
  saveButton: 'Сохранить изменения',
  templatesDetailStatsSegmentSelect: {
    allVersion: 'Все версии',
    oneVersion: 'Версия {{version}}',
  },
  templateNav: {
    newTemplateButton: 'Новый шаблон',
    newReviews: 'Новая цепочка',
  },
  text: 'Текст',
  templateCopy: {
    header: 'Выберите филиалы',
    errorHeader: 'Ошибка копирования',
    error: 'Шаблон не скопирован в филиалы, указанные ниже:',
    selectedFilials: 'Выбрано филиалов: {{filialsToCopy}}',
    description:
      'Выберите филиалы, в которые будет скопирован шаблон. Статус шаблона (действующий/недействующий) будет скопирован для всех выбранных филиалов.',
    cancelButton: 'Отмена',
    toCopyButton: 'Скопировать',
    replyButton: 'Повторить',
    copyButton: 'Скопировать в другой филиал',
    templateCopied: 'Шаблон скопирован в {{templateCopiedNumber}} {{filials}}',
    copyTag: '(Копия)',
  },
  statistics: {
    dateSelectError: 'Неправильно \n указан диапазон дат, \n минимально диапазон 7 дней',
    versionButton: 'По версиям',
    timeButton: 'По времени',
    detailStatistics: 'Подробная статистика',
    templateNumber: 'Шаблон # {{id}}',
    templateSent: 'Шаблон отправлен',
    answered: 'Ответили',
    registered: 'Записались',
    sumOfRecords: 'Сумма записей',
    earnings: 'Заработок с 1 отпр. сообщения',
    rublSign: '₽',
    template: 'шаблон {{id}}',
    allVersion: 'Все версии',
    version: 'Версия {{version}}',
    allTemplates: 'Все шаблоны',
    percent: 'Процент ответивших и записавшихся',
    table: {
      pagination: 'Показать строк:',
      details: 'Детализация',
      version: {
        version: 'Версия шаблона',
        date: 'Дата и время Создания',
        text: 'Текст сообщения',
        sendTime: 'Отправляется через',
        sended: 'Отправлен',
        answered: 'Ответили',
        recorded: 'Записались',
        earned: 'Сумма записей, ₽',
        earnedOneMessage: 'Заработок с 1-го сообщения, ₽',
      },
      time: {
        version: 'Версии шаблонов',
        period: 'Период',
        sended: 'Отправлен',
        answered: 'Ответили',
        recorded: 'Записались',
        earned: 'Сумма записей, ₽',
        earnedOneMessage: 'Заработок с 1-го сообщения, ₽',
      },
      templatesDetailStatsTable: {
        header: 'Список клиентов, получивших сообщения',
        searchInput: {
          searchButton: 'Найти',
          placeholder: 'Найти клиента',
          description: 'По номеру телефона',
        },
        phone: 'Номер клиента',
        name: 'Имя клиента',
        version: 'Версия шаблона',
        createdAt: 'Дата и время отправки',
        replyTime: 'Дата и время ответа',
        recordTime: 'Дата и время записи',
        services: 'Услуга',
        sum: 'Сумма, ₽',
        sendToTG: 'Отправить в TG',
        sendSuccessMessage: 'Файл отправлен в телеграм',
        sumOfRecords: 'Итого сумма записей: {{totalSum}} ₽',
        downloadClientList: 'Скачать список клиентов',
      },
    },
  },
  tildaIntegrationInfo: {
    header: 'Интеграция с Tilda',
    list: {
      first: 'Войдите в свою учетную запись Tilda и откройте настройки вашего сайта',
      second: 'Перейдите в раздел',
      secondBold: 'Настройки сайта - Формы - Webhook',
      thirdStart: 'В поле',
      thirdBold: 'Webhook URL',
      thirdEnd: 'укажите адрес',
    },
    copyButton: 'Копировать',
    copyButtonSuccess: 'Скопировано',
    linkText: 'Ссылка на подробную инструкцию',
    link: 'http://www.botsarmy.biz/some-instructions',
  },
};

export const TEMPLATE_PAGE_TEXT_ENG = {
  lang: 'eng',
  categoriesDataListNotRecord: 'No records',
  templateSwitchTitle: 'Current template',
  templateSwitch: 'Turn on for the template to take effect',
  templateRequired: 'Required fields are marked with an asterisk',
  deleteModal: {
    title: 'Do you want to delete\ntemplate {{id}}?',
    cancelButton: 'Cancel',
    deleteButton: 'Delete',
  },
  templateName: 'Template name',
  inputPlaceholder: 'Enter a title',
  templateContent: 'Content to submit',
  whenSendSelect: 'Select',
  whenSendSend: 'Send',
  whenSendSendSendTime: 'at',
  whenSendDayAgo: 'back',
  birthdayWhenSend2Before: 'For',
  birthdayWhenSend2After: 'Through',
  whenSendDayAgoPlaceholder: 'Number of days',
  minutes: 'minutes',
  previewTitle: 'Template preview',
  showPreviewButton: 'Show preview',
  newTemplateHeader: 'New template',
  headerOfExistingTemplate: 'Template #',
  detailStatsButton: 'Statistics',
  templateAdditionalInfoTitle: 'Click on text to add to post',
  templateAdditionalInfoFullHeader: 'Keys and text formatting',
  templateAdditionalInfoWRHeader: 'Text Formatting',
  templateAdditionalInfoKeyDescription:
    'You can insert any information into the message; to do this, insert any of the keys into the text:',
  templateAdditionalInfoSynonymsDescription: `You can diversify the messages you send by adding synonyms in square brackets [], separating them with the character \xa0|`,
  templateAdditionalInfoSynonymsDescription2: `For example: \n [Hello|Good afternoon|Greetings]`,
  templateAdditionalInfoSynonymsDescriptionLink: 'more details in the article',
  templateAdditionalInfoSpecialSymbolsDescription: 'Special symbols for text formatting:',
  templateAdditionalInfoSpecialSymbolsBold: 'text bold',
  templateAdditionalInfoSpecialSymbolsItalic: 'italic text',
  templateAdditionalInfoSpecialSymbolsStrikethrough: 'strikethrough text',
  templateAdditionalInfoSpecialSymbolsMonospace: 'monospace text',
  templateAdditionalInfoCallout:
    'You can click on a key or a special character and it will be inserted into the text. To add formatting, select the text and click the formatting symbol, for example *text*',
  templateAdditionalInfoCalloutWR:
    'To add formatting, select text and click the formatting symbol, for example *text*',
  addMediaFile: 'Upload media file',
  mediaFileSizeError: 'The file size exceeds 20 megabytes',
  sendCondition: 'Sending conditions',
  visitsQuantityFrom: 'From',
  visitsQuantityTo: 'to',
  inclusive: 'inclusive',
  visitsQuantityClearButton: 'Clear',
  visitsSend: 'Send every',
  visits: 'visits',
  blacklistPlaceholder: 'Enter a phone number',
  wrSend: 'To those who wrote',
  wrDayBefore: 'back',
  wrSendAt: 'Sending to',
  additionalSendConditions: 'Additional conditions',
  sendOnlyLostClientHeader: 'Send only to lost clients',
  sendOnlyLostClientDescription: 'Send messages to clients who have not visited for a long time',
  onlineRecordHeader: 'Send only when recording online',
  onlineRecordDescription: 'Send messages to clients who have registered online',
  confirmRecordHeader: 'Confirm record',
  confirmRecordDescription: 'When the record is confirmed, messages will be sent to clients',
  confirmRecordPlaceholder: 'Text when confirming a record',
  cancelRecordPlaceholder: 'Text when canceling a record',
  disableConfirmRecord: 'Disabled record confirmation,\n these settings are not compatible',
  disableClientDidNotCome:
    'Disabled the record status "Client did not come",\n these settings are not compatible',
  saveButton: 'Save changes',
  templatesDetailStatsSegmentSelect: {
    allVersion: 'All versions',
    oneVersion: 'Version {{version}}',
  },
  templateNav: {
    newTemplateButton: 'New template',
    newReviews: 'New chain',
  },
  text: 'Text',
  templateCopy: {
    header: 'Select branches',
    errorHeader: 'Copy error',
    error: 'The template was not copied to the branches listed below:',
    selectedFilials: 'Selected branches: {{filialsToCopy}}',
    description:
      'Select the branches to which the template will be copied. The template status (active/inactive) will be copied for all selected branches.',
    cancelButton: 'Cancel',
    toCopyButton: 'Copy',
    replyButton: 'Repeat',
    copyButton: 'Copy to another branch',
    templateCopied: 'Template copied to {{templateCopiedNumber}} {{filials}}',
    copyTag: '(Copy)',
  },
  statistics: {
    dateSelectError: 'The date range \n specified is incorrect, \n the minimum range is 7 days',
    versionButton: 'By versions',
    timeButton: 'By time',
    detailStatistics: 'Detailed statistics',
    templateNumber: 'Template #{{id}}',
    templateSent: 'Template sent',
    answered: 'Answered',
    registered: 'Registered',
    sumOfRecords: 'Sum of records',
    earnings: 'Earnings from 1 departure. messages',
    rublSign: '₽',
    template: 'template {{id}}',
    allVersion: 'All versions',
    version: 'Version {{version}}',
    allTemplates: 'All templates',
    percent: 'Percentage of people who responded and signed up',
    table: {
      pagination: 'Show lines:',
      details: 'Detailing',
      version: {
        version: 'Template version',
        date: 'Creation date and time',
        text: 'Message text',
        sendTime: 'Sent via',
        sended: 'Sent',
        answered: 'Answered',
        recorded: 'Recorded',
        earned: 'Sum of records, ₽',
        earnedOneMessage: 'Earnings from 1st message, ₽',
      },
      time: {
        version: 'Template versions',
        period: 'Period',
        sended: 'Sent',
        answered: 'Answered',
        recorded: 'Recorded',
        earned: 'Amount of records, ₽',
        earnedOneMessage: 'Earnings from 1st message, ₽',
      },
      templatesDetailStatsTable: {
        header: 'List of clients who received messages',
        searchInput: {
          searchButton: 'Find',
          placeholder: 'Find client',
          description: 'By phone number',
        },
        phone: 'Customer number',
        name: 'Client name',
        version: 'Template version',
        createdAt: 'Date and time of sending',
        replyTime: 'Date and time of reply',
        recordTime: 'Date and time of recording',
        services: 'Service',
        sum: 'Amount, ₽',
        sendToTG: 'Send to telegram',
        sendSuccessMessage: 'File sent to TG',
        sumOfRecords: 'Total sum of records: {{totalSum}} ₽',
        downloadClientList: 'Download client list',
      },
    },
  },
};

export const VERSIONS: Record<TAppLanguage, string[]> = {
  eng: ['version', 'versions', 'versions'],
  rus: ['версия', 'версии', 'версий'],
};

export const TIME: Record<TAppLanguage, string[]> = {
  eng: ['times', 'times', 'times'],
  rus: ['раз', 'раза', 'раз'],
};

export enum ATTENDANCE {
  CLIENT_DID_NOT_COME = -1,
  CLIENT_EXPECTATION = 0,
  CLIENT_CAME = 1,
  CLIENT_CONFIRMED = 2,
}

export enum WHEN_SEND_DAYS {
  YESTERDAY = '1',
  TODAY = '0',
  TOMORROW = '-1',
}
