import React, { memo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import isEqual from '@helpers/isEqual';
import { parseDivValue } from '@helpers/index';
import { TReviewData } from '@redux/reviews/models';
import { ToggleSwitch } from '@blocks/toggleSwitch';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { ReviewsChain } from '@components/reviewsChain';
import { HeaderWithEdit } from '@components/headerWithEdit';
import { getReviewsPageData } from '@redux/reviews/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { saveReview, updateReview } from '@redux/reviews/reviewsThunks';
import { TemplateAdditionalInfo } from '@blocks/templateAdditionalInfo';
import { TClickHandler, TOnChangeHandler, TSimpleOnchangeHandler } from '@shared/types';
import {
  addStep,
  wrapTextRV,
  setOldTitle,
  setChainName,
  setChainStatus,
  addKeyInTextareaRV,
  deleteStepInOneChain,
} from '@redux/reviews/reviewsSlice';

interface IReviewComponentProps {
  /**
   * Данные для отзыва
   * @param {TReviewData}
   */
  data: TReviewData;
  /**
   * ID выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Название текстовой области, используется для добавления ключей или эмодзи
   * @param {string}
   */
  blurFromTextareaName: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const ReviewTemplate = memo(
  ({ data, selectedFilialAccId, blurFromTextareaName, className = '' }: IReviewComponentProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { id, status } = data;
    const {
      isSave,
      isLoading,
      staffNewValue,
      specialKeyDict,
      oldOneReviewData,
      servicesNewValue,
      tgAlertsNewValue,
      textareaKeysData,
      blacklistNewValue,
      recordTagNewValue,
      categoriesDataList,
      categoriesNewValue,
      whenSendSelectOption,
      whenSend2SelectOption,
      clientCategoryNewValue,
      channelsPhoneListNewValue,
    } = useAppSelector(getReviewsPageData);

    // Сохраняется выбранная пользователем div для возвращения фокуса после добавления спец ключей или обрамления текста
    const div = useRef<(EventTarget & HTMLDivElement) | null>(null);

    const isFetchingSuccess = !isLoading && (!!id || !id);

    const deleteStepHandler = (stepId: number) => () => {
      dispatch(deleteStepInOneChain({ stepId }));
    };

    const addStepHandler = useCallback(() => {
      dispatch(addStep());
    }, [dispatch]);

    const formValidateAndSave = () => {
      const htmlTextAreaElements = document.querySelectorAll(
        '.innerError',
      ) as unknown as HTMLTextAreaElement[];
      if (htmlTextAreaElements.length) {
        htmlTextAreaElements[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
        htmlTextAreaElements[0].focus();
        return;
      }
      if (!data.id) dispatch(saveReview(data));

      if (data.id) dispatch(updateReview(data));
    };

    const renderChain = (
      <ReviewsChain
        key={data.id}
        div={div}
        data={data}
        staffNewValue={staffNewValue}
        deleteStep={deleteStepHandler}
        specialKeyDict={specialKeyDict}
        servicesNewValue={servicesNewValue}
        tgAlertsNewValue={tgAlertsNewValue}
        textareaKeysData={textareaKeysData}
        recordTagNewValue={recordTagNewValue}
        isFetchingSuccess={isFetchingSuccess}
        blacklistNewValue={blacklistNewValue}
        categoriesDataList={categoriesDataList}
        categoriesNewValue={categoriesNewValue}
        selectedFilialAccId={selectedFilialAccId}
        blurFromTextareaName={blurFromTextareaName}
        whenSendSelectOption={whenSendSelectOption}
        whenSend2SelectOption={whenSend2SelectOption}
        clientCategoryNewValue={clientCategoryNewValue}
        channelsPhoneListNewValue={channelsPhoneListNewValue}
      />
    );

    // Callback передается в компонент <TemplateAdditionalInfo />
    // добавляет спец ключ в текс 'выбранной' textarea
    const setKeyInTextarea = useCallback(
      (key: string) => () => {
        if (blurFromTextareaName && div.current) {
          dispatch(addKeyInTextareaRV({ innerText: key, callback: parseDivValue(div.current) }));
        }
      },
      [div, blurFromTextareaName, dispatch],
    );

    // Оборачивает выделенный текст в выбранные спецсимволы
    const wrapSelectedTextInTextarea: TClickHandler = useCallback(
      event => {
        if (blurFromTextareaName && div.current) {
          const { innerText } = event.currentTarget; // получаем выбранный спецсимвол вместе с примером текстом в функцию для формирования новой строки передается первый символ
          dispatch(wrapTextRV({ innerText, callback: parseDivValue(div.current) }));
        }
      },
      [div, blurFromTextareaName, dispatch],
    );

    // Обработчик изменения чекбоксов
    const selectOnchangeHandler: TSimpleOnchangeHandler = useCallback(
      event => {
        const { checked } = event.currentTarget;
        dispatch(setChainStatus({ checked }));
      },
      [dispatch],
    );

    const setChainNameHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setChainName({ title: value }));
      },
      [dispatch],
    );

    const setOldTitleHandler = useCallback(
      (value: string) => {
        dispatch(setOldTitle({ title: value }));
      },
      [dispatch],
    );

    const setTitleHandler = useCallback(
      (value: string) => {
        dispatch(setChainName({ title: value }));
      },
      [dispatch],
    );

    // Проверяем на идентичность текущие данные и сохраненые при открытии страницы
    const isReviewDataChanged = isEqual(oldOneReviewData, data);

    const disableSaveButton = isSave || isReviewDataChanged;

    return (
      <div className={` ${className}`}>
        <div className='flex lg:flex-row-reverse mb-10'>
          <TemplateAdditionalInfo
            forMobile={false}
            specialKeyDict={specialKeyDict}
            setKeyInTextarea={setKeyInTextarea}
            wrapSelectedTextInTextarea={wrapSelectedTextInTextarea}
            className={`hidden xl:flex flex-col ml-10 ${
              id ? 'mt-[8rem]' : 'mt-[9.5rem]'
            } max-w-[26.44rem]`}
          />
          <div className='flex flex-col w-full xl:max-w-[47rem] mr-auto'>
            <div className='mb-10'>
              <HeaderWithEdit
                id={String(id)}
                setTitle={setTitleHandler}
                setOldTitle={setOldTitleHandler}
                oldTitle={data.templates.firstStep.oldTitle}
                emptyTitleName={
                  id ? t('REVIEW_PAGE_TEXT.header', { id }) : t('REVIEW_PAGE_TEXT.headerNewChain')
                }
                className='mb-4'
              />
              <div className='flex items-center mb-10'>
                <ToggleSwitch
                  name='status'
                  checked={status}
                  onChangeHandler={selectOnchangeHandler}
                  className='mr-4'
                />
                <div>
                  <p className='font-medium text-blackText m-0'>
                    {t('REVIEW_PAGE_TEXT.toggleDescription')}
                  </p>
                </div>
              </div>
              <div>
                <p className='font-medium text-black leading-8 tracking-[0.004em] mb-4'>
                  {t('REVIEW_PAGE_TEXT.chainName')}
                  <span className='text-statusRed text-base ml-1'>*</span>
                </p>
                <TextInput
                  fill
                  view='outlined'
                  color='default'
                  onChange={setChainNameHandler}
                  value={data.templates.firstStep.title}
                  placeholder={t('REVIEW_PAGE_TEXT.chainNamePlaceholder')}
                  className='max-w-[26.31rem]'
                />
              </div>
            </div>
            {renderChain}
          </div>
        </div>
        <div className='flex flex-col sm:flex-row'>
          <Button
            dense
            icon='plus'
            type='action'
            view='outlined'
            color='default'
            onClick={addStepHandler}
            disabled={data.templates.secondStep.length >= 3}
            text={t('REVIEW_PAGE_TEXT.REVIEW_BUTTON_TEXT.addStep')}
            className='mb-4 sm:mb-0 sm:mr-4 w-full sm:w-[12.125rem]'
          />
          <Button
            dense
            type='action'
            view='filled'
            color='success'
            loading={isSave}
            icon='small-tick'
            disabled={disableSaveButton}
            onClick={formValidateAndSave}
            text={t('REVIEW_PAGE_TEXT.REVIEW_BUTTON_TEXT.saveChain')}
            className='w-full sm:w-fit'
          />
        </div>
      </div>
    );
  },
);

ReviewTemplate.displayName = 'ReviewTemplate';
