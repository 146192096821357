import { BONUS_TYPE_RESPONSE } from '@const/inviteFriend';
import { TInviteFriendsActionListResponse } from '@api/types';
import { TInviteFriendListData } from '@redux/inviteFriendList/types';

export const inviteFriendsListMapper = {
  actionListToSate(response: TInviteFriendsActionListResponse[]): TInviteFriendListData[] {
    return response.map(({ id, bonus_type, active, title, card_type }) => {
      const cardType = card_type
        ? {
            id: card_type.id,
            title: card_type.title,
            salonGroupId: card_type.salon_group_id,
          }
        : null;

      return {
        id: String(id),
        bonusType: BONUS_TYPE_RESPONSE[bonus_type],
        isActive: active,
        title,
        cardType,
      };
    });
  },
};
