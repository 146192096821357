import React, { forwardRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import Component from './Spinner';

const propTypes = {
  /**
   ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    "default",
    "primary",
    "warning",
    "danger",
    "success",
    "primary_alt",
    "warning_alt",
    "danger_alt",
    "success_alt"
  ]),

  /**
   * If set to `true`, the button will display a centered loading spinner instead of its contents.
   * The width of the button is not affected by the value of this prop.
   */
  loading: PropTypes.bool,
  /**
   * If `true`, the button will be disabled.
   */
  disabled: PropTypes.bool
};

type TComponentPropsType = InferProps<typeof propTypes>;

interface IComponentProps extends TComponentPropsType {
  [key: string]: any;
}

export const Spinner: React.FC<IComponentProps> = forwardRef((props, ref) => <Component {...props} ref={ref} />);