import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Icon } from '@atoms/icon';
import { getDateString } from '@helpers/index';
import { WhenTime } from '@components/whenTime';

type TSendOutDatePickerProps = {
  /**
   * Время отправки рассылки
   * @param {string}
   */
  mailingStartTime: string;
  /**
   * Дата начала отправки новой рассылки
   * @param {Date}
   */
  mailingStartDate: Date;
  /**
   * Callback для отображения календаря
   * @param {() => void}
   */
  showCalendarHandler: () => void;
  /**
   * Callback для установки времени отправки
   * @param {(newTime: string) => void}
   */
  setMailingStartTimeHandler: (newTime: string) => void;
  /**
   * Флаг проверки установленного времени
   * @param {boolean}
   */
  isChecking: boolean;
  /**
   * Callback для обработки события focus
   * @param {() => void}
   */
  onFocusCallback?: () => void;
  /**
   * Callback для обработки события blur
   * @param {() => void}
   */
  onBlurCallback?: () => void;
  /**
   * Опциональный параметр отключает возможность выбора даты отправки
   * @param {boolean}
   * @default
   */
  disabled?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const SendOutDatePicker = memo(
  ({
    disabled,
    isChecking,
    onBlurCallback,
    onFocusCallback,
    mailingStartDate,
    mailingStartTime,
    showCalendarHandler,
    setMailingStartTimeHandler,
    className = '',
  }: TSendOutDatePickerProps) => {
    const { t } = useTranslation();

    const dateString = getDateString(mailingStartDate);

    return (
      <div className={className}>
        <p>{t('MAILING_PAGE_TEXT.datePickerLabel')}</p>
        <div className='flex items-center'>
          <div
            onClick={showCalendarHandler}
            className={`${
              disabled ? 'cursor-not-allowed' : 'cursor-pointer'
            } border border-dGray rounded flex items-center justify-between text-blackText text-body2 leading-6 tracking-[0.0275em] px-3 h-10 max-w-[16.5rem] w-full`}>
            {dateString}
            <Icon variant='calendar' />
          </div>
          <span className='text-black leading-6 tracking-[0.0275em] ml-4'>
            {t('MAILING_PAGE_TEXT.inTime')}
          </span>
          <WhenTime
            roundTo={5}
            isRoundCeil
            whenTime={mailingStartTime}
            onBlurCallback={onBlurCallback}
            onFocusCallback={onFocusCallback}
            disabled={disabled || isChecking}
            onChangeHandler={setMailingStartTimeHandler}
            className='ml-2'
          />
        </div>
      </div>
    );
  },
);

SendOutDatePicker.displayName = 'SendOutDatePicker';
