import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Icon } from '@atoms/icon';
import { TOnChangeHandler } from '@shared/types';
import { TIconKey, TWithChildren } from '@models/index';

interface IAddFileProps extends TWithChildren {
  /**
   * Значение параметра name в input
   * @param {string}
   */
  name: string;
  /**
   * Строка с поддерживаемыми форматами для загрузки
   * @param {string}
   */
  accept: string;
  /**
   * Опциональный параметр, если true отключает кнопку добавления файла
   * @param {boolean}
   */
  isLoading?: boolean;
  /**
   * Вариант иконки, берется по ключу из словаря
   * @param {TIconKey}
   */
  iconVariant: TIconKey;
  /**
   * Callback для обработки события change для элемента input
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Callback для открытия окна загрузки файла
   * @param {() => void}
   */
  addFileCallback: () => void;
  /**
   * Флаг ошибки, если true отобразится сообщение о превышение размера загруженного файла
   * @param {boolean}
   */
  isFileToBig: boolean;
  /**
   * Опциональный параметр позволяет отключить кнопку
   * @param {boolean}
   */
  disabled?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const AddFile = forwardRef<HTMLInputElement, IAddFileProps>(
  (
    {
      name,
      accept,
      isLoading,
      children,
      iconVariant,
      onChangeHandler,
      addFileCallback,
      isFileToBig,
      className = '',
      disabled = false,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    return (
      <>
        <button
          onClick={addFileCallback}
          disabled={isLoading || disabled}
          className={`border border-lightGray1 flex flex-col items-center justify-center rounded-lg px-4 py-2 text-green1 h-[6.125rem] w-full ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          } ${className}`}>
          <Icon variant={iconVariant} className='mb-4 h-10 w-10' />
          <span className='text-green1'>{children}</span>
        </button>
        <input
          ref={ref}
          id={name}
          name={name}
          type='file'
          accept={accept}
          className='hidden'
          onChange={onChangeHandler}
        />
        {!isFileToBig ? (
          <p className='mt-0.5 mb-0 text-stormGray text-[0.75rem]'>
            {t('MAILING_PAGE_TEXT.maxFileSize')}
          </p>
        ) : null}
      </>
    );
  },
);

AddFile.displayName = 'AddFile';
