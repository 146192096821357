import React, { memo, useMemo } from 'react';
//
import { getLinkHref } from '@helpers/index';
import { TAppLanguage } from '@models/index';
import { NavDropDown } from '@components/navDropDown';
import { NavItem } from '@blocks/templateNavItem';
import { PARTNERS_PAGES_NAME_DICT } from '@const/partners';

interface IPartnersNavProps {
  /**
   * Текущий путь, взят из URL
   * @param {string}
   */
  path: string;
  /**
   * Флаг скрывает/показывает пункт меню "Список операций"
   * @param {boolean}
   */
  isSalon: boolean;
  /**
   * Флаг определяет что аккаунт является салоном и консультантом
   * @param {boolean}
   */
  isSalonConsultant: boolean;
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const PartnersNav = memo(
  ({
    path,
    isSalon,
    appLanguage,
    isSalonConsultant,
    selectedFilialAccId,
    className = '',
  }: IPartnersNavProps) => {
    const fullPageList = useMemo(
      () => Object.entries(PARTNERS_PAGES_NAME_DICT[appLanguage]),
      [appLanguage],
    );

    const isSalonPageList = useMemo(
      () => fullPageList.filter((_, index) => index === 0 || index === 2),
      [fullPageList],
    );

    const pageDict = useMemo(
      () => (isSalon ? isSalonPageList : isSalonConsultant ? fullPageList : fullPageList),
      [fullPageList, isSalon, isSalonConsultant, isSalonPageList],
    );

    const navDropdownDict = useMemo(() => Object.fromEntries(pageDict), [pageDict]);

    const navItemToRender = useMemo(
      () =>
        pageDict.map(([link, value]) => (
          <NavItem
            key={link}
            text={value}
            isActive={path.includes(link)}
            to={getLinkHref({ route: link, accId: selectedFilialAccId })}
            className='mr-2'
          />
        )),
      [pageDict, path, selectedFilialAccId],
    );

    return (
      <div className={`flex flex-wrap justify-between ${className}`}>
        <div className='md:hidden w-full'>
          <NavDropDown
            pageNameDict={navDropdownDict}
            selectedFilialAccId={selectedFilialAccId}
            className='nav_dropdown mb-4'
          />
        </div>
        <div className='flex flex-wrap md:flex hidden'>{navItemToRender}</div>
      </div>
    );
  },
);

PartnersNav.displayName = 'PartnersNav';
