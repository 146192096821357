import { TMediaFileType } from '@models/index';

export interface IInitialState {
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isModalOpen: boolean;
  feedbackText: string;
  mediaFileType: TMediaFileType;
  mediaFileName: string;
  mediaFileTooBigError: boolean;
  mediaFile: File | null;
  mediaFileURL: string | null;
  successMessage: string;
  errorMessage: string;
}

export const initialState: IInitialState = {
  isError: false,
  mediaFile: null,
  isSuccess: false,
  isLoading: false,
  errorMessage: '',
  feedbackText: '',
  mediaFileName: '',
  mediaFileURL: null,
  isModalOpen: false,
  successMessage: '',
  mediaFileType: null,
  mediaFileTooBigError: false,
};
