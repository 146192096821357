import { TQRCodeData } from '@models/index';
import { TGetQRCodeResponse } from '@api/types';
import { TConnectionStatus } from '@redux/connection/types';
import { CONNECTION_TG_STATUS_MAPPER } from '@const/connect';

/**
 * Преобразует QR-код и статус "ok" из объекта ответа в объект TQRCodeData.
 *
 * @param {TGetQRCodeResponse} param - Объект ответа, содержащий QR-код и статус "ok".
 * @returns {TQRCodeData} - Преображенные данные QR-кода.
 */
const qrMapper = ({ qr_code, ok }: TGetQRCodeResponse): TQRCodeData => ({
  qr: qr_code,
  ok,
});

/**
 * Преобразует статусы из snake_case в camelCase
 * @param status
 */
const mapTgStatusToStore = (status: string): keyof TConnectionStatus => {
  return CONNECTION_TG_STATUS_MAPPER[status] || 'error';
};

export { qrMapper, mapTgStatusToStore };
