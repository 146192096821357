import { TChatHistoryTableData } from '@redux/chatHistory/models';

export interface IInitialState {
  isError: boolean;
  isLoading: boolean;
  isAccessDenied: boolean;
  phoneToSearch: string;
  phoneNumberUsedToSearch: string;
  filteredTableData: TChatHistoryTableData[];
  tableData: TChatHistoryTableData[];
}

export const initialState: IInitialState = {
  isError: false,
  isLoading: false,
  isAccessDenied: false,
  phoneToSearch: '',
  filteredTableData: [],
  phoneNumberUsedToSearch: '',
  tableData: [],
};
