import { apiService } from '@api/ApiService';
import { END_POINTS_URL, QUERY_PARAMS } from '@const/apiConstants';
import { TCreateLeadsData, TGetLeadsData, TGetLeadsResponseData } from '@api/types';

/**
 * Api страницы добавления лидов
 * @module addLidsApi
 */

export const addLidsApi = {
  /**
   * Метод выполняет запрос за списком лидов
   * @param data { TGetLeadsData } данные для получения лидов
   * @method getLidsData
   * @return {Promise<AxiosResponse<TGetLeadsResponseData[], any>>}
   */
  getLeadsData(data: TGetLeadsData) {
    return apiService.get<TGetLeadsResponseData[]>(END_POINTS_URL.GET_LEAD_DATA, {
      [QUERY_PARAMS.PROMO_CODE]: data.promoCode,
    });
  },

  /**
   * Метод выполняет запрос для создания нового лида
   * @param data { TCreateLeadsData } данные для добавления нового лида
   * @method postLeadData
   * @return {Promise<AxiosResponse<TGetLeadsResponseData[], any>>}
   */
  postLeadData(data: TCreateLeadsData) {
    return apiService.post<TGetLeadsResponseData[]>(END_POINTS_URL.ADD_LEAD_DATA, {
      [QUERY_PARAMS.PROMO_CODE]: data.promo_code,
      [QUERY_PARAMS.PHONE]: data.phone,
      [QUERY_PARAMS.BRANCH]: data.branch_id,
      [QUERY_PARAMS.BRANCH_NAME]: data.branch_name,
      [QUERY_PARAMS.COMMENT]: data.comment,
    });
  },
};
