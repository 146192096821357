import React from 'react';
//
import { Spinner } from '@uikit/Spinner';

export const CustomSpinner = () => {
  return (
    <Spinner
      size={40}
      color='default'
      className='absolute top-[50%] left-[53%] translate-x-[-50%] translate-y-[-50%] z-50'
    />
  );
};
