import { Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
//
import React, { memo } from 'react';

type TMailingBlockingWarningProps = {
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const MailingBlockingWarning = memo(({ className = '' }: TMailingBlockingWarningProps) => {
  const { t } = useTranslation();

  return (
    <div className={`flex items-start w-full ${className}`}>
      <div className='flex items-start'>
        <Icon icon='info-sign' color='#787885' size={20} className='p-1 mr-2' />
      </div>
      <span className='text-blackText leading-6 tracking-[0.0275em]'>
        {t('MAILING_PAGE_TEXT.mailingBlockingWarningText')}
      </span>
    </div>
  );
});

MailingBlockingWarning.displayName = 'MailingBlockingWarning';
