import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { H2 } from '@atoms/h2';
import { Chips } from '@uikit/Chips';
import { Button } from '@uikit/Button';
import { Table } from '@uikit/Table/Table';
import { TPaymentsType } from '@api/types';
import { useAppDispatch } from '@store/store';
import { TPaymentData } from '@redux/pay/types';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { PayHistoryTableCard } from '@components/payHistoryTableCard';
import { setSortTypeDataPP, showMorePayHistoryCard } from '@redux/pay/paySlice';

type TPayHistoryTable = {
  /**
   * Данные для карточек на мобилках
   * @param {TPaymentData[]}
   */
  mobileTableData: TPaymentData[];
  /**
   * Данные для таблицы
   * @param {TPaymentData[]}
   */
  filteredData: TPaymentData[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const PayHistoryTable = memo(
  ({ filteredData, mobileTableData, className = '' }: TPayHistoryTable) => {
    const { t } = useTranslation();

    const width = useWindowWidth();

    const dispatch = useAppDispatch();

    const paymentChipsText: Record<TPaymentsType, string> = useMemo(
      () => ({
        auto_payment: t('PAY_PAGE_TEXT.paymentTypes.autoPayment'),
        bonus: t('PAY_PAGE_TEXT.paymentTypes.bonus'),
        '-': t('PAY_PAGE_TEXT.paymentTypes.-'),
        link_paid: t('PAY_PAGE_TEXT.paymentTypes.linkPaid'),
        bill: t('PAY_PAGE_TEXT.paymentTypes.bill'),
        bonus_days: t('PAY_PAGE_TEXT.paymentTypes.bonusDays'),
      }),
      [t],
    );

    const getPaymentTypeChips = useCallback(
      (paymentType: TPaymentsType) => {
        const chipsColor =
          paymentType === 'bonus' || paymentType === 'bonus_days' ? 'success' : 'default';

        return (
          <Chips
            round
            noClick
            type='dense'
            color={chipsColor}
            tag={paymentChipsText[paymentType]}
          />
        );
      },
      [paymentChipsText],
    );

    // функция сортировки, получает название столбца отправляет в редакс
    const setSortTypeHandler = useCallback(
      (name: string, order: string) => {
        dispatch(setSortTypeDataPP({ columnName: name || '', order }));
      },
      [dispatch],
    );

    // Добавляет к массиву мобильных карточек еще одну "страницу"
    const showMoreHandler = useCallback(() => {
      dispatch(showMorePayHistoryCard());
    }, [dispatch]);

    const Head = useMemo(
      () => [
        { id: 'date', label: t('PAY_PAGE_TEXT.tableDate') },
        { id: 'channels', label: t('PAY_PAGE_TEXT.tableChannels'), noSort: true },
        { id: 'period', label: t('PAY_PAGE_TEXT.tablePeriod') },
        { id: 'paymentType', label: t('PAY_PAGE_TEXT.tablePaymentType'), noSort: true },
        { id: 'sum', label: t('PAY_PAGE_TEXT.tableSum') },
      ],
      [t],
    );

    const getTableData = useCallback(
      (tableData: TPaymentData[]) => {
        const emptyData = [
          {
            date: ' ',
            channels: ' ',
            period: ' ',
            paymentType: ' ',
            sum: ' ',
          },
        ];

        if (!tableData.length) {
          return emptyData;
        }

        return tableData.map(item => {
          const channelsList = item.channels.map(channel => (
            <span key={channel} className='leading-6 tracking-[0.0275em]'>
              {t(`PAY_PAGE_TEXT.channel${channel}`)}
            </span>
          ));

          return {
            ...item,
            channels: <div className='flex flex-col'>{channelsList}</div>,
            paymentType: getPaymentTypeChips(item.paymentType),
            period: t('PAY_PAGE_TEXT.tableBeforePeriod', { period: item.period }),
          };
        });
      },
      [getPaymentTypeChips, t],
    );

    return (
      <div className={className}>
        <H2 className='!font-bold text-h6Mobile text-black mb-6'>
          {t('PAY_PAGE_TEXT.tableHeader')}
        </H2>
        <div className='w-full'>
          {width > 768 ? (
            <Table
              pages
              type='def'
              view='flat'
              name={Head}
              defRows={8}
              color='default'
              checkboxes={false}
              paperClassName='no_shadow'
              rowsPerPageOptions={[5, 8, 20]}
              data={getTableData(filteredData)}
              onSortCallback={setSortTypeHandler}
              style={{ boxShadow: 'none', marginLeft: '6px' }}
              position={['left', 'left', 'left', 'left', 'right']}
              labelRowsPerPage={t('PAY_PAGE_TEXT.paginationText')}
            />
          ) : (
            mobileTableData.map(item => (
              <PayHistoryTableCard
                key={`${item.date}${item.period}${item.paymentType}`}
                filteredData={item}
                className='mb-4'
              />
            ))
          )}
          {width < 768 && mobileTableData.length !== filteredData.length ? (
            <Button
              fill
              dense
              type='action'
              icon='refresh'
              view='outlined'
              color='default'
              onClick={showMoreHandler}
              text={t('PAY_PAGE_TEXT.showMore')}
            />
          ) : null}
        </div>
      </div>
    );
  },
);

PayHistoryTable.displayName = 'PayHistoryTable';
