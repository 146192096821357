import { TAutoResponseItem } from '@redux/autoResponse/models';
import { autoResponseEmptyItem } from '@redux/autoResponse/mockData';

export interface IInitialState {
  isError: boolean;
  isSaving: boolean;
  isLoading: boolean;
  isDeleting: boolean;
  activeItemId: string;
  errorMessage: string;
  selectionEnd: number;
  selectionStart: number;
  successMessage: string;
  isAccessDenied: boolean;
  data: TAutoResponseItem[];
  oldData: TAutoResponseItem[];
}

export const initialState: IInitialState = {
  isError: false,
  isSaving: false,
  selectionEnd: 0,
  activeItemId: '',
  isLoading: false,
  errorMessage: '',
  selectionStart: 0,
  isDeleting: false,
  successMessage: '',
  isAccessDenied: false,
  data: [autoResponseEmptyItem],
  oldData: [autoResponseEmptyItem],
};
