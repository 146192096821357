import React, { memo } from 'react';
//
import { TAppLanguage } from '@models/index';
import { TableTextBlock } from '@components/tableTextBlock';
import locationImg from '@img/chatHistory/locationImage.jpg';
import { TMessageFileData } from '@redux/chatHistory/models';
import { ChatTableMessageTag } from '@blocks/chatTableMessageTag';
import { ReactComponent as Contact } from '@img/chatHistory/contact.svg';
import { ReactComponent as Location } from '@img/chatHistory/location.svg';
import { chatHistoryTableTextBlock, MESSAGES_TYPE } from '@const/chatHistory';
import { ChatHistoryMediaFilePreview } from '@blocks/chatHistoryMediaFilePreview';

type TChatHistoryTableTextBlockProps = {
  /**
   * Тело сообщения
   * @param {string}
   */
  body: string;
  /**
   * Тип сообщения
   * @param {string}
   */
  type: string;
  /**
   * Изменяет внешний вид и поведение компонента
   * @param {boolean}
   */
  isMobile: boolean;
  /**
   * Текст подписи
   * @param{string}
   */
  caption: string | undefined;
  /**
   * Данные о загруженном файле
   * @param {TMessageFileData}
   */
  fileData: TMessageFileData;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
};

export const ChatHistoryTableTextBlock = memo(
  ({ body, type, caption, fileData, isMobile, appLanguage }: TChatHistoryTableTextBlockProps) => {
    const classes = `${isMobile ? '' : 'min-w-[18.75rem] w-full'}`;

    return type === MESSAGES_TYPE.TEXT ? (
      <TableTextBlock isMobile={isMobile} text={body} className={classes} />
    ) : type === MESSAGES_TYPE.FILE ? (
      <TableTextBlock
        text=''
        isMobile={isMobile}
        showButton={isMobile || !!caption}
        renderProps={isShowCaption => (
          <ChatHistoryMediaFilePreview
            url={body}
            caption={caption}
            fileData={fileData}
            isMobile={isMobile}
            isShowCaption={isShowCaption}
          />
        )}
        className={classes}
      />
    ) : type === MESSAGES_TYPE.GEO ? (
      <TableTextBlock isMobile={isMobile} text=''>
        <ChatTableMessageTag
          isMobile={isMobile}
          IconComponent={Location}
          iconExampleSrc={locationImg}
          messageTagText={chatHistoryTableTextBlock[appLanguage].locationMessageTag}
        />
      </TableTextBlock>
    ) : type === MESSAGES_TYPE.CONTACT ? (
      <TableTextBlock isMobile={isMobile} text='' className={classes}>
        <ChatTableMessageTag
          isMobile={isMobile}
          IconComponent={Contact}
          messageTagText={`${chatHistoryTableTextBlock[appLanguage].contactMessageTag} ${
            body.split(':')[1]
          }`}
        />
      </TableTextBlock>
    ) : (
      <TableTextBlock isMobile={isMobile} text={body} className={classes} />
    );
  },
);
