import { myTrackerApi } from '@api/myTrackerApi';
import { statisticsApi } from '@api/statisticsApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TRejectResponseData } from '@models/index';
import { getCommonEventData } from '@helpers/index';
import { MY_TRACKER_EVENTS } from '@helpers/myTracker';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { clearTemplateStatisticsState } from '@redux/templateStats/templateStatsSlice';
import {
  TTemplateStatisticData,
  TTemplatesStatisticsData,
  TTemplateStatisticDataByTime,
} from '@redux/templateStats/models';
import {
  mapTemplateToStore,
  mapTemplatesToStore,
  mapTemplatesByTimeToStore,
  mapTemplateDetailDataToStore,
} from '@redux/templateStats/templateStatsMappers';
import {
  REJECT_RESPONSE_KEY,
  STATISTICS_RESPONSE_KEYS,
  TTemplateDetailStatsData,
  TTemplatesStatisticsRequestData,
} from '@api/types';

/**
 * Thunk-экшен для получения статистики шаблонов.
 *
 * @returns {TTemplatesStatisticsData[] | void} - Массив данных статистики шаблонов или void в случае неудачи.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект, представляющий информацию об ошибке в случае неудачного запроса.
 */
const getTemplatesStatisticsData = createAsyncThunk<
  TTemplatesStatisticsData[] | void,
  Omit<TTemplatesStatisticsRequestData, 'id'>,
  { rejectValue: TRejectResponseData }
>('statistics/getTemplatesStatisticsData', (data, { dispatch, rejectWithValue }) => {
  return statisticsApi
    .getTemplatesStatisticsData(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (STATISTICS_RESPONSE_KEYS.DATA in response.data) {
        myTrackerApi.openTemplateStatsPage();

        return mapTemplatesToStore(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getTemplatesStatisticsData(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшен для получения статистики конкретного шаблона.
 *
 * @param {string} templ_id - Идентификатор шаблона.
 * @returns {TTemplateStatisticData | void} - Данные статистики шаблона или void в случае неудачи.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект, представляющий информацию об ошибке в случае неудачного запроса.
 */
const getTemplateStatistic = createAsyncThunk<
  TTemplateStatisticData | void,
  TTemplatesStatisticsRequestData,
  { rejectValue: TRejectResponseData }
>('statistics/getTemplateStatistic', (data, { dispatch, rejectWithValue }) => {
  dispatch(clearTemplateStatisticsState());
  return statisticsApi
    .getTemplateStatisticDataByVersion(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (STATISTICS_RESPONSE_KEYS.COUNT in response.data) {
        myTrackerApi.openTemplateStatsByVersion(
          getCommonEventData(MY_TRACKER_EVENTS.TEMPLATE_STATS_ON_VERSION_BUTTON),
        );

        return mapTemplateToStore(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getTemplateStatistic(error.response.data.templ_id)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшен для получения статистики конкретного шаблона сгруппированного по времени.
 *
 * @param {TTemplatesStatisticsRequestData} - фьлтры шаблона.
 * @returns {TTemplateStatisticDataByTime | void} - Данные статистики шаблона или void в случае неудачи.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект, представляющий информацию об ошибке в случае неудачного запроса.
 */
const getTemplateStatisticByTimeThunk = createAsyncThunk<
  TTemplateStatisticDataByTime | void,
  TTemplatesStatisticsRequestData,
  { rejectValue: TRejectResponseData }
>('statistics/getTemplateStatisticByTimeThunk', (data, { dispatch, rejectWithValue }) => {
  dispatch(clearTemplateStatisticsState());
  return statisticsApi
    .getTemplateStatisticDataByTime(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (STATISTICS_RESPONSE_KEYS.COUNT in response.data) {
        myTrackerApi.openTemplateStatsByTime(
          getCommonEventData(MY_TRACKER_EVENTS.TEMPLATE_STATS_ON_TIME_BUTTON),
        );

        return mapTemplatesByTimeToStore(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getTemplateStatistic(error.response.data.templ_id)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

const getTemplateDetailStatsThunk = createAsyncThunk<
  TTemplateDetailStatsData | void,
  { templ_id: string },
  { rejectValue: TRejectResponseData }
>('statistics/getTemplateDetailStatsThunk', ({ templ_id }, { rejectWithValue }) => {
  return statisticsApi
    .getTemplateDetailStats(templ_id)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (STATISTICS_RESPONSE_KEYS.DATA in response.data) {
        return mapTemplateDetailDataToStore(response.data);
      }
    })
    .catch(error => rejectWithValue(error.response.data));
});

const sendTemplateDetailStatsToTGlThunk = createAsyncThunk<
  string | void,
  string,
  { rejectValue: TRejectResponseData }
>('statistics/sendTemplateDetailStatsToTGlThunk', (data, { rejectWithValue }) => {
  return statisticsApi
    .sendTemplateDetailStatsToTg(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && response.data.ok) {
        myTrackerApi.exportTemplateStats(
          getCommonEventData(MY_TRACKER_EVENTS.SEND_TEMPLATE_STATISTICS_TO_TG),
        );

        return response.data.status;
      }

      if (REJECT_RESPONSE_KEY.STATUS in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
    })
    .catch(error => rejectWithValue(error.response.data));
});

export {
  getTemplateStatistic,
  getTemplatesStatisticsData,
  getTemplateDetailStatsThunk,
  getTemplateStatisticByTimeThunk,
  sendTemplateDetailStatsToTGlThunk,
};
