import { apiService } from '@api/ApiService';
import { CONNECT_API_ACTIONS } from '@const/connect';
import { END_POINTS_URL, QUERY_PARAMS } from '@const/apiConstants';
import {
  TChannel,
  TRequestStatus,
  TGetQRCodeResponse,
  TGetConnectionStatusResponse,
  TGetAuthCodeResponse,
} from '@api/types';

/**
 * API страницы подключения
 * @module connectionApi
 */

export const connectionApi = {
  /**
   * Метод делает запрос за данными для страницы подключения Бота WA
   * @method getConnectionStatus
   * @return {Promise<AxiosResponse<TRequestStatus | TGetConnectionStatusResponse, any>>}
   */
  getConnectionStatus() {
    return apiService.get<TGetConnectionStatusResponse | TRequestStatus>(END_POINTS_URL.CONNECT);
  },
  /**
   * Метод получает данные для отображения QR кода
   * @method getQRCodeString
   * @return {Promise<AxiosResponse<TRequestStatus | TGetQRCodeResponse, any>>}
   */
  getQRCodeString() {
    return apiService.get<TGetQRCodeResponse | TRequestStatus>(END_POINTS_URL.CONNECT_ACTION, {
      [QUERY_PARAMS.ACTION]: CONNECT_API_ACTIONS.GET_QR_CODE,
    });
  },
  /**
   * Метод отправляет номер телефона для получения пина авторизации бота
   * @method getAuthCodeString
   * @return {Promise<AxiosResponse<TRequestStatus | TGetQRCodeResponse, any>>}
   */
  getAuthCodeString(phone: string) {
    return apiService.get<TGetAuthCodeResponse | TRequestStatus>(END_POINTS_URL.CONNECT_ACTION, {
      [QUERY_PARAMS.ACTION]: CONNECT_API_ACTIONS.GET_AUTH_CODE,
      [QUERY_PARAMS.PHONE]: phone,
    });
  },
  /**
   * Метод останавливает телеграм бота
   * @method logOutInstance
   * @return {Promise<AxiosResponse<TRequestStatus | TGetQRCodeResponse, any>>}
   */
  logOutInstance() {
    return apiService.get<TGetQRCodeResponse | TRequestStatus>(END_POINTS_URL.LOGOUT_INSTANCE, {
      [QUERY_PARAMS.ACTION]: CONNECT_API_ACTIONS.LOGOUT_BOT,
    });
  },
  /**
   * Метод очищает очередь не отправленных сообщений
   * @method clearQuery
   * @return {Promise<AxiosResponse<TRequestStatus | TGetQRCodeResponse, any>>}
   */
  clearQuery() {
    return apiService.get<TGetQRCodeResponse | TRequestStatus>(END_POINTS_URL.CLEAR_QUERY, {
      [QUERY_PARAMS.ACTION]: CONNECT_API_ACTIONS.CLEAN_MESSAGE_QUERY,
    });
  },
  /**
   * Метод перезагружает телеграм бота
   * @method reloadBot
   * @return {Promise<AxiosResponse<TRequestStatus | TGetQRCodeResponse, any>>}
   */
  reloadBot() {
    return apiService.get<TGetQRCodeResponse | TRequestStatus>(END_POINTS_URL.RELOAD_BOT, {
      [QUERY_PARAMS.ACTION]: CONNECT_API_ACTIONS.REBOOT_BOT,
    });
  },
  /**
   * Метод делает запрос за данными для страницы подключения Бота TG
   * @method getConnectionTGStatus
   * @return {Promise<AxiosResponse<TRequestStatus | TGetConnectionStatusResponse, any>>}
   */
  getConnectionTGStatus(channel: TChannel) {
    return apiService.get<string | TRequestStatus>(END_POINTS_URL.GET_STATUS_CHANNEL, {
      [QUERY_PARAMS.CHANNEL]: channel,
    });
  },
  /**
   * Метод перезагружает канал отправки сообщений
   * @method restartChannel
   * @return {Promise<AxiosResponse<TRequestStatus | boolean, any>>}
   */
  restartChannel(channel: TChannel) {
    return apiService.get<boolean | TRequestStatus>(END_POINTS_URL.RESTART_CHANNEL, {
      [QUERY_PARAMS.CHANNEL]: channel,
    });
  },
  /**
   * Метод отключает соединение с каналом отправки сообщений
   * @method logoutChannel
   * @return {Promise<AxiosResponse<TRequestStatus | boolean, any>>}
   */
  logoutChannel(channel: TChannel) {
    return apiService.get<boolean | TRequestStatus>(END_POINTS_URL.LOGOUT_CHANNEL, {
      [QUERY_PARAMS.CHANNEL]: channel,
    });
  },
  /**
   * Метод отправляет введенный номер телефона для получения кода авторизации
   * @method sendPhone
   * @return {Promise<AxiosResponse<TRequestStatus | TGetQRCodeResponse, any>>}
   */
  sendPhone(phone: string) {
    return apiService.post<TGetQRCodeResponse | TRequestStatus>(
      END_POINTS_URL.SEND_PHONE_TG,
      {},
      {
        params: {
          [QUERY_PARAMS.PHONE]: phone,
        },
      },
    );
  },
  /**
   * Метод отправляет введенный код для авторизации
   * @method sendAuthCode
   * @return {Promise<AxiosResponse<TRequestStatus | TAuthTypes, any>>}
   */
  sendAuthCode(authCode: string) {
    return apiService.post<TRequestStatus>(
      END_POINTS_URL.SEND_AUTH_CODE_TG,
      {},
      {
        params: {
          [QUERY_PARAMS.AUTH_CODE]: authCode,
        },
      },
    );
  },
  /**
   * Метод отправляет введенный пароль для двух этапной авторизации
   * @method sendAuthCode
   * @return {Promise<AxiosResponse<TRequestStatus | TGetQRCodeResponse, any>>}
   */
  send2FaAuthCode(password: string) {
    return apiService.post<TRequestStatus>(
      END_POINTS_URL.SEND_2FA_AUTH_TG,
      {},
      {
        params: {
          [QUERY_PARAMS.PASSWORD]: password,
        },
      },
    );
  },
  /**
   * Метод удаляет инстанс TG канала
   * @method dropTGInstance
   * @return {Promise<AxiosResponse<TRequestStatus | TGetQRCodeResponse, any>>}
   */
  dropTGInstance() {
    return apiService.post<TRequestStatus>(END_POINTS_URL.DROP_TG_INSTANCE, {});
  },
};
