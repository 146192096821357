import { TBlackListData } from './models';

export type TBlackListStatus =
  | 'success'
  | 'loadingBlackList'
  | 'deletingFromBlackList'
  | 'errorDeletingFromBlackList'
  | 'addingToBlackList'
  | 'loadingSendOutBlackList'
  | 'deletingFromSendOutBlackList'
  | 'errorDeletingFromSendOutBlackList'
  | 'addingToSendOutBlackList';

export type TFilteredListNames = 'commonFilteredList' | 'unsubscribersFilteredList';

export type TListNames = 'commonList' | 'unsubscribersList';

export type TNewNumberInputs = 'newNumberToCommonList' | 'newNumberToUnsubscribersList';

export const List: Record<TFilteredListNames, TListNames> = {
  commonFilteredList: 'commonList',
  unsubscribersFilteredList: 'unsubscribersList',
};

export interface IInitialState {
  status: TBlackListStatus;
  commonList: TBlackListData[];
  unsubscribersList: TBlackListData[];
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  isAddSuccess: boolean;
  isAddingNumber: boolean;
  isDeleteSuccess: boolean;
  isDeleteNumberError: boolean;
  isAddingNumberError: boolean;
  isAccessDenied: boolean;
  newNumberToCommonList: string;
  newNumberToUnsubscribersList: string;
  successMessage: string;
  errorMessage: string;
  commonFilteredList: TBlackListData[];
  unsubscribersFilteredList: TBlackListData[];
  searchString: string;
}

export const initialState: IInitialState = {
  status: 'success',
  commonList: [],
  unsubscribersList: [],
  isLoading: false,
  isFetching: false,
  isError: false,
  isAddSuccess: false,
  isAddingNumber: false,
  isDeleteSuccess: false,
  isDeleteNumberError: false,
  isAddingNumberError: false,
  isAccessDenied: false,
  errorMessage: '',
  successMessage: '',
  newNumberToCommonList: '',
  newNumberToUnsubscribersList: '',
  commonFilteredList: [],
  unsubscribersFilteredList: [],
  searchString: '',
};
