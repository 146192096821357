export enum CONFIG {
  BB_LANDING_PAGE_LINK = 'https://xn--90aauzrd9dq.xn--p1ai/',
  ADMIN_BOT_LANDING_PAGE_LINK = '',
  LINK_TO_KNOWLEDGE_BASE = 'http://beautybot.platrum.ru/lk',
  SUPPORT_TELEGRAM_LINK = 'https://t.me/beauty_support_bot',
  SUPPORT_PHONE_NUMBER_TO_HREF = '+79581110714',
  SUPPORT_PHONE_NUMBER_TO_SHOW = '+7 (958) 111 0714',
  TASK_DESCRIPTION_MAX_LENGTH = '500',
  REPORT_DESCRIPTION_MAX_LENGTH = '1000',
  ARTICLE_SYNONYMS_IN_TEMPLATES = 'https://telegra.ph/Primery-ispolzovaniya-sinonimov-v-tekstah-shablonovrassylok-01-16',
  PIN_BOT_LINK = 'https://t.me/beauty_whatsapp_bot',
  TELEGRAM_ID_REMEMBER_BOT = 'https://t.me/beauty_whatsapp_bot?start=showId',
  TELEGRAM_BOT_NAME = 'beauty_whatsapp_bot',
  GET_TELEGRAM_ID_BOT = 'https://telegram.im/@getmyid_bot',
  IP_NAME = 'ИП Андреев Артём Александрович',
  INN = '774330672749',
  ADDRESS = 'г. Москва, ул. Беломорская, д. 1',
  PHONE = '+7 985 111-07-14',
  TILDA_INTEGRATION_INSTRUCTION = 'https://botsarmy.teamly.ru/space/cc312c51-a8cb-451c-bf71-54b94cc93db2/article/f3dc0afa-b7aa-4181-afa3-ebcd12395583',
}
