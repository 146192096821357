import { apiService } from '@api/ApiService';
import { ConfigENV } from '@const/httpConst';
import { END_POINTS_URL } from '@const/apiConstants';
import { TRequestStatus, TStartPageAutoConnectionData } from '@api/types';

export const yclientsStartPageApi = {
  postAutoConnectionData(data: TStartPageAutoConnectionData) {
    return apiService.post<TRequestStatus>(
      `${ConfigENV.YCLIENTS_START_PAGE_BASE_URL}${END_POINTS_URL.YCLIENTS_START_PAGE_REQUEST}`,
      {},
      {
        params: { ...data },
      },
    );
  },
};
