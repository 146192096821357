import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
//
import { Icon } from '@atoms/icon';
import { REG_EXP } from '@const/common';
import { createPortal } from 'react-dom';
import { AddEmoji } from '@atoms/addEmoji';
import { FormLabel } from '@blocks/formLabel';
import { EmojiLib } from '@components/emojiLib';
import { EmojiClickData } from 'emoji-picker-react';
import { cut, parseDivValue } from '@helpers/index';
import { KEY_WRAPPER_SYMBOLS } from '@const/preview';
import { getEmojiDate } from '@redux/emoji/selectors';
import { getSelectionOffset } from '@helpers/selection';
import { emojiPickerToggle } from '@redux/emoji/emojiSlice';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getInnerHtml, getKeysData } from '@helpers/contentMaker';
import { TKeyData, TTextareaNames } from '@redux/template/models';
import { EVENT_KEY_NAMES, THelpKeys, TSimpleStringObj } from '@models/index';
import {
  TDivOnBlurHandler,
  TDivOnClickHandler,
  TDivOnKeyUpHandler,
  TSetViewEmojiPicker,
  TDivOnInputInnerHandler,
} from '@shared/types';

type TDivWithLabelProps = {
  /**
   * Значение textarea
   * @param {string}
   */
  value: string;
  /**
   * Параметр htmlFor для label
   * @param {THelpKeys}
   */
  htmlFor: THelpKeys;
  /**
   * Опциональный параметр определяет показывать или нет иконку с всплывающей подсказкой
   * @param {boolean}
   */
  showInfo?: boolean;
  /**
   * Флаг ошибки устанавливает стили ошибки в textarea
   * @param {boolean}
   */
  isError?: boolean;
  /**
   * Опциональный параметр включает и выключает иконку Emoji
   * @param {boolean}
   */
  isEmoji?: boolean;
  /**
   * Опциональный параметр callback для открытия emojiPicker
   * @param {() => void}
   */
  showEmojiPicker?: () => void;
  /**
   * Опциональный параметр callback для записи выбранного эмодзи в textarea
   * @param {(emoji: EmojiClickData, event: MouseEvent) => void}
   */
  setEmojiInTextarea?: (emoji: EmojiClickData, event: MouseEvent) => void;
  /**
   * Опциональный параметр
   * @param {TSetViewEmojiPicker}
   */
  setViewEmojiPicker?: TSetViewEmojiPicker;
  /**
   * Опциональный параметр ID шага
   * @param {string}
   * * @default
   */
  stepId?: string;
  /**
   * Опциональный параметр отключает textarea
   * @param {boolean}
   * @default
   */
  disabled?: boolean;
  /**
   * Опциональный параметр устанавливает максимальное количество знаков
   * @param {number}
   * @default
   */
  maxLength?: number;
  /**
   * Опциональный параметр добавляет * в label и устанавливает параметр required в textarea
   * @param {boolean}
   * @default
   */
  isRequired?: boolean;
  /**
   * Опциональный параметр placeHolder в textarea
   * @param {string}
   */
  placeholder?: string;
  /**
   * Callback срабатывает ввод текста
   * @param {TDivOnInputHandler}
   */
  // eslint-disable-next-line
  divOnInputHandler: any;
  /**
   * Callback срабатывает на клик по диву
   * @param {TDivOnClickHandler}
   */
  divOnClickHandler: TDivOnClickHandler;
  /**
   * Callback устанавливает положение курсора
   * @param {TDivOnKeyUpHandler}
   */
  setCursorPosition: TDivOnKeyUpHandler;
  /**
   * Опциональный callback срабатывает на blur
   * @param {TDivOnBlurHandler}
   */
  divOnBlurHandler?: TDivOnBlurHandler;
  /**
   * Словарь ключей для рендеренга HTML
   * @param {TSimpleStringObj}
   */
  specialKeyDict: TSimpleStringObj;
  /**
   * Данные о расположении спец ключей
   * @param {TKeyData[]}
   */
  textareaKeysData: TKeyData[];
  /**
   * Опциональный параметр минимальная высота textarea
   * @param {number}
   * @default {120}
   */
  minHeight?: number;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const DivWithLabel = memo(
  ({
    value,
    isEmoji,
    htmlFor,
    disabled,
    maxLength,
    isRequired,
    stepId = '0',
    specialKeyDict,
    isError = false,
    minHeight = 120,
    showInfo = true,
    placeholder = '',
    textareaKeysData,
    divOnBlurHandler,
    divOnInputHandler,
    divOnClickHandler,
    setCursorPosition,
    showEmojiPicker = () => '',
    setViewEmojiPicker = () => '',
    setEmojiInTextarea = () => '',
    className = '',
  }: TDivWithLabelProps) => {
    // реф на textarea используется в useEffect
    // для установки размера окна в зависимости от количества введенного текста
    const refDiv = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();
    const [isViewEmoji, setIsViewEmoji] = useState(false);
    const [isInnerError, setIsInnerError] = useState(false);
    const [isShowPlaceholder, setIsShowPlaceholder] = useState(!value);
    const [maxLengthError, setMaxLengthError] = useState(false);
    const { isOpen, textAreaName, stepIdEmoji } = useAppSelector(getEmojiDate);

    useEffect(() => {
      if (refDiv.current) {
        refDiv.current.spellcheck = true;
        refDiv.current.innerHTML = getInnerHtml(value, specialKeyDict);
      }
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (value && isShowPlaceholder) {
        setIsShowPlaceholder(false);
      }
      if (!value && !isShowPlaceholder) {
        setIsShowPlaceholder(true);
      }
    }, [isShowPlaceholder, value]);

    useEffect(() => {
      if (htmlFor === textAreaName && isOpen && stepId === stepIdEmoji) {
        setIsViewEmoji(true);
      } else {
        setIsViewEmoji(false);
      }
    }, [isOpen, textAreaName, htmlFor, stepId, stepIdEmoji]);

    // высчитывает размер окна textarea в зависимости от количества введенного текста
    useEffect(() => {
      const textareaElement = refDiv.current as unknown as HTMLDivElement;
      const fontSize = parseInt(
        window.getComputedStyle(textareaElement).getPropertyValue('font-size'),
        10,
      );
      textareaElement.style.removeProperty('height');
      const { scrollHeight } = textareaElement;

      if (scrollHeight < minHeight) {
        textareaElement.style.setProperty('height', `${minHeight / fontSize}rem`);
      } else {
        textareaElement.style.setProperty('height', `${scrollHeight / fontSize}rem`);
      }
    }, [value, minHeight]);

    // если в пропсах передали ошибку, выставляет ошибку локально
    useEffect(() => {
      setIsInnerError(isError);
    }, [isError]);

    // Ели передан параметр maxLength, устанавливает/убирает ошибку превышения максимальной длинны сообщения
    useEffect(() => {
      if (value && value.length > (maxLength || Infinity)) {
        if (!maxLengthError) {
          setMaxLengthError(true);
        }
      } else if (maxLength) {
        setMaxLengthError(false);
      }
    }, [value, maxLength, maxLengthError, isRequired]);

    // Устанавливает/снимает ошибку
    const errorClassToggle = (
      event: React.KeyboardEvent<HTMLDivElement> | React.FocusEvent<HTMLDivElement>,
    ) => {
      const textValue = event.currentTarget.innerText;

      if (isRequired && !textValue) {
        setIsInnerError(true);
      }
      if (textValue) {
        setIsInnerError(false);
      }
    };

    const emojiPickerHandler = useCallback(
      ({ name, isOpenPicker }: { name: string; isOpenPicker: boolean }) =>
        () => {
          if (refDiv.current) {
            setViewEmojiPicker({ name, isOpenEmoji: isOpenPicker, divRef: refDiv.current });
            showEmojiPicker();
          }
        },
      [setViewEmojiPicker, showEmojiPicker],
    );

    const onClickEmoji = useCallback(() => {
      dispatch(emojiPickerToggle({ name: '', isOpen: false, stepIdEmoji: '0' }));
    }, [dispatch]);

    const innerOnInputHandler: TDivOnInputInnerHandler = event => {
      const {
        innerText,
        dataset: { name },
      } = event.currentTarget;

      const [selectionStart, selectionEnd] = getSelectionOffset(event.currentTarget);

      let newTextareaKeysData: TKeyData[] = [];

      const newText = parseDivValue(event.currentTarget)({
        text: innerText,
        selectionStart,
        selectionEnd,
        specialKeyDict,
      });

      newTextareaKeysData = getKeysData(newText, specialKeyDict);

      divOnInputHandler({
        name: name as TTextareaNames,
        value: newText,
        selectionStart,
        selectionEnd,
        newTextareaKeysData,
      });
    };

    const innerOnKeyUpHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
      const { key } = event;
      const {
        dataset: { name },
        innerText,
      } = event.currentTarget;

      const [selectionStart, selectionEnd] = getSelectionOffset(event.currentTarget);

      if (key !== EVENT_KEY_NAMES.ENTER) {
        let newText = innerText;

        let newTextareaKeysData: TKeyData[] = [];

        switch (key) {
          case EVENT_KEY_NAMES.BACKSPACE: {
            if (selectionStart === selectionEnd) {
              const [finedKeyData] = textareaKeysData.filter(
                keyData => selectionStart - 1 >= keyData.start && selectionEnd <= keyData.end,
              );

              if (finedKeyData) {
                const { start, end } = finedKeyData;

                const cutStartIndex = start;
                const cutEndIndex = end;

                newText = cut({
                  text: value,
                  cutStartIndex,
                  cutEndIndex,
                });

                newTextareaKeysData = getKeysData(newText, specialKeyDict);

                parseDivValue(event.currentTarget)({
                  text: newText,
                  selectionStart: start,
                  selectionEnd: start,
                  specialKeyDict,
                });
              } else {
                const cutStartIndex = value[selectionStart - 1]?.replace(REG_EXP.onlyCutEmoji, '')
                  ? selectionStart - 2
                  : selectionStart - 1;
                if (selectionStart - 1 >= 0) {
                  newText = cut({
                    text: value,
                    cutStartIndex,
                    cutEndIndex: selectionEnd,
                  });

                  newTextareaKeysData = getKeysData(newText, specialKeyDict);

                  parseDivValue(event.currentTarget)({
                    text: newText,
                    selectionStart: cutStartIndex,
                    selectionEnd: cutStartIndex,
                    specialKeyDict,
                  });
                }
              }
            } else {
              newText = cut({
                text: value,
                cutStartIndex: selectionStart,
                cutEndIndex: selectionEnd,
              });

              newTextareaKeysData = getKeysData(newText, specialKeyDict);

              parseDivValue(event.currentTarget)({
                text: newText,
                selectionStart,
                selectionEnd: selectionStart,
                specialKeyDict,
              });
            }
            break;
          }
          case EVENT_KEY_NAMES.DELETE: {
            if (selectionStart === selectionEnd) {
              const finedKeyData = textareaKeysData.filter(
                keyData => selectionStart + 1 >= keyData.start && selectionEnd <= keyData.end,
              );

              let start = 0;
              let end = 0;

              if (finedKeyData.length > 1) {
                start = finedKeyData[1].start;
                end = finedKeyData[1].end;
              } else if (finedKeyData.length) {
                start = finedKeyData[0].start;
                end = finedKeyData[0].end;
              }

              if (finedKeyData.length && end > selectionStart) {
                const cutStartIndex = start;
                const cutEndIndex = end;

                newText = cut({
                  text: value,
                  cutStartIndex,
                  cutEndIndex,
                });

                newTextareaKeysData = getKeysData(newText, specialKeyDict);

                parseDivValue(event.currentTarget)({
                  text: newText,
                  selectionStart: start,
                  selectionEnd: start,
                  specialKeyDict,
                });
              } else {
                const cutEndIndex = value[selectionStart + 1]?.replace(REG_EXP.onlyCutEmoji, '')
                  ? selectionStart + 2
                  : selectionStart + 1;

                if (cutEndIndex <= value.length) {
                  newText = cut({
                    text: value,
                    cutStartIndex: selectionStart,
                    cutEndIndex,
                  });

                  newTextareaKeysData = getKeysData(newText, specialKeyDict);

                  parseDivValue(event.currentTarget)({
                    text: newText,
                    selectionStart,
                    selectionEnd: selectionStart,
                    specialKeyDict,
                  });
                }
              }
            } else {
              newText = cut({
                text: value,
                cutStartIndex: selectionStart,
                cutEndIndex: selectionEnd,
              });

              newTextareaKeysData = getKeysData(newText, specialKeyDict);

              parseDivValue(event.currentTarget)({
                text: newText,
                selectionStart,
                selectionEnd: selectionStart,
                specialKeyDict,
              });
            }
            break;
          }
          default: {
            newText = value;
            newTextareaKeysData = getKeysData(value, specialKeyDict);

            parseDivValue(event.currentTarget)({
              text: value,
              selectionStart,
              selectionEnd,
              specialKeyDict,
            });
          }
        }

        divOnInputHandler({
          name: name as TTextareaNames,
          value: newText,
          selectionStart,
          selectionEnd,
          newTextareaKeysData,
        });
      } else {
        divOnInputHandler({
          name: name as TTextareaNames,
          value: innerText,
          selectionStart,
          selectionEnd,
          newTextareaKeysData: getKeysData(innerText, specialKeyDict),
        });
        parseDivValue(event.currentTarget)({
          text: innerText,
          selectionStart,
          selectionEnd,
          specialKeyDict,
        });
      }
    };

    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
      const { key, ctrlKey } = event;
      if (key === EVENT_KEY_NAMES.ENTER) {
        event.preventDefault();
        document.execCommand('insertLineBreak');
        innerOnKeyUpHandler(event);
      }
      if (ctrlKey && key === 'z') {
        // event.preventDefault();
      }
      if (key === KEY_WRAPPER_SYMBOLS.OPEN_SYMBOL || key === KEY_WRAPPER_SYMBOLS.CLOSE_SYMBOL) {
        event.preventDefault();
      }
      if (key === EVENT_KEY_NAMES.BACKSPACE || key === EVENT_KEY_NAMES.DELETE) {
        event.preventDefault();
        innerOnKeyUpHandler(event);
      }
    };

    const textareaStyle = `relative pr-10 transition-all duration-150 ease-in-out ${
      (isInnerError || maxLengthError) && !disabled
        ? 'shadow-[0_0_0_1px_rgb(250,169,163,1)] active:shadow-[0_0_0_2px_rgb(156,43,35,1)] focus:shadow-[0_0_0_2px_rgb(156,43,35,1)] hover:shadow-[0_0_0_1px_rgb(156,43,35,1)] innerError'
        : 'shadow-[0_0_0_1px_rgb(180,180,187,1)] active:shadow-[0_0_0_2px_rgb(58,58,68,1)] focus:shadow-[0_0_0_2px_rgb(58,58,68,1)] hover:shadow-[0_0_0_1px_rgb(58,58,68,1)]'
    } ${(isInnerError || maxLengthError) && !disabled ? 'placeholder-lightRed' : ''} ${
      disabled ? 'hover:!shadow-[0_0_0_1px_rgb(180,180,187,1)] bg-white cursor-not-allowed' : ''
    } rounded-lg whitespace-break-spaces px-3 py-2 mb-2 w-full overflow_wrap w-full`;

    return (
      <div className={`${isViewEmoji ? 'z-[19]' : ''} min-h-[168px] ${className}`}>
        <FormLabel
          htmlFor={htmlFor}
          showInfo={showInfo}
          isRequired={isRequired}
          textColor='!text-grayText'
          labelTextVariant={htmlFor}
          requiredSignColor='text-grayText'
          className='mb-[0.375rem]'
        />
        <div className='relative'>
          {isEmoji ? (
            <AddEmoji
              className={`absolute top-[0.2rem] z-[19] ${
                (isInnerError || maxLengthError) && !disabled ? 'right-8' : 'right-1'
              }`}
              callback={emojiPickerHandler({ name: htmlFor, isOpenPicker: !isViewEmoji })}
            />
          ) : null}
          {isViewEmoji ? (
            <EmojiLib
              onEmojiClick={setEmojiInTextarea}
              className='absolute z-[101] top-[-250px] right-0 max-w-[350px]'
            />
          ) : null}
          <div
            lang='ru'
            spellCheck
            ref={refDiv}
            data-name={htmlFor}
            onBlur={divOnBlurHandler}
            onFocus={errorClassToggle}
            contentEditable={!disabled}
            onClick={divOnClickHandler}
            onKeyUp={setCursorPosition}
            onKeyDown={onKeyDownHandler}
            onInput={innerOnInputHandler}
            className={textareaStyle}
          />
          {isShowPlaceholder ? (
            <span
              onClick={() => refDiv?.current?.focus()}
              className={`absolute top-2 left-3 text- ${
                (isInnerError || maxLengthError) && !disabled ? 'text-lightRed' : 'text-gray3'
              }`}>
              {placeholder}
            </span>
          ) : null}
          {(isInnerError || maxLengthError) && !disabled ? (
            <Icon variant='errorInInput' className='absolute top-[0.55rem] right-[0.6rem]' />
          ) : null}
        </div>
        <div className='leading-[0.5rem]'>
          {maxLength && value.length ? (
            <span className='text-grayText m-0 tracking-[0.033em] text-[12px]'>{`${value.length} / ${maxLength}`}</span>
          ) : isInnerError ? (
            <p className='text-grayText mt-[1px] mb-0 tracking-[0.033em] text-[12px]'>
              Введите текст
            </p>
          ) : null}
        </div>
        {isViewEmoji
          ? createPortal(
              <div
                onClick={onClickEmoji}
                className='absolute top-0 bottom-0 left-0 right-0 z-[18]'
              />,
              document.body,
            )
          : null}
      </div>
    );
  },
);

DivWithLabel.displayName = 'DivWithLabel';
