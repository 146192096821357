import { mailingApi } from '@api/mailingApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { REJECT_RESPONSE_KEY, TGetSendOutDetailStatisticsData } from '@api/types';
import { sendOutStatisticsTableMappers } from '@redux/sendOutStatisticsTable/sendOutStatisticsTableMappers';
import {
  TRejectResponseData,
  TSendOutDetailsStatisticsData,
  SEND_OUT_DETAIL_STATISTICS_KEY_NAMES,
} from '@models/index';

export const getDetailSendOutStatistics = createAsyncThunk<
  TSendOutDetailsStatisticsData[] | void,
  TGetSendOutDetailStatisticsData,
  { rejectValue: TRejectResponseData }
>('sendOutStatistics/getDetailSendOutStatistics', (data, { dispatch, rejectWithValue }) => {
  return mailingApi
    .sendOutDetailStatistics(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.STATUS in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (SEND_OUT_DETAIL_STATISTICS_KEY_NAMES.PHONE in response.data) {
        return sendOutStatisticsTableMappers.responseToStore(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getDetailSendOutStatistics(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

export const getDetailSendOutStatisticsDownloadLink = createAsyncThunk<
  string | void,
  TGetSendOutDetailStatisticsData,
  { rejectValue: TRejectResponseData }
>(
  'sendOutStatistics/getDetailSendOutStatisticsDownloadLink',
  (data, { dispatch, rejectWithValue }) => {
    return mailingApi
      .sendOutDetailStatisticsDownload(data)
      .then(response => {
        if (typeof response.data === 'string') {
          return response.data;
        }

        if (REJECT_RESPONSE_KEY.STATUS in response.data && !response.data.ok) {
          throw new Error(String(response.data.status));
        }
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(getDetailSendOutStatisticsDownloadLink(data)), 200);
        }
        return rejectWithValue(error.response.data);
      });
  },
);
