export type TInviteFriendBonusType = 'first_visit' | 'percent';

export type TInviteFriendCardType = {
  id: number;
  title: string;
  salonGroupId: number;
};

export type TInviteFriendListData = {
  id: string;
  title: string;
  cardType: TInviteFriendCardType | null;
  isActive: boolean;
  bonusType: TInviteFriendBonusType;
};

export type TInviteFriendsActionListObj = Record<string, TInviteFriendListData>;

export enum INVITE_FRIENDS_LIST_DATA_KEY_NAMES {
  IS_ACTIVE = 'isActive',
}

export type TSwitchActionData = {
  id: string;
  status: boolean;
};
