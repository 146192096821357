import { RootState } from '@store/store';
import { accountsApi } from '@api/accountsApi';
import { myTrackerApi } from '@api/myTrackerApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { accountsMappers } from '@redux/accounts/accountsMappers';
import { TCheckInstanceStatus, TRejectResponseData } from '@models/index';
import { REJECT_RESPONSE_KEY, ACCOUNT_RESPONSE_DATA_KEYS } from '@api/types';
import { MY_TRACKER_INTERFACE, MY_TRACKER_EVENTS } from '@helpers/myTracker';

import { TAccountsData } from './models';

/**
 * Thunk для получения данных аккаунтов.
 *
 * @return {TAccountsData | void} Данные аккаунтов или void.
 * @throws {Error} Если получен неверный статус ответа.
 * @param {any} data - Неиспользуемый параметр.
 * @param {any} rejectWithValue - Функция для обработки ошибок запроса.
 */
export const getAccounts = createAsyncThunk<
  TAccountsData | void,
  TCheckInstanceStatus,
  { state: RootState; rejectValue: TRejectResponseData }
>('accounts/getAccounts', (data, { getState, rejectWithValue }) => {
  const oldAccounts = getState().accounts.data;

  return accountsApi
    .getAccounts(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      if (ACCOUNT_RESPONSE_DATA_KEYS.ACCOUNTS_DATA in response.data) {
        const { accounts, accounts_data, banner_data, consultant, consultant_data } = response.data;

        const branchId = accounts_data ? accounts_data[0].branch_id : null;

        if (branchId) {
          // amplitudeSetUserId(branchId);

          if (!oldAccounts.length) {
            const eventData = {
              eventName: MY_TRACKER_EVENTS.LK_LOGIN,
              eventProperties: {
                interface: MY_TRACKER_INTERFACE,
                branch_id: accounts_data[0].branch_id,
              },
            };
            myTrackerApi.lkLoginEvent(eventData);
          }
        }

        return {
          accountsData: accounts ? accountsMappers.mapResponseDataToStore(accounts_data) : [],
          consultantData: consultant
            ? accountsMappers.mapConsultantResponseDataToStore(consultant_data)
            : null,
          bannerData: banner_data
            ? accountsMappers.mapResponseBannerDataToStore(banner_data)
            : null,
        };
      }
    })
    .catch(error => rejectWithValue(error.response.data));
});
