import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Icon } from '@atoms/icon';
import { LinkButton } from '@components/linkButton';

interface IHeaderButtonsProps {
  /**
   * опциональный параметр устанавливает стили отображения кнопки поддержки в telegram для мобильных устройств
   * @param {boolean}
   * @default
   */
  isFooter?: boolean;
  /**
   * Опциональный параметр показывает/скрывает номер телефона
   * @param {boolean}
   * @default
   */
  isShowPhone?: boolean;
  /**
   * Ссылка на канал поддержки в telegram
   * @param {string}
   */
  supportTelegramLink: string;
  /**
   * Номер телефона в href, передается без форматирования (+77777777777)
   * @param {string}
   */
  supportPhoneToHref: string;
  /**
   * Телефонный номер на страницу, передается с форматированием для лучшей читаемости (+7 (777) 777 7777)
   * @param {string}
   */
  supportPhoneToShow: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const Support = memo(
  ({
    isFooter = false,
    isShowPhone = false,
    supportTelegramLink,
    supportPhoneToHref,
    supportPhoneToShow,
    className = '',
  }: IHeaderButtonsProps) => {
    const { t } = useTranslation();

    return (
      <div className={`flex grow sm:grow-0 ${className}`}>
        <div className='flex flex-wrap grow sm:grow-0 items-center justify-center'>
          <p className='text-body_text font-medium text-darkGray font-inter tracking-[0.022em] my-0 mr-2 md:mr-6 ml-0'>
            {t('BASE_TEMPLATE_PAGE_TEXT.support.header')}
          </p>
          <div className='flex flex-wrap items-center grow sm:grow-0 justify-center'>
            <div className={isFooter ? `hidden sm:block` : ''}>
              <LinkButton
                targetBlank
                icon='telegramCyan'
                href={supportTelegramLink}
                className='!bg-white'>
                {t('BASE_TEMPLATE_PAGE_TEXT.support.telegramButton')}
              </LinkButton>
            </div>
            {isFooter ? (
              <a
                target='_blank'
                rel='noopener noreferrer'
                className='inline sm:hidden'
                href={supportTelegramLink}>
                <Icon variant='telegramCyan' />
              </a>
            ) : null}
            {isShowPhone ? (
              <a
                href={`tel:${supportPhoneToHref}`}
                className='flex items-center hover:no-underline hover:text-darkGray text-darkGray font-medium ml-6 sm:ml-2 md:ml-6 sm:min-w-[12.125rem]'>
                <Icon variant='phone' className='h-6 w-6' />
                <span className='hidden sm:inline ml-2'>{supportPhoneToShow}</span>
              </a>
            ) : null}
          </div>
        </div>
      </div>
    );
  },
);

Support.displayName = 'Support';
