import { TSimpleStringObj } from '@models/index';
import { TSettingsData } from '@redux/settings/type';
import { emptySettingsData } from '@redux/settings/mockData';

export interface IInitialState {
  [key: string]: boolean | number | string | TSettingsData | { [key: string]: TSimpleStringObj };
  retries: number;
  isRetries: boolean;
  isLoading: boolean;
  isError: boolean;
  isPosting: boolean;
  isPostError: boolean;
  isShowToken: boolean;
  isShowLogin: boolean;
  isShowPass: boolean;
  isSaveSettingSuccess: boolean;
  isAccessDenied: boolean;
  isShowSenderName: boolean;
  phonesDontShowInBotNewValue: string;
  exceptionMastersNamesNewValue: string;
  categoriesDataList: { [key: string]: TSimpleStringObj };
  isGettingDropdownListError: boolean;
  errorMessage: string;
  successMessage: string;
  warningMessage: string;
  isWarningMessage: boolean;
  isShowAdditionalInfo: boolean;
  isShowChatEnableModal: boolean;
  data: TSettingsData;
  oldData: TSettingsData;
}

export const initialState: IInitialState = {
  isError: false,
  retries: 1,
  isRetries: false,
  isLoading: false,
  isSuccess: false,
  isPosting: false,
  isPostError: false,
  isShowToken: false,
  isShowLogin: false,
  isShowChatEnableModal: false,
  isShowPass: false,
  errorMessage: '',
  successMessage: '',
  warningMessage: '',
  isWarningMessage: false,
  isSaveSettingSuccess: false,
  isAccessDenied: false,
  isShowSenderName: false,
  phonesDontShowInBotNewValue: '',
  exceptionMastersNamesNewValue: '',
  categoriesDataList: {},
  isGettingDropdownListError: false,
  isShowAdditionalInfo: false,
  data: emptySettingsData,
  oldData: emptySettingsData,
};
