import React, { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
//
import { PATH_NAMES } from '@data/dict';
import { NoAccess } from '@components/noAccess';
import { ErrorMessage } from '@blocks/errorMessage';
import { getPayPageState } from '@redux/pay/selectors';
import { PayCommonTemplate } from '@templates/payCommon';
import { getAccountsData } from '@redux/accounts/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getPayPageData, getPaymentHistoryDataThunk } from '@redux/pay/payThunks';

const PayPage = memo(() => {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const { isError, isAccessOnPayPageDenied } = useAppSelector(getPayPageState);

  const { selectedFilial } = useAppSelector(getAccountsData);

  useEffect(() => {
    if (selectedFilial?.accId && pathname === PATH_NAMES.PAY) {
      dispatch(getPayPageData({ isTgChanelAdd: false }));
    }
  }, [pathname, selectedFilial?.accId, dispatch]);

  useEffect(() => {
    if (selectedFilial?.accId && pathname === PATH_NAMES.PAY_HISTORY) {
      dispatch(getPaymentHistoryDataThunk());
    }
  }, [pathname, selectedFilial?.accId, dispatch]);

  return (
    <>
      {isError && !isAccessOnPayPageDenied ? <ErrorMessage /> : null}
      {isAccessOnPayPageDenied && !isError ? <NoAccess /> : null}
      {!isError && !isAccessOnPayPageDenied ? <PayCommonTemplate /> : null}
    </>
  );
});

PayPage.displayName = 'PayPage';

export default PayPage;
