import { QUERY_PARAMS } from '@const/apiConstants';

export type TStatisticsData = {
  id: number;
  text: string;
  date: string;
  time: string;
  recipients: number;
  answers: number;
  signUp: number;
  sum: number;
  sumOneMsg: number;
  conversionSignUp: number;
  conversionAnswers: number;
  visits: number;
  lastVisitDays: number;
  dateFinishSendout: string;
  timeFinishSendout: string;
  sendoutStopFlag: boolean;
};

export type TStatisticsTableDataRequestParams = {
  sortedBy: string;
  currentPage: number;
  rowsPerPage: number;
  sortDirection: string;
  startDate: string;
  endDate: string;
  searchString: string;
};

export type TStatisticsTableDataCountRequestParams = Pick<
  TStatisticsTableDataRequestParams,
  'startDate' | 'endDate' | 'searchString'
>;

export type TTemplateStatisticDataItem = {
  answeredCount: number;
  answeredRate: number;
  date: string;
  earnedCount: number;
  id: string;
  recordedCount: number;
  recordedRate: number;
  sendAfterDays: string;
  sendCount: number;
  sendRate: number;
  sendTime: string;
  text: string;
  version: number;
  earnOneMsg: number;
};

export type TTemplateStatisticDataItemByTime = {
  id: string;
  versions: number[];
  period: string;
  sendCount: number;
  answeredCount: number;
  recordedCount: number;
  earnedCount: number;
  sendRate: number;
  answeredRate: number;
  recordedRate: number;
  earnOneMsg: number;
};

export type TStatisticPageData = {
  name: string;
  statisticsDataArray: TStatisticsData[];
};

export type TStatisticsResponse = {
  totalCount: number;
  data: TStatisticsData[];
};

export const SORT_BY_KEYS_NAME = [
  [QUERY_PARAMS.SORTED_BY],
  [QUERY_PARAMS.CURRENT_PAGE],
  [QUERY_PARAMS.ROWS_PER_PAGE],
  [QUERY_PARAMS.SEARCH_STRING],
  [QUERY_PARAMS.START_DATE],
  [QUERY_PARAMS.END_DATE],
  [QUERY_PARAMS.SORT_DIRECTION],
] as unknown as string[];
