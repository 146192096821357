import { apiService } from '@api/ApiService';
import { END_POINTS_URL } from '@const/apiConstants';
import {
  TRequestStatus,
  TGetFullTableData,
  TGetFullTableResponse,
  TGetOperationListTableData,
  TGetConsultantFullTableData,
  TGetPayHistorySalonTableData,
  TGetPayHistoryConsultantTableData,
} from '@api/types';

export const partnerProgramApi = {
  getFullTableData: () => {
    return apiService.get<TGetFullTableResponse<TGetFullTableData> | TRequestStatus>(
      END_POINTS_URL.PARTNERS_REFF_SALON,
    );
  },
  getConsultantFullTableData: (promoCode: string) => {
    return apiService.get<TGetFullTableResponse<TGetConsultantFullTableData> | TRequestStatus>(
      END_POINTS_URL.PARTNERS_REFF_CONSULTANT,
      {
        promo_code: promoCode,
      },
    );
  },
  getOperationListTableData: (promoCode: string) => {
    return apiService.get<TGetFullTableResponse<TGetOperationListTableData> | TRequestStatus>(
      END_POINTS_URL.PARTNERS_REFF_CONSULTANT_OPERATIONS,
      {
        promo_code: promoCode,
      },
    );
  },
  getPayHistorySalonTableData: () => {
    return apiService.get<{ data: TGetPayHistorySalonTableData } | TRequestStatus>(
      END_POINTS_URL.PARTNERS_REFF_SALON_PAY_HISTORY,
    );
  },
  getPayHistoryConsultantTableData: (promoCode: string) => {
    return apiService.get<
      TGetFullTableResponse<TGetPayHistoryConsultantTableData> | TRequestStatus
    >(END_POINTS_URL.PARTNERS_REFF_CONSULTANT_HISTORY, {
      promo_code: promoCode,
    });
  },
};
