import { SORTING_TYPES } from '@const/common';
import { SORT_DROPDOWN_VALUES } from '@const/mailing';
import { getDateStringToGetData, getStartOfYearData } from '@helpers/index';

import { TStatisticsData } from './models';

export interface IInitialState {
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  numberOfRequest: number;
  searchString: string;
  currentMobilePage: number;
  sortedBy: string;
  sortDirection: string;
  currentPage: number;
  rowsPerPage: number;
  startDate: string;
  endDate: string;
  totalCount: number;
  data: TStatisticsData[];
  isAccessOnStatisticsPageDenied: boolean;
}

export const initialState: IInitialState = {
  isError: false,
  isLoading: false,
  isFetching: false,
  isSuccess: false,
  currentMobilePage: 1,
  numberOfRequest: 0,
  searchString: '',
  data: [],
  totalCount: 0,
  currentPage: 0,
  rowsPerPage: 8,
  startDate: getStartOfYearData().startOfYearDate,
  endDate: getDateStringToGetData(new Date()),
  sortDirection: SORTING_TYPES.DESCENDING,
  sortedBy: SORT_DROPDOWN_VALUES.DATE,
  isAccessOnStatisticsPageDenied: false,
};
