export const FEEDBACK_TOAST_MESSAGES = {
  SUCCESS_MESSAGE: 'Сообщение отправлено',
};

export const FEEDBACK_TOAST_MESSAGES_ENG = {
  SUCCESS_MESSAGE: 'Message sent',
};

export const FEEDBACK_MESSAGES = {
  SUCCESS_MESSAGE: 'FEEDBACK_TOAST_MESSAGES.SUCCESS_MESSAGE',
};
