import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { H1 } from '@atoms/h1';
import { Button } from '@uikit/Button';
import { Loader } from '@blocks/loader';
import { useAppDispatch } from '@store/store';
import { AccessBlock } from '@components/access';
import { ToastsMessages } from '@components/toastsMessages';
import { ACCESS_DATA_KEY_NAMES, TAccessDataItem } from '@redux/access/models';
import {
  changeInputValue,
  addNewAccessBlock,
  changeSelectValue,
  deleteAccessBlock,
  hideErrorMessageAP,
  setUniqueErrorMessage,
  hideSaveSuccessMessage,
  setEmptyInputErrorMessage,
} from '@redux/access/accessesSlice';

type TAccessesProps = {
  /**
   * Флаг состояния запроса на сохранение
   * @param {boolean}
   */
  isPosting: boolean;
  /**
   * Флаг загрузки данных
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Флаг ошибки сохранения изменений
   * @param {boolean}
   */
  isPostError: boolean;
  /**
   * Флаг ошибки сохранения, если такая запись уже существует
   * @param {boolean}
   */
  isUniqueTgIdError: boolean;
  /**
   * Флаг ошибки сохранения, если поле ввода пустое
   * @param {boolean}
   */
  isEmptyInputError: boolean;
  /**
   * Флаг успешного сохранения изменений
   * @param {boolean}
   */
  isPostingSuccess: boolean;
  /**
   * Сообщение об ошибке
   * @param {string}
   */
  errorMessage: string;
  /**
   * Сообщение об успешном сохранении
   * @param {string}
   */
  successMessage: string;
  /**
   * Callback для сохранения изменений на странице
   * @param {() => void}
   */
  postAccessesHandler: () => void;
  /**
   * Флаг отключения кнопки сохранения
   * @param {boolean}
   */
  isSaveButtonDisable: boolean;
  /**
   * Массив с данными пользователей
   * @param {TAccessDataItem[]}
   */
  data: TAccessDataItem[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const AccessesTemplate = memo(
  ({
    isLoading,
    isPosting,
    isPostError,
    errorMessage,
    successMessage,
    isPostingSuccess,
    isEmptyInputError,
    isUniqueTgIdError,
    isSaveButtonDisable,
    postAccessesHandler,
    data,
    className = '',
  }: TAccessesProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    // Обработчик события Change на элементах checkbox, получает id из события, запрашивает новый "список прав"
    // и записывает в state
    const onChangeSelectHandler = useCallback(
      (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;

        dispatch(changeSelectValue({ name, checked, id: String(id) }));
      },
      [dispatch],
    );

    // Добавляет новый, пустой, блок с правами в state, данные для блока берутся из словаря
    const addAccessBlock = useCallback(() => {
      dispatch(addNewAccessBlock());
    }, [dispatch]);

    // Обработчик события Change на инпутах, получает id из события и записывает в state,
    // запрещает вводить текст в поле user_id
    const onChangeHandler = useCallback(
      (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.currentTarget;

        dispatch(changeInputValue({ id: String(id), name, value }));
      },
      [dispatch],
    );

    // Обработчик события onBlur на инпутах, проверяет данные инпута на уникальность
    const onBlurHandler = useCallback(
      (id: number) => (event: React.FocusEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;

        if (value) {
          const userId = data.filter(item => item.userId === value);

          if (userId.length > 1 && !(isUniqueTgIdError || isEmptyInputError)) {
            dispatch(setUniqueErrorMessage());
            dispatch(
              changeInputValue({ id: String(id), name: ACCESS_DATA_KEY_NAMES.USER_ID, value: '' }),
            );
            event.currentTarget.focus();
          }
        }
      },
      [data, isEmptyInputError, isUniqueTgIdError, dispatch],
    );

    // Обрабатывает нажатия на кнопку "Сохранить"
    const saveHandler = useCallback(() => {
      const emptyUser = data.find(item => item.userId === '');

      if (emptyUser) {
        if (!(isUniqueTgIdError || isEmptyInputError)) {
          dispatch(setEmptyInputErrorMessage());
        }
      } else {
        postAccessesHandler();
      }
    }, [data, isEmptyInputError, isUniqueTgIdError, postAccessesHandler, dispatch]);

    // Удаляет блок с правами из state по id
    const deleteBlock = useCallback(
      (userId: string) => () => {
        dispatch(deleteAccessBlock({ userId }));
      },
      [dispatch],
    );

    const clearErrorMessage = useCallback(() => {
      dispatch(hideErrorMessageAP());
    }, [dispatch]);

    const clearSuccessMessage = useCallback(() => {
      dispatch(hideSaveSuccessMessage());
    }, [dispatch]);

    const renderAccessBlocks = data.map((item, index) => {
      const key = `${100}-${index}`;
      return (
        <AccessBlock
          key={key}
          id={index}
          name={item.name}
          userId={item.userId}
          rights={item.rights}
          deleteBlock={deleteBlock}
          onBlurHandler={onBlurHandler}
          onChangeHandlerInput={onChangeHandler}
          onChangeSelectHandler={onChangeSelectHandler}
          className={index === data.length - 1 ? '' : 'mb-4'}
        />
      );
    });

    const isAddNewUserButtonDisabled = (data.length && !data[data.length - 1]?.userId) || isPosting;

    const isShowErrorToast = isPostError || isUniqueTgIdError || isEmptyInputError;

    const isSaveButtonDisabled = isSaveButtonDisable || isUniqueTgIdError || isEmptyInputError;

    return (
      <div className={`flex flex-col ${className}`}>
        <H1 className='mb-10'>{t('ACCESS_PAGE_TEXT.header')}</H1>
        <div>
          {isLoading ? (
            <Loader className='h-[50vh]' />
          ) : (
            <>
              {renderAccessBlocks}
              <Button
                dense
                icon='plus'
                type='action'
                view='outlined'
                color='default'
                loading={isPosting}
                onClick={addAccessBlock}
                disabled={isAddNewUserButtonDisabled}
                text={t('ACCESS_PAGE_TEXT.addButton')}
                className='mt-4 w-fit'
              />
              <div className='mt-10'>
                <Button
                  dense
                  type='action'
                  view='filled'
                  color='success'
                  loading={isPosting}
                  onClick={saveHandler}
                  disabled={isSaveButtonDisabled}
                  text={t('ACCESS_PAGE_TEXT.saveButton')}
                />
              </div>
              <ToastsMessages
                errorMessage={errorMessage}
                successMessage={successMessage}
                isShowErrorToast={isShowErrorToast}
                isShowSuccessToast={isPostingSuccess}
                clearErrorCallback={clearErrorMessage}
                clearSuccessStatusCallback={clearSuccessMessage}
              />
            </>
          )}
        </div>
      </div>
    );
  },
);

AccessesTemplate.displayName = 'AccessesTemplate';
