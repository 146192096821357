import React, { memo, useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { useAppDispatch } from '@store/store';
import { parseDivValue } from '@helpers/index';
import { TSimpleStringObj } from '@models/index';
import { TKeyData } from '@redux/template/models';
import { EmojiClickData } from 'emoji-picker-react';
import { DivWithLabel } from '@components/divWithLabel';
import { getSelectionOffset } from '@helpers/selection';
import { emojiPickerToggle } from '@redux/emoji/emojiSlice';
import { ReviewsStepsWrapper } from '@blocks/reviewStepsWrapper';
import { REVIEW_STEP_FIVE_KEY_NAMES, TReviewsStep5Data } from '@redux/reviews/models';
import {
  onChange,
  addKeyInTextareaRV,
  setTextareaValueRV,
  setAbandonedTextareaDataRV,
} from '@redux/reviews/reviewsSlice';
import {
  TOnChangeHandler,
  TDivOnInputHandler,
  TDivOnKeyUpHandler,
  TDivOnClickHandler,
  TSetEmojiInTextarea,
  TShowPreviewHandler,
  TSetViewEmojiPicker,
  TOnEmojiViewChangeReview,
} from '@shared/types';

interface IReviewsStep5Props {
  /**
   * ID шага
   * @param {number}
   */
  stepId: number;
  /**
   * ID карточки
   * @param {string}
   */
  chainId: number;
  /**
   * Имя textarea для добавления ключей или эмодзи
   * @param {string}
   */
  blurFromTextareaName: string;
  /**
   * Данные шага
   * @param {TReviewsStep5Data}
   */
  data: TReviewsStep5Data;
  /**
   * Callback для предпросмотра текста сообщения
   * @param {string}
   */
  showPreviewHandler: TShowPreviewHandler;
  /**
   * Словарь ключей для рендеренга HTML
   * @param {TSimpleStringObj}
   */
  specialKeyDict: TSimpleStringObj;
  /**
   * ref на contenteditable div для передачи в него новых данных
   * @param {React.MutableRefObject<(EventTarget & HTMLDivElement) | null>}
   */
  div: React.MutableRefObject<(EventTarget & HTMLDivElement) | null>;
  /**
   * Данные о расположении спец ключей
   * @param {TKeyData[]}
   */
  textareaKeysData: TKeyData[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const ReviewsStep5 = memo(
  ({
    div,
    data,
    stepId,
    chainId,
    specialKeyDict,
    textareaKeysData,
    showPreviewHandler,
    blurFromTextareaName,
    className = '',
  }: IReviewsStep5Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const onEmojiViewChange: TOnEmojiViewChangeReview = useCallback(
      ({ textAreaName, textAreaValue, stepIdEmoji }) =>
        () => {
          dispatch(
            setAbandonedTextareaDataRV({
              name: textAreaName,
              stepId: stepIdEmoji,
              selectionStart: textAreaValue.length,
              selectionEnd: textAreaValue.length,
            }),
          );
        },
      [dispatch],
    );

    const [isViewSecondEmoji, setIsViewSecondEmoji] = useState(false);

    const onEmojiViewSecondChange: TOnEmojiViewChangeReview = useCallback(
      ({ textAreaName, textAreaValue, stepIdEmoji }) =>
        () => {
          dispatch(
            setAbandonedTextareaDataRV({
              name: textAreaName,
              stepId: stepIdEmoji,
              selectionStart: textAreaValue.length,
              selectionEnd: textAreaValue.length,
            }),
          );
          setIsViewSecondEmoji(!isViewSecondEmoji);
        },
      [isViewSecondEmoji, dispatch],
    );

    const setEmojiInTextarea: TSetEmojiInTextarea = useCallback(
      (emoji: EmojiClickData) => {
        if (blurFromTextareaName && div.current) {
          dispatch(
            addKeyInTextareaRV({ innerText: emoji.emoji, callback: parseDivValue(div.current) }),
          );
          div.current.focus();
        }
      },
      [div, blurFromTextareaName, dispatch],
    );

    // Обрабатывает событие change на чекбоксе отключения/включения шага, собирает данные из события
    // создает объект с данными
    const selectOnChangeHandler: TOnChangeHandler = useCallback(
      event => {
        const { name, checked } = event.currentTarget;
        dispatch(onChange({ stepId: 5, stepData: { ...data, [name]: checked } }));
      },
      [data, dispatch],
    );

    const divOnInputHandler: TDivOnInputHandler = useCallback(
      ({ name, value, selectionEnd, selectionStart, newTextareaKeysData }) => {
        // dispatch(onChange({ stepId: 5, stepData: { ...data, [name]: value } }));
        dispatch(
          setTextareaValueRV({
            stepId: 5,
            name: name || '',
            value,
            selectionEnd,
            selectionStart,
            newTextareaKeysData,
          }),
        );
      },
      [dispatch],
    );

    const divOnClickHandler: TDivOnClickHandler = useCallback(
      event => {
        const {
          dataset: { name },
        } = event.currentTarget;

        const [start, end] = getSelectionOffset(event.currentTarget);

        div.current = event.currentTarget;

        dispatch(
          setAbandonedTextareaDataRV({
            stepId: 5,
            name: name || '',
            selectionStart: start,
            selectionEnd: end,
          }),
        );
      },
      [div, dispatch],
    );

    const setCursorPositionHandler: TDivOnKeyUpHandler = useCallback(
      event => {
        const {
          dataset: { name },
        } = event.currentTarget;

        const [start, end] = getSelectionOffset(event.currentTarget);

        div.current = event.currentTarget;

        dispatch(
          setAbandonedTextareaDataRV({
            name: name || '',
            stepId: 5,
            selectionStart: start,
            selectionEnd: end,
          }),
        );
      },
      [div, dispatch],
    );

    const setViewEmojiPicker: TSetViewEmojiPicker = ({ name, isOpenEmoji, divRef }) => {
      if (divRef) {
        div.current = divRef;
      }
      dispatch(emojiPickerToggle({ isOpen: isOpenEmoji, name }));
    };

    const memoizedShowLastStepPositivePreviewButton = useMemo(
      () => (
        <Button
          dense
          type='default'
          view='outlined'
          color='default'
          rightIcon='application'
          onClick={showPreviewHandler(data.positive)}
          text={t('REVIEW_PAGE_TEXT.REVIEW_BUTTON_TEXT.preview')}
          className='mb-8 lg:mb-10 w-[11rem]'
        />
      ),
      [t, showPreviewHandler, data],
    );

    const memoizedShowLastStepNegativePreviewButton = useMemo(
      () => (
        <Button
          dense
          type='default'
          view='outlined'
          color='default'
          rightIcon='application'
          onClick={showPreviewHandler(data.negative)}
          text={t('REVIEW_PAGE_TEXT.REVIEW_BUTTON_TEXT.preview')}
          className='w-[11rem]'
        />
      ),
      [t, showPreviewHandler, data],
    );

    return (
      <ReviewsStepsWrapper
        isLastStep
        toggleSwitchName={REVIEW_STEP_FIVE_KEY_NAMES.STATUS}
        stepId={stepId}
        chainId={chainId}
        status={data.status}
        className={className}
        isDeleteButton={false}
        onChangeHandler={selectOnChangeHandler}>
        <DivWithLabel
          isEmoji
          setEmojiInTextarea={setEmojiInTextarea}
          showEmojiPicker={onEmojiViewChange({
            textAreaName: REVIEW_STEP_FIVE_KEY_NAMES.POSITIVE,
            stepIdEmoji: 5,
            textAreaValue: data.positive,
          })}
          setViewEmojiPicker={setViewEmojiPicker}
          isRequired
          htmlFor={REVIEW_STEP_FIVE_KEY_NAMES.POSITIVE}
          value={data.positive}
          isError={!data.positive}
          specialKeyDict={specialKeyDict}
          textareaKeysData={textareaKeysData}
          divOnInputHandler={divOnInputHandler}
          divOnClickHandler={divOnClickHandler}
          setCursorPosition={setCursorPositionHandler}
          placeholder='Текст для положительного отзыва'
          className='mb-4'
        />
        {memoizedShowLastStepPositivePreviewButton}
        <DivWithLabel
          isRequired
          isEmoji
          setEmojiInTextarea={setEmojiInTextarea}
          showEmojiPicker={onEmojiViewSecondChange({
            textAreaName: REVIEW_STEP_FIVE_KEY_NAMES.NEGATIVE,
            stepIdEmoji: 5,
            textAreaValue: data.negative,
          })}
          setViewEmojiPicker={setViewEmojiPicker}
          htmlFor={REVIEW_STEP_FIVE_KEY_NAMES.NEGATIVE}
          value={data.negative}
          isError={!data.negative}
          specialKeyDict={specialKeyDict}
          textareaKeysData={textareaKeysData}
          divOnInputHandler={divOnInputHandler}
          divOnClickHandler={divOnClickHandler}
          setCursorPosition={setCursorPositionHandler}
          placeholder='Текст для отрицательного отзыва'
          className='mb-4'
        />
        {memoizedShowLastStepNegativePreviewButton}
      </ReviewsStepsWrapper>
    );
  },
);

ReviewsStep5.displayName = 'ReviewsStep5';
