import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { Dialog } from '@uikit/Dialog';

type TAutoPayDisableModalProps = {
  /**
   * Строка с параметрами автоплатежа
   * @param {string}
   */
  rate: string;
  /**
   * Callback для перезагрузки страницы
   * @param {() => void}
   */
  reloadPage: () => void;
  /**
   * Callback для закрытия всех окон
   * @param {() => void}
   */
  closeAllModal: () => void;
  /**
   * Callback для отключения автоплатежа
   * @param {() => void}
   */
  turnOffAutoPaymentHandler: () => void;
  /**
   * Параметр сообщает о начале процесса отключения автоплатежа
   * @param {boolean}
   */
  turnOffAutoPaymentIsLoading: boolean;
  /**
   * Параметр сообщает о успешном отключении автоплатежа
   * @param {boolean}
   */
  turnOffAutoPaymentIsSuccess: boolean;
};

export const AutoPayDisableModal = memo(
  ({
    rate,
    reloadPage,
    closeAllModal,
    turnOffAutoPaymentHandler,
    turnOffAutoPaymentIsLoading,
    turnOffAutoPaymentIsSuccess,
  }: TAutoPayDisableModalProps) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(true);

    const [disableDone, setDisableDone] = useState(false);

    useEffect(() => {
      if (!turnOffAutoPaymentIsLoading && turnOffAutoPaymentIsSuccess) {
        setIsOpen(false);
        setDisableDone(true);
      }
    }, [turnOffAutoPaymentIsLoading, turnOffAutoPaymentIsSuccess]);

    useEffect(() => {
      if (turnOffAutoPaymentIsLoading) {
        setDisableDone(false);
      }
    }, [turnOffAutoPaymentIsLoading]);

    return (
      <div>
        {isOpen ? (
          <Dialog
            type='default'
            view='raised'
            color='default'
            title={t('PAY_PAGE_TEXT.disableAutomaticPaymentTitle')}
            text={<span>{`${rate}.`}</span>}
            backdropOpacity={40}
            rightButton={
              <div className='flex flex-wrap'>
                <Button
                  dense
                  type='action'
                  view='outlined'
                  color='default'
                  onClick={closeAllModal}
                  text={t('PAY_PAGE_TEXT.cancel')}
                  className='mt-2 mr-6'
                />
                <Button
                  dense
                  type='action'
                  view='filled'
                  color='danger'
                  text={t('PAY_PAGE_TEXT.disable')}
                  onClick={turnOffAutoPaymentHandler}
                  loading={turnOffAutoPaymentIsLoading}
                  disabled={turnOffAutoPaymentIsLoading}
                  className='mt-2'
                />
              </div>
            }
            isOpen={isOpen}
            onClose={closeAllModal}
          />
        ) : null}
        {disableDone ? (
          <Dialog
            type='default'
            view='raised'
            color='default'
            backdropOpacity={40}
            text={<span>{t('PAY_PAGE_TEXT.pleaseRefreshPage')}</span>}
            title={t('PAY_PAGE_TEXT.automaticPaymentDisabledTitle')}
            rightButton={
              <Button
                dense
                type='action'
                view='filled'
                color='success'
                onClick={reloadPage}
                text={t('PAY_PAGE_TEXT.refresh')}
              />
            }
            isOpen={disableDone}
            onClose={closeAllModal}
          />
        ) : null}
      </div>
    );
  },
);

AutoPayDisableModal.displayName = 'AutoPayDisableModal';
