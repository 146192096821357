import React, { useEffect } from 'react';

type TUseCloseByOutSideClickPros = {
  ref: React.RefObject<HTMLDivElement>;
  closeElementCallback: () => void;
};

export const useCloseByOutSideClick = ({
  ref,
  closeElementCallback,
}: TUseCloseByOutSideClickPros) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // eslint-disable-next-line
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        closeElementCallback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, closeElementCallback]);
};
