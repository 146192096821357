import React, { useState } from 'react';
//
import { Drawer } from '@uikit/Drawer';
import { useAppDispatch } from '@store/store';
import { setText } from '@redux/mailing/mailingSlice';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { MAILING_DRAWER_TAB_NAMES, TMailingDrawerTabNames } from '@redux/mailing/models';
import { imagesToMailing, TEXT_EXAMPLES } from '@const/mailing';
import { uploadMailingMediaFile } from '@redux/mailing/mailingThunks';
import { MailingExampleSwitch } from '@components/mailingExampleSwitch';
import { TAddImageHandler, TChangeMailingDrawerTabHandler } from '@shared/types';
import { MailingTextExampleElement } from '@components/mailingTextExampleElement';
import { MailingImageExampleElement } from '@components/mailingImageExampleElement';

type TAppDrawerProps = {
  /**
   * Флаг показывает drawer
   * @param {boolean}
   */
  showDrawer: boolean;
  /**
   * Флаг переключает внешний вид блока
   * или разделение на табы, текст и картинки
   * ии на общем экране, друг под другом
   */
  isShowWithTabs: boolean;
  /**
   * Закрывает drawer
   * @param{() => void}
   */
  onCloseHandler: () => void;
};
export const MailingDrawer = ({ showDrawer, isShowWithTabs, onCloseHandler }: TAppDrawerProps) => {
  const width = useWindowWidth();
  const dispatch = useAppDispatch();
  const [activeTabName, setActiveTabName] = useState<TMailingDrawerTabNames>('text');

  const renderTitle = (
    <span className='text-h4 font-bold text-black tracking-[0.15px]'>
      Шаблоны рассылок к 8 марта
    </span>
  );

  const setSelectedTextTemplate = (text: string) => () => {
    dispatch(setText({ value: text }));
  };

  const setSelectedImage: TAddImageHandler = imgSrc => () => {
    dispatch(uploadMailingMediaFile({ url: imgSrc }));
  };

  const textExamples = TEXT_EXAMPLES.map(({ text, title }) => (
    <MailingTextExampleElement
      key={title}
      text={text}
      title={title}
      isAnimate={false}
      setSelectedTextTemplate={setSelectedTextTemplate(text)}
      className='mb-2'
    />
  ));

  const imageExamples = imagesToMailing.map(imageSrc => (
    <MailingImageExampleElement
      key={imageSrc}
      imgSrc={imageSrc}
      addImageHandler={setSelectedImage(imageSrc)}
    />
  ));

  const changeTabHandler: TChangeMailingDrawerTabHandler = tabName => () => {
    setActiveTabName(tabName);
  };

  const wrapperStyles = 'overflow-auto h-[calc(100vh-11rem)] dropdown-list-scrollbar';

  return (
    <Drawer
      type='icon'
      position='right'
      isCloseButtonShown
      isOpen={showDrawer}
      title={renderTitle}
      onClose={onCloseHandler}
      size={width < 780 ? '100%' : 540}>
      <div className='p-6'>
        <p className='text-black leading-6 tracking-[0.0275em] my-6'>
          Выберите и нажмите на подходящие текст или картинку, они подставятся в форму рассылки.
        </p>
        {isShowWithTabs ? (
          <>
            <MailingExampleSwitch
              activeTabName={activeTabName}
              changeTabHandler={changeTabHandler}
              className='mb-2'
            />
            <div className={wrapperStyles}>
              {activeTabName === MAILING_DRAWER_TAB_NAMES.TEXT ? (
                textExamples
              ) : (
                <div className='grid grid-cols-[1fr_1fr] gap-[0.5rem]'>{imageExamples}</div>
              )}
            </div>
          </>
        ) : (
          <div className={wrapperStyles}>
            {textExamples}
            <hr className='bg-quartz border border-quartz mb-2' />
            <div className='grid grid-cols-[1fr_1fr] gap-[0.5rem]'>{imageExamples}</div>
          </div>
        )}
      </div>
    </Drawer>
  );
};
