import React, { forwardRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import Component from './Radio';

const propTypes = {
  /**
   `The type of the component.
   * Variants: `def` `dense`
   * Default value (if undefined): `def` `
   */
  type: PropTypes.oneOf(["def", "dense"]),
  /**
   ` The view of the component.
   * Variants: `flat` `smooth` `outlined` `raised`
   * Default value (if undefined): `flat` `
   */
  view: PropTypes.oneOf(["flat", "smooth", "outlined", "raised"]),
  /**
   ` The color of the component.
   * Variants: `default` `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    "default",
    "primary",
    "warning",
    "danger",
    "success",
    "primary_alt",
    "warning_alt",
    "danger_alt",
    "success_alt"
  ]),
  /**
   * Value of this option.
   */
  value: PropTypes.string,
  /**
   * Label text for this option. If omitted, value is used as the label.
   */
  label: PropTypes.element,
  /**
   * Whether this option is non-interactive.
   */
  disabled: PropTypes.bool,
  /**
   * Whether the radio buttons are to be displayed inline horizontally.
   */
  inline: PropTypes.bool,
  /**
   * Second line Helper Text
   */
  helperText: PropTypes.element,
  /**
   * RTL text
   */
  rtl: PropTypes.bool,
  /**
   * Fill container
   */
  fill: PropTypes.bool,
  /**
   * Left text position
   */
  left: PropTypes.bool,
  /**
   * Whether the control is checked.
   */
  checked: PropTypes.bool,
  /**
   * Event handler invoked when input value is changed.
   */
  onChange: PropTypes.func,
  /**
   * Event handler invoked when input is clicked
   */
  onClick: PropTypes.func
};

type TComponentPropsType = InferProps<typeof propTypes>;

interface IComponentProps extends TComponentPropsType {
  [key: string]: any;
}

export const Radio: React.FC<IComponentProps> = forwardRef((props, ref) => <Component {...props} ref={ref} />);