import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import Markdown from 'react-markdown';
import { Tooltip } from '@uikit/Tooltip';
import { THelpKeys } from '@models/index';
import { InfoIcon } from '@atoms/infoIcon';
import { storageDb } from '@api/storageApi';
import { FirstTooltip } from '@blocks/firstTooltip';

interface IIconWithTooltipsProps {
  /**
   * Флаг для сокрытия иконки с подсказкой
   * @param {boolean}
   */
  noIcon?: boolean;
  /**
   * Опциональный параметр отображает обучающую подсказку
   * @param {boolean}
   */
  isFirstTooltip?: boolean;
  /**
   * Параметр для выбора из словаря подсказок текста подсказки
   * @param {THelpKeys}
   */
  tooltips: THelpKeys;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const IconWithTooltips = memo(
  ({ noIcon, tooltips, isFirstTooltip, className = '' }: IIconWithTooltipsProps) => {
    const { t } = useTranslation();

    const [isShowTooltip, setIsShowTooltip] = useState(false);

    const [isViewTooltip, setIsViewTooltip] = useState<{ tooltip: string }>({ tooltip: 'false' });

    useEffect(() => {
      if (isFirstTooltip) {
        const tooltipStatus = storageDb.getTooltipStatus();

        if (tooltipStatus.tooltip !== isViewTooltip.tooltip) {
          setIsViewTooltip(storageDb.getTooltipStatus());
        }
      }
    }, [isViewTooltip.tooltip, isFirstTooltip]);

    const hideTooltipHandler = useCallback(() => {
      storageDb.setTooltipStatus('false');
      setIsViewTooltip({ tooltip: 'false' });
    }, []);

    const showSmallTooltip = useCallback(() => {
      setIsShowTooltip(true);
    }, []);

    const hideSmallTooltip = useCallback(() => {
      setIsShowTooltip(false);
    }, []);

    // Проверяем содержит ли текст подсказки ключевое слово RED
    const isRedTooltip = useMemo(
      () => t(`TOOLTIPS_TEXT.${tooltips}`).includes('RED'),
      [t, tooltips],
    );

    const hintText = useMemo(
      () =>
        isRedTooltip ? t(`TOOLTIPS_TEXT.${tooltips}`).slice(0, -4) : t(`TOOLTIPS_TEXT.${tooltips}`),
      [isRedTooltip, t, tooltips],
    );

    return (
      <div className='relative flex items-center justify-center'>
        {noIcon ? null : (
          <Tooltip
            color='default'
            position='auto'
            disabled={false}
            isOpen={isShowTooltip}
            content={<Markdown className='md-wrapper'>{hintText}</Markdown>}
            className='max-w-[23rem]'>
            <InfoIcon
              onMouseOut={hideSmallTooltip}
              onMouseOver={showSmallTooltip}
              iconColor={isShowTooltip ? '#43A047' : '#9696A0'}
              className={`cursor-pointer mb-1 ${className}`}
            />
          </Tooltip>
        )}
        {isFirstTooltip && !isViewTooltip.tooltip.includes('false') ? (
          <FirstTooltip
            view='filled'
            color='default'
            callback={hideTooltipHandler}
            bodyText={t('TOOLTIPS_TEXT.firstTooltipText')}
            className='top-0 left-0 translate-y-[-111%] translate-x-[-49.8%] absolute w-[334px] z-[999]'
          />
        ) : null}
      </div>
    );
  },
);

IconWithTooltips.displayName = 'IconWithTooltips';
