import { TPayResponseData } from '@api/types';
import { numberOfGiftWeek, numberOfMonth } from '@const/pay';
import { TCalculatePrice, TPayData, TPaymentData } from '@redux/pay/types';

export const emptyMonthData = {
  '1': 0,
  '3': 0,
  '6': 0,
  '12': 0,
};

export const emptyCalculatedPrice: TCalculatePrice = {
  newPayTillDate: '',
  paidPeriodReducedBy: 0,
  modulesCost: [],
  fullCoast: 0,
};

export const payPageEmptyData: TPayData = {
  isTestPeriod: false,
  branchEmployees: '',
  amount: '',
  isAutoPayEnable: false,
  payStatus: false,
  payTillDate: '',
  numberOfMonth,
  numberOfGiftWeek,
  createBill: false,
  monthCost: emptyMonthData,
  discount: 0,
  activeModules: [],
  modulesPrice: {
    WA: 0,
    TG: 0,
    loyalty: 0,
  },
  lastPayLength: 0,
  cardLastFourDigits: '0000',
  currency: 'RUB',
};

export const paymentData: TPaymentData[] = [
  {
    date: '24.12.2020',
    period: '26.01.2021',
    sum: 1890,
    channels: ['WA', 'TG'],
    paymentType: 'auto_payment',
  },
  {
    date: '25.01.2021',
    period: '26.02.2021',
    sum: 1890,
    channels: ['WA', 'TG'],
    paymentType: 'bonus',
  },
  {
    date: '25.03.2021',
    period: '26.04.2021',
    sum: 1890,
    channels: ['WA', 'TG'],
    paymentType: 'bill',
  },
  {
    date: '26.04.2021',
    period: '26.05.2021',
    sum: 1890,
    channels: ['WA', 'TG'],
    paymentType: 'bonus_days',
  },
  {
    date: '26.05.2021',
    period: '26.06.2021',
    sum: 1890,
    channels: ['WA', 'TG'],
    paymentType: 'link_paid',
  },
  {
    date: '26.06.2021',
    period: '26.07.2021',
    sum: 1890,
    channels: ['WA', 'TG'],
    paymentType: '-',
  },
  {
    date: '25.07.2021',
    period: '26.08.2021',
    sum: 1890,
    channels: ['WA', 'TG'],
    paymentType: '-',
  },
  {
    date: '29.08.2021',
    period: '29.09.2021',
    sum: 1890,
    channels: ['WA', 'TG'],
    paymentType: '-',
  },
  {
    date: '30.09.2021',
    period: '30.10.2021',
    sum: 1890,
    channels: ['WA', 'TG'],
    paymentType: '-',
  },
  {
    date: '02.11.2021',
    period: '02.12.2021',
    sum: 1890,
    channels: ['WA', 'TG'],
    paymentType: '-',
  },
];

export const payMockData: TPayData = {
  branchEmployees: '10',
  amount: '2390',
  isAutoPayEnable: false,
  payStatus: true,
  payTillDate: '02.12.2025',
  activeModules: ['WA', 'TG'],
  numberOfMonth,
  numberOfGiftWeek,
  monthCost: {
    '1': 940.0,
    '3': 929.8378378378378,
    '6': 924.8387096774194,
    '12': 919.8930481283422,
  },
  discount: 10,
  createBill: true,
  modulesPrice: {
    WA: 1000,
    TG: 1000,
    loyalty: 1000,
  },
  lastPayLength: 3,
  cardLastFourDigits: '9999',
  isTestPeriod: false,
  currency: 'RUB',
};

const paymentsResponse = [
  {
    date: '24.12.2020',
    period: '26.01.2021',
    summ: 1890,
  },
  {
    date: '25.01.2021',
    period: '26.02.2021',
    summ: 1890,
  },
  {
    date: '25.03.2021',
    period: '26.04.2021',
    summ: 1890,
  },
  {
    date: '26.04.2021',
    period: '26.05.2021',
    summ: 1890,
  },
  {
    date: '26.05.2021',
    period: '26.06.2021',
    summ: 1890,
  },
  {
    date: '26.06.2021',
    period: '26.07.2021',
    summ: 1890,
  },
  {
    date: '25.07.2021',
    period: '26.08.2021',
    summ: 1890,
  },
  {
    date: '29.08.2021',
    period: '29.09.2021',
    summ: 1890,
  },
  {
    date: '30.09.2021',
    period: '30.10.2021',
    summ: 1890,
  },
  {
    date: '02.11.2021',
    period: '02.12.2021',
    summ: 1890,
  },
];

export const getPayPageResponseMockData: TPayResponseData = {
  pay_status: true,
  pay_date: '24.08.2023',
  autopay: false,
  amount: '1000',
  active_modules: ['WA', 'TG', 'loyalty'],
  modules_price: {
    WA: 999,
    TG: 999,
    loyalty: 999,
  },
  create_bill: true,
  bonus_week: {
    '1': 0,
    '3': 1,
    '6': 3,
    '12': 5,
  },
  discount: 0.1,
  branch_employees: '3',
  month_cost: {
    '1': 100,
    '3': 300,
    '6': 600,
    '12': 1200,
  },
  last_pay_length: 3,
  last_4: '0000',
  currency: 'RUB',
  is_test_period: false,
};
