import React, { memo, useCallback, useMemo, useRef } from 'react';
import { Icon } from '@blueprintjs/core';
//
import { Menu } from '@uikit/Menu';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { useCloseByOutSideClick } from '@hooks/useCloseByOutSideClick';

type TYearsListProps = {
  /**
   * Флаг показа выпадающего списка
   * @param {boolean}
   */
  isShowYearList: boolean;
  /**
   * Callback для показа списка месяцев
   * @param {() => void}
   */
  showListCallback: () => void;
  /**
   * Callback для скрытия списка месяцев
   * @param {() => void}
   */
  hideListCallback: () => void;
  /**
   * Индекс выбранного месяца
   * @param {number}
   */
  selectedYear: number;
  /**
   * Callback для месяца
   * @param index
   */
  setSelectedYearCallback: (index: number) => void;
  /**
   * Список доступных в выбору годов
   * @param {number[]}
   */
  yearList: number[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const YearsList = memo(
  ({
    yearList,
    selectedYear,
    isShowYearList,
    hideListCallback,
    showListCallback,
    setSelectedYearCallback,
    className = '',
  }: TYearsListProps) => {
    // реф на элемент выпадающего списка
    const dropdownRef = useRef<HTMLDivElement>(null);

    useCloseByOutSideClick({ closeElementCallback: hideListCallback, ref: dropdownRef });

    // обработчик выбора года
    const setSelectedYearHandler = useCallback(
      (year: number) => () => {
        hideListCallback();
        setSelectedYearCallback(year);
      },
      [hideListCallback, setSelectedYearCallback],
    );

    const yearListToggle = useCallback(
      (isShow: boolean) => () => {
        if (isShow) {
          hideListCallback();
        } else {
          showListCallback();
        }
      },
      [hideListCallback, showListCallback],
    );

    // список годов для рендеринга
    const yearListToRender = useMemo(
      () =>
        yearList.map(year => (
          <MenuItem
            key={year}
            text={<span>{year}</span>}
            onClick={setSelectedYearHandler(year)}
            className={selectedYear === year ? '!bg-ivory' : ''}
          />
        )),
      [yearList, selectedYear, setSelectedYearHandler],
    );

    const dropdownStyle =
      'cursor-pointer relative text-green1 flex items-center justify-between tracking-[0.018em] px-2.5 h-[1.75rem] w-[3.75rem]';

    const controlElementColor = '#37833B';

    return (
      <div className={className}>
        <div ref={dropdownRef} onClick={yearListToggle(isShowYearList)} className={dropdownStyle}>
          <span className='mr-2'>{selectedYear}</span>
          <Icon icon='caret-down' color={controlElementColor} size={16} />
          {isShowYearList ? (
            <Menu view='raised' className='absolute top-[2rem] z-[52]'>
              {yearListToRender}
            </Menu>
          ) : null}
        </div>
      </div>
    );
  },
);

YearsList.displayName = 'YearsList';
