import React, { memo } from 'react';
//
import { Icon } from '@atoms/icon';

interface IConnectionStatusWithTextProps {
  /**
   * Статус подключения
   * @param {string}
   */
  status: string;
  /**
   * Текстовое описание статуса
   * @param {string}
   */
  statusText: string;
}

export const ConnectionStatusWithText = memo(
  ({ status, statusText }: IConnectionStatusWithTextProps) => (
    <div className='flex items-center'>
      <div className='flex items-center justify-center h-[1.75rem] w-[1.75rem] mr-3'>
        <Icon variant={status} className='h-[1.125rem] w-[1.125rem]' />
      </div>
      <span className='font-inter font-medium text-body_text text-blackText leading-6 tracking-[0.022em]'>
        {statusText}
      </span>
    </div>
  ),
);
