import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Counter } from '@uikit/Counter';
import { IconWithTooltips } from '@components/iconWithTooltips';

interface IVisitsProps {
  /**
   * Значение счетчика визитов
   * @param {string}
   */
  visits: string;
  /**
   * Callback для обработки события onChange
   * @param value
   */
  onChangeHandler: (value: string) => void;
  /**
   * Опциональный параметр, скрывает иконку с информацией
   * @param {boolean}
   */
  hideInfoIcon?: boolean;
  /**
   * Опциональный параметр, текст перед счетчиком
   * если текст не передается, остается стандартный текст
   * @param {string}
   */
  textBeforeCounter?: string;
  /**
   * Опциональный параметр, текст после счетчика
   * если текст не передается, остается стандартный текст
   * @param {string}
   */
  textAfterCounter?: string;
  /**
   * Флаг отключает возможность изменять значение
   * @param {boolean}
   */
  disabled?: boolean;
  /**
   * Если true, показывает знак бесконечности если значение visits === undefined
   * @param {boolean}
   */
  showInfinitySign?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const Visits = memo(
  ({
    visits,
    onChangeHandler,
    textAfterCounter,
    disabled = false,
    textBeforeCounter,
    hideInfoIcon = false,
    showInfinitySign = false,
    className = '',
  }: IVisitsProps) => {
    const { t } = useTranslation();

    const memoizedCounter = useMemo(
      () => (
        <div className='relative'>
          <Counter
            view='outlined'
            value={visits}
            leftIcon='plus'
            rightIcon='minus'
            leftColor='success'
            disabled={disabled}
            rightColor='success'
            onChange={onChangeHandler}
          />
          {!visits && showInfinitySign ? (
            <span className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-[1.3rem]'>
              ∞
            </span>
          ) : null}
        </div>
      ),
      [visits, showInfinitySign, onChangeHandler, disabled],
    );

    const textStyle = 'text-black leading-6 tracking-[0.022em]';

    return (
      <div className={`font-medium flex flex-col m:flex-row flex-wrap m:items-center ${className}`}>
        <span className={`${textStyle} mb-2 m:mb-0 mr-2.5`}>
          {textBeforeCounter || t('TEMPLATE_PAGE_TEXT.visitsSend')}
        </span>
        {memoizedCounter}
        <div className='flex items-center'>
          <span className={`${textStyle} my-2 m:my-0 m:mx-2.5`}>
            {textAfterCounter || t('TEMPLATE_PAGE_TEXT.visits')}
          </span>
          {!hideInfoIcon ? <IconWithTooltips tooltips='visits' /> : null}
        </div>
      </div>
    );
  },
);

Visits.displayName = 'Visits';
