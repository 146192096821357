import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Badge } from '@uikit/Badge';
import { Button } from '@uikit/Button';
import { PATH_NAMES } from '@data/dict';
import { TAppLanguage } from '@models/index';
import { useAppDispatch } from '@store/store';
import { TOnclickHandler } from '@shared/types';
import { NavDropDown } from '@components/navDropDown';
import { MAILING_PAGES_NAME_DICT } from '@const/mailing';
import { NavItem } from '@blocks/templateNavItem';
import { moveToSendOutStart } from '@redux/mailing/mailingSlice';

interface IMailingNavProps {
  /**
   * Текущий путь, взят из URL
   * @param {string}
   */
  path: string;
  /**
   * Callback для обработки нажатия на кнопку "Новая рассылка"
   * @param {TOnclickHandler}
   */
  callBack?: TOnclickHandler;
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Количество запущенных рассылок, отображается слева от пункта меню "Запущенные рассылки"
   * @param {number}
   */
  numberOfMailingsLaunched: number;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const MailingNav = memo(
  ({
    path,
    callBack,
    appLanguage,
    selectedFilialAccId,
    numberOfMailingsLaunched,
    className = '',
  }: IMailingNavProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const moveToSendOutStartHandler = useCallback(() => {
      dispatch(moveToSendOutStart());
    }, [dispatch]);

    return (
      <div className={`flex flex-wrap justify-between ${className}`}>
        <div className='sm:hidden w-full'>
          <NavDropDown
            isMailingPage
            selectedFilialAccId={selectedFilialAccId}
            pageNameDict={MAILING_PAGES_NAME_DICT[appLanguage]}
            className='nav_dropdown'
          />
        </div>
        <div className='flex flex-wrap items-center justify-between w-full'>
          <div className='hidden sm:flex flex-wrap mb-4'>
            <NavItem
              isActive={path.includes(PATH_NAMES.MAILING)}
              to={`${PATH_NAMES.MAILING}?acc_id=${selectedFilialAccId}`}
              text={MAILING_PAGES_NAME_DICT[appLanguage][PATH_NAMES.MAILING]}
              onClick={moveToSendOutStartHandler}
              className='mr-2'
            />
            <div className='mr-2'>
              <Badge
                middle
                view='filled'
                color='success'
                hide={!numberOfMailingsLaunched}
                value={String(numberOfMailingsLaunched)}
                className={numberOfMailingsLaunched > 9 ? 'left_2' : 'left_1'}>
                <NavItem
                  isActive={path.includes(PATH_NAMES.STARTED_MAILING)}
                  text={MAILING_PAGES_NAME_DICT[appLanguage][PATH_NAMES.STARTED_MAILING]}
                  to={`${PATH_NAMES.STARTED_MAILING}?acc_id=${selectedFilialAccId}`}
                  className={
                    numberOfMailingsLaunched
                      ? numberOfMailingsLaunched > 9
                        ? 'pl-14'
                        : 'pl-10'
                      : 'pl-3'
                  }
                />
              </Badge>
            </div>
            <NavItem
              isActive={
                path.includes(PATH_NAMES.STATISTICS) ||
                path.includes(PATH_NAMES.MAILING_DETAIL_STATS)
              }
              text={MAILING_PAGES_NAME_DICT[appLanguage][PATH_NAMES.STATISTICS]}
              to={`${PATH_NAMES.STATISTICS}?acc_id=${selectedFilialAccId}`}
            />
          </div>
          {path === PATH_NAMES.STARTED_MAILING ||
          path === PATH_NAMES.STATISTICS ||
          path === PATH_NAMES.MAILING_DETAIL_STATS ? (
            <Button
              dense
              icon='plus'
              type='action'
              view='filled'
              color='success'
              onClick={callBack}
              text={t('MAILING_PAGE_TEXT.newSendOut')}
              className='mt-4 sm:mt-0 min-w-[12.5rem] w-full sm:w-fit mb-4'
            />
          ) : null}
        </div>
      </div>
    );
  },
);

MailingNav.displayName = 'MailingNav';
