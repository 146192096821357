import React, { memo, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { chartColors } from '@const/chart';
import {
  Title,
  Legend,
  Colors,
  Tooltip,
  BarElement,
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
} from 'chart.js';

type TChartDataItem = {
  /**
   * Название типа данных
   * @param {string}
   */
  label: string;
  /**
   * Данные колонки графика
   * @param {number[]}
   */
  data: number[];
  /**
   * Опиональный параметр цвета колонки в HEX
   * @param {string}
   */
  backgroundColor?: string;
};

interface IBarChartProps {
  /**
   * Объединять ли данные из одного датасета
   * @param {boolean}
   * @default false
   */
  stacked?: boolean;
  /**
   * Отображать ли заголовок
   * @param {boolean}
   * @default false
   */
  displayTitle?: boolean;
  /**
   * Названия голонок графика
   * @param {string[]}
   */
  columnLabels: string[];
  /**
   * Заголовок графика
   * @param {string}
   */
  chartTitle: string;
  /**
   * Опциональный параметр, позиция легенды на графике
   * @param {'bottom' | 'left' | 'top' | 'right' | 'center' | undefined}
   * @default 'bottom''
   */
  chartLegendPosition?: 'bottom' | 'left' | 'top' | 'right' | 'center' | undefined;
  /**
   * Данные графика
   * @param {TChartDataItem[]}
   */
  data: TChartDataItem[];
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors);

export const BarChart = memo(
  ({
    chartTitle,
    columnLabels,
    stacked = false,
    data: chartData,
    displayTitle = false,
    chartLegendPosition = 'bottom',
  }: IBarChartProps) => {
    // Определаем нужно ли группировать данные
    const scales = useMemo(() => {
      return stacked
        ? {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          }
        : {};
    }, [stacked]);

    // Устанавливаем backgroundColor для данных если не были переданы
    const createDataset = (
      { label, data, backgroundColor: color }: TChartDataItem,
      index: number,
    ) => {
      const backgroundColor = color || chartColors[Object.keys(chartColors)[index]];
      return {
        label,
        data,
        backgroundColor,
      };
    };

    // Генерируем цельные данные необходимые для графика
    const dataToDisplay = useMemo(() => {
      return {
        labels: columnLabels,
        datasets: chartData.map(createDataset),
      };
    }, [columnLabels, chartData]);

    // Определяем параметры графика
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales,
      plugins: {
        legend: {
          position: `${chartLegendPosition}` as const,
        },
        title: {
          display: displayTitle,
          text: chartTitle,
        },
      },
      interaction: {
        mode: 'index' as const,
        axis: 'x' as const,
        position: 'nearest' as const,
      },
    };

    return (
      <div
        className='chart-container'
        style={{ position: 'relative', height: '420px', width: `clamp(200px, 99%, 1270px)` }}>
        <Bar options={options} data={dataToDisplay} />
      </div>
    );
  },
);

BarChart.displayName = 'BarChart';
