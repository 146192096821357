import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { TOnChangeHandler } from '@shared/types';
import { InputWithLabel } from '@blocks/inputWithLabel';
import { PhoneNumberInput } from '@atoms/phoneNumberInput';
import { ADD_LEADS_KEY_NAMES } from '@redux/addLeads/initialState';

type TAddLeadFormProps = {
  /**
   * Номер телефона
   * @param {string}
   */
  phone: string;
  /**
   * Текст имени лида
   * @param {string}
   */
  leadName: string;
  /**
   * Идентификатор филиала
   * @param {string}
   */
  branchId: string;
  /**
   * Текст комментария
   * @param {string}
   */
  comment: string;
  /**
   * Флаг процесса создания лида
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Callback для создания лида
   * @param {() => void}
   */
  createLidHandler: () => void;
  /**
   * Callback обрабатывает изменения в полях ввода
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Callback для изменения значения телефона
   * @param {(value: string) => void}
   */
  onChangeBlacklistCallback: (value: string) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const AddLeadForm = memo(
  ({
    phone,
    comment,
    leadName,
    branchId,
    isLoading,
    onChangeHandler,
    createLidHandler,
    onChangeBlacklistCallback,
    className = '',
  }: TAddLeadFormProps) => {
    const { t } = useTranslation();

    const isAddButtonDisable = !(phone?.length > 6 || branchId);

    return (
      <div className={className}>
        <p className='text-black text-body_text leading-6 tracking-[0.0275em] mb-4'>
          {t('PARTNER_PAGE_TEXT.addLeadForm.header')}
        </p>
        <div className='bg-gray1 pt-4 pl-4 pr-2 pb-2 rounded-lg flex flex-wrap w-full'>
          <div className='grow basis-[15.5rem] mr-2 mb-6 m:mb-4'>
            <div className='font-medium leading-6 tracking-[0.022em] mb-[0.6875rem]'>
              {t('PARTNER_PAGE_TEXT.addLeadForm.phoneLabel')}
            </div>
            <PhoneNumberInput
              value={phone}
              onChangeHandler={onChangeBlacklistCallback}
              placeholder={t('PARTNER_PAGE_TEXT.addLeadForm.phonePlaceholder')}
            />
          </div>
          <InputWithLabel
            value={branchId}
            onChangeCallback={onChangeHandler}
            name={ADD_LEADS_KEY_NAMES.BRANCH_ID}
            labelText={t('PARTNER_PAGE_TEXT.addLeadForm.branchId')}
            placeholderText={t('PARTNER_PAGE_TEXT.addLeadForm.branchIdPlaceholder')}
            className='basis-[15rem] mr-2 mb-6 m:mb-4'
          />
          <InputWithLabel
            value={leadName}
            name={ADD_LEADS_KEY_NAMES.LEAD_NAME}
            onChangeCallback={onChangeHandler}
            labelText={t('PARTNER_PAGE_TEXT.addLeadForm.leadNameLabel')}
            placeholderText={t('PARTNER_PAGE_TEXT.addLeadForm.leadNamePlaceholder')}
            className='basis-[15rem] mr-2 mb-6 m:mb-4'
          />
          <InputWithLabel
            value={comment}
            name={ADD_LEADS_KEY_NAMES.COMMENT}
            onChangeCallback={onChangeHandler}
            labelText={t('PARTNER_PAGE_TEXT.addLeadForm.commentLabel')}
            placeholderText={t('PARTNER_PAGE_TEXT.addLeadForm.commentPlaceholder')}
            className='basis-[21.25rem] mr-2 mb-6 m:mb-4'
          />
          <Button
            dense
            icon='plus'
            type='action'
            view='filled'
            color='success'
            loading={isLoading}
            onClick={createLidHandler}
            disabled={isAddButtonDisable}
            text={t('PARTNER_PAGE_TEXT.addLeadForm.addLeadButton')}
            className='self-end mb-[1.2rem]'
          />
        </div>
      </div>
    );
  },
);

AddLeadForm.displayName = 'AddLeadForm';
