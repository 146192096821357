import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import Component from './Item'

const propTypes = {
  /**
   ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    "default",
    "primary",
    "warning",
    "danger",
    "success",
    "primary_alt",
    "warning_alt",
    "danger_alt",
    "success_alt"
  ]),
  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className: PropTypes.string,
  /**
   * Name of a Blueprint UI icon (or an icon element) to render before the text.
   */
  icon: PropTypes.string,
  /**
   * Right-aligned label text content, useful for displaying hotkeys.
   */
  label: PropTypes.string,
  /**
   * Item text, required for usability.
   */
  text: PropTypes.element.isRequired,
  /**
   * Children of this component will be rendered in a submenu that appears when hovering or clicking on this menu item.
   */
  children: PropTypes.array
};

type TComponentPropsType = InferProps<typeof propTypes>;

interface IComponentProps extends TComponentPropsType {
  [key: string]: any;
}

export const MenuItem: React.FC<IComponentProps> = (props) => <Component {...props} />