import React, { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
//
import { Menu } from '@uikit/Menu';
import { Dropdown } from '@uikit/Dropdown';
import { getLinkHref } from '@helpers/index';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { TSimpleStringObj } from '@models/index';

interface INavDropDownProps {
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Словарь для составления списка в dropdown
   * @param {TSimpleStringObj}
   */
  pageNameDict: TSimpleStringObj;
  /**
   * Опциональный флаг изменяет логику работы со словарями списка страниц
   * @param {boolean}
   */
  isMailingPage?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const NavDropDown = memo(
  ({
    pageNameDict,
    selectedFilialAccId,
    isMailingPage = false,
    className = '',
  }: INavDropDownProps) => {
    const navigate = useNavigate();
    const path = useLocation().pathname;

    // Текст выбранного пункта меню
    const selectedMenuItemName = pageNameDict[path];

    // Для страницы рассылок обрезаем "лишний" пункт меню
    const pageNames = isMailingPage
      ? Object.entries(pageNameDict).slice(0, 3)
      : Object.entries(pageNameDict);

    // Подготавливает массив MenuItem для рендеренга
    const links = pageNames.map(([link, text]) => (
      <MenuItem
        key={link}
        text={<span>{text}</span>}
        onClick={() => navigate(getLinkHref({ route: link, accId: selectedFilialAccId }))}
        className={selectedMenuItemName === text ? '!bg-ivory' : ''}
      />
    ));

    return (
      <Dropdown
        view='outlined'
        color='success'
        position='bottom-left'
        text={<span>{selectedMenuItemName}</span>}
        content={<Menu view='raised'>{links}</Menu>}
        className={`w-full ${className}`}
      />
    );
  },
);

NavDropDown.displayName = 'NavDropDown';
