import { TStatsData } from '@redux/reviewsStats/types';
import { SORTING_TYPES } from '@const/common';
import { REVIEWS_STATS_SORT_DROPDOWN_VALUES } from '@const/reviews';
import { getDateStringToGetData, getStartOfYearData } from '@helpers/index';

export type TStatuses = 'loading' | 'success' | 'error' | 'accessDenied' | 'sendStatsToTG';

export interface IInitialState {
  status: TStatuses;
  sortedBy: string;
  sortDirection: SORTING_TYPES;
  currentPage: number;
  rowsPerPage: number;
  startDate: string;
  endDate: string;
  totalCount: number;
  errorMessage: string;
  successMessage: string;
  data: TStatsData[];
}

export const initialState: IInitialState = {
  status: 'success',
  totalCount: 0,
  currentPage: 0,
  rowsPerPage: 8,
  startDate: getStartOfYearData().startOfYearDate,
  endDate: getDateStringToGetData(new Date()),
  sortDirection: SORTING_TYPES.DESCENDING,
  sortedBy: REVIEWS_STATS_SORT_DROPDOWN_VALUES.TOTAL_SENDS,
  successMessage: '',
  errorMessage: '',
  data: [],
};
