import React, { memo, useCallback, useEffect, useState } from 'react';
//
import { Chips } from '@uikit/Chips';
import { Spinner } from '@uikit/Spinner';

interface IBlackListItemProps {
  /**
   * Значение которое отобразится внутри
   * @param {string}
   */
  number: string;
  /**
   * Флаг состояния запроса на удаление номера
   * необходим для скрытия спинера в случае ошибки
   * @param {boolean}
   */
  isDeleteError: boolean;
  /**
   * Callback для удаления номера из списка
   * @param {() => void}
   */
  deleteNumber: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const BlackListItem = memo(
  ({ number, deleteNumber, isDeleteError, className = '' }: IBlackListItemProps) => {
    const [deletingInProgress, setDeletingInProgress] = useState(() => false);

    useEffect(() => {
      if (isDeleteError) {
        setDeletingInProgress(false);
      }
    }, [isDeleteError]);

    // обработчик удаления, первый вариант, в последствии таймер заменится пропсом isLoading, для отображения спиннера
    const onClickHandler = useCallback(() => {
      setDeletingInProgress(true);
      deleteNumber();
    }, [deleteNumber, setDeletingInProgress]);

    return (
      <li className={`relative m-0 p-0 list-none w-[10.75rem] ${className}`}>
        <Chips
          round
          removable
          tag={number}
          type='def'
          color='danger'
          onRemove={onClickHandler}
          disabled={deletingInProgress}
          className='black-list'
        />
        {deletingInProgress ? (
          <Spinner
            size={16}
            color='default'
            className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'
          />
        ) : null}
      </li>
    );
  },
);

BlackListItem.displayName = 'BlackListItem';
