export type TConsultantFullTableData = {
  accId: string;
  filialName: string;
  lastPayDate: string;
  payTillDate: string;
};

export type TConsultantFullTableStoreData = {
  qrCode: string;
  promoUrl: string;
  promoCode: string;
  data: TConsultantFullTableData[];
};

export enum CONSULTANT_FULL_TABLE_DATA_KEY_NAMES {
  ACC_ID = 'accId',
  FILIAL_NAME = 'filialName',
  LAST_PAY_DATE = 'lastPayDate',
  PAY_TILL_DATE = 'payTillDate',
}
