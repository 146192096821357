import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
//
import { Button } from '@uikit/Button';
import { PATH_NAMES } from '@data/dict';
import { FormLabel } from '@blocks/formLabel';
import { myTrackerApi } from '@api/myTrackerApi';
import { TOnChangeHandler } from '@shared/types';
import { getAppData } from '@redux/app/selectors';
import { QUERY_PARAMS } from '@const/apiConstants';
import { Breadcrumb, Icon } from '@blueprintjs/core';
import { ToastsMessages } from '@components/toastsMessages';
import { getAccountsData } from '@redux/accounts/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { DETAIL_STATISTIC_FILTER_ITEMS } from '@const/mailing';
import { TSelectedFilters } from '@redux/sendOutStatisticsTable/models';
import { getSendOutStatisticsTableData } from '@redux/mailing/selectors';
import { DetailStatisticFilter } from '@components/detailStatisticFilter';
import { MailingDetailStatsTable } from '@blocks/mailingDetailStatsTable';
import { MailingDetailTableSearchBlock } from '@components/mailingDetailTableSearch';
import { getDetailSendOutStatisticsDownloadLink } from '@redux/sendOutStatisticsTable/sendOutStatisticsTableThunks';
import {
  startSearchSDS,
  setTableFilters,
  clearSearchStringSDS,
  clearErrorMessageSDS,
  clearSuccessMessageSDS,
  setTableSearchStringSDS,
} from '@redux/sendOutStatisticsTable/sendOutStatisticsTableSlice';

export const MailingDetailStat = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { appLanguage } = useAppSelector(getAppData);
  const sendOutId = searchParams.get(QUERY_PARAMS.SENDOUT_ID);
  const { selectedFilial } = useAppSelector(getAccountsData);
  const { filteredTableData, searchString, selectedFilters, errorMessage, successMessage } =
    useAppSelector(getSendOutStatisticsTableData);

  useEffect(() => {
    async function sendEvent() {
      await myTrackerApi.sendoutDetailStatisticsPadeOpen();
    }

    sendEvent();
  }, []);

  // осуществляет редирект на страницу детальной статистики
  const navToMailingStatsList = useCallback(() => {
    navigate(`${PATH_NAMES.STATISTICS}?${QUERY_PARAMS.ACC_ID}=${selectedFilial?.accId}`);
  }, [selectedFilial, navigate]);

  // Обработчик изменения поисковой строки
  const setSearchStringHandler: TOnChangeHandler = useCallback(
    event => {
      const { value } = event.currentTarget;
      dispatch(setTableSearchStringSDS({ value }));
    },
    [dispatch],
  );

  // Очищает поисковую строку
  const clearSearchStringHandler = useCallback(() => {
    dispatch(clearSearchStringSDS());
  }, [dispatch]);

  // Запускает поиск по значению введенному в поисковую строку
  const startSearchHandler = useCallback(() => {
    dispatch(startSearchSDS());
  }, [dispatch]);

  // Включает/отключает фильтры данных в таблице
  const setFiltersHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.currentTarget;

      dispatch(setTableFilters({ name: name as TSelectedFilters, checked }));
    },
    [dispatch],
  );

  // Запускает скачивание Excel файла
  const getExcelFife = useCallback(() => {
    if (sendOutId) {
      dispatch(
        getDetailSendOutStatisticsDownloadLink({ sendOutId, getExcelFile: true, method: 'all' }),
      );
    }
  }, [sendOutId, dispatch]);

  // Очищает/скрывает сообщение о ошибке
  const clearErrorMessageHandler = () => {
    dispatch(clearErrorMessageSDS());
  };

  // Очищает/скрывает сообщение о успешных действиях
  const clearSuccessMessageHandler = () => {
    dispatch(clearSuccessMessageSDS());
  };

  return (
    <div className='w-screen md:w-full'>
      <div className='flex flex-wrap mb-[2.5rem]'>
        <Button
          dense
          view='outlined'
          onClick={navToMailingStatsList}
          icon={<Icon icon='chevron-left' size={16} />}
          className='mr-4 mb-2'>
          <span className='leading-[1.25125rem]'>
            {t('MAILING_PAGE_TEXT.mailingDetailStat.allSendOutsButton')}
          </span>
        </Button>
        <div className='flex items-center mb-2'>
          <Breadcrumb
            className='mr-[1rem] text-black tracking-[0.022rem]'
            text={t('MAILING_PAGE_TEXT.mailingDetailStat.sendOutId', { sendOutId })}
            icon={<Icon icon='chevron-right' size={20} />}
          />
        </div>
      </div>
      <MailingDetailTableSearchBlock
        searchString={searchString}
        disableSearchButton={false}
        sendFileToTGCallback={getExcelFife}
        startSearchHandler={startSearchHandler}
        setSearchStringHandler={setSearchStringHandler}
        clearSearchStringHandler={clearSearchStringHandler}
        className='mb-8 overflow-x-auto'
      />
      <div className='flex flex-col sm:flex-row flex-wrap mb-2'>
        <FormLabel
          htmlFor=''
          labelTextVariant='detailFilter'
          className='mb-2.5 sm:mr-2.5 sm:mb-0'
        />
        <DetailStatisticFilter
          onChangeHandler={setFiltersHandler}
          selectedFiltersList={selectedFilters}
          filterList={DETAIL_STATISTIC_FILTER_ITEMS[appLanguage]}
          className='sm:max-w-[17.75rem] w-full'
        />
      </div>
      <MailingDetailStatsTable tableData={filteredTableData} />
      <ToastsMessages
        errorMessage={errorMessage}
        successMessage={successMessage}
        isShowErrorToast={!!errorMessage}
        isShowSuccessToast={!!successMessage}
        clearErrorCallback={clearErrorMessageHandler}
        clearSuccessStatusCallback={clearSuccessMessageHandler}
      />
    </div>
  );
});
