import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTimer } from '@hooks/useTimer';
import { QR_LIFE_TIME } from '@const/connect';
import { BotButton } from '@blocks/botButton';
import { CONNECTION_STATUSES, TQRCodeData } from '@models/index';
import { TConnectionWAStatusData } from '@redux/connection/types';
import { ClearMessageQueryModal } from '@blocks/clearMessageQueryModal';

type TProps = {
  /**
   * Флаг процесса получения QR кода
   * @param {boolean}
   */
  isFetchingQR: boolean;
  /**
   * Callback для получения QR кода
   * @param {() => void}
   */
  getQr: () => void;
  /**
   * Флаг ошибки получения QR кода
   * @param {boolean}
   */
  isGetQRError: boolean;
  /**
   * Callback для очистки очереди сообщений
   * @param {() => void}
   */
  clearQuery: () => void;
  /**
   * Отображает callout с предложением обновить страницу для получения нового статуса
   * @param {() => void}
   */
  showGetStatusCalloutHandler: () => void;
  /**
   * Данные QR кода
   * @param {TQRCodeData | undefined}
   */
  qrData: TQRCodeData | undefined;
  /**
   * Данные страницы подключения
   * @param {TConnectionWAStatusData}
   */
  data: TConnectionWAStatusData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const QrConnection = memo(
  ({
    getQr,
    qrData,
    clearQuery,
    isGetQRError,
    isFetchingQR,
    showGetStatusCalloutHandler,
    data,
    className = '',
  }: TProps) => {
    const { t } = useTranslation();

    const { isCompleted } = useTimer({
      initialMinute: 0,
      initialSeconds: 59,
      isStart: true,
    });

    useEffect(() => {
      if (isCompleted) {
        showGetStatusCalloutHandler();
      }
    }, [isCompleted, showGetStatusCalloutHandler]);

    // деструктурезируем state
    const { status, messages } = data;

    // храним строку qr кода
    const [qr, setQr] = useState(qrData?.qr);

    const [isOpen, setIsOpen] = useState(false);

    // Состояние отражает запущен ли таймер или нет
    const timerSetStatusRef = useRef<boolean>(false);

    // Эффект запускается при первом получении QR кода и запрашивает новый через 61 секунду. срок жизни QR кода 1 мин.
    useEffect(() => {
      let timeout: NodeJS.Timeout | null = null;

      if (!isFetchingQR && !isGetQRError && qrData) {
        setQr(qrData.qr);
        if (!timerSetStatusRef.current) {
          timerSetStatusRef.current = true;
          timeout = setTimeout(() => {
            timerSetStatusRef.current = false;
            getQr();
          }, QR_LIFE_TIME + 1000);
        }
      }

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }, [qr, qrData, getQr, setQr, isFetchingQR, isGetQRError]);

    // Закрывает открытое модальное окно с вопросом о очистке очереди и запрашивает QR код
    const closeModalHandler = useCallback(() => {
      setIsOpen(false);
    }, []);

    // Закрывает окно и запрашивает QR оставляет сообщения в очереди
    const getQwWithoutClearQuery = useCallback(() => {
      setIsOpen(false);
      getQr();
    }, [getQr]);

    // Функция открывает окно с предложением очистить очередь сообщений если есть сообщения в очереди
    const getQrHandler = useCallback(() => {
      if (messages.length) {
        setIsOpen(true);
      } else {
        getQr();
      }
    }, [getQr, messages.length]);

    // Закрывает модалку, очищает очередь сообщений и запрашивает QR
    const clearMessageQuery = useCallback(() => {
      setIsOpen(false);
      clearQuery();
      getQr();
    }, [clearQuery, getQr]);

    return (
      <div className={className}>
        <div className='flex flex-col'>
          <h3 className='font-medium text-[1.25rem] leading-8 tracking-[0.004em] mb-3'>
            {t('CONNECTION_PAGE_TEXT.botConnection.qrConnection')}
          </h3>
          <div>
            <ul className='pl-[1.5rem] text-balticSea leading-6 list-disc mt-[0.3rem] tracking-[0.0275em]'>
              <li>{t('CONNECTION_PAGE_TEXT.botConnection.ul.first')}</li>
              <li>{t('CONNECTION_PAGE_TEXT.botConnection.ul.second')}</li>
              <li>{t('CONNECTION_PAGE_TEXT.botConnection.ul.third')}</li>
              <li>{t('CONNECTION_PAGE_TEXT.botConnection.ul.fourth')}</li>
              <li>{t('CONNECTION_PAGE_TEXT.botConnection.ul.sixth')}</li>
              <li>{t('CONNECTION_PAGE_TEXT.botConnection.ul.seventh')}</li>
            </ul>
          </div>
          {!qr ? (
            <BotButton
              variant='qr'
              callBack={getQrHandler}
              isLoading={isFetchingQR}
              disabled={status === CONNECTION_STATUSES.LOADING}
              className='text-darkGray !bg-white mt-6 !min-w-[13rem] w-full sm:w-fit'
            />
          ) : null}
          {/* {!isCompleted ? (
            <span className='text-gray3 text-small_text leading-4 tracking-[0.033em] mt-1'>
              {`${minutes} : ${String(seconds).padStart(2, '0')}`}
            </span>
          ) : null} */}
          <ClearMessageQueryModal
            isOpen={isOpen}
            closeModalHandler={closeModalHandler}
            clearQueryHandler={clearMessageQuery}
            getQRWithoutClearQueryHandler={getQwWithoutClearQuery}
          />
        </div>
      </div>
    );
  },
);

QrConnection.displayName = 'QrConnection';
