import axios from 'axios';
import { RootState } from '@store/store';
import { templateApi } from '@api/templateApi';
import { myTrackerApi } from '@api/myTrackerApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TRejectResponseData } from '@models/index';
import { MY_TRACKER_EVENTS } from '@helpers/myTracker';
import { IInitialState } from '@redux/template/initialState';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { templateMappers } from '@redux/template/templateMappers';
import {
  TCopyTemplateData,
  TTemplatePageData,
  TEMPLATE_DATA_KEY_NAMES,
} from '@redux/template/models';
import {
  addMediaFile,
  clearTemplateState,
  setTemplatePageData,
} from '@redux/template/templateSlice';
import {
  TStaffListItem,
  REJECT_RESPONSE_KEY,
  T_STAFF_LIST_RESPONSE_KEYS,
  TEMPLATE_DATA_RESPONSE_KEY_NAMES,
} from '@api/types';

/**
 * Thnuk-экшен для загрузки медиа-файла.
 *
 * @param {string} url - URL для загрузки медиа-файла.
 * @rejects {number} - Число, представляющее статус ошибки в случае неудачной загрузки.
 */
const uploadMediaFile = createAsyncThunk<void, { url: string }, { rejectValue: number }>(
  'templateSlice/uploadMediaFile',
  (data, { dispatch, rejectWithValue }) => {
    if (data.url) {
      return axios
        .get(data.url, { responseType: 'arraybuffer' })
        .then(response => new Blob([response.data], { type: response.headers['content-type'] }))
        .then(imgBlob => {
          const fileName = data.url;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const file = new File([imgBlob], fileName, {
            type: imgBlob.type,
            lastModified: new Date().getTime(),
          });
          const container = new DataTransfer();
          container.items.add(file);
          dispatch(addMediaFile({ files: container.files, isUploadedMediaFile: true }));
        })
        .catch(error => rejectWithValue(error));
    }
  },
);

/**
 * Thunk-экшен для получения данных шаблона по идентификатору.
 *
 * @param {string} id - Идентификатор шаблона.
 * @returns {Promise<TTemplatePageData | void>} - Данные шаблона или  void в случае ошибки.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект, представляющий информацию об ошибке в случае неудачного запроса.
 */
const getTemplateByIdData = createAsyncThunk<
  TTemplatePageData | void,
  { id: string },
  { rejectValue: TRejectResponseData }
>('templateSlice/getTemplateByIdData', (data, { dispatch, rejectWithValue }) => {
  return templateApi
    .getTemplateById({ id: data.id })
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      if (TEMPLATE_DATA_RESPONSE_KEY_NAMES.CONFIRM_RECORD in response.data) {
        const templateData = templateMappers.mapDataToStore(response.data);
        if (response.data.file) {
          dispatch(uploadMediaFile({ url: response.data.file }));
        }
        return templateData;
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getTemplateByIdData(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшен для копирования шаблона в переданный список филиалов.
 *
 * @param {TCopyTemplateData} данные для копирования, список филиалов и id шаблона.
 * @returns {Promise<void>} - Данные шаблона или  void в случае ошибки.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект, представляющий информацию об ошибке в случае неудачного запроса.
 */
export const copyTemplateToBranchThunk = createAsyncThunk<
  number,
  TCopyTemplateData,
  { rejectValue: TRejectResponseData }
>('templateSlice/copyTemplateToBranchThunk', (data, { dispatch, rejectWithValue }) => {
  return templateApi
    .copyTemplateToBranch(data)
    .then(() => {
      return data.branchList.length;
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(copyTemplateToBranchThunk(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшен для сохранения данных шаблона.
 *
 * @param {File|null} file - Файл, связанный с шаблоном.
 * @param {TTemplatePageData} data - Данные шаблона.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект, представляющий информацию об ошибке в случае неудачного запроса.
 */
const saveTemplateData = createAsyncThunk<
  void,
  { file: File | null; data: TTemplatePageData },
  { state: RootState; rejectValue: TRejectResponseData }
>('templateSlice/saveTemplateData', (data, { getState, dispatch, rejectWithValue }) => {
  const { oldData } = getState().template;
  const newTemplateData = data.data;
  const formData = templateMappers.mapDataToSave(data);
  const saveData = { formData, templateData: newTemplateData };

  const {
    title,
    statusEnable,
    onlineRecord,
    blacklist,
    recordTag,
    services,
    categories,
    staff,
    clientCategory,
    whenSend,
    mediaFile,
    confirmRecord,
    textWhatsapp,
  } = newTemplateData;
  const currentStatus = statusEnable;
  const oldStatus = oldData.statusEnable;

  const newOnlineRecord = onlineRecord;
  const oldOnlineRecord = oldData.onlineRecord;

  const isSampleOn = currentStatus !== oldStatus ? currentStatus : false;
  const isSampleOff = currentStatus !== oldStatus ? !currentStatus : false;

  const isSampleOnlineOn = newOnlineRecord !== oldOnlineRecord ? newOnlineRecord : false;

  const isNumberAddedInBackList = blacklist.length > oldData.blacklist.length;

  const sample_condition = {
    attendance: formData.get('attendance'),
    [TEMPLATE_DATA_KEY_NAMES.RECORD_TAG]: recordTag,
    [TEMPLATE_DATA_KEY_NAMES.SERVICES]: services,
    [TEMPLATE_DATA_KEY_NAMES.CATEGORIES]: categories,
    [TEMPLATE_DATA_KEY_NAMES.STAFF]: staff,
    [TEMPLATE_DATA_KEY_NAMES.CLIENT_CATEGORY]: clientCategory,
    visits: formData.get('visits'),
    visits_frequency: formData.get('visits_frequency'),
  };

  return templateApi
    .saveTemplateEmTm(saveData)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (TEMPLATE_DATA_RESPONSE_KEY_NAMES.CONFIRM_RECORD in response.data) {
        const templateData = templateMappers.mapDataToStore(response.data);

        dispatch(setTemplatePageData({ data: templateData }));

        if (response.data.file) {
          dispatch(uploadMediaFile({ url: response.data.file }));
        }

        if (newTemplateData.id) {
          myTrackerApi.editTemplateEvent({
            sample_name: title,
            sample_on: isSampleOn,
            sample_off: isSampleOff,
            sample_trigger: whenSend,
            sample_text: textWhatsapp,
            sample_condition,
            sample_black: isNumberAddedInBackList,
            sample_online: isSampleOnlineOn,
          });
        } else {
          myTrackerApi.createTemplateEvent({
            user_id: '',
            event_name: MY_TRACKER_EVENTS.CREATE_NEW_TEMPLATE,
            event_properties: {
              sample_name: title,
              sample_trigger: whenSend,
              sample_condition,
              sample_media: !mediaFile,
              sample_black: !blacklist.length,
              sample_online: !onlineRecord,
              sample_confirm: confirmRecord,
              sample_text: textWhatsapp,
            },
          });
        }
      }
      setTimeout(() => dispatch(clearTemplateState()));
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        const taskData = getState().template as IInitialState;
        setTimeout(
          () => dispatch(saveTemplateData({ data: taskData.data, file: taskData.data.files })),
          100,
        );
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшен для получения списка категорий.
 *
 * @param {string} categoryName - Название категории.
 * @returns {{ data: TStaffListItem[]; categoryName: string } | void} - Объект, содержащим данные списка сотрудников и название категории, или void в случае неудачи.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект, представляющий информацию об ошибке в случае неудачного запроса.
 */
const getListOfCategoriesTS = createAsyncThunk<
  { data: TStaffListItem[]; categoryName: string } | void,
  { categoryName: string },
  { rejectValue: TRejectResponseData }
>('templateSlice/getListOfCategories', (data, { dispatch, rejectWithValue }) => {
  return templateApi
    .getCategoryList(data.categoryName)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (T_STAFF_LIST_RESPONSE_KEYS.COUNT in response.data) {
        return { data: response.data.data, categoryName: data.categoryName };
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getListOfCategoriesTS(data)), 100);
      }
      return rejectWithValue(error.response.data);
    });
});

export { uploadMediaFile, saveTemplateData, getTemplateByIdData, getListOfCategoriesTS };
