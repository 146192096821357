import { TAppLanguage, TSimpleStringObj, TSimpleStringObjLevel2 } from '@models/index';
import { PATH_NAMES } from '@data/dict';

export const REVIEW_PAGES_NAME_DICT: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    [PATH_NAMES.REVIEWS]: 'All chains',
    [PATH_NAMES.REVIEW_STATS]: 'Statistics',
  },
  rus: {
    [PATH_NAMES.REVIEWS]: 'Все цепочки',
    [PATH_NAMES.REVIEW_STATS]: 'Статистика',
  },
};

export const REVIEW_BUTTON_TEXT: TSimpleStringObj = {
  edit: 'Редактировать',
  deleteReview: 'Удалить цепочку',
  addStep: 'Добавить шаг',
  saveChain: 'Сохранить цепочку отзывов',
  preview: 'Показать превью',
};

export const REVIEW_BUTTON_TEXT_ENG: TSimpleStringObj = {
  edit: 'Edit',
  deleteReview: 'Delete chain',
  addStep: 'Add step',
  saveChain: 'Save the review chain',
  preview: 'Show preview',
};

export enum REVIEW_RECORD_STATUS {
  CLIENT_EXPECTATION = '0',
  CLIENT_CAME = '1',
  CLIENT_CONFIRMED = '2',
  CLIENT_DID_NOT_COME = '-1',
}

export enum WHEN_SEND_VALUE_REVIEWS {
  ALL_DAY_RECS_NOTIFICATION = 'all_day_recs_notifications',
  AFTER_END = 'after_end',
}

export const WHEN_SEND_SELECT_OPTION_REVIEWS: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    [WHEN_SEND_VALUE_REVIEWS.ALL_DAY_RECS_NOTIFICATION]:
      'To everyone whose recording was completed',
    [WHEN_SEND_VALUE_REVIEWS.AFTER_END]: 'After recording ends',
  },
  rus: {
    [WHEN_SEND_VALUE_REVIEWS.ALL_DAY_RECS_NOTIFICATION]: 'Всем у кого запись была окончена',
    [WHEN_SEND_VALUE_REVIEWS.AFTER_END]: 'По окончании записи',
  },
};

export const WHEN_SEND2_SELECT_OPTION_REVIEWS: Record<TAppLanguage, TSimpleStringObjLevel2> = {
  eng: {
    [WHEN_SEND_VALUE_REVIEWS.AFTER_END]: {
      '24.0': 'In 24 hours',
      '12.0': 'In 12 hours',
      '6.0': 'In 6 hours',
      '3.0': 'In 3 hours',
      '2.0': 'In 2 hours',
      '1.5': 'In 1.5 hours',
      '1.0': 'In 1 hour',
      '0.5': 'In 30 minutes',
      '0.25': 'In 15 minutes',
    },
    [WHEN_SEND_VALUE_REVIEWS.ALL_DAY_RECS_NOTIFICATION]: {
      '0': 'Today',
      '-1': 'Yesterday',
      '-2': 'The day before yesterday',
      '-3': '3 days ago',
      '-5': '5 days ago',
      '-7': 'A week ago',
    },
  },
  rus: {
    [WHEN_SEND_VALUE_REVIEWS.AFTER_END]: {
      '24.0': 'Через 24 часа',
      '12.0': 'Через 12 часов',
      '6.0': 'Через 6 часов',
      '3.0': 'Через 3 часа',
      '2.0': 'Через 2 часа',
      '1.5': 'Через 1,5 часа',
      '1.0': 'Через 1 час',
      '0.5': 'Через 30 минут',
      '0.25': 'Через 15 минут',
    },
    [WHEN_SEND_VALUE_REVIEWS.ALL_DAY_RECS_NOTIFICATION]: {
      '0': 'Сегодня',
      '-1': 'Вчера',
      '-2': 'Позавчера',
      '-3': '3 дня назад',
      '-5': '5 дней назад',
      '-7': 'Неделю назад',
    },
  },
};

export enum ALL_DAY_RECS_NOTIFICATION_VALUE {
  TODAY = '0',
}

export enum AFTER_END {
  IN_FIFTEEN_MINUTES = '0.25',
}

export const REVIEW_TOAST_MESSAGE = {
  SAVE_SUCCESS: 'Цепочка успешно сохранена',
  SEND_STATS_SUCCESS: 'Данные отправлены в Telegram',
};

export const REVIEW_TOAST_MESSAGE_ENG = {
  SAVE_SUCCESS: 'Chain saved successfully',
  SEND_STATS_SUCCESS: 'Data sent to Telegram',
};

export const REVIEW_MESSAGE = {
  SAVE_SUCCESS: 'REVIEW_TOAST_MESSAGE.SAVE_SUCCESS',
  SEND_STATS_SUCCESS: 'REVIEW_TOAST_MESSAGE.SEND_STATS_SUCCESS',
};

export const REVIEW_PAGE_TEXT = {
  pageWrapper: {
    header: 'Цепочка отзывов',
    newReviews: 'Новая цепочка',
  },
  chain: 'Цепочка {{id}}',
  REVIEW_BUTTON_TEXT,
  header: 'Цепочка отзывов #{{id}}',
  headerNewChain: 'Новая цепочка отзывов',
  toggleDescription: 'Действующая цепочка',
  chainName: 'Название цепочки',
  chainNamePlaceholder: 'Введите название',
  preview: 'Превью шага',
  warning:
    'Внимание! Шкалу оценивания в сообщение вставлять не нужно, она автоматически подставится при отправке',
  step: 'Шаг {{stepId}}',
  stepPostfix: '(последний)',
  requiredFields: 'Обязательные поля отмечены звёздочкой',
  content: 'Контент для отправки',
  sendCondition: 'Условия отправки',
  select: 'Выберите',
  send: 'Отправлять',
  clientPhoneList: 'Укажите номер телефона для отправки сообщений в WhatsApp',
  tgAlerts: 'Укажите telegram ID для отправки сообщений в Telegram',
  blackList: 'Введите номер телефона',
  statistics: {
    sortBy: 'Сортировать по',
    paginatorText: 'Показать строк:',
    nothingFound: 'Ничего не найдено',
    noData: 'Нет данных',
    showMore: 'Показать ещё',
    sendDetailInTG: 'Отправить детализацию отправок в Telegram',
  },
  statisticsCard: {
    tooltip: 'Посмотреть шаблон цепочки отзывов',
    reviewLink: 'Цепочка отзывов',
    sendTo: 'Отправить в',
    conversion: 'Конверсия',
    firstStepConversion: 'Ответов на первый шаг',
    fullConversion: 'Полное прохождение цепочки',
    common: 'Общее',
    totalSends: 'Кол-во отправок',
    negativeResults: 'Кол-во негативных результатов',
  },
};

export const REVIEW_PAGE_TEXT_ENG = {
  pageWrapper: {
    header: 'Review chain',
    newReviews: 'New chain',
  },
  chain: 'Chain {{id}}',
  REVIEW_BUTTON_TEXT: REVIEW_BUTTON_TEXT_ENG,
  header: 'Review chain #{{id}}',
  headerNewChain: 'New review chain',
  toggleDescription: 'Current chain',
  chainName: 'Chain name',
  chainNamePlaceholder: 'Enter name',
  preview: 'Step preview',
  warning:
    'Attention! There is no need to insert the rating scale into the message, it will be automatically inserted when sending',
  step: 'Step {{stepId}}',
  stepPostfix: '(last)',
  requiredFields: 'Required fields are marked with an asterisk',
  content: 'Content to send',
  sendCondition: 'Sending conditions',
  select: 'Select',
  send: 'Send',
  clientPhoneList: 'Specify the phone number to send messages to WhatsApp',
  tgAlerts: 'Specify the telegram ID to send messages to Telegram',
  blackList: 'Enter the phone number',
  statistics: {
    sortBy: 'Sort by',
    paginatorText: 'Show rows:',
    nothingFound: 'Nothing found',
    noData: 'No data',
    showMore: 'Show more',
    sendDetailInTG: 'Send details of sendings to Telegram',
  },
  statisticsCard: {
    tooltip: 'View the chain template reviews',
    reviewLink: 'Review chain',
    sendTo: 'Send to',
    conversion: 'Conversion',
    firstStepConversion: 'Responses to first step',
    fullConversion: 'Full chain completion',
    common: 'Total',
    totalSends: 'Number of sends',
    negativeResults: 'Number of negative results',
  },
};

export const REVIEWS_STATS_SORT_DROPDOWN_VALUES = {
  TOTAL_SENDS: 'total_sends',
  FIRST_STEP_CONVERSION: 'first_step_conversion',
  NEGATIVE_RESULTS: 'negative_results',
};

export const REVIEWS_STATS_SORT_DROPDOWN: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    [REVIEWS_STATS_SORT_DROPDOWN_VALUES.TOTAL_SENDS]: 'Number of sends',
    [REVIEWS_STATS_SORT_DROPDOWN_VALUES.FIRST_STEP_CONVERSION]:
      'Number of responses to the first step',
    [REVIEWS_STATS_SORT_DROPDOWN_VALUES.NEGATIVE_RESULTS]: 'Number of negative results',
  },
  rus: {
    [REVIEWS_STATS_SORT_DROPDOWN_VALUES.TOTAL_SENDS]: 'Количеству отправок',
    [REVIEWS_STATS_SORT_DROPDOWN_VALUES.FIRST_STEP_CONVERSION]: 'Количеству ответов на первый шаг',
    [REVIEWS_STATS_SORT_DROPDOWN_VALUES.NEGATIVE_RESULTS]: 'Количеству негативных результатов',
  },
};
