import React, { memo, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Dialog } from '@uikit/Dialog';
import { TKeyData } from '@redux/template/models';
import { TReviewData } from '@redux/reviews/models';
import { ReviewsStep5 } from '@components/reviewsStep5';
import { ReviewsStep1 } from '@components/reviewsStep1';
import { ReviewStep24 } from '@components/reviewStep2_4';
import { PreviewComponent } from '@components/previewComponent';
import { TSimpleStringObj, TAppLanguage, TSimpleStringObjLevel2 } from '@models/index';

interface IReviewsChainProps {
  /**
   *  Данные цепочки
   *  @param {TReviewData}
   */
  data: TReviewData;
  /**
   * Параметр используется для добавления нового шарика в блок staff
   * @param {string}
   */
  staffNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок services
   * @param {string}
   */
  servicesNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок blacklist
   * @param {string}
   */
  blacklistNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок categories
   * @param {string}
   */
  categoriesNewValue: string;
  /**
   * Флаг состояния запроса
   * @param {boolean}
   */
  isFetchingSuccess: boolean;
  /**
   * ID выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Имя textarea для добавления ключей или эмодзи
   * @param {string}
   */
  blurFromTextareaName: string;
  /**
   * Параметр используется для добавления нового шарика в блок recordTag
   * @param {string}
   */
  recordTagNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок clientCategory
   * @param {string}
   */
  clientCategoryNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок channelsPhoneList
   * @param {string}
   */
  channelsPhoneListNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок tgAlerts
   * @param {string}
   */
  tgAlertsNewValue: string;
  /**
   * Callback для удаления шага
   * @param stepId
   */
  deleteStep: (stepId: number) => () => void;
  /**
   * Данные для отображения выпадающих списков в dropdown
   * @param {{ [key: string]: TSimpleStringObj }}
   */
  categoriesDataList: { [key: string]: TSimpleStringObj };
  /**
   * Словарь ключей для рендеренга HTML
   * @param {TSimpleStringObj}
   */
  specialKeyDict: TSimpleStringObj;
  /**
   * ref на contenteditable div для передачи в него новых данных
   * @param {React.MutableRefObject<(EventTarget & HTMLDivElement)>}
   */
  div: React.MutableRefObject<(EventTarget & HTMLDivElement) | null>;
  /**
   * Данные о расположении спец ключей
   * @param {TKeyData[]}
   */
  textareaKeysData: TKeyData[];
  /**
   * Объект с данными для формирования опций в выпадающем списке
   * @param {Record<TAppLanguage, TSimpleStringObj>}
   */
  whenSendSelectOption: Record<TAppLanguage, TSimpleStringObj>;
  /**
   * Объект с данными для формирования опций в выпадающем списке
   * @param {Record<TAppLanguage, TSimpleStringObjLevel2>}
   */
  whenSend2SelectOption: Record<TAppLanguage, TSimpleStringObjLevel2>;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const ReviewsChain = memo(
  ({
    div,
    data,
    deleteStep,
    staffNewValue,
    specialKeyDict,
    textareaKeysData,
    servicesNewValue,
    tgAlertsNewValue,
    blacklistNewValue,
    recordTagNewValue,
    isFetchingSuccess,
    categoriesDataList,
    categoriesNewValue,
    selectedFilialAccId,
    blurFromTextareaName,
    whenSendSelectOption,
    whenSend2SelectOption,
    clientCategoryNewValue,
    channelsPhoneListNewValue,
    className = '',
  }: IReviewsChainProps) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [previewText, setPreviewText] = useState<string>('');

    const switchModalHandler = useCallback(() => {
      setIsModalOpen(prevState => !prevState);
    }, []);

    // Показывает модалку с превью текста шаблона
    const showPreviewHandler = useCallback(
      (text: string) => () => {
        if (!text) return;
        setPreviewText(text);
        switchModalHandler();
      },
      [switchModalHandler],
    );

    // Подготавливает шаги отзывов для рендера
    const renderSteps = () => {
      return (
        <>
          <ReviewsStep1
            chainId={data.id}
            stepId={0}
            div={div}
            staffNewValue={staffNewValue}
            data={data.templates.firstStep}
            specialKeyDict={specialKeyDict}
            textareaKeysData={textareaKeysData}
            tgAlertsNewValue={tgAlertsNewValue}
            servicesNewValue={servicesNewValue}
            recordTagNewValue={recordTagNewValue}
            isFetchingSuccess={isFetchingSuccess}
            blacklistNewValue={blacklistNewValue}
            showPreviewHandler={showPreviewHandler}
            categoriesNewValue={categoriesNewValue}
            categoriesDataList={categoriesDataList}
            selectedFilialAccId={selectedFilialAccId}
            whenSendSelectOption={whenSendSelectOption}
            blurFromTextareaName={blurFromTextareaName}
            whenSend2SelectOption={whenSend2SelectOption}
            clientCategoryNewValue={clientCategoryNewValue}
            channelsPhoneListNewValue={channelsPhoneListNewValue}
            className='mb-10'
          />
          {data.templates.secondStep.map((step, index) => {
            const key = `${step}-${index}`;

            return (
              <ReviewStep24
                key={key}
                div={div}
                data={step}
                chainId={data.id}
                stepId={index + 1}
                specialKeyDict={specialKeyDict}
                deleteStep={deleteStep(step.id)}
                textareaKeysData={textareaKeysData}
                showPreviewHandler={showPreviewHandler}
                blurFromTextareaName={blurFromTextareaName}
                className='mb-10'
              />
            );
          })}
          <ReviewsStep5
            div={div}
            chainId={data.id}
            data={data.templates.lastStep}
            specialKeyDict={specialKeyDict}
            textareaKeysData={textareaKeysData}
            showPreviewHandler={showPreviewHandler}
            blurFromTextareaName={blurFromTextareaName}
            stepId={data.templates.secondStep.length + 1}
          />
        </>
      );
    };

    const memoizedDialog = useMemo(
      () => (
        <Dialog
          type='default'
          view='raised'
          color='default'
          isOpen={isModalOpen}
          backdropOpacity={40}
          onClose={switchModalHandler}
          title={t('REVIEW_PAGE_TEXT.REVIEW_BUTTON_TEXT.preview')}
          text={isModalOpen ? <PreviewComponent description={previewText} /> : <span />}
          className='whitespace-pre-line break-words report-preview'
        />
      ),
      [t, isModalOpen, switchModalHandler, previewText],
    );

    return (
      <div className={`flex flex-col ${className}`}>
        {memoizedDialog}
        <div className='flex flex-col'>{renderSteps()}</div>
      </div>
    );
  },
);

ReviewsChain.displayName = 'ReviewsChain';
