import React, { memo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
//
import { NoAccess } from '@components/noAccess';
import { QUERY_PARAMS } from '@const/apiConstants';
import { ErrorMessage } from '@blocks/errorMessage';
import { ROUTES_ACCESS_RIGHTS } from '@const/access';
import { TemplateComponent } from '@templates/template';
import { getAccountsData } from '@redux/accounts/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getTemplatePageData } from '@redux/template/selectors';
import { emptyTemplateMockData } from '@redux/template/mockData';
import { getTemplateByIdData } from '@redux/template/templateThunks';
import { clearFiles, setTemplatePageData } from '@redux/template/templateSlice';

const TemplatePage = memo(() => {
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const id = searchParams.get(QUERY_PARAMS.ID);

  const { selectedFilial } = useAppSelector(getAccountsData);
  const { data, isError, isLoading, isFetching, isAccessDenied, isSuccess } =
    useAppSelector(getTemplatePageData);

  // Очищает значения в сторе для корректного отображения нового шаблона
  useEffect(() => {
    return () => {
      dispatch(clearFiles());
      dispatch(setTemplatePageData({ data: emptyTemplateMockData }));
    };
  }, [dispatch]);

  // Получаем массив прав если он есть и проверяем наличие нобходимых прав
  const rights = selectedFilial?.rights;
  const isEditTemplatesAccess =
    rights?.includes(ROUTES_ACCESS_RIGHTS.EDIT_TEMPLATES) ||
    rights?.includes(ROUTES_ACCESS_RIGHTS.OWNER) ||
    false;

  // Делает запрос за данными шаблона при загрузке страницы
  useEffect(() => {
    if (selectedFilial?.accId && id) {
      dispatch(getTemplateByIdData({ id }));
    }
  }, [dispatch, id, selectedFilial?.accId]);

  // Обновляет данные шаблона если isFetching true
  useEffect(() => {
    if (isFetching) {
      dispatch(getTemplateByIdData({ id: id || '' }));
    }
  }, [dispatch, id, isFetching]);

  return (
    <>
      {isAccessDenied && !isError && !isLoading ? <NoAccess /> : null}
      {isError && !isLoading && !isAccessDenied ? <ErrorMessage /> : null}
      {isSuccess && !isError && !isLoading && !isAccessDenied ? (
        <TemplateComponent
          data={data}
          isLoading={isLoading}
          isEditTemplatesAccess={isEditTemplatesAccess}
          selectedFilialAccId={selectedFilial?.accId || ''}
          className='px-4 sm:px-2 lg:px-0'
        />
      ) : null}
    </>
  );
});

export default TemplatePage;
