export interface IInitialState {
  leadId: string;
  isError: boolean;
  branchId: string;
  formHash: string;
  isLoading: boolean;
  yclientsName: string;
  errorMessage: string;
  yclientsPhone: string;
  showNameError: boolean;
  showPhoneError: boolean;
  isSendAmplitudeEvent: boolean;
  isAmplitudeEventSend: boolean;
  isShowSuccessMessage: boolean;
  isPlaceToContactPhone: boolean;
  linkToAutoConnectionForm: string;
  isPlaceToContactWhatsApp: boolean;
}

export const initialState: IInitialState = {
  leadId: '',
  branchId: '',
  formHash: '',
  isError: false,
  isLoading: false,
  yclientsName: '',
  errorMessage: '',
  yclientsPhone: '',
  showNameError: false,
  showPhoneError: false,
  isSendAmplitudeEvent: false,
  isAmplitudeEventSend: false,
  isShowSuccessMessage: false,
  isPlaceToContactPhone: false,
  isPlaceToContactWhatsApp: true,
  linkToAutoConnectionForm: '',
};
