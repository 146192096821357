import React, { memo } from 'react';
import { DndContainer } from '@components/dndContainer';
import { EventTemplateListTitle } from '@blocks/eventTemplateListTitle';

type TEventTemplateListWrapper = {
  /**
   * Текст в заголовок
   * @param {string}
   */
  text: string;
  /**
   * Флаг открытия/закрытия списка
   * @param {string}
   */
  isOpen: boolean;
  /**
   * Callback для открытия/закрытия списка шаблонов
   * @param {() => void}
   */
  onClickCallback: () => void;
  /**
   * Массив с Id для формирования списка DND
   * @param {number[]}
   */
  templateOrder: number[];
  /**
   * После окончания перетаскивания запускается callback,
   * в него передается массив id в новом порядке
   * @param {(itemsId: number[]) => void}
   */
  onDragEndCallback: (itemsId: number[]) => void;
  /**
   * Массив с карточками шаблонов
   * @param {React.ReactNode}
   */
  cartList: React.ReactNode;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const EventTemplateListWrapper = memo(
  ({
    text,
    isOpen,
    cartList,
    templateOrder,
    onClickCallback,
    onDragEndCallback,
    className = '',
  }: TEventTemplateListWrapper) => {
    return (
      <div className={className}>
        <EventTemplateListTitle
          text={text}
          isOpen={isOpen}
          onClickCallback={onClickCallback}
          className='mb-8'
        />
        <div
          className={`grid grid-cols-3temp xxl:grid-cols-3tempXXL gap-[1.5rem] pb-2 ${
            isOpen ? 'max-h-auto' : 'max-h-0'
          } overflow-hidden`}>
          <DndContainer itemsId={templateOrder} onDragEndCallback={onDragEndCallback}>
            {cartList}
          </DndContainer>
        </div>
      </div>
    );
  },
);

EventTemplateListWrapper.displayName = 'EventTemplateListWrapper';
