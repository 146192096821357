import React, { memo, useCallback, useState } from 'react';
import { Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { TQRCodeData } from '@models/index';
import whatsApp from '@img/connect/whatsApp.svg';
import { QrConnection } from '@blocks/qrConnection';
import { ChannelHeader } from '@blocks/channelHeader';
import { WaPhoneConnection } from '@blocks/waPhoneConnection';
import { ReactComponent as QrIcon } from '@img/connect/qr.svg';
import { TConnectionWAStatusData } from '@redux/connection/types';
import { PHONE_CONNECTION_STEP, TPhoneConnectionStatus } from '@redux/connection/initialState';

type TConnectionType = 'qr' | 'phone' | 'notSelected';

type TConnectionGetQrProps = {
  /**
   * Callback для получения QR кода
   * @param {() => void}
   */
  getQr: () => void;
  /**
   * Флаг процесса получения QR кода
   * @param {boolean}
   */
  isFetchingQR: boolean;
  /**
   * Флаг ошибки получения QR кода
   * @param {boolean}
   */
  isGetQRError: boolean;
  /**
   * Callback для очистки очереди сообщений
   * @param {() => void}
   */
  clearQuery: () => void;
  /**
   * Данные QR кода
   * @param {TQRCodeData | undefined}
   */
  qrData: TQRCodeData | undefined;
  /**
   * Статус подключения по телефону
   * @param {TPhoneConnectionStatus}
   */
  phoneConnectionStatus: TPhoneConnectionStatus;
  /**
   * Шаг формы подключения по телефону
   * @param {PHONE_CONNECTION_STEP}
   */
  phoneConnectionStep: PHONE_CONNECTION_STEP;
  /**
   * Номер телефона для отправки кода
   * @param {string}
   */
  phoneNumber: string;
  /**
   * Уникальный код для WA
   * @param {string}
   */
  uniqueCode: string;
  /**
   * Запрашивает данные по подключению каналов
   * @param {() => void}
   */
  getConnectionStatusCallback: () => void;
  /**
   * Данные страницы подключения
   * @param {TConnectionWAStatusData}
   */
  data: TConnectionWAStatusData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const ConnectionWA = memo(
  ({
    getQr,
    qrData,
    clearQuery,
    uniqueCode,
    phoneNumber,
    isFetchingQR,
    isGetQRError,
    phoneConnectionStep,
    phoneConnectionStatus,
    getConnectionStatusCallback,
    data,
    className = '',
  }: TConnectionGetQrProps) => {
    const { t } = useTranslation();

    const [showGetStatusCallout, setShowGetStatusCallout] = useState(false);

    const [connectionType, setConnectionType] = useState<TConnectionType>('phone');

    const setConnectionTypeHandler = useCallback(
      (type: TConnectionType) => () => {
        setConnectionType(type);
      },
      [],
    );

    const showGetStatusCalloutHandler = useCallback(() => {
      setShowGetStatusCallout(true);
    }, []);

    return (
      <div className={className}>
        <div>
          <div className='flex flex-col sm:flex-row'>
            <div className=''>
              <ChannelHeader
                iconSrc={whatsApp}
                headerText={t('CONNECTION_PAGE_TEXT.botConnection.header')}
                subheaderText={t('CONNECTION_PAGE_TEXT.botConnection.subHeader')}
                className='mb-6'
              />
              <div
                className={`flex flex-wrap items-center gap-[0.5rem] ${
                  connectionType !== 'notSelected' ? 'mb-6' : ''
                } w-fit`}>
                <Button
                  dense
                  type='action'
                  view='filled'
                  color='success'
                  onClick={setConnectionTypeHandler('phone')}
                  // color={connectionType === 'phone' ? 'success' : 'default'}
                  text={t('CONNECTION_PAGE_TEXT.botConnection.phoneConnectionButton')}
                  className='min-w-[7.5rem]'
                />
                <span className='text-darkGray leadi ng-6 tracking-[0.022em]'>
                  {t('CONNECTION_PAGE_TEXT.botConnection.or')}
                </span>
                <Button
                  dense
                  type='action'
                  view='outlined'
                  onClick={setConnectionTypeHandler('qr')}
                  color={connectionType === 'qr' ? 'success' : 'default'}
                  icon={<QrIcon fill='currentColor' className='h-4 w-4' />}
                  text={t('CONNECTION_PAGE_TEXT.botConnection.qrConnection')}
                  className='!bg-white'
                />
              </div>
              {connectionType === 'qr' ? (
                <QrConnection
                  getQr={getQr}
                  qrData={qrData}
                  clearQuery={clearQuery}
                  isGetQRError={isGetQRError}
                  isFetchingQR={isFetchingQR}
                  showGetStatusCalloutHandler={showGetStatusCalloutHandler}
                  data={data}
                />
              ) : null}
              {connectionType === 'phone' ? (
                <WaPhoneConnection
                  uniqueCode={uniqueCode}
                  phoneNumber={phoneNumber}
                  phoneConnectionStep={phoneConnectionStep}
                  phoneConnectionStatus={phoneConnectionStatus}
                  showGetStatusCalloutHandler={showGetStatusCalloutHandler}
                />
              ) : null}
            </div>
            {connectionType === 'qr' && qrData?.qr ? (
              <div className='flex items-end justify-center sm:ml-[10vw] mt-6'>
                <img
                  alt='qr'
                  src={qrData?.qr}
                  className='h-auto min-w-[14.5rem]'
                  height={232}
                  width={232}
                />
              </div>
            ) : null}
          </div>
          {showGetStatusCallout ? (
            <div className='bg-seashell rounded-lg flex flex-col sm:flex-row justify-between py-6 px-3 mt-6 max-w-[49.6875rem] w-full'>
              <div className='flex items-center mb-2 sm:mb-0'>
                <Icon icon='info-sign' color='#FB982E' size={20} className='mr-2' />
                <span className='text-balticSea leading-6 tracking-[0.0275em]'>
                  {t('CONNECTION_PAGE_TEXT.botConnection.waStatusCallout')}
                </span>
              </div>
              <Button
                dense
                type='action'
                view='outlined'
                color='default'
                onClick={getConnectionStatusCallback}
                text={t('CONNECTION_PAGE_TEXT.botConnection.reloadButton')}
                className='!bg-white w-full sm:w-fit'
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  },
);

ConnectionWA.displayName = 'ConnectionGetQr';
