import { getEUDateFromString } from '@helpers/index';
import { PARTNER_FULL_TABLE_FIRST_PAY_DATE_VALUE } from '@const/partners';
import { TGetConsultantFullTableData, TGetFullTableResponse } from '@api/types';
import { TConsultantFullTableStoreData } from '@redux/consultantFullTable/models';

type TMapGetConsultantFullTableDataToStore = (
  data: TGetFullTableResponse<TGetConsultantFullTableData>,
) => TConsultantFullTableStoreData;

/**
 * Преобразует данные в формат, подходящий для использования в хранилище.
 *
 * @param {Object} param - Объект с параметрами.
 * @param {string} param.promo_url - URL промо.
 * @param {string} param.promo_code - Код промо.
 * @param {Array} param.data - Данные, которые требуется преобразовать.
 * @return {Object} - Преобразованные данные.
 */
const mapGetConsultantFullTableDataToStore: TMapGetConsultantFullTableDataToStore = ({
  qr_code,
  promo_url,
  promo_code,
  data,
}) => ({
  qrCode: qr_code,
  promoUrl: promo_url,
  promoCode: promo_code,
  data: data.map(({ branch, branch_name, first_payment_date, paid_till }) => ({
    accId: branch,
    filialName: branch_name,
    firstPayDate: first_payment_date,
    payTillDate:
      paid_till && paid_till !== PARTNER_FULL_TABLE_FIRST_PAY_DATE_VALUE.NOT_PAID
        ? `до ${paid_till}`
        : PARTNER_FULL_TABLE_FIRST_PAY_DATE_VALUE.NOT_PAID,
    lastPayDate:
      first_payment_date && first_payment_date !== PARTNER_FULL_TABLE_FIRST_PAY_DATE_VALUE.NOT_PAID
        ? getEUDateFromString(first_payment_date)
        : PARTNER_FULL_TABLE_FIRST_PAY_DATE_VALUE.NOT_PAID,
  })),
});

export { mapGetConsultantFullTableDataToStore };
