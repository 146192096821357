import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Icon } from '@blueprintjs/core';
//
import {
  MAILING_IMPORTANT_INFORMATION_LINK2,
  MAILING_IMPORTANT_INFORMATION_LINK3,
} from '@const/mailing';

type TMailingSecondStepWarningProps = {
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const MailingSecondStepWarning = memo(
  ({ className = '' }: TMailingSecondStepWarningProps) => {
    const { t } = useTranslation();

    const linksData = [
      {
        href: MAILING_IMPORTANT_INFORMATION_LINK2.href,
        text: t('MAILING_PAGE_TEXT.mailingFirstStepWarning.instruction'),
      },
      {
        href: MAILING_IMPORTANT_INFORMATION_LINK3.href,
        text: t('MAILING_PAGE_TEXT.mailingFirstStepWarning.examples'),
      },
    ];

    const linksToRender = linksData.map(({ href, text }) => (
      <a
        key={href}
        href={href}
        target='_blank'
        rel='noreferrer noopener'
        className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600'>
        {text}
      </a>
    ));

    const descriptionData = [
      {
        description: t('MAILING_PAGE_TEXT.mailingFirstStepWarning.description'),
      },
      {
        description: t('MAILING_PAGE_TEXT.mailingFirstStepWarning.description2'),
      },
    ];

    const descriptionToRender = descriptionData.map((item, index) => {
      const isLastElement = index === descriptionData.length - 1;

      return (
        <div key={item.description} className={`flex ${isLastElement ? '' : 'mb-2'}`}>
          <div className='flex items-start mr-2'>
            <Icon icon='info-sign' color='#FB982E' size={20} className='p-1' />
          </div>
          <p className='text-black leading-6 tracking-[0.0275em]'>{item.description}</p>
        </div>
      );
    });

    return (
      <div className={className}>
        <p className='text-black leading-6 tracking-[0.0275em] mb-4 hidden sm:block lg:hidden'>
          {t('MAILING_PAGE_TEXT.mailingFirstStepWarning.title')}
          {linksToRender}
        </p>
        <h2 className='text-red2 font-bold text-h6Mobile tracking-[0.0275em] leading-8 mb-2'>
          {t('MAILING_PAGE_TEXT.mailingFirstStepWarning.waiting')}
        </h2>
        {descriptionToRender}
      </div>
    );
  },
);

MailingSecondStepWarning.displayName = 'MailingFirstStepWarning';
