import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { Dialog } from '@uikit/Dialog';
import { createPortal } from 'react-dom';
import { Overlay } from '@atoms/overlay';
import { useAppDispatch } from '@store/store';
import { TSimpleStringObj } from '@models/index';
import { TextAreaWithLabel } from '@blocks/textAreaWithLabel';
import { TOnChangeHandlerInputTextarea } from '@shared/types';
import { ReactComponent as StarIcon } from '@img/inviteFriend/star.svg';
import { addFastReview } from '@redux/inviteFriendList/inviteFriendListSlice';
import { ReactComponent as TelegramIcon } from '@img/inviteFriend/telegram.svg';

type TInviteFriendsFeedbackProps = {
  /**
   * Выбранный вид быстрого отзыва
   * @param {string}
   */
  fastReview: string;
  /**
   * Флаг отправки отзыва
   * @param {boolean}
   */
  isFeedbackSending: boolean;
  /**
   * Текст отзыва
   * @param {string}
   */
  feedbackText: string;
  /**
   * Обработчик изменений textarea
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandlerInputTextarea;
  /**
   * Callback для отправки отзыва на бэк
   * @param {(rate: number, showSuccessModalCallback: () => void) => void}
   */
  sendFeedback: (rate: number, showSuccessModalCallback: () => void) => void;
  /**
   * Объект с данными для формирования кнопок с бстрыми ответами
   * @param {TSimpleStringObj}
   */
  fastFeedbackButtons: TSimpleStringObj;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const InviteFriendsFeedback = memo(
  ({
    fastReview,
    feedbackText,
    sendFeedback,
    onChangeHandler,
    isFeedbackSending,
    fastFeedbackButtons,
    className = '',
  }: TInviteFriendsFeedbackProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [starRating, setStarRating] = useState(0);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const addFastFeedback = useCallback(
      (feedback: string) => () => {
        dispatch(addFastReview(feedback));
      },
      [dispatch],
    );

    const resetStarRating = useCallback(() => {
      if (!isOpen) {
        setStarRating(0);
      }
    }, [isOpen]);

    const setStarRatingHandler = useCallback(
      (rating: number) => () => {
        if (!isOpen) {
          setStarRating(rating);
        }
      },
      [isOpen],
    );

    const showFeedbackModalAndSetStarRating = useCallback(
      (rating: number) => () => {
        if (!isOpen) {
          setIsOpen(true);
        } else {
          setStarRating(rating);
        }
      },
      [isOpen],
    );

    const showSuccessModalHandler = useCallback(() => {
      setStarRating(0);
      setIsOpen(false);
      setShowSuccessModal(true);
    }, []);

    const hideFeedBackModal = useCallback(() => {
      setIsOpen(false);
      setStarRating(0);
    }, []);

    const hideSuccessModalHandler = useCallback(() => {
      setShowSuccessModal(false);
    }, []);

    const sendFeedbackHandler = useCallback(
      (rating: number, callback: () => void) => () => {
        sendFeedback(rating, callback);
      },
      [sendFeedback],
    );

    const starsToRender = [1, 2, 3, 4, 5].map(star => {
      const fill = starRating >= star ? '#F5BF03' : 'white';
      const stroke = starRating > star ? '#F5BF03' : '#87C289';

      return (
        <StarIcon
          key={star}
          fill={fill}
          stroke={stroke}
          onMouseOver={setStarRatingHandler(star)}
          onClick={showFeedbackModalAndSetStarRating(star)}
          className='cursor-pointer mr-2.5'
        />
      );
    });

    const fastFeedbackButtonsToRender = Object.entries(fastFeedbackButtons).map(([key, text]) => {
      const isActive = key === fastReview;

      return (
        <button
          key={key}
          onClick={addFastFeedback(isActive ? '' : key)}
          className={`border ${
            isActive ? 'bg-green3 border-btnGreen' : 'bg-white border-lightGray'
          } border-lightGray cursor-pointer rounded-full`}>
          <span className='text-darkGray text-small_text leading-4 tracking-[0.033em] px-3 py-1 whitespace-nowrap'>
            {text}
          </span>
        </button>
      );
    });

    const isSendButtonDisabled = !feedbackText;

    return (
      <div className={`${isOpen ? 'z-[52]' : ''} ${className}`}>
        <div className='bg-lightGreenOp20 rounded-lg py-4 px-4'>
          <h3 className='font-medium text-black leading-8 tracking-[0.022em] mb-4'>
            {t('INVITE_FRIEND_PAGE_TEXT.feedback.header')}
          </h3>
          <div onMouseLeave={resetStarRating} className='flex items-center py-2.5'>
            <div className='flex items-center mr-2'>{starsToRender}</div>
            <span>{t('INVITE_FRIEND_PAGE_TEXT.feedback.rating', { rating: starRating })}</span>
          </div>
          {isOpen ? (
            <div className='flex flex-col'>
              <span className='font-medium text-black leading-6 tracking-[0.022em] mb-4'>
                {t('INVITE_FRIEND_PAGE_TEXT.feedback.leaveAComment')}
              </span>
              <div className='relative'>
                <TextAreaWithLabel
                  isHideLabel
                  htmlFor='text'
                  minHeight={188}
                  value={feedbackText}
                  textareaClassName='pb-[5rem]'
                  onChangeHandler={onChangeHandler}
                  placeholder={t('INVITE_FRIEND_PAGE_TEXT.feedback.placeholder')}
                  className='mb-4 !min-h-fit'
                />
                <div className='absolute bottom-6 left-0 pb-2 px-3 flex sm:flex-wrap gap-[0.375rem] overflow-x-scroll sm:overflow-auto w-full sm:w-auto'>
                  {fastFeedbackButtonsToRender}
                </div>
              </div>
              <Button
                fill
                dense
                type='action'
                view='filled'
                color='success'
                loading={isFeedbackSending}
                disabled={isSendButtonDisabled}
                text={t('INVITE_FRIEND_PAGE_TEXT.feedback.buttonText')}
                onClick={sendFeedbackHandler(starRating, showSuccessModalHandler)}
                style={{
                  background: isSendButtonDisabled ? 'white' : '',
                }}
                icon={
                  <TelegramIcon
                    style={{
                      transitionProperty: 'fill',
                      transitionDuration: '300ms',
                      transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                    }}
                    fill={isSendButtonDisabled ? '#87C289' : 'white'}
                  />
                }
                className='disabled:bg-white'
              />
            </div>
          ) : null}
          {showSuccessModal ? (
            <Dialog
              type='default'
              view='raised'
              color='default'
              title={t('INVITE_FRIEND_PAGE_TEXT.feedback.successModalTitle')}
              backdropOpacity={40}
              rightButton={
                <Button
                  dense
                  type='action'
                  view='filled'
                  color='success'
                  onClick={hideSuccessModalHandler}
                  text={t('INVITE_FRIEND_PAGE_TEXT.feedback.successModalButton')}
                />
              }
              isOpen={showSuccessModal}
              onClose={hideSuccessModalHandler}
            />
          ) : null}
        </div>
        {isOpen || showSuccessModal
          ? createPortal(
              <Overlay
                onClickCallback={showSuccessModal ? hideSuccessModalHandler : hideFeedBackModal}
                className='bg-[#2B2B2BCC] z-[51]'
              />,
              document.body,
            )
          : null}
      </div>
    );
  },
);

InviteFriendsFeedback.displayName = 'InviteFriendsFeedback';
