import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

type TOptionToPayDataProps = {
  /**
   * Название опции
   * @param {string}
   */
  optionName: string;
  /**
   * Описание опции
   * @param {string}
   */
  optionDescription: string;
  /**
   * Цена опции
   * @param {string}
   */
  price: string;
  /**
   * Toggle для выбора опций
   * @param {() => void}
   */
  selectModuleHandler?: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const OptionToPayData = memo(
  ({
    price,
    optionName,
    optionDescription,
    selectModuleHandler,
    className = '',
  }: TOptionToPayDataProps) => {
    const { t } = useTranslation();

    const textStyle = 'text-tooltips leading-5 tracking-[0.018em]';

    return (
      <div className={className}>
        <div className='flex items-start justify-between w-full'>
          <div>
            <p className='font-medium text-blackText leading-6 tracking-[0.022em]'>{optionName}</p>
            <p className={`${textStyle} text-textareaBlack`}>{optionDescription}</p>
          </div>
          <div className='flex flex-col'>
            <span className={`font-medium ${textStyle} text-black text-end`}>{price}</span>
            {selectModuleHandler ? (
              <span
                onClick={selectModuleHandler}
                className={`${textStyle} text-textareaBlack cursor-pointer hover:underline text-end`}>
                {t('PAY_PAGE_TEXT.delete')}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    );
  },
);

OptionToPayData.displayName = 'OptionToPayData';
