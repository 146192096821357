import React, { memo, useCallback } from 'react';
//
import { THelp } from '@models/index';
import { TEMPLATE_EVENT_KEY_NAME } from '@const/templates';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getTemplatePageData } from '@redux/template/selectors';
import { InputWithDropdownFromEP } from '@blocks/inputWithDropdownFromEP';
import { addBalloonTP, deleteBalloonTP } from '@redux/template/templateSlice';
import { TEMPLATE_DATA_KEY_NAMES, TTemplatePageData } from '@redux/template/models';
import { TAddBalloonOnBlurHandler, TBalloonsKeyNames, TDeleteBalloonSetTP } from '@shared/types';

type TTemplateInputWithDropdownListProps = {
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Флаг успешного завершения получения данных
   * @param {boolean}
   */
  isFetchingSuccess: boolean;
  /**
   * Данные шаблона
   * @param {TTemplatePageData}
   */
  data: TTemplatePageData;
  /**
   * Флаг отображения блока в шаблоне на ДР
   * @param {boolean}
   */
  isBirthday: boolean;
  /**
   * Опциональны параметр доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const TemplateInputWithDropdownList = memo(
  ({
    isBirthday,
    isFetchingSuccess,
    selectedFilialAccId,
    isEditTemplatesAccess = false,
    data,
    className = '',
  }: TTemplateInputWithDropdownListProps) => {
    const dispatch = useAppDispatch();

    const { staff, services, whenSend2, recordTag, categories, clientCategory } = data;

    const {
      staffNewValue,
      servicesNewValue,
      categoriesNewValue,
      categoriesDataList,
      clientCategoryNewValue,
    } = useAppSelector(getTemplatePageData);

    const inputWithDropdownData = [
      {
        addedValues: recordTag,
        newValue: servicesNewValue,
        inputId: TEMPLATE_DATA_KEY_NAMES.RECORD_TAG,
        labelTextVariant: TEMPLATE_DATA_KEY_NAMES.RECORD_TAG,
        isDisabled: !!isEditTemplatesAccess,
        classNameText:
          whenSend2 === TEMPLATE_EVENT_KEY_NAME.RECORD_CANCELED ||
          whenSend2 === TEMPLATE_EVENT_KEY_NAME.RECORD_DONE
            ? 'mt-4 mb-8'
            : 'mb-8',
      },
      {
        addedValues: services,
        newValue: servicesNewValue,
        inputId: TEMPLATE_DATA_KEY_NAMES.SERVICES,
        labelTextVariant: TEMPLATE_DATA_KEY_NAMES.SERVICES,
        isDisabled: !!isEditTemplatesAccess,
        classNameText: 'mb-8',
      },
      {
        addedValues: categories,
        newValue: categoriesNewValue,
        inputId: TEMPLATE_DATA_KEY_NAMES.CATEGORIES,
        labelTextVariant: TEMPLATE_DATA_KEY_NAMES.CATEGORIES,
        isDisabled: !!isEditTemplatesAccess,
        classNameText: 'mb-8',
      },
      {
        addedValues: staff,
        newValue: staffNewValue,
        inputId: TEMPLATE_DATA_KEY_NAMES.STAFF,
        labelTextVariant: TEMPLATE_DATA_KEY_NAMES.STAFF,
        isDisabled: !!isEditTemplatesAccess,
        classNameText: 'mb-8',
      },
      {
        addedValues: clientCategory,
        newValue: clientCategoryNewValue,
        inputId: TEMPLATE_DATA_KEY_NAMES.CLIENT_CATEGORY,
        labelTextVariant: TEMPLATE_DATA_KEY_NAMES.CLIENT_CATEGORY,
        isDisabled: !!isEditTemplatesAccess,
        classNameText: '',
      },
    ];

    // Удаляет "шарик" по его значению
    const deleteBalloon: TDeleteBalloonSetTP = useCallback(
      (value, keyName) => () => {
        const tKeyName = keyName as TBalloonsKeyNames;
        dispatch(deleteBalloonTP({ keyName: tKeyName, value }));
      },
      [dispatch],
    );

    // Добавляет новый по событию Blur шарик и очищает инпут
    const addBalloonOnBlurHandler: TAddBalloonOnBlurHandler = useCallback(
      (name, value) => {
        const keyName = name as TBalloonsKeyNames;
        dispatch(addBalloonTP({ keyName, value }));
      },
      [dispatch],
    );

    const renderInputWithDropdown = inputWithDropdownData.map(
      ({ inputId, addedValues, isDisabled, newValue, labelTextVariant, classNameText }) => {
        if (isBirthday) {
          if (inputId === TEMPLATE_DATA_KEY_NAMES.CLIENT_CATEGORY) {
            return (
              <InputWithDropdownFromEP
                key={inputId}
                inputId={inputId}
                newValue={newValue}
                disabled={!isDisabled}
                addedValues={addedValues}
                deleteBalloon={deleteBalloon}
                isFetchingSuccess={isFetchingSuccess}
                categoriesDataList={categoriesDataList}
                selectedFilialAccId={selectedFilialAccId}
                addBalloonOnBlur={addBalloonOnBlurHandler}
                labelTextVariant={labelTextVariant as keyof THelp}
                className={classNameText}
              />
            );
          }
        } else {
          return (
            <InputWithDropdownFromEP
              key={inputId}
              inputId={inputId}
              newValue={newValue}
              disabled={!isDisabled}
              addedValues={addedValues}
              deleteBalloon={deleteBalloon}
              isFetchingSuccess={isFetchingSuccess}
              categoriesDataList={categoriesDataList}
              selectedFilialAccId={selectedFilialAccId}
              addBalloonOnBlur={addBalloonOnBlurHandler}
              labelTextVariant={labelTextVariant as keyof THelp}
              className={classNameText}
            />
          );
        }
        return null;
      },
    );

    return <div className={className}>{renderInputWithDropdown}</div>;
  },
);
