import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { TAppLanguage } from '@models/index';
import { AddFile } from '@components/addFile';
import { FileIsProcessing } from '@blocks/fileIsProcessing';
import { ImportantMailingInfo } from '@blocks/importantMailingInfo';
import { MailingFirstStepInfo } from '@blocks/mailingFirstStepInfo';
import { ACTIVE_STEP_NUMBER, CLIENT_FILE_ACCEPT } from '@const/mailing';
import { MailingBlockingWarning } from '@blocks/mailingBlockingWarning';
import { ParsedClientFileInfo } from '@components/parsedClientFileInfo';
import { MailingFirstStepWarning } from '@blocks/mailingFirstStepWarning';
import {
  TAddFile,
  TClickHandler,
  TClearClientFile,
  TOnChangeHandler,
  TSetSelectedRecipientHandler,
} from '@shared/types';
import {
  TCustomerKeys,
  TParsedXlsxFileData,
  TConsentParseXLSXFileData,
  MAILING_PAGE_DATA_KEY_NAME,
} from '@redux/mailing/models';

type TMailingStep1Props = {
  /**
   * Имя загруженного файла с номерами рассылки
   * @param {string}
   */
  clientFileName: string;
  /**
   * Флаг отображения данных по файлу рассылки
   * @param {boolean}
   */
  showParsedInfo: boolean;
  /**
   * Флаг показывает лоадер пока парсится файл с номерами
   * @param {boolean}
   */
  isParsingXLSXFile: boolean;
  /**
   * Callback для перехода на следующий шаг рассылки
   * @param {() => void}
   */
  setNextStepHandler: () => void;
  /**
   * Флаг ошибки загруженного файла
   * @param {boolean}
   */
  isParsingXLSXFileError: boolean;
  /**
   * Callback для загрузки файла с номерами рассылки
   * @param {TOnChangeHandler}
   */
  setClientListFileHandler: TOnChangeHandler;
  /**
   * Callback для открытия окна загрузки файла
   * @param {TAddFile}
   */
  addFile: TAddFile;
  /**
   * Callback для очистки инпута
   * @param {TClearClientFile}
   */
  clearClientFile: TClearClientFile;
  /**
   * Ошибка, файл слишком большой
   * @param {boolean}
   */
  clientFileTooBigError: boolean;
  /**
   * Callback для добавления ключа в текст
   * @param {(key: string) => () => void}
   */
  setKeyInTextarea: (key: string) => () => void;
  /**
   * Callback для оборачивания выделенного текста в символы форматирования
   * @param {TClickHandler}
   */
  wrapSelectedTextInTextarea: TClickHandler;
  /**
   * Callback для открытия drawer с примерами шаблонов
   * @param {() => void}
   */
  openDrawer: () => void;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Данные о загруженном файле со списком клиентов
   * @param {TParsedXlsxFileData}
   */
  parsedXSLXFileData: TParsedXlsxFileData;
  /**
   * Обрабатывает клики на на блоках выбора получателей рассылки
   * @param {TSetSelectedRecipientHandler}
   */
  setSelectedRecipientHandler: TSetSelectedRecipientHandler;
  /**
   * Отправить клиентам согласившимся на рассылку
   * @param {boolean}
   */
  sendToConsentingRecipients: boolean;
  /**
   * Отправить клиентам от которых не получено согласие на рассылку
   * @param {boolean}
   */
  sendToNeutralRecipient: boolean;
  /**
   * Пользовательские ключи полученные после парсинга файла с данными клиентов
   * @param {TCustomerKeys}
   */
  customerKeys: TCustomerKeys;
  /**
   * Данные согласия по списку клиентов
   * @param {TConsentParseXLSXFileData | null}
   */
  consentParseFileData: TConsentParseXLSXFileData | null;
  /**
   * Опциональный параметр отключает возможность добавления фала рассылок и перехода на след. шаг
   * @param {boolean}
   * @default
   */
  disabled?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const MailingStep1 = memo(
  ({
    addFile,
    disabled,
    openDrawer,
    appLanguage,
    customerKeys,
    clientFileName,
    showParsedInfo,
    clearClientFile,
    setKeyInTextarea,
    isParsingXLSXFile,
    parsedXSLXFileData,
    setNextStepHandler,
    consentParseFileData,
    clientFileTooBigError,
    isParsingXLSXFileError,
    sendToNeutralRecipient,
    setClientListFileHandler,
    wrapSelectedTextInTextarea,
    sendToConsentingRecipients,
    setSelectedRecipientHandler,
    className,
  }: TMailingStep1Props) => {
    const { t } = useTranslation();

    const clientFileRef = useRef<HTMLInputElement>(null);

    // Очищает инпут в случае возникновения ошибки при загрузке файла
    useEffect(() => {
      if (isParsingXLSXFileError || clientFileTooBigError) {
        clearClientFile(clientFileRef);
      }
    }, [isParsingXLSXFileError, clientFileTooBigError, clearClientFile]);

    // Очищает инпут от загруженного файла
    const clearClientFileHandler = useCallback(() => {
      clearClientFile(clientFileRef);
    }, [clearClientFile, clientFileRef]);

    // Условия отключения кнопки перехода на следующий шаг
    const isNextButtonDisable =
      disabled ||
      !clientFileName ||
      isParsingXLSXFile ||
      !(sendToConsentingRecipients || sendToNeutralRecipient);

    return (
      <div className={className}>
        <MailingFirstStepWarning className='mb-4' />
        <MailingFirstStepInfo />
        {!showParsedInfo ? (
          <>
            <p className='text-grayText mb-0.5 leading-6 tracking-[0.0275em]'>
              {t('MAILING_PAGE_TEXT.addRecipientFile')}
            </p>
            <AddFile
              disabled={disabled}
              ref={clientFileRef}
              isFileToBig={false}
              iconVariant='addFile'
              accept={CLIENT_FILE_ACCEPT}
              isLoading={isParsingXLSXFile}
              addFileCallback={addFile(clientFileRef)}
              onChangeHandler={setClientListFileHandler}
              name={MAILING_PAGE_DATA_KEY_NAME.CLIENT_FILE}>
              {t('MAILING_PAGE_TEXT.uploadRecipientFile')}
            </AddFile>
          </>
        ) : null}
        {isParsingXLSXFile ? <FileIsProcessing /> : null}
        {showParsedInfo ? (
          <ParsedClientFileInfo
            appLanguage={appLanguage}
            clientFileName={clientFileName}
            parsedXSLXFileData={parsedXSLXFileData}
            stepNumber={ACTIVE_STEP_NUMBER.FIRST_STEP}
            consentParseFileData={consentParseFileData}
            clearClientFileHandler={clearClientFileHandler}
            sendToNeutralRecipient={sendToNeutralRecipient}
            sendToConsentingRecipients={sendToConsentingRecipients}
            setSelectedRecipientHandler={setSelectedRecipientHandler}
          />
        ) : null}
        <div className='flex flex-col sm:flex-row mt-6 sm:mt-8'>
          <Button
            fill
            dense
            type='action'
            view='filled'
            color='success'
            icon='chevron-right'
            onClick={setNextStepHandler}
            disabled={isNextButtonDisable}
            text={t('MAILING_PAGE_TEXT.continueButton')}
            className='mb-2 sm:mb-0 sm:mr-6 max-w-[10.5625rem]'
          />
          <MailingBlockingWarning />
        </div>
        <ImportantMailingInfo
          isMobileView
          openDrawer={openDrawer}
          disableOpenDrawerButton
          appLanguage={appLanguage}
          isShowExampleBlock={false}
          customerKeys={customerKeys}
          isShowAdditionalInfo={false}
          setKeyInTextarea={setKeyInTextarea}
          wrapSelectedTextInTextarea={wrapSelectedTextInTextarea}
          className='mt-8 xl:hidden'
        />
      </div>
    );
  },
);

MailingStep1.displayName = 'MailingStep1';
