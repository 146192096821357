import React from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { ProgressBar } from '@uikit/ProgressBar';

type TMailingProcessBarProps = {
  /**
   * Идентификатор рассылки
   * @param {number}
   */
  id: number;
  /**
   * Флаг состояния рассылки, рассылка запланирована
   * @param {boolean}
   */
  isPlaned: boolean;
  /**
   * Флаг состояния рассылки, рассылка запущена
   * @param {boolean}
   */
  isStarted: boolean;
  /**
   * Флаг состояния рассылки, рассылка на паузе
   * @param {boolean}
   */
  isOnPause: boolean;
  /**
   * Общий параметр количество отправленных сообщений / общее количество получателей рассылки
   * @param {string}
   */
  process: string;
  /**
   * Количество отправленных сообщений
   * @param {number}
   */
  send: number;
  /**
   * Общее количество получателей рассылки
   * @param {number}
   */
  total: number;
  /**
   * Callback для постановки запущенной рассылки на паузу
   * @param {(sendOutId: number) => void}
   */
  pauseSendOut: (sendOutId: number) => void;
  /**
   * Callback для запуска ранее остановленной рассылки
   * @param {(sendOutId: number) => void}
   */
  startPausedSendOut: (sendOutId: number) => void;
  /**
   * Скрывает/показывает кнопки изменяющие активность рассылок
   * @param {boolean}
   */
  isShowButtons: boolean;
  /**
   * Меняет стили для отображения в мобильных карточках
   * @param {boolean}
   */
  isMobileView: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const MailingProcessBar = ({
  id,
  send,
  total,
  process,
  isPlaned,
  isStarted,
  isOnPause,
  isMobileView,
  pauseSendOut,
  isShowButtons,
  startPausedSendOut,
  className = '',
}: TMailingProcessBarProps) => {
  const { t } = useTranslation();

  const processTextStyle = 'font-medium text-tooltips leading-5 tracking-[0.018em]';

  const textColor = isPlaned || isOnPause ? 'text-darkGray' : isStarted ? 'text-green1' : '';

  const processBarLabel = isPlaned
    ? t('MAILING_PAGE_TEXT.mailingTable.statusText.planed')
    : isStarted
    ? t('MAILING_PAGE_TEXT.mailingTable.statusText.launched')
    : isOnPause
    ? t('MAILING_PAGE_TEXT.mailingTable.statusText.onPause')
    : '';

  const buttonIcon = isOnPause ? 'play' : isStarted ? 'pause' : '';

  const pauseSendOutHandler = () => {
    pauseSendOut(id);
  };

  const startPausedSendOutHandler = () => {
    startPausedSendOut(id);
  };

  return (
    <div
      className={`flex md:flex-wrap py-2.5 items-center ${
        isMobileView ? 'w-full' : ''
      } ${className}`}>
      {!isMobileView ? <span className='inline-block mr-6 xl:w-[8rem]'>{process}</span> : null}
      <div className={`flex flex-col ${isMobileView ? 'w-full' : ''}`}>
        <span className={`${processTextStyle} ${textColor}`}>{processBarLabel}</span>
        <div className={`py-2 ${isMobileView ? 'w-full' : ''}`}>
          <ProgressBar
            type='def'
            view='filled'
            animate={false}
            stripes={false}
            value={send / total}
            color={isOnPause ? '' : 'success'}
            className='block xl:!w-[8rem]'
          />
        </div>
      </div>
      {(isOnPause || isStarted) && isShowButtons ? (
        <div className='px-[0.875rem] min-w-[2.5rem]'>
          <Button
            type='icon'
            dense={false}
            view='outlined'
            color='default'
            loading={false}
            disabled={false}
            icon={buttonIcon}
            onClick={isOnPause ? startPausedSendOutHandler : pauseSendOutHandler}
          />
        </div>
      ) : null}
    </div>
  );
};
