import React from 'react';
import { useTranslation } from 'react-i18next';
//
import { Menu } from '@uikit/Menu';
import { Dropdown } from '@uikit/Dropdown';
import { TOnChangeHandler } from '@shared/types';
import { AccessCheckbox } from '@blocks/accessCheckbox';
import { SEND_OUT_DETAIL_STATISTICS_FILTERS } from '@const/mailing';

type DetailStatisticFilterProps = {
  /**
   * Объект для формирования списка фильтров
   * @param {Record<string, string>}
   */
  filterList: Record<string, string>;
  /**
   * Массив выбранных фильтров
   * @param {string[]}
   */
  selectedFiltersList: string[];
  /**
   * Callback для обработки изменений в чекбоксах
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const DetailStatisticFilter = ({
  filterList,
  onChangeHandler,
  selectedFiltersList,
  className = '',
}: DetailStatisticFilterProps) => {
  const { t } = useTranslation();
  const filterListLength = selectedFiltersList.length;

  // формируется заголовок в зависимости от количества выбранных пунктов в выпадающем списке
  const label = filterListLength
    ? filterListLength === 1
      ? `${filterList[selectedFiltersList[0]]}`
      : t('MAILING_PAGE_TEXT.mailingDetailStat.selected', { number: selectedFiltersList.length })
    : t('MAILING_PAGE_TEXT.mailingDetailStat.selectFormList');

  const links = Object.entries(filterList).map(item => (
    <AccessCheckbox
      key={item[0]}
      text={item[1]}
      name={item[0]}
      onChangeHandler={onChangeHandler}
      checked={
        item[0] === SEND_OUT_DETAIL_STATISTICS_FILTERS.ALL
          ? filterListLength === Object.keys(filterList).length - 1
          : selectedFiltersList.includes(item[0])
      }
      className='w-fit'
    />
  ));

  return (
    <div className={className}>
      <Dropdown
        view='outlined'
        color='default'
        position='bottom-left'
        text={<span>{label}</span>}
        content={
          <Menu view='raised' className='!p-2'>
            {links}
          </Menu>
        }
        className='w-full nav_dropdown'
      />
    </div>
  );
};
