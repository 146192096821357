import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//
import { TAppDrawerData } from '@redux/app/models';
import { IInitialState, initialState } from '@redux/app/state';

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    showAppDrawer(state: IInitialState, action: PayloadAction<TAppDrawerData>) {
      state.showAppDrawer = true;
      state.appDrawerData = action.payload;
    },
    hideAppDrawer(state: IInitialState) {
      state.showAppDrawer = false;
      state.appDrawerData = {
        title: '',
        description: '',
      };
    },
  },
});

export const appReducer = appSlice.reducer;

export const { showAppDrawer, hideAppDrawer } = appSlice.actions;
