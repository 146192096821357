import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@uikit/Spinner';
import { ChannelHeader } from '@blocks/channelHeader';
import icon from '@img/connect/telegram.svg';

type TTgBotLoadingProps = {
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const TgBotLoading = memo(({ className = '' }: TTgBotLoadingProps) => {
  const { t } = useTranslation();

  return (
    <div className={`flex flex-col grow ${className}`}>
      <ChannelHeader
        iconSrc={icon}
        subheaderText=''
        headerText={t('CONNECTION_PAGE_TEXT.telegramBotConnection.loadingHeader')}
        className='mb-6'
      />
      <div className='mb-4'>
        <p className='text-gray2 leading-6 tracking-[0.0275em] whitespace-pre-line'>
          {t('CONNECTION_PAGE_TEXT.telegramBotConnection.loadingDescription')}
        </p>
      </div>
      <div className='flex flex-col grow items-center justify-center w-full'>
        <Spinner view='smooth' color='success' value={undefined} size='40' />
      </div>
    </div>
  );
});

TgBotLoading.displayName = 'ConnectionStatusCardTgBot';
