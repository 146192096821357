import React, { memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { Dialog } from '@uikit/Dialog';
import { clearCurrentRefValue } from '@helpers/index';
import { TEXTAREA_TEXT_MAX_LENGTH } from '@const/common';
// eslint-disable-next-line
import { FormLabel } from '@blocks/formLabel';
// eslint-disable-next-line
import { AddFile } from '@components/addFile';
// eslint-disable-next-line
import { useAppDispatch } from '@store/store';
import { TMediaFileType } from '@models/index';
// eslint-disable-next-line
import { clearFiles } from '@redux/template/templateSlice';
// eslint-disable-next-line
import { MediaPreview } from '@blocks/mediaPreview';
import { TextAreaWithLabel } from '@blocks/textAreaWithLabel';
// eslint-disable-next-line
import { IconWithTooltips } from '@components/iconWithTooltips';
// eslint-disable-next-line
import { TOnChangeHandler, TOnChangeHandlerInputTextarea } from '@shared/types';
// eslint-disable-next-line
import { addMediaFileForFeedback, clearFeedbackMediaFile } from '@redux/feedback/feedbackSlice';

type TFeedbackModal = {
  /**
   * Текст сообщения
   * @param {string}
   */
  text: string;
  /**
   * Флаг открытия модалки
   * @param {boolean}
   */
  isOpen: boolean;
  /**
   * Флаг состояния запроса
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Имя загруженного файла
   * @param {string}
   */
  mediaFileName: string;
  /**
   * URL для отображения файла в превью
   * @param {string | null}
   */
  mediaFileURL: string | null;
  /**
   * Тип загруженного файла
   * @param {TMediaFileType}
   */
  mediaFileType: TMediaFileType;
  /**
   * Флаг ошибки по размеру файла
   * @param {boolean}
   */
  mediaFileTooBigError: boolean;
  /**
   * Флаг успешной отправки отзыва
   * @param {boolean}
   */
  isSendFeedbackSuccess: boolean;
  /**
   * Callback для закрытия модалки
   * @param {() => void}
   */
  closeModalCallback: () => void;
  /**
   * Callback для отправки сообщения
   * @param {() => void}
   */
  sendFeedbackCallback: () => void;
  /**
   * Callback для обработки ввода текста
   * @param {TOnChangeHandlerInputTextarea}
   */
  onChangeCallback: TOnChangeHandlerInputTextarea;
};

export const FeedbackModal = memo(
  ({
    text,
    isOpen,
    isLoading,
    // eslint-disable-next-line
  mediaFileURL,
    // eslint-disable-next-line
  mediaFileName,
    // eslint-disable-next-line
  mediaFileType,
    onChangeCallback,
    closeModalCallback,
    // eslint-disable-next-line
  mediaFileTooBigError,
    sendFeedbackCallback,
    isSendFeedbackSuccess,
  }: TFeedbackModal) => {
    const { t } = useTranslation();
    // const dispatch = useAppDispatch();

    // ref на input тип file
    const mediaFileRef = useRef<HTMLInputElement>(null);

    // // Callback открывает диалоговое окно добавления файла
    // const addFile = useCallback(
    //   (ref: React.RefObject<HTMLInputElement>) => () => {
    //     ref.current?.click();
    //   },
    //   [],
    // );

    // // Обработчик загрузки файлов, при подключении бэка, добавить сохранение файла в FormData или еще как)))
    // const onChangeHandlerFileInput: TOnChangeHandler = useCallback(
    //   event => {
    //     const { files: file } = event.currentTarget;
    //     if (file) {
    //       dispatch(addMediaFileForFeedback({ files: file }));
    //     }
    //   },
    //   [dispatch],
    // );

    // // Очищает state от загруженного/выбранного файла
    // const clearMediaFileInput = useCallback(() => {
    //   dispatch(clearFeedbackMediaFile());
    //   clearCurrentRefValue(mediaFileRef);
    // }, [dispatch]);

    useEffect(() => {
      clearCurrentRefValue(mediaFileRef);
    }, [isSendFeedbackSuccess]);

    return (
      <Dialog
        type='default'
        view='raised'
        color='default'
        isOpen={isOpen}
        onClose={closeModalCallback}
        title={t('BASE_TEMPLATE_PAGE_TEXT.feedbackModal.title')}
        text={
          <>
            <TextAreaWithLabel
              value={text}
              showInfo={false}
              htmlFor='feedback'
              disabled={isLoading}
              onChangeHandler={onChangeCallback}
              maxLength={TEXTAREA_TEXT_MAX_LENGTH}
              placeholder={t('BASE_TEMPLATE_PAGE_TEXT.feedbackModal.textareaPlaceholder')}
            />
            {/* <div className={`flex flex-wrap items-center ${mediaFileName ? 'hidden' : ''}`}>
            <FormLabel
              htmlFor=''
              showInfo={false}
              textColor='text-stormGray'
              labelTextVariant='selectFileText'
              fontSize='text-body2 tracking-[0.0275em]'
              className='mb-1'
            />
            <AddFile
              name='files'
              ref={mediaFileRef}
              iconVariant='addFile'
              accept='image/jpeg, video/mp4'
              isFileToBig={mediaFileTooBigError}
              addFileCallback={addFile(mediaFileRef)}
              onChangeHandler={onChangeHandlerFileInput}
              className='min-w-[13.875rem]'>
              {t('BASE_TEMPLATE_PAGE_TEXT.feedbackModal.addFile')}
            </AddFile>
            <IconWithTooltips tooltips='selectFileText' noIcon />
          </div>
          <MediaPreview
            fileURL={mediaFileURL}
            fileName={mediaFileName}
            fileType={mediaFileType}
            isFileToBig={mediaFileTooBigError}
            clearInputCallback={clearMediaFileInput}
            className={mediaFileURL ? 'mt-3' : ''}
          /> */}
          </>
        }
        backdropOpacity={40}
        leftButton={
          <Button
            dense
            type='action'
            view='outlined'
            color='default'
            onClick={closeModalCallback}
            text={t('BASE_TEMPLATE_PAGE_TEXT.feedbackModal.cancelButton')}
            className='w-[6rem]'
          />
        }
        rightButton={
          <Button
            dense
            type='action'
            view='filled'
            color='success'
            loading={isLoading}
            disabled={isLoading || !text}
            onClick={sendFeedbackCallback}
            text={t('BASE_TEMPLATE_PAGE_TEXT.feedbackModal.sendButton')}
            className='w-[7.5625rem]'
          />
        }
        className='!max-w-[42.5rem] !w-full'
      />
    );
  },
);

FeedbackModal.displayName = 'FeedbackModal';
