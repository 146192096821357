import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

type TProps = {
  /**
   * Номер телефона для отправки кода
   * @param {string}
   */
  phoneNumber: string;
  /**
   * Callback для изменения номера
   * @param {() => void}
   */
  changePhoneNumberHandler: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const PhoneNumberWithChangeButton = memo(
  ({ phoneNumber, changePhoneNumberHandler, className = '' }: TProps) => {
    const { t } = useTranslation();

    return (
      <div className={className}>
        <div className='text-balticSea leading-6 tracking-[0.0275em] flex items-center'>
          <span className='mr-2'>{t('CONNECTION_PAGE_TEXT.botConnection.yourPhone')}</span>
          <span className='font-bold mr-2'>+{phoneNumber}</span>
          <button
            onClick={changePhoneNumberHandler}
            className='bg-none border-none underline text-btnGreen text-tooltips'>
            {t('CONNECTION_PAGE_TEXT.botConnection.changePhoneNumberButton')}
          </button>
        </div>
      </div>
    );
  },
);

PhoneNumberWithChangeButton.displayName = 'PhoneNumberWithChangeButton';
