import { TGetLeadsResponseData } from '@api/types';
import { TAddLeadData } from '@redux/addLeads/models';

export const addLidsEmptyData: TAddLeadData = {
  promoCode: '',
  phone: '',
  comment: '',
  leadName: '',
  branchId: '',
  createdAt: '',
  branchName: '',
};

export const leadsResponseData: TGetLeadsResponseData[] = [
  {
    promo_code: 'sergo_rostov',
    phone: '79999999999',
    branch: '',
    branch_name: '',
    comment: 'тестовое добавление',
    created_branches: [],
    created_at: '2023-11-28T15:57:57.077000',
  },
  {
    promo_code: 'sergo_rostov',
    phone: '79630840600',
    branch: '',
    branch_name: 'test lead name',
    comment: 'another try to set lead name',
    created_branches: [],
    created_at: '2023-11-28T16:30:18.749000',
  },
];
