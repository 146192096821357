import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Menu } from '@uikit/Menu';
import { Dropdown } from '@uikit/Dropdown';
import { useAppSelector } from '@store/store';
import { TOnChangeHandler } from '@shared/types';
import { getAppData } from '@redux/app/selectors';
import { AccessCheckbox } from '@blocks/accessCheckbox';
import { RIGHT_ITEM_NAMES, RIGHT_MENU_ITEMS } from '@const/access';

type TSetBlockProps = {
  /**
   * Массив с правами
   * @param {string}
   */
  rights: string[];
  /**
   * Callback для обработки изменений в чекбоксах
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const AccessSelectBlock = memo(
  ({ rights, onChangeHandler, className = '' }: TSetBlockProps) => {
    const { t } = useTranslation();

    const { appLanguage } = useAppSelector(getAppData);

    // формируется заголовок в зависимости от количества выбранных пунктов в выпадающем списке
    const label = rights.length
      ? rights.length === 1
        ? RIGHT_MENU_ITEMS[appLanguage][rights[0]]
        : t('ACCESS_PAGE_TEXT.dropdownLabelSelected', { selected: rights.length })
      : t('ACCESS_PAGE_TEXT.dropdownLabelSelectInList');

    const links = Object.entries(RIGHT_MENU_ITEMS[appLanguage]).map(([key, value]) => (
      <div key={key} className='py-1 px-2'>
        <AccessCheckbox
          name={key}
          text={value}
          onChangeHandler={onChangeHandler}
          checked={
            key === RIGHT_ITEM_NAMES.ALL
              ? rights.length === Object.keys(RIGHT_MENU_ITEMS[appLanguage]).length - 1
              : rights.includes(key)
          }
          className='w-fit'
        />
      </div>
    ));

    return (
      <div className={className}>
        <Dropdown
          color='default'
          view='outlined'
          position='bottom-left'
          text={<span>{label}</span>}
          content={
            <Menu view='raised' className='!p-2'>
              {links}
            </Menu>
          }
          className='w-full nav_dropdown'
        />
      </div>
    );
  },
);

AccessSelectBlock.displayName = 'AccessSelectBlock';
