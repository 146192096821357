import { TGetInviteFriendsAnalytics } from '@api/types';
import { TInviteFriendsStatisticsItemData } from '@redux/inviteFrendsStatistics/types';

export const inviteFriendsStatisticsMappers = {
  responseToStore(data: TGetInviteFriendsAnalytics[]): TInviteFriendsStatisticsItemData[] {
    return data.map(
      ({
        count_child_paid,
        child_signup_ratio,
        median_children_records,
        date_first_activate_template,
        median_payments_child,
        child_payment_ratio,
        template_id,
        count_child,
        count_parent,
        template_name,
        amount_payments_child,
        amount_accrued_bonuses_parents,
        amount_accrued_bonuses_children,
        child_have_records,
      }) => ({
        childSignupRatio: child_signup_ratio || 0,
        amountAccruedBonusesChildren: amount_accrued_bonuses_children || 0,
        amountAccruedBonusesParents: amount_accrued_bonuses_parents || 0,
        amountPaymentsChild: amount_payments_child || 0,
        childPaymentRatio: child_payment_ratio || 0,
        countChild: count_child || 0,
        countChildPaid: count_child_paid || 0,
        countParent: count_parent || 0,
        dateFirstActivateTemplate: date_first_activate_template || '',
        medianChildrenRecords: median_children_records || 0,
        medianPaymentsChild: median_payments_child || 0,
        templateId: template_id,
        templateName: template_name,
        childHaveRecords: child_have_records || 0,
      }),
    );
  },
};
