import React, { memo } from 'react';
//
import { TIconKey } from '@models/index';
import { ICON_VARIANT } from '@data/dict';

interface IIconProps {
  /**
   * Опциональный параметр title в img
   * @param {string}
   */
  title?: string;
  /**
   * Вариант иконки, берется по ключу из словаря
   * @param {iconKey}
   */
  variant: TIconKey;
  /**
   * Опциональный callback обрабатывает событие onClick
   * @param {() => void}
   */
  onClick?: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const Icon = memo(({ className = '', title, variant, onClick }: IIconProps) => (
  <img
    title={title}
    onClick={onClick}
    className={className}
    alt={variant.toString()}
    src={ICON_VARIANT[variant]}
  />
));

Icon.displayName = 'Icon';
