import {
  TTelegramAuthData,
  TTelegramAuthResponseData,
  TTelegramIDAuthResponseData,
} from '@redux/auth/models';

export const authMappers = {
  /**
   * Преобразует данные авторизации Telegram в структуру TTelegramAuthData.
   *
   * @param {TTelegramAuthResponseData} data - Данные авторизации Telegram.
   * @returns {TTelegramAuthData} Преобразованные данные авторизации.
   */
  mapAuthUserData({
    id,
    first_name,
    last_name,
    username,
    photo_url,
  }: TTelegramAuthResponseData): TTelegramAuthData {
    return {
      id,
      firstName: first_name,
      lastName: last_name,
      username,
      photoUrl: photo_url,
    };
  },
  /**
   * Преобразует данные авторизации Telegram с использованием ID пользователя в структуру TTelegramAuthData.
   *
   * @param {TTelegramIDAuthResponseData} data - Данные авторизации Telegram с использованием ID пользователя.
   * @returns {TTelegramAuthData} Преобразованные данные авторизации.
   */
  mapAuthPinUserData({
    user_id,
    first_name,
    last_name,
    username,
    photo_url,
  }: TTelegramIDAuthResponseData): TTelegramAuthData {
    return {
      id: user_id,
      firstName: first_name,
      lastName: last_name,
      username,
      photoUrl: photo_url,
    };
  },
};
