export type TAccessRight =
  | 'manage_wapp'
  | 'sendouts'
  | 'translation_switch'
  | 'edit_templates'
  | 'hide_translation_phones'
  | 'notify';

export type TAccessDataItem = {
  [key: string]: string | TAccessRight[];
  userId: string;
  name: string;
  rights: TAccessRight[];
};

export enum ACCESS_DATA_KEY_NAMES {
  USER_ID = 'userId',
  NAME = 'name',
}

export type TGetNewRateAccesses = (
  event: { name: string; checked: boolean },
  rate: TAccessRight[],
) => string[];

export type TAccessDataItemResponse = {
  user_id: string;
  name: string;
  rights: TAccessRight[];
};

export enum ACCESS_RESPONSE_KEYS {
  DATA = 'data',
}

export type TPostAccessesRequestData = {
  data: TAccessDataItem[];
};
