import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@uikit/Button';
import { CONFIG } from '@const/config';
import { useCopyToClipboard } from '@hooks/useClipboard';

type TTildaIntegrationInfo = {
  /**
   * Ссылка на webhook
   * @param {string}
   */
  integrationLink: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const TildaIntegrationInfo = memo(
  ({ integrationLink, className = '' }: TTildaIntegrationInfo) => {
    const { t } = useTranslation();
    const [copiedValue, copyToClipboard] = useCopyToClipboard();

    const copyToClipboardHandler = useCallback(
      (value: string) => () => {
        if (!copiedValue) {
          copyToClipboard(value);

          setTimeout(() => {
            copyToClipboard('');
          }, 5000);
        }
      },
      [copiedValue, copyToClipboard],
    );

    return (
      <div className={className}>
        <div className='border border-quartz bg-gray1 rounded-lg p-4'>
          <p className='font-bold text-blackText text-[1.25rem] leading-8 tracking-[0.0075em] mb-4'>
            {t('TEMPLATE_PAGE_TEXT.tildaIntegrationInfo.header')}
          </p>
          <ul className='list-decimal pl-5 mb-2'>
            <li>{t('TEMPLATE_PAGE_TEXT.tildaIntegrationInfo.list.first')}</li>
            <li>
              <span className='mr-2'>
                {t('TEMPLATE_PAGE_TEXT.tildaIntegrationInfo.list.second')}
              </span>
              <span className='font-bold'>
                {t('TEMPLATE_PAGE_TEXT.tildaIntegrationInfo.list.secondBold')}
              </span>
            </li>
            <li>
              <span className='mr-2'>
                {t('TEMPLATE_PAGE_TEXT.tildaIntegrationInfo.list.thirdStart')}
              </span>
              <span className='font-bold mr-2'>
                {t('TEMPLATE_PAGE_TEXT.tildaIntegrationInfo.list.thirdBold')}
              </span>
              <span>{t('TEMPLATE_PAGE_TEXT.tildaIntegrationInfo.list.thirdEnd')}</span>
            </li>
          </ul>
          <div className='bg-white border border-dGray text-gray3 rounded-lg p-2 whitespace-break-spaces mb-4'>
            {integrationLink}
          </div>
          <Button
            dense
            type='action'
            view='outlined'
            color='success'
            rightIcon={copiedValue ? 'tick' : 'duplicate'}
            onClick={copyToClipboardHandler(integrationLink)}
            text={
              copiedValue
                ? t('TEMPLATE_PAGE_TEXT.tildaIntegrationInfo.copyButtonSuccess')
                : t('TEMPLATE_PAGE_TEXT.tildaIntegrationInfo.copyButton')
            }
            className='mb-8'
          />
          <div>
            <a
              target='_blank'
              rel='nofollow noreferrer noopener'
              href={CONFIG.TILDA_INTEGRATION_INSTRUCTION}
              className='underline text-green1 hover:text-green1 leading-6 tracking-[0.0275em] break-all'>
              {t('TEMPLATE_PAGE_TEXT.tildaIntegrationInfo.linkText')}
            </a>
          </div>
        </div>
      </div>
    );
  },
);

TildaIntegrationInfo.displayName = 'TildaIntegrationInfo';
