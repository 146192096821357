import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';
//
import { REJECT_RESPONSE_2_KEY } from '@api/types';
import { TRejectResponseData } from '@models/index';
import { COMMON_TOAST, REG_EXP } from '@const/common';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { yclientsStartPageThunk } from '@redux/yclients/yclientsThunk';

import { IInitialState, initialState } from './initialState';

const yclientsSlice = createSlice({
  name: 'yclientsSlice',
  initialState,
  reducers: {
    showErrorPageYSP(state: IInitialState) {
      state.isError = true;
    },
    setIsAmplitudeEventSend(state: IInitialState, action: PayloadAction<boolean>) {
      state.isAmplitudeEventSend = action.payload;
    },
    setIsSendAmplitudeEvent(state: IInitialState) {
      if (!state.isSendAmplitudeEvent) {
        state.isSendAmplitudeEvent = true;
      }
    },
    setIsLoading(state: IInitialState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setQueryParamsData(
      state: IInitialState,
      action: PayloadAction<{
        branchId: string;
        formUrl: string;
        leadId: string;
        formHash: string;
      }>,
    ) {
      const { branchId, formUrl, leadId, formHash } = action.payload;
      state.leadId = leadId;
      state.branchId = branchId;
      state.formHash = formHash;
      state.linkToAutoConnectionForm = formUrl;
    },
    setNameYSP(state: IInitialState, action: PayloadAction<{ value: string }>) {
      const { value } = action.payload;

      if (state.showNameError && value.length) {
        state.showNameError = false;
      }

      state.yclientsName = value;
    },
    setPhoneYSP(state: IInitialState, action: PayloadAction<{ value: string }>) {
      const { value } = action.payload;
      const newPhoneNumber = value.replace(REG_EXP.onlyNumbers, '');

      if (state.showPhoneError && newPhoneNumber.length > 9) {
        state.showPhoneError = false;
      }

      if (newPhoneNumber) {
        state.yclientsPhone = newPhoneNumber;
      } else {
        state.yclientsPhone = '';
      }
    },
    setPlaceToContactWhatsApp(state: IInitialState, action: PayloadAction<{ checked: boolean }>) {
      state.isPlaceToContactPhone = false;
      state.isPlaceToContactWhatsApp = action.payload.checked;
    },
    setPlaceToContactPhone(state: IInitialState, action: PayloadAction<{ checked: boolean }>) {
      state.isPlaceToContactWhatsApp = false;
      state.isPlaceToContactPhone = action.payload.checked;
    },
    setNameError(state: IInitialState) {
      const name = state.yclientsName;

      if (!name || name.length < 1) {
        state.showNameError = true;
      }
    },
    setPhoneError(state: IInitialState) {
      const phone = state.yclientsPhone;

      if (!phone && phone.length < 10) {
        state.showPhoneError = true;
      }
    },
    showSuccessPage(state: IInitialState) {
      state.isShowSuccessMessage = true;
      state.yclientsName = '';
      state.yclientsPhone = '';
      state.isPlaceToContactPhone = false;
      state.isPlaceToContactWhatsApp = true;
    },
    clearAllErrorYSP(state: IInitialState) {
      state.isError = false;
      state.errorMessage = '';
    },
  },
  extraReducers: builder => {
    builder.addCase(yclientsStartPageThunk.pending, (state: IInitialState) => {
      state.isLoading = true;
    });
    builder.addCase(yclientsStartPageThunk.fulfilled, (state: IInitialState) => {
      state.isLoading = false;
    });
    builder.addCase(
      yclientsStartPageThunk.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              return;
            }
          }
        }
        state.isLoading = false;
        state.errorMessage = i18n.t(COMMON_TOAST.COMMON_ERROR_MESSAGE);
      },
    );
  },
});

export const yclientsSliceReducer = yclientsSlice.reducer;

export const {
  setNameYSP,
  setPhoneYSP,
  setNameError,
  setIsLoading,
  setPhoneError,
  showSuccessPage,
  clearAllErrorYSP,
  showErrorPageYSP,
  setQueryParamsData,
  setPlaceToContactPhone,
  setIsAmplitudeEventSend,
  setIsSendAmplitudeEvent,
  setPlaceToContactWhatsApp,
} = yclientsSlice.actions;
