import React, { memo, useEffect, useRef } from 'react';
//
import { EVENT_KEY_NAMES } from '@models/index';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { TKeyboardClickEvent, TOnChangeHandler, TOnFocusHandlerInputElement } from '@shared/types';

type TPinInputProps = {
  /**
   * Значение пинкода для авторизации
   * @param {string}
   */
  pin: string;
  /**
   * Callback для обработки изменения пина
   * @param {(value: string) => void}
   */
  onChangeCallback: (value: string) => void;
  /**
   * Флаг ошибки
   * @param {boolean}
   */
  isError?: boolean;
  /**
   * Отключает инпуты
   * @param {boolean}
   */
  disabled: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const TgChanelPinInput = memo(
  ({ pin, disabled, isError, onChangeCallback, className = '' }: TPinInputProps) => {
    const ref0 = useRef<HTMLInputElement>(null);
    const ref1 = useRef<HTMLInputElement>(null);
    const ref2 = useRef<HTMLInputElement>(null);
    const ref3 = useRef<HTMLInputElement>(null);
    const ref4 = useRef<HTMLInputElement>(null);

    const refsArray = [ref0, ref1, ref2, ref3, ref4];

    const inputBlockColor = isError ? 'danger' : 'default';

    useEffect(() => {
      ref0?.current?.focus();
    }, []);

    useEffect(() => {
      if (!pin) {
        ref0?.current?.focus();
      }
    }, [pin]);

    const onChangeHandler: TOnChangeHandler = event => {
      const { name, value } = event.currentTarget;

      if (Number.isNaN(+value)) return;

      const newValue = [...pin.split('')];
      newValue.splice(+name, 1, value);
      onChangeCallback(newValue.join(''));

      if (!value) {
        // refsArray[+name - 1]?.current?.focus();
        return;
      }
      refsArray[+name + 1]?.current?.focus();
    };

    const selectOnFocus: TOnFocusHandlerInputElement = event => {
      event.currentTarget.select();
    };

    const onKeyUpHandler: TKeyboardClickEvent = event => {
      const { key, currentTarget } = event;
      const { name } = currentTarget;

      const isMoveFocusToLeft =
        key === EVENT_KEY_NAMES.BACKSPACE && pin.length >= +name && !pin[+name];

      if (isMoveFocusToLeft) {
        refsArray[+name - 1]?.current?.focus();
      }
    };

    return (
      <div className={`flex items-center ${className}`}>
        <div className='flex justify-between w-[14.5rem]'>
          <div className='h-10 w-10'>
            <TextInput
              name={0}
              value={pin[0]}
              onChange={onChangeHandler}
              onClick={selectOnFocus}
              type='text'
              view='outlined'
              color={inputBlockColor}
              inputMode='numeric'
              autoComplete='off'
              ref={ref0}
              disabled={disabled}
            />
          </div>
          <div className='h-10 w-10'>
            <TextInput
              name={1}
              value={pin[1]}
              onChange={onChangeHandler}
              onClick={selectOnFocus}
              onKeyDown={onKeyUpHandler}
              type='text'
              view='outlined'
              color={inputBlockColor}
              inputMode='numeric'
              autoComplete='off'
              ref={ref1}
              disabled={disabled}
            />
          </div>
          <div className='h-10 w-10'>
            <TextInput
              name={2}
              value={pin[2]}
              onChange={onChangeHandler}
              onClick={selectOnFocus}
              onKeyDown={onKeyUpHandler}
              type='text'
              view='outlined'
              color={inputBlockColor}
              inputMode='numeric'
              autoComplete='off'
              ref={ref2}
              disabled={disabled}
            />
          </div>
          <div className='h-10 w-10'>
            <TextInput
              name={3}
              value={pin[3]}
              onChange={onChangeHandler}
              onClick={selectOnFocus}
              onKeyDown={onKeyUpHandler}
              type='text'
              view='outlined'
              color={inputBlockColor}
              inputMode='numeric'
              autoComplete='off'
              ref={ref3}
              disabled={disabled}
            />
          </div>
          <div className='h-10 w-10'>
            <TextInput
              name={4}
              value={pin[4]}
              onChange={onChangeHandler}
              onClick={selectOnFocus}
              onKeyDown={onKeyUpHandler}
              type='text'
              view='outlined'
              color={inputBlockColor}
              inputMode='numeric'
              autoComplete='off'
              ref={ref4}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    );
  },
);

TgChanelPinInput.displayName = 'TgChanelPinInput';
