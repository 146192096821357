export interface IInitialState {
  isShowEmojiPicker: boolean;
  textAreaName: string;
  isOpen: boolean;
  stepIdEmoji: string;
}

export const initialState: IInitialState = {
  isShowEmojiPicker: false,
  textAreaName: '',
  isOpen: false,
  stepIdEmoji: '0',
};
