import React, { memo } from 'react';
import { IconWithTooltips } from '@components/iconWithTooltips';

type TGrayLabelWithInfoProps = {
  /**
   * Текст метки
   * @param {string}
   */
  text: string;
  /**
   * Флаг обязательного поля
   * @param {boolean}
   */
  isRequired: boolean;
  /**
   * Опциональный параметр, если true при первом визите на страницу будет показан обучающий попап
   * @param {boolean}
   */
  isFirstTooltip?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const GrayLabelWithInfo = memo(
  ({ text, isRequired, isFirstTooltip, className = '' }: TGrayLabelWithInfoProps) => {
    return (
      <div className={`flex ${className}`}>
        <p className='text-stormGray leading-6 tracking-[0.0275em] mr-2'>
          {text}
          {isRequired ? <span>*</span> : null}
        </p>
        <IconWithTooltips tooltips='templateTitle' isFirstTooltip={isFirstTooltip} />
      </div>
    );
  },
);

GrayLabelWithInfo.displayName = 'GrayLabelWithInfo';
