import { convertDateString } from '@helpers/index';
import { TGetFullTableResponse, TGetPayHistoryConsultantTableData } from '@api/types';
import { TPayHistoryConsultantTableData } from '@redux/payHistoryConsultantTable/models';

export type TMapGetPayHistoryConsultantTableDataToStore = (
  data: TGetFullTableResponse<TGetPayHistoryConsultantTableData>,
) => TPayHistoryConsultantTableData[];

/**
 * Преобразует данные полученные из getPayHistoryConsultantTable в структуру данных хранилища Redux.
 * @param {TGetFullTableResponse<TGetPayHistoryConsultantTableData>} data - Данные полученные из getPayHistoryConsultantTable.
 * @returns {TPayHistoryConsultantTableData[]} - Преобразованные данные для хранилища Redux.
 */
const mapGetPayHistoryConsultantTableDataToStore: TMapGetPayHistoryConsultantTableDataToStore = ({
  data,
}) =>
  data.map(({ payed, amount, period }) => ({
    status: payed,
    sum: amount,
    period: convertDateString(period),
  }));

export { mapGetPayHistoryConsultantTableDataToStore };
