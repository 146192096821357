import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Menu } from '@uikit/Menu';
import { Dropdown } from '@uikit/Dropdown';
import { MenuItem } from '@uikit/Menu/MenuItem';

type TTemplatesVersionDropdownProps = {
  /**
   * Заголовок
   * @param {string}
   */
  selectedVersion: string;
  /**
   * Массив с версиями шаблонов для отобрадения выпадающего списка
   * @param {string[]}
   */
  versions: string[];
  /**
   * Callback для выбора версии шаблона
   * @param version
   */
  onVersionSelect: (version: string) => void;
  /**
   * Опциональная строка классов
   * @param {string}
   * @default ''
   * */
  className?: string;
};
export const TemplatesVersionDropdown = memo(
  ({
    versions,
    selectedVersion,
    onVersionSelect,
    className = '',
  }: TTemplatesVersionDropdownProps) => {
    const { t } = useTranslation();

    const renderText = useCallback(
      (version: string) => {
        return version === 'all'
          ? t('TEMPLATE_PAGE_TEXT.statistics.allVersion')
          : t('TEMPLATE_PAGE_TEXT.statistics.version', { version });
      },
      [t],
    );

    const whenSendOptionList = useMemo(
      () =>
        versions.map(item => (
          <MenuItem
            key={item}
            text={<span>{renderText(item)}</span>}
            onClick={() => onVersionSelect(item)}
          />
        )),
      [renderText, versions, onVersionSelect],
    );

    return (
      <div className={className}>
        <Dropdown
          color='default'
          view='outlined'
          position='bottom-left'
          content={<Menu view='raised'>{whenSendOptionList}</Menu>}
          text={<span className='text-body1'>{renderText(selectedVersion)}</span>}
          className='when_send_dropdown min-w-[12rem] w-full'
        />
      </div>
    );
  },
);

TemplatesVersionDropdown.displayName = 'TemplatesVersionDropdown';
