import React from 'react';

interface INoAccess {
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const NoAccess: React.FC<INoAccess> = ({ className = '' }) => {
  return (
    <div className={`flex flex-col items-center justify-center h-[50vh] w-full ${className}`}>
      <p>У вас нет прав для доступа на эту страницу,</p>
      <p>обратитесь к руководителю.</p>
    </div>
  );
};
