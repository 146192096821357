import { TSettingsData } from '@redux/settings/type';
import { TGetMessageSendingScheduleTime } from '@shared/types';
import { TChannel, TGetPriorityChannelResponseData, TGetSettingResponseData } from '@api/types';
import {
  Midnight,
  LOGIN_REQUIRED,
  PROVIDER_NAMES,
  TOKEN_REQUIRED,
  PASSWORD_REQUIRED,
  SMS_OPERATOR_NAMES,
  SENDER_NAME_REQUIRED,
  SMS_OPERATOR_NAMES_ENG,
} from '@const/settings';

interface ISettingsMappers {
  /**
   * Возвращает расписание времени отправки сообщений на основе указанного sendFromTime и sendToTime.
   *
   * @param {Object} options - Объект с параметрами.
   * @param {string} options.sendFromTime - Время начала отправки сообщений.
   * @param {string} options.sendToTime - Время окончания отправки сообщений.
   * @return {Array<string> | null} - Расписание времени отправки сообщений. Возвращает null, если sendFromTime и sendToTime равны '00:00'.
   */
  getMessageSendingScheduleTime: TGetMessageSendingScheduleTime;
  /**
   * Функция преобразует данные полученные из ответа сервера в формат данных настроек.
   *
   * @param {Object} data - Объект с данными полученными из ответа сервера.
   * @return {Object} - Объект с данными настроек.
   * @property {Array} exceptionMastersNames - Массив исключений для мастеров в формате строк.
   * @property {boolean} phonesDontShowInBot - Флаг, указывающий, показывать ли номера телефонов в боте.
   * @property {string} smsProvider - Провайдер SMS или значение TURN_OFF, если SMS отключены.
   * @property {string} smsApikey - Ключ API для отправки SMS.
   * @property {string} smsSenderName - Имя отправителя SMS.
   * @property {string} smsLogin - Логин для авторизации при отправке SMS или пустая строка.
   * @property {string} smsPass - Пароль для авторизации при отправке SMS или пустая строка.
   * @property {string} phone - Телефон или пустая строка
   * @property {Object} billData - Объект с данными для счета.
   * @property {string} billData.inn - ИНН или пустая строка.
   * @property {string} billData.name - Имя или пустая строка.
   * @property {string} billData.kpp - КПП или пустая строка.
   * @property {boolean} replyMsgCheck - Флаг, указывающий, делать ли проверку сообщений-ответов.
   * @property {boolean} smsConfirm - Флаг, указывающий, требуется ли подтверждение при отправке SMS.
   * @property {boolean} enableReplyInBot - Флаг, указывающий, разрешены ли ответы в боте.
   * @property {boolean} sendContact - Флаг, указывающий, разрешено ли отправлять контактные данные.
   * @property {string} sendFromTime - Время начала отправки сообщений или '00:00', если расписание не указано.
   * @property {string} sendToTime - Время окончания отправки сообщений или '00:00', если расписание не указано.
   */
  mapResponseToState: (data: TGetSettingResponseData) => TSettingsData;
  /**
   * Функция преобразует данные настроек в формат, подходящий для отправки на сервер.
   *
   * @param {Object} settings - Объект с данными настроек.
   * @return {Object} - Объект с данными настроек, готовый для отправки на сервер.
   * @property {Array} exception_masters_names - Массив исключений для мастеров.
   * @property {boolean} phones_dont_show_in_bot - Флаг, указывающий, показывать ли номера телефонов в боте.
   * @property {string} sms_provider - Провайдер SMS или пустая строка, если SMS отключены.
   * @property {string} sms_apikey - Ключ API для отправки SMS.
   * @property {string} sms_sendername - Имя отправителя SMS.
   * @property {string} sms_login - Логин для авторизации при отправке SMS или пустая строка.
   * @property {string} sms_pass - Пароль для авторизации при отправке SMS или пустая строка.
   * @property {string} phone - Телефон.
   * @property {Object} bill_data - Данные для счета.
   * @property {string} bill_data.inn - ИНН.
   * @property {string} bill_data.name - Имя.
   * @property {string} bill_data.kpp - КПП.
   * @property {number} reply_msg_check - Флаг, указывающий, делать ли проверку сообщений-ответов (0 - нет, 1 - да).
   * @property {boolean} sms_confirm - Флаг, указывающий, требуется ли подтверждение при отправке SMS.
   * @property {number} enable_reply_in_bot - Флаг, указывающий, разрешены ли ответы в боте (0 - нет, 1 - да).
   * @property {boolean} send_contact - Флаг, указывающий, разрешено ли отправлять контактные данные.
   * @property {string} message_sending_schedule - Массив с расписанием времени отправки сообщений.
   * @property {string} message_sending_schedule[0] - Время начала отправки сообщений.
   * @property {string} message_sending_schedule[1] - Время окончания отправки сообщений.
   */
  getDataToPostSettings: (data: TSettingsData) => TGetSettingResponseData;
  /**
   * Превращает объект в массив
   * @param data {TGetPriorityChannelResponseData}
   * @return {TChannel[]}
   */
  mapChannelPriorityToStore: (data: TGetPriorityChannelResponseData) => TChannel[];
  /**
   * Превращает массив в объект
   * @param data {TChannel[]}
   * @return {TGetPriorityChannelResponseData}
   */
  mapChannelPriorityToBack: (data: TChannel[]) => TGetPriorityChannelResponseData;
}

export const settingsMappers: ISettingsMappers = {
  getMessageSendingScheduleTime({ sendFromTime, sendToTime }) {
    return sendFromTime === Midnight && sendToTime === Midnight ? null : [sendFromTime, sendToTime];
  },
  mapResponseToState({
    exception_masters_names,
    phones_dont_show_in_bot,
    sms_provider,
    sms_apikey,
    sms_sendername,
    sms_login,
    sms_pass,
    phone,
    bill_data,
    reply_msg_check,
    sms_confirm,
    enable_reply_in_bot,
    send_contact,
    disable_chat,
    message_sending_schedule,
  }) {
    const isSmsPassToApiKey =
      sms_provider === PROVIDER_NAMES.INTISTELECOM || sms_provider === PROVIDER_NAMES.NOTISEND;

    return {
      exceptionMastersNames: exception_masters_names.map(item => String(item)),
      phonesDontShowInBot: phones_dont_show_in_bot,
      smsProvider: sms_provider || '',
      smsApikey: sms_provider ? (isSmsPassToApiKey ? sms_pass || '' : sms_apikey || '') : '',
      smsSenderName: sms_sendername || '',
      smsLogin: sms_login || '',
      smsPass: sms_pass || '',
      phone: phone || '',
      billData: {
        inn: bill_data?.inn || '',
        name: bill_data?.name || '',
        kpp: bill_data?.kpp || '',
      },
      replyMsgCheck: !!reply_msg_check,
      savePhonesYcl: false,
      smsConfirm: sms_confirm,
      enableReplyInBot: !!enable_reply_in_bot,
      sendContact: send_contact,
      sendFromTime: message_sending_schedule
        ? message_sending_schedule[0] && message_sending_schedule[1]
          ? message_sending_schedule[0]
          : Midnight
        : Midnight,
      sendToTime: message_sending_schedule?.length
        ? message_sending_schedule[1] && message_sending_schedule[1]
          ? message_sending_schedule[1]
          : Midnight
        : Midnight,
      disableChat: !!disable_chat,
    };
  },
  getDataToPostSettings({
    exceptionMastersNames,
    phonesDontShowInBot,
    smsProvider,
    smsApikey,
    smsSenderName,
    smsLogin,
    smsPass,
    phone,
    billData,
    replyMsgCheck,
    smsConfirm,
    enableReplyInBot,
    sendContact,
    sendFromTime,
    sendToTime,
    disableChat,
    phonesDontShowInBotNewValue,
  }: TSettingsData): TGetSettingResponseData {
    const newPhonesDontShowInBotNewValue = String(phonesDontShowInBotNewValue || '');

    const isSmsProviderDisabled =
      smsProvider === SMS_OPERATOR_NAMES.TURN_OFF ||
      smsProvider === SMS_OPERATOR_NAMES_ENG.TURN_OFF ||
      smsProvider === '';

    const isSaveToken = TOKEN_REQUIRED.includes(smsProvider);
    const isSaveLogin = LOGIN_REQUIRED.includes(smsProvider);
    const iSavePass = PASSWORD_REQUIRED.includes(smsProvider);
    const isSaveSenderName = SENDER_NAME_REQUIRED.includes(smsProvider);

    const IsSmsAPiKeyToPass =
      smsProvider === PROVIDER_NAMES.INTISTELECOM || smsProvider === PROVIDER_NAMES.NOTISEND;

    return {
      exception_masters_names: exceptionMastersNames,
      phones_dont_show_in_bot: newPhonesDontShowInBotNewValue
        ? [...phonesDontShowInBot, newPhonesDontShowInBotNewValue]
        : phonesDontShowInBot,
      sms_provider: isSmsProviderDisabled ? null : smsProvider,
      sms_apikey: isSmsProviderDisabled ? null : isSaveToken ? smsApikey : null,
      sms_sendername: isSmsProviderDisabled ? null : isSaveSenderName ? smsSenderName : null,
      sms_login: isSmsProviderDisabled ? null : isSaveLogin ? smsLogin : null,
      sms_pass: isSmsProviderDisabled
        ? null
        : IsSmsAPiKeyToPass
        ? smsApikey
        : iSavePass
        ? smsPass
        : null,
      bill_data: {
        inn: billData.inn,
        name: billData.name,
        kpp: billData.kpp,
      },
      reply_msg_check: replyMsgCheck ? 1 : 0,
      save_phones_ycl: false,
      sms_confirm: smsConfirm,
      enable_reply_in_bot: enableReplyInBot ? 1 : 0,
      send_contact: sendContact,
      phone,
      message_sending_schedule: this.getMessageSendingScheduleTime({
        sendFromTime,
        sendToTime,
      }),
      disable_chat: disableChat,
    };
  },
  mapChannelPriorityToStore(data: TGetPriorityChannelResponseData): TChannel[] {
    return Object.values(data).filter(Boolean);
  },
  mapChannelPriorityToBack(data) {
    return Object.fromEntries(data.map((channel, index) => [String(index), channel || '']));
  },
};
