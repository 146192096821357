import { TSettingsBillData } from '@const/settings';
import { TSettingsData } from '@redux/settings/type';

export const emptyBillData: TSettingsBillData = {
  inn: '',
  name: '',
  kpp: '',
};

export const emptySettingsData: TSettingsData = {
  replyMsgCheck: false,
  savePhonesYcl: false,
  enableReplyInBot: false,
  smsConfirm: false,
  exceptionMastersNames: [],
  phonesDontShowInBot: [],
  smsSenderName: '',
  smsApikey: '',
  smsProvider: 'отключено',
  smsLogin: '',
  smsPass: '',
  billData: emptyBillData,
  sendContact: false,
  sendFromTime: '',
  sendToTime: '',
  phone: '',
  disableChat: false,
  channelPriorityData: [],
};
