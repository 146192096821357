import { apiService } from '@api/ApiService';
import { WHEN_SEND_VALUE } from '@const/templates';
import { TCopyTemplateData, TSaveTemplateData } from '@redux/template/models';
import { CATEGORIES_EP_NAME, END_POINTS_URL, QUERY_PARAMS } from '@const/apiConstants';
import {
  TTemplateData,
  TRequestStatus,
  TStaffListItem,
  TTemplateListData,
  TTemplateOrderType,
  TSaveWrTemplateData,
  TGetResponseWithData,
  TTemplateRequestData,
  TResponseWithOkAndData,
  ITemplateOperationData,
  TTemplateListRequestData,
  TGetResponseWithDataAndOrder,
} from '@api/types';

/**
 * API шаблонов
 * @module templateApi
 */

export const templateApi = {
  /**
   * Метод запрашивает список шаблонов
   * @method getTemplateListData
   * @param data {TTemplateListRequestData}
   * @return {Promise<AxiosResponse<TGetResponseWithDataAndOrder<TTemplateListData[]> | TRequestStatus, any>>}
   */
  getTemplateListData(data: TTemplateListRequestData) {
    return apiService.get<TGetResponseWithDataAndOrder<TTemplateListData[]> | TRequestStatus>(
      END_POINTS_URL.TEMPLATE_LIST,
      {
        [QUERY_PARAMS.TIME_TEMPLATE]: data.tm,
        [QUERY_PARAMS.EVENT_TEMPLATE]: data.em,
      },
    );
  },
  /**
   * Метод сохраняет порядок шаблонов в списке
   * @method getTemplateListData
   * @param {string[]} data список id шаблонов в новом порядке
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  setTemplateOrder({
    order,
    templatesType,
  }: {
    order: string[];
    templatesType: TTemplateOrderType;
  }) {
    return apiService.post<TRequestStatus>(END_POINTS_URL.SET_TEMPLATES_ORDER, order, {
      params: {
        [QUERY_PARAMS.TEMPLATES_TYPE]: templatesType,
      },
    });
  },
  /**
   * Метод Запрашивает данные одного шаблона по id
   * @method getTemplateById
   * @param data {TTemplateRequestData}
   * @return {Promise<AxiosResponse<TRequestStatus | TTemplateData, any>>}
   */
  getTemplateById(data: TTemplateRequestData) {
    return apiService.get<TTemplateData | TRequestStatus>(END_POINTS_URL.TEMPLATE, {
      [QUERY_PARAMS.TEMPLATE_ID]: data.id,
    });
  },
  /**
   * Метод переключает активность шаблона по id
   * @method switchTemplateById
   * @param data {ITemplateOperationData}
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  switchTemplateById(data: ITemplateOperationData) {
    return apiService.post<TRequestStatus>(
      END_POINTS_URL.SWITCH_TEMPLATE,
      {},
      {
        params: {
          [QUERY_PARAMS.TEMPLATE_ID]: data.id,
          [QUERY_PARAMS.STATE]: data.state,
          [QUERY_PARAMS.TIME_TEMPLATE]: data.tm,
          [QUERY_PARAMS.EVENT_TEMPLATE]: data.em,
        },
      },
    );
  },
  /**
   * Метод удаляет шаблон по id
   * @method deleteTemplateById
   * @param data {ITemplateOperationData}
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  deleteTemplateById(data: ITemplateOperationData) {
    return apiService.post<TRequestStatus>(
      END_POINTS_URL.DELETE_TEMPLATE,
      {},
      {
        params: {
          [QUERY_PARAMS.TEMPLATE_ID]: data.id,
          [QUERY_PARAMS.TIME_TEMPLATE]: data.tm,
          [QUERY_PARAMS.EVENT_TEMPLATE]: data.em,
        },
      },
    );
  },
  /**
   * Метод создает дубль шаблона по id
   * @method duplicateTemplateById
   * @param id {string}
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  duplicateTemplateById(id: string) {
    return apiService.post<TRequestStatus>(
      END_POINTS_URL.DUPLICATE_TEMPLATE,
      {},
      {
        params: {
          [QUERY_PARAMS.TEMPLATE_ID]: id.slice(1),
        },
      },
    );
  },
  /**
   * Метод сохраняет изменения в шаблоне или создает новый шаблон используется для шаблонов по времени и по событиям
   * @method saveTemplateEmTm
   * @param data {TSaveTemplateData}
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  saveTemplateEmTm(data: TSaveTemplateData) {
    const isTimeTemplate = !(
      data.templateData.whenSend === WHEN_SEND_VALUE.EVENT ||
      data.templateData.whenSend === WHEN_SEND_VALUE.CONSENT
    );
    const isEventTemplate =
      data.templateData.whenSend === WHEN_SEND_VALUE.EVENT ||
      data.templateData.whenSend === WHEN_SEND_VALUE.CONSENT;

    return apiService.post<TTemplateData | TRequestStatus>(
      END_POINTS_URL.SAVE_TEMPLATE,
      data.formData,
      {
        params: {
          // [QUERY_PARAMS.USER_ID]: data.userId,
          // [QUERY_PARAMS.ACC_ID]: data.accId,
          [QUERY_PARAMS.TIME_TEMPLATE]: isTimeTemplate,
          [QUERY_PARAMS.EVENT_TEMPLATE]: isEventTemplate,
          [QUERY_PARAMS.NEW_TEMPLATE]: !data.templateData.id,
        },
      },
    );
  },
  /**
   * Метод создает копии указааного щаблона в перечисленных филиалах
   * @method copyTemplateToBranch
   * @param data {TCopyTemplateData}
   * @return {Promise<AxiosResponse<TRequestStatus | TResponseWithOkAndData<TTemplateData>, any>>}
   */
  copyTemplateToBranch(data: TCopyTemplateData) {
    return apiService.post<TResponseWithOkAndData<TTemplateData> | TRequestStatus>(
      END_POINTS_URL.COPY_TEMPLATE_TO_BRANCHES,
      data.branchList,
      {
        params: {
          [QUERY_PARAMS.TEMPLATE_ID]: data.templateId,
        },
      },
    );
  },
  /**
   * Метод сохраняет изменения в шаблоне или создает новый шаблон используется для шаблонов потеряшек
   * @method saveTemplateEmTm
   * @param data {TSaveWrTemplateData}
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  saveTemplateWr(data: TSaveWrTemplateData) {
    return apiService.post<TRequestStatus>(END_POINTS_URL.SAVE_TEMPLATE, data.data);
  },
  /**
   * Метод запрашивает список категорий для отображения выпадающего списка
   * @method getCategoryList
   * @param data {string}
   * @return Promise<AxiosResponse<TRequestStatus | TGetResponseWithData<TStaffListItem[]>, any>>
   */
  getCategoryList(data: string) {
    return apiService.get<TGetResponseWithData<TStaffListItem[]> | TRequestStatus>(
      CATEGORIES_EP_NAME[data],
    );
  },
};
