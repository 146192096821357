import React, { memo, useCallback } from 'react';
//
import { WhenSend } from '@blocks/whenSend';
import { TAppLanguage } from '@models/index';
import { useAppDispatch, useAppSelector } from '@store/store';
import { TOnChangeHandler, TSetWhenSend2Days } from '@shared/types';
import { getInviteFriendsActionData } from '@redux/inviteFriendsAction/selectors';
import {
  setWhenSendIF,
  setWhenSend2IF,
  setWhenTimeIF,
  setWhenSend2MinIF,
  setWhenSend2DaysIF,
  roundedWhenSend2MinutesIF,
} from '@redux/inviteFriendsAction/inviteFriendSlice';

type TInviteFriendsWhenSendProps = {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Опциональны параметр доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess?: boolean;
  /**
   * Id шаблона
   * @param {string}
   */
  id: string;
  /**
   * Значение дропдауна whenSend
   * @param {string}
   */
  whenSend: string;
  /**
   * Значение дропдауна whenSend2
   * @param {string}
   */
  whenSend2: string;
  /**
   * Значение timePicker whenTime в формате HH:MM
   * @param {string}
   */
  whenTime: string;
  /**
   * Через сколько дней отправлять шаблон
   * @param {string}
   */
  whenSend2Days: string;
  /**
   * Через сколько минут отправлять шаблон
   * @param {string}
   */
  whenSend2Min: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const InviteFriendsWhenSend = memo(
  ({
    id,
    whenSend,
    whenTime,
    whenSend2,
    appLanguage,
    whenSend2Min,
    whenSend2Days,
    isEditTemplatesAccess,
    className = '',
  }: TInviteFriendsWhenSendProps) => {
    const dispatch = useAppDispatch();

    const { whenSendSelectOption, whenSend2SelectOption } = useAppSelector(
      getInviteFriendsActionData,
    );

    // Устанавливает когда отправлять шаблон
    const setWhenSendHandler = useCallback(
      (value: string) => () => {
        dispatch(setWhenSendIF(value));
      },
      [dispatch],
    );

    // Устанавливает время отправки шаблона
    const setWhenTimeHandler = useCallback(
      (value: string) => {
        dispatch(setWhenTimeIF(value));
      },
      [dispatch],
    );

    // Устанавливает когда отправлять шаблон
    const setWhenSend2Handler = useCallback(
      (value: string) => () => {
        dispatch(setWhenSend2IF(value));
      },
      [dispatch],
    );

    // Устанавливает дни в блоке настройки отправки шаблона
    const setWhenSend2DaysHandler: TSetWhenSend2Days = useCallback(
      ({ maxValue, minValue }) =>
        event => {
          const { value } = event.currentTarget;
          dispatch(setWhenSend2DaysIF({ value, minValue, maxValue }));
        },
      [dispatch],
    );

    // Устанавливает минуты в блоке настройки отправки шаблона
    const setWhenSend2MinHandler = useCallback(
      (minutes: string) => () => {
        dispatch(setWhenSend2MinIF(minutes));
      },
      [dispatch],
    );

    // Устанавливает минуты в блоке настройки отправки шаблона
    const setWhenSend2MinOnChangeHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;

        dispatch(setWhenSend2MinIF(value));
      },
      [dispatch],
    );

    // Округляет минуты при потери фокуса на поле ввода минут
    const roundedMinutes = useCallback(() => {
      dispatch(roundedWhenSend2MinutesIF());
    }, [dispatch]);

    return (
      <div className={className}>
        <WhenSend
          isRequired
          whenSend={whenSend}
          whenTime={whenTime}
          isNewTemplate={!!id}
          whenSend2={whenSend2}
          labelTextColor='!text-black'
          whenSend2Days={whenSend2Days}
          whenSend2Minutes={whenSend2Min}
          onBlurCallback={roundedMinutes}
          setWhenSend={setWhenSendHandler}
          requiredSignColor='text-grayText'
          setWhenTime={setWhenTimeHandler}
          disabled={!isEditTemplatesAccess}
          setWhenSend2={setWhenSend2Handler}
          labelTextVariant='inviteFriendsWhenSend'
          setWhenSend2Days={setWhenSend2DaysHandler}
          setWhenSend2MinutesHandler={setWhenSend2MinHandler}
          whenSendSelectOption={whenSendSelectOption[appLanguage]}
          whenSend2SelectOption={whenSend2SelectOption[appLanguage]}
          setWhenSend2MinutesOnChangeHandler={setWhenSend2MinOnChangeHandler}
        />
      </div>
    );
  },
);

InviteFriendsWhenSend.displayName = 'InviteFriendsWhenSend';
