import { createAsyncThunk } from '@reduxjs/toolkit';
//
import { TRejectResponseData } from '@models/index';
import { TStartPageAutoConnectionData } from '@api/types';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { yclientsStartPageApi } from '@api/yclientsStartPageApi';
import { TYclientsAutoConnectRequestData } from '@redux/yclients/type';

import { showSuccessPage } from './yclientsSlice';

/**
 * Thunk-экшен для отправки данных для связи с клиентом.
 *
 * @returns {TTemplatesStatisticsData[] | void} - Массив данных статистики шаблонов или void в случае неудачи.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект, представляющий информацию об ошибке в случае неудачного запроса.
 */
export const yclientsStartPageThunk = createAsyncThunk<
  void,
  TYclientsAutoConnectRequestData,
  { rejectValue: TRejectResponseData }
>('yclients/yclientsStartPageThunk', (data, { dispatch, rejectWithValue }) => {
  const requestData: TStartPageAutoConnectionData = {
    communication_method: data.isPlaceToContactWhatsApp ? 'whatsapp' : 'phone',
    salon_id: data.branchId,
    user_name: data.yclientsName,
    phone: data.yclientsPhone,
    lead_id: data.leadId,
  };

  return yclientsStartPageApi
    .postAutoConnectionData(requestData)
    .then(() => {
      dispatch(showSuccessPage());
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(yclientsStartPageThunk(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});
