import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
//
import { PATH_NAMES } from '@data/dict';
import { Loader } from '@blocks/loader';
import { CardList } from '@components/cardList';
import { NoAccess } from '@components/noAccess';
import { TTemplateAction } from '@shared/types';
import { myTrackerApi } from '@api/myTrackerApi';
import { getAppData } from '@redux/app/selectors';
import { TemplateNav } from '@blocks/templateNav';
import { VIDEO_INSTRUCTIONS } from '@const/common';
import { TemplateStat } from '@pages/templateStat';
import { QUERY_PARAMS } from '@const/apiConstants';
import { ErrorMessage } from '@blocks/errorMessage';
import { getCommonEventData } from '@helpers/index';
import { ROUTES_ACCESS_RIGHTS } from '@const/access';
import { ReviewsList } from '@templates/reviewsList';
import { MY_TRACKER_EVENTS } from '@helpers/myTracker';
import { TemplateComponent } from '@templates/template';
import { getAccountsData } from '@redux/accounts/selectors';
import { ToastsMessages } from '@components/toastsMessages';
import { useAppDispatch, useAppSelector } from '@store/store';
import { clearChainErrors } from '@redux/reviews/reviewsSlice';
import { HeaderWithButton } from '@components/headerWithButton';
import { getTemplatePageData } from '@redux/template/selectors';
import { consentTemplateMockData, emptyTemplateMockData } from '@redux/template/mockData';
import { TemplatesStatsList } from '@templates/templatesStatsList';
import { getTemplateListPageData } from '@redux/templateList/selectors';
import { getTemplatesStatisticsPageData } from '@redux/templateStats/selectors';
import { getTemplatesStatisticsData } from '@redux/templateStats/templateStatsThunks';
import { clearTemplateStatisticsState } from '@redux/templateStats/templateStatsSlice';
import { clearTemplateState, setTemplatePageData } from '@redux/template/templateSlice';
import {
  clearErrorsTL,
  clearSuccessStatuses,
  setTemplateIdInProgress,
} from '@redux/templateList/templateListSlice';
import {
  deleteTemplate,
  switchTemplate,
  getTemplateList,
  duplicateTemplate,
} from '@redux/templateList/templateListThunks';

type TTemplateListProps = {
  /**
   * Флаг шаблонов по событиям
   * @param {boolean}
   */
  isEm: boolean;
  /**
   * Флаг шаблонов по времени
   * @param {boolean}
   */
  isTm: boolean;
};

const TemplateListPage = memo(({ isEm, isTm }: TTemplateListProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { appLanguage } = useAppSelector(getAppData);
  const isConfirmTemplate = searchParams.get(QUERY_PARAMS.IS_CONSENT_TEMPLATE) === 'true';

  // получаем объект selectedFilial
  const { selectedFilial } = useAppSelector(getAccountsData);

  const selectedFilialAccId = selectedFilial?.accId || '';

  const { templates: templatesStatistics, isFetching: isLoadingTemplatesStatistics } =
    useAppSelector(getTemplatesStatisticsPageData);

  const isReviewsList = path.includes(PATH_NAMES.REVIEWS);
  const isTemplateStat = path.includes(PATH_NAMES.TEMPLATE_STAT);
  const isNewTemplatePage = path.includes(PATH_NAMES.NEW_TEMPLATE);
  const isTemplatesListStatistics = path.includes(PATH_NAMES.TEMPLATE_LIST_STATISTICS);

  // забираем данные из store
  const {
    data,
    isError,
    isLoading,
    isFetching,
    errorMessage,
    isCopingError,
    successMessage,
    isDeletingError,
    isCopingSuccess,
    templateInfoObj,
    isDeletingSuccess,
    templateListOrder,
    isTemplateSwitchingError,
    isTemplateListAccessDenied,
  } = useAppSelector(getTemplateListPageData);

  const {
    templateDetailFilter: { dateFrom, dateTo },
  } = useAppSelector(getTemplatesStatisticsPageData);

  const {
    data: newTemplateData,
    isError: newTemplateIsError,
    isAccessDenied: newTemplateIsAccessDenied,
  } = useAppSelector(getTemplatePageData);

  const { isFetching: statisticsPageFetching, isError: statisticsPageError } = useAppSelector(
    getTemplatesStatisticsPageData,
  );

  // Получаем массив прав если он есть и проверяем наличие необходимых прав
  const rights = selectedFilial?.rights;
  const isEditTemplatesAccess =
    rights?.includes(ROUTES_ACCESS_RIGHTS.EDIT_TEMPLATES) ||
    rights?.includes(ROUTES_ACCESS_RIGHTS.OWNER) ||
    false;

  // Устанавливает пустые данные шаблона при загрузке компонента.
  useEffect(() => {
    dispatch(clearErrorsTL());
    dispatch(clearChainErrors());
    dispatch(clearTemplateState());
  }, [dispatch]);

  // Устанавливает пустые данные шаблона при загрузке компонента на странице нового шаблона.
  useEffect(() => {
    if (isNewTemplatePage) {
      if (isConfirmTemplate) {
        dispatch(setTemplatePageData({ data: consentTemplateMockData }));
      } else {
        dispatch(setTemplatePageData({ data: emptyTemplateMockData }));
      }
    }
  }, [isNewTemplatePage, isConfirmTemplate, dispatch]);

  // Запрашивает список шаблонов при загрузке страницы, в зависимости от заданных флагов.
  useEffect(() => {
    if (!isTm && !isEm) return;

    if (selectedFilial?.accId && !isNewTemplatePage) {
      dispatch(getTemplateList({ em: isEm, tm: isTm, isSendEvent: true }));
    }
  }, [dispatch, isEm, isTm, selectedFilial?.accId, isReviewsList, isNewTemplatePage]);

  // Запрашивает список шаблонов при удалении шаблона из списка.
  useEffect(() => {
    if (isFetching) {
      dispatch(getTemplateList({ em: isEm, tm: isTm, isSendEvent: false }));
    }
  }, [dispatch, isEm, isTm, isFetching, isReviewsList]);

  useEffect(() => {
    if (isTemplatesListStatistics) {
      dispatch(clearTemplateStatisticsState());
      dispatch(getTemplatesStatisticsData({ date_from: dateFrom, date_to: dateTo }));
    }
  }, [dispatch, isTemplatesListStatistics, dateFrom, dateTo]);

  // Функция для переключения активности шаблона. Принимает ID шаблона, текущее состояние, и флаги шаблона.
  const switchTask: TTemplateAction = useCallback(
    ({ id, state, tm, em }) =>
      () => {
        dispatch(setTemplateIdInProgress(id));
        dispatch(switchTemplate({ id: id.slice(1), state: !state, tm, em }));
      },
    [dispatch],
  );

  // Функция для удаления шаблона по ID.
  const deleteTemplateHandler: TTemplateAction = useCallback(
    ({ id, state, tm, em }) =>
      () => {
        dispatch(setTemplateIdInProgress(id));
        dispatch(deleteTemplate({ id: id.slice(1), state: !state, tm, em }));
      },
    [dispatch],
  );

  // Функция для копирования шаблона по ID.
  const duplicateTemplateHandler = useCallback(
    (id: string) => {
      dispatch(duplicateTemplate(id));
    },
    [dispatch],
  );

  // Делает редирект на страницу нового шаблона.
  const addTemplate = useCallback(async () => {
    await myTrackerApi.openNewTemplate();

    navigate(`${PATH_NAMES.NEW_TEMPLATE}?${QUERY_PARAMS.ACC_ID}=${selectedFilial?.accId}`);
  }, [navigate, selectedFilial?.accId]);

  // делает редирект на страницу создания цепочки отзывов
  const addReview = useCallback(async () => {
    await myTrackerApi.openNewReview(getCommonEventData(MY_TRACKER_EVENTS.OPEN_NEW_REVIEW_PAGE));

    navigate(`${PATH_NAMES.NEW_REVIEW}?${QUERY_PARAMS.ACC_ID}=${selectedFilial?.accId}`);
  }, [navigate, selectedFilial?.accId]);

  // Callback для компонента ToastsMessages, очищает в state флаги ошибок
  const clearAllErrorHandler = useCallback(() => {
    dispatch(clearErrorsTL());
  }, [dispatch]);

  // Callback для компонента ToastsMessages, очищает в state флаги успешных действий
  const clearAllSuccessStatusesHandler = useCallback(() => {
    dispatch(clearSuccessStatuses());
  }, [dispatch]);

  const showLoader = isLoading || isLoadingTemplatesStatistics || statisticsPageFetching;

  const showError = isError || newTemplateIsError || statisticsPageError;

  const accessDenied = isTemplateListAccessDenied || newTemplateIsAccessDenied;

  const isShowSuccessToast = isDeletingSuccess || isCopingSuccess;

  const isShowErrorToast = isDeletingError || isTemplateSwitchingError || isCopingError;

  return (
    <>
      {showError ? <ErrorMessage /> : null}
      {accessDenied ? <NoAccess /> : null}
      {!showError && !accessDenied ? (
        <>
          {isNewTemplatePage ? (
            <TemplateComponent
              isNewTemplate
              isLoading={isLoading}
              data={newTemplateData}
              selectedFilialAccId={selectedFilialAccId}
              isEditTemplatesAccess={isEditTemplatesAccess}
              className='px-4 sm:px-2 lg:px-0'
            />
          ) : (
            <div className='md:px-2 lg:px-0'>
              <HeaderWithButton
                callBack={addTemplate}
                instructionData={VIDEO_INSTRUCTIONS.templates}
                headerText={t('TEMPLATE_LIST_PAGE_TEXT.header')}
                buttonText={t('TEMPLATE_LIST_PAGE_TEXT.headerButton')}
                className='px-4 sm:px-0 mb-10 mt-0'
              />
              <TemplateNav
                path={path}
                isDisable={false}
                callBack={addTemplate}
                isReviewsList={isReviewsList}
                addReviewCallback={addReview}
                selectedFilialAccId={selectedFilialAccId}
                isEditTemplatesAccess={isEditTemplatesAccess}
                className='px-4 sm:px-0 mb-6'
              />
              {!isReviewsList && !isLoading ? (
                <CardList
                  data={data}
                  isEm={isEm}
                  isTm={isTm}
                  isFetching={isFetching}
                  switchTask={switchTask}
                  appLanguage={appLanguage}
                  templateInfoObj={templateInfoObj}
                  templateOrder={templateListOrder}
                  deleteTemplate={deleteTemplateHandler}
                  duplicateTemplate={duplicateTemplateHandler}
                  isEditTemplatesAccess={isEditTemplatesAccess}
                />
              ) : null}
              {isTemplatesListStatistics && !isLoadingTemplatesStatistics ? (
                <TemplatesStatsList data={templatesStatistics} />
              ) : null}
              {isTemplateStat ? <TemplateStat /> : null}
              {showLoader ? <Loader className='h-[50vh]' /> : null}
            </div>
          )}
          <ToastsMessages
            errorMessage={errorMessage}
            successMessage={successMessage}
            isShowErrorToast={isShowErrorToast}
            isShowSuccessToast={isShowSuccessToast}
            clearErrorCallback={clearAllErrorHandler}
            clearSuccessStatusCallback={clearAllSuccessStatusesHandler}
          />
        </>
      ) : null}
    </>
  );
});

TemplateListPage.displayName = 'TemplateListPage';

export default TemplateListPage;
