import React, { memo } from 'react';
//
import { Label } from '@atoms/label';
import { THelpKeys } from '@models/index';
import { IconWithTooltips } from '@components/iconWithTooltips';

interface IFormLabelProps {
  /**
   * Значение параметра htmlFor в label
   * @param {string}
   */
  htmlFor: string;
  /**
   * Опциональный параметр отображает обучающую подсказку
   * @param {boolean}
   */
  isFirstTooltip?: boolean;
  /**
   * Опциональный параметр показывает или скрывает иконку с всплывающими подсказками
   * @param {boolean}
   * @default
   */
  showInfo?: boolean;
  /**
   * Опциональный параметр tailwind класс для изменения цвета текста label
   * @param {string}
   * @default
   */
  textColor?: string;
  /**
   * Опциональный параметр tailwind класс для изменения размера текста label
   * @param {string}
   * @default
   */
  fontSize?: string;
  /**
   * Опциональный параметр добавляет к тексту label знак *
   * @param {boolean}
   * @default
   */
  isRequired?: boolean;
  /**
   * Опциональный параметр tailwind класс для изменения цвет знака *
   * @param {string}
   * @default
   */
  requiredSignColor?: string;
  /**
   * Вариант текста в label, берется по ключу из словаря
   * @param {THelpKeys}
   */
  labelTextVariant: THelpKeys;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const FormLabel = memo(
  ({
    htmlFor,
    isRequired,
    fontSize = '',
    textColor = '',
    isFirstTooltip,
    showInfo = true,
    labelTextVariant,
    requiredSignColor = 'text-statusRed',
    className = '',
  }: IFormLabelProps) => (
    <div className={`flex items-center ${className}`}>
      <Label
        htmlFor={htmlFor}
        variant={labelTextVariant}
        className={`${textColor} ${fontSize} mr-2`}
      />
      {isRequired ? <span className={`${requiredSignColor} text-base`}>*</span> : null}
      {showInfo ? (
        <IconWithTooltips
          tooltips={labelTextVariant}
          isFirstTooltip={isFirstTooltip}
          className={isRequired ? 'ml-2' : ''}
        />
      ) : null}
    </div>
  ),
);

FormLabel.displayName = 'FormLabel';
