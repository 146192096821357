import { TChannels } from '@api/types';
import { emptyCalculatedPrice, payPageEmptyData } from '@redux/pay/mockData';
import { TPayData, TInvoiceData, TPaymentData, TCalculatePrice } from '@redux/pay/types';

export interface IInitialState {
  isGetNewCalculatingPrice: boolean;
  isLoading: boolean;
  isError: boolean;
  isChangeModalOpen: boolean;
  isConnectAutoPayModalOpen: boolean;
  isAutoPayDisableModalOpen: boolean;
  isBillDownloadModalOpen: boolean;
  autoPayLink: string;
  isFetchingAutoPayLink: boolean;
  isFetchingAutoPayLinkError: boolean;
  isFetchingAutoPayLinkSuccess: boolean;
  changeCardDataLink: string;
  changeCardIsLoading: boolean;
  changeCardIsError: boolean;
  changeCardIsSuccess: boolean;
  turnOffAutoPaymentIsLoading: boolean;
  turnOffAutoPaymentIsError: boolean;
  turnOffAutoPaymentIsSuccess: boolean;
  isAccessOnPayPageDenied: boolean;
  isRetries: boolean;
  errorMessage: string;
  selectedCardNumber: number;
  newCardNumber: number;
  selectedCardTotalAmount: number;
  selectedCardMonthAmount: number;
  isFetchingInvoiceLink: boolean;
  isFetchingInvoiceLinkError: boolean;
  isSendingInvoiceToTgError: boolean;
  isSendingInvoiceToTg: boolean;
  isCalculatingCoast: boolean;
  invoiceData: TInvoiceData | null;
  filteredData: TPaymentData[];
  mobileTableData: TPaymentData[];
  mobilePage: number;
  successMessage: string;
  promoCode: string;
  payMonthQuantity: string;
  selectedModules: TChannels[];
  calculatedPrice: TCalculatePrice;
  isGettingPayLink: boolean;
  payTariffDays: boolean;
  data: TPayData;
}

export const initialState: IInitialState = {
  isGetNewCalculatingPrice: false,
  isLoading: false,
  isError: false,
  isChangeModalOpen: false,
  isConnectAutoPayModalOpen: false,
  isAutoPayDisableModalOpen: false,
  isBillDownloadModalOpen: false,
  autoPayLink: '',
  isFetchingAutoPayLink: false,
  isFetchingAutoPayLinkError: false,
  isFetchingAutoPayLinkSuccess: false,
  changeCardDataLink: '',
  changeCardIsLoading: false,
  changeCardIsError: false,
  changeCardIsSuccess: false,
  turnOffAutoPaymentIsLoading: false,
  turnOffAutoPaymentIsError: false,
  turnOffAutoPaymentIsSuccess: false,
  isAccessOnPayPageDenied: false,
  isRetries: false,
  errorMessage: '',
  filteredData: [],
  newCardNumber: 0,
  selectedCardNumber: 0,
  selectedCardTotalAmount: 0,
  selectedCardMonthAmount: 0,
  isFetchingInvoiceLink: false,
  isFetchingInvoiceLinkError: false,
  isSendingInvoiceToTg: false,
  isSendingInvoiceToTgError: false,
  isCalculatingCoast: false,
  successMessage: '',
  invoiceData: null,
  promoCode: '',
  payMonthQuantity: '1',
  selectedModules: ['WA'],
  mobilePage: 1,
  mobileTableData: [],
  calculatedPrice: emptyCalculatedPrice,
  isGettingPayLink: false,
  payTariffDays: false,
  data: payPageEmptyData,
};
