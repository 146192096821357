import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//
import { SORTING_TYPES } from '@const/common';
import { TRejectResponseData } from '@models/index';
import { PAGINATION_ROWS_OPTIONS } from '@const/mailing';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { REJECT_RESPONSE_2_KEY, REJECT_RESPONSE_KEY } from '@api/types';
import { IInitialState, initialState } from '@redux/statistics/initialState';
import { SORT_BY_KEYS_NAME, TStatisticsResponse } from '@redux/statistics/models';
import {
  getStatisticTableData,
  getStatisticDataCountThunk,
} from '@redux/statistics/statisticsThunks';

export const statisticsSlice = createSlice({
  name: 'statisticsTable',
  initialState,
  reducers: {
    clearNumberOfRequestSP(state: IInitialState) {
      state.numberOfRequest = 0;
    },
    setTableSearchStringSP(state: IInitialState, action: PayloadAction<{ value: string }>) {
      const { value: searchString } = action.payload;
      state.searchString = searchString;
      state.currentPage = 0;
    },
    clearSearchStringSP(state: IInitialState) {
      state.searchString = '';
    },
    setSortTypeDataSP(state: IInitialState, action: PayloadAction<{ columnName: string }>) {
      const { columnName } = action.payload;

      state.sortedBy = columnName;
    },
    setSortDirectionSP(state: IInitialState, action: PayloadAction<string>) {
      state.sortDirection =
        action.payload === SORTING_TYPES.ASCENDING
          ? SORTING_TYPES.DESCENDING
          : SORTING_TYPES.ASCENDING;
    },
    setSelectedPageSP(state: IInitialState, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    setRowsPerPageSP(state: IInitialState, action: PayloadAction<number>) {
      state.rowsPerPage = action.payload;
    },
    setDateRangeSP(
      state: IInitialState,
      action: PayloadAction<{ startDate: string; endDate: string }>,
    ) {
      const { startDate, endDate } = action.payload;
      state.startDate = startDate;
      state.endDate = endDate;
      state.currentPage = 0;
    },
    setSearchDataFromQueryParams(
      state: IInitialState,
      action: PayloadAction<Partial<Record<keyof IInitialState, string>>>,
    ) {
      const {
        searchString,
        endDate,
        startDate,
        sortedBy,
        rowsPerPage,
        sortDirection,
        currentPage,
      } = action.payload;

      if (searchString !== undefined && searchString !== state.searchString) {
        state.searchString = searchString;
      }
      if (endDate !== undefined && endDate !== state.endDate) {
        const newEndDate = new Date(endDate).toUTCString().slice();

        if (newEndDate !== 'Invalid Date') {
          state.endDate = endDate;
        }
      }
      if (startDate !== undefined && startDate !== state.startDate) {
        const newStartDate = new Date(startDate).toUTCString().slice();

        if (newStartDate !== 'Invalid Date') {
          state.startDate = startDate;
        }
      }
      if (sortedBy !== undefined && sortedBy !== state.sortedBy) {
        if (SORT_BY_KEYS_NAME.includes(sortedBy)) {
          state.sortedBy = sortedBy;
        }
      }
      if (rowsPerPage !== undefined) {
        const rowsPerPageNumber = Number(rowsPerPage);
        if (!Number.isNaN(rowsPerPageNumber) && rowsPerPageNumber !== state.rowsPerPage) {
          if (PAGINATION_ROWS_OPTIONS.includes(rowsPerPageNumber)) {
            state.rowsPerPage = rowsPerPageNumber;
          }
        }
      }
      if (sortDirection !== undefined && sortDirection !== state.sortDirection) {
        state.sortDirection = sortDirection;
      }
      if (currentPage !== undefined) {
        const currentPageNumber = Number(currentPage);
        if (!Number.isNaN(currentPageNumber) && currentPageNumber !== state.currentPage) {
          const totalPages = Math.ceil(state.totalCount / state.rowsPerPage);
          if (currentPageNumber <= totalPages) {
            state.currentPage = currentPageNumber;
          }
        }
      }
    },
    clearMailingStatisticsError(state: IInitialState) {
      state.isError = false;
      state.isAccessOnStatisticsPageDenied = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(getStatisticDataCountThunk.pending, (state: IInitialState) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(
      getStatisticDataCountThunk.fulfilled,
      (state: IInitialState, action: PayloadAction<number | void>) => {
        if (action.payload) {
          state.isSuccess = true;
          state.totalCount = action.payload;
        }
        state.isLoading = false;
      },
    );
    builder.addCase(
      getStatisticDataCountThunk.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | void>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
              state.isLoading = false;
            }
          } else if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessOnStatisticsPageDenied = true;
            } else {
              state.isError = true;
            }
            state.isLoading = false;
          } else {
            state.isError = true;
            state.isLoading = false;
          }
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      },
    );
    builder.addCase(getStatisticTableData.pending, (state: IInitialState) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = true;
    });
    builder.addCase(
      getStatisticTableData.fulfilled,
      (state: IInitialState, action: PayloadAction<TStatisticsResponse | void>) => {
        if (action.payload) {
          const { data } = action.payload;
          state.data = data;
          state.isSuccess = true;
          state.isLoading = false;
          state.isFetching = false;
        }
        state.isLoading = false;
      },
    );
    builder.addCase(
      getStatisticTableData.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isError = true;
              state.isLoading = false;
            }
          } else if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;
            if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
              state.isAccessOnStatisticsPageDenied = true;
            } else {
              state.isError = true;
            }
            state.isLoading = false;
          } else {
            state.isError = true;
            state.isLoading = false;
          }
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      },
    );
  },
});

export const statisticsReducer = statisticsSlice.reducer;
export const {
  setDateRangeSP,
  setRowsPerPageSP,
  setSelectedPageSP,
  setSortTypeDataSP,
  setSortDirectionSP,
  clearSearchStringSP,
  clearNumberOfRequestSP,
  setTableSearchStringSP,
  clearMailingStatisticsError,
  setSearchDataFromQueryParams,
} = statisticsSlice.actions;
