import React, { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
//
import { PATH_NAMES } from '@data/dict';
import { NoAccess } from '@components/noAccess';
import { getAccountsData } from '@redux/accounts/selectors';
import { getAddLeadsData } from '@redux/addLeads/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { PartnersTemplate } from '@templates/partnersTemplate';
import { getLeadDataThunk } from '@redux/addLeads/addLeadsThunk';
import { getPartnersFullTablePageData } from '@redux/partnersFullTable/selectors';
import { getOperationListTablePageData } from '@redux/operationListTable/selectors';
import { getConsultantFullTablePageData } from '@redux/consultantFullTable/selectors';
import { getPayHistorySalonTablePageData } from '@redux/payHistorySalonTable/selectors';
import { getPartnerFullTableData } from '@redux/partnersFullTable/partnerFullTableThunks';
import { getOperationListTableData } from '@redux/operationListTable/operationListTableThunks';
import { getConsultantFullTableData } from '@redux/consultantFullTable/consultantFullTableThunks';
import { getPayHistoryConsultantTablePageData } from '@redux/payHistoryConsultantTable/selectors';
import { getPayHistorySalonTableData } from '@redux/payHistorySalonTable/payHistorySalonTableThunks';
import { getPayHistoryConsultantTableData } from '@redux/payHistoryConsultantTable/payHistoryConsultantTableThunks';

const PartnersPage = memo(() => {
  const dispatch = useAppDispatch();

  const path = useLocation().pathname;

  const { isError, isAccessDenied } = useAppSelector(getPartnersFullTablePageData);

  const { selectedFilial, isSalon, consultantData, isSalonConsultant } =
    useAppSelector(getAccountsData);

  const { isError: consultantIsError, isAccessDenied: consultantIsIsAccessDenied } = useAppSelector(
    getConsultantFullTablePageData,
  );

  const { isError: operationIsError, isAccessDenied: operationIsAccessDenied } = useAppSelector(
    getOperationListTablePageData,
  );

  const { isError: partnersPayHistoryIsError, isAccessDenied: partnersPayHistoryIsAccessDenied } =
    useAppSelector(getPayHistorySalonTablePageData);

  const {
    isError: consultantPayHistoryIsError,
    isAccessDenied: consultantPayHistoryIsAccessDenied,
  } = useAppSelector(getPayHistoryConsultantTablePageData);

  const { isError: getLeadsDataError, isAccessDenied: getLeadsIsAccessDenied } =
    useAppSelector(getAddLeadsData);

  const selectFilialAccId = selectedFilial?.accId || '';

  const accessDenied =
    isAccessDenied ||
    getLeadsIsAccessDenied ||
    operationIsAccessDenied ||
    consultantIsIsAccessDenied ||
    partnersPayHistoryIsAccessDenied ||
    consultantPayHistoryIsAccessDenied;

  useEffect(() => {
    if (path.includes(PATH_NAMES.PARTNERS_PROGRAM) && selectFilialAccId && isSalon) {
      dispatch(getPartnerFullTableData());
    }
  }, [path, isSalon, dispatch, selectFilialAccId]);

  useEffect(() => {
    if (
      path.includes(PATH_NAMES.PARTNERS_PROGRAM) &&
      selectFilialAccId &&
      (!isSalon || isSalonConsultant)
    ) {
      dispatch(getConsultantFullTableData(consultantData?.promoCode || ''));
    }
  }, [path, isSalon, dispatch, consultantData?.promoCode, selectFilialAccId, isSalonConsultant]);

  useEffect(() => {
    if (path.includes(PATH_NAMES.LIST_OF_OPERATION) && selectFilialAccId) {
      dispatch(getOperationListTableData(consultantData?.promoCode || ''));
    }
  }, [path, dispatch, consultantData?.promoCode, selectFilialAccId]);

  useEffect(() => {
    if (path.includes(PATH_NAMES.PAYMENT_HISTORY) && selectFilialAccId && isSalon) {
      dispatch(getPayHistorySalonTableData());
    }
  }, [path, isSalon, dispatch, selectFilialAccId]);

  useEffect(() => {
    if (
      path.includes(PATH_NAMES.PAYMENT_HISTORY) &&
      selectFilialAccId &&
      (!isSalon || isSalonConsultant)
    ) {
      dispatch(getPayHistoryConsultantTableData(consultantData?.promoCode || ''));
    }
  }, [path, isSalon, dispatch, consultantData?.promoCode, selectFilialAccId, isSalonConsultant]);

  useEffect(() => {
    if (
      path.includes(PATH_NAMES.ADD_LEADS) &&
      selectFilialAccId &&
      (!isSalon || isSalonConsultant)
    ) {
      dispatch(getLeadDataThunk({ promoCode: consultantData?.promoCode || '' }));
    }
  }, [path, isSalon, dispatch, selectFilialAccId, consultantData?.promoCode, isSalonConsultant]);

  return (
    <>
      {accessDenied ? <NoAccess /> : null}
      {!accessDenied ? (
        <PartnersTemplate
          isSalon={isSalon}
          getLeadDataError={getLeadsDataError}
          isSalonConsultant={isSalonConsultant}
          getOperationTableDataError={operationIsError}
          getFullTableDataError={isError || consultantIsError}
          getPaymentHistoryTableDataError={partnersPayHistoryIsError || consultantPayHistoryIsError}
          className='px-4 md:px-2 lg:px-0 w-screen md:w-full'
        />
      ) : null}
    </>
  );
});

PartnersPage.displayName = 'PartnersPage';

export default PartnersPage;
