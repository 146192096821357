import React from 'react';
import { useTranslation } from 'react-i18next';

type TLeadCardProps = {
  /**
   * Номер телефона лида
   * @param {string}
   */
  phone: string;
  /**
   * Идентификатор филиала
   * @param {string}
   */
  branchId: string;
  /**
   * Имя лида
   * @param {string}
   */
  branchName: string;
  /**
   * Комментарий к лиду
   * @param {string}
   */
  comment: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const LeadCard = ({
  comment,
  phone,
  branchId,
  branchName,
  className = '',
}: TLeadCardProps) => {
  const { t } = useTranslation();

  const cardElementStyle = 'border-b border-quartz flex flex-col py2';

  const cardElementTextStyle =
    'font-medium leading-5 tracking-[0.018em] text-quartz1 text-inner_text mb-0.5';

  const cardElementDataStyle = 'leading-5 tracking-[0.018em] text-blackText pb-2';

  return (
    <div className={`border border-quartz rounded-lg flex flex-col py-2 px-4 ${className}`}>
      <div className={cardElementStyle}>
        <p className={cardElementTextStyle}>{t('PARTNER_PAGE_TEXT.leadsTable.phone')}</p>
        <p className={cardElementDataStyle}>{phone}</p>
      </div>
      <div className={cardElementStyle}>
        <p className={cardElementTextStyle}>{t('PARTNER_PAGE_TEXT.leadsTable.branchId')}</p>
        <p className={cardElementDataStyle}>{branchId}</p>
      </div>
      <div className={cardElementStyle}>
        <p className={cardElementTextStyle}>{t('PARTNER_PAGE_TEXT.leadsTable.leadName')}</p>
        <p className={cardElementDataStyle}>{branchName}</p>
      </div>
      <div className='flex flex-col py-2'>
        <p className={cardElementTextStyle}>{t('PARTNER_PAGE_TEXT.leadsTable.comment')}</p>
        <p className={cardElementDataStyle}>{comment}</p>
      </div>
    </div>
  );
};
