import React, { memo, useLayoutEffect, useState } from 'react';

type TTemplatesProgressBarProps = {
  /**
   * Процент записавшихся после рассылки шаблона
   * @type {number}
   */
  recorded: number;
  /**
   * Процент ответивших после рассылки шаблона
   * @type {number}
   */
  answered: number;
};

export const TemplatesProgressBar = memo(({ recorded, answered }: TTemplatesProgressBarProps) => {
  const [{ recordedStyle, answeredStyle, totalStyle }, setStyle] = useState({
    recordedStyle: {},
    answeredStyle: {},
    totalStyle: {},
  });

  useLayoutEffect(() => {
    setStyle({
      recordedStyle: {
        width: `${recorded}%`,
      },
      answeredStyle: {
        width: `${answered - recorded}%`,
      },
      totalStyle: {
        width: `${100 - answered}%`,
      },
    });
  }, [recorded, answered]);

  return (
    <div className='flex rounded-[1rem] bg-lightGray text-stormGray text-captionSmall text-end'>
      <div
        style={recordedStyle}
        className={`pr-2 text-white ${
          recorded < 100 ? 'rounded-l-[1rem]' : 'rounded-[1rem]'
        } bg-green2`}>{`${recorded}%`}</div>
      {recorded < 100 ? (
        <div style={answeredStyle} className='pr-2 text-white bg-orange'>{`${answered}%`}</div>
      ) : null}
      {recorded + answered < 100 ? (
        <div style={totalStyle} className='pr-2'>
          100%
        </div>
      ) : null}
    </div>
  );
});

TemplatesProgressBar.displayName = 'TemplatesProgressBar';
