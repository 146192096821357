import { memo, ReactNode, useCallback, useMemo } from 'react';
//
import { useDetectPlatform } from '@hooks/useDetectPlatform';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import {
  useSensor,
  DndContext,
  useSensors,
  TouchSensor,
  DragEndEvent,
  closestCenter,
  PointerSensor,
  KeyboardSensor,
} from '@dnd-kit/core';

type TProps = {
  /**
   * Массив id элементов для сортировки
   * @param {number[]}
   */
  itemsId: number[];
  /**
   * Массив компонентов обернутых в <SortableItem /> для формирования списка на странице
   * @param {ReactNode}
   */
  children: ReactNode;
  /**
   * После окончания перетаскивания запускается callback,
   * в него передается массив id в новом порядке
   * @param {(itemsId: number[]) => void}
   */
  onDragEndCallback?: (itemsId: number[]) => void;
};

export const DndContainer = memo(({ itemsId, children, onDragEndCallback }: TProps) => {
  const isMobile = useDetectPlatform();

  const detectSensor = useMemo(() => {
    return {
      sensor: isMobile ? TouchSensor : PointerSensor,
      options: isMobile
        ? { activationConstraint: { delay: 250, tolerance: 5 } }
        : { activationConstraint: { distance: 5 } },
    };
  }, [isMobile]);

  const sensors = useSensors(
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(detectSensor.sensor, detectSensor.options),
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        const oldIndex = itemsId.findIndex(id => id === active.id);
        const newIndex = itemsId.findIndex(id => id === over.id);
        const sortedId = arrayMove(itemsId, oldIndex, newIndex);

        if (onDragEndCallback) {
          onDragEndCallback(sortedId);
        }
      }
    },
    [itemsId, onDragEndCallback],
  );

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={itemsId}>{children}</SortableContext>
    </DndContext>
  );
});

DndContainer.displayName = 'DndContainer';
