import React, { memo } from 'react';
import { Button } from '@uikit/Button';
import { ChannelHeader } from '@blocks/channelHeader';
import icon from '@img/connect/telegram.svg';

type TConnectionStatusCardTgBotProps = {
  /**
   * Флаг ошибки
   * @param {boolean}
   */
  isError: boolean;
  /**
   * Флаг состояния запроса
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Тектс в кнопку
   * @param {string}
   */
  buttonText: string;
  /**
   * Тест заголовка
   * @param {string}
   */
  headerText: string;
  /**
   * Текст пояснений в карточку
   * @param {string}
   */
  descriptionText: string;
  /**
   * Название иконки в кнопку
   * @param {string | null}
   */
  buttonIcon: string | null;
  /**
   * Параметр цвета кнопки
   * @param {string | null}
   */
  buttonColor: string | null;
  /**
   * Обработчик нажатий на кнопку
   * @param {() => void}
   */
  onClickHandler: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const ConnectionStatusCardTgBot = memo(
  ({
    isError,
    isLoading,
    headerText,
    buttonText,
    buttonIcon,
    buttonColor,
    onClickHandler,
    descriptionText,
    className = '',
  }: TConnectionStatusCardTgBotProps) => {
    const isShowButton = buttonText && onClickHandler;

    return (
      <div className={className}>
        <div className='mb-4'>
          <ChannelHeader
            iconSrc={icon}
            headerText={headerText}
            subheaderText={isError ? '' : descriptionText}
          />
          {isError ? (
            <p
              className={`${
                isError ? 'text-red2' : 'text-gray2'
              } leading-6 tracking-[0.0275em] my-6`}>
              {descriptionText}
            </p>
          ) : null}
        </div>
        {isShowButton ? (
          <div className={isError ? '' : 'ml-[4.125rem]'}>
            <Button
              dense
              type='action'
              view='outlined'
              text={buttonText}
              icon={buttonIcon}
              loading={isLoading}
              color={buttonColor}
              onClick={onClickHandler}
              className='!bg-white'
            />
          </div>
        ) : null}
      </div>
    );
  },
);

ConnectionStatusCardTgBot.displayName = 'ConnectionStatusCardTgBot';
