import { TSimpleStringObj } from '@models/index';
import { TKeyData } from '@redux/template/models';
import { KEY_WRAPPER_SYMBOLS } from '@const/preview';

export const getBeforeAndAfterText = ({
  text,
  cutStartIndex,
  cutEndIndex,
}: {
  text: string;
  cutStartIndex: number;
  cutEndIndex: number;
}) => {
  const before = text.slice(0, cutStartIndex);
  const after = text.slice(cutEndIndex);

  return { before, after };
};

export const getKeysData = (text: string, specialKeyDict: TSimpleStringObj): TKeyData[] => {
  let openSymbolIndex = 0;
  let isOpenIndexFound = false;
  let closedSymbolIndex = 0;
  let isCloseIndexFound = false;
  const keyData: TKeyData[] = [];

  for (let i = 0; i < text.length; i++) {
    if (text[i] === KEY_WRAPPER_SYMBOLS.OPEN_SYMBOL) {
      openSymbolIndex = i;
      isOpenIndexFound = true;
      i++;
    }
    if (text[i] === KEY_WRAPPER_SYMBOLS.CLOSE_SYMBOL) {
      closedSymbolIndex = i;
      isCloseIndexFound = true;
    }

    if (isOpenIndexFound && isCloseIndexFound) {
      const keyName = text.slice(openSymbolIndex + 1, closedSymbolIndex);

      if (Object.keys(specialKeyDict).includes(keyName)) {
        keyData.push({
          start: openSymbolIndex,
          end: closedSymbolIndex + 1,
          keyName: text.slice(openSymbolIndex + 1, closedSymbolIndex),
        });
      }

      openSymbolIndex = 0;
      isOpenIndexFound = false;
      closedSymbolIndex = 0;
      isCloseIndexFound = false;
    }
  }

  return keyData;
};

export const getInnerHtml = (text: string, keysDict: TSimpleStringObj) => {
  let openSymbolIndex = 0;
  let isOpenIndexFound = false;
  let closedSymbolIndex = 0;
  let isCloseIndexFound = false;

  let html = text;

  for (let i = 0; i <= html.length; i++) {
    if (html[i] === KEY_WRAPPER_SYMBOLS.OPEN_SYMBOL) {
      openSymbolIndex = i;
      isOpenIndexFound = true;
    }
    if (html[i] === KEY_WRAPPER_SYMBOLS.CLOSE_SYMBOL) {
      closedSymbolIndex = i;
      isCloseIndexFound = true;
    }

    if (isOpenIndexFound && isCloseIndexFound) {
      const keyName = html.slice(openSymbolIndex + 1, closedSymbolIndex);

      const isKeyValid = Object.keys(keysDict).includes(keyName);

      const { before, after } = getBeforeAndAfterText({
        text: html,
        cutStartIndex: openSymbolIndex,
        cutEndIndex: closedSymbolIndex + 1,
      });

      const replacedKeyLength = closedSymbolIndex - openSymbolIndex;

      if (isKeyValid) {
        const keySpan = `<span contentEditable='false' class="bg-quartz inline-block rounded px-1">{${keyName}}</span>`;

        const keySpanLength = keySpan.length;

        const iCorrectValue = keySpanLength - replacedKeyLength;

        html = `${before}${keySpan}${after}`;

        i += iCorrectValue - 2;
      } else {
        html = `${before}${after}`;

        i += replacedKeyLength - 2;
      }

      openSymbolIndex = 0;
      isOpenIndexFound = false;
      closedSymbolIndex = 0;
      isCloseIndexFound = false;
    }
  }
  return html.replace(/\n/g, '\r\n');
};
