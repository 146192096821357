import { TFullStatsData } from '@redux/reviewsStats/types';
import { TGetResponseWithData, TGetReviewStatsResponse } from '@api/types';

export const mapStatsDataToStore = (
  data: TGetResponseWithData<TGetReviewStatsResponse[]>,
): TFullStatsData => {
  const statsData = data.data.map(
    ({ _id, full_conversion, first_step_conversion, total_sends, negative_results }) => ({
      id: _id,
      totalSends: total_sends,
      fullConversion: full_conversion,
      negativeResults: negative_results,
      firstStepConversion: first_step_conversion,
    }),
  );
  return { data: statsData, totalCount: data.count };
};
