import React, { memo } from 'react';
//
import { TOnclickHandler } from '@shared/types';

interface IDeleteButtonProps {
  /**
   * Callback обработчик события onClick
   * @return {TOnclickHandler}
   */
  callback: TOnclickHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const DeleteButton = memo(({ callback, className = '' }: IDeleteButtonProps) => {
  const onClickHandler: TOnclickHandler = event => {
    event.stopPropagation();
    callback(event);
  };

  return (
    <button
      onClick={onClickHandler}
      aria-label='delete template'
      className={`flex items-center justify-center bg-none m-0 p-0 h-[1.5rem] w-[1.5rem] text-center ${className}`}>
      <svg
        width='20'
        height='24'
        viewBox='0 0 20 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='fill-stormGray hover:fill-statusRed h-[1.25rem] w-[1.25rem]'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18.3999 1.2H12.3999C12.3999 0.54 11.8599 0 11.1999 0H8.7999C8.1399 0 7.5999 0.54 7.5999 1.2H1.5999C0.939902 1.2 0.399902 1.74 0.399902 2.4V3.6H19.5999V2.4C19.5999 1.74 19.0599 1.2 18.3999 1.2ZM18.9999 4.8H0.999902C0.663902 4.8 0.399902 5.064 0.399902 5.4C0.399902 5.736 0.663902 6 0.999902 6H1.5999V22.8C1.5999 23.46 2.1399 24 2.7999 24H17.1999C17.8599 24 18.3999 23.46 18.3999 22.8V6H18.9999C19.3359 6 19.5999 5.736 19.5999 5.4C19.5999 5.064 19.3359 4.8 18.9999 4.8ZM6.3999 19.2C6.3999 19.86 5.8599 20.4 5.1999 20.4C4.5399 20.4 3.9999 19.86 3.9999 19.2V9.6C3.9999 8.94 4.5399 8.4 5.1999 8.4C5.8599 8.4 6.3999 8.94 6.3999 9.6V19.2ZM11.1999 19.2C11.1999 19.86 10.6599 20.4 9.9999 20.4C9.3399 20.4 8.7999 19.86 8.7999 19.2V9.6C8.7999 8.94 9.3399 8.4 9.9999 8.4C10.6599 8.4 11.1999 8.94 11.1999 9.6V19.2ZM15.9999 19.2C15.9999 19.86 15.4599 20.4 14.7999 20.4C14.1399 20.4 13.5999 19.86 13.5999 19.2V9.6C13.5999 8.94 14.1399 8.4 14.7999 8.4C15.4599 8.4 15.9999 8.94 15.9999 9.6V19.2Z'
        />
      </svg>
    </button>
  );
});
