import React from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';

type TMailingDoButtonProps = {
  /**
   * Флаг допустимого действия, переноса рассылки
   * @param {boolean}
   */
  isMove: boolean;
  /**
   * Флаг допустимого действия, перехода к детальной статистике
   * @param {boolean}
   */
  isStatistics: boolean;
  /**
   * Callback для остановки рассылки
   * @param {() => void}
   */
  stopSendOutHandler: () => void;
  /**
   * Флаг процесса остановки рассылки
   * @param {boolean}
   */
  isStoppingSendOut: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: '';
};

export const MailingDoButton = ({
  isMove,
  isStatistics,
  isStoppingSendOut,
  stopSendOutHandler,
  className = '',
}: TMailingDoButtonProps) => {
  const { t } = useTranslation();

  const buttonText = isStatistics
    ? t('MAILING_PAGE_TEXT.mailingTable.statisticsButton')
    : isMove
    ? t('MAILING_PAGE_TEXT.mailingTable.moveButton')
    : t('MAILING_PAGE_TEXT.mailingTable.stopButton');

  const buttonColor = isStatistics || isMove ? 'default' : 'danger';

  return (
    <div className={`w-full ${className}`}>
      <Button
        dense
        type='default'
        view='outlined'
        text={buttonText}
        color={buttonColor}
        disabled={isStoppingSendOut}
        onClick={stopSendOutHandler}
      />
    </div>
  );
};
