import { TMailingTableData } from '@redux/mailing/models';

export interface IInitialState {
  isFetchingMP: boolean;
  isFetchingMPError: boolean;
  isFetchingMPSuccess: boolean;
  searchString: string;
  totalMobilePages: number;
  pageSize: number;
  currentMobilePage: number;
  mobileData: TMailingTableData[];
  filteredData: TMailingTableData[];
  data: TMailingTableData[];
}

export const tableInitialState: IInitialState = {
  isFetchingMP: false,
  isFetchingMPError: false,
  isFetchingMPSuccess: false,
  totalMobilePages: 0,
  pageSize: 3,
  currentMobilePage: 1,
  searchString: '',
  filteredData: [],
  mobileData: [],
  data: [],
};
