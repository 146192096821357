import React, { memo, useEffect } from 'react';
//
import { NoAccess } from '@components/noAccess';
import { ErrorMessage } from '@blocks/errorMessage';
import { BlackListTemplate } from '@templates/blackList';
import { getAccountsData } from '@redux/accounts/selectors';
import { getBlackListData } from '@redux/blackList/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getBlackList, getSendOutBlackList } from '@redux/blackList/blackListThunks';

const BlackListPage = memo(() => {
  const dispatch = useAppDispatch();
  const { status, isError, isAccessDenied } = useAppSelector(getBlackListData);

  // получаем объект selectedFilial
  const { selectedFilial } = useAppSelector(getAccountsData);

  // Запрашивает список номеров при загрузке страницы и смене филиала
  useEffect(() => {
    if (selectedFilial?.accId) {
      dispatch(getBlackList());
      dispatch(getSendOutBlackList());
    }
  }, [dispatch, selectedFilial?.accId]);

  return (
    <>
      {isError && !isAccessDenied ? <ErrorMessage /> : null}
      {isAccessDenied && !isError ? <NoAccess /> : null}
      {!isError && !isAccessDenied ? (
        <BlackListTemplate status={status} className='px-4 sm:px-2 lg:px-0' />
      ) : null}
    </>
  );
});

BlackListPage.displayName = 'BlackListPage';

export default BlackListPage;
