import React, { forwardRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import Component from './Callout';

const propTypes = {
  /**
   ` The view of the component.
   * Variants: `flat` `smooth` `outlined` `raised`
   * Default value (if undefined): `filled` `
   */
  view: PropTypes.oneOf(["filled", "flat", "smooth", "outlined", "raised"]),
  /**
   ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    "default",
    "primary",
    "warning",
    "danger",
    "success",
    "primary_alt",
    "warning_alt",
    "danger_alt",
    "success_alt"
  ]),
  /**
   * String content of optional title element.
   */
  title: PropTypes.string,
  /**
   * Name of a Blueprint UI icon (or an icon element) to render on the left side.
   */
  icon: PropTypes.any,
  dense: PropTypes.bool,
  children: PropTypes.any.isRequired
};

type TComponentPropsType = InferProps<typeof propTypes>;

interface IComponentProps extends TComponentPropsType {
  [key: string]: any;
}

export const Callout: React.FC<IComponentProps> = forwardRef((props, ref) => <Component {...props} ref={ref} />);