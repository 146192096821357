import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { THelpKeys, TWithOptionalChildren } from '@models/index';

interface ILabelProps extends TWithOptionalChildren {
  /**
   * Параметр htmlFor для label
   * @param {string}
   */
  htmlFor: string;
  /**
   * Опциональный параметр, если передан ключ, из словаря берется текст и подставляется в label
   * можно передать и ключ и children
   * @param {THelpKeys}
   */
  variant?: THelpKeys;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const Label = memo(({ htmlFor, variant, className = '', children }: ILabelProps) => {
  const { t } = useTranslation();
  // из словаря выбирается вариант текста для метки, если, опциональный параметр вариант не передан,
  // устанавливается пустая строка
  const labelInnerText = variant ? t(`LABEL_TEXT.${variant}`) : '';

  const labelClass = `flex items-center font-inter font-medium text-blackText cursor-pointer leading-6 tracking-[0.022em] w-fit ${className}`;

  return (
    <label htmlFor={htmlFor} className={labelClass}>
      {labelInnerText ? <p className={children ? 'mr-[0.3125rem]' : ''}>{labelInnerText}</p> : null}
      {children}
    </label>
  );
});

Label.displayName = 'Label';
