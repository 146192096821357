import React, { forwardRef, memo } from 'react';
import { TOnChangeHandler } from '@shared/types';
import { TextInput } from '@uikit/Inputs/DefaultInput';

type TInputWithLabelProps = {
  /**
   * Имя инпута
   * @param {string}
   */
  name: string;
  /**
   * Значение инпута
   * @param {string}
   */
  value: string;
  /**
   * Текст подписи над инпутом
   * @param {string}
   */
  labelText?: string;
  /**
   * Компонент подписи над инпутом
   * @param {React.ReactNode}
   */
  labelComponent?: React.ReactNode;
  /**
   * Флаг обязательного к заполнению поля, добавляет красную *
   * @param {boolean}
   */
  isRequired?: boolean;
  /**
   * Текст placeholder для инпута
   * @param {string}
   */
  placeholderText: string;
  /**
   * Callback для обработки изменений в инпуте
   * @param {TOnChangeHandler}
   */
  onChangeCallback: TOnChangeHandler;
  /**
   * Callback для обработки события blur
   * @param {TOnChangeHandler}
   */
  onBlurCallback?: TOnChangeHandler;
  /**
   * Опциональный параметр отключает textarea
   * @param {boolean}
   * @default
   */
  disabled?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

const Input = forwardRef<HTMLInputElement, TInputWithLabelProps>(
  (
    {
      name,
      value,
      disabled,
      labelText,
      onBlurCallback,
      labelComponent,
      placeholderText,
      onChangeCallback,
      isRequired = false,
      className = '',
    }: TInputWithLabelProps,
    ref,
  ) => {
    return (
      <div className={`grow basis-60 ${className}`}>
        {labelText || labelComponent ? (
          <div className='font-medium leading-6 tracking-[0.022em] mb-2'>
            {labelComponent || labelText}
            {isRequired ? (
              <span className='leading-6 tracking-[0.022em] ml-1 text-[red]'>*</span>
            ) : null}
          </div>
        ) : null}
        <div className={disabled ? 'cursor-not-allowed' : ''}>
          <TextInput
            fill
            ref={ref}
            type='text'
            name={name}
            value={value}
            color='default'
            view='outlined'
            onChange={onChangeCallback}
            placeholder={placeholderText}
            onBlur={onBlurCallback || onBlurCallback}
            className={disabled ? 'pointer-events-none' : ''}
          />
        </div>
      </div>
    );
  },
);

export const InputWithLabel = memo(Input);
