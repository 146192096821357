import { apiService } from '@api/ApiService';
import { END_POINTS_URL } from '@const/apiConstants';
import { TAccessDataItemResponse, TPostAccessesRequestData } from '@redux/access/models';

import { TRequestStatus, TGetAccessesResponseData } from './types';

/**
 * Api страницы доступов
 * @module accessApi
 */

export const accessApi = {
  /**
   * Метод выполняет запрос за данными доступа
   * @method getAccessData
   * @return {Promise<AxiosResponse<TGetAccessesResponseData<TAccessDataItemResponse[]> | TRequestStatus, any>>}
   */
  getAccessData() {
    return apiService.get<TGetAccessesResponseData<TAccessDataItemResponse[]> | TRequestStatus>(
      END_POINTS_URL.ACCESSES,
    );
  },
  /**
   * Метод отправляет POST запрос для сохранения новых или измененных данных со страницы доступы
   * @param data {TPostAccessesRequestData}
   * @return { Promise<AxiosResponse<TGetAccessesResponseData<TAccessDataItemResponse[]> | TRequestStatus, any>>}
   */
  postAccessesData(data: TPostAccessesRequestData) {
    return apiService.post<TGetAccessesResponseData<TAccessDataItemResponse[]> | TRequestStatus>(
      END_POINTS_URL.ACCESSES,
      {
        count: data.data.length,
        data: [
          ...data.data.map(item => ({
            user_id: +item.userId,
            name: item.name,
            rights: item.rights,
          })),
        ],
      },
    );
  },
};
