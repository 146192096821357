import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { TablePagination } from '@material-ui/core';
import { PAGINATION_ROWS_OPTIONS } from '@const/mailing';
import { TOnChangeHandler, TOnPageChange } from '@shared/types';

type TProps = {
  /**
   * Количество строк на странице
   * @param {number}
   */
  rowsPerPage: number;
  /**
   * Текущая страница
   * @param {number}
   */
  currentPage: number;
  /**
   * Общее количество элементов
   * @param {number}
   */
  totalCount: number;
  /**
   * Функция для изменения страницы
   * @param {TOnPageChange}
   */
  onPageChange: TOnPageChange;
  /**
   * Функция для изменения количества строк на странице
   * @param {TOnChangeHandler}
   */
  onRowsPerPageChange: TOnChangeHandler;
  /**
   * Текст перед выбором коичества элементов на странице
   * @param {string}
   */
  labelRowsPerPage: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const CustomTablePagination = memo(
  ({
    totalCount,
    rowsPerPage,
    currentPage,
    onPageChange,
    labelRowsPerPage,
    onRowsPerPageChange,
    className = '',
  }: TProps) => {
    const { t } = useTranslation();

    const inText = t('CHAT_HISTORY_PAGE_TEXT.chatHistoryTable.paginationInText');

    const defaultLabelDisplayedRows = ({
      from,
      to,
      count,
    }: {
      from: number;
      to: number;
      count: number;
    }): React.ReactNode => {
      return `${from}–${to} ${inText} ${count !== -1 ? count : `${inText} ${to}`}`;
    };

    return (
      <div className={className}>
        <TablePagination
          component='div'
          page={currentPage}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={onPageChange}
          labelRowsPerPage={labelRowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
          rowsPerPageOptions={PAGINATION_ROWS_OPTIONS}
          labelDisplayedRows={defaultLabelDisplayedRows}
          style={{ margin: '0, 30px' }}
        />
      </div>
    );
  },
);

CustomTablePagination.displayName = 'CustomTablePagination';
