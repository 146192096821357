import React, { memo } from 'react';
//
import { getLinkHref } from '@helpers/index';
import { TAppLanguage } from '@models/index';
import { PAY_PAGES_NAME_DICT } from '@const/pay';
import { NavItem } from '@blocks/templateNavItem';
import { NavDropDown } from '@components/navDropDown';

type TPayNavProps = {
  /**
   * Путь из URL полученный хуком useLocation в родительском компоненте
   * @param {string}
   */
  path: string;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Id выбранного филиала используется для формирования ссылок
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const PayNav = memo(
  ({ path, appLanguage, selectedFilialAccId, className = '' }: TPayNavProps) => {
    const pageData = Object.entries(PAY_PAGES_NAME_DICT[appLanguage]);

    const linksToRender = pageData.map(([link, text], index) => {
      const lastElementIndex = pageData.length - 1;
      const isLastElement = index === lastElementIndex;

      const fullLink = getLinkHref({ route: link, accId: selectedFilialAccId });

      return (
        <NavItem
          key={text}
          text={text}
          to={fullLink}
          isActive={path.includes(link)}
          className={!isLastElement ? 'mr-2' : ''}
        />
      );
    });

    return (
      <div className={`flex flex-wrap sm:flex-nowrap justify-between ${className}`}>
        <div className='lg:hidden w-full'>
          <NavDropDown
            selectedFilialAccId={selectedFilialAccId}
            pageNameDict={PAY_PAGES_NAME_DICT[appLanguage]}
            className='nav_dropdown sm:max-w-[20rem]'
          />
        </div>
        <div className='hidden flex-wrap lg:flex'>{linksToRender}</div>
      </div>
    );
  },
);

PayNav.displayName = 'PayNav';
