import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { HOUR } from '@const/common';
import { createPortal } from 'react-dom';
import { Overlay } from '@atoms/overlay';
import { useAppSelector } from '@store/store';
import { getAppData } from '@redux/app/selectors';
import { WHEN_SEND_VALUE } from '@const/templates';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { SetMinutesDropdown } from '@blocks/setMinutesDropdown';
import { numWord, selectTextContentOnFocus } from '@helpers/index';
import { TOnChangeHandler, TOnFocusHandlerInputElement, TSetWhenSend2Days } from '@shared/types';

type TDayAndMinutesProps = {
  /**
   * Флаг отключает возможность изменения значения в блоке
   * @param {boolean}
   */
  disabled?: boolean;
  /**
   * Значение дропдауна whenSend
   * @param {string}
   */
  whenSend: string;
  /**
   * Через сколько дней отправлять шаблон
   * @param {string}
   */
  whenSend2Days: string;
  /**
   * Callback для изменения whenSend2Days
   * @param {TSetWhenSend2Days}
   */
  setWhenSend2Days: TSetWhenSend2Days;
  /**
   * Через сколько минут отправлять шаблон
   * @param {string}
   */
  whenSend2Minutes: string;
  /**
   * Callback для изменения whenSend2Min для инпута
   * @param {TOnChangeHandler}
   */
  setWhenSend2MinOnChangeHandler: TOnChangeHandler;
  /**
   * Callback для изменения whenSend2Min для dropdown
   * @param {(minutes: string) => () => void}
   */
  setWhenSend2MinHandler: (minutes: string) => () => void;
  /**
   * Колбэк который срабатывает при закрытии выпадающего списка с минутами
   * @param {() => void}
   */
  onBlurCallback: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const DayAndMinutes = memo(
  ({
    disabled,
    whenSend,
    whenSend2Days,
    onBlurCallback,
    setWhenSend2Days,
    whenSend2Minutes,
    setWhenSend2MinHandler,
    setWhenSend2MinOnChangeHandler,
    className = '',
  }: TDayAndMinutesProps) => {
    const { t } = useTranslation();

    const { appLanguage } = useAppSelector(getAppData);
    const [isShowDropdown, setIsShowDropdown] = useState(false);

    // показывает выпадающий список при фокусе на TimePicker
    const onFocusHandler: TOnFocusHandlerInputElement = useCallback(
      event => {
        if (!isShowDropdown) {
          setIsShowDropdown(true);
        }
        selectTextContentOnFocus(event);
      },
      [isShowDropdown],
    );

    // показывает/скрывает выпадающий список
    const dropDownToggle = useCallback(() => {
      setIsShowDropdown(prevState => !prevState);

      onBlurCallback();
    }, [onBlurCallback]);

    // Выделяет значение в инпуте при фокусе
    const selectValueOnFocus: TOnFocusHandlerInputElement = useCallback(event => {
      selectTextContentOnFocus(event);
    }, []);

    // текст перед полем ввода
    const prefixText =
      whenSend === WHEN_SEND_VALUE.AFTER_END
        ? t('TEMPLATE_PAGE_TEXT.birthdayWhenSend2After')
        : t('TEMPLATE_PAGE_TEXT.birthdayWhenSend2Before');

    return (
      <div className={`flex items-center ${className}`}>
        <span className='text-black leading-6 tracking-[0.0275em] mr-1'>{prefixText}</span>
        <div className='ml-2 w-[4rem]'>
          <TextInput
            fill
            type='text'
            placeholder=''
            color='default'
            view='outlined'
            disabled={disabled}
            value={whenSend2Days}
            onFocus={selectValueOnFocus}
            onChange={setWhenSend2Days({ maxValue: 999, minValue: 0 })}
            className='m-0'
          />
        </div>
        <span className='text-black leading-6 tracking-[0.0275em] ml-2.5'>
          {numWord(+whenSend2Days, HOUR[appLanguage])}
        </span>
        <div className='relative ml-4 w-[3.25rem]'>
          <TextInput
            fill
            type='text'
            placeholder=''
            color='default'
            view='outlined'
            disabled={disabled}
            value={whenSend2Minutes}
            onFocus={onFocusHandler}
            onChange={setWhenSend2MinOnChangeHandler}
            className='m-0'
          />
          {isShowDropdown && !disabled ? (
            <SetMinutesDropdown
              endMinutes={59}
              startMinutes={0}
              minIncreaseStep={15}
              dropdownToggle={dropDownToggle}
              minutesValue={whenSend2Minutes}
              setMinutesCallback={setWhenSend2MinHandler}
              className='absolute top-[2.75rem] z-[60]'
            />
          ) : null}
        </div>
        <span className='text-black leading-6 tracking-[0.0275em] ml-2.5'>
          {t('TEMPLATE_PAGE_TEXT.minutes')}
        </span>
        {isShowDropdown && !disabled
          ? createPortal(<Overlay onClickCallback={dropDownToggle} />, document.body)
          : null}
      </div>
    );
  },
);

DayAndMinutes.displayName = 'DayAndMinutes';
