import React, { memo } from 'react';
import { Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
//
import { numWord } from '@helpers/index';
import { recipients } from '@const/mailing';
import { TAppLanguage } from '@models/index';
import { ReactComponent as RejectedIcon } from '@img/mailing/checkbox_square_disabled.svg';
import { ReactComponent as CheckedCheckboxIcon } from '@img/mailing/checkbox_square_checked.svg';
import {
  ReactComponent as UnCheckedCheckboxIcon,
  ReactComponent as DisabledCheckedCheckboxIcon,
} from '@img/mailing/checkbox_square_unchecked.svg';

type TProps = {
  /**
   * Выбранный язык в приложении
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Флаг выбранного блока
   * @param {boolean}
   */
  checked: boolean;
  /**
   * Текст в блок
   * @param {string}
   */
  text: string;
  /**
   * Количество получателей
   * @param {number}
   */
  numberOfRecipients: number;
  /**
   * Тогл для чекбокса
   * @param {() => void}
   */
  selectBlockHandler: () => void;
  /**
   * Флаг отображения отказавшихся от рассылки
   * @param {boolean}
   */
  isRejectedBlock: boolean;
  // TODO выпилить если не понадобится deadline 24.11.24.
  /**
   * Флаг отображения callout при выборе опции
   * @param {boolean}
   */
  isShowWarning: boolean;
  /**
   * Выделяет текст жирным
   * @param {boolean}
   */
  isBoldText: boolean;
  /**
   * Флаг добавления заеленого background
   * @param {boolean}
   */
  showGreenBackground?: boolean;
  /**
   * Опциональный параметр - строка классов
   * @param {string}
   */
  className?: string;
};

export const RecipientSelectItem = memo(
  ({
    text,
    checked,
    isBoldText,
    appLanguage,
    isShowWarning,
    isRejectedBlock,
    selectBlockHandler,
    numberOfRecipients,
    showGreenBackground,
    className = '',
  }: TProps) => {
    const { t } = useTranslation();

    const boldTextStyle = `${
      isBoldText ? 'bg-lightGray font-bold max-w-[26.375rem]' : 'font-medium'
    } text-[1rem] text-black leading-6 tracking-[0.022em] ${
      numberOfRecipients && !isRejectedBlock ? 'text-black' : 'text-gray3'
    }`;

    return (
      <div className={className}>
        <div
          onClick={isRejectedBlock || !numberOfRecipients ? undefined : selectBlockHandler}
          className={`rounded flex sm:items-center min-h-10 ${boldTextStyle} ${
            numberOfRecipients ? 'cursor-pointer' : 'cursor-not-allowed'
          } ${showGreenBackground ? 'bg-green3 max-w-[26.375rem]' : ''}`}>
          <div className='flex items-start sm:items-center'>
            {!isRejectedBlock && numberOfRecipients ? (
              checked ? (
                <CheckedCheckboxIcon />
              ) : (
                <UnCheckedCheckboxIcon />
              )
            ) : null}
            {!numberOfRecipients && !isRejectedBlock ? <DisabledCheckedCheckboxIcon /> : null}
            {isRejectedBlock ? (
              <RejectedIcon className='flex items-center justify-center p-[0.6875rem] h-10 w-10' />
            ) : null}
          </div>
          <div className='flex flex-col sm:items-center sm:flex-row w-full'>
            <span className='max-w-[12rem] w-full'>{text}</span>
            <div className='flex flex-col sm:flex-row sm:items-center'>
              <span className='mb-[0.375rem] sm:m-0 mr-4 max-w-[15rem]'>{`${numberOfRecipients} ${numWord(
                numberOfRecipients,
                recipients[appLanguage],
              )}`}</span>
              {checked && isShowWarning ? (
                <div className='bg-[#fbf6ed] flex items-center rounded-lg px-3 min-h-[2rem] sm:ml-2 w-fit'>
                  <Icon icon='info-sign' color='#FB982E' size={20} className='mr-2' />
                  <p className='text-black leading-6 tracking-[0.0275em]'>
                    {t('MAILING_PAGE_TEXT.selectRecipients.sendAnyway')}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

RecipientSelectItem.displayName = 'RecipientSelectItem';
