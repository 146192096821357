import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { Table } from '@uikit/Table/Table';
import { TOnChangeHandler } from '@shared/types';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { TMailingTableData } from '@redux/mailing/models';
import { TableTextBlock } from '@components/tableTextBlock';
import { MailingProcessBar } from '@blocks/mailingProcessBar';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getMailingTableData } from '@redux/mailing/selectors';
import { SearchInputWithButton } from '@components/searchInputWithButton';
import { MobileMailingTableCardList } from '@blocks/mobileMailingTableCardList';
import {
  search,
  showMoreMP,
  setSortTypeDataMP,
  clearSearchStringMP,
  setTableSearchStringMP,
} from '@redux/mailing/mailingTableSlice';
import { MailingDoButton } from '@blocks/mailingDoButton';
import { ACTIVE_SEND_OUT_STATUSES } from '@const/mailing';

interface IMailingTableProps {
  /**
   * Флаг сообщает о запущенном процессе остановки рассылки,
   * по этому флагу блокируются кнопки остановки рассылок
   * @param {boolean}
   */
  isStoppingSendOut: boolean;
  /**
   * Callback для постановки запущенной рассылки на паузу
   * @param {(sendOutId: number) => void}
   */
  pauseSendOut: (sendOutId: number) => void;
  /**
   * Callback для запуска ранее остановленной рассылки
   * @param {(sendOutId: number) => void}
   */
  startPausedSendOut: (sendOutId: number) => void;
  /**
   * Callback для остановки запущенной рассылки
   * @param {(sendOutId: number) => void}
   */
  stopSendOut: (sendOutId: number) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const MailingTable = memo(
  ({
    stopSendOut,
    pauseSendOut,
    isStoppingSendOut,
    startPausedSendOut,
    className = '',
  }: IMailingTableProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const windowsWidth = useWindowWidth();

    const { filteredData, searchString, mobileData, totalMobilePages, currentMobilePage } =
      useAppSelector(getMailingTableData);

    // записывает введенные данные в состояние, для последующей обработки
    const setSearchStringHandler: TOnChangeHandler = event => {
      const { value } = event.currentTarget;
      dispatch(setTableSearchStringMP({ value }));
    };

    // функция сортировки, получает название столбца отправляет в редакс
    const setSortTypeHandler = (name: string, order: string) => {
      dispatch(setSortTypeDataMP({ columnName: name || '', order }));
    };

    // вызывает callback для остановки рассылки
    const stopSendOutHandler = (id: number) => () => {
      stopSendOut(id);
    };

    // Запускает поиск по нажатию на кнопку
    const searchOnClickHandler = () => {
      dispatch(search());
    };

    // Обработчик кнопки "Показать еще", в мобильном виде показывается только часть данных,
    // после нажатия на кнопку "подгружается"  еще часть данных
    const showMoreHandler = () => {
      dispatch(showMoreMP());
    };

    // Очищает поисковую строку
    const clearSearchStringHandler = () => {
      dispatch(clearSearchStringMP());
    };

    // Создаем массив для отображения шапки таблицы
    const Head = [
      { id: 'id', label: t('MAILING_PAGE_TEXT.mailingTable.id') },
      { id: 'date', label: t('MAILING_PAGE_TEXT.mailingTable.date') },
      { id: 'text', label: t('MAILING_PAGE_TEXT.mailingTable.text') },
      { id: 'process', label: t('MAILING_PAGE_TEXT.mailingTable.process') },
      { id: 'do', label: t('MAILING_PAGE_TEXT.mailingTable.do'), noSort: true },
    ];

    // Создаем массив элементов для тела таблицы, если нет данных, возвращаем массив с пустым объектом
    const getTableData = (tableData: TMailingTableData[]) => {
      const emptyData = [
        {
          id: ' ',
          date: ' ',
          text: ' ',
          process: ' ',
          do: ' ',
        },
      ];
      if (!tableData.length) {
        return emptyData;
      }

      return tableData.map(({ id, date, time, text, process, status }) => {
        const index = process.indexOf('/');
        const send = Number(process.slice(0, index));
        const total = Number(process.slice(index + 1));
        return {
          id,
          date: (
            <div className='flex flex-wrap min-w-[5.625rem]'>
              <span className='break-all mr-1'>{`${date}, `}</span>
              <span>{time}</span>
            </div>
          ),
          text: (
            <div className='xl:max-w-[49rem] xl:w-full'>
              <TableTextBlock text={text} />
            </div>
          ),
          process: (
            <MailingProcessBar
              id={id}
              send={send}
              total={total}
              process={process}
              isMobileView={false}
              isShowButtons={false}
              pauseSendOut={pauseSendOut}
              startPausedSendOut={startPausedSendOut}
              isPlaned={status === ACTIVE_SEND_OUT_STATUSES.IS_PLANED}
              isStarted={status === ACTIVE_SEND_OUT_STATUSES.IS_STARTED}
              isOnPause={status === ACTIVE_SEND_OUT_STATUSES.IS_ON_PAUSE}
            />
          ),
          do: (
            <MailingDoButton
              isMove={false}
              isStatistics={false}
              isStoppingSendOut={isStoppingSendOut}
              stopSendOutHandler={stopSendOutHandler(id)}
            />
          ),
        };
      });
    };

    return (
      <div className={`flex flex-col ${className}`}>
        <SearchInputWithButton
          hideSearchButton
          isChatHistoryPage
          searchString={searchString}
          disableSearchButton={false}
          startSearchHandler={searchOnClickHandler}
          setSearchStringHandler={setSearchStringHandler}
          clearSearchStringHandler={clearSearchStringHandler}
          buttonText={t('MAILING_PAGE_TEXT.mailingTable.searchButton')}
          placeholder={t('MAILING_PAGE_TEXT.mailingTable.searchPlaceholder')}
          className='pl-px mb-8 md:max-w-[27rem]'
        />
        {windowsWidth > 769 ? (
          <Table
            name={Head}
            data={getTableData(filteredData)}
            position={['left', 'left', 'left', 'left', 'left']}
            type='def'
            view='flat'
            color='default'
            pages
            labelRowsPerPage={t('MAILING_PAGE_TEXT.mailingTable.pagination')}
            rowsPerPageOptions={[5, 8, 20]}
            defRows={8}
            checkboxes={false}
            paperClassName='no_shadow'
            style={{ boxShadow: 'none', marginLeft: '6px' }}
            onSortCallback={setSortTypeHandler}
          />
        ) : null}
        {windowsWidth < 770 ? (
          <>
            <MobileMailingTableCardList
              head={Head}
              data={mobileData}
              pauseSendOut={pauseSendOut}
              isStoppingSendOut={isStoppingSendOut}
              startPausedSendOut={startPausedSendOut}
              stopSendOutHandler={stopSendOutHandler}
            />
            {mobileData.length ? (
              <Button
                dense
                type='action'
                icon='repeat'
                view='outlined'
                color='default'
                onClick={showMoreHandler}
                disabled={totalMobilePages === currentMobilePage}
                text={t('MAILING_PAGE_TEXT.mailingTable.showMoreButton')}
                className='mt-8'
              />
            ) : null}
          </>
        ) : null}
      </div>
    );
  },
);
