import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { BANNER_LINK } from '@const/pay';
import { changePageOnClick } from '@helpers/index';

type IReviewOnMarketplaceBannerProps = {
  /**
   * Опциональный параметр - строка классов
   * @param {string}
   */
  className?: string;
};

export const ReviewOnMarketplaceBanner = memo(
  ({ className = '' }: IReviewOnMarketplaceBannerProps) => {
    const { t } = useTranslation();

    const changePageOnClickHandler = useCallback(() => {
      changePageOnClick({
        isTargetBlank: true,
        href: BANNER_LINK,
      });
    }, []);

    return (
      <div
        className={`bg-no-repeat bg-violet bg-payCommercialBanner bg-cover rounded-lg flex flex-col items-center justify-between pt-[1.6875rem] pr-[1.8125rem] pb-[1.8125rem] pl-[1.1875rem] h-[16.5rem] w-full ${className}`}>
        <span className='font-bold text-white text-[1.5rem] leading-6 whitespace-pre-line mb-4 w-full'>
          {t('PAY_PAGE_TEXT.commercialHeader')}
        </span>
        <span className='text-white leading-5 tracking-[0.018em] mb-4'>
          {t('PAY_PAGE_TEXT.commercialDescription')}
        </span>
        <Button
          fill
          dense
          type='action'
          view='filled'
          color='success'
          onClick={changePageOnClickHandler}
          text={t('PAY_PAGE_TEXT.commercialButton')}
        />
      </div>
    );
  },
);

ReviewOnMarketplaceBanner.displayName = 'NewBanner';
