import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { DAYS } from '@const/common';
import { numWord, selectTextContentOnFocus } from '@helpers/index';
import { useAppSelector } from '@store/store';
import { TOnChangeHandler } from '@shared/types';
import { getAppData } from '@redux/app/selectors';
import { TEMPLATE_DATA_KEY_NAMES } from '@redux/template/models';

type TWhenSendDayProps = {
  /**
   * Значение в инпут
   * @param {string}
   */
  value: string;
  /**
   * Флаг отключения возможности редактирования
   * @param {boolean}
   */
  disabled: boolean;
  /**
   * Значение второго поля настроек когда отправлять шаблон
   * если значение больше 0 к значению добавляется "дней"
   * если меньше, добавляется "дней назад"
   * @param {string}
   */
  whenSend2: string;
  /**
   * Callback для изменения значения в инпуте
   * @param {TOnChangeHandler}
   */
  onChangeCallback: TOnChangeHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const WhenSendDay = memo(
  ({ value, disabled, whenSend2, onChangeCallback, className = '' }: TWhenSendDayProps) => {
    const { t } = useTranslation();

    const { appLanguage } = useAppSelector(getAppData);

    const onFocusHandler = useCallback((event: React.FormEvent<HTMLInputElement>) => {
      selectTextContentOnFocus(event);
    }, []);

    const blockClasses = `transition-all duration-150 ease-in-out shadow-[0_0_0_1px_rgb(180,180,187,1)] active:shadow-[0_0_0_2px_rgb(58,58,68,1)] focus:shadow-[0_0_0_2px_rgb(58,58,68,1)] hover:shadow-[0_0_0_1px_rgb(58,58,68,1)]
  ${
    disabled ? 'hover:!shadow-[0_0_0_1px_rgb(180,180,187,1)]' : ''
  } disabled:bg-white disabled:cursor-not-allowed rounded-[0.25rem] whitespace-pre-line break-words px-3 py-2 h-10 w-full`;

    const daysText = numWord(+value, DAYS[appLanguage]);

    const postfixText =
      +whenSend2 > 0 ? daysText : `${daysText} ${t('TEMPLATE_PAGE_TEXT.whenSendDayAgo')}`;

    return (
      <label className={`${blockClasses} ${disabled ? 'cursor-not-allowed' : ''} ${className}`}>
        <input
          value={value}
          disabled={disabled}
          onFocus={onFocusHandler}
          onChange={onChangeCallback}
          name={TEMPLATE_DATA_KEY_NAMES.WHEN_SEND2_DAY}
          placeholder={t('TEMPLATE_PAGE_TEXT.whenSendDayAgoPlaceholder')}
          className={`${value ? 'text-center w-8' : 'w-full'} border-none disabled:bg-white ${
            disabled ? 'cursor-not-allowed' : ''
          }`}
        />
        {value ? (
          <span className={`text-gray3 ml-1 ${disabled ? 'cursor-not-allowed' : ''}`}>
            {postfixText}
          </span>
        ) : null}
      </label>
    );
  },
);

WhenSendDay.displayName = 'WhenSendDay';
