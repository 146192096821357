import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//
import { COMMON_TOAST, REG_EXP } from '@const/common';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { TConnectionStatus, TG_CONNECT_ERROR } from '@redux/connection/types';
import { CONNECTION_TG_STATUS, TELEGRAM_BOT_CONNECTION_STEPS } from '@const/connect';
import {
  REJECT_RESPONSE_KEY,
  REJECT_RESPONSE_2_KEY,
  TGetConnectionStatusResponse,
} from '@api/types';
import {
  TQRCodeData,
  TConnectStatuses,
  CONNECTION_STATUSES,
  TRejectResponseData,
} from '@models/index';
import {
  stopBot,
  getQrData,
  restartBot,
  getAuthCode,
  clearMessages,
  sendPhoneThunk,
  sendAuthCodeThunk,
  logoutChanelThunk,
  restartChanelThunk,
  send2FaPasswordThunk,
  getConnectionPageStatus,
  getConnectionTGPageStatusThunk,
} from '@redux/connection/connectionThunks';

import { IInitialState, initialState, PHONE_CONNECTION_STEP } from './initialState';

export const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    setPhoneNumber(state: IInitialState, { payload }: PayloadAction<string>) {
      const newValue = payload.replace(REG_EXP.onlyNumbers, '');

      if (state.tgData.isInputError) {
        state.tgData.errorMessage = '';
        state.tgData.isInputError = false;
      }

      if (newValue) {
        state.tgData.phone = newValue;
      } else {
        state.tgData.phone = '';
      }
    },
    setWAPhoneNumber(state: IInitialState, { payload }: PayloadAction<string>) {
      state.phoneNumber = payload;
    },
    setPhoneConnectionStep(
      state: IInitialState,
      { payload }: PayloadAction<PHONE_CONNECTION_STEP>,
    ) {
      state.phoneConnectionStep = payload;
    },
    changePhoneNumber(state: IInitialState) {
      state.tgData.pin = '';
      state.tgData.errorMessage = '';
      state.tgData.isInputError = false;
      state.tgData.requestStatus = 'success';
      state.tgData.stepNumber = TELEGRAM_BOT_CONNECTION_STEPS.FIRST;
    },
    restartConnection(state: IInitialState) {
      state.tgData.pin = '';
      state.tgData.errorMessage = '';
      state.tgData.isInputError = false;
      state.tgData.requestStatus = 'success';
      state.tgData.stepNumber = TELEGRAM_BOT_CONNECTION_STEPS.FIRST;
    },
    setPinCode(state: IInitialState, { payload }: PayloadAction<string>) {
      state.tgData.pin = payload.replace(REG_EXP.onlyNumbers, '');
    },
    setPassword(state: IInitialState, { payload }: PayloadAction<string>) {
      state.tgData.password = payload;
    },
    setInputErrorCP(state: IInitialState, { payload }: PayloadAction<boolean>) {
      state.tgData.isInputError = payload;
    },
    showToSoonRequestError(state: IInitialState) {
      state.isLoading = false;
      state.phoneConnectionStatus = 'error';
      state.errorMessage = COMMON_TOAST.CONNECTION_GET_UNIQUE_CODE_TIMEOUT_ERROR;
    },
    clearError(state: IInitialState) {
      state.isError = false;
      state.errorMessage = '';
      state.isGetQRError = false;
      state.isCleanQueryError = false;
      state.isBotRestartError = false;
      state.isBotStoppingError = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(getConnectionPageStatus.pending, (state: IInitialState) => {
      state.isError = false;
      state.isLoading = true;
      state.isLoading = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.isFetchingQR = false;
      state.isGetQRError = false;
      state.isRestartingBot = false;
      state.isBotRestartError = false;
      state.isCleanQueryError = false;
      state.isBotStoppingQuery = false;
      state.isBotStoppingError = false;
      state.isFetchingCleanQuery = false;
      state.isAccessToConnectionDenied = false;
    });
    builder.addCase(
      getConnectionPageStatus.fulfilled,
      (state: IInitialState, { payload }: PayloadAction<TGetConnectionStatusResponse | void>) => {
        state.isSuccess = true;
        state.isLoading = false;

        if (payload) {
          const { status, phone, messages } = payload;

          let newStatus = '';
          if (status === CONNECTION_STATUSES.GOT_QR_CODE_BACK_STATUS) {
            newStatus = CONNECTION_STATUSES.GOT_QR_CODE;
          }

          state.phoneNumber = phone || '';

          state.waData = {
            ...payload,
            status: (newStatus || status) as TConnectStatuses,
            messages: messages.map((message, index) => ({
              id: index + 1,
              phone: message.phone,
              body: message.body,
            })),
          };
        }
      },
    );
    builder.addCase(
      getConnectionPageStatus.rejected,
      (state: IInitialState, { payload }: PayloadAction<TRejectResponseData | undefined>) => {
        if (payload && REJECT_RESPONSE_2_KEY.DETAIL in payload) {
          const { detail } = payload;
          if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isError = true;
          }
        } else if (payload && REJECT_RESPONSE_KEY.STATUS in payload) {
          const { status } = payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessToConnectionDenied = true;
          } else {
            state.isError = true;
          }
        } else {
          state.isError = true;
        }
        state.isLoading = false;
      },
    );
    builder.addCase(getQrData.pending, (state: IInitialState) => {
      state.isFetchingQR = true;
      state.isGetQRError = false;
    });
    builder.addCase(
      getQrData.fulfilled,
      (state: IInitialState, { payload }: PayloadAction<TQRCodeData | void>) => {
        state.isFetchingQR = false;
        if (payload) {
          const { qr } = payload;

          if (qr.includes('data:image')) {
            state.qrData = payload;
          } else {
            const newQr = `data:image/jpeg;base64,${qr}`;
            state.qrData = { ...payload, qr: newQr };
          }
        }
      },
    );
    builder.addCase(
      getQrData.rejected,
      (state: IInitialState, { payload }: PayloadAction<TRejectResponseData | undefined>) => {
        if (payload && REJECT_RESPONSE_2_KEY.DETAIL in payload) {
          const { detail } = payload;
          if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isGetQRError = true;
            state.isFetchingQR = false;
            state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
          }
        } else if (payload && REJECT_RESPONSE_KEY.STATUS in payload) {
          const { status } = payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessToConnectionDenied = true;
          } else {
            state.isGetQRError = true;
            state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
          }
          state.isFetchingQR = false;
        } else {
          state.isGetQRError = true;
          state.isFetchingQR = false;
          state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
        }
      },
    );
    builder.addCase(getAuthCode.pending, (state: IInitialState) => {
      state.phoneConnectionStatus = 'loading';
    });
    builder.addCase(
      getAuthCode.fulfilled,
      (state: IInitialState, { payload }: PayloadAction<string | void>) => {
        if (payload) {
          state.phoneConnectionStatus = 'success';
          state.oldPhoneNumber = state.phoneNumber;
          state.phoneConnectionStep = PHONE_CONNECTION_STEP.SECOND;
          state.uniqueCode = payload;
        }
      },
    );
    builder.addCase(
      getAuthCode.rejected,
      (state: IInitialState, { payload }: PayloadAction<TRejectResponseData | undefined>) => {
        if (payload && REJECT_RESPONSE_2_KEY.DETAIL in payload) {
          const { detail } = payload;
          if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.phoneConnectionStatus = 'error';
            state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
          }
        } else if (payload && REJECT_RESPONSE_KEY.STATUS in payload) {
          const { status } = payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessToConnectionDenied = true;
          } else {
            state.phoneConnectionStatus = 'error';
            state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
          }
        } else {
          state.phoneConnectionStatus = 'error';
          state.errorMessage = COMMON_TOAST.COMMON_ERROR_MESSAGE;
        }
      },
    );
    builder.addCase(clearMessages.pending, (state: IInitialState) => {
      state.isCleanQueryError = false;
      state.isFetchingCleanQuery = true;
    });
    builder.addCase(clearMessages.fulfilled, (state: IInitialState) => {
      state.isFetchingCleanQuery = false;
      state.waData.messages = [];
    });
    builder.addCase(
      clearMessages.rejected,
      (state: IInitialState, { payload }: PayloadAction<TRejectResponseData | undefined>) => {
        if (payload && REJECT_RESPONSE_2_KEY.DETAIL in payload) {
          const { detail } = payload;
          if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isCleanQueryError = true;
            state.isFetchingCleanQuery = false;
            state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
          }
        } else if (payload && REJECT_RESPONSE_KEY.STATUS in payload) {
          const { status } = payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessToConnectionDenied = true;
          } else {
            state.isCleanQueryError = true;
            state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
          }
          state.isFetchingCleanQuery = false;
        } else {
          state.isCleanQueryError = true;
          state.isFetchingCleanQuery = false;
          state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
        }
      },
    );
    builder.addCase(stopBot.pending, (state: IInitialState) => {
      state.isBotStoppingQuery = true;
      state.isBotStoppingError = false;
    });
    builder.addCase(stopBot.fulfilled, (state: IInitialState) => {
      state.isBotStoppingQuery = false;
    });
    builder.addCase(
      stopBot.rejected,
      (state: IInitialState, { payload }: PayloadAction<TRejectResponseData | undefined>) => {
        if (payload && REJECT_RESPONSE_2_KEY.DETAIL in payload) {
          const { detail } = payload;
          if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isBotStoppingError = true;
            state.isBotStoppingQuery = false;
            state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
          }
        } else if (payload && REJECT_RESPONSE_KEY.STATUS in payload) {
          const { status } = payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessToConnectionDenied = true;
          } else {
            state.isBotStoppingError = true;
            state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
          }
          state.isBotStoppingQuery = false;
        } else {
          state.isBotStoppingError = true;
          state.isBotStoppingQuery = false;
          state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
        }
      },
    );
    builder.addCase(restartBot.pending, (state: IInitialState) => {
      state.isRestartingBot = true;
      state.isBotRestartError = false;
    });
    builder.addCase(restartBot.fulfilled, (state: IInitialState) => {
      state.isRestartingBot = false;
    });
    builder.addCase(
      restartBot.rejected,
      (state: IInitialState, { payload }: PayloadAction<TRejectResponseData | undefined>) => {
        if (payload && REJECT_RESPONSE_2_KEY.DETAIL in payload) {
          const { detail } = payload;
          if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
            state.isRestartingBot = false;
            state.isBotRestartError = true;
            state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
          }
        } else if (payload && REJECT_RESPONSE_KEY.STATUS in payload) {
          const { status } = payload;
          if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
            state.isAccessToConnectionDenied = true;
          } else {
            state.isBotRestartError = true;
            state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
          }
          state.isRestartingBot = false;
        } else {
          state.isRestartingBot = false;
          state.isBotRestartError = true;
          state.errorMessage = COMMON_TOAST.COMMON_SAVE_ERROR_MESSAGE;
        }
      },
    );
    builder.addCase(getConnectionTGPageStatusThunk.pending, (state: IInitialState) => {
      if (!state.isRefresh && !state.isLoadingStatusRefresh) {
        state.isLoading = true;
      }
      state.isLoadingStatusRefresh = false;
    });
    builder.addCase(
      getConnectionTGPageStatusThunk.fulfilled,
      (state: IInitialState, { payload }: PayloadAction<keyof TConnectionStatus | void>) => {
        if (payload) {
          if (state.isRefresh && payload !== 'loading') {
            state.isRefresh = false;
            state.tgData.requestStatus = 'success';
            state.tgData.stepNumber = TELEGRAM_BOT_CONNECTION_STEPS.FIRST;
          }

          if (payload === 'loading') {
            state.isLoadingStatusRefresh = true;
            state.tgData.requestStatus = 'success';
            state.tgData.stepNumber = TELEGRAM_BOT_CONNECTION_STEPS.LOADING_NEW_STATUS;
          }

          state.isLoading = false;
          state.tgData.tgStatus = payload;
        }
      },
    );
    builder.addCase(
      getConnectionTGPageStatusThunk.rejected,
      (state: IInitialState, { payload }: PayloadAction<TRejectResponseData | undefined>) => {
        if (payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in payload) {
            const { detail } = payload;

            if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              return;
            }
          }
        }
        state.isError = true;
        state.isLoading = false;
        state.tgData.requestStatus = 'error';
      },
    );
    builder.addCase(restartChanelThunk.pending, (state: IInitialState) => {
      state.tgData.requestStatus = 'restart';
    });
    builder.addCase(restartChanelThunk.fulfilled, (state: IInitialState) => {
      state.isRefresh = true;
    });
    builder.addCase(
      restartChanelThunk.rejected,
      (state: IInitialState, { payload }: PayloadAction<TRejectResponseData | undefined>) => {
        if (payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in payload) {
            const { detail } = payload;

            if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              return;
            }
          }
        }
        state.tgData.requestStatus = 'error';
        state.tgData.tgStatus = CONNECTION_TG_STATUS.REQUEST_ERROR;
      },
    );
    builder.addCase(logoutChanelThunk.pending, (state: IInitialState) => {
      state.tgData.requestStatus = 'logout';
    });
    builder.addCase(logoutChanelThunk.fulfilled, (state: IInitialState) => {
      state.isRefresh = true;
    });
    builder.addCase(
      logoutChanelThunk.rejected,
      (state: IInitialState, { payload }: PayloadAction<TRejectResponseData | undefined>) => {
        if (payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in payload) {
            const { detail } = payload;

            if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              return;
            }
          }
        }
        state.tgData.requestStatus = 'error';
        state.tgData.tgStatus = CONNECTION_TG_STATUS.REQUEST_ERROR;
      },
    );
    builder.addCase(sendPhoneThunk.pending, (state: IInitialState) => {
      state.tgData.requestStatus = 'phoneSend';
    });
    builder.addCase(
      sendPhoneThunk.fulfilled,
      (state: IInitialState, { payload }: PayloadAction<string | void>) => {
        if (payload) {
          state.tgData.isInputError = true;
          state.tgData.requestStatus = 'error';
          state.tgData.errorMessage = payload;
        } else {
          state.tgData.requestStatus = 'success';
          state.tgData.stepNumber = TELEGRAM_BOT_CONNECTION_STEPS.SECOND;
        }
      },
    );
    builder.addCase(
      sendPhoneThunk.rejected,
      (state: IInitialState, { payload }: PayloadAction<TRejectResponseData | undefined>) => {
        if (payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in payload) {
            const { detail } = payload;

            if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              return;
            }
          }
        }
        state.tgData.isError = true;
        state.tgData.requestStatus = 'error';
        state.tgData.stepNumber = TELEGRAM_BOT_CONNECTION_STEPS.ERROR;
      },
    );
    builder.addCase(sendAuthCodeThunk.pending, (state: IInitialState) => {
      state.tgData.requestStatus = 'pinSend';
    });
    builder.addCase(
      sendAuthCodeThunk.fulfilled,
      (state: IInitialState, { payload }: PayloadAction<string | void>) => {
        if (payload) {
          state.tgData.requestStatus = 'success';

          if (payload === 'error') {
            state.tgData.pin = '';
            state.tgData.phone = '';
            state.tgData.password = '';
            state.tgData.isInputError = true;
            state.tgData.stepNumber = TELEGRAM_BOT_CONNECTION_STEPS.INPUT_ERROR;
          }

          if (payload === 'auth_success') {
            state.tgData.pin = '';
            state.tgData.phone = '';
            state.tgData.password = '';
            state.tgData.tgStatus = 'loading';
            state.isLoadingStatusRefresh = true;
            state.tgData.stepNumber = TELEGRAM_BOT_CONNECTION_STEPS.LOADING_NEW_STATUS;
          }

          if (payload === '2fa') {
            state.tgData.stepNumber = TELEGRAM_BOT_CONNECTION_STEPS.THIRD;
          }
        }
      },
    );
    builder.addCase(
      sendAuthCodeThunk.rejected,
      (state: IInitialState, { payload }: PayloadAction<TRejectResponseData | undefined>) => {
        if (payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in payload) {
            const { detail } = payload;

            if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              return;
            }

            if (detail === TG_CONNECT_ERROR.PASSWORD_ERROR) {
              state.tgData.requestStatus = 'error';
              state.tgData.isInputError = true;
              return;
            }
          }
          state.tgData.isError = true;
          state.tgData.requestStatus = 'error';
          state.tgData.stepNumber = TELEGRAM_BOT_CONNECTION_STEPS.ERROR;
        } else {
          state.tgData.isError = true;
          state.tgData.requestStatus = 'error';
          state.tgData.stepNumber = TELEGRAM_BOT_CONNECTION_STEPS.ERROR;
        }
      },
    );
    builder.addCase(send2FaPasswordThunk.pending, (state: IInitialState) => {
      state.tgData.requestStatus = 'passwordSend';
      state.tgData.stepNumber = TELEGRAM_BOT_CONNECTION_STEPS.LOADING_NEW_STATUS;
    });
    builder.addCase(send2FaPasswordThunk.fulfilled, (state: IInitialState) => {
      state.tgData.pin = '';
      state.tgData.phone = '';
      state.tgData.password = '';
      state.tgData.tgStatus = 'loading';
      state.isLoadingStatusRefresh = true;
      state.tgData.stepNumber = TELEGRAM_BOT_CONNECTION_STEPS.LOADING_NEW_STATUS;
    });
    builder.addCase(
      send2FaPasswordThunk.rejected,
      (state: IInitialState, { payload }: PayloadAction<TRejectResponseData | undefined>) => {
        if (payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in payload) {
            const { detail } = payload;

            if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              return;
            }
          }
          state.tgData.isError = true;
          state.tgData.requestStatus = 'error';
          state.tgData.stepNumber = TELEGRAM_BOT_CONNECTION_STEPS.ERROR;
        } else {
          state.tgData.isError = true;
          state.tgData.requestStatus = 'error';
          state.tgData.stepNumber = TELEGRAM_BOT_CONNECTION_STEPS.ERROR;
        }
      },
    );
  },
});

export const connectionReducer = connectionSlice.reducer;

export const {
  clearError,
  setPinCode,
  setPassword,
  setPhoneNumber,
  setInputErrorCP,
  setWAPhoneNumber,
  changePhoneNumber,
  restartConnection,
  showToSoonRequestError,
  setPhoneConnectionStep,
} = connectionSlice.actions;
