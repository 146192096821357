import { TAppLanguage } from '@models/index';

export const BLACKLIST_TOAST_MESSAGES = {
  SUCCESS_ADD_NUMBER: 'Номер добавлен',
  SUCCESS_DELETE_NUMBER: 'Номер удален',
  ERROR_ADD_NUMBER: 'Ошибка добавления. \n Перезагрузите страницу \n и попробуйте ещё раз',
  ERROR_DELETE_NUMBER: 'Ошибка удаления. \n Перезагрузите страницу \n и попробуйте ещё раз',
};

export const BLACKLIST_TOAST_MESSAGES_ENG = {
  SUCCESS_ADD_NUMBER: 'Number added',
  SUCCESS_DELETE_NUMBER: 'Number deleted',
  ERROR_ADD_NUMBER: 'Error adding.\n Reload the page\n and try again',
  ERROR_DELETE_NUMBER: 'Delete error.\n Reload the page\n and try again',
};

export const BLACKLIST_TOAST = {
  SUCCESS_ADD_NUMBER: 'BLACKLIST_PAGE_TEXT.BLACKLIST_TOAST_MESSAGES.SUCCESS_ADD_NUMBER',
  SUCCESS_DELETE_NUMBER: 'BLACKLIST_PAGE_TEXT.BLACKLIST_TOAST_MESSAGES.SUCCESS_DELETE_NUMBER',
  ERROR_ADD_NUMBER: 'BLACKLIST_PAGE_TEXT.BLACKLIST_TOAST_MESSAGES.ERROR_ADD_NUMBER',
  ERROR_DELETE_NUMBER: 'BLACKLIST_PAGE_TEXT.BLACKLIST_TOAST_MESSAGES.ERROR_ADD_NUMBER',
};

export const NUMBERS: Record<TAppLanguage, string[]> = {
  eng: ['number', 'numbers', 'numbers'],
  rus: ['номер', 'номера', 'номеров'],
};

export const BLACKLIST_PAGE_TEXT = {
  header: 'Черный список',
  searchInputPlaceholder: 'Поиск по номеру',
  commonListHeader: 'Общий список',
  listOfUnsubscribersHeader: 'Список, отказавшихся от рассылки',
  listItemsQuality: '{{quality}} {{text}}',
  noItemsInList: 'нет номеров',
  numberInputPlaceholder: 'Номер телефона',
  numberInputDescription: 'Введите номер в международном формате. Пример: 79123456789',
  addButton: 'Добавить',
  deleteButton: 'Удалить',
  noNumbers: 'Нет номеров',
  BLACKLIST_TOAST_MESSAGES,
};

export const BLACKLIST_PAGE_TEXT_ENG = {
  header: 'Blacklist',
  searchInputPlaceholder: 'Search by number',
  commonListHeader: 'General list',
  listOfUnsubscribersHeader: 'List of unsubscribers',
  listItemsQuality: '{{quality}} {{text}}',
  noItemsInList: 'no numbers',
  numberInputPlaceholder: 'Phone number',
  numberInputDescription: 'Enter the number in international format. Example: 79123456789',
  addButton: 'Add',
  deleteButton: 'Delete',
  noNumbers: 'No numbers',
  BLACKLIST_TOAST_MESSAGES: BLACKLIST_TOAST_MESSAGES_ENG,
};

export const NUMBER_MAX_LENGTH = 14;
