import { getEUDateFromString } from '@helpers/index';
import { TSendOutDetailsStatisticsData } from '@models/index';
import { DETAILED_MAILING_STATISTICS_TABLE } from '@const/mailing';
import {
  TSendOutDetailStatisticsResponse,
  TSendOutDetailsStatisticsParsingResponse,
} from '@api/types';

type TGetStatsData = (
  data: TSendOutDetailStatisticsResponse,
) => TSendOutDetailsStatisticsParsingResponse[];

export const getStatsData: TGetStatsData = data => {
  return data?.phone?.map((item, index) => {
    const recordTime = data.record_time?.[index]
      ? getEUDateFromString(data.record_time?.[index], true)
      : null;
    const replyTime = data.reply_time?.[index]
      ? getEUDateFromString(data.reply_time?.[index], true)
      : null;
    return {
      phone: item,
      name: data.name?.[index],
      record_time: recordTime,
      reply_time: replyTime,
      services: data.services?.[index] || null,
      summary_cost: data.summary_cost?.[index] || null,
    };
  });
};

const getTime = (timeStamp: string): string => {
  if (
    timeStamp === DETAILED_MAILING_STATISTICS_TABLE.NO_DATA ||
    timeStamp === DETAILED_MAILING_STATISTICS_TABLE.NO_TIME
  ) {
    return DETAILED_MAILING_STATISTICS_TABLE.NO_DATA;
  }
  const newTime = timeStamp.split('T');
  const date = newTime[0];
  const time = newTime[1]?.split('.')[0];

  return `${date} ${time}`;
};

export const sendOutStatisticsTableMappers = {
  responseToStore(data: TSendOutDetailStatisticsResponse): TSendOutDetailsStatisticsData[] {
    const newData = getStatsData(data);

    return newData.map(({ name, record_time, reply_time, services, summary_cost, phone }) => ({
      phone,
      name,
      recordTime: getTime(record_time || DETAILED_MAILING_STATISTICS_TABLE.NO_DATA),
      replyTime: getTime(reply_time || DETAILED_MAILING_STATISTICS_TABLE.NO_DATA),
      service: (services || []).join('\n') || DETAILED_MAILING_STATISTICS_TABLE.NO_DATA,
      summaryCost: summary_cost || 0,
    }));
  },
};
