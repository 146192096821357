import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { FeedbackModal } from '@blocks/feedbackModal';
import { getAccountsData } from '@redux/accounts/selectors';
import { getFeedbackData } from '@redux/feedback/selectors';
import { ToastsMessages } from '@components/toastsMessages';
import { useAppDispatch, useAppSelector } from '@store/store';
import { TOnChangeHandlerInputTextarea } from '@shared/types';
import { sendFeedbackData } from '@redux/feedback/feedbackThunk';
import {
  setFeedbackText,
  feedbackModalToggle,
  clearAllRequestStatuses,
} from '@redux/feedback/feedbackSlice';

type TFeedbackComponent = {
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const FeedbackComponent = memo(({ className = '' }: TFeedbackComponent) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { selectedFilial } = useAppSelector(getAccountsData);

  const {
    isError,
    mediaFile,
    isLoading,
    isSuccess,
    isModalOpen,
    feedbackText,
    mediaFileURL,
    errorMessage,
    mediaFileName,
    mediaFileType,
    successMessage,
    mediaFileTooBigError,
  } = useAppSelector(getFeedbackData);

  const modalToggleHandler = useCallback(() => {
    dispatch(feedbackModalToggle());
  }, [dispatch]);

  const setFeedbackTextHandler: TOnChangeHandlerInputTextarea = useCallback(
    event => {
      const { value } = event.currentTarget;

      dispatch(setFeedbackText(value));
    },
    [dispatch],
  );

  const sendFeedback = useCallback(() => {
    dispatch(
      sendFeedbackData({
        topic: 'review',
        file: mediaFile,
        text: feedbackText,
        filialName: selectedFilial?.address || '',
      }),
    );
  }, [feedbackText, mediaFile, selectedFilial?.address, dispatch]);

  const clearAllRequestStatusesHandler = useCallback(() => {
    dispatch(clearAllRequestStatuses());
  }, [dispatch]);

  return (
    <div className={`flex items-end grow ${className}`}>
      <Button
        dense
        icon='comment'
        type='action'
        view='outlined'
        color='default'
        loading={false}
        disabled={false}
        onClick={modalToggleHandler}
        text={t('BASE_TEMPLATE_PAGE_TEXT.feedback.button')}
        className='bottom-8 sticky w-[15rem]'
      />
      <FeedbackModal
        text={feedbackText}
        isOpen={isModalOpen}
        isLoading={isLoading}
        mediaFileURL={mediaFileURL}
        mediaFileType={mediaFileType}
        mediaFileName={mediaFileName}
        isSendFeedbackSuccess={isSuccess}
        sendFeedbackCallback={sendFeedback}
        closeModalCallback={modalToggleHandler}
        onChangeCallback={setFeedbackTextHandler}
        mediaFileTooBigError={mediaFileTooBigError}
      />
      <ToastsMessages
        isShowErrorToast={isError}
        errorMessage={errorMessage}
        isShowSuccessToast={isSuccess}
        successMessage={successMessage}
        clearErrorCallback={clearAllRequestStatusesHandler}
        clearSuccessStatusCallback={clearAllRequestStatusesHandler}
      />
    </div>
  );
});

FeedbackComponent.displayName = 'FeedbackComponent';
