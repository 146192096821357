import React, { memo, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
//
import { PATH_NAMES } from '@data/dict';
import { NoAccess } from '@components/noAccess';
import { ErrorMessage } from '@blocks/errorMessage';
import { getAccountsData } from '@redux/accounts/selectors';
import { getFeedbackData } from '@redux/feedback/selectors';
import { ToastsMessages } from '@components/toastsMessages';
import { useAppDispatch, useAppSelector } from '@store/store';
import { InviteFriendsTemplate } from '@templates/inviteFriends';
import { getActionListThunk } from '@redux/inviteFriendList/inviteFriendsThunk';
import { getInviteFriendsListPageData } from '@redux/inviteFriendList/selectors';
import { InviteFriendActivateModal } from '@components/inviteFriendActivateModal';
import { getCardTypesThunk } from '@redux/inviteFriendsAction/inviteFriendsActionThunk';
import { getInviteFriendsStatisticsData } from '@redux/inviteFrendsStatistics/selectors';
import { clearAllErrorIFA } from '@redux/inviteFrendsStatistics/inviteFriendsStatisticsSlice';
import { getInviteFriendsAnalytics } from '@redux/inviteFrendsStatistics/inviteFriendsStatisticsThunk';
import {
  clearAllErrorIFL,
  hideWarningModalIFL,
  clearAllStatusMessages,
} from '@redux/inviteFriendList/inviteFriendListSlice';

const InviteFriendPage = memo(() => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const isShowCardList = pathname.includes(PATH_NAMES.INVITE_FRIEND_LIST);
  const isShowStatistics = pathname.includes(PATH_NAMES.INVITE_FRIEND_STATISTICS);

  const { isLoading: isFeedbackSending } = useAppSelector(getFeedbackData);

  const {
    actionObj,
    fastReview,
    isDeleting,
    actionOrder,
    isSwitching,
    feedbackText,
    isDeletingError,
    isDeletingSuccess,
    isShowWarningModal,
    isError: listError,
    isLoading: listLoading,
    errorMessage: listErrorMessage,
    isAccessDenied: listAccessDenied,
    successMessage: listSuccessMessage,
  } = useAppSelector(getInviteFriendsListPageData);

  const {
    isError,
    isLoading,
    errorMessage,
    isAccessDenied,
    data: statisticsData,
  } = useAppSelector(getInviteFriendsStatisticsData);

  const { selectedFilial } = useAppSelector(getAccountsData);

  useEffect(() => {
    if (selectedFilial?.accId && isShowCardList) {
      dispatch(clearAllErrorIFL());
      dispatch(getCardTypesThunk());
      dispatch(getActionListThunk());
    }
    return () => {
      dispatch(clearAllErrorIFL());
    };
  }, [selectedFilial?.accId, isShowCardList, dispatch]);

  useEffect(() => {
    if (selectedFilial?.accId && isShowStatistics) {
      dispatch(clearAllErrorIFA());
      dispatch(getInviteFriendsAnalytics());
    }
    return () => {
      dispatch(clearAllErrorIFA());
    };
  }, [selectedFilial?.accId, isShowStatistics, dispatch]);

  const isShowError = listError || isError;

  const errorMessageToShow = errorMessage || listErrorMessage;

  const isShowAccessDeniedWarning = listAccessDenied || isAccessDenied;

  const hideWarningModalHandler = useCallback(() => {
    dispatch(hideWarningModalIFL());
  }, [dispatch]);

  const clearAllStatusHandler = useCallback(() => {
    dispatch(clearAllStatusMessages());
  }, [dispatch]);

  return (
    <>
      {isShowAccessDeniedWarning ? <NoAccess /> : null}
      {isShowError ? <ErrorMessage /> : null}
      {!isShowError && !isShowAccessDeniedWarning ? (
        <InviteFriendsTemplate
          actionObj={actionObj}
          fastReview={fastReview}
          isDeleting={isDeleting}
          actionOrder={actionOrder}
          isSwitching={isSwitching}
          feedbackText={feedbackText}
          statisticsData={statisticsData}
          isDeletingError={isDeletingError}
          isLoading={isLoading || listLoading}
          isDeletingSuccess={isDeletingSuccess}
          isFeedbackSending={isFeedbackSending}
        />
      ) : null}
      <InviteFriendActivateModal isOpen={isShowWarningModal} hideModal={hideWarningModalHandler} />
      <ToastsMessages
        errorMessage={errorMessageToShow}
        successMessage={listSuccessMessage}
        isShowErrorToast={!!errorMessageToShow}
        isShowSuccessToast={!!listSuccessMessage}
        clearErrorCallback={clearAllStatusHandler}
        clearSuccessStatusCallback={clearAllStatusHandler}
      />
    </>
  );
});

InviteFriendPage.displayName = 'InviteFriendPage';

export default InviteFriendPage;
