import React, { memo } from 'react';
//
import { Loader } from '@blocks/loader';
import { useAppSelector } from '@store/store';
import { OperationListTable } from '@components/operationListTable';
import { getOperationListTablePageData } from '@redux/operationListTable/selectors';
import { TAppLanguage } from '@models/index';

interface IOperationListProps {
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const OperationList = memo(({ appLanguage, className = '' }: IOperationListProps) => {
  const { isLoading } = useAppSelector(getOperationListTablePageData);
  const { data, totalAccrued } = useAppSelector(getOperationListTablePageData);

  return (
    <div className={className}>
      {isLoading ? (
        <Loader className='h-[50vh]' />
      ) : (
        <OperationListTable
          data={data}
          appLanguage={appLanguage}
          totalAccrued={totalAccrued}
          className='overflow-x-auto mt-10'
        />
      )}
    </div>
  );
});

OperationList.displayName = 'OperationList';
