import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { CONFIG } from '@const/config';
import { REG_EXP } from '@const/common';
import { PATH_NAMES } from '@data/dict';
import { useAppDispatch } from '@store/store';
import { useNavigate } from 'react-router-dom';
import { TOnChangeHandler } from '@shared/types';
import { TgBotLoading } from '@blocks/tgBotLoading';
import { CONNECTION_TG_STATUS } from '@const/connect';
import { TgBotAuthenticated } from '@blocks/tgBotAuthenticated';
import { TConnectionTGStatusData } from '@redux/connection/types';
import { ConnectionTgBotSetUp } from '@components/connectionTgBotSetUp';
import { ConnectionStatusCardTgBot } from '@blocks/connectionStatusCardTgBot';
import { changePageOnClick, getLinkHref, validatePhoneNumber } from '@helpers/index';
import {
  setPinCode,
  setPassword,
  setPhoneNumber,
  setInputErrorCP,
  changePhoneNumber,
  restartConnection,
} from '@redux/connection/connectionSlice';
import {
  sendPhoneThunk,
  logoutChanelThunk,
  sendAuthCodeThunk,
  restartChanelThunk,
  send2FaPasswordThunk,
} from '@redux/connection/connectionThunks';

type TConnectionTelegramBotProps = {
  /**
   * AccId выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Данные TG бота
   * @param {TConnectionWAStatusData}
   */
  tgData: TConnectionTGStatusData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const ConnectionTelegramBot = memo(
  ({ selectedFilialAccId, tgData, className = '' }: TConnectionTelegramBotProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { phone, tgStatus, password, requestStatus } = tgData;

    // Обрабатывает изменения в поле ввода телефона
    const setPhoneNumberHandler = useCallback(
      (newPhone: string) => {
        dispatch(setPhoneNumber(newPhone));
      },
      [dispatch],
    );

    // Обрабатывает изменения в поле ввода кода авторизации
    const setPinHandler = useCallback(
      (value: string) => {
        const newValue = value.replace(REG_EXP.onlyNumbers, '');

        dispatch(setPinCode(newValue));

        if (newValue.length === 5) {
          dispatch(sendAuthCodeThunk(newValue));
        }
      },
      [dispatch],
    );

    // Обрабатывает изменения в поле ввода пароля для 2х этапной авторизации
    const setPasswordHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;

        dispatch(setPassword(value));
      },
      [dispatch],
    );

    // Отправляет номер телефона для получения пароля от TG
    const sendCodeButtonHandler = useCallback(
      (phoneNumber: string) => () => {
        const phoneLength = phoneNumber.length;

        if (phoneLength < 10) {
          dispatch(setInputErrorCP(true));
        } else {
          dispatch(sendPhoneThunk(validatePhoneNumber(phoneNumber)));
        }
      },
      [dispatch],
    );

    // Отправляет пароль 2х этапной авторизации
    const connectButtonHandler = useCallback(
      (pass: string) => () => {
        dispatch(send2FaPasswordThunk(pass));
      },
      [dispatch],
    );

    // Переключает шаг формы для редактирования введенного телефона
    const changePhoneNumberHandler = useCallback(() => {
      dispatch(changePhoneNumber());
    }, [dispatch]);

    // Переводит пользователя на страницу оплат
    const navigateToPayPage = useCallback(() => {
      navigate(getLinkHref({ route: PATH_NAMES.PAY, accId: selectedFilialAccId }));
    }, [navigate, selectedFilialAccId]);

    // Отключает бота
    const logoutTgBot = useCallback(() => {
      dispatch(logoutChanelThunk());
    }, [dispatch]);

    // Открывает страницу со ссылкой на канал поддержки в TG
    const contactTechSupport = useCallback(() => {
      changePageOnClick({ href: CONFIG.SUPPORT_TELEGRAM_LINK, isTargetBlank: true });
    }, []);

    const restartConnectionHandler = useCallback(() => {
      dispatch(restartConnection());
    }, [dispatch]);

    // Перезапускает бота
    const restartTgBot = useCallback(() => {
      dispatch(restartChanelThunk());
    }, [dispatch]);

    // Текст заголовка карточки для разных состояний подключения
    const headerToRender: Record<string, string> = useMemo(
      () => ({
        [CONNECTION_TG_STATUS.NOT_PAID]: t(
          'CONNECTION_PAGE_TEXT.telegramBotConnection.payBotHeader',
        ),
        [CONNECTION_TG_STATUS.NOT_SET_UP]: t(
          'CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpHeader',
        ),
        [CONNECTION_TG_STATUS.BLOCKED]: t(
          'CONNECTION_PAGE_TEXT.telegramBotConnection.blockedHeader',
        ),
        [CONNECTION_TG_STATUS.ERROR]: t('CONNECTION_PAGE_TEXT.telegramBotConnection.errorHeader'),
      }),
      [t],
    );

    // Текст описания карточки для разных состояний подключения
    const descriptionToRender: Record<string, string> = useMemo(
      () => ({
        [CONNECTION_TG_STATUS.NOT_PAID]: t(
          'CONNECTION_PAGE_TEXT.telegramBotConnection.payBotDescription',
        ),
        [CONNECTION_TG_STATUS.NOT_SET_UP]: t(
          'CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpDescription',
        ),
        [CONNECTION_TG_STATUS.BLOCKED]: t(
          'CONNECTION_PAGE_TEXT.telegramBotConnection.blockedDescription',
        ),
        [CONNECTION_TG_STATUS.ERROR]: t(
          'CONNECTION_PAGE_TEXT.telegramBotConnection.errorDescription',
        ),
      }),
      [t],
    );

    // Текст кнопки карточки для разных состояний подключения
    const buttonTextToRender: Record<string, string> = useMemo(
      () => ({
        [CONNECTION_TG_STATUS.NOT_PAID]: t('CONNECTION_PAGE_TEXT.telegramBotConnection.payButton'),
        [CONNECTION_TG_STATUS.BLOCKED]: t(
          'CONNECTION_PAGE_TEXT.telegramBotConnection.blockedButton',
        ),
        [CONNECTION_TG_STATUS.ERROR]: t('CONNECTION_PAGE_TEXT.telegramBotConnection.errorButton'),
      }),
      [t],
    );

    // Цвет кнопки карточки для разных состояний подключения
    const buttonColor = tgStatus === CONNECTION_TG_STATUS.OK ? 'danger' : 'default';

    // Иконка кнопки карточки для разных состояний подключения
    const buttonIcon = tgStatus === CONNECTION_TG_STATUS.OK ? 'disable' : null;

    // Обработчики для кнопки карточки для разных состояний подключения
    const simpleCardButtonHandler = {
      [CONNECTION_TG_STATUS.NOT_PAID]: navigateToPayPage,
      [CONNECTION_TG_STATUS.OK]: logoutTgBot,
      [CONNECTION_TG_STATUS.ERROR]: contactTechSupport,
      [CONNECTION_TG_STATUS.BLOCKED]: contactTechSupport,
    };

    // Флаг отображения простой карточки
    const isShowSimpleCard =
      tgStatus === CONNECTION_TG_STATUS.NOT_PAID ||
      tgStatus === CONNECTION_TG_STATUS.NO_CONNECT ||
      tgStatus === CONNECTION_TG_STATUS.BLOCKED ||
      tgStatus === CONNECTION_TG_STATUS.ERROR;

    // Флаг получения данных
    const isLoading = !(requestStatus === 'success' || requestStatus === 'error');

    return (
      <div className={className}>
        <div className='flex flex-col'>
          <div className='flex flex-col grow items-start justify-between max-w-[46.3125rem] w-full'>
            {isShowSimpleCard ? (
              <ConnectionStatusCardTgBot
                isLoading={isLoading}
                buttonIcon={buttonIcon}
                buttonColor={buttonColor}
                headerText={headerToRender[tgStatus] || ''}
                buttonText={buttonTextToRender[tgStatus] || ''}
                isError={
                  tgStatus === CONNECTION_TG_STATUS.ERROR ||
                  tgStatus === CONNECTION_TG_STATUS.BLOCKED
                }
                onClickHandler={simpleCardButtonHandler[tgStatus]}
                descriptionText={descriptionToRender[tgStatus] || ''}
              />
            ) : null}
            {tgStatus === CONNECTION_TG_STATUS.OK ? (
              <TgBotAuthenticated
                logoutBotHandler={logoutTgBot}
                restartBotHandler={restartTgBot}
                isLogout={requestStatus === 'logout'}
                isRestart={requestStatus === 'restart'}
                className=''
              />
            ) : null}
            {tgStatus === CONNECTION_TG_STATUS.LOADING ? <TgBotLoading /> : null}
            {tgStatus === CONNECTION_TG_STATUS.REQUEST_ERROR ? (
              <div className='flex'>
                <div className='flex flex-col mr-2 grow'>
                  <h1 className='font-medium text-blackText text-h6Mobile leading-8 tracking-[0.004em] mb-1'>
                    {t(
                      'CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpBlock.requestErrorLabel',
                    )}
                  </h1>
                  <p className='text-red2 leading-6 tracking-[0.0275em]'>
                    {t(
                      'CONNECTION_PAGE_TEXT.telegramBotConnection.notSetUpBlock.requestErrorDescription',
                    )}
                  </p>
                </div>
              </div>
            ) : null}
            {tgStatus === CONNECTION_TG_STATUS.NOT_SET_UP ? (
              <ConnectionTgBotSetUp
                tgData={tgData}
                setPinHandler={setPinHandler}
                setPasswordHandler={setPasswordHandler}
                headerText={headerToRender[tgStatus] || ''}
                setPhoneNumberHandler={setPhoneNumberHandler}
                changePhoneNumberHandler={changePhoneNumberHandler}
                descriptionText={descriptionToRender[tgStatus] || ''}
                restartConnectionHandler={restartConnectionHandler}
                sendCodeButtonHandler={sendCodeButtonHandler(phone)}
                connectButtonHandler={connectButtonHandler(password)}
                className='w-full'
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  },
);

ConnectionTelegramBot.displayName = 'ConnectionTelegramBot';
