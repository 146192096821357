import { NEW_EMPTY_ACCESS_BLOCK } from '@const/access';
import { TAccessDataItem } from '@redux/access/models';

export interface IInitialAccessesState {
  isLoading: boolean;
  isPosting: boolean;
  isGetSuccess: boolean;
  isPostSuccess: boolean;
  isUniqueTgIdError: boolean;
  isEmptyInputError: boolean;
  isError: boolean;
  isPostError: boolean;
  isAccessDenied: boolean;
  retries: number;
  isRetries: boolean;
  data: TAccessDataItem[];
  oldData: TAccessDataItem[];
  successMessage: string;
  errorMessage: string;
}

export const initialState: IInitialAccessesState = {
  isLoading: false,
  isPosting: false,
  isGetSuccess: false,
  isPostSuccess: false,
  isUniqueTgIdError: false,
  isEmptyInputError: false,
  isError: false,
  isPostError: false,
  isAccessDenied: false,
  retries: 1,
  isRetries: false,
  errorMessage: '',
  successMessage: '',
  data: [NEW_EMPTY_ACCESS_BLOCK],
  oldData: [NEW_EMPTY_ACCESS_BLOCK],
};
