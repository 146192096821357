import React, { memo, useRef } from 'react';
import { Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { TSendOutsDetailData } from '@redux/mailing/models';
import { SendOutDetailDataItem } from '@blocks/sendOutDetailDataItem';
import { useCloseByOutSideClick } from '@hooks/useCloseByOutSideClick';

type TSendOutDetailDataModalProps = {
  /**
   * Выбранная дата для отображения данных о запущенных рассылках
   * @param {Date}
   */
  selectedDate: Date;
  /**
   * Закрывает модалку
   * @param {() => void}
   */
  closeSendOutDataHandler: () => void;
  /**
   * Массив с данными рассылок в выбранном дне
   * @param {TSendOutsDetailData[]}
   */
  sendOutDetailData: TSendOutsDetailData[];
  /**
   * Флаг показа сообщения о невозможности запустить рассылку
   * @param {boolean}
   */
  isShowWarning: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const SendOutDetailDataModal = memo(
  ({
    selectedDate,
    isShowWarning,
    sendOutDetailData,
    closeSendOutDataHandler,
    className = '',
  }: TSendOutDetailDataModalProps) => {
    const { t } = useTranslation();

    // реф на компонент
    const ref = useRef<HTMLDivElement>(null);

    useCloseByOutSideClick({ closeElementCallback: closeSendOutDataHandler, ref });

    // Выбранная дата для отображения данных по рассылкам
    const date = selectedDate.toLocaleString('ru-Ru', { dateStyle: 'short' });

    // Информация по рассылкам
    const sendOutDetailsToRender = sendOutDetailData.map((item, index) => {
      const isLastElement = index === sendOutDetailData.length - 1;
      return (
        <SendOutDetailDataItem
          key={item.sendOutId}
          sendOutDetailData={item}
          className={!isLastElement ? 'mb-4' : ''}
        />
      );
    });

    return (
      <div ref={ref} className={`flex flex-col grow max-w-[25rem] w-full ${className}`}>
        <div className='bg-gray1 grow p-4 overflow-y-auto dropdown-list-scrollbar'>
          <div>
            <p className='text-small_text text-gray3 leading-3 tracking-[0.15em]'>{date}</p>
            <div
              className={`flex items-center ${
                isShowWarning ? 'justify-between' : 'justify-end'
              } mb-4 h-9`}>
              {isShowWarning ? (
                <h3 className='font-bold text-red2 text-h3Mobile leading-8 tracking-[0.0075em]'>
                  {t('MAILING_PAGE_TEXT.sendOutDetailModal.title')}
                </h3>
              ) : null}
              <button
                onClick={closeSendOutDataHandler}
                className='flex items-start justify-end h-9 w-9'>
                <Icon icon='cross' size={24} />
              </button>
            </div>
            {isShowWarning ? (
              <p className='text-myrtle leading-6 tracking-[0.0275em] mb-4'>
                {t('MAILING_PAGE_TEXT.sendOutDetailModal.warning')}
              </p>
            ) : null}
          </div>
          {sendOutDetailsToRender}
        </div>
      </div>
    );
  },
);

SendOutDetailDataModal.displayName = 'SendOutDetailDataModal';
