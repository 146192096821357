import React from 'react';
//
import { Button } from '@uikit/Button';

type MailingImageExampleElementProps = {
  /**
   * Ссылка на картинку
   * @param {string}
   */
  imgSrc: string;
  /**
   * Функция для добавления картинки в рассылку
   * @param {() => void}
   */
  addImageHandler: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const MailingImageExampleElement = ({
  imgSrc,
  addImageHandler,
  className = '',
}: MailingImageExampleElementProps) => {
  return (
    <div
      className={`group relative flex items-end justify-center rounded h-[10rem] overflow-x-hidden ${className}`}>
      <img src={imgSrc} alt='nice' className='h-[10rem] max-w-max' />
      <div className='absolute mb-2 h-10 hidden group-hover:block'>
        <Button
          dense
          type='action'
          view='filled'
          color='success'
          text='Вставить'
          onClick={addImageHandler}
        />
      </div>
    </div>
  );
};
