import React, { memo } from 'react';
//
import { Icon } from '@atoms/icon';
import { TAppLanguage } from '@models/index';
import { useAppDispatch } from '@store/store';
import { MAILING_STEPS } from '@const/mailing';
import { setActiveStep } from '@redux/mailing/mailingSlice';

interface IMailingStepIconsProps {
  /**
   * Флаг сообщающий что загружен файл в первом шаге рассылки
   * @param {boolean}
   */
  isSwitchToStepTwo: boolean;
  /**
   * Флаг сообщающий что загружен файл на втором шаге рассылки или добавлен текст
   * и можно переключаться на третий шаг
   * @param {boolean}
   */
  isSwitchToStepThree: boolean;
  /**
   * Номер активного шага
   * @param {number}
   */
  activeStep: number;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Опциональный параметр отключает возможность перехода на след шаг
   * @param {boolean}
   * @default
   */
  disabled?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const MailingStepIcons = memo(
  ({
    disabled,
    activeStep,
    appLanguage,
    isSwitchToStepTwo,
    isSwitchToStepThree,
    className = '',
  }: IMailingStepIconsProps) => {
    const dispatch = useAppDispatch();

    const setActiveStepHandler = (step: number) => () => {
      switch (step) {
        case 0: {
          dispatch(setActiveStep({ step }));
          break;
        }
        case 1: {
          if (isSwitchToStepTwo) {
            dispatch(setActiveStep({ step }));
          }
          break;
        }
        case 2: {
          if (isSwitchToStepTwo && isSwitchToStepThree) {
            dispatch(setActiveStep({ step }));
          }
          break;
        }
        default: {
          break;
        }
      }
    };

    const stepsToRender = Object.entries(MAILING_STEPS[appLanguage]).map((item, index) => {
      const key = `${item[0]}${index}`;

      const cursorType = !disabled
        ? index === 0
          ? 'cursor-pointer'
          : index === 1 && isSwitchToStepTwo
          ? 'cursor-pointer'
          : index === 2 && isSwitchToStepThree
          ? 'cursor-pointer'
          : 'cursor-not-allowed'
        : 'cursor-not-allowed';

      const backgroundColor = !disabled
        ? activeStep === index
          ? 'bg-green1'
          : activeStep === index + 1 || activeStep === index + 2
          ? 'bg-white'
          : 'bg-quartz1'
        : 'bg-quartz1';

      const isCompleted = activeStep === index + 1 || activeStep === index + 2;

      return (
        <div
          key={key}
          onClick={setActiveStepHandler(index)}
          className={`${cursorType} max-w-[33%] w-full`}>
          <div
            className={`bg-white flex items-center px-2 z-[1] w-fit ${
              index === 0 ? 'pr-2' : index === 1 ? 'px-2' : 'pl-2'
            }`}>
            <div
              className={`${backgroundColor} flex items-center justify-center rounded-full h-10 w-10`}>
              {isCompleted ? (
                <Icon variant='stepSuccess' />
              ) : (
                <span
                  className={`${
                    activeStep === index ? 'text-white' : 'text-grayText'
                  } font-medium text-h3Mobile`}>
                  {item[0]}
                </span>
              )}
            </div>
            <p
              className={`hidden sm:block font-medium text-h3Mobile leading-8 tracking-[0.004em] ${
                activeStep === index ? 'text-black' : 'text-grayText'
              } ml-2`}>
              {item[1]}
            </p>
          </div>
        </div>
      );
    });

    return (
      <div className={`relative flex justify-between ${className}`}>
        <hr className='absolute top-[50%] translate-y-[-50%] border-none bg-aquaSpring h-0.5 w-full z-[-1]' />
        {stepsToRender}
      </div>
    );
  },
);
