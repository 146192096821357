import React, { memo } from 'react';
//
import { payCardTags } from '@const/pay';
import { TAppLanguage } from '@models/index';
import { PayCard } from '@components/payCard';
import { TCardSelectHandler } from '@shared/types';
import { TCurrency, TMonthData } from '@api/types';

type TPayCards = {
  /**
   * Валюта для оплаты
   * @param {TCurrency}
   */
  currency: TCurrency;
  /**
   * Цена 1 месяца
   * @param {string}
   */
  amount: string;
  /**
   * Стоимость месяца с учетом бонусных недель
   * @param {TMonthData}
   */
  monthCost: TMonthData;
  /**
   * Массив с количеством месяцев для оплаты
   * @param {number}
   */
  numberOfMonth: number[];
  /**
   * Массив с количеством недель при оплате
   * @param {string[]}
   */
  numberOfGiftWeek: string[];
  /**
   * Номер выбранной карточки
   * @param {number}
   */
  selectedCardNumber: number;
  /**
   * Новый номер карточки которая выберется после завершения запроса на расчет цен
   * @param {number}
   */
  newCardNumber: number;
  /**
   * Флаг запроса цен
   * @param {boolean}
   */
  isCalculatingCoast: boolean;
  /**
   * Callback для выбора карточки для оплаты
   * @param {TCardSelectHandler}
   */
  cardSelectCallback: TCardSelectHandler;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * На сколько месяцев была произведена оплата
   * @param {number}
   */
  lastPayLength: number;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const PayCards = memo(
  ({
    amount,
    currency,
    monthCost,
    appLanguage,
    lastPayLength,
    numberOfMonth,
    newCardNumber,
    numberOfGiftWeek,
    cardSelectCallback,
    selectedCardNumber,
    isCalculatingCoast,
    className = '',
  }: TPayCards) => {
    const cardsToRender = numberOfMonth.map((item, index) => {
      const plusOneIndex = index + 1;
      const cardTotalMonth = numberOfMonth[index];
      const oneMonthCostWithDiscount = monthCost[numberOfMonth[index]];
      const profit = (monthCost['1'] - oneMonthCostWithDiscount) * cardTotalMonth;

      const isProfitInteger = parseInt(String(profit), 10) === profit;

      return (
        <PayCard
          key={cardTotalMonth}
          months={+item}
          currency={currency}
          appLanguage={appLanguage}
          tagKey={payCardTags[index]}
          oneMonthCost={Number(amount)}
          giftWeeks={numberOfGiftWeek[index]}
          isSelected={selectedCardNumber === plusOneIndex}
          isPayedTariff={+lastPayLength === +cardTotalMonth}
          onClickCallback={cardSelectCallback(plusOneIndex)}
          oneMonthCostWithDiscount={oneMonthCostWithDiscount}
          isLoading={newCardNumber === plusOneIndex && isCalculatingCoast}
          profit={isProfitInteger ? profit : +profit.toFixed(2)}
        />
      );
    });

    return (
      <div
        className={`sm:grid sm:grid-cols-4payCards gap-[1rem] flex overflow-x-auto w-screen sm:w-full ${className}`}>
        {cardsToRender}
      </div>
    );
  },
);

PayCards.displayName = 'PayCards';
