import React, { memo } from 'react';
//
import { TWithChildren } from '@models/index';

interface IH1Props extends TWithChildren {
  /**
   * Callback для обработки нажатия
   * @param {() => void}
   */
  onClick?: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const H1 = memo(({ onClick, children, className = '' }: IH1Props) => (
  <h1
    onClick={onClick}
    className={`font-inter text-[1.5rem] lg:text-h1 md:text-h2Mobile font-bold text-blackHeader m-0 tracking-[-0.01rem] ${className}`}>
    {children}
  </h1>
));

H1.displayName = 'H1';
