import { numberOfMonth } from '@const/pay';
import { getEUDateFromString } from '@helpers/index';
import { TCalculatedPrice, TPaymentsDataResponse, TPayResponseData } from '@api/types';
import {
  TPayData,
  TInvoiceData,
  TPaymentData,
  TCalculatePrice,
  TCreateInvoiceResponse,
} from '@redux/pay/types';

/**
 * Преобразует данные ответа API в структуру данных для хранилища Redux.
 * @param {TPayResponseData} response - Данные ответа API.
 * @returns {TPayData} - Преобразованные данные для хранилища Redux.
 */
const mapResponseDateToStore = ({
  amount,
  last_4,
  autopay,
  currency,
  discount,
  pay_date,
  pay_status,
  month_cost,
  bonus_week,
  create_bill,
  modules_price,
  active_modules,
  is_test_period,
  last_pay_length,
  branch_employees,
}: TPayResponseData): TPayData => {
  return {
    amount,
    currency,
    numberOfMonth,
    payTillDate: pay_date,
    payStatus: pay_status,
    monthCost: month_cost,
    createBill: create_bill,
    isAutoPayEnable: autopay,
    discount: discount * 100,
    cardLastFourDigits: last_4,
    modulesPrice: modules_price,
    isTestPeriod: is_test_period,
    activeModules: active_modules,
    lastPayLength: last_pay_length,
    branchEmployees: branch_employees,
    numberOfGiftWeek: Object.values(bonus_week).map(item => String(item)),
  };
};

const mapPaymentsDataToStore = (data: TPaymentsDataResponse[]): TPaymentData[] => {
  return data.map(({ date, period, amount, modules, type }) => ({
    date,
    channels: modules,
    paymentType: type,
    sum: Number(amount),
    period: period.replace(/-/gm, '.'),
  }));
};

const mapCalculatePriceToStore = ({
  full_cost,
  modules_cost,
  new_data_if_pay_days,
}: TCalculatedPrice): TCalculatePrice => {
  return {
    fullCoast: full_cost,
    modulesCost: modules_cost,
    paidPeriodReducedBy: 0,
    newPayTillDate: new_data_if_pay_days ? getEUDateFromString(new_data_if_pay_days) : '',
  };
};

const mapInvoiceDataToStore = ({
  inn,
  cost,
  length,
  pdf_url,
  branches,
  invoice_id,
  created_at,
}: TCreateInvoiceResponse): TInvoiceData => {
  return {
    inn,
    cost,
    length,
    pdfUrl: pdf_url,
    invoiceId: invoice_id,
    createdAt: created_at,
    branches: branches.map(({ branch_id, amount, name }) => ({
      name,
      amount,
      branchId: branch_id,
    })),
  };
};

export {
  mapInvoiceDataToStore,
  mapResponseDateToStore,
  mapPaymentsDataToStore,
  mapCalculatePriceToStore,
};
