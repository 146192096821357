import React, { memo } from 'react';
import { TAppLanguage } from '@models/index';
import { ChannelConnectionStatus } from '@blocks/connectionStatusTgBot';

type TProps = {
  /**
   * Ссылка на картинку
   * @param {string}
   */
  iconSrc: string;
  /**
   * Текст заголовка
   * @param {string}
   */
  headerText: string;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Статус бота
   * @param {string}
   */
  status: string;
  /**
   * Флаг выбора блока для настройки
   * @param {boolean}
   */
  isBlockSelected: boolean;
  /**
   * Активирует блок с настройками
   * @param {() => void}
   */
  setChanelToViewHandler: () => void;
  /**
   * Компонент с настройками канала
   * @param {React.ReactNode}
   */
  children: React.ReactNode;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const ConnectionMobileWrapper = memo(
  ({
    status,
    iconSrc,
    headerText,
    appLanguage,
    isBlockSelected,
    setChanelToViewHandler,
    children,
    className = '',
  }: TProps) => {
    const bgColor = isBlockSelected ? 'bg-gray1' : 'bg-white';

    return (
      <div className={className}>
        <div className={`border border-quartz ${bgColor} rounded-lg pt-5 px-2 pb-4 w-full`}>
          <div
            onClick={setChanelToViewHandler}
            className={`${bgColor} ${isBlockSelected ? 'mb-4' : ''}`}>
            <ChannelConnectionStatus
              status={status}
              iconSrc={iconSrc}
              headerText={headerText}
              appLanguage={appLanguage}
            />
          </div>
          {isBlockSelected ? children : null}
        </div>
      </div>
    );
  },
);

ConnectionMobileWrapper.displayName = 'ConnectionMobileWrapper';
