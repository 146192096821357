import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
//
import { Icon } from '@atoms/icon';
import { THelpKeys } from '@models/index';
import { createPortal } from 'react-dom';
import { AddEmoji } from '@atoms/addEmoji';
import { FormLabel } from '@blocks/formLabel';
import { EmojiLib } from '@components/emojiLib';
import { EmojiClickData } from 'emoji-picker-react';
import { getEmojiDate } from '@redux/emoji/selectors';
import { emojiPickerToggle } from '@redux/emoji/emojiSlice';
import { useAppDispatch, useAppSelector } from '@store/store';
import { TOnChangeHandlerInputTextarea, TTextareaFocusHandler } from '@shared/types';

interface ITextAreaWithLabelProps {
  /**
   * Значение textarea
   * @param {string}
   */
  value: string;
  /**
   * Параметр htmlFor для label
   * @param {THelpKeys}
   */
  htmlFor: THelpKeys;
  /**
   * Callback для изменения значения в textarea
   * @param {TOnChangeHandlerInputTextarea}
   */
  onChangeHandler: TOnChangeHandlerInputTextarea;
  /**
   * Скрывает текст над textarea
   * @param {boolean}
   */
  isHideLabel?: boolean;
  /**
   * Опциональный параметр определяет показывать или нет иконку с всплывающей подсказкой
   * @param {boolean}
   */
  showInfo?: boolean;
  /**
   * Флаг ошибки устанавливает стили ошибки в textarea
   * @param {boolean}
   */
  isError?: boolean;
  /**
   * Опциональный параметр включает и выключает иконку Emoji
   * @param {boolean}
   */
  isEmoji?: boolean;
  /**
   * Опциональный параметр callback для открытия emojiPicker
   * @param {() => void}
   */
  showEmojiPicker?: () => void;
  /**
   * Опциональный параметр callback для записи выбранного эмодзи в textarea
   * @param {(emoji: EmojiClickData, event: MouseEvent) => void}
   */
  setEmojiInTextarea?: (emoji: EmojiClickData, event: MouseEvent) => void;
  /**
   * Опциональный параметр
   * @param {({ name, isOpenEmoji }: { name: string; isOpenEmoji: boolean }) => void}
   */
  setViewEmojiPicker?: ({ name, isOpenEmoji }: { name: string; isOpenEmoji: boolean }) => void;
  /**
   * Опциональный параметр ID шага
   * @param {string}
   */
  stepId?: string;
  /**
   * Опциональный параметр отключает textarea
   * @param {boolean}
   * @default
   */
  disabled?: boolean;
  /**
   * Опциональный параметр устанавливает максимальное количество знаков
   * @param {number}
   * @default
   */
  maxLength?: number;
  /**
   * Классы в textarea
   * @param {string}
   */
  textareaClassName?: string;
  /**
   * Опциональный параметр добавляет * в label и устанавливает параметр required в textarea
   * @param {boolean}
   * @default
   */
  isRequired?: boolean;
  /**
   * Опциональный параметр placeHolder в textarea
   * @param {string}
   * @default
   */
  placeholder?: string;
  /**
   * Опциональный callback срабатывает на blur
   * @param {TTextareaFocusHandler}
   * @default
   */
  onBlurHandler?: TTextareaFocusHandler;
  /**
   * Опциональный параметр минимальная высота textarea
   * @param {number}
   * @default {120}
   */
  minHeight?: number;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const TextAreaWithLabel = memo(
  ({
    value,
    isEmoji,
    htmlFor,
    disabled,
    maxLength,
    isRequired,
    stepId = '0',
    isError = false,
    minHeight = 120,
    showInfo = true,
    onChangeHandler,
    placeholder = '',
    isHideLabel = false,
    textareaClassName = '',
    onBlurHandler = () => '',
    showEmojiPicker = () => '',
    setViewEmojiPicker = () => '',
    setEmojiInTextarea = () => '',
    className = '',
  }: ITextAreaWithLabelProps) => {
    // реф на textarea используется в useEffect
    // для установки размера окна в зависимости от количества введенного текста
    const ref = useRef<HTMLTextAreaElement>(null);

    const dispatch = useAppDispatch();
    const [isViewEmoji, setIsViewEmoji] = useState(false);
    const [isInnerError, setIsInnerError] = useState(false);
    const [maxLengthError, setMaxLengthError] = useState(false);
    const { isOpen, textAreaName, stepIdEmoji } = useAppSelector(getEmojiDate);

    useEffect(() => {
      if (htmlFor === textAreaName && isOpen && stepId === stepIdEmoji) {
        setIsViewEmoji(true);
      } else {
        setIsViewEmoji(false);
      }
    }, [isOpen, textAreaName, htmlFor, stepId, stepIdEmoji]);

    // высчитывает размер окна textarea в зависимости от количества введенного текста
    useEffect(() => {
      const textareaElement = ref.current as unknown as HTMLTextAreaElement;
      const fontSize = parseInt(
        window.getComputedStyle(textareaElement).getPropertyValue('font-size'),
        10,
      );
      textareaElement.style.removeProperty('height');
      const { scrollHeight } = textareaElement;

      if (scrollHeight < minHeight) {
        textareaElement.style.setProperty('height', `${minHeight / fontSize}rem`);
      } else {
        textareaElement.style.setProperty('height', `${scrollHeight / fontSize}rem`);
      }
    }, [value, minHeight]);

    // если в пропсах передали ошибку, выставляет ошибку локально
    useEffect(() => {
      setIsInnerError(isError);
    }, [isError]);

    // Ели передан параметр maxLength, устанавливает/убирает ошибку превышения максимальной длинны сообщения
    useEffect(() => {
      if (value && value.length > (maxLength || Infinity)) {
        if (!maxLengthError) {
          setMaxLengthError(true);
        }
      } else if (maxLength) {
        setMaxLengthError(false);
      }
    }, [value, maxLength, maxLengthError, isRequired]);

    // Вызывает callback для сохранения имени "покинутой" textarea
    const setIsTouchedOnBlur: TTextareaFocusHandler = useCallback(
      event => {
        onBlurHandler(event);
      },
      [onBlurHandler],
    );

    // Устанавливает/снимает ошибку
    const errorClassToggle = useCallback(
      (event: React.KeyboardEvent<HTMLTextAreaElement> | React.FocusEvent<HTMLTextAreaElement>) => {
        const textValue = event.currentTarget.value;

        if (isRequired && !textValue) {
          setIsInnerError(true);
        }
        if (textValue) {
          setIsInnerError(false);
        }
      },
      [isRequired],
    );

    const emojiPickerHandler = useCallback(
      ({ name, isOpenPicker }: { name: string; isOpenPicker: boolean }) =>
        () => {
          if (!disabled) {
            setViewEmojiPicker({ name, isOpenEmoji: isOpenPicker });
            showEmojiPicker();
          }
        },
      [disabled, setViewEmojiPicker, showEmojiPicker],
    );

    const onClickEmoji = useCallback(() => {
      dispatch(emojiPickerToggle({ name: '', isOpen: false, stepIdEmoji: '0' }));
    }, [dispatch]);

    return (
      <div className={`${isViewEmoji ? 'z-[19]' : ''} min-h-[${minHeight}px] ${className}`}>
        {!isHideLabel ? (
          <FormLabel
            className='mb-[0.375rem]'
            htmlFor={htmlFor}
            showInfo={showInfo}
            isRequired={isRequired}
            textColor='!text-grayText'
            labelTextVariant={htmlFor}
          />
        ) : null}
        <div className='relative'>
          {isEmoji ? (
            <AddEmoji
              disabled={disabled}
              className={`absolute top-[0.2rem] z-[19] ${
                (isInnerError || maxLengthError) && !disabled ? 'right-8' : 'right-1'
              }`}
              callback={emojiPickerHandler({ name: htmlFor, isOpenPicker: !isViewEmoji })}
            />
          ) : null}
          {isViewEmoji ? (
            <EmojiLib
              onEmojiClick={setEmojiInTextarea}
              className='absolute z-[101] top-[-250px] right-0 max-w-[350px]'
            />
          ) : null}
          <textarea
            ref={ref}
            spellCheck
            id={htmlFor}
            name={htmlFor}
            disabled={disabled}
            required={isRequired}
            placeholder={placeholder}
            className={`pr-10 transition-all duration-150 ease-in-out ${
              (isInnerError || maxLengthError) && !disabled
                ? 'shadow-[0_0_0_1px_rgb(250,169,163,1)] active:shadow-[0_0_0_2px_rgb(156,43,35,1)] focus:shadow-[0_0_0_2px_rgb(156,43,35,1)] hover:shadow-[0_0_0_1px_rgb(156,43,35,1)] innerError'
                : 'shadow-[0_0_0_1px_rgb(180,180,187,1)] active:shadow-[0_0_0_2px_rgb(58,58,68,1)] focus:shadow-[0_0_0_2px_rgb(58,58,68,1)] hover:shadow-[0_0_0_1px_rgb(58,58,68,1)]'
            } ${(isInnerError || maxLengthError) && !disabled ? 'placeholder-lightRed' : ''} ${
              disabled ? 'hover:!shadow-[0_0_0_1px_rgb(180,180,187,1)]' : ''
            } disabled:bg-white disabled:cursor-not-allowed rounded-lg whitespace-pre-line break-words px-3 py-2 w-full ${textareaClassName}`}
            onChange={onChangeHandler}
            onBlur={setIsTouchedOnBlur}
            onFocus={errorClassToggle}
            onKeyUp={errorClassToggle}
            value={value}
          />
          {(isInnerError || maxLengthError) && !disabled ? (
            <Icon variant='errorInInput' className='absolute top-[0.55rem] right-[0.6rem]' />
          ) : null}
        </div>
        <div className='leading-[0.5rem]'>
          {maxLength && value.length ? (
            <span className='text-grayText m-0 tracking-[0.033em] text-[12px]'>{`${value.length} / ${maxLength}`}</span>
          ) : isInnerError ? (
            <p className='text-grayText mt-[1px] mb-0 tracking-[0.033em] text-[12px]'>
              Введите текст
            </p>
          ) : null}
        </div>
        {isViewEmoji
          ? createPortal(
              <div
                onClick={onClickEmoji}
                className='absolute top-0 bottom-0 left-0 right-0 z-[18]'
              />,
              document.body,
            )
          : null}
      </div>
    );
  },
);

TextAreaWithLabel.displayName = 'TextAreaWithLabel';
