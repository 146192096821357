export const ConfigENV = {
  BASE_URL: process.env.REACT_APP_API_URL,
  SEND_OUT_BASE_URL: process.env.REACT_APP_SEND_OUT_BASE_URL,
  AUTO_CONNECT_BASE_URL: process.env.REACT_APP_API_AUTO_CONNECT,
  YCLIENTS_START_PAGE_BASE_URL: process.env.REACT_APP_API_YCLIENTS_START_PAGE,
};

export enum REQUEST_STATUS {
  SUCCESS = 200,
  ACCESS_DENIED = 401,
  TOKEN_EXPIRED = 403,
}

export enum REQUEST_TEXT_ERROR_STATUS {
  FAIL_RIGHT = 'Fail rights',
  ACCESS_DENIED = 'Access denied',
  TOKEN_EXPIRED = 'Expired token',
  ACCOUNT_NOT_FOUNDED = 'accounts not found',
  OBJECT_NOT_UPDATE = 'Object not update',
  KEY_ERROR = 'Некорректные ключи в сообщении',
}
