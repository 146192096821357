import { TActionData, TCardType } from '@redux/inviteFriendsAction/type';
import {
  TGetInviteFriendsCartType,
  TInviteFriendsActionResponse,
  TInviteFriendsCard,
} from '@api/types';

export const notSelectedCardResponse: TInviteFriendsCard = {
  id: 0,
  title: 'Не выбрано',
  salon_group_id: 0,
};

export const getActionByIdResponse: TInviteFriendsActionResponse = {
  title: 'Название этой акции 6',
  card_type: {
    id: 60137,
    title: 'Тест Алексей',
    salon_group_id: 795106,
  },
  bonus_type: 'first_visit',
  loyalty_points: 50,
  interest_regular_customer: null,
  new_customer_points: 40,
  range_of_visits: [0, 99],
  first_message:
    'Привет, если приведешь друга будет бонус(текст какие бонусы ждут и как это работает). второе сообщение отправь другу!',
  second_message:
    'Для того чтобы получить бонусы как новый клиент, нажми на эту ссылку которая будет в самом низу сообщения, тебя перекинет в ватсап и отправь сообщение с промокодом.',
  services: null,
  categories: null,
  staff: null,
  record_labels: null,
  client_category: null,
  after_end: null,
  before_start: null,
  all_day_recs_notifications: {
    day: '0',
    time: '15 00',
  },
  visits: null,
  blacklist: null,
  active: false,
  sending_bonuses: true,
  date_create_template: null,
  date_first_activate_template: null,
  id: '1303181',
  loyalty_permission_status: true,
};

export const actionMockData: TActionData = {
  title: 'Название этой акции 6',
  oldTitle: 'Название этой акции 6',
  cardType: {
    id: 60137,
    title: 'Тест Алексей',
    salonGroupId: 795106,
  },
  bonusType: 'first_visit',
  loyaltyPoints: '50',
  interestRegularCustomer: '0',
  newCustomerPoints: '40',
  rangeOfVisits: '',
  firstMessage:
    'Привет, если приведешь друга будет бонус(текст какие бонусы ждут и как это работает). второе сообщение отправь другу!',
  secondMessage:
    'Для того чтобы получить бонусы как новый клиент, нажми на эту ссылку которая будет в самом низу сообщения, тебя перекинет в ватсап и отправь сообщение с промокодом.',
  services: [],
  categories: [],
  staff: [],
  recordLabels: [],
  clientCategory: [],
  afterEnd: '0',
  beforeStart: '0',
  allDayRecsNotifications: {
    day: '0',
    time: '15 00',
  },
  visits: ['', ''],
  blacklist: [],
  active: false,
  sendingBonuses: true,
  id: '1303181',
  whenSend: 'before_start',
  whenSend2: '0.25',
  whenSend2Days: '0',
  whenSend2Min: '15',
  whenTime: '00:00',
  onlineRecord: false,
  dateCreateTemplate: null,
  dateFirstActivateTemplate: null,
  loyaltyPermissionStatus: true,
};

export const notSelectCardType: TCardType = {
  id: 0,
  title: 'Не выбрано',
  salonGroupId: 0,
};

export const actionEmptyData: TActionData = {
  title: '',
  oldTitle: '',
  cardType: notSelectCardType,
  bonusType: 'notSelected',
  loyaltyPoints: '0',
  interestRegularCustomer: '0',
  newCustomerPoints: '0',
  rangeOfVisits: '',
  firstMessage: '',
  secondMessage: '',
  services: [],
  categories: [],
  staff: [],
  recordLabels: [],
  clientCategory: [],
  afterEnd: '0',
  beforeStart: '0',
  allDayRecsNotifications: {
    day: '0',
    time: '15 00',
  },
  visits: ['', ''],
  blacklist: [],
  active: false,
  sendingBonuses: false,
  id: '',
  whenSend: 'before_start',
  whenSend2: '0.25',
  whenSend2Days: '0',
  whenSend2Min: '15',
  whenTime: '00:00',
  onlineRecord: false,
  dateCreateTemplate: null,
  dateFirstActivateTemplate: null,
  loyaltyPermissionStatus: true,
};

export const newActionData: TActionData = {
  title: 'Новый шаблон',
  oldTitle: 'Новый шаблон',
  cardType: {
    id: 0,
    title: 'Не выбрано',
    salonGroupId: 0,
  },
  bonusType: 'first_visit',
  loyaltyPoints: '0',
  interestRegularCustomer: '0',
  newCustomerPoints: '0',
  rangeOfVisits: '',
  firstMessage: '',
  secondMessage: '',
  services: [],
  categories: [],
  staff: [],
  recordLabels: [],
  clientCategory: [],
  afterEnd: '0',
  beforeStart: '0',
  allDayRecsNotifications: {
    day: '0',
    time: '15 00',
  },
  visits: ['', ''],
  blacklist: [],
  active: false,
  sendingBonuses: false,
  id: '',
  whenSend: 'before_start',
  whenSend2: '0.25',
  whenSend2Days: '0',
  whenSend2Min: '15',
  whenTime: '00:00',
  onlineRecord: false,
  dateCreateTemplate: null,
  dateFirstActivateTemplate: null,
  loyaltyPermissionStatus: true,
};

export const loyaltyCardMockData: TCardType[] = [
  {
    id: 63717,
    title: 'АБ',
    salonGroupId: 348542,
  },
  {
    id: 56573,
    title: 'Приведи друга',
    salonGroupId: 348542,
  },
  {
    id: 60137,
    title: 'Тест Алексей',
    salonGroupId: 795106,
  },
  {
    id: 56625,
    title: 'Тестовый тип',
    salonGroupId: 348542,
  },
];

export const loyaltyCardResponseMockData: TGetInviteFriendsCartType[] = [
  {
    id: 63717,
    title: 'АБ',
    salon_group_id: 348542,
  },
  {
    id: 56573,
    title: 'Приведи друга',
    salon_group_id: 348542,
  },
  {
    id: 60137,
    title: 'Тест Алексей',
    salon_group_id: 795106,
  },
  {
    id: 56625,
    title: 'Тестовый тип',
    salon_group_id: 348542,
  },
];

export const inviteFriendsMinPriceResponseMockData = [
  {
    id: 5872948,
    title: 'Тест',
    category_id: 5872947,
    price_min: 1000,
  },
];
