import { validateAutoResponseWords } from '@helpers/index';
import { TAutoResponseItem, TUpdateAutoResponseThunkData } from '@redux/autoResponse/models';
import {
  TGetAutoResponseData,
  TAutoResponseItemData,
  TUpdateAutoResponseItemSendData,
} from '@api/types';

export const autoResponseMappers = {
  responseToStore(data: TGetAutoResponseData): TAutoResponseItem[] {
    return data.items.map(({ active, reply, words }) => ({
      id: words.join(','),
      isActive: active,
      clientRequest: words,
      responseToClient: reply,
    }));
  },
  responseToBack(data: TAutoResponseItem[], activeItemId: string): TAutoResponseItemData {
    const [activeItemData] = data.filter(item => item.id === activeItemId);

    return {
      reply: activeItemData.responseToClient,
      words: validateAutoResponseWords(activeItemData.clientRequest),
      active: activeItemData.isActive,
      caption: '',
    };
  },
  updateAutoResponseItemData({
    data,
    activeItemId,
  }: TUpdateAutoResponseThunkData): TUpdateAutoResponseItemSendData {
    const [activeAutoResponseItem] = data.filter(item => item.id === activeItemId);
    const { clientRequest, responseToClient, isActive } = activeAutoResponseItem;

    return {
      active: isActive,
      reply: responseToClient,
      caption: '',
      new_words: validateAutoResponseWords(clientRequest),
      old_words: activeItemId.split(',').filter(item => !!item),
    };
  },
};
