import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { ICON_VARIANT } from '@data/dict';

interface IBotButtonProps {
  /**
   * Callback для обработки события onChange
   * @param {TOnChangeHandler}
   */
  callBack: () => void;
  /**
   * Вариант текста кнопки, берется по ключу из словаря
   * @param {'edit' | 'deleteReview'}
   */
  variant: 'edit' | 'deleteReview';
  /**
   * Опциональный параметр настраивает цвет кнопки
   * @param {string}
   */
  color?: string;
  /**
   * Опциональный параметр позволяет отключить кнопку
   * @param {boolean}
   */
  disabled?: boolean;
  /**
   * Параметр позволяет показать загрузчик в кнопке
   * @param {boolean}
   */
  isLoading?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const ReviewButton = memo(
  ({ color, variant, disabled, callBack, isLoading, className = '' }: IBotButtonProps) => {
    const { t } = useTranslation();

    return (
      <div>
        <Button
          dense
          type='action'
          view='outlined'
          onClick={callBack}
          loading={isLoading}
          disabled={disabled}
          color={color || `default`}
          text={t(`REVIEW_PAGE_TEXT.REVIEW_BUTTON_TEXT.${variant}`)}
          icon={
            <img
              src={ICON_VARIANT[variant]}
              alt={t(`REVIEW_PAGE_TEXT.REVIEW_BUTTON_TEXT.${variant}`)}
              className='h-4 w-4'
            />
          }
          className={`${className} !bg-white hidden sm:flex min-w-[13rem]`}
        />
        <Button
          dense
          type='icon'
          view='outlined'
          onClick={callBack}
          loading={isLoading}
          disabled={disabled}
          color={color || `default`}
          icon={
            <img
              src={ICON_VARIANT[variant]}
              alt={t(`REVIEW_PAGE_TEXT.REVIEW_BUTTON_TEXT.${variant}`)}
              className='h-4 w-4'
            />
          }
          className={`${className} !bg-white sm:hidden`}
        />
      </div>
    );
  },
);

ReviewButton.displayName = 'ReviewButton';
