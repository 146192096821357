import React, { memo } from 'react';
//
import { THelpKeys, TSimpleStringObj } from '@models/index';
import { InputWithDropdownFromEP } from '@blocks/inputWithDropdownFromEP';
import { TAddBalloonOnBlurHandler, TDeleteBalloonSet } from '@shared/types';
import { REVIEW_STEP_ONE_KEY_NAMES, TReviewsStep1Data } from '@redux/reviews/models';

type TReviewInputWithDropdownListProps = {
  /**
   * Флаг состояния запроса
   * @param {boolean}
   */
  isFetchingSuccess: boolean;
  /**
   * ID выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Параметр используется для добавления нового шарика в блок staff
   * @param {string}
   */
  staffNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок recordTag
   * @param {string}
   */
  recordTagNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок services
   * @param {string}
   */
  servicesNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок categories
   * @param {string}
   */
  categoriesNewValue: string;
  /**
   * Параметр используется для добавления нового шарика в блок clientCategory
   * @param {string}
   */
  clientCategoryNewValue: string;
  /**
   * Данные для отображения выпадающих списков в dropdown
   * @param {{ [key: string]: TSimpleStringObj }}
   */
  categoriesDataList: { [key: string]: TSimpleStringObj };
  /**
   * Callback для удаления Chips component
   * @param {TDeleteBalloonSet}
   */
  deleteBalloon: TDeleteBalloonSet;
  /**
   * Callback для добавления Chips component при событии blur
   * @param {TAddBalloonOnBlurHandler}
   */
  addBalloonOnBlur: TAddBalloonOnBlurHandler;
  /**
   * Данные первого шага цепочки
   * @param {TReviewsStep1Data}
   */
  data: TReviewsStep1Data;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const ReviewInputWithDropdownList = memo(
  ({
    deleteBalloon,
    staffNewValue,
    servicesNewValue,
    addBalloonOnBlur,
    recordTagNewValue,
    isFetchingSuccess,
    categoriesNewValue,
    categoriesDataList,
    selectedFilialAccId,
    clientCategoryNewValue,
    data,
    className = '',
  }: TReviewInputWithDropdownListProps) => {
    const inputWithDropdownFromEPData = [
      {
        addedValues: data.recordTag,
        newValue: recordTagNewValue,
        inputId: REVIEW_STEP_ONE_KEY_NAMES.RECORD_TAG,
        labelTextVariant: REVIEW_STEP_ONE_KEY_NAMES.RECORD_TAG,
        classNameText: 'mb-8',
      },
      {
        addedValues: data.services,
        newValue: servicesNewValue,
        inputId: REVIEW_STEP_ONE_KEY_NAMES.SERVICES,
        labelTextVariant: REVIEW_STEP_ONE_KEY_NAMES.SERVICES,
        classNameText: 'mb-8',
      },
      {
        addedValues: data.categories,
        newValue: categoriesNewValue,
        inputId: REVIEW_STEP_ONE_KEY_NAMES.CATEGORIES,
        labelTextVariant: REVIEW_STEP_ONE_KEY_NAMES.CATEGORIES,
        classNameText: 'mb-8',
      },
      {
        addedValues: data.staff,
        newValue: staffNewValue,
        inputId: REVIEW_STEP_ONE_KEY_NAMES.STAFF,
        labelTextVariant: REVIEW_STEP_ONE_KEY_NAMES.STAFF,
        classNameText: 'mb-8',
      },
      {
        addedValues: data.clientCategory,
        newValue: clientCategoryNewValue,
        inputId: REVIEW_STEP_ONE_KEY_NAMES.CLIENT_CATEGORY,
        labelTextVariant: REVIEW_STEP_ONE_KEY_NAMES.CLIENT_CATEGORY,
        classNameText: '',
      },
    ];

    const inputWithDropdownFromEPTORender = inputWithDropdownFromEPData.map(
      ({ inputId, classNameText, labelTextVariant, addedValues, newValue }) => (
        <InputWithDropdownFromEP
          key={inputId}
          inputId={inputId}
          newValue={newValue}
          addedValues={addedValues}
          deleteBalloon={deleteBalloon}
          addBalloonOnBlur={addBalloonOnBlur}
          isFetchingSuccess={isFetchingSuccess}
          categoriesDataList={categoriesDataList}
          selectedFilialAccId={selectedFilialAccId}
          labelTextVariant={labelTextVariant as THelpKeys}
          className={classNameText}
        />
      ),
    );

    return <div className={className}>{inputWithDropdownFromEPTORender}</div>;
  },
);

ReviewInputWithDropdownList.displayName = 'ReviewInputWithDropdownList';
