import { TSimpleStringObj } from '@models/index';
import { TAccessDataItem, TAccessRight } from '@redux/access/models';

export enum RIGHT_ITEM_NAMES {
  ALL = 'all',
  MANEGE_WAPP = 'manage_wapp',
  SENDOUTS = 'sendouts',
  TRANSITION_SWITCH = 'translation_switch',
  EDIT_TEMPLATES = 'edit_templates',
  HIDE_TRANSLATION_PHONES = 'hide_translation_phones',
  // NOTIFY = 'notify',
}

export const RIGHT_MENU_ITEMS: Record<string, TSimpleStringObj> = {
  rus: {
    [RIGHT_ITEM_NAMES.ALL]: 'Выбрать всё',
    [RIGHT_ITEM_NAMES.MANEGE_WAPP]: 'Управление WA',
    // [RIGHT_ITEM_NAMES.NOTIFY]: 'Трансляции',
    [RIGHT_ITEM_NAMES.SENDOUTS]: 'Доступ к рассылкам',
    [RIGHT_ITEM_NAMES.TRANSITION_SWITCH]: 'Трансляции',
    [RIGHT_ITEM_NAMES.EDIT_TEMPLATES]: 'Редактирование шаблонов',
    [RIGHT_ITEM_NAMES.HIDE_TRANSLATION_PHONES]: 'Скрыть номера в трансляции',
  },
  eng: {
    [RIGHT_ITEM_NAMES.ALL]: 'Select all',
    [RIGHT_ITEM_NAMES.MANEGE_WAPP]: 'WA Management',
    // [RIGHT_ITEM_NAMES.NOTIFY]: 'Broadcasts',
    [RIGHT_ITEM_NAMES.SENDOUTS]: 'Access to mailing lists',
    [RIGHT_ITEM_NAMES.TRANSITION_SWITCH]: 'Broadcasts',
    [RIGHT_ITEM_NAMES.EDIT_TEMPLATES]: 'Editing templates',
    [RIGHT_ITEM_NAMES.HIDE_TRANSLATION_PHONES]: 'Hide numbers in broadcast',
  },
};

export const RIGHT_ARRAY: TAccessRight[] = [
  // RIGHT_ITEM_NAMES.NOTIFY,
  RIGHT_ITEM_NAMES.SENDOUTS,
  RIGHT_ITEM_NAMES.MANEGE_WAPP,
  RIGHT_ITEM_NAMES.EDIT_TEMPLATES,
  RIGHT_ITEM_NAMES.TRANSITION_SWITCH,
  RIGHT_ITEM_NAMES.HIDE_TRANSLATION_PHONES,
];

export const NEW_EMPTY_ACCESS_BLOCK: TAccessDataItem = {
  userId: '',
  name: '',
  rights: [],
};

export enum ROUTES_ACCESS_RIGHTS {
  OWNER = 'owner',
  SENDOUTS = 'sendouts',
  MANAGE_WAPP = 'manage_wapp',
  EDIT_TEMPLATES = 'edit_templates',
  TRANSLATION_SWITCH = 'translation_switch',
}

export const ACCESS_PAGE_TEXT = {
  header: 'Доступы',
  accessBlockFirstInputPlaceholder: 'Телеграм ID',
  accessBlockSecondInputPlaceholder: 'Описание',
  dropdownLabelSelected: 'Выбрано: {{selected}}',
  dropdownLabelSelectInList: 'Выберите из списка',
  addButton: 'Добавить пользователя',
  saveButton: 'Сохранить',
};

export const ACCESS_PAGE_TEXT_ENG = {
  header: 'Access',
  accessBlockFirstInputPlaceholder: 'Telegram ID',
  accessBlockSecondInputPlaceholder: 'Description',
  dropdownLabelSelected: 'Selected: {{selected}}',
  dropdownLabelSelectInList: 'Select from list',
  addButton: 'Add user',
  saveButton: 'Save',
};
