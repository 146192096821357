import React, { memo, useCallback, useEffect, useState } from 'react';
import { Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
//
import { Label } from '@atoms/label';
import { Button } from '@uikit/Button';
import { useTimer } from '@hooks/useTimer';
import { useAppDispatch } from '@store/store';
import { useCopyToClipboard } from '@hooks/useClipboard';
import { PhoneNumberInput } from '@atoms/phoneNumberInput';
import { getAuthCode } from '@redux/connection/connectionThunks';
import { PhoneNumberWithChangeButton } from '@blocks/phoneNumberWithChangeButton';
import { GET_UNIQUE_CODE_TIMEOUT_MIN, GET_UNIQUE_CODE_TIMEOUT_SEC } from '@const/connect';
import { setPhoneConnectionStep, setWAPhoneNumber } from '@redux/connection/connectionSlice';
import { PHONE_CONNECTION_STEP, TPhoneConnectionStatus } from '@redux/connection/initialState';

type TProps = {
  /**
   * Шаг формы подключения по телефону
   * @param {PHONE_CONNECTION_STEP}
   */
  phoneConnectionStep: PHONE_CONNECTION_STEP;
  /**
   * Статус подключения по телефону
   * @param {TPhoneConnectionStatus}
   */
  phoneConnectionStatus: TPhoneConnectionStatus;
  /**
   * Номер телефона для отправки кода
   * @param {string}
   */
  phoneNumber: string;
  /**
   * Уникальный код для WA
   * @param {string}
   */
  uniqueCode: string;
  /**
   * Отображает callout с предложением обновить страницу для получения нового статуса
   * @param {() => void}
   */
  showGetStatusCalloutHandler: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const WaPhoneConnection = memo(
  ({
    uniqueCode,
    phoneNumber,
    phoneConnectionStep,
    phoneConnectionStatus,
    showGetStatusCalloutHandler,
    className = '',
  }: TProps) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const [, copy] = useCopyToClipboard();

    const { minutes, seconds, isCompleted, reset } = useTimer({
      initialMinute: GET_UNIQUE_CODE_TIMEOUT_MIN,
      initialSeconds: GET_UNIQUE_CODE_TIMEOUT_SEC,
      isStart: phoneConnectionStep === PHONE_CONNECTION_STEP.SECOND,
    });

    const [showTickIcon, setShowTickIcon] = useState<boolean>(false);

    const [showRefreshButton, setShowRefreshButton] = useState<boolean>(false);

    useEffect(() => {
      if (isCompleted) {
        setShowRefreshButton(true);
        showGetStatusCalloutHandler();
      }
    }, [isCompleted, showGetStatusCalloutHandler]);

    const setPhoneNumberHandler = useCallback(
      (phone: string) => {
        dispatch(setWAPhoneNumber(phone));
      },
      [dispatch],
    );

    const sendCodeHandler = useCallback(() => {
      if (phoneNumber.length > 10) {
        dispatch(getAuthCode(phoneNumber))
          .unwrap()
          .then(() => {
            setShowRefreshButton(false);
            reset();
          });
      }
    }, [phoneNumber, reset, dispatch]);

    const changePhoneNumberHandler = useCallback(() => {
      dispatch(setPhoneConnectionStep(PHONE_CONNECTION_STEP.FIRST));
      setShowRefreshButton(false);
      reset();
    }, [reset, dispatch]);

    const copyCodeToClipboard = useCallback(
      (text: string) => () => {
        copy(text);

        setShowTickIcon(true);

        setTimeout(() => {
          setShowTickIcon(false);
        }, 5000);
      },
      [copy],
    );

    return (
      <div className={className}>
        <div className='flex flex-col'>
          <h3 className='font-medium text-[1.25rem] leading-8 tracking-[0.004em] mb-3'>
            {t('CONNECTION_PAGE_TEXT.botConnection.phoneConnection')}
          </h3>
          <ul className='pl-[1.5rem] leading-6 text-balticSea list-disc mt-[0.3rem] tracking-[0.0275em] mb-6'>
            <li>{t('CONNECTION_PAGE_TEXT.botConnection.ulPhone.first')}</li>
            <li>{t('CONNECTION_PAGE_TEXT.botConnection.ulPhone.second')}</li>
            <li>{t('CONNECTION_PAGE_TEXT.botConnection.ulPhone.third')}</li>
            <li>{t('CONNECTION_PAGE_TEXT.botConnection.ulPhone.fourth')}</li>
            <li>{t('CONNECTION_PAGE_TEXT.botConnection.ulPhone.sixth')}</li>
          </ul>
        </div>
        <div>
          {phoneConnectionStep === PHONE_CONNECTION_STEP.FIRST ? (
            <div>
              <div className='flex items-end'>
                <div className='mr-4'>
                  <Label
                    htmlFor='waPhoneNumber'
                    className='font-inter text-stormGray text-body_text font-medium mb-2'>
                    {t('CONNECTION_PAGE_TEXT.botConnection.waPhoneNumber')}
                  </Label>
                  <PhoneNumberInput
                    placeholder=''
                    value={phoneNumber}
                    onChangeHandler={setPhoneNumberHandler}
                  />
                </div>
                <Button
                  dense
                  type='action'
                  view='filled'
                  color='success'
                  onClick={sendCodeHandler}
                  loading={phoneConnectionStatus === 'loading'}
                  text={t('CONNECTION_PAGE_TEXT.botConnection.sendCode')}
                />
              </div>
            </div>
          ) : null}
          {phoneConnectionStep === PHONE_CONNECTION_STEP.SECOND ? (
            <div>
              <PhoneNumberWithChangeButton
                phoneNumber={phoneNumber}
                changePhoneNumberHandler={changePhoneNumberHandler}
                className='mb-4'
              />
              <div className='flex flex-col'>
                <span className='mb-2'>
                  {t('CONNECTION_PAGE_TEXT.botConnection.enterUniqueCode')}
                </span>
                <div className='bg-white border border-dGray rounded flex items-center justify-between py-2 px-3 max-w-[20.6875rem] w-full'>
                  <span className='font-bold text-blackText text-[1.25rem] leading-8 tracking-[0.26em]'>
                    {uniqueCode}
                  </span>
                  <button
                    onClick={copyCodeToClipboard(uniqueCode)}
                    className='border-none bg-none text-btnGreen text-small_text leading-7 tracking-[0.022em]'>
                    <span className='mr-2'>
                      {t('CONNECTION_PAGE_TEXT.botConnection.copyButton')}
                    </span>
                    <Icon icon={showTickIcon ? 'tick' : 'duplicate'} color='#65B168' size={18} />
                  </button>
                </div>
                {showRefreshButton ? (
                  <button
                    onClick={sendCodeHandler}
                    className='border-none bg-none text-btnGreen text-small_text leading-4 tracking-[0.022em] flex items-center mt-1'>
                    <Icon icon='repeat' color='#65B168' size={12} className='rotate-[249deg]' />
                    <span className='ml-2'>
                      {t('CONNECTION_PAGE_TEXT.botConnection.getANewUniqueCode')}
                    </span>
                  </button>
                ) : (
                  <span className='text-gray3 text-small_text leading-4 tracking-[0.033em] mt-1'>
                    {t('CONNECTION_PAGE_TEXT.botConnection.getANewUniqueCodeVia', {
                      time: `${minutes} : ${String(seconds).padStart(2, '0')}`,
                    })}
                  </span>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  },
);

WaPhoneConnection.displayName = 'WaPhoneConnection';
