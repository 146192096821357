import React, { forwardRef } from 'react';
import PropTypes, {InferProps} from 'prop-types';

import Component from './Dialog';

const propTypes = {
  /**
   `The type of the component.
   * Variants: `dense` `default`
   * Default value (if undefined): `default` `
   */
  type: PropTypes.oneOf(["default", "dense"]),
  /**
   ` The view of the component.
   * Variants: `smooth` `raised`
   * Default value (if undefined): `filled` `
   */
  view: PropTypes.oneOf(["filled", "smooth", "raised"]),
  /**
   ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    "default",
    "primary",
    "warning",
    "danger",
    "success",
    "primary_alt",
    "warning_alt",
    "danger_alt",
    "success_alt"
  ]),
  /**
   * Click event handler.
   */
  onClick: PropTypes.func,
  /**
   * Name of a icon to render in the dialog's header. Note that the header
   *      will only be rendered if title is provided.
   */
  icon: PropTypes.string,
  /**
   * Title of the dialog.
   */
  title: PropTypes.string,
  /**
   * Action text. Havn't default state.
   */
  text: PropTypes.element,
  /**
   * Opacity of backdrop. From 0 to 100 with step 10.
   */
  backdropOpacity: PropTypes.oneOf([
    0,
    10,
    20,
    30,
    40,
    50,
    60,
    70,
    80,
    90,
    100
  ]),
  /**
   * Left button of dialog.
   */
  leftButton: PropTypes.element,
  /**
   * Right button of dialog.
   */
  rightButton: PropTypes.element,
  /**
   * Toggles the visibility of the overlay and its children. This prop is required because the component is controlled.
   */
  isOpen: PropTypes.bool,
  /**
   * A callback that is invoked when user interaction causes the overlay to close, such as clicking on the overlay or pressing the esc key.
   */
  onClose: PropTypes.func
};

type TComponentProps = InferProps<typeof propTypes>;

interface IComponentProps extends TComponentProps {
  [key: string]: any;
}

export const Dialog: React.FC<IComponentProps> = forwardRef((props, ref) => <Component {...props} ref={ref}/>);