import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { CONFIG } from '@const/config';
import { BotButton } from '@blocks/botButton';
import whatsApp from '@img/connect/whatsApp.svg';
import { CONNECTION_STATUSES } from '@models/index';
import { ConnectionWA } from '@blocks/connectionWA';
import { LinkButton } from '@components/linkButton';
import { ChannelHeader } from '@blocks/channelHeader';
import { TConnectionWAStatusData, TQrCodeData } from '@redux/connection/types';
import { PHONE_CONNECTION_STEP, TPhoneConnectionStatus } from '@redux/connection/initialState';

type TConnectionWhatsAppBotProps = {
  /**
   * Callback для получения QR кода
   * @param {() => void}
   */
  getQr: () => void;
  /**
   * Данные QR кода
   * @param {TQrCodeData | undefined}
   */
  qrData: TQrCodeData | undefined;
  /**
   * Флаг процесса получения QR кода
   * @param {boolean}
   */
  isFetchingQR: boolean;
  /**
   * Флаг ошибки получения QR кода
   * @param {boolean}
   */
  isGetQRError: boolean;
  /**
   * Callback для очистки очереди сообщений
   * @param {() => void}
   */
  clearQuery: () => void;
  /**
   * Callback для остановки бота
   * @param {() => void}
   */
  stopWhatsAppBot: () => void;
  /**
   * Флаг процесса остановки бота
   * @param {boolean}
   */
  isBotStoppingQuery: boolean;
  /**
   * Callback для перезапуска бота
   * @param {() => void}
   */
  restartWhatsAppBot: () => void;
  /**
   * Флаг процесса перезапуска бота
   * @param {boolean}
   */
  isRestartingBot: boolean;
  /**
   * Статус подключения по телефону
   * @param {TPhoneConnectionStatus}
   */
  phoneConnectionStatus: TPhoneConnectionStatus;
  /**
   * Шаг формы подключения по телефону
   * @param {PHONE_CONNECTION_STEP}
   */
  phoneConnectionStep: PHONE_CONNECTION_STEP;
  /**
   * Номер телефона для отправки кода
   * @param {string}
   */
  phoneNumber: string;
  /**
   * Уникальный код для WA
   * @param {string}
   */
  uniqueCode: string;
  /**
   * Запрашивает данные по подключению каналов
   * @param {() => void}
   */
  getConnectionStatusCallback: () => void;
  /**
   * Данные страницы подключения
   * @param {TConnectionWAStatusData}
   */
  data: TConnectionWAStatusData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const ConnectionWhatsAppBot = memo(
  ({
    getQr,
    qrData,
    uniqueCode,
    clearQuery,
    phoneNumber,
    isFetchingQR,
    isGetQRError,
    stopWhatsAppBot,
    isRestartingBot,
    isBotStoppingQuery,
    restartWhatsAppBot,
    phoneConnectionStep,
    phoneConnectionStatus,
    getConnectionStatusCallback,
    data,
    className = '',
  }: TConnectionWhatsAppBotProps) => {
    const { t } = useTranslation();

    // деструктурезируем state
    const { status } = data;

    return (
      <div className={className}>
        <div className='flex flex-col justify-between'>
          {status === CONNECTION_STATUSES.GOT_QR_CODE ? (
            <ConnectionWA
              getQr={getQr}
              qrData={qrData}
              clearQuery={clearQuery}
              uniqueCode={uniqueCode}
              phoneNumber={phoneNumber}
              isGetQRError={isGetQRError}
              isFetchingQR={isFetchingQR}
              phoneConnectionStep={phoneConnectionStep}
              phoneConnectionStatus={phoneConnectionStatus}
              getConnectionStatusCallback={getConnectionStatusCallback}
              data={data}
            />
          ) : null}
          {status === CONNECTION_STATUSES.AUTHENTICATED ? (
            <div className='flex flex-col'>
              <ChannelHeader
                iconSrc={whatsApp}
                subheaderText=''
                headerText={t('CONNECTION_PAGE_TEXT.botConnection.botConnectedHeader')}
                className='mb-6'
              />
              <div className='flex flex-col sm:flex-row items-center my-2.5'>
                <BotButton
                  variant='stop'
                  callBack={stopWhatsAppBot}
                  isLoading={isBotStoppingQuery}
                  className='!bg-white !min-w-[12.6875rem] w-full sm:w-fit mb-2.5 sm:mb-0 mr-0 sm:mr-2.5'
                />
                <BotButton
                  variant='restart'
                  isLoading={isRestartingBot}
                  callBack={restartWhatsAppBot}
                  className='!bg-white !min-w-[14.375rem] w-full sm:w-fit'
                />
              </div>
            </div>
          ) : null}
          {status === CONNECTION_STATUSES.LOADING ? (
            <div className='flex flex-col'>
              <ChannelHeader
                iconSrc={whatsApp}
                subheaderText=''
                headerText={t('CONNECTION_PAGE_TEXT.botConnection.botLoadingHeader')}
                className='mb-6'
              />
              <div className='flex flex-col sm:flex-row items-center my-2.5'>
                <BotButton
                  variant='restart'
                  isLoading={isRestartingBot}
                  callBack={restartWhatsAppBot}
                  className='!bg-white !min-w-[14.375rem] w-full sm:w-fit'
                />
              </div>
            </div>
          ) : null}
          {status === CONNECTION_STATUSES.BLOCKED ? (
            <div className='flex flex-col'>
              <ChannelHeader
                iconSrc={whatsApp}
                subheaderText=''
                headerText={t('CONNECTION_PAGE_TEXT.botConnection.botBlockedHeader')}
                className='mb-6'
              />
              <div className='flex flex-col m:flex-row items-center my-2.5'>
                <span className='text text-darkGray text-[1.125rem] mr-2'>
                  {t('BASE_TEMPLATE_PAGE_TEXT.support.header')}
                </span>
                <LinkButton
                  targetBlank
                  icon='telegramCyan'
                  href={CONFIG.SUPPORT_TELEGRAM_LINK}
                  className='!bg-white'>
                  {t('BASE_TEMPLATE_PAGE_TEXT.support.telegramButton')}
                </LinkButton>
              </div>
            </div>
          ) : null}
          {status === CONNECTION_STATUSES.ERROR ? (
            <div className='flex flex-col'>
              <ChannelHeader
                iconSrc={whatsApp}
                subheaderText=''
                headerText={t('CONNECTION_PAGE_TEXT.botConnection.botErrorHeader')}
                className='mb-6'
              />
              <div className='flex flex-col m:flex-row items-center my-2.5'>
                <span className='text text-darkGray text-[1.125rem] mr-2'>
                  {t('BASE_TEMPLATE_PAGE_TEXT.support.header')}
                </span>
                <LinkButton
                  targetBlank
                  icon='telegramCyan'
                  href={CONFIG.SUPPORT_TELEGRAM_LINK}
                  className='!bg-white'>
                  {t('BASE_TEMPLATE_PAGE_TEXT.support.telegramButton')}
                </LinkButton>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  },
);

ConnectionWhatsAppBot.displayName = 'ConnectionWhatsAppBot';
