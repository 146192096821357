import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//
import { REG_EXP } from '@const/common';
import { storageDb } from '@api/storageApi';
import { THeaderBannerInfo } from '@api/types';
import { HEADER_BANNER_TYPES } from '@redux/accounts/models';
import { IInitialState, initialState } from '@redux/auth/initialState';
import { headerBanner, login, telegramId, tgLogin, updateToken } from '@redux/auth/authThunks';

import { TELEGRAM_ID_AUTH_KEY_NAME, TLoginData, TTelegramAuthData } from './models';

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthUserData(state: IInitialState, action: PayloadAction<TTelegramAuthData>) {
      state.authUserData = action.payload;
    },
    setAccessToken(state: IInitialState, action: PayloadAction<string>) {
      state.accessToken = action.payload;
    },
    logout(state: IInitialState) {
      storageDb.setAuth(false);
      state.auth = false;
    },
    setGetLoginInput(state: IInitialState, action: PayloadAction<boolean>) {
      state.isLoginInput = action.payload;
    },
    setInputError(state: IInitialState, action: PayloadAction<boolean>) {
      state.isInputError = action.payload;
    },
    changeAuthInputValue(
      state: IInitialState,
      action: PayloadAction<{ name: string; value: string }>,
    ) {
      const { name, value } = action.payload;
      if (name === TELEGRAM_ID_AUTH_KEY_NAME.PIN && value.length > 4) {
        return;
      }
      state[name] = value.replace(REG_EXP.onlyNumbers, '');
      state.isTgIdError = false;
      state.isPinError = false;
      state.isInputError = false;
    },
    setIsRedirectToConnectionPage(state: IInitialState, action: PayloadAction<boolean>) {
      state.isRedirectToConnectionPage = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(login.pending, (state: IInitialState) => {
      state.isError = false;
      state.isLoading = true;
    });
    builder.addCase(
      login.fulfilled,
      (state: IInitialState, action: PayloadAction<TLoginData | void>) => {
        if (action.payload) {
          const { auth, userId, accessToken } = action.payload;
          state.auth = auth;
          state.userId = userId;
          state.accessToken = accessToken;
        }
        state.isLoading = false;
      },
    );
    builder.addCase(login.rejected, (state: IInitialState) => {
      state.isError = true;
      state.isLoading = false;
    });
    builder.addCase(updateToken.pending, (state: IInitialState) => {
      state.isError = false;
      state.isLoading = true;
      state.isUpdTokenPending = true;
    });
    builder.addCase(
      updateToken.fulfilled,
      (state: IInitialState, action: PayloadAction<TLoginData | void>) => {
        if (action.payload) {
          const { auth, userId, accessToken } = action.payload;
          state.auth = auth;
          state.userId = userId;
          state.accessToken = accessToken;
        }
        state.isLoading = false;
        state.isUpdTokenSuccess = true;
        state.isUpdTokenPending = false;
      },
    );
    builder.addCase(updateToken.rejected, (state: IInitialState) => {
      state.isError = true;
      state.isLoading = false;
      state.isUpdTokenError = true;
      state.isUpdTokenPending = false;
      state.auth = false;
      storageDb.setAuth(false);
    });
    builder.addCase(telegramId.pending, (state: IInitialState) => {
      state.isLoading = true;
      state.isTgIdError = false;
      state.isLoginInput = true;
      state.isInputError = false;
      state.isPinError = false;
    });
    builder.addCase(telegramId.fulfilled, (state: IInitialState) => {
      state.isLoading = false;
      state.isLoginInput = false;
    });
    builder.addCase(telegramId.rejected, (state: IInitialState) => {
      state.isLoading = false;
      state.isTgIdError = true;
      state.isLoginInput = true;
    });

    builder.addCase(headerBanner.pending, (state: IInitialState) => {
      state.isLoading = true;
    });
    builder.addCase(
      headerBanner.fulfilled,
      (state: IInitialState, action: PayloadAction<THeaderBannerInfo | void>) => {
        state.isLoading = false;

        if (action.payload) {
          const { type, status } = action.payload;
          const headerBannerText = storageDb.getHeaderBannerText();

          if (status !== headerBannerText.text) {
            storageDb.setHeaderBannerStatus('');
            storageDb.setHeaderBannerText(status);
          }

          if (type === HEADER_BANNER_TYPES.SUCCESS) {
            state.status = '';
            state.type = '';
          }

          state.status = status;
          state.type = type;
        }
      },
    );
    builder.addCase(headerBanner.rejected, (state: IInitialState) => {
      state.isLoading = false;
      state.status = '';
      state.type = '';
    });

    builder.addCase(tgLogin.pending, (state: IInitialState) => {
      state.isLoading = true;
      state.isPinError = false;
      state.isInputError = false;
      state.isTgIdError = false;
    });
    builder.addCase(
      tgLogin.fulfilled,
      (state: IInitialState, action: PayloadAction<TLoginData | void>) => {
        if (action.payload) {
          const { auth, userId, accessToken } = action.payload;
          state.auth = auth;
          state.userId = userId;
          state.accessToken = accessToken;
          state.pin = '';
          state.userId = '';
        }
        state.isLoading = false;
      },
    );
    builder.addCase(tgLogin.rejected, (state: IInitialState) => {
      state.pin = '';
      state.isPinError = true;
      state.isLoading = false;
    });
  },
});

export const authReducer = authSlice.reducer;
export const {
  logout,
  setInputError,
  setAccessToken,
  setAuthUserData,
  setGetLoginInput,
  changeAuthInputValue,
  setIsRedirectToConnectionPage,
} = authSlice.actions;
