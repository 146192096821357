import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//
import { PATH_NAMES } from '@data/dict';
import { useAppSelector } from '@store/store';
import { AddLeads } from '@templates/addLeads';
import { getAppData } from '@redux/app/selectors';
import { PartnersNav } from '@blocks/partnersNav';
import { VIDEO_INSTRUCTIONS } from '@const/common';
import { ErrorMessage } from '@blocks/errorMessage';
import { OperationList } from '@templates/operationList';
import { getAccountsData } from '@redux/accounts/selectors';
import { HeaderWithButton } from '@components/headerWithButton';
import { PartnerProgramTemplate } from '@templates/partnerProgram';
import { PartnerPayHistoryTemplate } from '@templates/partnersPayHistory';

interface IPartnersTemplateProps {
  /**
   * Флаг показывает что аккаунт является салоном
   * @param {boolean}
   */
  isSalon: boolean;
  getLeadDataError: boolean;
  /**
   * Флаг показывает что аккаунт является смежной сущностью салон-консультант
   * @param {boolean}
   */
  isSalonConsultant: boolean;
  /**
   * Флаг ошибки получения данных для таблицы списка партнеров
   * @param {boolean}
   */
  getFullTableDataError: boolean;
  /**
   * Флаг ошибки получения данных для таблицы списка операций
   * @param {boolean}
   */
  getOperationTableDataError: boolean;
  /**
   * Флаг ошибки получения данных для таблицы истории платежей
   * @param {boolean}
   */
  getPaymentHistoryTableDataError: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const PartnersTemplate = ({
  isSalon,
  getLeadDataError,
  isSalonConsultant,
  getFullTableDataError,
  getOperationTableDataError,
  getPaymentHistoryTableDataError,
  className = '',
}: IPartnersTemplateProps) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const { appLanguage } = useAppSelector(getAppData);

  const { selectedFilial } = useAppSelector(getAccountsData);

  const selectFilialAccId = selectedFilial?.accId || '';

  return (
    <div className={className}>
      <HeaderWithButton
        headerText={t('PARTNER_PAGE_TEXT.header')}
        instructionData={VIDEO_INSTRUCTIONS.partners}
        className='mb-10'
      />
      <PartnersNav
        path={pathname}
        isSalon={isSalon}
        appLanguage={appLanguage}
        isSalonConsultant={isSalonConsultant}
        selectedFilialAccId={selectFilialAccId}
        className='mb-10'
      />
      {pathname.includes(PATH_NAMES.PARTNERS_PROGRAM) ? (
        !getFullTableDataError ? (
          <PartnerProgramTemplate
            isSalon={isSalon}
            appLanguage={appLanguage}
            isSalonConsultant={isSalonConsultant}
          />
        ) : (
          <ErrorMessage />
        )
      ) : null}
      {pathname.includes(PATH_NAMES.LIST_OF_OPERATION) ? (
        !getOperationTableDataError ? (
          <OperationList appLanguage={appLanguage} />
        ) : (
          <ErrorMessage />
        )
      ) : null}
      {pathname.includes(PATH_NAMES.PAYMENT_HISTORY) ? (
        !getPaymentHistoryTableDataError ? (
          <PartnerPayHistoryTemplate isSalon={isSalon} />
        ) : (
          <ErrorMessage />
        )
      ) : null}
      {pathname.includes(PATH_NAMES.ADD_LEADS) ? (
        !getLeadDataError ? (
          <AddLeads />
        ) : (
          <ErrorMessage />
        )
      ) : null}
    </div>
  );
};
