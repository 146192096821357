import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Icon } from '@atoms/icon';
import { Button } from '@uikit/Button';
import { MailingBlockingWarning } from '@blocks/mailingBlockingWarning';

type TSendSendOutProps = {
  /**
   * Флаг отключения кнопки начала рассылки
   * @param {boolean}
   */
  isDisableStartSendOutButton: boolean;
  /**
   * Callback для запуска рассылки
   * @param {() => void}
   */
  startMailing: () => void;
  /**
   * Флаг состояния запроса на запуск рассылки
   * @param {boolean}
   */
  isStartingSendOut: boolean;
  /**
   * Флаг состояния запроса на запуск рассылки
   * @param {boolean}
   */
  isTestMessageSending: boolean;
  /**
   * Callback для отправки тестового сообщения
   * @param {() => void}
   */
  sendTestMessageHandler: () => void;
  /**
   * Флаг отправки рассылки по выбранной дате
   * @param {boolean}
   */
  isScheduledLaunch: boolean;
  /**
   * Опциональный параметр отключает возможность запуска рассылки
   * @param {boolean}
   * @default
   */
  disabled?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const SendSendOut = memo(
  ({
    disabled,
    startMailing,
    isScheduledLaunch,
    isStartingSendOut,
    isTestMessageSending,
    sendTestMessageHandler,
    isDisableStartSendOutButton,
    className = '',
  }: TSendSendOutProps) => {
    const { t } = useTranslation();

    return (
      <div className={className}>
        <h2 className='font-bold text-black text-h3Mobile tracking-[0.0075em] mb-4'>
          {t('MAILING_PAGE_TEXT.testAndStart')}
        </h2>
        <p className='text-black leading-6 tracking-[0.0275em] mb-4'>
          {t('MAILING_PAGE_TEXT.thirdStepDescription')}
        </p>
        <div className='flex flex-col sm:flex-row items-start'>
          <Button
            dense
            type='action'
            view='outlined'
            color='default'
            disabled={disabled}
            loading={isTestMessageSending}
            onClick={sendTestMessageHandler}
            text={t('MAILING_PAGE_TEXT.sendTest')}
            icon={<Icon variant='whatsAppGreen' />}
            className='mr-4 mb-4 sm:mb-0 w-full sm:w-fit'
          />
          <Button
            dense
            type='action'
            view='filled'
            color='success'
            onClick={startMailing}
            loading={isStartingSendOut}
            icon={<Icon variant='playIvory' />}
            disabled={disabled || isDisableStartSendOutButton}
            text={
              !isScheduledLaunch
                ? t('MAILING_PAGE_TEXT.rightNowStartSendOut')
                : t('MAILING_PAGE_TEXT.scheduledStartSendOut')
            }
            className='mr-4 mb-4 sm:mb-0 w-full sm:w-fit'
          />
        </div>
        <MailingBlockingWarning className='mt-4 mb-6 sm:mb-8' />
      </div>
    );
  },
);
