import React, { useRef } from 'react';
//
import { PreviewMaker } from '@helpers/previewMaker';
import { TCustomerKeys } from '@redux/mailing/models';
import { reportPreviewDict, KEY_WRAPPER_SYMBOLS } from '@const/preview';

interface IPreviewComponent {
  /**
   * Описание задачи
   * @param {string}
   */
  description: string;
  /**
   * Параметр указывает на наличие загруженного медиа файла
   * @param {boolean}
   */
  isFileAdded?: boolean;
  /**
   * Компонент для показа загруженной пользователем картинки
   * @param {React.ReactNode}
   */
  mediaPreviewComponent?: React.ReactNode;
  /**
   * Пользовательские ключи полученные после парсинга файла с данными клиентов
   * @param {TCustomerKeys}
   */
  customerKeys?: TCustomerKeys;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const PreviewComponent = ({
  description,
  isFileAdded,
  mediaPreviewComponent,
  customerKeys = reportPreviewDict,
  className = '',
}: IPreviewComponent) => {
  const ref = useRef<HTMLDivElement>(null);

  const preview = new PreviewMaker({
    keyWrapperSymbol: KEY_WRAPPER_SYMBOLS,
    reportPreviewDictionary: customerKeys,
  });

  if (description && ref.current) {
    ref.current.innerHTML = preview.getPreviewMarkUp(description);
  }

  return (
    <div className={className}>
      {isFileAdded && mediaPreviewComponent ? mediaPreviewComponent : null}
      <div ref={ref} className='whitespace-pre-line overflow_wrap' />
    </div>
  );
};
