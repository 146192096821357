import { TSendOutBlackLitResponse } from '@api/types';
import { TBlackListData } from '@redux/blackList/models';

export const blackListMappers = {
  sendOutBlackListToStore(data: TSendOutBlackLitResponse[]): TBlackListData[] {
    return data.map(({ phone }) => ({
      number: phone,
    }));
  },
};
