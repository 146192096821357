import { apiService } from '@api/ApiService';
import { END_POINTS_URL } from '@const/apiConstants';
import { TAuthResponse, TAuthErrorResponse, TRequestStatus, THeaderBannerInfo } from '@api/types';
import {
  TCheckPinData,
  TTelegramAuthResponseData,
  TTelegramIDAuthResponseData,
} from '@redux/auth/models';

/**
 * API страницы авторизации
 * @module authApi
 */

export const authApi = {
  /**
   * Метод выполняет запрос для авторизации пользователя
   * @method loginUser
   * @param data {TTelegramAuthResponseData} данные пользователя для авторизации передает виджет телеграма
   * @return {Promise<AxiosResponse<TAuthResponse | TAuthErrorResponse, any>>}
   */
  loginUser(data: TTelegramAuthResponseData) {
    return apiService.post<TAuthResponse | TAuthErrorResponse>(END_POINTS_URL.GET_TOKEN, data);
  },
  /**
   * Обновляет токен.
   *
   * @param {string} token - токен  для обновления
   * @return {Promise<AxiosResponse<TAuthResponse | TAuthErrorResponse, any>>}
   */
  updateToken(token: string) {
    return apiService.get<TAuthResponse | TAuthErrorResponse>(END_POINTS_URL.UPDATE_TOKEN, {
      token,
    });
  },
  /**
   * Метод отправляет введенный пользователем telegramId для проверки, если пользователь указан в этом филиале
   * в telegram бота приходит пин для авторизации
   * @method getPin
   * @param data {number} телеграм ID
   * @return {Promise<AxiosResponse<TRequestStatus>>}
   */
  getPin: (data: number) => {
    return apiService.post<TRequestStatus>(END_POINTS_URL.AUTH_ID, { user_id: data });
  },
  /**
   * Метод отправляет введенный пользователем pin для проверки, если пин правильный в ответ приходят данные пользователя
   * @method checkPin
   * @param data {TCheckPinData} data содержит в себе телеграм id и пин
   * @return {Promise<AxiosResponse<TTelegramIDAuthResponseData | TRequestStatus>>}
   */
  checkPin: (data: TCheckPinData) => {
    return apiService.post<TTelegramIDAuthResponseData | TRequestStatus>(
      END_POINTS_URL.CHECK_PIN,
      {
        user_id: data.userId,
      },
      {
        params: {
          pin: data.pin,
        },
      },
    );
  },
  /**
   * Метод отправляет запрос за данными банера
   * @method getInfoBanner
   * @return {Promise<AxiosResponse<THeaderBannerInfo>>}
   */
  getInfoBanner: () => {
    return apiService.post<THeaderBannerInfo>(END_POINTS_URL.GET_INFO);
  },
};
