import { WHEN_SEND_VALUE_REVIEWS } from '@const/reviews';
import {
  TReviewData,
  TReviewChainData,
  TReviewInListData,
  TReviewsStep24Data,
  TReviewInListMapData,
} from '@redux/reviews/models';

export const reviewsEmptyTemplate: TReviewData = {
  id: 0,
  status: true,
  templates: {
    firstStep: {
      title: '',
      oldTitle: 'Новая цепочка',
      whatsapp: '',
      status: false,
      timeType: WHEN_SEND_VALUE_REVIEWS.ALL_DAY_RECS_NOTIFICATION,
      whenSend: '0',
      whenTime: '10:00',
      recordTag: [],
      statuses: [],
      services: [],
      staff: [],
      channels: [],
      channelsPhoneList: [],
      clientCategory: [],
      categories: [],
      blacklist: [],
      tgAlerts: [],
      quantityFrom: '',
      quantityTo: '',
      visits: '',
      id: 1,
    },
    secondStep: [
      {
        id: 2,
        status: false,
        whatsapp: '',
      },
    ],
    lastStep: {
      status: true,
      positive: '',
      negative: '',
      id: 5,
    },
  },
};

export const reviewsEmptyStep24: TReviewsStep24Data = {
  whatsapp: '',
  status: false,
  id: 2,
};

export const reviewsMockData: TReviewData[] = [
  {
    id: 1,
    status: true,
    templates: {
      firstStep: {
        whatsapp: 'текст сообщения whatsapp',
        title: 'Название цепочки',
        oldTitle: 'Название цепочки',
        status: true,
        timeType: '2',
        whenSend: '7',
        whenTime: 'undefined',
        statuses: ['-1', '0', '1', '2'],
        recordTag: ['2561906'],
        services: ['5872948'],
        staff: ['1083512'],
        channels: ['555', '555', '555'],
        clientCategory: ['VIP'],
        categories: ['5872947'],
        channelsPhoneList: ['77777777777'],
        blacklist: ['888', '888', '88'],
        quantityFrom: '6',
        quantityTo: '7',
        visits: '1',
        tgAlerts: ['123', '345', '678'],
        id: 1,
      },
      secondStep: [
        {
          whatsapp: 'текст сообщения whatsapp',
          status: true,
          id: 2,
        },
        {
          whatsapp: 'текст сообщения whatsapp',
          status: true,
          id: 3,
        },
        {
          whatsapp: 'текст сообщения whatsapp',
          status: true,
          id: 4,
        },
      ],
      lastStep: {
        status: true,
        positive: 'Текст сообщения для положительного отзыва',
        negative: 'Текст сообщение для отрицательного отзыва',
        id: 5,
      },
    },
  },
  {
    id: 2,
    status: true,
    templates: {
      firstStep: {
        whatsapp: 'шаг 1-2 сообщение',
        title: 'Цепочка 2',
        oldTitle: 'Цепочка 2',
        status: true,
        timeType: '2',
        whenSend: '7',
        whenTime: '12:00',
        statuses: ['-1', '0', '1', '2'],
        recordTag: [],
        services: ['222', '222'],
        staff: ['444', '444'],
        channels: ['666', '666'],
        channelsPhoneList: ['77777777777'],
        clientCategory: ['555', '555'],
        categories: ['333', '333'],
        blacklist: ['999'],
        quantityFrom: '7',
        quantityTo: '8',
        visits: '2',
        tgAlerts: ['123', '345', '678'],
        id: 1,
      },
      secondStep: [
        {
          whatsapp: 'шаг 2-2 сообщение',
          status: true,
          id: 2,
        },
        {
          whatsapp: 'шаг 3-2 сообщение',
          status: true,
          id: 3,
        },
        {
          whatsapp: 'шаг 4-2 сообщение',
          status: true,
          id: 4,
        },
      ],
      lastStep: {
        status: false,
        positive: 'шаг 5-2 сообщение для положительного отзыва',
        negative: 'шаг 5-2 сообщение для отрицательного отзыва',
        id: 5,
      },
    },
  },
  {
    id: 3,
    status: true,
    templates: {
      firstStep: {
        title: 'Цепочка 3',
        oldTitle: 'Цепочка 3',
        whatsapp: 'шаг 1-3 сообщение',
        status: true,
        timeType: '2',
        whenSend: '7',
        whenTime: '12:00',
        statuses: ['-1', '0', '1', '2'],
        recordTag: [],
        services: ['222', '222'],
        staff: ['444', '444'],
        channels: ['666', '666'],
        channelsPhoneList: ['77777777777'],
        clientCategory: ['555', '555'],
        categories: ['333', '333'],
        blacklist: ['999'],
        quantityFrom: '7',
        quantityTo: '8',
        visits: '3',
        tgAlerts: ['123', '345', '678'],
        id: 1,
      },
      secondStep: [
        {
          whatsapp: 'шаг 2-3 сообщение',
          status: true,
          id: 2,
        },
        {
          whatsapp: 'шаг 3-3 сообщение',
          status: true,
          id: 3,
        },
        {
          whatsapp: 'шаг 4-3 сообщение',
          status: true,
          id: 4,
        },
      ],
      lastStep: {
        status: false,
        positive: 'шаг 5-3 сообщение для положительного отзыва',
        negative: 'шаг 5-3 сообщение для отрицательного отзыва',
        id: 5,
      },
    },
  },
];

export const getReviewsListMockData: TReviewInListData[] = [
  {
    first_step: {
      id: '5343345',
      body: '{name}, спасибо, что доверяете нам!\n\nПожалуйста, подскажите, вам понравилась работа мастера 👩{master}?',
      active: true,
      title: 'Цепочка 5343345',
      time: '',
    },
    second_step: [
      {
        active: true,
        body: 'Тест 2го шага',
      },
    ],
    last_step: {
      active: false,
      body: 'Спасибо за высокие оценки, мы стараемся ради таких приятных моментов 😍\r\n\r\nБудем благодарны если также оставите небольшой отзыв по одной из следующих ссылок:\r\n\r\n👉https://go.2gis.com/b4n4c3\r\n👉https://yandex.ru/maps/-/CCUbQAaXGD\r\nотзыв мастеру 👩{master} {review_link}',
      fail_text: 'Жаль, что вам не все понравилось, напишите, что мы могли бы исправить',
    },
  },
  {
    first_step: {
      id: '7156081',
      body: 'Тест цепочки 1',
      active: true,
      title: 'Цепочка тест',
      time: '',
    },
    second_step: [
      {
        active: true,
        body: 'Тест цепочки 2',
      },
    ],
    last_step: {
      active: true,
      body: 'Тест цепочки 3 +',
      fail_text: 'Тест цепочки 3 -',
    },
  },
  {
    first_step: {
      id: '32566',
      body: 'Текст для Whatsapp',
      active: true,
      title: 'test',
      time: '',
    },
    second_step: [
      {
        active: false,
        body: 'Текст для Whatsapp\n2',
      },
      {
        active: false,
        body: 'Текст для Whatsapp\n3',
      },
      {
        active: false,
        body: 'Текст для Whatsapp\n4',
      },
    ],
    last_step: {
      active: true,
      body: 'Текст для положительного отзыва',
      fail_text: 'Текст для отрицательного отзыва',
    },
  },
];

export const reviewsListMockData: TReviewInListMapData[] = [
  {
    firstStep: {
      id: '5343345',
      body: '{name}, спасибо, что доверяете нам!\n\nПожалуйста, подскажите, вам понравилась работа мастера 👩{master}?',
      active: true,
      title: 'Цепочка 5343345',
      time: '',
    },
    secondStep: [
      {
        active: true,
        body: 'Тест 2го шага',
      },
    ],
    lastStep: {
      active: false,
      body: 'Спасибо за высокие оценки, мы стараемся ради таких приятных моментов 😍\r\n\r\nБудем благодарны если также оставите небольшой отзыв по одной из следующих ссылок:\r\n\r\n👉https://go.2gis.com/b4n4c3\r\n👉https://yandex.ru/maps/-/CCUbQAaXGD\r\nотзыв мастеру 👩{master} {review_link}',
      fail_text: 'Жаль, что вам не все понравилось, напишите, что мы могли бы исправить',
    },
  },
  {
    firstStep: {
      id: '7156081',
      body: 'Тест цепочки 1',
      active: true,
      title: 'Цепочка тест',
      time: '',
    },
    secondStep: [
      {
        active: true,
        body: 'Тест цепочки 2',
      },
    ],
    lastStep: {
      active: true,
      body: 'Тест цепочки 3 +',
      fail_text: 'Тест цепочки 3 -',
    },
  },
  {
    firstStep: {
      id: '32566',
      body: 'Текст для Whatsapp',
      active: true,
      title: 'test',
      time: '',
    },
    secondStep: [
      {
        active: false,
        body: 'Текст для Whatsapp\n2',
      },
      {
        active: false,
        body: 'Текст для Whatsapp\n3',
      },
      {
        active: false,
        body: 'Текст для Whatsapp\n4',
      },
    ],
    lastStep: {
      active: true,
      body: 'Текст для положительного отзыва',
      fail_text: 'Текст для отрицательного отзыва',
    },
  },
];

export const reviewChainData: TReviewChainData[] = [
  {
    id: 1,
    templates: [
      {
        id: 1,
        whatsapp:
          '{name}, спасибо, что доверяете нам!\n\nПожалуйста, подскажите, вам понравилась работа мастера 👩{master}?',
        status: false,
        whenSend: '2',
        whenSend2: '7',
        recordTag: [],
        services: [],
        staffs: ['-1749527'],
        channels: ['120363040733894434@g.us'],
        statuses: [],
        categories: [],
        clientCategory: [],
        blacklist: [],
      },
      {
        whatsapp:
          'Спасибо, теперь оцените, пожалуйста, сервис салона (работа администратора, атмосфера)',
        status: true,
        id: 2,
      },
      {
        status: true,
        positive:
          'Спасибо за высокие оценки, мы стараемся ради таких приятных моментов 😍\n\nБудем благодарны если также оставите небольшой отзыв по одной из следующих ссылок:\n\n👉https://yandex.ru/maps/-/CCUE5RqB3A\n👉https://go.2gis.com/ht1z0\nотзыв мастеру 👩{master} {review_link}',
        negative: 'Жаль, что вам не все понравилось, напишите, что мы могли бы исправить',
        id: 5,
      },
    ],
  },
];
