import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Loader } from '@blocks/loader';
import { TOnChangeHandler } from '@shared/types';
import { getAppData } from '@redux/app/selectors';
import { VIDEO_INSTRUCTIONS } from '@const/common';
import { useAppDispatch, useAppSelector } from '@store/store';
import { ChatHistoryTable } from '@components/chatHistoryTable';
import { HeaderWithButton } from '@components/headerWithButton';
import { getChatHistoryPageData } from '@redux/chatHistory/selectors';
import { SearchInputWithButton } from '@components/searchInputWithButton';
import {
  setPhoneSearch,
  searchByPhoneNumber,
  clearSearchPhoneString,
} from '@redux/chatHistory/chatHistorySlice';

export const ChatHistoryTemplate = memo(() => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { appLanguage } = useAppSelector(getAppData);

  const { isLoading, filteredTableData, phoneToSearch, phoneNumberUsedToSearch } =
    useAppSelector(getChatHistoryPageData);

  // Записывает поисковую строку в стор
  const setPhoneSearchHandler: TOnChangeHandler = useCallback(
    event => {
      const { value } = event.currentTarget;

      dispatch(setPhoneSearch(value));
    },
    [dispatch],
  );

  // Очищает поисковую строку записывает полные данные таблицы в filteredTableData
  const clearSearchString = useCallback(() => {
    dispatch(clearSearchPhoneString());
  }, [dispatch]);

  // Запускает поиск по сохраненной строке
  const startSearchHandler = useCallback(() => {
    dispatch(searchByPhoneNumber());
  }, [dispatch]);

  // Записывает поисковую строку в стор
  const setSearchPhoneNumberHandler = useCallback(
    (number: string) => {
      dispatch(setPhoneSearch(number));
    },
    [dispatch],
  );

  return (
    <div className='px-4 md:px-0 w-screen md:w-full'>
      <HeaderWithButton
        headerText={t('CHAT_HISTORY_PAGE_TEXT.header')}
        instructionData={VIDEO_INSTRUCTIONS.chatHistory}
        className='mb-10'
      />
      {isLoading ? (
        <Loader className='h-[50vh]' />
      ) : (
        <>
          <div className='mb-10 max-w-[32.125rem] w-full'>
            <SearchInputWithButton
              isChatHistoryPage
              searchString={phoneToSearch}
              startSearchHandler={startSearchHandler}
              clearSearchStringHandler={clearSearchString}
              setSearchStringHandler={setPhoneSearchHandler}
              buttonText={t('CHAT_HISTORY_PAGE_TEXT.searchButton')}
              disableSearchButton={isLoading || phoneToSearch.length < 10}
              placeholder={t('CHAT_HISTORY_PAGE_TEXT.searchInputPlaceholder')}
              additionalInputDescription={t('CHAT_HISTORY_PAGE_TEXT.searchInputDescription')}
            />
          </div>
          <ChatHistoryTable
            appLanguage={appLanguage}
            tableData={filteredTableData}
            phoneNumberUsedToSearch={phoneNumberUsedToSearch}
            setSearchPhoneNumberCallback={setSearchPhoneNumberHandler}
            className='overflow-x-auto'
          />
        </>
      )}
    </div>
  );
});

ChatHistoryTemplate.displayName = 'ChatHistoryTemplate';
