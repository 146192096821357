import { TConsultantData } from '@api/types';

export type TFilialData = {
  status: string;
  address: string;
  quantityOfTemplates: number;
  accId: string;
  sendOutAccount: boolean;
  branchId: string;
  isAdminBotIntegrated: boolean;
  payPeriod: string;
  payStatus: boolean;
  actionSendOut: string;
  rights: string[];
  testLoyalty: boolean;
};

export type TAccountsData = {
  consultantData: TConsultantData | null;
  accountsData: TFilialData[];
  bannerData: TBannerDataType | null;
};

export type TBannerDataType = {
  imageUrl: string;
  infoUrl: string;
};

export enum HEADER_BANNER_TYPES {
  SUCCESS = 'success',
}
