import { TReviewFirstStepInListData, TReviewSecondStepInListData } from '@api/types';

export type TReviewsStep1Data = {
  [key: string]: string | boolean | string[] | number;
  whatsapp: string;
  title: string;
  oldTitle: string;
  status: boolean;
  timeType: string;
  whenSend: string;
  whenTime: string;
  statuses: string[];
  services: string[];
  staff: string[];
  recordTag: string[];
  clientCategory: string[];
  channelsPhoneList: string[];
  categories: string[];
  blacklist: string[];
  quantityFrom: string;
  quantityTo: string;
  visits: string;
  tgAlerts: string[];
  id: number;
};

export enum REVIEW_STEP_ONE_KEY_NAMES {
  WHATSAPP = 'whatsapp',
  TITLE = 'title',
  OLD_TITLE = 'oldTitle',
  STATUS = 'status',
  TIME_TYPE = 'timeType',
  WHEN_SEND = 'whenSend',
  WHEN_TIME = 'whenTime',
  STATUSES = 'statuses',
  RECORD_TAG = 'recordTag',
  SERVICES = 'services',
  STAFF = 'staff',
  CLIENT_CATEGORY = 'clientCategory',
  CHANNEL_PHONE_LIST = 'channelsPhoneList',
  TG_ALERTS = 'tgAlerts',
  CATEGORIES = 'categories',
  BLACKLIST = 'blacklist',
  QUANTITY_FROM = 'quantityFrom',
  QUANTITY_TO = 'quantityTo',
  ID = 'id',
}

export type TReviewsStep24Data = {
  [key: string]: boolean | number | string;
  whatsapp: string;
  status: boolean;
  id: number;
};

export enum REVIEW_STEP_TWO_FOUR_KEY_NAMES {
  WHATSAPP = 'whatsapp',
  STATUS = 'status',
}

export type TReviewsStep5Data = {
  [key: string]: boolean | number | string;
  status: boolean;
  positive: string;
  negative: string;
  id: number;
};

export enum REVIEW_STEP_FIVE_KEY_NAMES {
  STATUS = 'status',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  ID = 'id',
}

export type TReviewData = {
  id: number;
  status: boolean;
  templates: {
    firstStep: TReviewsStep1Data;
    secondStep: TReviewsStep24Data[];
    lastStep: TReviewsStep5Data;
  };
};

export type TReviewLastStepInListData = {
  active: boolean;
  body: string;
  fail_text: string;
};

export type TReviewInListData = {
  first_step: TReviewFirstStepInListData;
  second_step: TReviewSecondStepInListData[];
  last_step: TReviewLastStepInListData;
};

export type TReviewInListMapData = {
  firstStep: TReviewFirstStepInListData;
  secondStep: TReviewSecondStepInListData[];
  lastStep: TReviewLastStepInListData;
};

export type TReviewChainData = {
  id: number;
  templates: [
    {
      id: number;
      whatsapp: string;
      status: boolean;
      whenSend: string;
      whenSend2: string;
      services: string[];
      staffs: string[];
      channels: string[];
      statuses: string[];
      recordTag: string[];
      categories: string[];
      clientCategory: string[];
      blacklist: string[];
    },
    {
      whatsapp: string;
      status: boolean;
      id: number;
    },
    {
      status: boolean;
      positive: string;
      negative: string;
      id: number;
    },
  ];
};
