import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Radio } from '@uikit/Radio';
import { getRoundedTime } from '@helpers/index';
import { MAILING_DATA_KEY_NAMES } from '@redux/mailing/mailingInitialState';

type TSetSendOutSendingTypeProps = {
  /**
   * Флаг показывает возможно ли отправить рассылку немедленно
   * @param {boolean}
   */
  isImmediateStartAllowed: boolean;
  /**
   * Флаг отправки рассылки немедленно
   * @param {boolean}
   */
  isImmediateLaunch: boolean;
  /**
   * Флаг отправки рассылки по выбранной дате
   * @param {boolean}
   */
  isScheduledLaunch: boolean;
  /**
   * Callback для установки типа отправки рассылки
   * устанавливает тип "Немедленная отправка"
   * @param {boolean}
   */
  setIsImmediateLaunchHandler: () => void;
  /**
   * Callback для установки типа отправки рассылки
   * устанавливает тип "Отправка по расписанию"
   * @param {boolean}
   */
  setIsScheduledLaunchHandler: () => void;
  /**
   * Минимально рекомендуемая дата начала рассылки
   * @param {Date}
   */
  minimalRecommendedStartDate: Date;
  /**
   * Опциональный параметр отключает возможность изменения условий отправки
   * @param {boolean}
   * @default
   */
  disabled?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const SetSendOutSendingType = memo(
  ({
    disabled,
    isImmediateLaunch,
    isScheduledLaunch,
    isImmediateStartAllowed,
    minimalRecommendedStartDate,
    setIsImmediateLaunchHandler,
    setIsScheduledLaunchHandler,
    className = '',
  }: TSetSendOutSendingTypeProps) => {
    const { t } = useTranslation();

    const nowDate = new Date();

    const isSameYear = minimalRecommendedStartDate.getFullYear() === nowDate.getFullYear();
    const isSameMonth = minimalRecommendedStartDate.getMonth() === nowDate.getMonth();
    const daysDifference = minimalRecommendedStartDate.getDate() - nowDate.getDate();

    // Уточняющий текст по отправке рассылки "как можно скорее"
    const date = minimalRecommendedStartDate.toLocaleString('ru-RU', {
      weekday: 'long',
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    });

    let additionalText = t('MAILING_PAGE_TEXT.radioButton.dateAndTime', {
      date,
      time: getRoundedTime(minimalRecommendedStartDate, false, 5, true),
    });

    if (isSameYear && isSameMonth) {
      if (daysDifference === 0) {
        additionalText = t('MAILING_PAGE_TEXT.radioButton.today');
      } else if (daysDifference > 0 && daysDifference <= 1) {
        additionalText = t('MAILING_PAGE_TEXT.radioButton.tomorrow');
      }
    }

    const radioButtonData = [
      {
        checked: isImmediateLaunch,
        onChange: setIsImmediateLaunchHandler,
        name: MAILING_DATA_KEY_NAMES.IS_IMMEDIATE_LAUNCH,
        value: MAILING_DATA_KEY_NAMES.IS_IMMEDIATE_LAUNCH,
        label: isImmediateStartAllowed
          ? t('MAILING_PAGE_TEXT.radioButton.firstLabelRightNowSend')
          : t('MAILING_PAGE_TEXT.radioButton.firstLabelSunAsPossibleSend', { additionalText }),
        helperText: isImmediateStartAllowed
          ? t('MAILING_PAGE_TEXT.radioButton.firstHelperTextRightNowSend')
          : t('MAILING_PAGE_TEXT.radioButton.firstHelperTextSunAsPossibleSend'),
        style: { marginBottom: '1rem', height: 'auto', width: '100%' },
      },
      {
        checked: isScheduledLaunch,
        onChange: setIsScheduledLaunchHandler,
        name: MAILING_DATA_KEY_NAMES.IS_SCHEDULE_LAUNCH,
        value: MAILING_DATA_KEY_NAMES.IS_SCHEDULE_LAUNCH,
        label: t('MAILING_PAGE_TEXT.radioButton.secondLabel'),
        helperText: t('MAILING_PAGE_TEXT.radioButton.secondHelperText'),
        style: { height: 'auto', width: '100%' },
      },
    ];

    const radioButtonToRender = radioButtonData.map(
      ({ value, checked, style, helperText, onChange, label, name }) => (
        <Radio
          key={name}
          type='def'
          name={name}
          value={value}
          color='success'
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          leftPosition={false}
          label={
            <span className='font-medium text-myrtle leading-6 tracking-[0.022em]'>{label}</span>
          }
          helperText={
            <span className='text-textareaBlack text-tooltips leading-5 tracking-[0.018em]'>
              {helperText}
            </span>
          }
          style={style}
        />
      ),
    );

    return <div className={className}>{radioButtonToRender}</div>;
  },
);

SetSendOutSendingType.displayName = 'SetSendOutSendingType';
