import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { THelpKeys } from '@models/index';
import { Checkbox } from '@uikit/CheckBox';
import { TOnChangeHandler } from '@shared/types';
import { IconWithTooltips } from '@components/iconWithTooltips';

interface ISettingsCheckboxProps {
  /**
   * Параметр htmlFor для label
   * @param {string}
   */
  htmlFor: string;
  /**
   * Состояние инпута
   * @param {boolean}
   */
  checked: boolean;
  /**
   * Вариант текста для метки, берется по ключу из словаря
   * @param {THelpKeys}
   */
  variant: THelpKeys;
  /**
   * Опциональный параметр отображает обучающую подсказку
   * @param {boolean}
   */
  isFirstTooltip?: boolean;
  /**
   * Callback для обработки изменения значения в инпуте
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const SettingsCheckbox = memo(
  ({
    checked,
    htmlFor,
    variant,
    isFirstTooltip,
    onChangeHandler,
    className = '',
  }: ISettingsCheckboxProps) => {
    const { t } = useTranslation();

    return (
      <div className={`flex items-center rounded min-h-7 ${checked ? 'bg-aquaSpring' : ''} w-fit`}>
        <Checkbox
          color='success'
          name={htmlFor}
          checked={checked}
          onChange={onChangeHandler}
          label={t(`LABEL_TEXT.${variant}`)}
          className={`!text-[11px] sm:!text-[14px] md:!text-[16px] ${className}`}
        />
        <IconWithTooltips isFirstTooltip={isFirstTooltip} tooltips={variant} className='mr-2' />
      </div>
    );
  },
);

SettingsCheckbox.displayName = 'SettingsCheckbox';
