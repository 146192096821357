import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { PATH_NAMES } from '@data/dict';
import { useAppDispatch } from '@store/store';
import { useNavigate } from 'react-router-dom';
import { QUERY_PARAMS } from '@const/apiConstants';
import { ToggleSwitch } from '@blocks/toggleSwitch';
import { ReviewButton } from '@blocks/reviewButton';
import { TReviewInListMapData } from '@redux/reviews/models';
import { ReviewStep1Card } from '@components/reviewStep1Card';
import { ReviewSep24Card } from '@components/reviewSep2_4Card';
import { ReviewsStep5Card } from '@components/reviewsStep5Card';
import { TChangeSwitchChain, TDeleteStep, TSwitchStep } from '@shared/types';
import { switchChainActivity, switchChainStepActivity } from '@redux/reviews/reviewsThunks';

interface IReviewsCardProps {
  /**
   * ID цепочки
   * @param {string}
   */
  templateCardId: string;
  /**
   * Callback для удаления шаблона
   * @param {() => void}
   */
  deleteChain: () => void;
  /**
   * Количество шагов в цепочке
   * @param {number}
   */
  reviewsChanLength: number;
  /**
   * Callback для удаления шага в цепочке
   * @param {TDeleteStep}
   */
  deleteStep: TDeleteStep;
  /**
   * ID выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Данные для карточки
   * @param {TReviewInListMapData}
   */
  data: TReviewInListMapData;
  /**
   * Флаг доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const ReviewsCard = memo(
  ({
    data,
    deleteStep,
    deleteChain,
    templateCardId,
    reviewsChanLength,
    selectedFilialAccId,
    isEditTemplatesAccess,
    className = '',
  }: IReviewsCardProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const lastCountStep = {
      countIndex: 0,
    };

    const secondStepLength = data.secondStep.length;

    if (secondStepLength) {
      lastCountStep.countIndex = secondStepLength + 2;
    } else {
      lastCountStep.countIndex = 2;
    }

    const changeSwitchStep: TSwitchStep = useCallback(
      ({ status, stepId, templateId }) => {
        if (stepId === '1') {
          dispatch(switchChainActivity({ stepSwitchStatus: status, templateId }));
        } else {
          dispatch(switchChainStepActivity({ switch: status, stepId, templateId }));
        }
      },
      [dispatch],
    );

    const changeSwitchChain: TChangeSwitchChain = useCallback(
      ({ status, templateId }) => {
        dispatch(switchChainActivity({ stepSwitchStatus: status, templateId }));
      },
      [dispatch],
    );

    const editChainHandler = useCallback(() => {
      navigate(
        `${PATH_NAMES.REVIEW}?${QUERY_PARAMS.ACC_ID}=${selectedFilialAccId}&${QUERY_PARAMS.ID}=${data.firstStep.id}`,
      );
    }, [data.firstStep.id, selectedFilialAccId, navigate]);

    const firstStepCard = (
      <ReviewStep1Card
        stepId={1}
        switchStep={changeSwitchStep}
        templateCardId={templateCardId}
        isEditTemplatesAccess={isEditTemplatesAccess}
        data={data.firstStep}
        className='w-full'
      />
    );

    const middleSteps = data.secondStep.map((item, index) => {
      const key = `${index}${item.body}`;
      return (
        <ReviewSep24Card
          key={key}
          data={item}
          stepId={index + 2}
          deleteStep={deleteStep}
          switchStep={changeSwitchStep}
          templateCardId={templateCardId}
          isEditTemplatesAccess={isEditTemplatesAccess}
          className='w-full'
        />
      );
    });

    const lastStep = (
      <ReviewsStep5Card
        data={data.lastStep}
        stepId={lastCountStep.countIndex}
        isEditTemplatesAccess={isEditTemplatesAccess}
        className='w-full'
      />
    );

    return (
      <div className={`bg-gray1 sm:rounded-lg p-4 ${className}`}>
        <div className='flex items-center justify-between mb-4'>
          <div className='flex items-center'>
            {isEditTemplatesAccess && (
              <ToggleSwitch
                name='status'
                checked={data.firstStep.active}
                onChangeHandler={() =>
                  changeSwitchChain({ status: !data.firstStep.active, templateId: templateCardId })
                }
              />
            )}
            <p className='font-bold text-h3Mobile text-black tracking-[0.0075em] ml-2'>
              {data.firstStep.title
                ? data.firstStep.title
                : t('REVIEW_PAGE_TEXT.chain', {
                    id: data.firstStep.id,
                  })}
            </p>
          </div>
          {isEditTemplatesAccess && (
            <div className='flex'>
              <ReviewButton variant='edit' callBack={editChainHandler} className='mr-2' />
              <ReviewButton
                callBack={deleteChain}
                variant='deleteReview'
                disabled={reviewsChanLength < 2}
              />
            </div>
          )}
        </div>
        <div className='grid grid-cols-3temp xxl:grid-cols-3tempXXL gap-[1rem]'>
          {firstStepCard}
          {middleSteps}
          {lastStep}
        </div>
      </div>
    );
  },
);

ReviewsCard.displayName = 'ReviewsCard';
