import { apiService } from '@api/ApiService';
import { END_POINTS_URL } from '@const/apiConstants';
import { TCheckInstanceStatus } from '@models/index';
import { TAccountsResponse, TRequestStatus } from '@api/types';

/**
 * Api страницы списка филиалов
 * @module accountsApi
 */

export const accountsApi = {
  /**
   * Метод выполняет запрос за списком филиалов
   * @param data { TCheckInstanceStatus } флаг запроса статуса подключения инстанса
   * @method getAccounts
   * @return {Promise<AxiosResponse<TAccountsResponse | TRequestStatus, any>>}
   */
  getAccounts(data: TCheckInstanceStatus) {
    return apiService.get<TAccountsResponse | TRequestStatus>(END_POINTS_URL.ACCOUNTS, {
      check_status: data.checkStatus,
    });
  },
};
