import React from 'react';
//
import { Icon } from '@blueprintjs/core';
import { TChangeMailingDrawerTabHandler } from '@shared/types';
import { MAILING_DRAWER_TAB_NAMES } from '@redux/mailing/models';

type TMailingExampleSwitchProps = {
  /**
   * Название активной вкладки
   * @param {string}
   */
  activeTabName: string;
  /**
   * Callback для изменения активной вкладки
   * @param {TChangeMailingDrawerTabHandler}
   */
  changeTabHandler: TChangeMailingDrawerTabHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const MailingExampleSwitch = ({
  changeTabHandler,
  activeTabName,
  className = '',
}: TMailingExampleSwitchProps) => {
  const activeStyle = 'border-b-green1 border-b-4 text-myrtle';
  const commonStyles =
    'cursor-pointer text-green1 hover:text-green1 min-h-[2.5rem] leading-[2.5rem] px-[0.75rem] hover:no-underline tracking-[0.022em]';
  return (
    <div className={`flex ${className}`}>
      <div
        onClick={changeTabHandler(MAILING_DRAWER_TAB_NAMES.TEXT)}
        className={`${commonStyles} ${
          activeTabName === MAILING_DRAWER_TAB_NAMES.TEXT ? activeStyle : ''
        }`}>
        <Icon
          size={20}
          icon='text-highlight'
          color={activeTabName === MAILING_DRAWER_TAB_NAMES.TEXT ? 'black' : '#37833B'}
          className='mr-2'
        />
        Текст
      </div>
      <div
        onClick={changeTabHandler(MAILING_DRAWER_TAB_NAMES.IMAGES)}
        className={`${commonStyles} ${
          activeTabName === MAILING_DRAWER_TAB_NAMES.IMAGES ? activeStyle : ''
        }`}>
        <Icon
          size={20}
          icon='media'
          color={activeTabName === MAILING_DRAWER_TAB_NAMES.IMAGES ? 'black' : '#37833B'}
          className='mr-2'
        />
        Картинки
      </div>
    </div>
  );
};
