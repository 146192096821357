import React, { memo, useMemo } from 'react';
//
import { Menu } from '@uikit/Menu';
import { listCreator } from '@helpers/index';
import { MenuItem } from '@uikit/Menu/MenuItem';

type TSetMinutesDropdown = {
  /**
   * Значение с которого начинается формирование списка минут
   * @param {number}
   */
  startMinutes: number;
  /**
   * Значение которым заканчивается формирование списка минут
   * @param {number}
   */
  endMinutes: number;
  /**
   * Шаг увеличения значения минут
   * @param {number}
   */
  minIncreaseStep: number;
  /**
   * Callback для установки минут
   * @param {(minutes: string) => () => void}
   */
  setMinutesCallback: (minutes: string) => () => void;
  /**
   * Значение минут
   * @param {string}
   */
  minutesValue: string;
  /**
   * Callback для закрытия выпадающего списка
   * @param {() => void}
   */
  dropdownToggle: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const SetMinutesDropdown = memo(
  ({
    endMinutes,
    minutesValue,
    startMinutes,
    dropdownToggle,
    minIncreaseStep,
    setMinutesCallback,
    className,
  }: TSetMinutesDropdown) => {
    // массив значений минут
    const minutes = useMemo(
      () => listCreator(startMinutes, endMinutes, minIncreaseStep, true),
      [startMinutes, endMinutes, minIncreaseStep],
    );

    // формирование массива элементов выпадающего списка минут
    const minutesMenuItemList = useMemo(
      () =>
        minutes.map(item => (
          <MenuItem
            key={item}
            text={<span>{item}</span>}
            onClick={setMinutesCallback(item)}
            // добавление класса при соответствии значению
            className={`${item === minutesValue ? 'bg-lightGray' : ''} m-0`}
          />
        )),
      [minutes, minutesValue, setMinutesCallback],
    );

    // классы для обертки списка
    const classList =
      'flex flex-col pt-2 dropdown-list-scrollbar dropdown-list-scrollbar-tiny overflow-y-auto h-[11rem] min-w-[4rem]';

    return (
      <div className={className}>
        <Menu
          type='def'
          view='raised'
          color='default'
          onClick={dropdownToggle}
          className={classList}>
          {minutesMenuItemList}
        </Menu>
      </div>
    );
  },
);

SetMinutesDropdown.displayName = 'SetMinutesDropdown';
