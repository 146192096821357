import React, { memo, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { PATH_NAMES } from '@data/dict';
import { Tooltip } from '@uikit/Tooltip';
import { NoAccess } from '@components/noAccess';
import { getAppData } from '@redux/app/selectors';
import { QUERY_PARAMS } from '@const/apiConstants';
import { ErrorMessage } from '@blocks/errorMessage';
import { ReviewTemplate } from '@templates/reviews';
import { ReviewsList } from '@templates/reviewsList';
import { ROUTES_ACCESS_RIGHTS } from '@const/access';
import { ReviewsStats } from '@templates/reviewsStats';
import { REVIEW_PAGES_NAME_DICT } from '@const/reviews';
import { PageNavigate } from '@components/pageNavigate';
import { getAccountsData } from '@redux/accounts/selectors';
import { ToastsMessages } from '@components/toastsMessages';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getReviewsPageData } from '@redux/reviews/selectors';
import { reviewsEmptyTemplate } from '@redux/reviews/mockData';
import { HeaderWithButton } from '@components/headerWithButton';
import { SORTING_TYPES, VIDEO_INSTRUCTIONS } from '@const/common';
import { getReviewStatsPageData } from '@redux/reviewsStats/selectors';
import { getReviewById, getReviewsList } from '@redux/reviews/reviewsThunks';
import { ReactComponent as TGIcon } from '@img/baseTemplate/telegramCyan.svg';
import { getStatsDataThunk, sendStatsDataToTGThunk } from '@redux/reviewsStats/thunks';
import { clearAllErrorRSP, clearAllSuccessStatusesRSP } from '@redux/reviewsStats/slice';
import {
  clearChainErrors,
  setOneReviewData,
  clearSuccessStatus,
} from '@redux/reviews/reviewsSlice';

type TProps = {
  /**
   * Флаг новой цепочки
   * @param {boolean}
   */
  isNewReviews: boolean;
};

const Reviews = memo(({ isNewReviews }: TProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get(QUERY_PARAMS.ID);
  const [isShowTooltips, setIsShowTooltips] = useState(false);

  const { appLanguage } = useAppSelector(getAppData);

  const {
    isError,
    isLoading,
    errorMessage,
    isAccessDenied,
    successMessage,
    blurFromTextareaName,
    oneReviewData,
  } = useAppSelector(getReviewsPageData);

  const {
    status,
    sortedBy,
    endDate,
    startDate,
    sortDirection,
    rowsPerPage,
    currentPage,
    data: reviewStatsData,
    successMessage: statsSuccessMessage,
    errorMessage: statsErrorMessage,
  } = useAppSelector(getReviewStatsPageData);

  // получаем объект selectedFilial
  const { selectedFilial } = useAppSelector(getAccountsData);

  const selectedFilialAccId = selectedFilial?.accId || '';

  // Получаем массив прав если он есть и проверяем наличие необходимых прав
  const rights = selectedFilial?.rights;
  const isEditTemplatesAccess =
    rights?.includes(ROUTES_ACCESS_RIGHTS.EDIT_TEMPLATES) ||
    rights?.includes(ROUTES_ACCESS_RIGHTS.OWNER) ||
    false;

  const isReviewsList = pathname.includes(PATH_NAMES.REVIEWS);
  const isReviewStat = pathname.includes(PATH_NAMES.REVIEW_STATS);
  const isReview = pathname.includes(PATH_NAMES.REVIEW) || pathname.includes(PATH_NAMES.NEW_REVIEW);

  // Запрашивает список цепочек отзывов, если пользователь находится на странице отзывов.
  useEffect(() => {
    if (isReviewsList && selectedFilialAccId) {
      dispatch(getReviewsList());
    }
    return () => {
      dispatch(clearAllErrorRSP());
      dispatch(clearChainErrors());
      dispatch(clearSuccessStatus());
    };
  }, [dispatch, isReviewsList, selectedFilialAccId]);

  useEffect(() => {
    if (!isNewReviews && id && selectedFilialAccId) {
      dispatch(getReviewById({ templateId: String(id) }));
    }
  }, [isNewReviews, id, selectedFilialAccId, dispatch]);

  useEffect(() => {
    if (isNewReviews && selectedFilialAccId) {
      dispatch(setOneReviewData(reviewsEmptyTemplate));
    }

    return () => {
      dispatch(setOneReviewData(reviewsEmptyTemplate));
    };
  }, [isNewReviews, dispatch, selectedFilialAccId]);

  useEffect(() => {
    if (isReviewStat && selectedFilialAccId) {
      dispatch(
        getStatsDataThunk({
          sort: sortedBy,
          dateEnd: endDate,
          dateStart: startDate,
          page: currentPage + 1,
          pageSize: rowsPerPage,
          sortDesc: sortDirection === SORTING_TYPES.DESCENDING,
        }),
      );
    }
  }, [
    endDate,
    sortedBy,
    startDate,
    currentPage,
    rowsPerPage,
    isReviewStat,
    sortDirection,
    selectedFilialAccId,
    dispatch,
  ]);

  // делает редирект на страницу создания цепочки отзывов
  const addReview = useCallback(() => {
    navigate(`${PATH_NAMES.NEW_REVIEW}?${QUERY_PARAMS.ACC_ID}=${selectedFilial?.accId}`);
  }, [navigate, selectedFilial?.accId]);

  // Отправляет данные статистики в TG
  const sendToTelegramHandler = useCallback(() => {
    dispatch(sendStatsDataToTGThunk());
  }, [dispatch]);

  const isNoStatistics = !reviewStatsData.length;

  const showError = isError || status === 'error';

  const accessDenied = isAccessDenied || status === 'accessDenied';

  const successToastMessage = successMessage || statsSuccessMessage;

  const errorToastMessage = errorMessage || statsErrorMessage;

  const clearAllErrorState = useCallback(() => {
    dispatch(clearChainErrors());
  }, [dispatch]);

  const clearAllSuccessState = useCallback(() => {
    dispatch(clearSuccessStatus());
    dispatch(clearAllSuccessStatusesRSP());
  }, [dispatch]);

  return (
    <>
      {showError ? <ErrorMessage /> : null}
      {accessDenied ? <NoAccess /> : null}
      {!showError && !accessDenied ? (
        <div>
          {isReview && !isLoading ? (
            <ReviewTemplate
              data={oneReviewData}
              selectedFilialAccId={selectedFilialAccId}
              blurFromTextareaName={blurFromTextareaName}
              className='px-4'
            />
          ) : (
            <div className='md:px-2 lg:px-0'>
              <HeaderWithButton
                instructionData={VIDEO_INSTRUCTIONS.templates}
                headerText={t('REVIEW_PAGE_TEXT.pageWrapper.header')}
                className='px-4 sm:px-0 mb-10 mt-0'
              />
              <div className='flex flex-wrap items-start justify-between px-4 sm:px-0 mb-6 pb-5 sm:pb-0 sm:mb-7'>
                <PageNavigate
                  path={pathname}
                  isDisable={false}
                  callBack={addReview}
                  selectedFilialAccId={selectedFilialAccId}
                  pathNameObj={REVIEW_PAGES_NAME_DICT[appLanguage]}
                  isEditTemplatesAccess={isReviewsList && isEditTemplatesAccess}
                  newButtonText={t('REVIEW_PAGE_TEXT.pageWrapper.newReviews')}
                  className={`${isReviewStat ? 'sm:max-w-[20rem]' : ''} w-full`}
                />
                {isReviewStat ? (
                  <Tooltip
                    position='top'
                    color='default'
                    isOpen={isShowTooltips}
                    content={<span>{t('REVIEW_PAGE_TEXT.statistics.sendDetailInTG')}</span>}>
                    <Button
                      dense
                      type='action'
                      view='outlined'
                      color='default'
                      onClick={sendToTelegramHandler}
                      disabled={isNoStatistics}
                      loading={status === 'sendStatsToTG'}
                      rightIcon={
                        <TGIcon className={`${isNoStatistics ? 'opacity-40' : ''} ml-2`} />
                      }
                      onMouseOver={() => setIsShowTooltips(true)}
                      onMouseLeave={() => setIsShowTooltips(false)}
                      text={t('REVIEW_PAGE_TEXT.statisticsCard.sendTo')}
                      className='mt-1 sm:mt-0 w-full sm:w-fit'
                    />
                  </Tooltip>
                ) : null}
              </div>
              {isReviewsList ? (
                <ReviewsList
                  selectedFilialAccId={selectedFilialAccId}
                  isEditTemplatesAccess={isEditTemplatesAccess}
                  className='sm:w-auto'
                />
              ) : null}
              {isReviewStat ? (
                <ReviewsStats
                  appLanguage={appLanguage}
                  selectedFilialAccId={selectedFilialAccId}
                  className='sm:w-auto'
                />
              ) : null}
            </div>
          )}
        </div>
      ) : null}
      <ToastsMessages
        errorMessage={errorToastMessage}
        successMessage={successToastMessage}
        isShowErrorToast={!!errorToastMessage}
        clearErrorCallback={clearAllErrorState}
        isShowSuccessToast={!!successToastMessage}
        clearSuccessStatusCallback={clearAllSuccessState}
      />
    </>
  );
});

Reviews.displayName = 'Reviews';

export default Reviews;
