import React from 'react';
//
import { ICON_VARIANT } from '@data/dict';
import { CONNECTION_STATUSES } from '@models/index';

interface IFilialStatusProps {
  /**
   * Статус подключения филиала
   * @param {string}
   */
  status: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const FilialStatus = ({ className = '', status }: IFilialStatusProps) => {
  // устанавливается вариант иконки в зависимости от параметра status, ссылки на картинки берутся из словаря
  const src =
    status === CONNECTION_STATUSES.AUTHENTICATED
      ? ICON_VARIANT.authenticated
      : ICON_VARIANT.gotQrCode;
  // устанавливается вариант заполнения тэга alt в зависимости от параметра status
  const statusTitle = status === CONNECTION_STATUSES.AUTHENTICATED ? 'Подключен' : 'Ошибка';

  return <img src={src} className={`h-[2.125rem] w-[2.125rem] ${className}`} alt={statusTitle} />;
};
