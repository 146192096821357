import React, { memo } from 'react';
import { DndContainer } from '@components/dndContainer';

type TTemplateCardWrapper = {
  /**
   * Массив с Id для формирования списка DND
   * @param {number[]}
   */
  templateOrder: number[];
  /**
   * После окончания перетаскивания запускается callback,
   * в него передается массив id в новом порядке
   * @param {(itemsId: number[]) => void}
   */
  onDragEndCallback: (itemsId: number[]) => void;
  /**
   * Массив с карточками шаблонов
   * @param {React.ReactNode}
   */
  templateCard: React.ReactNode;
};

export const TemplateCardWrapper = memo(
  ({ templateOrder, templateCard, onDragEndCallback }: TTemplateCardWrapper) => {
    return (
      <div className='grid grid-cols-3temp xxl:grid-cols-3tempXXL items-stretch gap-[1.5rem]'>
        <DndContainer itemsId={templateOrder} onDragEndCallback={onDragEndCallback}>
          {templateCard}
        </DndContainer>
      </div>
    );
  },
);

TemplateCardWrapper.displayName = 'TemplateCardWrapper';
