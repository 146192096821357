import { TChannels } from '@api/types';
import { PATH_NAMES } from '@data/dict';
import { TAppLanguage, TSimpleStringObj } from '@models/index';

export const numberOfMonth = [1, 3, 6, 12];
export const numberOfGiftWeek = ['', '1', '3', '8'];

export const PAY_PAGES_NAME_DICT: Record<TAppLanguage, TSimpleStringObj> = {
  eng: {
    [PATH_NAMES.PAY]: 'Tariff',
    [PATH_NAMES.PAY_HISTORY]: 'Payment history',
  },
  rus: {
    [PATH_NAMES.PAY]: 'Тарифы',
    [PATH_NAMES.PAY_HISTORY]: 'История оплат',
  },
};

export type TPayCardsTags = 'mostPopularTag' | 'mostProfitableTag' | '';

export const payCardTags: Record<number, TPayCardsTags> = {
  0: '',
  1: '',
  2: 'mostPopularTag',
  3: '',
};

export enum OPTIONS_NAME {
  WA = 'WA',
  TG = 'TG',
  LOYALTY = 'loyalty',
}

export const PAY_OPTION_NAME: Record<TAppLanguage, Record<TChannels, string>> = {
  eng: {
    [OPTIONS_NAME.WA]: 'WhatsApp',
    [OPTIONS_NAME.TG]: 'Telegram channel',
    [OPTIONS_NAME.LOYALTY]: 'Invite friend',
  },
  rus: {
    [OPTIONS_NAME.WA]: 'WhatsApp',
    [OPTIONS_NAME.TG]: 'Телеграм канал',
    [OPTIONS_NAME.LOYALTY]: 'Приведи друга',
  },
};

export const PAY_PAGE_SUCCESS_MESSAGES = {
  SEND_INVOICE_IN_TG: 'Счет отправлен в telegram',
};

export const PAY_PAGE_SUCCESS_MESSAGES_ENG = {
  SEND_INVOICE_IN_TG: 'Invoice sent to telegram',
};

export const PAY_PAGE_SUCCESS_TOAST_MESSAGES = {
  SEND_INVOICE_IN_TG: 'PAY_PAGE_SUCCESS_MESSAGES.SEND_INVOICE_IN_TG',
};

export const BANNER_LINK = 'https://yclients.com/m/mp_3_beautybot_review/';

export const YEAR_WORDS = {
  rus: ['год', 'года', 'лет'],
  eng: ['year', 'years', 'years'],
};

export const MONTH_WORDS = {
  rus: ['месяц', 'месяца', 'месяцев'],
  eng: ['month', 'months', 'months'],
};

export const WEEK_WORDS = {
  rus: ['неделя', 'недели', 'недель'],
  eng: ['week', 'weeks', 'weeks'],
};

export const EMPLOYEES: Record<TAppLanguage, string[]> = {
  eng: ['employee', 'employees', 'employees'],
  rus: ['сотрудник', 'сотрудника', 'сотрудников'],
};

export const PAY_PAGE_TEXT = {
  header: 'Оплата',
  payStatusTitle: 'статус оплаты',
  payTill: 'Оплачен до {{payTillDate}}',
  payBy: 'Оплатить до {{payTillDate}}',
  notPaid: 'Не оплачен',
  payEvery: 'Оплата каждое {{payTillDate}} число месяца',
  filial: 'Филиал: {{filialName}}',
  // tariffData: '{{branchEmployees}} {{employersText}}: от\u00A0{{amount}}\u00A0₽/мес.',
  tariffData: '{{branchEmployees}} {{employersText}}',
  discountText: 'Ваша скидка',
  newTariff: '{{branchEmployees}} {{employersText}}',
  rate: 'Филиал: {{filialName}}\n Ваш тариф:\n 👩 {{branchEmployees}} {{employersText}} \n💳 {{amount}} руб. в месяц',
  // eslint-disable-next-line
  rateUSD: 'Филиал: {{filialName}}\n Ваш тариф:\n 👩 {{branchEmployees}} {{employersText}} \n💳 ${{amount}} в месяц',
  discount: '-{{discount}}%',
  connectedOptions: 'Подключенные опции',
  autoPaymentStatusEnabled: 'Автоплатеж подключен',
  autoPaymentStatusDisabled: 'Автоплатеж не подключен',
  autoPaymentLK: 'Личный кабинет с автоплатежом',
  telegramChannel: 'Telegram канал',
  steUpTelegramChannel: 'Настроить',
  selectAccountPaymentDeadline: 'Выберите срок оплаты кабинета',
  toPay: 'К оплате',
  selectPaymentTerm1: 'Добавьте подписку',
  selectPaymentTerm2: 'или опции для оплаты',
  oneMonthCostWithDiscount: '{{oneMonthCostWithDiscount}} ₽/мес.',
  // eslint-disable-next-line
  oneMonthCostWithDiscountUSD: '${{oneMonthCostWithDiscount}}/мес.',
  or: 'или',
  totalForAllMonth: 'итого {{totalAmount}} ₽',
  giftText: '🎁 +{{giftWeeks}} {{weekText}}',
  payButtonText: 'Оплатить {{selectedCardTotalAmountText}}',
  billDownloadModalRate: '👩 {{branchEmployees}} {{employersText}}',
  selectedCardTotalAmountText: '{{selectedCardTotalAmount}} ₽ {{paymentMethodText}}',
  // eslint-disable-next-line
  selectedCardTotalAmountTextUSD: '${{selectedCardTotalAmount}} {{paymentMethodText}}',
  yourTariff: 'Ваш тариф:',
  employers: 'сотрудников',
  rubl: 'руб.',
  month: 'в месяц',
  // autoPayment: 'Автоплатеж',
  // autoPaymentEnabled: 'подключен',
  // autoPaymentDisabled: 'не подключен',
  card: 'Карта **** **** **** {{cardLastFourDigits}}',
  monthAmountWithCard: '{{monthAmount}} ₽/мес. x Карта *{{cardLastFourDigits}}',
  monthAmountWithDate: '{{monthAmount}} ₽/мес. x Оплачен до {{paidByDate}}',
  monthAmountWithAutoPayments: '{{monthAmount}} ₽/мес. x с автоплатежом',
  selectPaymentPeriod: 'ВЫБЕРИТЕ ОСНОВНОЙ ТАРИФ',
  paymentWithAutoPay: '1 МЕСЯЦ С АВТОПЛАТЕЖОМ',
  paymentRange: 'ОПЛАТА НА {{month}} {{monthText}}',
  fullPriceForPeriod: '{{pricePerMonth}} ₽ за',
  // eslint-disable-next-line
  fullPriceForPeriodUSD: '${{pricePerMonth}} за',
  pricePerMonth: '{{pricePerMonth}} ₽/мес. x',
  // eslint-disable-next-line
  pricePerMonthUSD: '${{pricePerMonth}}/мес x',
  monthQuantity: '{{monthQuantity}} {{monthText}}',
  payTariffDays: 'Оплатить опции днями тарифа',
  reductionOfTariffDuration: 'Срок действия вашего тарифа будет уменьшен',
  reductionOfTariffData: 'на {{day}} {{dayText}} до {{date}}',
  price: '{{amount}} ₽',
  // eslint-disable-next-line
  priceUSD: '${{amount}}',
  waDescription: 'Входит в тариф',
  tariffDescription: 'Тариф {{month}} {{monthText}}',
  delete: 'удалить',
  lk: 'Личный кабинет',
  enableAutoPayment: 'Подключите автоплатёж',
  changeCard: 'Изменить карту',
  rublSign: '₽',
  dollarSign: '$',
  monthShort: 'мес.',
  amount: 'Итого',
  profit: 'выгода {{profit}} ₽',
  // eslint-disable-next-line
  profitUSD: 'выгода ${{profit}}',
  withAutoPayments: 'с автоплатежом',
  withAutoPaymentsCapitalise: 'С автоплатежом',
  mostPopularTag: 'ПОПУЛЯРНЫЙ',
  mostProfitableTag: 'ВЫГОДНЫЙ',
  selectButton: 'Выбрать',
  extendButton: 'Продлить',
  selectedButton: 'Выбрано',
  promoCodeLabel: 'Введите промокод',
  selectAdditionalServices: 'Дополнительные опции',
  promoCodePlaceholder: 'Промокод',
  setPromCode: 'Применить',
  enableAutomaticPayment: 'Вы хотите подключить автоплатеж',
  paymentDescription: 'Вы будете перенаправлены на сайт платёжной системы, следуйте инструкциям.',
  paymentDescription2:
    'В окне кассы укажите карту и выполните платёж. После завершения оплаты, пожалуйста, обновите страницу.',
  refresh: 'Обновить',
  cancel: 'Отмена',
  connect: 'Подключить',
  changeAutomaticPaymentCardTitle: 'Вы желаете изменить карту для автоплатежа',
  changeAutomaticPaymentCard: 'Вы желаете изменить карту для филиала:',
  changeAutomaticPaymentCardDescription:
    'В окне кассы укажите карту и выполните платёж. После завершения оплаты, пожалуйста, обновите страницу.',
  change: 'Изменить',
  disableAutomaticPaymentTitle: 'Вы хотите отключить автоплатеж',
  disable: 'Отключить',
  automaticPaymentDisabledTitle: 'Автоплатёж успешно отключен.',
  pleaseRefreshPage: 'Пожалуйста обновите страницу.',
  billCreated: 'Счет сформирован ({{totalAmount}} ₽)',
  // eslint-disable-next-line
  billCreatedUSD: 'Счет сформирован (${{totalAmount}})',
  billCreated2: 'Счет сформирован на сумму {{totalAmount}} руб.',
  // eslint-disable-next-line
  billCreated2USD: 'Счет сформирован на сумму ${{totalAmount}}.',
  sendInTg: 'Отправить в TG',
  downloadPdf: 'Скачать .PDF',
  billSum: 'на сумму',
  downloadBill: 'Скачать счет',
  pay: 'Оплатить',
  cardPay: 'по карте',
  linkPay: 'по ссылке',
  commercialHeader: '2 недели\n в подарок!',
  commercialDescription: 'Оставьте отзыв в маркетплейсе YCLIENTS и получите 2 недели в подарок!',
  commercialGift: '2 недели в подарок!',
  commercialButton: 'Оставить отзыв',
  tableHeader: 'Предыдущие платежи',
  tableDate: 'Дата',
  tableChannels: 'Услуга',
  channelWA: 'WhatsApp канал',
  channelTG: 'Telegram канал',
  channelloyalty: 'Приведи друга',
  tablePeriod: 'Период',
  tablePaymentType: 'Тип платежа',
  paymentTypes: {
    bonusDays: 'Бонусные дни',
    bonus: 'Бонус',
    linkPaid: 'По ссылке',
    bill: 'По счету',
    autoPayment: 'Автоплатеж',
    '-': 'Неизвестно',
  },
  options: {
    whatsApp: {
      name: 'WhatsApp',
      description: 'Основной канал связи с клиентами',
    },
    telegram: {
      name: 'Телеграм канал',
      description: 'Канал связи с клиентами',
    },
    inviteFriends: {
      name: 'Приведи друга',
      description: 'Реферальная ссылка для ваших клиентов',
    },
  },
  connectedTill: 'Подключено до {{date}}',
  includedInTariff: 'Включен в тариф',
  tableSum: 'Сумма',
  tableBeforePeriod: 'до {{period}}',
  paginationText: 'Показать строк:',
  showMore: 'Показать еще',
};

export const PAY_PAGE_TEXT_ENG = {
  header: 'Payment',
  payStatusTitle: 'payment status',
  payTill: 'Paid until {{payTillDate}}',
  payBy: 'Pay by {{payTillDate}}',
  notPaid: 'Not paid',
  payEvery: 'Payment every {{payTillDate}} day of the month',
  filial: 'Branch: {{filialName}}',
  tariffData: '{{branchEmployees}} {{employersText}}',
  discountText: 'Your discount',
  newTariff: '{{branchEmployees}} {{employersText}}',
  rate: 'Branch: {{filialName}}\n Your tariff:\n 👩 {{branchEmployees}} {{employersText}} \n💳 {{amount}} rubles per month',
  // eslint-disable-next-line
  rateUSD: "Branch: {{filialName}}\n Your tariff:\n 👩 {{branchEmployees}} {{employersText}} \n💳 ${{amount}} per month",
  discount: '-{{discount}}%',
  connectedOptions: 'Connected options',
  autoPaymentStatusEnabled: 'Autopayment enabled',
  autoPaymentStatusDisabled: 'Autopayment disabled',
  autoPaymentLK: 'Personal cabinet with autopayment',
  telegramChannel: 'Telegram channel',
  steUpTelegramChannel: 'Set up',
  selectAccountPaymentDeadline: 'Select account payment deadline',
  toPay: 'To pay',
  selectPaymentTerm1: 'Add a subscription',
  selectPaymentTerm2: 'or options for payment',
  oneMonthCostWithDiscount: '{{oneMonthCostWithDiscount}} ₽/month',
  // eslint-disable-next-line
  oneMonthCostWithDiscountUSD: "${{oneMonthCostWithDiscount}}/month",
  or: 'or',
  totalForAllMonth: 'total {{totalAmount}} ₽',
  giftText: '🎁 +{{giftWeeks}} {{weekText}}',
  payButtonText: 'Pay {{selectedCardTotalAmountText}}',
  billDownloadModalRate: '👩 {{branchEmployees}} {{employersText}}',
  selectedCardTotalAmountText: '{{selectedCardTotalAmount}} ₽ {{paymentMethodText}}',
  // eslint-disable-next-line
  selectedCardTotalAmountTextUSD: "${{selectedCardTotalAmount}} {{paymentMethodText}}",
  yourTariff: 'Your tariff:',
  employers: 'employees',
  rubl: 'rub.',
  month: 'per month',
  card: 'Card **** **** **** {{cardLastFourDigits}}',
  monthAmountWithCard: '{{monthAmount}} ₽/month x Card *{{cardLastFourDigits}}',
  monthAmountWithDate: '{{monthAmount}} ₽/month x Paid until {{paidByDate}}',
  monthAmountWithAutoPayments: '{{monthAmount}} ₽/month x with autopayment',
  selectPaymentPeriod: 'SELECT MAIN TARIFF',
  paymentWithAutoPay: '1 MONTH WITH AUTOPAY',
  paymentRange: 'PAYMENT FOR {{month}} {{monthText}}',
  fullPriceForPeriod: '{{pricePerMonth}} ₽ for',
  // eslint-disable-next-line
  fullPriceForPeriodUSD: "${{pricePerMonth}} for",
  pricePerMonth: '{{pricePerMonth}} ₽/month x',
  // eslint-disable-next-line
  pricePerMonthUSD: "${{pricePerMonth}}/month x",
  monthQuantity: '{{monthQuantity}} {{monthText}}',
  payTariffDays: 'Pay options with tariff days',
  reductionOfTariffDuration: 'The duration of your tariff will be reduced',
  reductionOfTariffData: 'by {{day}} {{dayText}} until {{date}}',
  price: '{{amount}} ₽',
  // eslint-disable-next-line
  priceUSD: "${{amount}}",
  waDescription: 'Included in tariff',
  tariffDescription: 'Tariff {{month}} {{monthText}}',
  delete: 'delete',
  lk: 'Personal cabinet',
  enableAutoPayment: 'Enable autopayment',
  changeCard: 'Change card',
  rublSign: '₽',
  dollarSign: '$',
  monthShort: 'mo.',
  amount: 'Total',
  profit: 'profit {{profit}} ₽',
  // eslint-disable-next-line
  profitUSD: "profit ${{profit}}",
  withAutoPayments: 'with autopayment',
  withAutoPaymentsCapitalise: 'With autopayment',
  mostPopularTag: 'POPULAR',
  mostProfitableTag: 'PROFITABLE',
  selectButton: 'Select',
  extendButton: 'Extend',
  selectedButton: 'Selected',
  promoCodeLabel: 'Enter promo code',
  selectAdditionalServices: 'Additional options',
  promoCodePlaceholder: 'Promo code',
  setPromCode: 'Apply',
  enableAutomaticPayment: 'Do you want to enable autopayment',
  paymentDescription:
    'You will be redirected to the payment system website, follow the instructions.',
  paymentDescription2:
    'In the cashier window, enter the card and complete the payment. After completing the payment, please refresh the page.',
  refresh: 'Refresh',
  cancel: 'Cancel',
  connect: 'Connect',
  changeAutomaticPaymentCardTitle: 'You want to change the card for autopayment',
  changeAutomaticPaymentCard: 'You want to change the card for the branch:',
  changeAutomaticPaymentCardDescription:
    'In the cashier window, enter the card and complete the payment. After completing the payment, please refresh the page.',
  change: 'Change',
  disableAutomaticPaymentTitle: 'You want to disable autopayment',
  disable: 'Disable',
  automaticPaymentDisabledTitle: 'Autopayment successfully disabled.',
  pleaseRefreshPage: 'Please refresh the page.',
  billCreated: 'Bill created ({{totalAmount}} ₽)',
  // eslint-disable-next-line
  billCreatedUSD: "Bill created (${{totalAmount}})",
  billCreated2: 'Bill created for amount {{totalAmount}} rub.',
  // eslint-disable-next-line
  billCreated2USD: "Bill created for amount ${{totalAmount}}.",
  sendInTg: 'Send in TG',
  downloadPdf: 'Download .PDF',
  billSum: 'for the amount',
  downloadBill: 'Download bill',
  pay: 'Pay',
  cardPay: 'by card',
  linkPay: 'by link',
  commercialHeader: '2 weeks\n as a gift!',
  commercialDescription: 'Leave a review on the YCLIENTS marketplace and get 2 weeks as a gift!',
  commercialGift: '2 weeks as a gift!',
  commercialButton: 'Leave a review',
  tableHeader: 'Previous payments',
  tableDate: 'Date',
  tableChannels: 'Service',
  channelWA: 'WhatsApp channel',
  channelTG: 'Telegram channel',
  channelloyalty: 'Refer a friend',
  tablePeriod: 'Period',
  tablePaymentType: 'Payment type',
  paymentTypes: {
    bonusDays: 'Bonus days',
    bonus: 'Bonus',
    linkPaid: 'By link',
    bill: 'By bill',
    autoPayment: 'Autopayment',
    '-': 'Unknown',
  },
  options: {
    whatsApp: {
      name: 'WhatsApp',
      description: 'Main communication channel with clients',
    },
    telegram: {
      name: 'Telegram channel',
      description: 'Communication channel with clients',
    },
    inviteFriends: {
      name: 'Refer a friend',
      description: 'Referral link for your clients',
    },
  },
  connectedTill: 'Connected until {{date}}',
  includedInTariff: 'Included in tariff',
  tableSum: 'Sum',
  tableBeforePeriod: 'until {{period}}',
  paginationText: 'Show rows:',
  showMore: 'Show more',
};
