export enum TELEGRAM_ID_AUTH_KEY_NAME {
  PIN = 'pin',
  USER_ID = 'userId',
}

export type TTelegramIDAuthResponseData = {
  user_id: number;
  first_name: string;
  last_name: string;
  username: string;
  photo_url: string;
  auth: boolean;
  access_token: string;
};

export enum TELEGRAM_ID_AUTH_RESPONSE_KEY_NAMES {
  USER_ID = 'user_id',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  USER_NAME = 'username',
  PHOTO_URL = 'photo_url',
  AUTH = 'auth',
  ACCESS_TOKEN = 'access_token',
}

export type TTelegramAuthData = {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  photoUrl: string;
};

export type TLoginData = {
  accessToken: string;
  userId: string;
  auth: boolean;
};

export type TTelegramAuthResponseData = {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  photo_url: string;
  auth_date: number;
  hash: string;
};

export type TCheckPinData = {
  pin: number;
  userId: number;
};
