import { payApi } from '@api/payApi';
import { TRejectResponseData } from '@models/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import {
  TPayData,
  TInvoiceData,
  TPaymentData,
  TCrateInvoice,
  TCalculatePrice,
  TCreateInvoiceResponse,
} from '@redux/pay/types';
import {
  mapInvoiceDataToStore,
  mapPaymentsDataToStore,
  mapResponseDateToStore,
  mapCalculatePriceToStore,
} from '@redux/pay/payMappers';
import {
  TGetPayLinkData,
  PAY_RESPONSE_KEY,
  TGetCalculatedPrice,
  REJECT_RESPONSE_KEY,
  PAY_LINK_RESPONSE_KEYS,
  CREATE_INVOICE_RESPONSE_KEY_NAMES,
} from '@api/types';

/**
 * Thunk Получает данные страницы оплаты и обрабатывает результат.
 * @returns {TPayData | void} - Данные страницы оплаты или в undefined.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const getPayPageData = createAsyncThunk<
  TPayData | void,
  { isTgChanelAdd: boolean },
  { rejectValue: TRejectResponseData }
>('pay/getPayPageData', (data, { dispatch, rejectWithValue }) => {
  return payApi
    .getPayData(data.isTgChanelAdd)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (PAY_RESPONSE_KEY.AMOUNT in response.data) {
        return mapResponseDateToStore(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getPayPageData(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk Получает данные страницы истории оплат и обрабатывает результат.
 * @returns {TPaymentData | void} - Данные страницы оплаты или в undefined.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const getPaymentHistoryDataThunk = createAsyncThunk<
  TPaymentData[] | void,
  void,
  { rejectValue: TRejectResponseData }
>('pay/getPaymentHistoryDataThunk', (data, { dispatch, rejectWithValue }) => {
  return payApi
    .getPaymentHistoryData()
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if ('count' in response.data) {
        return mapPaymentsDataToStore(response.data.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getPaymentHistoryDataThunk()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk Получает данные по ценам на опции и общей сумме и обрабатывает результат.
 * @returns {TPayData | void} - Данные страницы оплаты или в undefined.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const getCalculatedPriceThunk = createAsyncThunk<
  TCalculatePrice | void,
  { data: TGetCalculatedPrice; successCallback: () => void },
  { rejectValue: TRejectResponseData }
>('pay/getCalculatedPriceThunk', (data, { dispatch, rejectWithValue }) => {
  return payApi
    .getCalculatedPrice(data.data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if ('full_cost' in response.data) {
        if (data.successCallback) {
          data.successCallback();
        }

        return mapCalculatePriceToStore(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getCalculatedPriceThunk(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk Получает ссылку для открытия страницы платежной системы.
 * @returns {TPayData | void} - Данные страницы оплаты или в undefined.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const getPayLinkThunk = createAsyncThunk<
  TCalculatePrice | void,
  { data: TGetPayLinkData; successCallback: (link: string) => void },
  { rejectValue: TRejectResponseData }
>('pay/getPayLinkThunk', (data, { dispatch, rejectWithValue }) => {
  return payApi
    .getPayLink(data.data)
    .then(response => {
      if (typeof response.data === 'string') {
        if (data.successCallback) {
          data.successCallback(response.data);
        }
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getPayLinkThunk(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk Получает данные для ссылки на автоплатеж и обрабатывает результат.
 * @returns {Promise<string | void>} - Ссылка на автоплатеж или в undefined.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const getAutoPayLinkData = createAsyncThunk<
  string | void,
  void,
  { rejectValue: TRejectResponseData }
>('pay/getAutoPayLinkData', (data, { dispatch, rejectWithValue }) => {
  return payApi
    .getAutoPayLink()
    .then(response => {
      if (REJECT_RESPONSE_KEY.STATUS in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (PAY_LINK_RESPONSE_KEYS.LINK in response.data) {
        return response.data.link;
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getAutoPayLinkData()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk Изменяет данные карты для автоплатежа и обрабатывает результат.
 * @returns {string | void} - Строка или в undefined.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const changeCartData = createAsyncThunk<string | void, void, { rejectValue: TRejectResponseData }>(
  'pay/changeCartData',
  (data, { dispatch, rejectWithValue }) => {
    return payApi
      .changeCard()
      .then(response => {
        if (REJECT_RESPONSE_KEY.STATUS in response.data && !response.data.ok) {
          throw new Error(String(response.data.status));
        }
        if (PAY_LINK_RESPONSE_KEYS.LINK in response.data) {
          return response.data.link;
        }
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(changeCartData()), 200);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

/**
 * Thunk Выключает автоплатеж и обрабатывает результат.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const turnOffAutoPayment = createAsyncThunk<void, void, { rejectValue: TRejectResponseData }>(
  'pay/turnOffAutoPayment',
  (data, { dispatch, rejectWithValue }) => {
    return payApi
      .turnOffAutoPayment()
      .then(response => {
        if (REJECT_RESPONSE_KEY.STATUS in response.data && !response.data.ok) {
          throw new Error(String(response.data.status));
        }
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(turnOffAutoPayment()), 200);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

/**
 * Thunk запрашивает данные счета для отправки пользователю.
 * @return {TInvoiceData | void} данные счета или undefined
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const createInvoiceThunk = createAsyncThunk<
  TInvoiceData | void,
  TCrateInvoice,
  { rejectValue: TRejectResponseData }
>('pay/createInvoice', (data, { dispatch, rejectWithValue }) => {
  return payApi
    .createInvoice(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.STATUS in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (CREATE_INVOICE_RESPONSE_KEY_NAMES.BRANCHES in response.data) {
        return mapInvoiceDataToStore(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(createInvoiceThunk(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk отправляет запрос на отправку счета пользователю в TG.
 * @return {TInvoiceData | void} данные счета или undefined
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const sendInvoiceToTg = createAsyncThunk<
  TCreateInvoiceResponse | void,
  TInvoiceData,
  { rejectValue: TRejectResponseData }
>('pay/sendInvoiceToTg', (data, { dispatch, rejectWithValue }) => {
  return payApi
    .sendInvoiceToTg(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.STATUS in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (CREATE_INVOICE_RESPONSE_KEY_NAMES.BRANCHES in response.data) {
        return response.data;
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(sendInvoiceToTg(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

export {
  changeCartData,
  getPayPageData,
  sendInvoiceToTg,
  getPayLinkThunk,
  createInvoiceThunk,
  getAutoPayLinkData,
  turnOffAutoPayment,
  getCalculatedPriceThunk,
  getPaymentHistoryDataThunk,
};
