import React from 'react';
import EmojiPicker, { EmojiClickData, EmojiStyle } from 'emoji-picker-react';

interface IEmojiLibProps {
  /**
   * Callback для добавления эмодзи в текст
   * @param {(emoji: EmojiClickData, event: MouseEvent) => void}
   */
  onEmojiClick: (emoji: EmojiClickData, event: MouseEvent) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const EmojiLib = ({ onEmojiClick, className = '' }: IEmojiLibProps) => {
  return (
    <div className={className}>
      <EmojiPicker
        lazyLoadEmojis
        skinTonesDisabled
        onEmojiClick={onEmojiClick}
        emojiStyle={EmojiStyle.GOOGLE}
      />
    </div>
  );
};
