import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { useAppDispatch } from '@store/store';
import { ToggleSwitch } from '@blocks/toggleSwitch';
import { TFilialData } from '@redux/accounts/models';
import { TSimpleOnchangeHandler } from '@shared/types';
import { CopyTemplates } from '@components/copyTemplates';
import { HeaderWithEdit } from '@components/headerWithEdit';
import { TemplateStatLink } from '@blocks/templateStatLink';
import { TEMPLATE_DATA_KEY_NAMES, TTemplatePageData } from '@redux/template/models';
import {
  selectOnchange,
  setTemplatesName,
  setOldTemplateName,
} from '@redux/template/templateSlice';

type TemplateHeaderProps = {
  /**
   * Колличество адрестов в филиале
   * @param {number}
   */
  accountsLength: number;
  /**
   * Копирует шаблон в переданный список филиалов
   * @param {(filialToCopy: string[]) => void}
   */
  copyHandler: (filialToCopy: string[]) => void;
  /**
   * Флаг копирования шаблонов
   * @param {boolean}
   */
  isTemplatesCopying: boolean;
  /**
   * Список аккаунтов фииала
   * @param {TFilialData[]}
   */
  accounts: TFilialData[];
  /**
   * Флаг успешного завершения процесса копирования
   * @param {boolean}
   */
  isCopyTemplatesSuccess: boolean;
  /**
   * Данные шаблона
   * @param {TTemplatePageData}
   */
  data: TTemplatePageData;
  /**
   * Опциональны параметр доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const TemplateHeader = memo(
  ({
    accounts,
    copyHandler,
    accountsLength,
    isTemplatesCopying,
    isEditTemplatesAccess,
    isCopyTemplatesSuccess,
    data,
    className = '',
  }: TemplateHeaderProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { id, oldTitle, statusEnable, sendOnlyLostClient } = data;

    // Callback для установки имени шаблона
    const setOldTitleHandler = useCallback(
      (value: string) => {
        dispatch(setOldTemplateName({ title: value }));
      },
      [dispatch],
    );

    // Callback для установки имени шаблона в шапке шаблона
    const setTitleHandler = useCallback(
      (value: string) => {
        dispatch(setTemplatesName({ title: value }));
      },
      [dispatch],
    );

    // Обработчик изменения чекбоксов
    const selectOnchangeHandler: TSimpleOnchangeHandler = useCallback(
      event => {
        const { name, checked } = event.currentTarget;
        dispatch(selectOnchange({ name, checked }));
      },
      [dispatch],
    );

    return (
      <div className={className}>
        <div>
          <HeaderWithEdit
            id={id}
            oldTitle={oldTitle}
            setTitle={setTitleHandler}
            setOldTitle={setOldTitleHandler}
            emptyTitleName={
              id
                ? t('TEMPLATE_PAGE_TEXT.headerOfExistingTemplate', { id })
                : t('TEMPLATE_PAGE_TEXT.newTemplateHeader')
            }
            className=''
          />
          <div className='flex flex-wrap items-center justify-between w-full'>
            <div className='flex items-center mt-4'>
              {isEditTemplatesAccess && (
                <ToggleSwitch
                  checked={statusEnable}
                  onChangeHandler={selectOnchangeHandler}
                  name={TEMPLATE_DATA_KEY_NAMES.STATUS_ENABLE}
                />
              )}
              <div className='ml-4'>
                <p className='font-medium text-blackText m-0'>
                  {t('TEMPLATE_PAGE_TEXT.templateSwitchTitle')}
                </p>
                <p className='m-0 text-[0.875rem] text-textareaBlack'>
                  {t('TEMPLATE_PAGE_TEXT.templateSwitch')}
                </p>
              </div>
            </div>
            <div className='flex flex-wrap flex-col sm:flex-row w-full sm:w-fit'>
              {sendOnlyLostClient ? (
                <TemplateStatLink dense id={id} className='mt-4 sm:mr-4' />
              ) : null}
              {isEditTemplatesAccess && id && accountsLength > 1 ? (
                <CopyTemplates
                  accounts={accounts}
                  copyHandler={copyHandler}
                  isTemplatesCopying={isTemplatesCopying}
                  isCopyTemplatesSuccess={isCopyTemplatesSuccess}
                  className='mt-4 w-full sm:w-fit'
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

TemplateHeader.displayName = 'TemplateHeader';
