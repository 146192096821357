import { TGetAutoResponseData } from '@api/types';
import { NEW_AUTO_RESPONSE_ID } from '@const/autoResponse';
import { TAutoResponseItem } from '@redux/autoResponse/models';

export const autoResponseMockData: TAutoResponseItem[] = [
  {
    id: '1',
    isActive: true,
    responseToClient:
      'Адрес нашего салона: Профсоюзная ул., 102, стр. 1, Москва https://www.google.com/maps/place/The+Moscow+Kremlin/@55.7530561,37.6175325,16.56z/data=!4m6!3m5!1s0x46b54a50b315e573:0xa886bf5a3d9b2e68!8m2!3d55.7509544!4d37.6175755!16zL20vMDIxbHJ5?entry=ttu',
    clientRequest: ['Адрес', 'адрес'],
  },
];

export const autoResponseData: TGetAutoResponseData = {
  items: [
    {
      words: ['test'],
      reply: 'test',
      caption: '',
      active: true,
    },
  ],
};

export const autoResponseEmptyItem: TAutoResponseItem = {
  id: NEW_AUTO_RESPONSE_ID,
  isActive: true,
  responseToClient: '',
  clientRequest: [''],
};
