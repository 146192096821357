import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { EVENT_KEY_NAMES } from '@models/index';
import { getAppData } from '@redux/app/selectors';
import { VIDEO_INSTRUCTIONS } from '@const/common';
import { ToastsMessages } from '@components/toastsMessages';
import { getBlackListData } from '@redux/blackList/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { HeaderWithButton } from '@components/headerWithButton';
import { TBlackListStatus } from '@redux/blackList/initialState';
import { BlackListComponent } from '@components/blackListComponent';
import { TKeyboardClickEvent, TOnChangeHandler } from '@shared/types';
import { BlackListSearchInput } from '@components/blackListSearchInput';
import {
  addNumberToBlacklist,
  deleteNumberFromBlacklist,
  addNumberToSendOutBlacklist,
  deleteNumberFromSendOutBlacklist,
} from '@redux/blackList/blackListThunks';
import {
  setSearchString,
  hideAllErrorToast,
  hideAllSuccessToast,
  setNewNumberToCommonList,
  setNewNumberToUsubscribersList,
} from '@redux/blackList/blackListSlice';

interface IBlackListProps {
  /**
   * Статус страницы ЧС
   * @param {TBlackListStatus}
   */
  status: TBlackListStatus;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const BlackListTemplate = memo(({ status, className = '' }: IBlackListProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { appLanguage } = useAppSelector(getAppData);

  // получаем данные из store/blacklist
  const {
    isLoading,
    searchString,
    errorMessage,
    successMessage,
    commonFilteredList,
    newNumberToCommonList,
    unsubscribersFilteredList,
    newNumberToUnsubscribersList,
  } = useAppSelector(getBlackListData);

  const hideAllSuccessToastHandler = useCallback(() => {
    dispatch(hideAllSuccessToast());
  }, [dispatch]);

  const hideAllErrorToastHandler = useCallback(() => {
    dispatch(hideAllErrorToast());
  }, [dispatch]);

  // Проверяет введен ли номер и вызывает callBack для добавления номера в базу.
  const addNumber = useCallback(
    (number: string) => () => {
      dispatch(addNumberToBlacklist({ number }));
    },
    [dispatch],
  );

  // Проверяет введен ли номер и вызывает callBack для добавления номера в базу.
  const addNumberToSenOutBlacklist = useCallback(
    (number: string) => () => {
      dispatch(addNumberToSendOutBlacklist({ number }));
    },
    [dispatch],
  );

  // Вызывает callBack для удаления номера из базы.
  const deleteNumberFromCommonList = useCallback(
    (number: string) => () => {
      dispatch(deleteNumberFromBlacklist(number));
    },
    [dispatch],
  );

  // Вызывает callBack для удаления номера из базы.
  const deleteNumberFromUnsubscribersList = useCallback(
    (number: string) => () => {
      dispatch(deleteNumberFromSendOutBlacklist(number));
    },
    [dispatch],
  );

  // Обработчик события Change, добавляет введенные данные в state.
  const inputNewCommonNumberHandler = useCallback(
    (value: string) => {
      if (value) {
        dispatch(setNewNumberToCommonList({ value }));
      } else {
        dispatch(setNewNumberToCommonList({ value: '' }));
      }
    },
    [dispatch],
  );

  // Обработчик события Change, добавляет введенные данные в state.
  const inputNewUnsubscribersNumberHandler = useCallback(
    (value: string) => {
      if (value) {
        dispatch(setNewNumberToUsubscribersList({ value }));
      } else {
        dispatch(setNewNumberToUsubscribersList({ value: '' }));
      }
    },
    [dispatch],
  );

  // Обработчик события Change, добавляет данные в state для выполнения фильтрации по номеру.
  const setSearch: TOnChangeHandler = useCallback(
    event => {
      if (!Number.isNaN(+event.currentTarget.value)) {
        dispatch(setSearchString({ value: event.target.value }));
      } else {
        dispatch(setSearchString({ value: '' }));
      }
    },
    [dispatch],
  );

  // Очищает введенное значение
  const clearSearchString = useCallback(() => {
    dispatch(setSearchString({ value: '' }));
  }, [dispatch]);

  // Обработчик события keyDown, добавляет номер при нажатии на enter
  const onKeyDownAddNumberToCommonListHandler: TKeyboardClickEvent = useCallback(
    event => {
      const {
        key,
        currentTarget: { value },
      } = event;
      if (key === EVENT_KEY_NAMES.ENTER && value.length > 9) {
        dispatch(addNumberToBlacklist({ number: newNumberToCommonList }));
      }
    },
    [dispatch, newNumberToCommonList],
  );

  // Обработчик события keyDown, добавляет номер при нажатии на enter
  const onKeyDownAddNumberToUnsubscribersListHandler: TKeyboardClickEvent = useCallback(
    event => {
      const {
        key,
        currentTarget: { value },
      } = event;
      if (key === EVENT_KEY_NAMES.ENTER && value.length > 9) {
        dispatch(addNumberToSendOutBlacklist({ number: newNumberToUnsubscribersList }));
      }
    },
    [dispatch, newNumberToUnsubscribersList],
  );

  return (
    <div className={`flex flex-col relative ${className}`}>
      <HeaderWithButton
        headerText={t('BLACKLIST_PAGE_TEXT.header')}
        instructionData={VIDEO_INSTRUCTIONS.blacklist}
        className='mb-10'
      />
      <div>
        <div className='mb-8'>
          <BlackListSearchInput
            value={searchString}
            onChangeHandler={setSearch}
            clearSearchString={clearSearchString}
            placeholder={t('BLACKLIST_PAGE_TEXT.searchInputPlaceholder')}
          />
        </div>
        <BlackListComponent
          isLoading={isLoading}
          appLanguage={appLanguage}
          newNumber={newNumberToCommonList}
          blackListData={commonFilteredList}
          setNewNumber={inputNewCommonNumberHandler}
          isAddingNumber={status === 'addingToBlackList'}
          deleteNumberHandler={deleteNumberFromCommonList}
          addNumberHandler={addNumber(newNumberToCommonList)}
          headerText={t('BLACKLIST_PAGE_TEXT.commonListHeader')}
          isDeleteNumberError={status === 'errorDeletingFromBlackList'}
          onKeyDownAddNumberHandler={onKeyDownAddNumberToCommonListHandler}
          className='mb-6'
        />
        <BlackListComponent
          isLoading={isLoading}
          appLanguage={appLanguage}
          newNumber={newNumberToUnsubscribersList}
          blackListData={unsubscribersFilteredList}
          setNewNumber={inputNewUnsubscribersNumberHandler}
          isAddingNumber={status === 'addingToSendOutBlackList'}
          deleteNumberHandler={deleteNumberFromUnsubscribersList}
          headerText={t('BLACKLIST_PAGE_TEXT.listOfUnsubscribersHeader')}
          isDeleteNumberError={status === 'errorDeletingFromSendOutBlackList'}
          onKeyDownAddNumberHandler={onKeyDownAddNumberToUnsubscribersListHandler}
          addNumberHandler={addNumberToSenOutBlacklist(newNumberToUnsubscribersList)}
        />
      </div>
      <ToastsMessages
        errorMessage={errorMessage}
        successMessage={successMessage}
        isShowErrorToast={!!errorMessage}
        isShowSuccessToast={!!successMessage}
        clearErrorCallback={hideAllErrorToastHandler}
        clearSuccessStatusCallback={hideAllSuccessToastHandler}
      />
    </div>
  );
});

BlackListTemplate.displayName = 'BlackListTemplate';
