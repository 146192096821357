import { blackListApi } from '@api/blackListApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TRejectResponseData } from '@models/index';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { blackListMappers } from '@redux/blackList/blackListMappers';
import { REJECT_RESPONSE_KEY, BLACKLIST_RESPONSE_KEYS } from '@api/types';

import { TBlackListData } from './models';

/**
 * Thunk для получения черного списка.
 *
 * @returns {TBlackListData[] | void} Данные черного списка или void.
 * @throws {Error} Если произошла ошибка при получении черного списка.
 * @param {any} dispatch - Функция для отправки экшенов Redux.
 * @param {(value: TRejectResponse | TRejectResponse2) => RejectWithValue<TRejectResponseData, unknown>} rejectWithValue - Функция для обработки ошибок запроса.
 */
const getBlackList = createAsyncThunk<
  TBlackListData[] | void,
  void,
  { rejectValue: TRejectResponseData }
>('blackList/getBlackList', (data, { dispatch, rejectWithValue }) => {
  return blackListApi
    .getBlackList()
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      if (BLACKLIST_RESPONSE_KEYS.DATA in response.data) {
        return response.data.data;
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getBlackList()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk для получения черного списка рассылок.
 *
 * @returns {TBlackListData[] | void} Данные черного списка или void.
 * @throws {Error} Если произошла ошибка при получении черного списка.
 * @param {any} dispatch - Функция для отправки экшенов Redux.
 * @param {(value: TRejectResponse | TRejectResponse2) => RejectWithValue<TRejectResponseData, unknown>} rejectWithValue - Функция для обработки ошибок запроса.
 */
const getSendOutBlackList = createAsyncThunk<
  TBlackListData[] | void,
  void,
  { rejectValue: TRejectResponseData }
>('blackList/getSendOutBlackList', (data, { dispatch, rejectWithValue }) => {
  return blackListApi
    .geSendOutBlackList()
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      if (Array.isArray(response.data)) {
        return blackListMappers.sendOutBlackListToStore(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getSendOutBlackList()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk для добавления номера в черный список.
 *
 * @param {{ number: string }} data - Данные номера для добавления.
 * @returns {TBlackListData | undefined} Новый номер или undefined.
 * @throws {Error} Если произошла ошибка при добавлении номера.
 * @param {any} dispatch - Функция для отправки экшенов Redux.
 * @param {(value: TRejectResponse | TRejectResponse2) => RejectWithValue<TRejectResponseData, unknown>} rejectWithValue - Функция для обработки ошибок запроса.
 */
const addNumberToBlacklist = createAsyncThunk<
  TBlackListData | undefined,
  { number: string },
  { rejectValue: TRejectResponseData }
>('blackList/addNumberToBlacklist', (data, { dispatch, rejectWithValue }) => {
  if (!data.number) return;
  return blackListApi
    .addNumberToBlacklist({ phone: data.number })
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }

      return data;
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(addNumberToBlacklist(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk для добавления номера в черный список.
 *
 * @param {{ number: string }} data - Данные номера для добавления.
 * @returns {TRequestStatus | undefined} Данные ответа на запрос или undefined.
 * @throws {Error} Если произошла ошибка при добавлении номера.
 * @param {any} dispatch - Функция для отправки экшенов Redux.
 * @param {(value: TRejectResponse | TRejectResponse2) => RejectWithValue<TRejectResponseData, unknown>} rejectWithValue - Функция для обработки ошибок запроса.
 */
const addNumberToSendOutBlacklist = createAsyncThunk<
  TBlackListData | undefined,
  { number: string },
  { rejectValue: TRejectResponseData }
>('blackList/addNumberToSendOutBlacklist', (data, { dispatch, rejectWithValue }) => {
  if (!data.number) return;
  return blackListApi
    .addNumberToSendOutBlacklist({ phone: data.number })
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      return data;
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(addNumberToSendOutBlacklist(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk для удаления номера из черного списка.
 *
 * @param {string} newNumber - Номер для удаления.
 * @returns {TRequestStatus | undefined} Данные ответа на запрос или undefined.
 * @throws {Error} Если произошла ошибка при удалении номера.
 * @param {any} dispatch - Функция для отправки экшенов Redux.
 * @param {(value: TRejectResponse | TRejectResponse2) => RejectWithValue<TRejectResponseData, unknown>} rejectWithValue - Функция для обработки ошибок запроса.
 */
const deleteNumberFromBlacklist = createAsyncThunk<
  string | undefined,
  string,
  { rejectValue: TRejectResponseData }
>('blackList/deleteNumberFromBlacklist', (newNumber: string, { dispatch, rejectWithValue }) => {
  return blackListApi
    .deleteNumberFromBlacklist({ phone: newNumber })
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      return newNumber;
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(deleteNumberFromBlacklist(newNumber)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk для удаления номера из черного списка рассылок.
 *
 * @param {string} newNumber - Номер для удаления.
 * @returns {TRequestStatus | undefined} Данные ответа на запрос или undefined.
 * @throws {Error} Если произошла ошибка при удалении номера.
 * @param {any} dispatch - Функция для отправки экшенов Redux.
 * @param {(value: TRejectResponse | TRejectResponse2) => RejectWithValue<TRejectResponseData, unknown>} rejectWithValue - Функция для обработки ошибок запроса.
 */
const deleteNumberFromSendOutBlacklist = createAsyncThunk<
  string | undefined,
  string,
  { rejectValue: TRejectResponseData }
>(
  'blackList/deleteNumberFromSendOutBlacklist',
  (newNumber: string, { dispatch, rejectWithValue }) => {
    return blackListApi
      .deleteNumberFromSendOutBlacklist({ phone: newNumber })
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(String(response.data));
        }
        return newNumber;
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(deleteNumberFromSendOutBlacklist(newNumber)), 200);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

export {
  getBlackList,
  getSendOutBlackList,
  addNumberToBlacklist,
  addNumberToSendOutBlacklist,
  deleteNumberFromBlacklist,
  deleteNumberFromSendOutBlacklist,
};
