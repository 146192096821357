export type TAutoResponseItem = {
  [key: string]: string | string[] | boolean;
  id: string;
  isActive: boolean;
  clientRequest: string[];
  responseToClient: string;
};

export enum AUTO_RESPONSE_ITEM_KEYS {
  ID = 'id',
  IS_ACTIVE = 'isActive',
  CLIENT_REQUEST = 'clientRequest',
  RESPONSE_TO_CLIENT = 'responseToClient',
}

export type TAutoResponseItemActivityCheckData = { clientRequest: string[]; isActive: boolean };

export type TCreateAutoResponseThunkData = {
  data: TAutoResponseItem[];
  activeItemId: string;
  callback: () => void;
};

export type TUpdateAutoResponseThunkData = {
  data: TAutoResponseItem[];
  activeItemId: string;
  callback: () => void;
};

export type TDeleteAutoResponseItemData = { data: TAutoResponseItem[]; activeItemId: string };
