import { REJECT_RESPONSE_KEY } from '@api/types';
import { myTrackerApi } from '@api/myTrackerApi';
import { TPaymentType } from '@helpers/myTracker';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TRejectResponseData } from '@models/index';
import { inviteFriendsApi } from '@api/inviteFriendsApi';
import { TCopyTemplateData } from '@redux/template/models';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { inviteFriendsActionMappers } from '@redux/inviteFriendsAction/inviteFriendsActionMappers';

import { ACTION_DATA_KEY_NAMES, TActionData, TCardType } from './type';

/**
 * Thunk Запрашивает шаблон акции по ID.
 * @param {string} actionId - Id шаблона.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
export const getInviteFriendActionThunk = createAsyncThunk<
  TActionData | void,
  string,
  { rejectValue: TRejectResponseData }
>('inviteFriendSlice/getInviteFriendActionThunk', (actionId, { dispatch, rejectWithValue }) => {
  return inviteFriendsApi
    .getActionById(actionId)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error();
      }
      if ('data' in response.data) {
        return inviteFriendsActionMappers.actionDataToStore(response.data.data[0]);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getInviteFriendActionThunk(actionId)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk Создает новый шаблон.
 * @param {TActionData} data - Данные нового шаблона.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
export const createNewActionThunk = createAsyncThunk<
  TActionData | void,
  TActionData,
  { rejectValue: TRejectResponseData }
>('inviteFriendSlice/createNewActionThunk', (data: TActionData, { dispatch, rejectWithValue }) => {
  return inviteFriendsApi
    .createAction(inviteFriendsActionMappers.actionToBack(data))
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error();
      }
      if ('title' in response.data) {
        const paymentType: TPaymentType = data.bonusType === 'first_visit' ? 'cash' : 'point';

        myTrackerApi.inviteFriendsNewActionCreate({
          payment_type: paymentType,
          sample_id: response.data.id,
        });

        return inviteFriendsActionMappers.actionDataToStore(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(createNewActionThunk(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk Обновляет данные шаблона.
 * @param {TActionData} data - Данные нового шаблона.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
export const updateActionThunk = createAsyncThunk<
  TActionData | void,
  { newData: TActionData; oldData: TActionData },
  { rejectValue: TRejectResponseData }
>('inviteFriendSlice/updateActionThunk', (data, { dispatch, rejectWithValue }) => {
  return inviteFriendsApi
    .updateAction(inviteFriendsActionMappers.actionToBack(data.newData))
    .then(response => {
      if (
        REJECT_RESPONSE_KEY.OK in response.data &&
        response.data.status === REQUEST_TEXT_ERROR_STATUS.OBJECT_NOT_UPDATE
      ) {
        return data.newData;
      }
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error();
      }
      if (ACTION_DATA_KEY_NAMES.TITLE in response.data) {
        if (!data.newData.active && data.oldData.active !== data.newData.active) {
          myTrackerApi.inviteFriendsStopAction({ sample_id: data.newData.id });
        }
        return inviteFriendsActionMappers.actionDataToStore(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(updateActionThunk(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk Получает данные карт.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
export const getCardTypesThunk = createAsyncThunk<
  TCardType[],
  void,
  { rejectValue: TRejectResponseData }
>('inviteFriendSlice/getCardTypesThunk', (_, { dispatch, rejectWithValue }) => {
  return inviteFriendsApi
    .getCardsTypes()
    .then(response => {
      return inviteFriendsActionMappers.loyaltyCardsToStore(response.data.data);
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getCardTypesThunk()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk Получает минимальную ену услуги.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
export const getMinimalPriceThunk = createAsyncThunk<
  number,
  void,
  { rejectValue: TRejectResponseData }
>('inviteFriendSlice/getMinimalPriceThunk', (_, { dispatch, rejectWithValue }) => {
  return inviteFriendsApi
    .getMinimalPrice()
    .then(response => {
      return response.data.data[0].price_min;
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getMinimalPriceThunk()), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk-экшен для копирования шаблона в переданный список филиалов.
 * @param {TCopyTemplateData} данные для копирования, список филиалов и id шаблона.
 * @returns {Promise<void>} - Данные шаблона или  void в случае ошибки.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект, представляющий информацию об ошибке в случае неудачного запроса.
 */
export const copyActionToBranchThunk = createAsyncThunk<
  number,
  TCopyTemplateData,
  { rejectValue: TRejectResponseData }
>('inviteFriendSlice/copyActionToBranchThunk', (data, { dispatch, rejectWithValue }) => {
  return inviteFriendsApi
    .copyActionToBranch(data)
    .then(() => {
      return data.branchList.length;
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(copyActionToBranchThunk(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});
