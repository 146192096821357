import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { PATH_NAMES } from '@data/dict';
import { Icon } from '@blueprintjs/core';
import { useAppSelector } from '@store/store';
import { useNavigate } from 'react-router-dom';
import { QUERY_PARAMS } from '@const/apiConstants';
import { getAccountsData } from '@redux/accounts/selectors';

type TTemplateStatLinkProps = {
  /**
   * Обязательный параметр ID шаблона
   * @param {string}
   */
  id: string;
  /**
   * Опциональный параметр высоты кнопки
   * @param {boolean}
   */
  dense?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

// Компонент для перехода на страницу статистики шаблона
export const TemplateStatLink = memo(
  ({ id, dense = false, className = '' }: TTemplateStatLinkProps) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    // получаем объект selectedFilial
    const { selectedFilial } = useAppSelector(getAccountsData);

    const pathToNavigate = `${PATH_NAMES.TEMPLATE_STAT}?${QUERY_PARAMS.ACC_ID}=${selectedFilial?.accId}&${QUERY_PARAMS.TEMPL_ID}=${id}`;

    // Обработчик клика на кнопку для перехода на страницу статистики шаблона
    const onClickHandler = useCallback(() => {
      navigate(pathToNavigate);
    }, [navigate, pathToNavigate]);

    return (
      <div className={`flex xl:justify-end ${className}`}>
        <Button
          fill
          dense={dense}
          type='action'
          view='outlined'
          color='default'
          onClick={onClickHandler}
          text={t('TEMPLATE_PAGE_TEXT.detailStatsButton')}
          icon={<Icon icon='timeline-bar-chart' size={24} />}
        />
      </div>
    );
  },
);

TemplateStatLink.displayName = 'TemplateStatLink';
