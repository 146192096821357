export const AUTH_PAGE_TEXT = {
  header: 'Авторизуйтесь через Телеграм',
  backButton: 'Назад',
  error: 'Ошибка, перезагрузите страницу и попробуйте еще раз.',
  userIdInputPlaceholder: 'Телеграм ID',
  userIdInputHeader: 'Введите телеграм ID',
  errorInUserId: 'Ошибка, проверьте телеграм ID',
  noUserIdError: 'Для продолжения введите телеграм ID',
  remindButton: 'Напомнить',
  pinInputHeader: 'Введите PIN, присланный',
  errorInPin: 'Неверный PIN',
  noPinError: 'Для продолжения введите PIN',
  linkButtonText: 'Бьюти Ботом',
  enterButton: 'Войти',
  sendPin: 'Отправить PIN',
  remindTelegramIdModal: {
    dialogTitle: 'Напомнить телеграм ID',
    description: 'Чтобы узнать ваш телеграм id, перейдите по ссылке в телеграм и запустите бота',
    closeButton: 'Закрыть',
    goButton: 'Перейти',
  },
};

export const AUTH_PAGE_TEXT_ENG = {
  header: 'Log in via Telegram',
  backButton: 'Back',
  error: 'Error, please reload the page and try again.',
  userIdInputPlaceholder: 'Telegram ID',
  userIdInputHeader: 'Enter telegram ID',
  errorInUserId: 'Error, check telegram ID',
  noUserIdError: 'Enter your telegram ID to continue',
  remindButton: 'Remind',
  pinInputHeader: 'Enter the PIN sent',
  errorInPin: 'Invalid PIN',
  noPinError: 'Please enter your PIN to continue',
  linkButtonText: 'Beauty Bot',
  enterButton: 'Login',
  sendPin: 'Send PIN',
  remindTelegramIdModal: {
    dialogTitle: 'Remind telegram ID',
    description: 'To find out your telegram id, follow the link in telegram and launch the bot',
    closeButton: 'Close',
    goButton: 'Go',
  },
};
