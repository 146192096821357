import { createAsyncThunk } from '@reduxjs/toolkit';
import { REJECT_RESPONSE_KEY } from '@api/types';
import { TRejectResponseData } from '@models/index';
import { partnerProgramApi } from '@api/partnerProgramApi';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { TPayHistoryConsultantTableData } from '@redux/payHistoryConsultantTable/models';
import { mapGetPayHistoryConsultantTableDataToStore } from '@redux/payHistoryConsultantTable/payHistoryConsultantTableMappers';

/**
 * Thunk Получает данные таблицы истории выплат консультанта и обрабатывает результат.
 * @param {string} data - Данные для запроса.
 * @returns {TPayHistoryConsultantTableData[] | void} - Данные таблицы истории выплат консультанта или в undefined.
 * @throws {Error} - Если ответ API содержит ошибку.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект с данными об ошибке при отклонении промиса.
 */
const getPayHistoryConsultantTableData = createAsyncThunk<
  TPayHistoryConsultantTableData[] | void,
  string,
  { rejectValue: TRejectResponseData }
>(
  'payHistoryConsultantTableSlice/getPayHistoryConsultantTableData',
  (data, { dispatch, rejectWithValue }) => {
    return partnerProgramApi
      .getPayHistoryConsultantTableData(data)
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(response.data.status);
        }
        if ('data' in response.data) {
          return mapGetPayHistoryConsultantTableDataToStore(response.data);
        }
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(getPayHistoryConsultantTableData(data)), 200);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

export { getPayHistoryConsultantTableData };
