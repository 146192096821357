import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { TCurrency } from '@api/types';
import { numWord } from '@helpers/index';
import { TAppLanguage } from '@models/index';
import { TCardSelectCallbackToCard } from '@shared/types';
import { MONTH_WORDS, TPayCardsTags, WEEK_WORDS } from '@const/pay';

interface IPayCardProps {
  /**
   * Валюта для оплаты
   * @param {TCurrency}
   */
  currency: TCurrency;
  /**
   * Количество месяцев, по этому параметру рассчитывается цена
   * @param {number}
   */
  months: number;
  /**
   * Стоимость месяца с учетом бонусных недель
   * @param {TMonthData}
   */
  oneMonthCostWithDiscount: number;
  /**
   * Количество недель в в подарок
   * @param {string}
   */
  giftWeeks: string;
  /**
   * Цена за один месяц
   * @param {number}
   */
  oneMonthCost: number;
  /**
   * Выгода
   * @param {number}
   */
  profit: number;
  /**
   * Флаг состояния карточки
   * @param {boolean}
   */
  isSelected: boolean;
  /**
   * Callback для обработки onClick
   * @param {TCardSelectCallbackToCard}
   */
  onClickCallback: TCardSelectCallbackToCard;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Флаг оплаченного тарифа
   * @param {boolean}
   */
  isPayedTariff: boolean;
  /**
   * Флаг запроса новых цен
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Текст тэга в правом верхнем углу
   * @param {TPayCardsTags}
   */
  tagKey?: TPayCardsTags;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const PayCard = memo(
  ({
    // eslint-disable-next-line
    profit,
    months,
    tagKey,
    currency,
    isLoading,
    giftWeeks,
    isSelected,
    appLanguage,
    oneMonthCost,
    isPayedTariff,
    onClickCallback,
    oneMonthCostWithDiscount,
    className = '',
  }: IPayCardProps) => {
    const { t } = useTranslation();

    // Общая сумма к оплате
    const totalAmount = oneMonthCost * months;

    const onClickHandler = useCallback(() => {
      onClickCallback(totalAmount, months);
    }, [onClickCallback, months, totalAmount]);

    // Формируем строку с количеством месяцев
    const numberOfMonthText = `${months} ${numWord(
      months,
      MONTH_WORDS[appLanguage],
    ).toLocaleUpperCase()}`;

    // Формируем строку с общей суммой к оплате
    const oneMonthCostWithDiscountText = t(
      currency === 'RUB'
        ? 'PAY_PAGE_TEXT.oneMonthCostWithDiscount'
        : 'PAY_PAGE_TEXT.oneMonthCostWithDiscountUSD',
      {
        oneMonthCostWithDiscount,
      },
    );

    const totalProfit = t(currency === 'RUB' ? 'PAY_PAGE_TEXT.profit' : 'PAY_PAGE_TEXT.profitUSD', {
      profit,
    });

    // Формируем строку информации о количестве недель в подарок
    const giftText = t('PAY_PAGE_TEXT.giftText', {
      giftWeeks,
      weekText: numWord(Number(giftWeeks), WEEK_WORDS[appLanguage]),
    });

    const borderStyle = 'border';

    const borderColor = isSelected ? 'border-orangeBg' : 'border-quartz';

    const backgroundColor = isPayedTariff
      ? 'bg-gray1'
      : tagKey && !isPayedTariff
      ? 'bg-green6 border-btnGreen'
      : isSelected && !isPayedTariff
      ? 'bg-white'
      : 'bg-white';

    const cardStyle = `relative border ${borderStyle} flex flex-col justify-between ${
      !isSelected ? 'hover:border-btnGreen' : ''
    } rounded-lg px-4 pt-6 pb-4 h-[15.625rem] min-w-[12.875rem] w-full select-none ${backgroundColor} ${borderColor} cursor-pointer ${className}`;

    return (
      <div onClick={onClickHandler} className={cardStyle}>
        {tagKey ? (
          <span className='absolute top-0 right-0 bg-btnGreen rounded-tr-lg rounded-bl-lg flex items-center justify-center text-white text-[0.625rem] leading-3 tracking-[0.15em] px-2 h-6 w-fit'>
            {t(`PAY_PAGE_TEXT.${tagKey}`)}
          </span>
        ) : null}
        <div>
          <p className='font-medium text-small_text text-textareaBlack leading-5 mb-6'>
            {numberOfMonthText}
          </p>
          <p className='font-medium text-[1.25rem] text-black leading-8 tracking-[0.004em] mb-0.5'>
            {oneMonthCostWithDiscountText}
          </p>
          {months > 1 ? (
            <p className='bg-orangeBg rounded-full font-medium text-tooltips text-white leading-5 px-2.5 py-1 w-fit'>
              {totalProfit}
            </p>
          ) : null}
        </div>
        {+giftWeeks > 0 ? (
          <p className='font-medium text-green1 leading-6 tracking-[0.022em] my-[1.625]'>
            {giftText}
          </p>
        ) : null}
        <div className='bg-white rounded-full'>
          <Button
            fill
            dense
            type='action'
            color='success'
            loading={isLoading}
            view={isSelected ? 'filled' : 'outlined'}
            text={
              <span
                className={`font-medium text-small_text leading-6 tracking-[0.022em] ${
                  isSelected ? 'text-white' : 'text-black'
                }`}>
                {isPayedTariff && !isSelected
                  ? t('PAY_PAGE_TEXT.extendButton')
                  : isSelected
                  ? t('PAY_PAGE_TEXT.selectedButton')
                  : t('PAY_PAGE_TEXT.selectButton')}
              </span>
            }
          />
        </div>
        {/* {isSelected ? (
          <div
            className={`absolute bottom-0 left-0 translate-y-[100%] ${
              isPayedTariff ? 'bg-gray1' : 'bg-white'
            } h-8 w-full`}
          />
        ) : null} */}
      </div>
    );
  },
);

PayCard.displayName = 'PayCard';
