import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Checkbox } from '@uikit/CheckBox';
import { FormLabel } from '@blocks/formLabel';
import { TOnChangeHandler } from '@shared/types';
import { REVIEW_RECORD_STATUS } from '@const/reviews';

interface IReviewsRecordStatusProps {
  /**
   * Значение для чекбокса clientExpectation
   * @param {boolean}
   */
  clientExpectation: boolean;
  /**
   * Значение для чекбокса clientCame
   * @param {boolean}
   */
  clientCame: boolean;
  /**
   * Значение для чекбокса clientConfirmed
   * @param {boolean}
   */
  clientConfirmed: boolean;
  /**
   * Значение для чекбокса clientDidNotCome
   * @param {boolean}
   */
  clientDidNotCome: boolean;
  /**
   * Callback для обработки события onChange на checkbox
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}
// подумать над объединением внутренней логики с компонентом TemplateRecordStatus
export const ReviewsRecordStatus = memo(
  ({
    clientCame,
    clientConfirmed,
    onChangeHandler,
    clientDidNotCome,
    clientExpectation,
    className = '',
  }: IReviewsRecordStatusProps) => {
    const { t } = useTranslation();

    const recordStatusData = useMemo(
      () => [
        {
          checked: clientDidNotCome,
          label: t('LABEL_TEXT.clientDidNotCome'),
          name: REVIEW_RECORD_STATUS.CLIENT_DID_NOT_COME,
          classNameText: 'mr-2.5 mb-2.5',
        },
        {
          checked: clientExpectation,
          label: t('LABEL_TEXT.clientExpectation'),
          name: REVIEW_RECORD_STATUS.CLIENT_EXPECTATION,
          classNameText: 'mb-2.5',
        },
        {
          checked: clientCame,
          label: t('LABEL_TEXT.clientCame'),
          name: REVIEW_RECORD_STATUS.CLIENT_CAME,
          classNameText: 'mr-2.5 mb-2.5',
        },
        {
          checked: clientConfirmed,
          label: t('LABEL_TEXT.clientConfirmed'),
          name: REVIEW_RECORD_STATUS.CLIENT_CONFIRMED,
          classNameText: 'mb-2.5',
        },
      ],
      [clientDidNotCome, clientExpectation, clientCame, clientConfirmed, t],
    );

    const recordStatusElementsToRender = useMemo(
      () =>
        recordStatusData.map(({ name, checked, classNameText, label }) => (
          <div key={name} className={classNameText}>
            <Checkbox
              name={name}
              label={label}
              color='success'
              checked={checked}
              onChange={onChangeHandler}
              className='w-[14.625rem]'
            />
          </div>
        )),
      [onChangeHandler, recordStatusData],
    );

    return (
      <div className={`max-w-[30rem] ${className}`}>
        <FormLabel
          htmlFor='recordStatus'
          labelTextVariant='recordStatus'
          className='font-medium mb-2'
          fontSize='tracking-[0.022em]'
        />
        <div className='flex flex-wrap'>{recordStatusElementsToRender}</div>
      </div>
    );
  },
);

ReviewsRecordStatus.displayName = 'ReviewsRecordStatus';
