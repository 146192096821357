import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { Dialog } from '@uikit/Dialog';
import { PreviewComponent } from '@components/previewComponent';

type TProps = {
  /**
   * Текст первого сообщения
   * @param {string}
   */
  firstMessage: string;
  /**
   * Текст второго сообщения
   * @param {string}
   */
  secondMessage: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const InviteFriendsPreview = memo(
  ({ firstMessage, secondMessage, className = '' }: TProps) => {
    const { t } = useTranslation();

    const [showPreview, setShowPreview] = useState<boolean>(false);

    const dialogToggle = useCallback(() => {
      setShowPreview(prevState => !prevState);
    }, []);

    return (
      <div className={className}>
        <Button
          dense
          type='default'
          view='outlined'
          color='default'
          onClick={dialogToggle}
          rightIcon='application'
          text={t('INVITE_FRIEND_PAGE_TEXT.preview.showPreviewButton')}
        />
        <Dialog
          type='default'
          view='raised'
          color='default'
          isOpen={showPreview}
          backdropOpacity={40}
          onClose={dialogToggle}
          title={t('INVITE_FRIEND_PAGE_TEXT.preview.title')}
          text={
            <div className='flex flex-col text-myrtle leading-6 tracking-[0.0275em]'>
              <p className='font-bold'>{t('INVITE_FRIEND_PAGE_TEXT.preview.firstMessageHeader')}</p>
              <PreviewComponent description={firstMessage} className='mb-4' />
              <p className='font-bold'>
                {t('INVITE_FRIEND_PAGE_TEXT.preview.secondMessageHeader')}
              </p>
              <PreviewComponent description={secondMessage} />
            </div>
          }
        />
      </div>
    );
  },
);
