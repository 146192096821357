import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';

type TMailingExamplesBannerProps = {
  /**
   * Отключает кнопку открытия drawer с примерами для рассылки
   * @param {boolean}
   */
  disableOpenDrawerButton: boolean;
  /**
   * Callback для открытия drawer с примерами шаблонов
   * @param {() => void}
   */
  openDrawer?: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const MailingExamplesBanner = memo(
  ({ openDrawer, disableOpenDrawerButton, className = '' }: TMailingExamplesBannerProps) => {
    const { t } = useTranslation();

    return (
      <div
        onClick={disableOpenDrawerButton ? undefined : openDrawer}
        className={`relative ${
          disableOpenDrawerButton ? 'cursor-not-allowed' : 'cursor-pointer'
        } flex flex-col justify-center h-fit ${className}`}>
        <p className='font-bold text-blackText text-h6Mobile leading-8 tracking-[0.0275em] mb-2'>
          {t('MAILING_PAGE_TEXT.mailingExamplesBanner.header')}
        </p>
        <div className='bg-cover bg-no-repeat bg-center bg-mailingBanner rounded-lg h-[10rem]' />
        <div className='absolute bottom-4 right-4 bg-white rounded-full w-[8.625rem]'>
          <Button
            fill
            dense
            type='action'
            color='default'
            view='outlined'
            disabled={disableOpenDrawerButton}
            text={t('MAILING_PAGE_TEXT.mailingExamplesBanner.button')}
            onClick={disableOpenDrawerButton ? undefined : openDrawer}
          />
        </div>
      </div>
    );
  },
);
