import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//
import logo from '@img/baseTemplate/beauty_bot.png';

interface IHomeLinkProps {
  /**
   * Ссылка в Link компонент
   * @param {string}
   */
  href: string;
  /**
   * Опциональный параметр указывает на то что ссылка находится в Drawer component
   * @param {boolean}
   * @default
   */
  isDrawer?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const HomeLink = memo(({ href, isDrawer, className = '' }: IHomeLinkProps) => {
  const { t } = useTranslation();
  return (
    <Link
      to={href}
      className={`flex items-center font-bold text-blackText text-h3_body hover:no-underline hover:text-blackText ${className}`}>
      {!isDrawer ? (
        <img
          src={logo}
          alt={t('BASE_TEMPLATE_PAGE_TEXT.header.homeLink.imgAlt')}
          title={t('BASE_TEMPLATE_PAGE_TEXT.header.homeLink.imgTitle')}
          height='40'
          width='27'
          className='ml-[0.375rem] mr-[1.125rem]'
        />
      ) : null}
      <span
        className={`${
          isDrawer ? '' : 'hidden'
        } font-bold text-blackText text-h1_header tracking-normal ${
          isDrawer ? 'block' : 'lg:block'
        }`}>
        {t('BASE_TEMPLATE_PAGE_TEXT.header.productName')}
      </span>
    </Link>
  );
});
