import { TGetOneReviewData } from '@api/types';
import { WHEN_SEND_VALUE_REVIEWS } from '@const/reviews';
import { getAfterEndToString, mapToNumberArray, mapToStringArray } from '@helpers/index';
import { TReviewData, TReviewInListData, TReviewInListMapData } from '@redux/reviews/models';

/**
 * Преобразует список данных отзывов в список отображаемых данных отзывов для хранилища Redux.
 * @param {TReviewInListData[]} responseData - Список данных отзывов.
 * @returns {TReviewInListMapData[]} - Список отображаемых данных отзывов для хранилища Redux.
 */
const mapReviewsDataToStore = (responseData: TReviewInListData[]): TReviewInListMapData[] => {
  return responseData.map(({ first_step, second_step, last_step }) => ({
    firstStep: first_step,
    secondStep: second_step,
    lastStep: last_step,
  }));
};

/**
 * Преобразует данне полученне из API в структуру TReviewData для хранилища Redux.
 * @param {TGetOneReviewData} data - Данные полученные из API.
 * @returns {TReviewData} - Преобразованные данные TReviewData для хранилища Redux.
 */
const mapResponseToStore = ({
  first_step,
  second_step,
  last_step,
}: TGetOneReviewData): TReviewData => {
  const {
    id,
    active,
    body,
    title,
    all_day_recs_notifications,
    after_end,
    attendance,
    services,
    staff,
    record_labels,
    client_category,
    WA_alerts,
    categories,
    blacklist,
    visits,
    visits_frequency,
    tg_alerts,
  } = first_step;
  const secondStep = second_step.map((item, index) => {
    return {
      whatsapp: item.body,
      status: item.active,
      id: index + 1,
    };
  });
  const lastStep = last_step;
  return {
    id: +id,
    status: active,
    templates: {
      firstStep: {
        whatsapp: body || '',
        title: title || '',
        oldTitle: title || `Цепочка отзывов #${id}`,
        status: active,
        timeType: all_day_recs_notifications?.day
          ? WHEN_SEND_VALUE_REVIEWS.ALL_DAY_RECS_NOTIFICATION
          : after_end
          ? WHEN_SEND_VALUE_REVIEWS.AFTER_END
          : WHEN_SEND_VALUE_REVIEWS.ALL_DAY_RECS_NOTIFICATION,
        whenSend: all_day_recs_notifications
          ? all_day_recs_notifications?.day
          : after_end
          ? getAfterEndToString(after_end)
          : '',
        whenTime: all_day_recs_notifications ? all_day_recs_notifications?.time : '',
        statuses: attendance ? mapToStringArray(attendance) : [],
        recordTag: record_labels ? mapToStringArray(record_labels) : [],
        services: services ? mapToStringArray(services) : [],
        staff: staff ? mapToStringArray(staff) : [],
        clientCategory: client_category || [],
        channelsPhoneList: WA_alerts || [],
        categories: categories ? mapToStringArray(categories) : [],
        blacklist: blacklist || [],
        quantityFrom: visits?.length ? String(visits[0]) : '',
        quantityTo: visits?.length ? String(visits[1]) : '',
        visits: visits_frequency ? String(visits_frequency) : '',
        tgAlerts: tg_alerts ? mapToStringArray(tg_alerts) : [],
        id: 1,
      },
      secondStep,
      lastStep: {
        id: secondStep.length + 2,
        status: lastStep.active,
        positive: lastStep.body,
        negative: lastStep.fail_text,
      },
    },
  };
};

/**
 * Преобразует данные отзыва для отправки на сервер.
 * @param {TReviewData} data - Данные отзыва.
 * @returns {TGetOneReviewData} - Преобразованные данные для отправки на сервер.
 */
const mapReviewDataToSend = ({
  templates: { firstStep, secondStep, lastStep },
  status,
}: TReviewData): TGetOneReviewData => {
  const visits =
    firstStep.quantityFrom && firstStep.quantityTo
      ? [+firstStep.quantityFrom, +firstStep.quantityTo]
      : [];

  return {
    first_step: {
      id: String(firstStep.id),
      body: firstStep.whatsapp,
      active: status,
      title: firstStep.title || '',
      WA_alerts: firstStep.channelsPhoneList,
      services: mapToNumberArray(firstStep.services),
      staff: mapToNumberArray(firstStep.staff),
      attendance: mapToNumberArray(firstStep.statuses),
      record_labels: mapToNumberArray(firstStep.recordTag),
      categories: mapToNumberArray(firstStep.categories),
      client_category: firstStep.clientCategory,
      blacklist: firstStep.blacklist,
      visits,
      all_day_recs_notifications:
        firstStep.timeType === WHEN_SEND_VALUE_REVIEWS.ALL_DAY_RECS_NOTIFICATION
          ? { day: firstStep.whenSend, time: firstStep.whenTime }
          : null,
      after_end:
        firstStep.timeType === WHEN_SEND_VALUE_REVIEWS.AFTER_END ? +firstStep.whenSend : null,
      visits_frequency: firstStep.visits ? Number(firstStep.visits) : null,
      tg_alerts: mapToNumberArray(firstStep.tgAlerts),
    },
    second_step: secondStep.map(item => {
      return {
        active: item.status,
        body: item.whatsapp,
      };
    }),
    last_step: {
      active: lastStep.status,
      body: lastStep.positive,
      fail_text: lastStep.negative,
    },
  };
};

export const reviewsMappers = { mapReviewsDataToStore, mapResponseToStore, mapReviewDataToSend };
