import React from 'react';
import { useTranslation } from 'react-i18next';
//
import { Dialog } from '@uikit/Dialog';
import { Button } from '@uikit/Button';

type TClearMessageQueryModalProps = {
  /**
   * Состояние компонента Modal открыт/закрыт
   * @param {boolean}
   */
  isOpen: boolean;
  /**
   * Callback для закрытия модалки
   * @param {() => void}
   */
  closeModalHandler: () => void;
  /**
   * Обрабатывает нажатие на кнопку Оставить сообщения
   * @param {() => void}
   */
  getQRWithoutClearQueryHandler: () => void;
  /**
   * Callback для очистки очереди сообщений
   * @param {() => void}
   */
  clearQueryHandler: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const ClearMessageQueryModal = ({
  isOpen,
  clearQueryHandler,
  closeModalHandler,
  getQRWithoutClearQueryHandler,
  className = '',
}: TClearMessageQueryModalProps) => {
  const { t } = useTranslation();

  return (
    <div className={` ${className}`}>
      {isOpen ? (
        <Dialog
          type='default'
          view='raised'
          color='default'
          title={t('CONNECTION_PAGE_TEXT.clearMessageQueryModal.title')}
          text={<span>{t('CONNECTION_PAGE_TEXT.clearMessageQueryModal.description')}</span>}
          backdropOpacity={40}
          rightButton={
            <div className='flex flex-wrap'>
              <Button
                dense
                type='action'
                view='outlined'
                color='default'
                onClick={getQRWithoutClearQueryHandler}
                text={t('CONNECTION_PAGE_TEXT.clearMessageQueryModal.cancelButton')}
                className='mt-2 mr-6'
              />
              <Button
                dense
                type='action'
                view='filled'
                color='danger'
                onClick={clearQueryHandler}
                text={t('CONNECTION_PAGE_TEXT.clearMessageQueryModal.deleteButton')}
                className='mt-2'
              />
            </div>
          }
          isOpen={isOpen}
          onClose={closeModalHandler}
          className='!max-w-[42.5rem] w-full'
        />
      ) : null}
    </div>
  );
};
