import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { simpleSort } from '@helpers/index';
import { TMessages } from '@redux/connection/types';
import { IInitialState, initialState } from '@redux/connection/initialTableState';

export const connectionSlice = createSlice({
  name: 'connectionTable',
  initialState,
  reducers: {
    setTableDataCP(state: IInitialState, action: PayloadAction<TMessages[]>) {
      state.data = action.payload;
      state.filteredData = action.payload;
      state.mobileData = action.payload.slice(0, state.pageSize);
      state.totalMobilePages = Math.ceil(action.payload.length / state.pageSize);
    },
    setTableSearchStringCP(state: IInitialState, action: PayloadAction<{ value: string }>) {
      const { value: searchString } = action.payload;

      if (!action.payload.value) {
        state.filteredData = state.data;
      } else {
        state.filteredData = state.data.filter(item => item.phone.includes(searchString));
      }
      state.searchString = action.payload.value;
    },
    setSearchStringCP(state: IInitialState, action: PayloadAction<{ value: string }>) {
      state.searchString = action.payload.value;
      if (!action.payload.value) {
        state.filteredData = state.data;
      }
    },
    setSortTypeDataCP(
      state: IInitialState,
      action: PayloadAction<{ columnName: string; order: string }>,
    ) {
      const { columnName, order } = action.payload;
      const key = columnName as unknown as keyof TMessages;

      state.filteredData = simpleSort<TMessages>(state.filteredData, key, order);
    },
    showMoreCP(state: IInitialState) {
      const { pageSize, currentMobilePage, data } = state;
      const startOfPageSlice = pageSize * currentMobilePage;
      const endOfPageSlice = startOfPageSlice + pageSize;
      const nextSlice = data.slice(startOfPageSlice, endOfPageSlice);
      state.mobileData = [...state.mobileData, ...nextSlice];
      state.currentMobilePage += 1;
    },
  },
});

export const connectionTableReducer = connectionSlice.reducer;
export const {
  showMoreCP,
  setTableDataCP,
  setSearchStringCP,
  setSortTypeDataCP,
  setTableSearchStringCP,
} = connectionSlice.actions;
