import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { TLeadsData } from '@api/types';
import { Table } from '@uikit/Table/Table';
import { LeadCard } from '@blocks/leadCard';
import { useWindowWidth } from '@hooks/useWindowWidth';

type TLeadsTableProps = {
  /**
   * Данные для формирования таблицы
   * @param {TLeadsData[]}
   */
  tableData: TLeadsData[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const LeadsTable = memo(({ tableData, className = '' }: TLeadsTableProps) => {
  const { t } = useTranslation();

  const width = useWindowWidth();

  const Head = useMemo(
    () => [
      { id: 'phone', label: t('PARTNER_PAGE_TEXT.leadsTable.phone'), noSort: true },
      { id: 'branchId', label: t('PARTNER_PAGE_TEXT.leadsTable.branchId'), noSort: true },
      { id: 'leadName', label: t('PARTNER_PAGE_TEXT.leadsTable.leadName'), noSort: true },
      { id: 'comment', label: t('PARTNER_PAGE_TEXT.leadsTable.comment'), noSort: true },
    ],
    [t],
  );

  const getTableData = useCallback((data: TLeadsData[]) => {
    const emptyData = [
      {
        phone: ' ',
        branchId: ' ',
        leadName: ' ',
        comment: ' ',
      },
    ];

    if (!data.length) {
      return emptyData;
    }

    return data.map(({ phone, branchId, leadName, comment }) => {
      return {
        phone,
        branchId,
        leadName,
        comment: <span className='break-all'>{comment}</span>,
      };
    });
  }, []);

  const mobileCards = useMemo(
    () =>
      tableData.map(({ phone, comment, leadName, branchId }, index) => {
        const key = `${phone}${leadName}${comment}${index}`;

        return (
          <LeadCard
            key={key}
            phone={phone}
            comment={comment}
            branchId={branchId}
            branchName={leadName}
            className='mb-4'
          />
        );
      }),
    [tableData],
  );

  return (
    <div className={className}>
      <h3 className='font-medium text-black text-h3Mobile leading-8 mb-4 tracking-[0.004em]'>
        {t('PARTNER_PAGE_TEXT.leadsTable.header')}
      </h3>
      {width > 640 ? (
        <Table
          name={Head}
          data={getTableData(tableData)}
          position={['left', 'left', 'left', 'left']}
          type='def'
          view='flat'
          color='default'
          pages
          labelRowsPerPage={t('PARTNER_PAGE_TEXT.leadsTable.pagination')}
          rowsPerPageOptions={[5, 8, 20]}
          defRows={8}
          checkboxes={false}
          paperClassName='no_shadow'
          style={{ boxShadow: 'none', marginLeft: '6px' }}
        />
      ) : null}
      {width < 641 ? <div className='overflow-y-auto'>{mobileCards}</div> : null}
    </div>
  );
});

LeadsTable.displayName = 'LeadsTable';
