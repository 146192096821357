import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Chips } from '@uikit/Chips';
import { Table } from '@uikit/Table/Table';
import { useAppDispatch } from '@store/store';
import { PARTNERS_LIST_STATUSES } from '@const/partners';
import { TPartnersFullTableData } from '@redux/partnersFullTable/models';
import { setSortTypeDataPFT } from '@redux/partnersFullTable/partnerFullTableSlice';
import { TAppLanguage } from '@models/index';

interface IPartnersFullTableProps {
  /**
   * Данные таблицы
   * @param {TPartnersFullTableData[]}
   */
  data: TPartnersFullTableData[];
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const PartnersFullTable = memo(
  ({ appLanguage, data, className = '' }: IPartnersFullTableProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const getStatusChips = useCallback(
      (status: string) => (
        <Chips
          round
          noClick
          type='dense'
          icon={
            status === PARTNERS_LIST_STATUSES[appLanguage].PAID
              ? 'small-tick'
              : status === PARTNERS_LIST_STATUSES[appLanguage].ON_TEST
              ? 'time'
              : 'disable'
          }
          color={
            status === PARTNERS_LIST_STATUSES[appLanguage].PAID
              ? 'success'
              : status === PARTNERS_LIST_STATUSES[appLanguage].ON_TEST
              ? 'warning'
              : 'danger'
          }
          tag={status}
        />
      ),
      [appLanguage],
    );

    const Head = useMemo(
      () => [
        { id: 'accId', label: t('PARTNER_PAGE_TEXT.partnersFullTable.accId') },
        { id: 'filialName', label: t('PARTNER_PAGE_TEXT.partnersFullTable.filialName') },
        { id: 'connectDate', label: t('PARTNER_PAGE_TEXT.partnersFullTable.connectDate') },
        { id: 'firstPayDate', label: t('PARTNER_PAGE_TEXT.partnersFullTable.firstPayDate') },
        { id: 'status', label: t('PARTNER_PAGE_TEXT.partnersFullTable.status'), noSort: true },
      ],
      [t],
    );

    const getTableData = useCallback(
      (tableData: TPartnersFullTableData[]) => {
        const emptyData = [
          {
            accId: ' ',
            filialName: ' ',
            connectDate: ' ',
            firstPayDate: ' ',
            status: ' ',
          },
        ];

        if (!tableData.length) {
          return emptyData;
        }

        return tableData.map(item => {
          return {
            accId: item.accId,
            filialName: item.filialName,
            connectDate: item.connectDate,
            firstPayDate: item.firstPayDate,
            status: getStatusChips(item.status),
          };
        });
      },
      [getStatusChips],
    );

    // функция сортировки, получает название столбца отправляет в редакс
    const setSortTypeHandler = useCallback(
      (columnName: string, order: string) => {
        dispatch(setSortTypeDataPFT({ columnName, order }));
      },
      [dispatch],
    );

    return (
      <div className={className}>
        <h3 className='font-medium text-black text-h3Mobile leading-8 mb-4 tracking-[0.004em]'>
          {t('PARTNER_PAGE_TEXT.partnersFullTable.header')}
        </h3>
        <Table
          name={Head}
          data={getTableData(data)}
          position={['left', 'left', 'left', 'left', 'left']}
          type='def'
          view='flat'
          color='default'
          pages
          labelRowsPerPage={t('PARTNER_PAGE_TEXT.partnersFullTable.pagination')}
          rowsPerPageOptions={[5, 8, 20]}
          defRows={8}
          checkboxes={false}
          paperClassName='no_shadow'
          onSortCallback={setSortTypeHandler}
          style={{ boxShadow: 'none', marginLeft: '6px' }}
        />
      </div>
    );
  },
);

PartnersFullTable.displayName = 'PartnersFullTable';
