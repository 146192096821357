import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { H1 } from '@atoms/h1';
import { useAppDispatch } from '@store/store';
import { FilialCard } from '@blocks/filialCard';
import { TSetChosenFilialId } from '@shared/types';
import { TFilialData } from '@redux/accounts/models';
import { FilialListItem } from '@blocks/filialListItem';
import { setSelectedFilial } from '@redux/accounts/accountsSlice';

export interface IAccountsProps {
  /**
   * Массив со списком филиалов
   * @param {TFilialData[]}
   */
  data: TFilialData[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const AccountsTemplate = memo(({ data, className = '' }: IAccountsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // Записывает id выбранного филиала и тип его аккаунта в LS для последующего использования в запросах
  const setChosenFilialId: TSetChosenFilialId = useCallback(
    ({ accId, branchId, rights, filialName, isSendOutAccount }) =>
      () => {
        dispatch(setSelectedFilial({ accId, branchId, filialName, isSendOutAccount, rights }));

        // amplitudeSetUserId(branchId);
      },
    [dispatch],
  );

  const renderWidth = useMemo(
    () =>
      data?.map((filialData, index) => {
        const key = `${filialData.accId}${index}`;
        return (
          <FilialListItem
            key={key}
            filialData={filialData}
            setChosenFilialId={setChosenFilialId({
              accId: filialData.accId,
              rights: filialData.rights,
              branchId: filialData.branchId,
              filialName: filialData.address,
              isSendOutAccount: filialData.sendOutAccount,
            })}
          />
        );
      }),
    [data, setChosenFilialId],
  );

  const render = useMemo(
    () =>
      data.map((filialData, index) => {
        const key = `${filialData.accId}${index}`;
        return (
          <FilialCard
            key={key}
            filialData={filialData}
            setChosenFilialId={setChosenFilialId({
              accId: filialData.accId,
              rights: filialData.rights,
              branchId: filialData.branchId,
              filialName: filialData.address,
              isSendOutAccount: filialData.sendOutAccount,
            })}
            className={index === data.length - 1 ? '' : 'mb-4'}
          />
        );
      }),
    [data, setChosenFilialId],
  );

  const tableHeadElementData = [
    {
      classNameText: 'font-medium px-2 w-[33%]',
      text: t('ACCOUNTS_PAGE_TEXT.tableHead.filial'),
    },
    {
      classNameText: 'font-medium px-2 w-[15%]',
      text: t('ACCOUNTS_PAGE_TEXT.tableHead.status'),
    },
    {
      classNameText: 'font-medium px-2 w-[9%]',
      text: t('ACCOUNTS_PAGE_TEXT.tableHead.templates'),
    },
    {
      classNameText: 'font-medium px-2 w-[15%]',
      text: t('ACCOUNTS_PAGE_TEXT.tableHead.startedMailing'),
    },
    {
      classNameText: 'font-medium px-2 w-[19%]',
      text: t('ACCOUNTS_PAGE_TEXT.tableHead.payment'),
    },
    {
      classNameText: 'font-medium px-2 w-[9%] text-center',
      text: t('ACCOUNTS_PAGE_TEXT.tableHead.settings'),
    },
  ];

  const tableHeadElementsToRender = tableHeadElementData.map(({ classNameText, text }) => (
    <th key={text} className={classNameText}>
      {text}
    </th>
  ));

  return (
    <div className={`px-4 lg:px-[1.5rem] mb-6 ${className}`}>
      <H1 className='mb-6'>{t('ACCOUNTS_PAGE_TEXT.header')}</H1>
      <div className='hidden lg:block'>
        <table className='w-full'>
          <thead>
            <tr className='border-b-2 border-stormGray text-left text-grayText text-[0.875rem] h-10 w-full'>
              {tableHeadElementsToRender}
            </tr>
          </thead>
          <tbody>{renderWidth}</tbody>
        </table>
      </div>
      <div className='flex flex-col lg:hidden'>{render}</div>
    </div>
  );
});

AccountsTemplate.displayName = 'AccountsTemplate';
