import React, { FunctionComponent, memo } from 'react';

type TChatTableMessageTagProps = {
  /**
   * Src для отображения картинки
   * @param {string}
   */
  iconExampleSrc?: string;
  /**
   * Компонент иконки отображающийся перед основным блоком
   * @param {FunctionComponent}
   */
  IconComponent?: FunctionComponent;
  /**
   * Изменяет внешний вид и поведение компонента
   * @param {boolean}
   */
  isMobile: boolean;
  /**
   * Текст в тэг
   * @param {string}
   */
  messageTagText: string;
};

export const ChatTableMessageTag = memo(
  ({ isMobile, IconComponent, iconExampleSrc, messageTagText }: TChatTableMessageTagProps) => {
    return (
      <div className={`flex ${isMobile ? 'min-h-[3rem] w-full' : 'h-[3rem]'} overflow-hidden`}>
        {!isMobile && IconComponent ? <IconComponent /> : null}
        <div
          className={`bg-gray1 border border-quartz rounded-lg flex ${
            isMobile ? 'items-start w-full' : 'items-center'
          } px-4 py-[0.45rem] ${!isMobile && IconComponent ? 'ml-2' : ''}`}>
          {iconExampleSrc ? <img src={iconExampleSrc} alt='icon' className='mr-2' /> : null}
          <p className='font-medium leading-6 text-blackText tracking-[0.022em] break-all whitespace-pre overflow-hidden'>
            {messageTagText}
          </p>
        </div>
      </div>
    );
  },
);

ChatTableMessageTag.displayName = 'ChatTableMessageTag';
