import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
//
import { VisitQuantity } from '@blocks/visitQuantity';
import { getAccountsData } from '@redux/accounts/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { getTemplatePageData } from '@redux/template/selectors';
import { FormBlackListInput } from '@components/formBlackListInput';
import { InputWithDropdownFromEP } from '@blocks/inputWithDropdownFromEP';
import { getInviteFriendsActionData } from '@redux/inviteFriendsAction/selectors';
import {
  TActionData,
  TIFBalloonsKeyNames,
  ACTION_DATA_KEY_NAMES,
} from '@redux/inviteFriendsAction/type';
import {
  TDeleteBalloonSetTP,
  TAddBalloonOnBlurHandler,
  TVisitQuantityOnChangeHandler,
} from '@shared/types';
import {
  addBalloonIF,
  deleteBalloonIF,
  setVisitQuantityIF,
  setNewBalloonValueIF,
} from '@redux/inviteFriendsAction/inviteFriendSlice';

type TInviteFriendsSendingConditionalsProps = {
  /**
   * Данные шаблона акции
   * @param {TActionData}
   */
  actionData: TActionData;
  /**
   * Флаг успешного получения данных
   * @param {boolean}
   */
  isFetchingSuccess: boolean;
  /**
   * Опциональны параметр доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const InviteFriendsSendingConditionals = memo(
  ({
    actionData,
    isFetchingSuccess,
    isEditTemplatesAccess,
    className = '',
  }: TInviteFriendsSendingConditionalsProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { visits, staff, services, blacklist, categories, clientCategory } = actionData;

    const [quantityFrom, quantityTo] = visits;

    const {
      staffNewValue,
      servicesNewValue,
      blacklistNewValue,
      categoriesNewValue,
      clientCategoryNewValue,
    } = useAppSelector(getInviteFriendsActionData);

    const { selectedFilial } = useAppSelector(getAccountsData);

    const { categoriesDataList } = useAppSelector(getTemplatePageData);

    // Обрабатывает изменение newBalloonValue
    const newBlacklistBalloonValueHandler = useCallback(
      (phone: string) => {
        dispatch(setNewBalloonValueIF({ value: phone, name: ACTION_DATA_KEY_NAMES.BLACKLIST }));
      },
      [dispatch],
    );

    // Удаляет "шарик" по его значению
    const deleteBalloon: TDeleteBalloonSetTP = useCallback(
      (value, keyName) => () => {
        const tKeyName = keyName as TIFBalloonsKeyNames;
        dispatch(deleteBalloonIF({ keyName: tKeyName, value }));
      },
      [dispatch],
    );

    // Добавляет новый по событию Blur шарик и очищает инпут
    const addBlacklistBalloonOnBlurHandlerEvent = useCallback(
      (phone: string) => {
        if (phone.length > 10) {
          dispatch(addBalloonIF({ keyName: ACTION_DATA_KEY_NAMES.BLACKLIST, value: phone }));
        }
      },
      [dispatch],
    );

    // Добавляет новый по событию Blur шарик и очищает инпут
    const addBalloonOnBlurHandler: TAddBalloonOnBlurHandler = useCallback(
      (name, value) => {
        const keyName = name as TIFBalloonsKeyNames;
        dispatch(addBalloonIF({ keyName, value }));
      },
      [dispatch],
    );

    // Обрабатывает изменения количества визитов
    const visitQuantityOnChangeHandler: TVisitQuantityOnChangeHandler = useCallback(
      ({ newQuantityFromValue, newQuantityToValue }) => {
        dispatch(
          setVisitQuantityIF({
            quantityFrom: newQuantityFromValue,
            quantityTo: newQuantityToValue,
          }),
        );
      },
      [dispatch],
    );

    return (
      <div className={className}>
        <p className='font-medium text-[1.25rem] text-black tracking-[0.004em] mb-4'>
          {t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.sendingConditionals')}
        </p>
        <InputWithDropdownFromEP
          addedValues={services}
          newValue={servicesNewValue}
          labelTextVariant='serviceIF'
          deleteBalloon={deleteBalloon}
          disabled={!isEditTemplatesAccess}
          isFetchingSuccess={isFetchingSuccess}
          categoriesDataList={categoriesDataList}
          inputId={ACTION_DATA_KEY_NAMES.SERVICES}
          addBalloonOnBlur={addBalloonOnBlurHandler}
          textColor='text-grayText tracking-[0.0275em]'
          selectedFilialAccId={selectedFilial?.accId || ''}
          className='mb-8'
        />
        <InputWithDropdownFromEP
          addedValues={categories}
          newValue={categoriesNewValue}
          deleteBalloon={deleteBalloon}
          labelTextVariant='categoriesIF'
          disabled={!isEditTemplatesAccess}
          isFetchingSuccess={isFetchingSuccess}
          categoriesDataList={categoriesDataList}
          addBalloonOnBlur={addBalloonOnBlurHandler}
          inputId={ACTION_DATA_KEY_NAMES.CATEGORIES}
          textColor='text-grayText tracking-[0.0275em]'
          selectedFilialAccId={selectedFilial?.accId || ''}
          className='mb-8'
        />
        <InputWithDropdownFromEP
          addedValues={staff}
          newValue={staffNewValue}
          labelTextVariant='staffIF'
          deleteBalloon={deleteBalloon}
          disabled={!isEditTemplatesAccess}
          isFetchingSuccess={isFetchingSuccess}
          inputId={ACTION_DATA_KEY_NAMES.STAFF}
          categoriesDataList={categoriesDataList}
          addBalloonOnBlur={addBalloonOnBlurHandler}
          textColor='text-grayText tracking-[0.0275em]'
          selectedFilialAccId={selectedFilial?.accId || ''}
          className='mb-8'
        />
        <InputWithDropdownFromEP
          addedValues={clientCategory}
          deleteBalloon={deleteBalloon}
          disabled={!isEditTemplatesAccess}
          newValue={clientCategoryNewValue}
          labelTextVariant='clientCategoryIF'
          isFetchingSuccess={isFetchingSuccess}
          categoriesDataList={categoriesDataList}
          addBalloonOnBlur={addBalloonOnBlurHandler}
          textColor='text-grayText tracking-[0.0275em]'
          inputId={ACTION_DATA_KEY_NAMES.CLIENT_CATEGORY}
          selectedFilialAccId={selectedFilial?.accId || ''}
          className='mb-8'
        />
        <VisitQuantity
          quantityFrom={quantityFrom}
          quantityTo={quantityTo}
          disabled={!isEditTemplatesAccess}
          textColor='text-grayText tracking-[0.0275em]'
          onChangeHandler={visitQuantityOnChangeHandler}
          className='mb-6'
        />
        <FormBlackListInput
          isShowInfoIcon
          value={blacklist}
          variant='blacklistIF'
          newValue={blacklistNewValue}
          deleteBalloon={deleteBalloon}
          disabled={!isEditTemplatesAccess}
          inputId={ACTION_DATA_KEY_NAMES.BLACKLIST}
          textColor='text-grayText tracking-[0.004em]'
          setNewValue={newBlacklistBalloonValueHandler}
          addBalloonOnBlur={addBlacklistBalloonOnBlurHandlerEvent}
          placeholder={t('INVITE_FRIEND_PAGE_TEXT.actionTemplate.blacklistPlaceholder')}
          textSize='text-h3Mobile !text-black !mb-4'
          className='mt-10'
        />
      </div>
    );
  },
);

InviteFriendsSendingConditionals.displayName = 'InviteFriendsSendingConditionals';
