import { useState, useEffect } from 'react';

type TProps = {
  initialMinute: number;
  initialSeconds: number;
  isStart: boolean;
};

type TReturnData = {
  minutes: number;
  seconds: number;
  isCompleted: boolean;
  reset: () => void;
};

export const useTimer = ({ initialMinute, initialSeconds, isStart }: TProps): TReturnData => {
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  const reset = () => {
    const min = initialMinute;
    const sec = initialSeconds;

    setMinutes(min);
    setSeconds(sec);
  };

  useEffect(() => {
    if (!isStart) return;

    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return {
    reset,
    minutes,
    seconds,
    isCompleted: seconds === 0 && minutes === 0,
  };
};
