import { createAsyncThunk } from '@reduxjs/toolkit';
import { partnerProgramApi } from '@api/partnerProgramApi';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { TConsultantFullTableStoreData } from '@redux/consultantFullTable/models';
import { TRejectValue, REJECT_RESPONSE_KEY, GET_FULL_TABLE_RESPONSE_KEY_NAME } from '@api/types';
import { mapGetConsultantFullTableDataToStore } from '@redux/consultantFullTable/consultantFullTableMappers';

/**
 * Функция для получения данных полной таблицы консультантов.
 *
 * @param {string} data - Данные для запроса.
 * @param {Object} dispatch - Функция для отправки действий в Redux.
 * @param {Function} rejectWithValue - Функция для обработки ошибки.
 * @return {TConsultantFullTableStoreData | void} - Данные таблицы консультантов.
 * @throws {Error} - Ошибка с текстом статуса, если получен некорректный ответ.
 */
const getConsultantFullTableData = createAsyncThunk<
  TConsultantFullTableStoreData | void,
  string,
  { rejectValue: TRejectValue }
>('consultantFullTableSlice/getConsultantFullTableData', (data, { dispatch, rejectWithValue }) => {
  return partnerProgramApi
    .getConsultantFullTableData(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(response.data.status);
      }
      if (GET_FULL_TABLE_RESPONSE_KEY_NAME.PROMO_CODE in response.data) {
        return mapGetConsultantFullTableDataToStore(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getConsultantFullTableData(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

export { getConsultantFullTableData };
