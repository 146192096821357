import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Chips } from '@uikit/Chips';
import { TPaymentsType } from '@api/types';
import { TPaymentData } from '@redux/pay/types';

type TPayHistoryTableCardProps = {
  /**
   * Данные для таблицы
   * @param {TPaymentData}
   */
  filteredData: TPaymentData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const PayHistoryTableCard = memo(
  ({ filteredData, className = '' }: TPayHistoryTableCardProps) => {
    const { t } = useTranslation();

    const { date, channels, sum, paymentType, period } = filteredData;

    const paymentChipsText: Record<TPaymentsType, string> = useMemo(
      () => ({
        auto_payment: t('PAY_PAGE_TEXT.paymentTypes.autoPayment'),
        bonus: t('PAY_PAGE_TEXT.paymentTypes.bonus'),
        '-': t('PAY_PAGE_TEXT.paymentTypes.-'),
        link_paid: t('PAY_PAGE_TEXT.paymentTypes.linkPaid'),
        bill: t('PAY_PAGE_TEXT.paymentTypes.bill'),
        bonus_days: t('PAY_PAGE_TEXT.paymentTypes.bonusDays'),
      }),
      [t],
    );

    const getPaymentTypeChips = useCallback(
      (type: TPaymentsType) => {
        const chipsColor = type === 'bonus' || type === 'bonus_days' ? 'success' : 'default';

        return <Chips round noClick type='dense' color={chipsColor} tag={paymentChipsText[type]} />;
      },
      [paymentChipsText],
    );

    const cardItemStyle = 'border-b border-quartz1 flex flex-col py-2';

    const labelStyle = 'text-stormGray text-tooltips leading-5 tracking-[0.018em] mb-0.5';

    const cardDataStyle = 'text-black text-tooltips leading-5 tracking-[0.018em]';

    return (
      <div className={className}>
        <div className='border border-quartz rounded-lg px-4 py-2'>
          <div className={cardItemStyle}>
            <span className={labelStyle}>{t('PAY_PAGE_TEXT.tableDate')}</span>
            <span className={cardDataStyle}>{date}</span>
          </div>
          <div className={cardItemStyle}>
            <span className={labelStyle}>{t('PAY_PAGE_TEXT.tableChannels')}</span>
            <div className='flex flex-col'>
              {channels.map(channel => (
                <span key={channel} className={cardDataStyle}>
                  {channel}
                </span>
              ))}
            </div>
          </div>
          <div className={cardItemStyle}>
            <span className={labelStyle}>{t('PAY_PAGE_TEXT.tablePeriod')}</span>
            <span className={cardDataStyle}>{period}</span>
          </div>
          <div className={cardItemStyle}>
            <span className={labelStyle}>{t('PAY_PAGE_TEXT.tablePaymentType')}</span>
            <span className={cardDataStyle}>{getPaymentTypeChips(paymentType)}</span>
          </div>
          <div className='flex flex-col py-2'>
            <span className={labelStyle}>{t('PAY_PAGE_TEXT.tableSum')}</span>
            <span className={cardDataStyle}>{sum}</span>
          </div>
        </div>
      </div>
    );
  },
);

PayHistoryTableCard.displayName = 'PayHistoryTableCard';
