import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { TSwitchStep } from '@shared/types';
import { ToggleSwitch } from '@blocks/toggleSwitch';
import { TReviewFirstStepInListData } from '@api/types';

interface IReviewStep1CardProps {
  /**
   * ID шага
   * @param {number}
   */
  stepId: number;
  /**
   * ID шага
   * @param {string}
   */
  templateCardId: string;
  /**
   *  Callback для переключения активности шага
   *  @param {TSwitchStep}
   */
  switchStep: TSwitchStep;
  /**
   * Данные карточки
   * @param {TReviewFirstStepInListData}
   */
  data: TReviewFirstStepInListData;
  /**
   * Флаг доступа к редактированию шаблона
   * @param {boolean}
   */
  isEditTemplatesAccess?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const ReviewStep1Card = memo(
  ({
    data,
    stepId,
    switchStep,
    templateCardId,
    isEditTemplatesAccess,
    className = '',
  }: IReviewStep1CardProps) => {
    const { t } = useTranslation();
    const { body, active: status, time } = data;

    return (
      <div
        className={`bg-white border border-lightGray flex flex-col grow shrink font-inter text-[1.5rem] text-blackText p-4 rounded-lg ${className}`}>
        <div className='flex items-center mb-5'>
          {isEditTemplatesAccess && (
            <ToggleSwitch
              name='status'
              checked={status}
              onChangeHandler={() =>
                switchStep({ status: !status, stepId: String(stepId), templateId: templateCardId })
              }
              className='mr-2 z-[1]'
            />
          )}
          <p className='font-bold text-darkGray m-0'>{t('REVIEW_PAGE_TEXT.step', { stepId })}</p>
        </div>
        <p className='text-body_text h-12 overflow-hidden mb-3'>{body}</p>
        <p className='bg-dot bg-left-0.625rem bg-no-repeat pl-[2.25rem] text-body_text text-grayText'>
          {time}
        </p>
      </div>
    );
  },
);

ReviewStep1Card.displayName = 'ReviewStep1Card';
