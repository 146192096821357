import React, { forwardRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import Component from './Typography';

const propTypes = {
  /**
   `The type of the component text.
   * Variants: `body` `button` `overline` `caption` `h1` `h2` `h3` `h4` `h5` `h6`
   * Default value (if undefined): `body` `
   */
  type: PropTypes.oneOf([
    "body",
    "body_bold",
    "button",
    "overline",
    "caption",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6"
  ]),
  /**
   ` The step of the component color from "0" to "100".
   * Default value (if undefined): `100` `
   */
  colorStep: PropTypes.oneOf([0, 5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]),
  /**
   ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    "default",
    "primary",
    "warning",
    "danger",
    "success",
    "primary_alt",
    "warning_alt",
    "danger_alt",
    "success_alt"
  ]),
  /**
   * Dense size
   */
  dense: PropTypes.bool
};

type TComponentPropsType = InferProps<typeof propTypes>;

interface IComponentProps extends TComponentPropsType {
  [key: string]: any;
}

export const Typography: React.FC<IComponentProps> = forwardRef((props, ref) => <Component {...props} ref={ref} />);