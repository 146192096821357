import React, { memo, useCallback, useEffect } from 'react';
//
import { NoAccess } from '@components/noAccess';
import { ErrorMessage } from '@blocks/errorMessage';
import { ConnectionTemplate } from '@templates/connection';
import { getAccountsData } from '@redux/accounts/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { TELEGRAM_BOT_CONNECTION_STEPS } from '@const/connect';
import { getConnectionPageData } from '@redux/connection/selectors';
import {
  dropTGInstanceThunk,
  getConnectionPageStatus,
  getConnectionTGPageStatusThunk,
} from '@redux/connection/connectionThunks';

const ConnectionPage = memo(() => {
  const dispatch = useAppDispatch();

  const {
    qrData,
    waData,
    tgData,
    isError,
    isLoading,
    isSuccess,
    isRefresh,
    uniqueCode,
    phoneNumber,
    isFetchingQR,
    isGetQRError,
    errorMessage,
    isRestartingBot,
    isBotRestartError,
    isCleanQueryError,
    isBotStoppingError,
    isBotStoppingQuery,
    phoneConnectionStep,
    isFetchingCleanQuery,
    phoneConnectionStatus,
    isLoadingStatusRefresh,
    isAccessToConnectionDenied,
  } = useAppSelector(getConnectionPageData);

  const { selectedFilial, isError: isAccountsError } = useAppSelector(getAccountsData);

  const selectedFilialAccId = selectedFilial?.accId || '';

  useEffect(() => {
    if (selectedFilialAccId) {
      dispatch(getConnectionPageStatus());
    }
  }, [dispatch, selectedFilialAccId]);

  // useEffect(() => {
  //   if (selectedFilialAccId) {
  //     dispatch(getConnectionTGPageStatusThunk());
  //   }
  // }, [dispatch, selectedFilialAccId]);

  useEffect(() => {
    if (selectedFilialAccId && isRefresh) {
      setTimeout(() => dispatch(getConnectionPageStatus()), 1000);
    }
  }, [dispatch, isRefresh, selectedFilialAccId]);

  // useEffect(() => {
  //   let timeout: NodeJS.Timeout;
  //
  //   if (selectedFilialAccId && isLoadingStatusRefresh) {
  //     timeout = setTimeout(() => dispatch(getConnectionTGPageStatusThunk()), 30000);
  //   }
  //
  //   return () => clearTimeout(timeout);
  // }, [dispatch, isLoadingStatusRefresh, selectedFilialAccId]);

  useEffect(() => {
    const instanceDeleteHandler = () => {
      dispatch(dropTGInstanceThunk());
    };

    if (tgData.isInputError && tgData.stepNumber === TELEGRAM_BOT_CONNECTION_STEPS.INPUT_ERROR) {
      window.onbeforeunload = instanceDeleteHandler;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [tgData.isInputError, tgData.stepNumber, dispatch]);

  const getConnectionStatus = useCallback(() => {
    dispatch(getConnectionPageStatus());
    dispatch(getConnectionTGPageStatusThunk());
  }, [dispatch]);

  const isLoadingState = (isLoading || !isSuccess) && !isError;

  const isShowNoAccess = isAccessToConnectionDenied && !isLoading;

  const isShowError = !isAccessToConnectionDenied && (isError || isAccountsError);

  const isShowConnectionTemplate = !isAccessToConnectionDenied && !(isError || isAccountsError);

  return (
    <>
      {isShowNoAccess ? <NoAccess /> : null}
      {isShowError ? <ErrorMessage /> : null}
      {isShowConnectionTemplate ? (
        <ConnectionTemplate
          qrData={qrData}
          tgData={tgData}
          waData={waData}
          uniqueCode={uniqueCode}
          phoneNumber={phoneNumber}
          isLoading={isLoadingState}
          errorMessage={errorMessage}
          isFetchingQR={isFetchingQR}
          isGetQRError={isGetQRError}
          isRestartingBot={isRestartingBot}
          isBotRestartError={isBotRestartError}
          isCleanQueryError={isCleanQueryError}
          isBotStoppingQuery={isBotStoppingQuery}
          isBotStoppingError={isBotStoppingError}
          phoneConnectionStep={phoneConnectionStep}
          isFetchingCleanQuery={isFetchingCleanQuery}
          phoneConnectionStatus={phoneConnectionStatus}
          getConnectionStatusCallback={getConnectionStatus}
        />
      ) : null}
    </>
  );
});

ConnectionPage.displayName = 'ConnectionPage';

export default ConnectionPage;
