import React, { memo } from 'react';
import { Icon } from '@blueprintjs/core';

type TProps = {
  /**
   * Компонент для отображения в callout
   * @param {React.ReactNode}
   */
  children: React.ReactNode;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};
export const CustomWarningCallout = memo(({ children, className = '' }: TProps) => {
  return (
    <div className={className}>
      <div className='bg-[#FFF6ED] rounded-lg flex items-center px-3 py-4 min-h-[3.75rem] w-full'>
        <div className='flex items-center justify-center ml-2 mr-2.5 h-[1.75rem] w-[1.75rem]'>
          <Icon size={20} color='#FB982E' icon='info-sign' />
        </div>
        <div className='text-balticSea leading-6 tracking-[0.0275rem]'>{children}</div>
      </div>
    </div>
  );
});
