import React, { ReactNode } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type TProps = {
  /**
   * Id элемента для сортировки
   * @param {number}
   */
  id: number;
  /**
   * Элемент для отображения в доме
   * @param {ReactNode}
   */
  children?: ReactNode;
  /**
   * Callback для отображения передачи внутреннего состояния в компонент
   * @param {(isDragging: boolean) => JSX.Element}
   */
  renderOption?: (isDragging: boolean) => JSX.Element;
};

export const SortableItem: React.FC<TProps> = ({ id, children, renderOption }) => {
  const { attributes, listeners, setNodeRef, isDragging, transform, transition } = useSortable({
    id,
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        cursor: isDragging ? 'grabbing' : 'pointer',
        position: 'relative',
        transition,
        transform: CSS.Transform.toString(transform),
        zIndex: isDragging ? '99' : '',
      }}
      {...attributes}
      {...listeners}>
      <div style={{ pointerEvents: isDragging ? 'none' : 'auto' }}>
        {renderOption ? renderOption(isDragging) : children}
      </div>
    </div>
  );
};
