export enum ACCOUNTS_TOAST_MESSAGES {
  FILIAL_ERROR = 'Ошибка, \n запрашиваемый вами филиал не найден.\n Пожалуйста уточните параметры ссылки',
}

export enum ACCOUNT_NO_CONNECTION_STATUS {
  N_A = 'N/A',
}

export const ACCOUNTS_PAGE_TEXT = {
  header: 'Все филиалы',
  tableHead: {
    filial: 'Филиал',
    status: 'Статус',
    templates: 'Шаблоны',
    startedMailing: 'Запущенные рассылки',
    payment: 'Оплата',
    settings: 'Настройки',
  },
  tableRow: {
    mailing: 'Рассылки',
    connectionStatus: {
      connected: 'Подключен',
      notConnected: 'Не подключен',
    },
    paymentStatus: {
      paidUntil: 'Оплачен до',
      notPaid: 'Не оплачен',
    },
    settingsButton: 'Перейти',
  },
  filialCard: {
    sendOutAccountTag: 'Рассылки',
    connectionStatus: {
      connected: 'Подключен',
      notConnected: 'Не подключен',
    },
    paymentStatus: {
      paidUntil: 'Оплачен до',
      notPaid: 'Не оплачен',
    },
    paymentTitle: 'статус оплаты',
    settingsButton: 'Перейти',
  },
};

export const ACCOUNTS_PAGE_TEXT_ENG = {
  header: 'All branches',
  tableHead: {
    filial: 'Branch',
    status: 'Status',
    templates: 'Templates',
    startedMailing: 'Started mailings',
    payment: 'Payment',
    settings: 'Settings',
  },
  tableRow: {
    mailing: 'Newsletters',
    connectionStatus: {
      connected: 'Connected',
      notConnected: 'Not connected',
    },
    paymentStatus: {
      paidUntil: 'Paid until',
      notPaid: 'Not paid',
    },
    settingsButton: 'Settings',
  },
  filialCard: {
    sendOutAccountTag: 'Mailmails',
    connectionStatus: {
      connected: 'Connected',
      notConnected: 'Not connected',
    },
    paymentStatus: {
      paidUntil: 'Paid until',
      notPaid: 'Not paid',
    },
    paymentTitle: 'payment status',
    settingsButton: 'Settings',
  },
};
