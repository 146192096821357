import React from 'react';
import { useTranslation } from 'react-i18next';
//
import { Icon } from '@atoms/icon';
import { Button } from '@uikit/Button';
import { TOnChangeHandler } from '@shared/types';
import { SearchInputWithButton } from '@components/searchInputWithButton';

type TMailingDetailTableSearchProps = {
  /**
   * Строка поиска в инпут
   * @param {string}
   */
  searchString: string;
  /**
   * Флаг отключения кнопки поиска
   * @param {boolean}
   */
  disableSearchButton: boolean;
  /**
   * Callback запуска поиска
   * @param {() => void}
   */
  startSearchHandler: () => void;
  /**
   * Callback для очистки поисковой строки
   * @param {() => void}
   */
  clearSearchStringHandler: () => void;
  /**
   * Callback для обработки изменения поисковой строки
   * @param {TOnChangeHandler}
   */
  setSearchStringHandler: TOnChangeHandler;
  /**
   * Callback для отправки файла со статистикой в TG
   */
  sendFileToTGCallback: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const MailingDetailTableSearchBlock = ({
  searchString,
  startSearchHandler,
  disableSearchButton,
  sendFileToTGCallback,
  setSearchStringHandler,
  clearSearchStringHandler,
  className = '',
}: TMailingDetailTableSearchProps) => {
  const { t } = useTranslation();

  return (
    <div className={`flex flex-wrap justify-between pt-0.5 ${className}`}>
      <SearchInputWithButton
        hideSearchButton
        isChatHistoryPage={false}
        searchString={searchString}
        startSearchHandler={startSearchHandler}
        disableSearchButton={disableSearchButton}
        setSearchStringHandler={setSearchStringHandler}
        clearSearchStringHandler={clearSearchStringHandler}
        buttonText={t('MAILING_PAGE_TEXT.mailingDetailStat.searchButton')}
        placeholder={t('MAILING_PAGE_TEXT.mailingDetailStat.searchPlaceholder')}
        additionalInputDescription={t('MAILING_PAGE_TEXT.mailingDetailStat.searchInputDescription')}
        className='p-px !w-fit'
      />
      <div className='max-w-[12rem] mb-2 mr-0.5'>
        <Button
          fill
          dense
          type='action'
          view='outlined'
          color='default'
          onClick={sendFileToTGCallback}
          icon={<Icon variant='smallTG' />}
          text={t('MAILING_PAGE_TEXT.mailingDetailStat.sendToTG')}
        />
      </div>
    </div>
  );
};
