import { TConnectStatuses } from '@models/index';

export type TQrCodeData = {
  ok: boolean;
  qr: string;
};

export type TConnectionTGStatuses =
  | 'error'
  | 'success'
  | 'restart'
  | 'logout'
  | 'phoneSend'
  | 'pinSend'
  | 'passwordSend';

export type TAuthTypes = 'auth_success' | '2fa';

export type TMessages = {
  id: number;
  phone: string;
  body: string;
};

export enum TG_CONNECT_ERROR {
  PASSWORD_ERROR = 'Неверный код',
  INVALID_PHONE_NUMBER = 'Некорректный номер телефона',
}

export type TConnectionWAStatusData = {
  status: TConnectStatuses;
  reference: string;
  messages: TMessages[];
};

export type TConnectionTGStatusData = {
  requestStatus: TConnectionTGStatuses;
  phone: string;
  pin: string;
  isError: boolean;
  isInputError: boolean;
  errorMessage: string;
  password: string;
  tgStatus: keyof TConnectionStatus;
  stepNumber: number;
};

export type TConnectionStatus = {
  authenticated: string;
  loading: string;
  blocked: string;
  gotQrCode: string;
  notPaid: string;
  error: string;
  notConnected: string;
  notAuthenticated: string;
  requestError: string;
};
