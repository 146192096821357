import { apiService } from '@api/ApiService';
import { ConfigENV } from '@const/httpConst';
import { TBlackListData } from '@redux/blackList/models';
import { BLACKLIST_ACTION, END_POINTS_URL, QUERY_PARAMS } from '@const/apiConstants';
import {
  TRequestStatus,
  TGetResponseWithData,
  TSendOutBlackLitResponse,
  TAddDeleteNumberInBlackListQueryData,
} from '@api/types';

/**
 * API страницы черный список
 * @module blackListApi
 */

export const blackListApi = {
  /**
   * Метод получает массив номеров в ЧС
   * @method getBlackList
   * @return {Promise<AxiosResponse<TRequestStatus | TGetResponseWithData<TBlackListData[]>, any>>}
   */
  getBlackList() {
    return apiService.get<TGetResponseWithData<TBlackListData[]> | TRequestStatus>(
      END_POINTS_URL.BLACKLIST,
    );
  },
  /**
   * Метод получает массив номеров в ЧС рассылок
   * @method geSendOutBlackList
   * @return {Promise<AxiosResponse<TRequestStatus | TSendOutBlackLitResponse[], any>>}
   */
  geSendOutBlackList() {
    return apiService.get<TSendOutBlackLitResponse[] | TRequestStatus>(
      `${ConfigENV.SEND_OUT_BASE_URL}${END_POINTS_URL.SEND_OUT_BLACKLIST}`,
    );
  },
  /**
   * Метод добавляет новый номер в ЧС
   * @method addNumberToBlacklist
   * @param data {TAddDeleteNumberInBlackListQueryData}
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  addNumberToBlacklist(data: TAddDeleteNumberInBlackListQueryData) {
    return apiService.post<TRequestStatus>(END_POINTS_URL.BLACKLIST, {
      action: BLACKLIST_ACTION.ADD_NUMBER,
      phone: data.phone,
    });
  },
  /**
   * Метод добавляет новый номер в ЧС рассылок
   * @method addNumberToSendOutBlacklist
   * @param data {TAddDeleteNumberInBlackListQueryData}
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  addNumberToSendOutBlacklist(data: TAddDeleteNumberInBlackListQueryData) {
    return apiService.post<TRequestStatus>(
      `${ConfigENV.SEND_OUT_BASE_URL}${END_POINTS_URL.SEND_OUT_BLACKLIST}`,
      {},
      {
        params: {
          [QUERY_PARAMS.PHONE]: data.phone,
        },
      },
    );
  },
  /**
   * Метод удаляет номер из ЧС
   * @method deleteNumberToBlacklist
   * @param data {TAddDeleteNumberInBlackListQueryData}
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  deleteNumberFromBlacklist(data: TAddDeleteNumberInBlackListQueryData) {
    return apiService.post<TRequestStatus>(END_POINTS_URL.BLACKLIST, {
      action: BLACKLIST_ACTION.DELETE_NUMBER,
      phone: data.phone,
    });
  },
  /**
   * Метод удаляет номер из ЧС
   * @method deleteNumberFromSendOutBlacklist
   * @param data {TAddDeleteNumberInBlackListQueryData}
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  deleteNumberFromSendOutBlacklist(data: TAddDeleteNumberInBlackListQueryData) {
    return apiService.delete<TRequestStatus>(
      `${ConfigENV.SEND_OUT_BASE_URL}${END_POINTS_URL.SEND_OUT_BLACKLIST}`,
      {},
      {
        params: {
          [QUERY_PARAMS.PHONE]: data.phone,
        },
      },
    );
  },
};
