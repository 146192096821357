import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { EMPLOYEES } from '@const/pay';
import { TCurrency } from '@api/types';
import { numWord } from '@helpers/index';
import { Status } from '@blocks/colorStatus';
import { TAppLanguage } from '@models/index';
import { ToggleSwitch } from '@blocks/toggleSwitch';
import { IconWithTooltips } from '@components/iconWithTooltips';

type TFilialPayData = {
  /**
   * Валюта для оплаты
   * @param {TCurrency}
   */
  currency: TCurrency;
  /**
   * Статус оплаты
   * @param {boolean}
   */
  payStatus: boolean;
  /**
   * До какой даты оплачена подписка
   * @param {string}
   */
  payTillDate: string;
  /**
   * Количество сотрудников в филиале
   * @param {string}
   */
  branchEmployees: string;
  /**
   * Флаг подключения автоплатежа
   * @param {boolean}
   */
  isAutoPayEnable: boolean;
  /**
   * Выбранный язык приложения
   * @param {TAppLanguage}
   */
  appLanguage: TAppLanguage;
  /**
   * Скидка
   * @param {string}
   */
  discount: string;
  /**
   * Callback для подключения автоплатежа
   * @param {() => void}
   */
  getAutoPayLinkHandler: () => void;
  /**
   * Флаг состояния модалки для отключения автоплатежа
   * @param {() => void}
   */
  autoPayDisableModalOpen: () => void;
  /**
   * Callback для изменения карты для автоплатежа
   * @param {() => void}
   */
  changeCardHandler: () => void;
  /**
   * Флаг состояния процесса загрузки ссылки для смены карты
   * @param {boolean}
   */
  changeCardIsLoading: boolean;
  /**
   * Последние 4 цифры карты
   * @param {string}
   */
  cardLastFourDigits: string;
  /**
   * Флаг нового аккаунта (филиал на тесте)
   * @param {boolean}
   */
  isNewAccount: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const FilialPayData = memo(
  ({
    discount,
    currency,
    payStatus,
    appLanguage,
    payTillDate,
    isNewAccount,
    isAutoPayEnable,
    branchEmployees,
    changeCardHandler,
    cardLastFourDigits,
    changeCardIsLoading,
    getAutoPayLinkHandler,
    autoPayDisableModalOpen,
    className = '',
  }: TFilialPayData) => {
    const { t } = useTranslation();

    const payStatusText = payStatus
      ? t('PAY_PAGE_TEXT.payTill', { payTillDate })
      : t('PAY_PAGE_TEXT.notPaid');

    const autoPayText = t('PAY_PAGE_TEXT.payEvery', { payTillDate: payTillDate.slice(0, 2) });

    const discountPrefix = t('PAY_PAGE_TEXT.discountText');

    const tariffData = t('PAY_PAGE_TEXT.tariffData', {
      branchEmployees,
      employersText: numWord(+branchEmployees, EMPLOYEES[appLanguage]),
    });

    const newTariff = t('PAY_PAGE_TEXT.newTariff', {
      branchEmployees,
      employersText: numWord(+branchEmployees, EMPLOYEES[appLanguage]),
    });

    const autoPaymentText = isAutoPayEnable
      ? t('PAY_PAGE_TEXT.autoPaymentStatusEnabled')
      : t('PAY_PAGE_TEXT.autoPaymentStatusDisabled');

    return (
      <div
        className={`bg-gray1 rounded-lg ${
          isNewAccount ? 'flex flex-col sm:flex-row sm:items-center sm:justify-between' : ''
        } p-4 ${className}`}>
        <div className='flex flex-col sm:flex-row flex-wrap justify-between mb-4 sm:mb-0'>
          <div
            className={`flex items-center font-medium tracking-[0.0275em] ${
              !isNewAccount ? 'mb-4 sm:mb-2' : ''
            }`}>
            <Status isPayStatus status={payStatus} title={t('PAY_PAGE_TEXT.payStatusTitle')} />
            <span className='ml-3 mr-2'>{isAutoPayEnable ? autoPayText : payStatusText}</span>
            <IconWithTooltips
              noIcon={false}
              tooltips={currency === 'RUB' ? 'tariff' : 'tariffUSD'}
              isFirstTooltip={false}
              className='mb-0'
            />
          </div>
          {!isNewAccount ? (
            <div className='flex items-center justify-between'>
              <div className='flex flex-col mr-4'>
                <p className='font-medium leading-6 tracking-[0.022em] m-0'>{autoPaymentText}</p>
              </div>
              <ToggleSwitch
                name=''
                checked={isAutoPayEnable}
                onChangeHandler={isAutoPayEnable ? autoPayDisableModalOpen : getAutoPayLinkHandler}
              />
            </div>
          ) : null}
        </div>
        <div
          className={`flex flex-col-reverse sm:flex-row flex-wrap sm:items-center justify-between ${
            isAutoPayEnable ? 'mt-2' : ''
          }`}>
          <div className='flex flex-wrap items-start m:items-center justify-start'>
            <span className='mr-1'>👩</span>
            <span className='flex items-center whitespace-pre-wrap text-darkGray mr-2'>
              {isNewAccount ? newTariff : tariffData}
            </span>
            {discount && !isNewAccount ? (
              <>
                <span className='whitespace-pre-wrap text-darkGray mr-2'>{discountPrefix}</span>
                <span className='bg-orangeBg rounded-full flex items-center justify-center whitespace-pre-wrap break-words text-white px-2.5 py-1 w-fit'>
                  {discount}
                </span>
              </>
            ) : null}
          </div>
          {isAutoPayEnable ? (
            <div className='flex flex-col sm:flex-row items-center mb-4 sm:mb-0'>
              <span className='mr-4 mb-2 sm:mb-0'>
                {t('PAY_PAGE_TEXT.card', { cardLastFourDigits })}
              </span>
              <Button
                dense
                icon='edit'
                type=''
                view=''
                color='default'
                onClick={changeCardHandler}
                loading={changeCardIsLoading}
                text={t('PAY_PAGE_TEXT.changeCard')}
                className='sm:!min-w-[12.25rem] !p-0 !h-fit w-fit'
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  },
);

FilialPayData.displayName = 'FilialPayData';
