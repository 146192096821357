import { RootState } from '@store/store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TRejectResponseData } from '@models/index';
import { autoConnectionApi } from '@api/autoConnectionApi';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { autoConnectionMappers } from '@redux/autoConnect/autoConnectionMappers';
import { AUTO_CONNECTION_RESPONSE_DATA_KEY_NAMES, REJECT_RESPONSE_KEY } from '@api/types';
import {
  TSendLinkInWAData,
  TAutoConnectionData,
  TGetFormRequestData,
  TAutoConnectFormData,
} from '@redux/autoConnect/models';

/**
 * Thunk для отправки ссылки на бота в WA
 * @returns {void} void.
 * @throws {Error} Ошибка, если получен некорректный ответ.
 * @param {string} data - Ссылка для отправки в whatsApp.
 * @param {(value: TRejectResponse | TRejectResponse2) => RejectWithValue<TRejectResponseData, unknown>} rejectWithValue - Функция для обработки ошибок запроса.
 */
export const sendLinkInWaThunk = createAsyncThunk<
  void,
  TSendLinkInWAData,
  { rejectValue: TRejectResponseData }
>('autoConnection/sendLinkMessageInWaThunk', (data, { dispatch, rejectWithValue }) => {
  const params = autoConnectionMappers.getFormRequestData(data.params);
  return autoConnectionApi
    .sendLinkInWA({ params: params.params, phoneNumber: data.phoneNumber })
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      data.callback();
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(sendLinkInWaThunk(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk для отправки номера телефона для обратного звонка.
 * @returns {void} void.
 * @throws {Error} Ошибка, если получен некорректный ответ.
 * @param {string} data - Номер телефона для обратного звонка.
 * @param {(value: TRejectResponse | TRejectResponse2) => RejectWithValue<TRejectResponseData, unknown>} rejectWithValue - Функция для обработки ошибок запроса.
 */
export const getCallbackCallThunk = createAsyncThunk<
  void,
  string,
  { state: RootState; rejectValue: TRejectResponseData }
>('autoConnection/getCallbackCallThunk', (phoneHumber, { getState, dispatch, rejectWithValue }) => {
  const { hash, accId } = getState().autoConnection;
  const queryParams = autoConnectionMappers.getFormRequestData({ hash, accId });
  return autoConnectionApi
    .getCallbackCall({ phoneNumber: phoneHumber, params: queryParams })
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getCallbackCallThunk(phoneHumber)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk для получения данных формы
 * @returns {TAutoConnectionData | void} Данные формы или void.
 * @throws {Error} Ошибка, если получен некорректный ответ.
 * @param {TGetFormRequestData} data - Данные для отправки запроса.
 * @param {(value: TRejectResponse | TRejectResponse2) => RejectWithValue<TRejectResponseData, unknown>} rejectWithValue - Функция для обработки ошибок запроса.
 */
export const getAutoConnectFormData = createAsyncThunk<
  TAutoConnectionData | void,
  TGetFormRequestData,
  { rejectValue: TRejectResponseData }
>('autoConnection/getAutoConnectFormData', (data, { dispatch, rejectWithValue }) => {
  return autoConnectionApi
    .getFormData(autoConnectionMappers.getFormRequestData(data))
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      if (AUTO_CONNECTION_RESPONSE_DATA_KEY_NAMES.FORM_STEP in response.data) {
        return autoConnectionMappers.mapResponseToStore(response.data);
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getAutoConnectFormData(data)), 200);
      }
      return rejectWithValue(error.response.data);
    });
});

/**
 * Thunk для отправки новых данных шага
 * @returns {TAutoConnectionData | void} Данные формы или void.
 * @throws {Error} Ошибка, если получен некорректный ответ.
 * @param {TGetFormRequestData} data - Данные формы для отправки запроса.
 * @param {(value: TRejectResponse | TRejectResponse2) => RejectWithValue<TRejectResponseData, unknown>} rejectWithValue - Функция для обработки ошибок запроса.
 */
export const acceptFormStepThunk = createAsyncThunk<
  TAutoConnectionData | void,
  { data: TAutoConnectFormData; callback: () => void },
  { state: RootState; rejectValue: TRejectResponseData }
>(
  'autoConnection/acceptFormStepThunk',
  ({ data, callback }, { getState, dispatch, rejectWithValue }) => {
    const { stepId, params } = data;
    const formData = getState().autoConnection.data;
    const { newPromoCode2, newPromoCode } = getState().autoConnection;
    const queryParams = autoConnectionMappers.getFormRequestData(params);
    const dataToSave = autoConnectionMappers.mapDataToSave({
      ...formData,
      promoCode: newPromoCode || newPromoCode2,
    });
    return autoConnectionApi
      .acceptStep({ data: { ...dataToSave, form_step: stepId }, params: queryParams })
      .then(response => {
        if (REJECT_RESPONSE_KEY.ERROR in response.data) {
          throw new Error(String(response.data));
        }
        if (AUTO_CONNECTION_RESPONSE_DATA_KEY_NAMES.FORM_STEP in response.data) {
          callback();
          return autoConnectionMappers.mapResponseToStore(response.data);
        }
      })
      .catch(error => {
        if (error?.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(acceptFormStepThunk({ data, callback })), 200);
        }
        return rejectWithValue(error?.response?.data);
      });
  },
);
