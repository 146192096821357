import React, { memo, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
//
import { EMPLOYEES } from '@const/pay';
import { PayNav } from '@blocks/payNav';
import { TPayData } from '@redux/pay/types';
import { useLocation } from 'react-router-dom';
import { PayCards } from '@components/payCards';
import { getAppData } from '@redux/app/selectors';
import { getPayPageState } from '@redux/pay/selectors';
import { PaymentBlock } from '@components/paymentBlock';
import { TChannels, TGetPayLinkData } from '@api/types';
import { FilialPayData } from '@components/filialPayData';
import { PayPageModals } from '@components/payPageModals';
import { PayOptionList } from '@components/payOptionList';
import { getAccountsData } from '@redux/accounts/selectors';
import { useAppDispatch, useAppSelector } from '@store/store';
import { TCardSelectHandler, TOnChangeHandler } from '@shared/types';
import { downloadByLink, getUSFormatDateString, numWord } from '@helpers/index';
import {
  getPayLinkThunk,
  sendInvoiceToTg,
  createInvoiceThunk,
  getCalculatedPriceThunk,
} from '@redux/pay/payThunks';
import {
  selectModule,
  setPromoCode,
  hideGetBillModal,
  setNewCardNumber,
  setPayTariffDays,
  hideChangeCardModal,
  setSelectedCardNumber,
  hideAutoPayDisableModal,
  hideConnectAutoPayModal,
  showAutoPayDisableModal,
} from '@redux/pay/paySlice';

type TPayTemplateProps = {
  /**
   * Callback для повторной загрузки данных
   * @param {function}
   */
  refetch: () => void;
  /**
   * Callback для получения ссылки на автоплатеж
   * @param {function}
   */
  getAutoPayLinkHandler: () => void;
  /**
   * Ссылка на автоплатеж
   * @param {string}
   */
  autoPayLink: string;
  /**
   * Состояние загрузки ссылки на автоплатеж
   * @param {boolean}
   */
  isFetchingAutoPayLink: boolean;
  /**
   * Callback для изменения карты
   * @param {function}
   */
  changeCardHandler: () => void;
  /**
   * Ссылка для изменения карты
   * @param {string}
   */
  changeCardDataLink: string;
  /**
   * Состояние загрузки при изменении карты
   * @param {boolean}
   */
  changeCardIsLoading: boolean;
  /**
   * Callback для отключения автоплатежа
   * @param {function}
   */
  turnOffAutoPaymentHandler: () => void;
  /**
   * Состояние загрузки при отключении автоплатежа
   * @param {boolean}
   */
  turnOffAutoPaymentIsLoading: boolean;
  /**
   * Успешно ли было отключение автоплатежа
   * @param {boolean}
   */
  turnOffAutoPaymentIsSuccess: boolean;
  /**
   * Данные для отображения на странице
   * @param {TPayData}
   */
  data: TPayData;
  /**
   * Опциональный параметр - строка классов
   * @param {string}
   */
  className?: string;
};

export const PayTemplate = memo(
  ({
    refetch,
    autoPayLink,
    changeCardHandler,
    changeCardDataLink,
    changeCardIsLoading,
    getAutoPayLinkHandler,
    isFetchingAutoPayLink,
    turnOffAutoPaymentHandler,
    turnOffAutoPaymentIsLoading,
    turnOffAutoPaymentIsSuccess,
    data,
    className = '',
  }: TPayTemplateProps) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const { pathname } = useLocation();

    const {
      promoCode,
      isRetries,
      invoiceData,
      payTariffDays,
      newCardNumber,
      calculatedPrice,
      selectedModules,
      isGettingPayLink,
      isChangeModalOpen,
      isCalculatingCoast,
      selectedCardNumber,
      isSendingInvoiceToTg,
      isFetchingInvoiceLink,
      isBillDownloadModalOpen,
      selectedCardMonthAmount,
      isAutoPayDisableModalOpen,
      isConnectAutoPayModalOpen,
    } = useAppSelector(getPayPageState);

    const { appLanguage } = useAppSelector(getAppData);

    // Получаем имя сохраняется при выборе филиала на странице /accounts
    const { selectedFilial } = useAppSelector(getAccountsData);

    const filialName = selectedFilial?.address || '';

    // Деструктуризируем data
    const {
      amount,
      currency,
      discount,
      payStatus,
      monthCost,
      createBill,
      payTillDate,
      isTestPeriod,
      modulesPrice,
      numberOfMonth,
      activeModules,
      lastPayLength,
      isAutoPayEnable,
      branchEmployees,
      numberOfGiftWeek,
      cardLastFourDigits,
    } = data;

    // Формируем строку описания филиала
    const rate = t(currency === 'RUB' ? 'PAY_PAGE_TEXT.rate' : 'PAY_PAGE_TEXT.rateUSD', {
      filialName,
      branchEmployees,
      employersText: numWord(+branchEmployees, EMPLOYEES[appLanguage]),
      amount,
    });

    // Формируем текст скидки
    const discountText = discount ? t('PAY_PAGE_TEXT.discount', { discount }) : '';

    // Callback для закрытия всех модалок
    const closeAllModal = useCallback(() => {
      dispatch(hideChangeCardModal());
      dispatch(hideConnectAutoPayModal());
      dispatch(hideAutoPayDisableModal());
    }, [dispatch]);

    // Callback для управлением модалкой изменения карты
    const changeModalClose = useCallback(() => {
      dispatch(hideChangeCardModal());
    }, [dispatch]);

    // Callback для управлением модалкой подключения автоплатежа
    const connectAutoPayModalClose = useCallback(() => {
      dispatch(hideConnectAutoPayModal());
    }, [dispatch]);

    // Callback для управлением модалкой отключения автоплатежа
    const autoPayDisableModalOpen = useCallback(() => {
      dispatch(showAutoPayDisableModal());
    }, [dispatch]);

    // Callback для открытия модалки получения счетна на оплату
    const getBillModalOpen = useCallback(() => {
      const modules = selectedCardMonthAmount
        ? selectedModules
        : selectedModules.filter(item => !activeModules.includes(item));

      dispatch(
        createInvoiceThunk({
          modules,
          months: selectedCardMonthAmount || lastPayLength,
        }),
      );
    }, [lastPayLength, activeModules, selectedModules, selectedCardMonthAmount, dispatch]);

    // Callback для закрытия модалки получения счетна на оплату
    const getBillModalClose = useCallback(() => {
      dispatch(hideGetBillModal());
    }, [dispatch]);

    // Callback для отправки счета в telegram
    const sendBillInTGHandler = useCallback(() => {
      if (invoiceData) {
        dispatch(sendInvoiceToTg(invoiceData));
      }
    }, [invoiceData, dispatch]);

    // Callback для скачивания счета в формате pdf
    const downloadBillHandler = useCallback(
      (link: string) => () => {
        downloadByLink(link);
      },
      [],
    );

    // Callback для повторного запроса данных страницы
    const reloadPage = useCallback(() => {
      refetch();
    }, [refetch]);

    // Функция осуществляет переход по ссылке
    const changePageOnClick = useCallback((link: string) => {
      const target = '_blank';

      const newWindow = window.open(link, target, 'noopener, noreferrer');

      if (newWindow) {
        newWindow.opener = null;
      }
    }, []);

    const payHandler = useCallback(() => {
      const modules = selectedCardMonthAmount
        ? selectedModules
        : selectedModules.filter(item => !activeModules.includes(item));

      const requestData: TGetPayLinkData = {
        modules,
        months: selectedCardMonthAmount,
      };

      dispatch(getPayLinkThunk({ data: requestData, successCallback: changePageOnClick }));
    }, [activeModules, changePageOnClick, selectedCardMonthAmount, selectedModules, dispatch]);

    // Рассчитывает стоимость выбранного периода с указанныи набором опций
    const calculatePriceHandler = useCallback(
      ({ monthAmount, successCallback }: { monthAmount: number; successCallback: () => void }) => {
        const payPeriod = getUSFormatDateString(payTillDate);

        if (payPeriod && amount && !isCalculatingCoast) {
          dispatch(
            getCalculatedPriceThunk({
              data: {
                currency,
                amount: +amount,
                months: monthAmount,
                pay_period: payPeriod,
                modules: ['WA'],
                // modules: selectedModules,
              },
              successCallback,
            }),
          );
        }
      },
      [amount, currency, payTillDate, isCalculatingCoast, dispatch],
      // [amount, currency, payTillDate, selectedModules, isCalculatingCoast, dispatch],
    );

    const calculatePriceWithNewOptions = useCallback(
      (moduleName: TChannels, successCallback: () => void) => {
        const isSelected = selectedModules.includes(moduleName);

        const modules = isSelected
          ? selectedModules.filter(item => item !== moduleName)
          : [...selectedModules, moduleName];

        const payPeriod = getUSFormatDateString(payTillDate);

        if (payPeriod && !isCalculatingCoast) {
          dispatch(
            getCalculatedPriceThunk({
              data: {
                currency,
                amount: +amount,
                pay_period: payPeriod,
                months: selectedCardMonthAmount,
                modules: selectedCardMonthAmount
                  ? modules
                  : modules.filter(module => module !== 'WA'),
              },
              successCallback,
            }),
          );
        }
      },
      [
        amount,
        currency,
        payTillDate,
        selectedModules,
        isCalculatingCoast,
        selectedCardMonthAmount,
        dispatch,
      ],
    );

    // Функция обрабатывает выбор карточки для оплаты
    const cardSelectHandler: TCardSelectHandler = useCallback(
      cardNumber => (totalAmount, monthAmount) => {
        if (cardNumber !== selectedCardNumber) {
          dispatch(setNewCardNumber(cardNumber));
          calculatePriceHandler({
            monthAmount,
            successCallback: () =>
              dispatch(setSelectedCardNumber({ cardNumber, totalAmount, monthAmount })),
          });
        } else {
          dispatch(setSelectedCardNumber({ cardNumber, totalAmount, monthAmount }));
        }
      },
      [calculatePriceHandler, selectedCardNumber, dispatch],
    );

    // Выбирает тг канал как опцию для оплаты
    const selectModuleHandler = useCallback(
      (moduleName: TChannels) => {
        if (moduleName !== 'WA') {
          calculatePriceWithNewOptions(moduleName, () => dispatch(selectModule(moduleName)));
        }
      },
      [calculatePriceWithNewOptions, dispatch],
    );

    // Обрабатывает изменения поля ввода промокода
    const setPromoCodeHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;

        dispatch(setPromoCode(value));
      },
      [dispatch],
    );

    // Изменяет чекбокс оплаты днями нарифа
    const setPayTariffDaysHandler = useCallback(() => {
      dispatch(setPayTariffDays(!payTariffDays));
    }, [payTariffDays, dispatch]);

    // Применяет введенный промокод
    const applyPromoCode = useCallback(() => {
      // console.log(promoCode);
    }, []);

    // const borderColor = selectedCardNumber ? 'border-orangeBg' : 'border-quartz';
    //
    // const backgroundColor =
    //   selectedCardMonthAmount === lastPayLength
    //     ? 'bg-gray1'
    //     : selectedCardNumber && selectedCardNumber !== 0
    //     ? 'bg-white'
    //     : 'bg-white';

    return (
      <div className={`relative flex flex-col ${className}`}>
        <FilialPayData
          currency={currency}
          payStatus={payStatus}
          discount={discountText}
          appLanguage={appLanguage}
          payTillDate={payTillDate}
          isNewAccount={isTestPeriod}
          isAutoPayEnable={isAutoPayEnable}
          branchEmployees={branchEmployees}
          changeCardHandler={changeCardHandler}
          cardLastFourDigits={cardLastFourDigits}
          changeCardIsLoading={changeCardIsLoading}
          getAutoPayLinkHandler={getAutoPayLinkHandler}
          autoPayDisableModalOpen={autoPayDisableModalOpen}
          className='mx-4 sm:mx-0 mb-8'
        />
        <PayNav
          path={pathname}
          appLanguage={appLanguage}
          selectedFilialAccId={selectedFilial?.accId || ''}
          className='px-4 sm:px-0 mb-6'
        />
        <div className='flex flex-col xl:flex-row w-full'>
          <div className='sm:mr-6 xxl:max-w-[54.5rem] w-full'>
            <div className='sm:mx-0'>
              <div className='mb-6'>
                <PayCards
                  amount={amount}
                  currency={currency}
                  monthCost={monthCost}
                  appLanguage={appLanguage}
                  lastPayLength={lastPayLength}
                  newCardNumber={newCardNumber}
                  numberOfMonth={numberOfMonth}
                  numberOfGiftWeek={numberOfGiftWeek}
                  cardSelectCallback={cardSelectHandler}
                  isCalculatingCoast={isCalculatingCoast}
                  selectedCardNumber={selectedCardNumber}
                />
              </div>
              {/* {activeModules.length || selectedCardNumber ? (
                <div
                  className={`border rounded-lg ${borderColor} ${backgroundColor} px-2.5 sm:px-5 py-5 mx-4 sm:mx-0 mb-6`}>
                  <div className='mb-4'>
                    <span className='font-medium text-black text-[1.25rem] leading-8 tracking-[0.004em]'>
                      {t('PAY_PAGE_TEXT.selectAdditionalServices')}
                    </span>
                  </div>
                  <div className='flex flex-col'>
                    <PayOptionList
                      currency={currency}
                      payStatus={payStatus}
                      payTill={payTillDate}
                      bgColor={backgroundColor}
                      modulesPrice={modulesPrice}
                      activeModules={activeModules}
                      selectedModules={selectedModules}
                      selectedCardNumber={selectedCardNumber}
                      selectModuleHandler={selectModuleHandler}
                    />
                  </div>
                </div>
              ) : null} */}
              <PayPageModals
                rate={rate}
                currency={currency}
                filialName={filialName}
                reloadPage={reloadPage}
                appLanguage={appLanguage}
                autoPayLink={autoPayLink}
                closeAllModal={closeAllModal}
                branchEmployees={branchEmployees}
                changeModalClose={changeModalClose}
                isChangeModalOpen={isChangeModalOpen}
                getBillModalClose={getBillModalClose}
                totalAmount={calculatedPrice.fullCoast}
                changeCardDataLink={changeCardDataLink}
                sendBillInTGHandler={sendBillInTGHandler}
                isSendingInvoiceToTg={isSendingInvoiceToTg}
                isBillDownloadModalOpen={isBillDownloadModalOpen}
                connectAutoPayModalClose={connectAutoPayModalClose}
                isAutoPayDisableModalOpen={isAutoPayDisableModalOpen}
                isConnectAutoPayModalOpen={isConnectAutoPayModalOpen}
                turnOffAutoPaymentHandler={turnOffAutoPaymentHandler}
                turnOffAutoPaymentIsSuccess={turnOffAutoPaymentIsSuccess}
                turnOffAutoPaymentIsLoading={turnOffAutoPaymentIsLoading}
                downloadBillHandler={downloadBillHandler(invoiceData?.pdfUrl || '')}
                className=''
              />
            </div>
          </div>
          <PaymentBlock
            currency={currency}
            isHidePromoCodeBlock
            promoCode={promoCode}
            createBill={createBill}
            appLanguage={appLanguage}
            activeModules={activeModules}
            payTariffDays={payTariffDays}
            changePageOnClick={payHandler}
            applyPromoCode={applyPromoCode}
            calculatedPrice={calculatedPrice}
            isAutoPayEnable={isAutoPayEnable}
            isLoading={isFetchingAutoPayLink}
            selectedModules={selectedModules}
            isGettingPayLink={isGettingPayLink}
            getBillModalOpen={getBillModalOpen}
            isCalculatingCoast={isCalculatingCoast}
            selectedCardNumber={selectedCardNumber}
            selectModuleHandler={selectModuleHandler}
            setPromoCodeHandler={setPromoCodeHandler}
            getAutoPayLinkHandler={getAutoPayLinkHandler}
            isFetchingInvoiceLink={isFetchingInvoiceLink}
            setPayTariffDaysHandler={setPayTariffDaysHandler}
            selectedCardMonthAmount={selectedCardMonthAmount}
            className='mx-4 sm:mx-0 xxl:max-w-[26.5rem] h-fit sm:w-full'
          />
        </div>
        {isRetries
          ? createPortal(
              <div className='absolute top-0 left-0 bottom-0 right-0 cursor-wait' />,
              document.body,
            )
          : null}
      </div>
    );
  },
);

PayTemplate.displayName = 'PayTemplate';
