import { TTemplateInfo, TTemplateInfoObj } from '@redux/templateList/types';

export interface IInitialState {
  retries: number;
  isError: boolean;
  isCoping: boolean;
  isLoading: boolean;
  isRetries: boolean;
  isSuccess: boolean;
  isDeleting: boolean;
  isFetching: boolean;
  errorMessage: string;
  successMessage: string;
  isCopingError: boolean;
  isCopingSuccess: boolean;
  isDeletingError: boolean;
  deletedTemplateId: string;
  isDeletingSuccess: boolean;
  isTemplateSwitching: boolean;
  templateIdInProgress: string;
  isTemplateSwitchingError: boolean;
  isTemplateSwitchingSuccess: boolean;
  isTemplateListAccessDenied: boolean;
  templateInfoObj: TTemplateInfoObj;
  templateListOrder: number[];
  data: TTemplateInfo[];
}

export const initialState: IInitialState = {
  retries: 1,
  isError: false,
  isCoping: false,
  isLoading: false,
  errorMessage: '',
  isSuccess: false,
  isRetries: false,
  isDeleting: false,
  isFetching: false,
  successMessage: '',
  isCopingError: false,
  deletedTemplateId: '',
  isCopingSuccess: false,
  isDeletingError: false,
  isDeletingSuccess: false,
  templateIdInProgress: '',
  isTemplateSwitching: false,
  isTemplateSwitchingError: false,
  isTemplateSwitchingSuccess: false,
  isTemplateListAccessDenied: false,
  templateInfoObj: {},
  templateListOrder: [],
  data: [],
};
