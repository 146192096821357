import React, { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { TOnChangeHandler } from '@shared/types';
import { InputWithLabel } from '@blocks/inputWithLabel';
import { AUTO_RESPONSE_ITEM_KEYS } from '@redux/autoResponse/models';

type TClientRequestInputProps = {
  /**
   * Иконка в кнопку
   * @param {string}
   */
  buttonIcon: string;
  /**
   * Callback для обработки изменений в инпуте
   * @param {TOnChangeHandler}
   */
  onChangeCallback: TOnChangeHandler;
  /**
   * Флаг включения режима редактирования поля ввода
   * @param {boolean}
   */
  isEdit: boolean;
  /**
   * Значение инпута
   * @param {string}
   */
  inputValue: string;
  /**
   * Callback для обработки нажатия на кнопку
   * @param {() => void}
   */
  buttonOnClickHandler: () => void;
  /**
   * Callback для обработки события onBlur
   * @param {(() => void) | undefined}
   */
  onBlurCallback: (() => void) | undefined;
  /**
   * Опциональный параметр - строка классов
   * @param {string}
   */
  className?: string;
};

const RequestInput = forwardRef<HTMLInputElement, TClientRequestInputProps>(
  (
    {
      isEdit,
      inputValue,
      buttonIcon,
      onBlurCallback,
      onChangeCallback,
      buttonOnClickHandler,
      className = '',
    },
    ref,
  ) => {
    const { t } = useTranslation();

    return (
      <div className={`flex items-end justify-between ${className}`}>
        <div className='w-full'>
          <InputWithLabel
            ref={ref}
            value={inputValue}
            disabled={!isEdit}
            onBlurCallback={onBlurCallback}
            onChangeCallback={onChangeCallback}
            name={AUTO_RESPONSE_ITEM_KEYS.CLIENT_REQUEST}
            placeholderText={t('AUTO_RESPONSE_PAGE_TEXT.clientRequestPlaceholder')}
            labelComponent={
              <span className='text-stormGray'>{t('AUTO_RESPONSE_PAGE_TEXT.clientRequest')}</span>
            }
          />
        </div>
        {isEdit ? (
          <Button
            dense
            type='action'
            view='outlined'
            color='default'
            icon={buttonIcon}
            disabled={!inputValue}
            onClick={buttonOnClickHandler}
            className='ml-4 mb-1 h-10 w-10'
          />
        ) : null}
      </div>
    );
  },
);

export const ClientRequestInput = memo(RequestInput);

ClientRequestInput.displayName = 'ClientRequestInput';
