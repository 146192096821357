import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
//
import { CONFIG } from '@const/config';
import { Footer } from '@blocks/footer';
import { Loader } from '@blocks/loader';
import { PATH_NAMES } from '@data/dict';
import { SidedNav } from '@blocks/sideNav';
import { Header } from '@components/header';
import { TWithChildren } from '@models/index';
import { logout } from '@redux/auth/authSlice';
import { useLocation } from 'react-router-dom';
import { NoAccess } from '@components/noAccess';
import { hideAppDrawer } from '@redux/app/slice';
import { AppDrawer } from '@components/appDrawer';
import { VIDEO_INSTRUCTIONS } from '@const/common';
import { ErrorMessage } from '@blocks/errorMessage';
import { getAuthData } from '@redux/auth/selectors';
import { headerBanner } from '@redux/auth/authThunks';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { HeaderBanner } from '@components/headerBanner';
import { getAccountsData } from '@redux/accounts/selectors';
import { HEADER_BANNER_TYPES } from '@redux/accounts/models';
import { useAppDispatch, useAppSelector } from '@store/store';

interface IBaseTemplateProps extends TWithChildren {
  /**
   * Путь для корректной работы компонента в storyBook
   * @param {string}
   */
  storyBookPath?: string;
  /**
   * Флаг показывает лоадер
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Флаг показывает ошибку в запросе к серверу
   * @param {boolean}
   */
  isError: boolean;
  /**
   * Флаг показывает ошибку доступа
   * @param {boolean}
   */
  isAccessDenied: boolean;
  /**
   * Флаг указывает является и выбранный филиал салоном
   * @param {boolean}
   */
  isSalon: boolean;
}

export const BaseTemplate = memo(
  ({
    isError,
    isSalon,
    children,
    isLoading,
    storyBookPath,
    isAccessDenied,
  }: IBaseTemplateProps) => {
    const width = useWindowWidth();
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();

    const pathToCompare = storyBookPath || pathname;

    // получаем данные авторизованного пользователя
    const { status, type, authUserData, auth } = useAppSelector(getAuthData);

    const { selectedFilial } = useAppSelector(getAccountsData);

    // Состояние меню для мобильных
    const [isOpen, setIsOpen] = useState(false);

    const memoizedAuthUserData = useMemo(() => authUserData, [authUserData]);

    // eslint-disable-next-line
    // @ts-ignore
    const isShow404Page = !Object.values(PATH_NAMES).includes(pathToCompare);

    const hideSideNav =
      pathToCompare === PATH_NAMES.ACCOUNTS ||
      pathToCompare === PATH_NAMES.START_PAGE ||
      pathToCompare === PATH_NAMES.AUTO_CONNECT_FORM ||
      pathToCompare === PATH_NAMES.AUTO_CONNECT ||
      pathToCompare === PATH_NAMES.YCLIENTS_START_PAGE;

    const hideHeader =
      pathToCompare === PATH_NAMES.START_PAGE ||
      pathToCompare === PATH_NAMES.AUTO_CONNECT_FORM ||
      pathToCompare === PATH_NAMES.AUTO_CONNECT ||
      pathToCompare === PATH_NAMES.YCLIENTS_START_PAGE;

    const hideFooter =
      pathToCompare === PATH_NAMES.AUTO_CONNECT_FORM ||
      pathToCompare === PATH_NAMES.AUTO_CONNECT ||
      pathToCompare === PATH_NAMES.YCLIENTS_START_PAGE;

    const isAutoConnectForm =
      pathToCompare === PATH_NAMES.AUTO_CONNECT_FORM ||
      pathToCompare === PATH_NAMES.AUTO_CONNECT ||
      pathToCompare === PATH_NAMES.YCLIENTS_START_PAGE;

    useEffect(() => {
      if (auth && selectedFilial?.accId) {
        dispatch(headerBanner());
        const interval = setInterval(() => {
          dispatch(headerBanner());
        }, 1800000);

        return () => clearInterval(interval);
      }
    }, [auth, selectedFilial?.accId, dispatch]);

    // Эффект следит за изменением ширины экрана и если ширина больше 980 и
    // закрывает все принудительно
    useEffect(() => {
      if (width > 980 && isOpen) {
        setIsOpen(false);
      }
    }, [width, isOpen]);

    // Открывает/закрывает мобильное меню
    const onClickHandler = useCallback(() => {
      setIsOpen(prevState => !prevState);
    }, []);

    const logoutHandler = useCallback(() => {
      dispatch(logout());
    }, [dispatch]);

    const closeAppDrawer = useCallback(() => {
      dispatch(hideAppDrawer());
    }, [dispatch]);

    const contentWrapperStyles = `flex ${
      !isShow404Page && !hideSideNav
        ? 'lg:grid sm:px-4 lg:px-[1.5rem] lg:grid-cols-[17.5rem_1fr]'
        : !hideFooter
        ? ''
        : 'h-full'
    }  lg:gap-[1.5rem] ${isOpen ? 'hidden' : ''} mx-auto min-h-[calc(100vh-6.9rem)] ${
      isAutoConnectForm ? '' : 'pb-[4rem] max-w-[104.5rem]'
    }`;

    const { videoLink, headerText, startTime } = VIDEO_INSTRUCTIONS.login[0];

    return (
      <>
        {!hideHeader ? (
          <Header
            isOpen={isOpen}
            isSalon={isSalon}
            isAccountPage={false}
            logoutHandler={logoutHandler}
            onClickHandler={onClickHandler}
            authUserData={memoizedAuthUserData}
            supportTelegramLink={CONFIG.SUPPORT_TELEGRAM_LINK}
            supportTelegramPhoneToHref={CONFIG.SUPPORT_PHONE_NUMBER_TO_HREF}
            supportTelegramPhoneToShow={CONFIG.SUPPORT_PHONE_NUMBER_TO_SHOW}
            className={isShow404Page ? '' : 'mb-[2.3125rem]'}
          />
        ) : null}
        <div className={contentWrapperStyles}>
          {!isShow404Page && !hideSideNav ? (
            <SidedNav
              isSalon={isSalon}
              onClickHandler={onClickHandler}
              className='hidden lg:flex'
            />
          ) : null}
          <main className={`${isAutoConnectForm ? '' : 'mb-8'} w-full`}>
            {isLoading && !isError ? <Loader className='h-[50Vh]' /> : null}
            {!isLoading && !isError && !isAccessDenied ? (
              <div className='w-full'>
                {status && type !== HEADER_BANNER_TYPES.SUCCESS && !hideSideNav ? (
                  <HeaderBanner bodyText={status} variant={type} className='mb-[1.5rem]' />
                ) : null}
                {children}
                <AppDrawer onCloseHandler={closeAppDrawer} />
              </div>
            ) : null}
            {isAccessDenied && !isLoading ? <NoAccess /> : null}
            {!isAccessDenied && isError ? <ErrorMessage /> : null}
          </main>
        </div>
        {!hideFooter ? (
          <Footer
            videoLink={videoLink}
            startTime={startTime}
            headerText={headerText}
            bbLandingPageLink={CONFIG.BB_LANDING_PAGE_LINK}
            supportTelegramLink={CONFIG.SUPPORT_TELEGRAM_LINK}
            linkToKnowledgeBase={CONFIG.LINK_TO_KNOWLEDGE_BASE}
            isLoginPage={pathToCompare === PATH_NAMES.START_PAGE}
            supportPhoneToHref={CONFIG.SUPPORT_PHONE_NUMBER_TO_HREF}
            supportPhoneToShow={CONFIG.SUPPORT_PHONE_NUMBER_TO_SHOW}
            className={isOpen ? 'hidden' : ''}
          />
        ) : null}
      </>
    );
  },
);

BaseTemplate.displayName = 'BaseTemplate';
