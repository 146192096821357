import { TRejectResponseData } from '@models/index';
import { dateSort, simpleSort } from '@helpers/index';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CHAT_HISTORY_API_ERRORS_TEXT } from '@const/chatHistory';
import { getChatHistory } from '@redux/chatHistory/chatHistoryThunk';
import { REJECT_RESPONSE_KEY, REJECT_RESPONSE_2_KEY } from '@api/types';
import { IInitialState, initialState } from '@redux/chatHistory/initialState';
import {
  CHAT_HISTORY_TABLE_DATA_KEY_NAMES,
  TChatHistoryTableData,
} from '@redux/chatHistory/models';

const chatHistorySlice = createSlice({
  name: 'chatHistory',
  initialState,
  reducers: {
    setChatHistoryTableData(state: IInitialState, action: PayloadAction<TChatHistoryTableData[]>) {
      state.tableData = action.payload;
      state.filteredTableData = action.payload;
    },
    setPhoneSearch(state: IInitialState, action: PayloadAction<string>) {
      const newValue = action.payload.match(/\d|\*/gm)?.join('');

      if (newValue && state.phoneNumberUsedToSearch) {
        state.phoneToSearch = newValue;
        state.phoneNumberUsedToSearch = '';
        state.filteredTableData = state.tableData;
      } else {
        state.phoneToSearch = newValue || '';
      }
    },
    setSortTypeDataCHT(
      state: IInitialState,
      action: PayloadAction<{ columnName: string; order: string }>,
    ) {
      const { columnName, order } = action.payload;

      const key = columnName as unknown as keyof TChatHistoryTableData;

      if (columnName === CHAT_HISTORY_TABLE_DATA_KEY_NAMES.DATE) {
        state.filteredTableData = dateSort<TChatHistoryTableData>(state.tableData, key, order, 0);
      } else {
        state.filteredTableData = simpleSort<TChatHistoryTableData>(state.tableData, key, order);
      }
    },
    searchByPhoneNumber(state: IInitialState) {
      if (state.phoneToSearch.length < 10) return;

      state.phoneNumberUsedToSearch = state.phoneToSearch;
      state.filteredTableData = state.tableData.filter(item =>
        item.phoneNumber.includes(state.phoneToSearch),
      );
    },
    clearSearchPhoneString(state: IInitialState) {
      state.phoneToSearch = '';
      state.phoneNumberUsedToSearch = '';
      state.filteredTableData = state.tableData;
    },
  },
  extraReducers: builder => {
    builder.addCase(getChatHistory.pending, (state: IInitialState) => {
      state.isError = false;
      state.isLoading = true;
      state.phoneToSearch = '';
    });
    builder.addCase(
      getChatHistory.fulfilled,
      (state: IInitialState, action: PayloadAction<TChatHistoryTableData[] | void>) => {
        if (action.payload) {
          const tableData = action.payload;

          state.tableData = tableData;
          state.filteredTableData = tableData;
        }
        state.isLoading = false;
      },
    );
    builder.addCase(
      getChatHistory.rejected,
      (state: IInitialState, action: PayloadAction<TRejectResponseData | undefined>) => {
        if (action.payload) {
          if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
            const { detail } = action.payload;
            if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
              state.isLoading = true;
            }
            return;
          }
          if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
            const { status } = action.payload;

            if (status === CHAT_HISTORY_API_ERRORS_TEXT.MESSAGES_NOT_FOUND) {
              state.isLoading = false;
              return;
            }
            if (
              status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED ||
              status === REQUEST_TEXT_ERROR_STATUS.ACCOUNT_NOT_FOUNDED
            ) {
              state.isAccessDenied = true;
            } else {
              state.isError = true;
            }
            return;
          }
          state.isError = true;
          state.isLoading = false;
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      },
    );
  },
});

export const chatHistoryReducer = chatHistorySlice.reducer;
export const {
  setPhoneSearch,
  setSortTypeDataCHT,
  searchByPhoneNumber,
  clearSearchPhoneString,
  setChatHistoryTableData,
} = chatHistorySlice.actions;
