import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import { Button } from '@uikit/Button';
import { Dialog } from '@uikit/Dialog';

type TConnectAutoPayModalProps = {
  /**
   * Строка с параметрами автоплатежа
   * @param {string}
   */
  rate: string;
  /**
   * URL на ю-кассу для подключения автоплатежа
   * @param {string}
   */
  autoPayLink: string;
  /**
   * Callback для перезагрузки страницы
   * @param {() => void}
   */
  reloadPage: () => void;
  /**
   * Callback для закрытия окна автоплатежа
   * @param {() => void}
   */
  connectAutoPayModalClose: () => void;
};

export const ConnectAutoPayModal = memo(
  ({ rate, reloadPage, autoPayLink, connectAutoPayModalClose }: TConnectAutoPayModalProps) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const modalToggle = useCallback(() => {
      setIsOpen(prevState => !prevState);
    }, []);

    const changePageOnClick = useCallback(() => {
      const target = '_blank';
      const newWindow = window.open(autoPayLink, target, 'noopener,noreferrer');

      if (newWindow) {
        newWindow.opener = null;
      }

      modalToggle();
    }, [autoPayLink, modalToggle]);

    return (
      <div>
        {isOpen ? (
          <Dialog
            type='default'
            view='raised'
            color='default'
            title={t('PAY_PAGE_TEXT.enableAutomaticPayment')}
            text={<span>{t('PAY_PAGE_TEXT.paymentDescription2')}</span>}
            backdropOpacity={40}
            rightButton={
              <Button
                dense
                type='action'
                view='filled'
                color='success'
                onClick={reloadPage}
                text={t('PAY_PAGE_TEXT.refresh')}
              />
            }
            isOpen={isOpen}
            onClose={connectAutoPayModalClose}
          />
        ) : (
          <Dialog
            type='default'
            view='raised'
            color='default'
            title={t('PAY_PAGE_TEXT.enableAutomaticPayment')}
            text={<span>{`${rate}. \n\n${t('PAY_PAGE_TEXT.paymentDescription')}`}</span>}
            backdropOpacity={40}
            rightButton={
              <div className='flex flex-wrap'>
                <Button
                  dense
                  type='action'
                  view='outlined'
                  color='default'
                  text={t('PAY_PAGE_TEXT.cancel')}
                  onClick={connectAutoPayModalClose}
                  className='mt-2 mr-6'
                />
                <Button
                  dense
                  type='action'
                  view='filled'
                  color='success'
                  onClick={changePageOnClick}
                  text={t('PAY_PAGE_TEXT.connect')}
                  className='mt-2'
                />
              </div>
            }
            isOpen={!isOpen}
            onClose={connectAutoPayModalClose}
          />
        )}
      </div>
    );
  },
);

ConnectAutoPayModal.displayName = 'ConnectAutoPayModal';
